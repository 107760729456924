<template>
  <div>
    <div class="itemTitle m-t1 flex ju-between al-center">
      <div><span style="font-weight: bold;"> {{ item.title }}</span>&nbsp;
      <span @click="openList">
      <CountTo color="#0066CC" fontSize="16px" :endVal="item.num" />
    </span>
      </div>
      <a-tooltip :title="item.define">
        <img class="tipsIcon" src="@/assets/images/newTransport/u60.png" alt="">
      </a-tooltip>
    </div>
    <div class="itemBox" style="margin-top: 0;font-size: 13px;">
      <div class=" flex wrap ju-between">
        <div v-for="opt in item.subItemList" :key="opt.id" :style="{borderColor:colorType[opt.colorType]}" class="flex al-center stateStyle ju-between">
          {{ opt.title }}&nbsp;&nbsp;<span :style="{color:colorType[opt.colorType]}">{{ opt.num }}</span>

        </div>
      </div>
    </div>
    <div v-if="visible">
      <PickModal :title="title" :typeKey="typeKey" v-model:visible="visible" :iptData="iptData" :data="data" :columns="columns"></PickModal>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import PickModal from './components/PickModal.vue' 
import { carryiptData1, carrycolumns1, carryiptData2, carrycolumns2, carryiptData3, carrycolumns3, carryiptData4, carrycolumns4, carryiptData5, carrycolumns5,
  carryiptData6, carrycolumns6,
  carryiptData7, carrycolumns7,
  carryiptData8, carrycolumns8,
  carryiptData9, carrycolumns9
 } from './components/PickModal.js'
 import { colorType } from '@/utils/enum';
 import CountTo from '@/components/countTo'
const props = defineProps({
  item: {
    type: Object,
    default: {}
  }
})
const typeKey = ref('')
const title = ref('')
const iptData = ref(null)
const columns = ref(null)
const data = ref([{}])
const visible = ref(false)
const openList = () => {
  switch (props.item.modalType) {
    case 1:
      title.value = '提-待安排'
      typeKey.value = '提'
      iptData.value = carryiptData1.value
      columns.value = carrycolumns1.value
      break;
    case 2:
      title.value = '提-待发运'
      typeKey.value = '提'
      iptData.value = carryiptData2.value
      columns.value = carrycolumns2.value
      break;
    case 3:
      title.value = '提-已发运'
      typeKey.value = '提'
      iptData.value = carryiptData3.value
      columns.value = carrycolumns3.value
      break;
    case 4:
      title.value = '干-待安排'
      typeKey.value = '干'
      iptData.value = carryiptData4.value
      columns.value = carrycolumns4.value
      break;
    case 5:
      title.value = '干-待发运'
      typeKey.value = '干'
      iptData.value = carryiptData5.value
      columns.value = carrycolumns5.value
      break;
      case 6:
      title.value = '干-待发运'
      typeKey.value = '在途'
      iptData.value = carryiptData6.value
      columns.value = carrycolumns6.value
      break;
      case 7:
      title.value = '送-待安排'
      typeKey.value = '送'
      iptData.value = carryiptData7.value
      columns.value = carrycolumns7.value
      break;
      case 8:
      title.value = '送-待发运'
      typeKey.value = '送'
      iptData.value = carryiptData8.value
      columns.value = carrycolumns8.value
      break;
      case 9:
      title.value = '送-已发运'
      typeKey.value = '送'
      iptData.value = carryiptData9.value
      columns.value = carrycolumns9.value
      break;
  }
  visible.value = true
}
</script>

<style lang="less" scoped>
.itemTitle {
  padding: 10px 0 0 10px;
  background: #f7f8fc;
  border-radius: 5px 5px 0 0;
}
.itemBox {
  padding: 0 10px;
  background: #f7f8fc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  padding-bottom: 10px;
  border-radius:  0 0 5px 5px;
  .tips {
    font-size: 12px;
    color: #999;
  }
}
.stateStyle {
  padding: 4px 8px;
  background: #edf0f9;
  border-radius: 4px;
  width: 48%;
  margin-top: 10px;
  border-left: 5px solid transparent;
}
.arrBox {
  padding: 2px 8px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 12px;
}
.tipsIcon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  cursor: pointer;
}
</style>