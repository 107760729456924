import { postBodyRequest, getRequest } from '@/utils/axios'

export function getPage (params) {
  return postBodyRequest(`/carrier/carrier/truck/page`, params)
}

export function addvehicles (params) {
  return postBodyRequest(`/carrier/carrier/truck/add`, params)
}

export function carrierDetail (truckId, params) {
  return getRequest(`/carrier/carrier/${truckId}/truck/photos`, params)
}

export function deleteVeh (carrierId, params) {
  return getRequest(`/carrier/carrier/${carrierId}/truck/delete`, params)
}

export function edit (params) {
  return postBodyRequest(`/carrier/carrier/truck/edit`, params)
}
export function getCarrierIdTruckId (carrierId, truckId) {
  return getRequest(`/carrier/carrier/${carrierId}/truck/${truckId}`, {})
}

export function editPhoto (params) {
  return postBodyRequest('/carrier/carrier/trunk/photoedit', params)
}