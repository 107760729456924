import {
  getRequest,
  postBodyRequest,
  fileUpload
} from '@/utils/axios'
export function pageAll (params) {
  return postBodyRequest('/anal/dispatch-center-console/statistics ', params)
}
// 配置参数
export function configAdd (params) {
  return postBodyRequest('/anal/dispatch-center-console/config-add', params)
}
// 获取省
export function onlyProvince (params) {
  return getRequest(`/cis/system/only-province/${Math.floor(Math.random() * 1000)}`, params)
}
// 所有小组组员
export function allGroupEmployee (params) {
  return getRequest('/trans/order/config/all-group-employee', params)
}