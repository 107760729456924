<template>
  <div>
    <div class="text-center dfx-jc-sb" style="margin-bottom:15px;">
      <span style="font-weight:bold"><img class="img" src="@/assets/images/carrier/car-success.png"><span>预装列表</span></span>
      <a v-show="addShow" @click="addData">新增预装</a>
    </div>
    <a-table :columns="advanceColumns" :data-source="advanceData" :loading="advanceLoading" :pagination="advancePagination" :row-key="record => record.id" size="small" @change="handleTableChange">
      <template #operation="{record}">
        <div><a @click="lookDetail(record)">查看</a></div>
      </template>
    </a-table>
  </div>
  <!-- 查看展示 -->
  <a-modal v-model:visible="lookShow" title="查看详情" :width="700" footer="" :mask="false" style="top: 40%">
    <div class="order-data" v-if="advanceDetail.stowageJson">
      <a-row :gutter="[24,12]">
        <a-col :span="8">
          <span class="detail-look-title">已选择承运商</span>
          <span class="font-color2">{{advanceDetail.stowageJson.carrierData.carrierName}}</span>
        </a-col>
        <a-col :span="8">
          <span class="detail-look-title">负责人</span>
          <span class="font-color2">{{advanceDetail.stowageJson.carrierData.charge}}</span>
        </a-col>
        <a-col :span="8">
          <span class="detail-look-title">负责人电话</span>
          <span class="font-color2">{{advanceDetail.stowageJson.carrierData.chargeMobile}}</span>
        </a-col>
        <a-col :span="24">
          <span class="detail-look-title">已选择线路</span>
          <span class="font-color2">{{advanceDetail.stowageJson.IDs.lineData?advanceDetail.stowageJson.IDs.lineData.lineName:''}}</span>
        </a-col>
        <a-col :span="8" v-if="advanceDetail.stowageJson.bigCarData!=={}&&advanceDetail.stowageJson.bigCarData!==undefined">
          <span class="detail-look-title">已选择大车</span>
          <span class="font-color2">{{advanceDetail.stowageJson.bigCarData.carNo}}</span>
        </a-col>
        <a-col :span="8" v-if="advanceDetail.stowageJson.bigCarData!=={}&&advanceDetail.stowageJson.bigCarData!==undefined">
          <span class="detail-look-title">司机姓名</span>
          <span class="font-color2">{{advanceDetail.carrierDriverName}}</span>
        </a-col>
        <a-col :span="8" v-if="advanceDetail.stowageJson.bigCarData!=={}&&advanceDetail.stowageJson.bigCarData!==undefined">
          <span class="detail-look-title">司机电话</span>
          <span class="font-color2">{{advanceDetail.stowageJson.bigCarData.mobile}}</span>
        </a-col>
        <a-col :span="8">
          <span class="detail-look-title">任务安排人</span>
          <span class="font-color2">{{advanceDetail.arranger?advanceDetail.arranger:''}}</span>
        </a-col>
        <a-col :span="16">
          <span class="detail-look-title">安排时间</span>
          <span class="font-color2">{{advanceDetail.scheduleTime?advanceDetail.scheduleTime:''}}</span>
        </a-col>
        <a-col :span="16">
          <span class="detail-look-title">备注信息</span>
          <span class="font-color2">{{advanceDetail.remark}}</span>
        </a-col>
      </a-row>
    </div>
  </a-modal>
  <!-- 新增预装 -->
  <a-modal v-model:visible="addAdvanceShow" :width="1200" footer="" @cancel="loadData()" :destroyOnClose="true">
    <template #title>
      <div v-if="element!==undefined&&element!=={}">{{element.vinNo}}</div>
    </template>
    <OperationDetail v-if="element!==undefined&&element!=={}" :type="element.position" :vehiclesArr="chooseList" :close="closeOperation" name="preparation" />
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { message } from "ant-design-vue"
import { advanceList, advanceListLook } from '@/api/transport/truck'

import OperationDetail from '../detail/operationDetail.vue'

export default {
  components: {
    OperationDetail
  },
  props: {
    orderVehicleId: String,
    element: Object
  },
  setup (props) {
    const state = reactive({
      advanceLoading: false,
      orderVehicleId: '',
      addShow: false,
      lookShow: false,
      addAdvanceShow: false,
      advanceData: [],
      chooseList: [],
      element: {},
      advanceDetail: {},
      advanceColumns: [
        {
          title: '承运商名称',
          dataIndex: 'carrierName'
        },
        {
          title: '线路名称',
          dataIndex: 'transportLineName'
        },
        {
          title: '安排人',
          dataIndex: 'arranger'
        },
        {
          title: '安排时间',
          dataIndex: 'scheduleTime'
        },
        {
          title: '操作',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      advancePagination: {
        current: 1,
        pageSize: 4,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['4', '10', '20', '50'],
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.advancePagination.current = pag.current
      state.advancePagination.pageSize = pag.pageSize
      loadData()
    }
    // 获取预装列表
    const loadData = () => {
      state.advanceLoading = true
      advanceList({
        orderVehicleId: state.orderVehicleId,
        current: state.advancePagination.current,
        size: 5
      }).then(res => {
        if (res.code === 10000) {
          state.advanceData = res.data.records
          state.advancePagination.total = res.data.total
          state.advanceLoading = false
        }
      })
    }
    // 查看详情
    const lookDetail = record => {
      state.lookShow = true
      advanceListLook(record.id)
        .then(res => {
          if (res.code === 10000) {
            res.data.stowageJson = JSON.parse(res.data.stowageJson)
            state.advanceDetail = res.data
          }
        })
    }
    // 新增预装弹出
    const addData = () => {
      if (state.element.preStowageType > 0) {
        state.addAdvanceShow = true
      } else message.info('当前车辆状态不可新增预装')
    }
    const closeOperation = () => {
      state.addAdvanceShow = false
      loadData()
    }
    onMounted(() => {
      if (props.orderVehicleId) state.orderVehicleId = props.orderVehicleId
      if (props.element) {
        state.element = props.element
        state.chooseList[0] = state.element
        state.addShow = true
      }
      loadData()
    })

    return {
      ...toRefs(state),
      loadData,
      lookDetail,
      addData,
      handleTableChange,
      closeOperation
    }
  }
}
</script>

<style lang="less" scoped>
</style>
