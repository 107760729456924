<template>
  <!-- 图片查看 -->
  <a-modal v-model:visible="imgShow" :width="1160" title="车辆照片" @ok="imgShow = false" footer="" @cancel="closeWindow">
    <a-button @click="changeBigType" type="primary">{{ isBig ? '切换小图' : '切换大图' }}</a-button>
    <div>
      <a-image-preview-group>
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane :key="1" tab="常规照片">
            <a-button @click="fileNormalDown(0)" type="primary">下载常规图片</a-button>
            <div style="width:100%;display:flex;justify-content: center;">
              <a-spin v-show="conventionaLoad" size="large" tip="加载中..." />
            </div>
            <div v-show="!injuryLoad" class="injury-infos">
              <div v-for="item in conventionalImg" :key="item.id"
                style="margin-right:10px;padding:5px;border:1px solid #eee;">
                <a-image :width="200" :src="isBig ? item.fileRealUrl : item.fileUrl" :preview="item.preview"
                  @click="handlePreview(item)" />
                <div style="display:flex;justify-content: space-between;font-size:12px;">
                  <span>{{ item.creator }}</span>
                  <span>{{ item.createTime }}</span>
                </div>
                <div style="text-align: left;max-width: 200px;word-break: break-all" v-show="item.fileRemark">{{ '备注：' +
                  item.fileRemark }}</div>
              </div>
            </div>
            <a-pagination size="small" v-model:pageSize="pageSize" :total="conventionaTotal" @change="conventionaPage" />
          </a-tab-pane>
          <a-tab-pane :key="2" tab="标准照片">
            <a-button @click="fileDown(0)" type="primary">下载标准图片</a-button>
            <div class="validate-infos">
              <div v-for="item in carValidateInfos" :key="item.id"
                style="margin-right:10px;margin-bottom:10px;padding:5px;border:1px solid #eee;">
                <a-image :width="200" :src="isBig ? item.fileRealUrl : item.fileUrl" :preview="item.preview"
                  @click="handlePreview(item)" />
                <div style="font-weight:bold;font-size:18px">{{ item.inspectType.label }}</div>
                <div style="display:flex;justify-content: space-between;font-size:12px">
                  <span>{{ item.creator }}</span>
                  <span>{{ item.createTime }}</span>
                </div>
                <div style="text-align: left;max-width: 200px;word-break: break-all" v-show="item.fileRemark">备注：{{
                  item.fileRemark }}</div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane :key="3" tab="车伤照片">
            <a-button @click="fileOtherDown(0)" type="primary">下载车伤图片</a-button>
            <div style="width:100%;display:flex;justify-content: center;">
              <a-spin v-show="injuryLoad" size="large" tip="加载中..." />
            </div>
            <div v-show="!injuryLoad" class="injury-infos">
              <div v-for="item in carInjuryInfos" :key="item.id"
                style="margin-right:10px;padding:5px;border:1px solid #eee;">
                <a-image :width="200" :src="isBig ? item.fileRealUrl : item.fileUrl" :preview="item.preview"
                  @click="handlePreview(item)" />
                <div style="display:flex;justify-content: space-between;font-size:12px">
                  <span>{{ item.creator }}</span>
                  <span>{{ item.createTime }}</span>
                </div>
                <div style="text-align: left;max-width: 200px;word-break: break-all" v-show="item.fileRemark">备注：{{
                  item.fileRemark }}</div>
              </div>
            </div>
            <a-pagination size="small" v-model:pageSize="pageSize" :total="totalPage" @change="carInjuryPage" />
          </a-tab-pane>
          <a-tab-pane :key="4" tab="入盘库照片">
            <a-button @click="fileStoreDown(0)" type="primary">下载入盘库图片</a-button>
            <div style="width:100%;display:flex;justify-content: center;">
              <a-spin v-show="injuryLoad" size="large" tip="加载中..." />
            </div>
            <div v-show="!injuryLoad" class="injury-infos">
              <div v-for="item in ware.record" :key="item.id"
                style="margin-right:10px;padding:5px;border:1px solid #eee;">
                <a-image :width="200" :src="isBig ? item.fileRealUrl : item.fileUrl" :preview="item.preview"
                  @click="handlePreview(item)" />
                <div style="display:flex;justify-content: space-between;font-size:12px">
                  <span>{{ item.creator }}</span>
                  <span>{{ item.createTime }}</span>
                </div>
                <div style="text-align: left;max-width: 200px;word-break: break-all" v-show="item.fileRemark">备注：{{
                  item.fileRemark }}</div>
              </div>
            </div>
            <a-pagination v-if="ware.total > 0" v-model:current="ware.current" :total="ware.total" :pageSize="5"
              show-less-items @change="ValidateInfoChange" />
          </a-tab-pane>
          <a-tab-pane :key="5" tab="上板视频">
            <h2 v-show="!videoData.fileUrl">暂无上板视频</h2>
            <div v-show="videoData.fileUrl">
              <video style="width:200px;height:150px" controls :src="videoData.fileUrl"></video>
              <div style="width:200px;display:flex;justify-content: space-between;font-size:12px">
                <span>{{ videoData.creator }}</span>
                <span>{{ videoData.createTime }}</span>
              </div>
              <div style="width:200px;text-align: left;max-width: 200px;word-break: break-all"
                v-show="videoData.fileRemark">备注：{{ videoData.fileRemark }}</div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-image-preview-group>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { carPicsGroup, fileDownload } from '@/api/global'
import { message } from 'ant-design-vue'

export default {
  setup () {
    const state = reactive({
      vinNo: '',
      pageSize: 30,
      totalPage: 0,
      imgShow: false,
      isBig: false,
      injuryLoad: false,
      conventionaLoad: false,
      activeKey: null,
      orderVehicleId: '',
      conventionaPageSize: 1,
      conventionaTotal: '',
      conventionalImg: [], // 常规图片
      carValidateInfos: [], // 标准图片
      carBases: [], // 有常规图片
      carInjuryInfos: [], // 车伤图片
      videoData: { fileUrl: null, fileRemark: '' }, // 上板视频
      ware: {
        current: 1,
        total: null,
        record: []
      }, // 入盘库图片
    })
    // 查看图片
    const vehicleImg = (orderVehicleId, vinNo) => {
      state.vinNo = ''
      state.imgShow = true
      state.activeKey = 1
      state.orderVehicleId = orderVehicleId
      state.vinNo = vinNo
      state.carBases = []
      // 常规图片
      carPicsGroup(orderVehicleId, {
        injury: 2,
        current: 1,
        size: state.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.conventionalImg = res.data.records
          state.conventionaTotal = res.data.total
        }
      }).catch(err => { console.log(err) })
      // 标准图片
      carPicsGroup(orderVehicleId, {
        injury: 0
      }).then(res => {
        if (res.code === 10000) {
          state.videoData = res.data.records.find(item => item.inspectType.value === 128)
          res.data.records.splice(res.data.records.findIndex(item => item.inspectType.value === 128), 1)
          state.carValidateInfos = res.data.records
          state.carValidateInfos.forEach(item => {
            if (item.fileUrl === null) {
              item.fileUrl = require('@/assets/images/nullImg.jpg')
              item.fileRealUrl = require('@/assets/images/nullImg.jpg')
            } else {
              state.carBases.push(item)
            }
          })
        }
      }).catch(err => console.log(err))
      // 车伤图片
      carPicsGroup(orderVehicleId, {
        injury: 1,
        current: state.injuryCurrent,
        size: state.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.carInjuryInfos = res.data.records
          state.totalPage = res.data.total
        }
      }).catch(err => console.log(err))
      // 入盘库图片
      carPicsGroup(orderVehicleId, {
        injury: 3,
        current: state.ware.current,
        size: 5
      }).then(res => {
        if (res.code === 10000) {
          state.ware.record = res.data.records
          state.ware.current = res.data.current
          state.ware.total = res.data.total
        }
      })
    }
    const ValidateInfoChange = page => {
      state.validateInfoCurrent = page
      carPicsGroup(state.orderVehicleId)
    }
    const carInjuryPage = (page, pageSize) => {
      state.injuryLoad = true
      state.injuryCurrent = page.current
      carPicsGroup(state.orderVehicleId, {
        injury: 1,
        current: page,
        size: state.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.carInjuryInfos = res.data.records
          state.totalPage = res.data.total
          state.injuryLoad = false
        }
      }).catch(err => console.log(err))
    }
    const conventionaPage = (page, pageSize) => {
      state.conventionaCurrent = page.current
      state.conventionaLoad = true
      // 常规图片
      carPicsGroup(state.orderVehicleId, {
        injury: 2,
        current: page,
        size: state.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.conventionalImg = res.data.records
          state.conventionaTotal = res.data.total
          state.conventionaLoad = false
        }
      }).catch(err => { console.log(err) })
    }
    const handlePreview = (item) => {
      item.preview = true
      item.fileUrl = item.fileRealUrl
    }
    const changeBigType = () => {
      state.isBig = !state.isBig
      const info = state.isBig ? '大图' : '小图'
      message.success('切换' + info + '成功')
    }
    const fileDown = (i) => {
      if (state.carBases.length === 0) {
        message.info(state.vinNo + '无图片可下载')
        return
      }
      if (i >= state.carBases.length) {
        message.success(state.vinNo + '的基本图片，已全部下载完成')
        return
      }
      fileDownload({
        url: state.carBases[i].fileRealUrl
      }, state.vinNo + '的基本第' + (i + 1) + '张').then(res => {
        i = i + 1
        message.info(state.vinNo + '的基本第' + i + '张下载完成')
        fileDown(i)
      })
    }
    const fileOtherDown = (i) => {
      if (state.carInjuryInfos.length === 0) {
        message.info(state.vinNo + '无图片可下载')
        return
      }
      if (i >= state.carInjuryInfos.length) {
        message.success(state.vinNo + '的车伤/其他图片，已全部下载完成，总' + i + '张')
        return
      }
      fileDownload({
        url: state.carInjuryInfos[i].fileRealUrl
      }, state.vinNo + '的其他第' + (i + 1) + '张').then(res => {
        i = i + 1
        message.info(state.vinNo + '的车伤/其他 第' + i + '张下载完成')
        fileOtherDown(i)
      })
    }
    const fileNormalDown = (i) => {
      if (state.conventionalImg.length === 0) {
        message.info(state.vinNo + '无图片可下载')
        return
      }
      if (i >= state.conventionalImg.length) {
        message.success(state.vinNo + '的图片，已全部下载完成，总' + i + '张')
        return
      }
      fileDownload({
        url: state.conventionalImg[i].fileRealUrl
      }, state.vinNo + '的第' + (i + 1) + '张').then(res => {
        i = i + 1
        message.info(state.vinNo + '的 第' + i + '张下载完成')
        fileNormalDown(i)
      })
    }
    // 入盘库下载
    const fileStoreDown = (i) => {
      if (state.ware.record.length === 0) {
        message.info(state.vinNo + '无图片可下载')
        return
      }
      if (i >= state.ware.record.length) {
        message.success(state.vinNo + '的图片，已全部下载完成，总' + i + '张')
        return
      }
      fileDownload({
        url: state.ware.record[i].fileRealUrl
      }, state.vinNo + '的第' + (i + 1) + '张').then(res => {
        i = i + 1
        message.info(state.vinNo + '的 第' + i + '张下载完成')
        fileNormalDown(i)
      })
    }
    const closeWindow = () => {
      state.conventionalImg = []
      state.carValidateInfos = []
      state.carBases = []
      state.carInjuryInfos = []
      state.ware.record = []
    }
    return {
      ...toRefs(state),
      vehicleImg,
      closeWindow,
      handlePreview,
      changeBigType,
      fileOtherDown,
      fileNormalDown,
      fileDown,
      conventionaPage,
      carInjuryPage,
      fileStoreDown,
      ValidateInfoChange,
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-image-img) {
  height: 170px;
}

.validate-infos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

// 车伤图片
.injury-infos {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}
</style>
