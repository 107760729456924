<template>
  <div>
    <div style="display:flex;justify-content:space-between">
      <div>
        <span v-show="detailData.vinNo">{{ detailData.vinNo + ' ' + detailData.brand + detailData.model }}</span>
        <span v-show="!detailData.vinNo">暂无车牌号</span>
        <a style="margin-left:10px;font-size:14px" @click="showRecord">车辆日志</a>
        <!-- <a style="margin-left:10px;font-size:14px" @click="getUpdateImg">车辆图片</a> -->
        <a style="margin-left:10px;font-size:14px" @click="getUpdateImg">车辆图片</a>
        <a style="margin-left:10px;font-size:14px" @click="orderGPS">在途位置</a>
        <a style="margin-left:10px;font-size:14px" @click="openAbnormal">异常列表</a>
        <!-- <a style="margin-left:10px;font-size:14px" @click="addReportShow = true">上报异常</a> -->
        <a style="margin-left:10px;font-size:14px" v-show="detailData.isPlaceIn && detailData.isPlaceIn.value === 1" @click="pickVisible = true">取车委托</a>
        <a style="margin-left:10px;font-size:14px" @click="transVisible = true">运车委托</a>
        <a style="margin-left:10px;font-size:14px" v-show="detailData.isTakeOut && detailData.isTakeOut.value === 1" @click="takeVisible = true">送车委托</a>
        <a style="margin-left:10px;font-size:14px" v-if="detailData.isDelivery?.value === 1" @click="lookDelivety">查看交车条</a>
        <a style="margin-left:10px;font-size:14px" v-if="detailData.isDelivery?.value !== 1" @click="uploadDelivety">上传交车条</a>
        <a style="margin-left:10px;font-size:14px" @click="lookFollowList">跟单列表</a>
      </div>
      <div style="margin-right:40px">
        <span>{{ detailData.salesman }}</span>
        <span>{{ detailData.orderTime }}</span>
      </div>
    </div>
    <a-modal v-model:visible="pickVisible" title="取车委托" width="600px" footer="">
      <div>
        <p class="font-color2">订单号：{{detailData.orderId}}</p>
        <p class="font-color2">车牌/车架号：{{ detailData.brand + detailData.model }} {{ detailData.vinNo }}</p>
        <p class="font-color2">交车人姓名：{{ detailData.consignor }}</p>
        <p class="font-color2">交车人电话：{{ detailData.consignorMobile }}</p>
        <p class="font-color2">交车人身份证：{{ detailData.consignorCertificateNumber }}</p>
        <p class="font-color2" v-if="detailData.orderRemark">订单备注：{{ detailData.orderRemark }}</p>
        <p class="font-color2" v-if="detailData.remark">小车备注：{{ detailData.remark }}</p>
        <p class="font-color2">取车信息：{{ detailData.pickType ? detailData.pickType.label : '' }}&nbsp;{{ detailData.placeInAddress }}
        <p class="font-color2">温馨提示：物流只负责外观内饰的检查，车辆性能及其它内部问题我们没有技术能力检验，望理解！贵重物品不随车，小件物品请打包放好，有ETC请提前拔掉，谢谢配合！</p>
        </p>
      </div>
    </a-modal>
    <a-modal v-model:visible="transVisible" title="运车委托" width="600px" footer="">
      <div>
        <p class="font-color2">订单号：{{detailData.orderId}}</p>
        <p class="font-color2">车牌/车架号：{{ detailData.brand + detailData.model }} {{ detailData.vinNo }}</p>
        <p class="font-color2">起运地：{{detailData.startAddress}}</p>
        <p class="font-color2">目的地：{{detailData.endAddress}} {{ detailData.picker }} {{ detailData.pickerMobile }}</p>
        <p class="font-color2"><span v-show="detailData.paymentArrival > 0">
          订单到付: <a-tag color="red">{{ detailData.paymentArrival }}</a-tag>
                  <span>{{ detailData.isNoticeReleaseCar?.value === 1 ? '等通知放车' : '' }}</span></span></p>
                  <p class="font-color2">温馨提示：承运商只做交车相关的沟通，若客户询问其它，让联系业务咨询。有私自添加客户微信等撬客户行为，一经发现，终止合作！</p>
      </div>
    </a-modal>
    <a-modal v-model:visible="takeVisible" title="送车委托" width="600px" footer="">
      <div>
        <p class="font-color2">订单号：{{detailData.orderId}}</p>
        <p class="font-color2">车牌/车架号：{{ detailData.brand + detailData.model }} {{ detailData.vinNo }}</p>
        <p class="font-color2">收车人姓名：{{ detailData.picker }}</p>
        <p class="font-color2" v-if="detailData.pushTime">收车人电话：{{ detailData.pickerMobile }}</p>
        <p class="font-color2">收车人身份证：{{ detailData.pickerCertificateNumber }}</p>
        <p class="font-color2"><span v-show="detailData.paymentArrival > 0"> 订单到付：<a-tag color="red">{{
          detailData.paymentArrival }}</a-tag></span><span>{{ detailData.isNoticeReleaseCar?.value === 1 ? '等通知放车' :
    ''
  }}</span></p>
        <p class="font-color2" v-if="detailData.orderRemark ">订单备注：{{ detailData.orderRemark }}</p>
        <p class="font-color2" v-if="detailData.remark">小车备注：{{ detailData.remark }}</p>
        <p class="font-color2">送车信息：{{ detailData.takeType ? detailData.takeType.label : '' }}<span v-if="detailData.takeOutAddress">&nbsp;{{ detailData.takeOutAddress }}</span></p>
        <p class="font-color2">温馨提示：承运商只做交车相关的沟通，若客户询问其它，让联系业务咨询。有私自添加客户微信等撬客户行为，一经发现，终止合作！</p>
      </div>
    </a-modal>
    <a-modal v-model:visible="deliveryVisible" title="交车信息查看" width="40%" footer="" @cancel="closeDelivery">
      <div class="delivery-content">
        <div v-show="!deliveryLoading && deliveryData.orderVehicleId">
          <a-form-item label="到收款金额" v-if="deliveryData.paidAmt > 0">
            <span style="color:red"> {{ deliveryData.paidAmt }}</span>
          </a-form-item>
          <span>收车条：</span>
          <a-image :width="100" style="height:100px" :src="deliveryData.fileUrl" :preview="deliveryData.preview" @click="handlePreview(deliveryData)" />
          <a-row :gutter="[24, 12]">
            <a-col :span="10">
              <span>收款对象：</span>
              {{ deliveryData.deliveryType && deliveryData.deliveryType.label }}
            </a-col>
            <a-col :span="7">
              {{ deliveryData.officeOrgName }}
            </a-col>
            <a-col :span="7" v-if="deliveryData.deliveryOfficeOrg != null">
              {{ deliveryData.deliveryOfficeOrg.label }}
            </a-col>
            <a-col :span="24">
              <span>交车人：</span>
              {{ deliveryData.creatorBy }}
            </a-col>
            <a-col :span="24">
              <span>交车时间：</span>
              {{ deliveryData.createTime }}
            </a-col>
            <a-col :span="24">
              <span>交车说明：</span>
              {{ deliveryData.remark }}
            </a-col>
          </a-row>
        </div>
        <h2 v-show="!deliveryLoading && !deliveryData.orderVehicleId" style="text-align: center">暂无交车条</h2>
        <div v-show="deliveryLoading" style="display:flex;justify-content: center;align-items:center">
          <a-spin size="large" />
        </div>
      </div>
    </a-modal>
    <!-- 跟单列表 -->
    <a-modal v-model:visible="followListShow" title="跟单列表" width="60%" footer="">
      <div>
        <ResultFollow ref="resultFollowRef" />
      </div>
    </a-modal>
    <!-- 信息日志 -->
    <a-modal v-model:visible="journalShow" width="40%" title="车辆日志" footer="" :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">
      <div v-if="detailData.orderVehicleId">
        <Record v-if="detailData.orderVehicleId" ref="recordRef" :vehicleList="[]" :vehiclesId="detailData.orderVehicleId" :orderId="detailData.orderId" />
      </div>
    </a-modal>
    <!-- 异常上报 -->
    <AddReport :windowShow="addReportShow" :vehicleDetail="detailData" @reportData="addReportData" />
    <!-- 车辆图片 -->
    <!-- <InspectionImg ref="imgListRef" :orderVehicleId="detailData.orderVehicleId" :loadInspectList="[]" /> -->
    <!-- 车辆图片 -->
    <VehicleImg ref="vehicleImgRef" />
    <!-- 异常列表 -->
    <a-modal v-model:visible="abnormalReportShow" title="异常列表" width="60%" footer="">
      <AbnormalReport v-if="abnormalReportShow" ref="abnormalReportRef" :vinNo="detailData.vinNo" :vehicleDetail="detailData" @statusShow="abnormal" />
    </a-modal>
    <VehicleGps ref="vehicleGps" />
    <!-- 交车 -->
    <GiveVehicle ref="giveVehicleRef" :vehicleId="detailData.orderVehicleId" @successUpload="getSuccessUpload" :orderSettlement="detailData.orderSettlement?.value" :paidAmt="Number(detailData.contractAmt)" />
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import { vehicleDeliveryInfo } from '@/api/transport/truck'
import VehicleGps from '@/views/order/positionInfo/carGps.vue'
import AddReport from '@/views/components/transport/comon/addReport.vue'
import AbnormalReport from '@/views/components/transport/comon/abnormalReport.vue'
// import InspectionImg from '@/views/components/vehicleUpload/inspectionImg'
import Record from '@/views/transportCapacity/transportUse/comon/record.vue'
import ResultFollow from '@/views/components/followOrder'
import VehicleImg from '@/views/components/vehicleImg'
import GiveVehicle from '@/views/components/giveVehicle'

export default {
  props: {
    detailData: Object
  },
  components: {
    Record,
    VehicleGps,
    AddReport,
    AbnormalReport,
    VehicleImg,
    GiveVehicle,
    // InspectionImg,
    ResultFollow
  },
  setup (props, context) {
    const giveVehicleRef = ref(null)
    const vehicleImgRef = ref(null)
    const abnormalReportRef = ref(null)
    const recordRef = ref(null)
    const resultFollowRef = ref(null)
    const imgListRef = ref(null)
    const vehicleGps = ref(null)
    const state = reactive({
      pickVisible: false,
      transVisible: false,
      takeVisible: false,
      deliveryLoading: false,
      deliveryVisible: false,
      followListShow: false,
      journalShow: false,
      addReportShow: false,
      abnormalReportShow: false,
      detailData: props.detailData,
      deliveryData: {
        paidAmt: 0,
        officeOrgId: '',
        vehicleId: '',
        deliveryOfficeOrg: 0
      }
    })
    const showRecord = () => {
      state.journalShow = true
      setTimeout(() => {
        recordRef.value.loadData()
      }, 10)
    }
    // 车辆图片
    const getUpdateImg = () => {
      setTimeout(() => {
        vehicleImgRef.value.vehicleImg(props.detailData.orderVehicleId, state.detailData.vinNo)
      }, 50)
      // setTimeout(() => {
      //   imgListRef.value.inspectionImg = true
      //   imgListRef.value.loadData(props.detailData.orderVehicleId)
      // }, 100)
    }
    // 在途位置gps
    const orderGPS = () => {
      setTimeout(() => {
        vehicleGps.value.onvisible(true)
        vehicleGps.value.onorderVehicleId(props.detailData.orderVehicleId)
      }, 100)
    }
    // 异常列表回传
    const abnormal = e => {
      state.abnormalReportShow = false
    }
    // 新增异常上报
    const addReportData = e => {
      if (e.reportType !== null && e.reportType !== undefined && e.reportType !== 0) {
        delete e.belong
        let submit = {
          ...e,
          totvId: props.detailData.totvId,
          orderVehicleId: props.detailData.orderVehicleId,
          taskId: null
        }
        addReport(submit).then(res => {
          if (res.code === 10000) {
            state.addReportShow = false
            message.success('上报成功')
          }
        })
      } else state.addReportShow = false
    }
    const uploadDelivety = () => {
      if (giveVehicleRef.value) giveVehicleRef.value.windowShow = true, giveVehicleRef.value.getDataProps()
    }
    const getSuccessUpload = e => {
      props.detailData.isDelivery = { value: 1 }
    }
    const lookDelivety = () => {
      state.deliveryLoading = true
      state.deliveryVisible = true
      vehicleDeliveryInfo(props.detailData.orderVehicleId).then(res => {
        if (res.code === 10000 && res.data) {
          state.deliveryData = res.data
        }
      }).finally(() => { state.deliveryLoading = false })
    }
    const closeDelivery = () => {
      state.deliveryVisible = false
      if (state.deliveryData.vehicleId !== '') state.deliveryData = {}
    }
    const handlePreview = (item) => {
      item.preview = true
      item.fileUrl = item.fileRealUrl
    }
    const lookFollowList = () => {
      state.followListShow = true
      setTimeout(() => {
        resultFollowRef.value.loadData(props.detailData.orderVehicleId)
      }, 10)
    }
    const openAbnormal = () => {
      state.abnormalReportShow = true
      setTimeout(() => {
        abnormalReportRef.value.openWindow()
      }, 10)
    }
    return {
      ...toRefs(state),
      resultFollowRef,
      abnormalReportRef,
      recordRef,
      giveVehicleRef,
      vehicleImgRef,
      // imgListRef,
      vehicleGps,
      getSuccessUpload,
      showRecord,
      getUpdateImg,
      openAbnormal,
      orderGPS,
      abnormal,
      uploadDelivety,
      addReportData,
      lookDelivety,
      closeDelivery,
      handlePreview,
      lookFollowList
    }
  }
}
</script>

<style lang="less" scoped></style>