import { postBodyRequest, getRequest } from '@/utils/axios'

export function searchPid (empid) {
  return postBodyRequest(`/trans/order/inspection/${empid}`)
}

export function page (params) {
  return postBodyRequest('/trans/order/inspection/page', params)
}

export function inspection (orderId, params) {
  return postBodyRequest(`/trans/order/inspection/${orderId}`, params)
}

export function receiving (orderId, params) {
  return postBodyRequest(`/trans/order/inspection/${orderId}/receiving`, params)
}

export function inspectionChange (params) {
  return postBodyRequest('/trans/order/inspection/change', params)
}

export function vent (params) {
  return postBodyRequest('/trans/order/inspection/vent', params)
}

export function done (orderId,taskId, params) {
  return postBodyRequest(`/trans/order/inspection/${orderId}/${taskId}/done`, params)
}

export function orderVehicle (orderId, params) {
  return postBodyRequest(`/trans/order/inspection/${orderId}/orderVehicle`, params)
}

export function QRCurl (params) {
  return postBodyRequest('/trans/wx/order/getWXprogramQr', params)
}

export function QRCpayurl (params) {
  return postBodyRequest('/trans/aggregate/getQrCode', params)
}
export function QRCpayurlYST (params) {
  return postBodyRequest('/trans/yunstpay/getYunstQrCode', params)
}
export function getYunsReconcQRCode (params) {
  return postBodyRequest('/trans/yunstpay/getYunsReconcQRCode', params)
}
export function ventPage (params) {
  return postBodyRequest('/trans/order/inspection/vent/page', params)
}

export function driverPage (params) {
  return postBodyRequest('/trans/order/inspection/driver/page', params)
}

export function inspectionChangeEdit (params) {
  return postBodyRequest('/trans/order/inspection/change/edit', params)
}

export function orderSplit (params) {
  return postBodyRequest('/trans/order/inspection/split', params)
}

export function take (orderId, params) {
  return postBodyRequest(`/trans/order/inspection/${orderId}/collect`, params)
}

export function remarkData (orderId, params) {
  return postBodyRequest(`/trans/order/inspection/remark/${orderId}`, params)
}

export function inspectionCommission (orderVehicleId) {
  return getRequest(`/trans/order/inspection/commission/${orderVehicleId}`)
}

// 任务付款码
export function orderpayqrcodeTask (params) {
  return postBodyRequest(`/trans/yunstpay/qrcode/task`, params)
}