import { postBodyRequest, postRequest, getRequest } from '@/utils/axios'

export function channelList (params) {
  return postBodyRequest('/market/channel/list', params)
}
export function enable (id, type, params) {
  return postRequest(`/market/channel/enable/${id}/${type}`, params)
}

export function addCategory (params) {
  return postBodyRequest('/market/channel/add/category', params)
}

export function addSub (params) {
  return postBodyRequest('/market/channel/add/sub', params)
}
export function edit (params) {
  return postBodyRequest('/market/channel/edit', params)
}

export function channeltree (params) {
  return getRequest('/market/channel/tree', params)
}

export function channelDelete (id) {
  return postBodyRequest(`/market/channel/delete/${id}`, {})
}
