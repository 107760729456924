<template>
  <a-modal :visible="windowShow" footer="" width="50%" :destroyOnClose="true" :maskClosable="false"
    @cancel="cancelClose" :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">
    <template #title>
      <div class="font-color" style="display:flex;justify-content: space-between">
        <div style="display:flex">
          <div class="avater">
            <img class="img" src="@/assets/images/carrier/orderId.png">
          </div>
          <div style="color: #344563">&emsp;新增运输任务</div>
        </div>
      </div>
    </template>
    <div style="display:flex">
      <div class="left" style="width:100%;flex:1">
        <!-- 选择路线-----暂停用 -->
        <div v-if="false">
          <div>
            <div class="font-color2 detail-title" style="margin:15px 0;">
              <img class="img" src="@/assets/images/carrier/accept.png">
              &emsp;
              <span class="mrg-10" style="font-weight:bold;color:#344563">选择线路</span><a
                @click="lineShow = !lineShow">选择</a>
              &emsp;<a>{{ lineName }}</a>
            </div>
            <!-- <div class="font-color2" style="flex:1;text-align: end;">报价：1200</div> -->
          </div>
          <div v-show="lineShow">
            <p>
              <a-cascader :options="$store.state.app.PClist" :show-search="{ filter }" :changeOnSelect="true"
                @change="setStartAddress" placeholder="请输入起点" />
              ——
              <a-cascader :options="$store.state.app.PClist" :show-search="{ filter }" :changeOnSelect="true"
                @change="setEndAddress" placeholder="请输入终点" />
              <a @click="setLine">搜索线路</a>
            </p>
            <span v-show="lineList.length <= 0">暂无该线路信息</span>
            <div v-show="!lineLoading && addShow"><a @click="addLine">点击添加路线</a></div>
          </div>
          <div v-show="lineShow && !lineLoading" class="line-list font-color2" v-for="(item, index) in lineList"
            :key="index" @click="confirmLine(item)">
            <span>{{ item.name }}</span>
            <a class="confirm">确认选择</a>
          </div>
          <a-skeleton v-show="lineLoading" active :paragraph="{ rows: 5 }" />
          <div v-show="lineList.length > 0 && lineShow">
            <a-pagination size="small" :current="linePage.current" :total="linePage.total" :pageSize="linePage.pageSize"
              @change="lineListChange" />
          </div>
        </div>
        <!-- 选择起始地目的地 -->
        <div>
          <div>
            <div class="font-color2 detail-title" style="margin:15px 0;">
              <img class="img" src="@/assets/images/carrier/accept.png">
              &emsp;
              <span class="mrg-10" style="font-weight:bold;color:#344563">选择城市</span><a
                @click="lineShow = !lineShow">选择</a>
            </div>
          </div>
          <div v-show="lineShow">
            <p>
              <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true"
                @change="setStartAddress" placeholder="请输入起点" />
              ——
              <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true"
                @change="setEndAddress" placeholder="请输入终点" />
              <a v-show="startAddress.city && endAddress.city" @click="setCarrier">确认选择</a>
            </p>
          </div>
        </div>
        <!-- 途经点 -->
        <div v-if="false" v-show="passByDivShow">
          <div class="font-color2 detail-title" style="margin:15px 0;">
            <img class="img" src="@/assets/images/carrier/u234.png">
            &emsp;
            <span class="mrg-10" style="font-weight:bold;color:#344563">途经点</span><a class="mrg-10"
              @click="passByShow = !passByShow">{{ routeList.length < 1 ? '选择' : '已选择' }}</a>
                <span class="mrg-10" v-for="item in routeList" :key="item.value">{{ item.label }}</span>
          </div>
          <div v-show="passByShow" class="dfx-jc-sb">
            <div>
              <a-tag v-for="(item, index) in routeList" :key="index">
                {{ item.label }}
                <a @click="deleteRoute(item, index)" style="color: #f00">X</a>
              </a-tag>
              <a-cascader v-if="inputShow" style="width:80px;height:22px" size="small"
                :options="$store.state.app.OnlyCityList" :show-search="{ filter }" :changeOnSelect="true" @change="addVia"
                placeholder="请选择" />
              <a-tag v-show="!inputShow" @click="inputShow = true" style="background: #fff; border-style: dashed">
                <plus-outlined />
                添加
              </a-tag>
            </div>
            <a-button type="primary" size="small" @click="confirmVIA">确定</a-button>
          </div>
        </div>
        <!-- <a v-show="lineName !== ''" @click="setCarrier">搜索承运商</a> -->
        <!-- 选择承运商 -->
        <Carrier v-if="carrierShow" :carrierList="carrierList" @confirm="carrierData" :carrierId="carrierId" />
        <!-- 选择大车 -->
        <div v-show="chooseBig">
          <div class="font-color2 detail-title" style="margin:15px 0;">
            <img class="img" src="@/assets/images/carrier/bigCar.png" />
            &emsp;
            <span style="font-weight:bold;color:#344563">选择大车</span>&emsp;&emsp;
            <a v-show="driverName === ''" @click="tableChange = !tableChange">选择{{ carNo !== '' ? '已选择' : '选择' }}</a>
            <a v-show="driverName !== ''" @click="tableChange = !tableChange">{{ driverName }}</a>
            &emsp;{{ carNo !== '' ? carNo : '' }}<span v-show="carNo !== ''">({{ gpsType?.label }})</span>
          </div>
          <div class="big-table" v-show="!tableChange">
            <BigCarDriver ref="carDrvierRef" v-if="carrierId !== ''" :carrierId="carrierId"
              @carDriverData="carDriverData" />
          </div>
          <!-- <div class="big-table" v-show="tableChange"> -->
          <div class="big-table" v-show="planVehicles.length > 0">
            <!-- <a-table :data-source="bigCarTable" v-model:columns="bigCarColumns" :pagination="false" :loading="loading" :rowKey="(record,index)=>{return index}" size="small">
            <template #operation>
              <div>
                <a-button type="link" size="small" @click="tableChange=false">更换大车</a-button>
              </div>
            </template>
          </a-table> -->
            <a-space style="margin-top:25px" v-show="planVehicles.length > 0">
              <a-button style="background:#1890FF;color:#fff" @click="sendCarrierShow = true">发送承运商运单</a-button>
              <a-button v-show="planVehicles.length > 0 && !completeShow" type="primary" @click="forwarding">发车</a-button>
              <!--            <a-button v-show="completeShow" type="primary" style="background:#0077B8" @click="transitShow=true">中途到达</a-button>-->
              <!-- <a-popconfirm title="确认到达" ok-text="确认" cancel-text="关闭" @confirm="confirmArrival">
                <a-button v-show="completeShow" type="primary" style="background:#07C160">到达</a-button>
              </a-popconfirm> -->
              <a-popconfirm title="确认取消该任务" ok-text="确认" cancel-text="关闭" @confirm="cancelPlan">
                <a-button v-show="planVehicles.length === 0 && !completeShow" type="primary"
                  style="background:#FF5722">取消任务</a-button>
              </a-popconfirm>
              <!-- <a-button type="primary" style="background:#FFB800">故障</a-button> -->
            </a-space>
          </div>
        </div>
        <!-- 已安排小车 -->
        <div v-show="chooseSmall">
          <div class="font-color2 detail-title" style="margin:15px 0;">
            <img class="img" src="@/assets/images/carrier/u299.png">
            &emsp;
            <span style="font-weight:bold;color:#344563">已安排小车</span>&emsp;&emsp;<a>选择</a>&emsp;
          </div>
          <div>
            <a-table v-show="planVehicles.length > 0" :data-source="planVehicles" v-model:columns="arrangedColumns"
              :pagination="ArrangedPagination" :loading="arrangedLoading" :rowKey="(record, index) => { return index }"
              size="small" bordered @change="arrangedChange">
              <template #vinNo="{ record }">
                <div><a @click="smallDetail = record, smallDetailShow = true">{{ record.vinNo }}</a></div>
              </template>
              <template #brand="{ record }">
                <div>{{ record.brand + record.model }}</div>
              </template>
              <template #address="{ record }">
                <div>
                  <div>起：{{ record.startAddress }}</div>
                  <div>终：{{ record.endAddress }}</div>
                </div>
              </template>
              <template #store="{ record }">
                <div v-if="record.isShop">
                  <a-switch checked-children="是" un-checked-children="否" :checkedValue="1" :unCheckedValue="0"
                    v-model:checked="record.isShop.value" :disabled="record.disabled" @change="isShopChange(record)" />
                </div>
              </template>
              <template #operation="{ record }">
                <div>
                  <a-popconfirm title="确认取消装车？" ok-text="确认" cancel-text="关闭" @confirm="cancel(record)">
                    <a class="mrg-10" v-show="!completeShow">取消装车</a>
                  </a-popconfirm>
                  <a-popconfirm title="确认退板？" ok-text="确认" cancel-text="关闭" @confirm="backCar(record)">
                    <a v-show="completeShow" style="color:#f00">退板</a>
                  </a-popconfirm>
                  <a-divider type="vertical" />
                  <a v-show="completeShow"
                    @click="transitShow = true, transOrderVehicleId = record.transOrderTruckVehicleId"
                    style="color:#f00">中途到达</a>
                </div>
              </template>
            </a-table>
            <div>
              <img class="img" src="@/assets/images/carrier/u299.png">
              &emsp;
              <span style="font-weight:bold;color:#344563">可安排小车</span>
              <SmallCar @confirmLoad="confirmChoose" />
            </div>
          </div>
        </div>
      </div>
      <div class="right" v-if="false">
        <Record v-if="vehiclesId !== ''" :vehicleList="vehicleList" :vehiclesId="vehiclesId"
          :employeeRealList="employeeList" :orderId="orderId" />
      </div>
    </div>

    <!-- 选择车辆 -->
    <a-modal v-model:visible="chooseCar" title="填写报价" @ok="detail.choose = true, chooseCar = false">
      <a-input v-model:value="price" type="number" />
    </a-modal>
    <!-- 选择司机 -->
    <a-modal width="50%" v-model:visible="deiverShow" title="选择司机" @ok="deiverShow = false">
      <Driver :carrierId="carrierId" @onondriverName="chooseDriver" />
    </a-modal>
    <!-- 承运商运单发送 -->
    <a-modal v-model:visible="sendCarrierShow" title="承运商运单发送" @ok="sendCarrierShow = false" :width="800"
      :destroyOnClose="true">
      <SendCarrier :transTruckId="planId" :lineName="lineName" />
    </a-modal>
    <!-- 输入实际收款方式 -->
    <a-modal v-model:visible="receivables" title="填写收款方式" :confirmLoading="btnLoading" @ok="startCar"
      @cancel="receivables = false, driver.oilCard = 0, driver.bankCard = 0">
      <h1>总金额<span style="color:#f00">{{ totalPrice }}</span></h1>
      <a-input addon-before="油卡" v-model:value="driver.oilCard" type='number' size="large">
        <template #suffix>
          <a-tooltip title="全部油卡">
            <EnterOutlined @click="driver.oilCard = totalPrice, driver.bankCard = 0" style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </template>
      </a-input>
      <a-divider type="vertical" />
      <a-input addon-before="转账" v-model:value="driver.bankCard" type='number' size="large">
        <template #suffix>
          <a-tooltip title="全部转账">
            <EnterOutlined @click="driver.bankCard = totalPrice, driver.oilCard = 0" style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </template>
      </a-input>
    </a-modal>
    <!-- 中途到达 中转点选择 -->
    <a-modal v-model:visible="transitShow" title="请选择中转地点" @ok="transitOperation" :confirmLoading="btnLoading">
      <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" placeholder="请选择中转地点" style="width: 100%"
        @change="transitAddress" />
    </a-modal>
    <!-- 退板 -->
    <a-modal v-model:visible="backVisible" title="退小板-原因" :zIndex="1110" :maskClosable='false' @ok="quitPlan" :confirmLoading="btnLoading">
      <a-input addon-before="原因" v-model:value="reason" size="large" />
    </a-modal>
    <!-- 小车详情 -->
    <a-modal v-model:visible="smallDetailShow" :width="1400" title="小车信息" :destroyOnClose="true">
      <div>
        <TotalDetail :element="smallDetail" :orderId="smallDetail.orderId" :orderVehicleId="smallDetail.orderVehicleId"
          :index="0" />
      </div>
    </a-modal>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs, watch, ref } from 'vue'
import { changeDriver, setAssembled, transDepart, transPlan, transArrive, transfer, transCancel, changeStore, changeCarrier } from '@/api/transport/truck'
import { changeTransType, carUnloadVehicle } from '@/api/transport/transport'
import { vehicleId } from '@/api/transport/vehicle'
import { getCarrier, linePage } from '@/api/transport/transportUse'
import { add as lineAdd } from '@/api/trans/line'
import { transEdit } from '@/api/trans/ops/edit'
import Carrier from '../../../transportCapacity/transportUse/comon/carrierChoose.vue'
import Driver from '@/views/components/driver'
import SendCarrier from '../../../transportCapacity/transportUse/comon/carrierSend.vue'
import { PlusOutlined, EnterOutlined } from '@ant-design/icons-vue'
import Record from '../../../transportCapacity/transportUse/comon/record.vue'
import BigCarDriver from '../../../transportCapacity/transportUse/comon/bigCarDriver.vue'
import SmallCar from '../../../transportCapacity/transportUse/comon/smallCar.vue'
import TotalDetail from '../../../transportCapacity/transportUse/detail/totalDetail.vue'
import { message } from 'ant-design-vue'

export default {
  props: {
    windowShow: Boolean
  },
  components: {
    Carrier,
    Driver,
    Record,
    SmallCar,
    TotalDetail,
    EnterOutlined,
    PlusOutlined,
    SendCarrier,
    BigCarDriver
  },
  setup (props, context) {
    const carDrvierRef = ref(null)
    const record = ref(null)
    const carrierSend = ref(null)
    const state = reactive({
      addShow: false,
      windowShow: false,
      backVisible: false,
      inputShow: false,
      lineShow: false,
      carrierShow: false,
      indeterminate: false,
      transitShow: false,
      btnLoading: false,
      sendCarrierShow: false,
      checkAll: false,
      passByShow: false,
      chooseBig: false,
      chooseSmall: false,
      receivables: false,
      arrangedLoading: false,
      smallDetailShow: false,
      vehicleList: [],
      totalPrice: 0,
      driverName: '',
      reason: '',
      vehiclesId: '',
      carNo: '',
      gpsType: null,
      endCity: null,
      record: {},
      transit: {},
      routeList: [],
      employeeList: [],
      checkedList: [],
      plainOptions: [
        {
          label: '成都',
          value: 1
        },
        {
          label: '北京',
          value: 2
        },
        {
          label: '广州',
          value: 3
        }
      ],
      linePage: {
        total: '',
        current: 1,
        pageSize: 5
      },
      driver: {
        oilCard: 0,
        bankCard: 0
      },
      chooseForm: {},
      unloadings: '',
      // 选择大车 ↓
      carrierId: '',
      price: '',
      lineName: '',
      lineId: '',
      vehicles: {},
      planId: '',
      transTruckId: '',
      transOrderVehicleId: '',
      lineLoading: false,
      passByDivShow: false,
      completeShow: false,
      loading: false,
      tableChange: false,
      chooseCar: false,
      deiverShow: false,
      lineList: [],
      carrierList: [],
      planVehicles: [],
      bigCarTable: [],
      detail: {},
      smallDetail: {},
      startAddress: {},
      endAddress: {},
      bigCar: {},
      columns: [],
      bigCarColumns: [
        {
          title: '车辆类型',
          dataIndex: 'carType.label'
        },
        {
          title: '车牌号',
          dataIndex: 'carNo'
        },
        {
          title: '大车司机',
          dataIndex: 'driver'
        },
        {
          title: '司机电话',
          dataIndex: 'driverMobile'
        },
        {
          title: '剩余空位',
          dataIndex: 'vacancy'
        },
        {
          title: '历史承运次数',
          dataIndex: 'historyFrequency'
        },
        {
          title: '价格',
          dataIndex: 'price'
        },
        {
          title: '报价',
          dataIndex: 'offer'
        },
        {
          title: '选项',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      BIGcolumns: [
        {
          title: '运输类型',
          dataIndex: 'truckType.label'
        },
        {
          title: '大车车牌',
          dataIndex: 'carNo'
        },
        {
          title: '剩余空位',
          dataIndex: 'vacancy'
        },
        {
          title: '历史承运次数',
          dataIndex: 'history'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      BIGcolumnss: [
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true
        },

        {
          title: '电话',
          dataIndex: 'mobile',
          key: 'mobile',
          ellipsis: true
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          key: 'carrierName',
          ellipsis: true
        },
        {
          title: '选择',
          dataIndex: 'operation',
          width: 200,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      arrangedColumns: [
        {
          title: '车牌号',
          slots: {
            customRender: 'vinNo'
          }
        },
        {
          title: '车型',
          dataIndex: 'brand',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '单台价格',
          dataIndex: 'transportFee'
        },
        {
          title: '装车人',
          dataIndex: 'salesman'
        },
        {
          title: '起始地',
          dataIndex: 'address',
          slots: {
            customRender: 'address'
          }
        },
        {
          title: '是否到达门店',
          dataIndex: 'store',
          slots: {
            customRender: 'store'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      // 选择大车 ↑
      vinNo: '',
      ArrangedPagination: {
        current: 1,
        pageSize: 5,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    // 中转地
    const transitAddress = (e, v) => {
      state.transit.province = e[0]
      state.transit.city = e[1]
      state.transit.area = e[2]
    }
    const forwarding = () => {
      state.receivables = true
    }
    // 确认发车
    const startCar = () => {
      if (Number(state.driver.oilCard) + Number(state.driver.bankCard) === Number(state.totalPrice)) {
        state.btnLoading = true
        transDepart(state.planId, state.driver).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.completeShow = true
            state.receivables = false
            vehicleAlready()
          }
        }).finally(()=>{state.btnLoading=false})
      } else message.error('金额填写数目错误,请查看')
    }
    // 到达
    const confirmArrival = () => {
      transArrive(state.planId, {})
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            context.emit('closePlan')
          }
        })
    }
    // 中途到达
    const transitOperation = () => {
      state.btnLoading = true
      transfer(state.planId, {
        totvId: state.transOrderVehicleId,
        stopAddress: state.transit,
        transTruckId: state.planId,
        transOrderVehicleIds: [state.transOrderVehicleId]
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.transitShow = false
          vehicleAlready()
        }
      }).finally(()=>{state.btnLoading=false})
    }
    // 取消任务
    const cancelPlan = () => {
      transCancel(state.planId, {
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          context.emit('closePlan')
        }
      })
    }
    // 卸车地
    const setAddress = (value, selectedOptions) => {
      switch (selectedOptions.length) {
        case 1:
          state.chooseForm.province = value[0]
          state.unloadings = selectedOptions[0].label
          break
        case 2:
          state.chooseForm.city = value[1]
          state.unloadings = selectedOptions[0].label + selectedOptions[1].label
          break
        case 3:
          state.chooseForm.area = value[2]
          state.unloadings = selectedOptions[0].label + selectedOptions[1].label + selectedOptions[2].label
          break
      }
    }
    // 确认装车
    const confirmChoose = e => {
      if (e) {
        transPlan({
          transit: e.transit,
          freight: e.freight,
          transTruckId: state.planId,
          transOrderVehicleIds: e.transOrderVehicleIds,
          isShop: e.isShop
        }).then((res) => {
          if (res.code === 10000) {
            message.success(res.msg)
            vehicleAlready()
          }
        })
      }
    }
    // 取消装车
    const cancel = item => {
      // state.planVehicles.splice(state.planVehicles.findIndex(v => v.vinNo === item.vinNo), 1)
      carUnloadVehicle({ ids: [item.transOrderTruckVehicleId] })
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            vehicleAlready()
          }
        })
    }
    // 线路分页----暂停用
    const lineListChange = page => {
      state.linePage.current = page
      setLine()
    }
    // 获取线路----暂停用
    const setLine = () => {
      state.lineLoading = true
      linePage({
        current: state.linePage.current,
        size: state.linePage.pageSize,
        startCityId: state.startAddress.city,
        endCityId: state.endAddress.city
      }).then(res => {
        if (res.code === 10000) {
          state.lineLoading = false
          state.lineList = res.data.records
          state.linePage.total = res.data.total
          if (state.lineList.length <= 0) {
            state.addShow = true
          } else state.addShow = false
        }
      })
    }
    // 自动添加线路----暂停用
    const addLine = () => {
      lineAdd({ transLine: { type: 1, isEnable: 1, startAddress: state.startAddress, endAddress: state.endAddress } }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          setLine()
        }
      }).catch(err => {
        console.log(err)
      })
    }
    // 确认路线----暂停用
    const confirmLine = item => {
      state.endCity = item.endCity
      state.lineName = item.name
      state.lineId = item.id
      state.lineShow = false
      state.passByDivShow = true
      state.passByShow = true
    }
    // 途经点
    // 添加途经点
    const addVia = (value, selectedOptions) => {
      const DOM = document.querySelectorAll('.ant-tag')
      const aTag = {
        label: selectedOptions[0].label,
        value: value[0]
      }
      const allow = state.routeList.findIndex(item => aTag.value === item.value)
      if (allow < 0) {
        for (let i = 0; i < DOM.length; i++) {
          if (DOM[i].style.borderColor === 'rgb(255, 0, 0)') DOM[i].style.border = '1px solid #d9d9d9'
        }
        state.routeList.push(aTag)
        state.inputShow = false
      } else {
        message.error('添加失败：已经存在该点')
        DOM[allow].style.border = '1px solid #f00'
      }
    }
    // 删除途经点
    const deleteRoute = (item, index) => {
      state.routeList.splice(index, 1)
    }
    // 途经点确认
    const confirmVIA = () => {
      state.inputShow = false
      state.passByShow = false
      setCarrier()
    }
    // 获取承运商
    const setCarrier = () => {
      // 承运商
      getCarrier({
        startAddress: state.startAddress,
        endAddress: state.endAddress
        // transportType: props.type,
        // orderVehicleIds: props.orderVehicleIds
      }).then(res => {
        if (res.code === 10000) {
          state.carrierList = res.data
          state.carrierShow = true
        }
      })
    }
    // 确认承运商
    const carrierData = e => {
      if (e) {
        state.carrierId = e.id
        state.chooseBig = true
        if (state.planId !== '') {
          changeCarrier(state.planId, e.id)
            .then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
              }
            })
        } else {
          setAssembled({
            transLine: {
              carrierId: state.carrierId,
              startAddress: state.startAddress,
              endAddress: state.endAddress,
              passPoint: state.routeList
            }
          }).then(res => {
            if (res.code === 10000) {
              message.success(res.data + res.msg)
              state.planId = res.data // 任务ID
            }
          })
        }
        setTimeout(() => {
          carDrvierRef.value.loadData()
        }, 100)
      }
    }
    // 大车组件数据返回
    const carDriverData = e => {
      if (e) {
        state.transTruckId = e.carrierTruckId
        state.carNo = e.carNo
        state.gpsType = e.gpsType
        if (e.carrierTruckId) {
          changeTransType(state.planId, state.transTruckId, e.id) // e.id 司机ID
            .then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                state.chooseSmall = true
                state.tableChange = true
              }
            })
        } else {
          state.driverName = e.name
          changeDriver(state.planId, e.id)
            .then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                state.chooseSmall = true
                state.tableChange = true
              }
            })
        }
      }
    }

    const loadData = () => {
    }
    const setStartAddress = (value, selectedOptions) => {
      state.startAddress.province = value[0]
      state.startAddress.city = value[1]
      state.startAddress.area = value[2]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.endAddress.province = value[0]
      state.endAddress.city = value[1]
      state.endAddress.area = value[2]
    }
    const onCheckAllChange = e => {
      Object.assign(state, {
        checkedList: e.target.checked ? state.plainOptions : [],
        indeterminate: false
      })
    }
    watch(
      () => state.checkedList,
      val => {
        state.indeterminate = !!val.length && val.length < state.plainOptions.length
        state.checkAll = val.length === state.plainOptions.length
      }
    )
    const choose = record => {
      state.detail = record
      state.chooseCar = true
      state.transTruckId = record.id
      state.bigCar = {
        carType: record.truckType,
        carNo: record.carNo,
        place: record.place,
        price: state.price
      }
    }
    // 选择司机
    const chooseDriver = recode => {
      if (recode) {
        state.tableChange = true
        state.deiverShow = false
        state.bigCarTable[0] = {
          ...state.bigCar,
          price: state.price,
          driver: recode.name,
          driverMobile: recode.mobile,
          historyFrequency: '',
          offer: ''
        }
        // changeTransType(state.planId, state.transTruckId, recode.id)
        //   .then(res => {
        //     if (res.code === 10000) {
        //       message.success(res.msg)
        //       state.chooseSmall = true
        //     }
        //   })
      }
    }
    const arrangedChange = (pag, filters, sorter) => {
      state.ArrangedPagination.current = pag.current
      state.ArrangedPagination.pageSize = pag.pageSize
      vehicleAlready()
    }
    // 是否到达修改
    const isShopChange = record => {
      if (record.isShop.value === 0) record.isShop.label = '否'
      else record.isShop.label = '是'
      changeStore({
        isShop: record.isShop,
        transOrderTruckVehicleId: record.transOrderTruckVehicleId
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          vehicleAlready()
        }
      })
    }
    // 已安排车
    const vehicleAlready = () => {
      state.arrangedLoading = true
      vehicleId(state.planId, ({
        current: state.ArrangedPagination.current,
        size: 5
      })).then(res => {
        if (res.code === 10000) {
          state.vehicleList = []
          state.totalPrice = 0
          res.data.records.forEach(item => {
            state.totalPrice += Number(item.transportFee)
            if (item.isShop === null) {
              item.isShop = {
                label: '否',
                value: 0
              }
              item.disabled = true
            }
            state.vehicleList.push({ id: item.orderVehicleId, vinNo: item.vinNo })
          })
          state.arrangedLoading = false
          state.planVehicles = res.data.records
          if (res.data.records.length > 0) state.vehiclesId = res.data.records[0].orderVehicleId
        }
      })
    }
    // 退板
    const backCar = item => {
      state.record = item
      state.backVisible = true
    }
    // 确认退板
    const quitPlan = () => {
      state.btnLoading = true
      transEdit({
        params: {
          transportTruckId: state.planId,
          totvIds: [state.record.transOrderTruckVehicleId]
        },
        type: {
          value: 1
        },
        reason: state.reason,
        subType: 100
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.reason = ''
          state.backVisible = false
          vehicleAlready()
        }
      }).finally(()=>{state.btnLoading=false})
    }
    const cancelClose = () => {
      state.windowShow = false
      state.passByDivShow = false
      state.carrierShow = false
      state.chooseBig = false
      state.chooseSmall = false
      state.completeShow = false
      state.transit = {}
      state.startAddress = {}
      state.endAddress = {}
      state.routeList = []
      state.planVehicles = []
      state.carNo = ''
      state.planId = ''
      context.emit('statusShow', state.windowShow)
    }

    watch(() => props.windowShow, (newVal, oldVal) => {
      state.windowShow = newVal
      if (state.windowShow === true) loadData()
    })
    onMounted(() => {
      // vehicleAlready()
    })

    return {
      ...toRefs(state),
      record,
      addVia,
      addLine,
      backCar,
      quitPlan,
      forwarding,
      cancelClose,
      carDrvierRef,
      carDriverData,
      deleteRoute,
      isShopChange,
      arrangedChange,
      cancelPlan,
      confirmArrival,
      transitAddress,
      transitOperation,
      vehicleAlready,
      startCar,
      onCheckAllChange,
      setAddress,
      setStartAddress,
      setEndAddress,
      confirmChoose,
      carrierSend,
      confirmVIA,
      carrierData,
      confirmLine,
      chooseDriver,
      choose,
      setCarrier,
      loadData,
      cancel,
      setLine,
      lineListChange
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

.adsss {
  display: flex;
  width: 20px;
  padding: 0 10px;
}

:deep(.ant-pagination .mini) {
  display: flex;
  justify-content: flex-end;
}
</style>
