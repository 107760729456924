<template>
  <div style="position: relative;">
    <div class="data-paginate" v-show="statisticsData.length > 10">
      <LeftCircleFilled style="font-size:20px;color:#B0B8BF;cursor: pointer;" @click="leftMove" />
      <RightCircleFilled style="font-size:20px;color:#B0B8BF;cursor: pointer;margin-left:5px" @click="rightMove" />
    </div>
    <!-- 头部信息统计模块 -->
    <div class="shop-plant">
      <div class="data-statistics" v-show="statisticsData.length > 0">
        <div v-for="(item, index) in statisticsData" :key="index" class="data-statistics-content">
          <div class="statistics-content" :style="{ flex: index === statisticsData.length - 1 ? '1' : '' }"
            @click="statisticsSearch(item, index)">
            <div class="statistics-title">{{ item.taskStatus?.label }}</div>
            <div class="data-statistics-data">{{ item.num }}</div>
          </div>
          <a-divider type="vertical" style="height: 60px; background-color: #eee" />
        </div>
      </div>
    </div>
    <a-divider style="height: 10px;margin-top:0; background-color: #EEEEEE;border:none" />
    <GlobalTable :loading="loading">
      <!-- 搜索栏 -->
      <template #headerSearch>
        <a-space>
          <a-input v-model:value="searchForm.vinNo" placeholder="车牌号" />
          <a-input v-model:value="searchForm.arranger" placeholder="派单人" />
          <a-cascader style="width:150px" :allowClear="true" :show-search="{ filter }" :options="$store.state.app.PClist"
            v-model:value="searchForm.start" :changeOnSelect="true" @change="setStartAddress" placeholder="选择始发地" />
          <a-cascader style="width:150px" :allowClear="true" :show-search="{ filter }" :options="$store.state.app.PClist"
            v-model:value="searchForm.end" :changeOnSelect="true" @change="setEndAddress" placeholder="选择目的地" />
          <div class="operate-btn">
            <a-button class="header-search-btn" @click="searchData" :loading="loading">搜索</a-button>
            <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
          </div>
        </a-space>
        <!-- <a-form-item label="任务状态">
            <a-select ref="select" v-model:value="searchForm.taskStatus" style="width: 120px">
              <a-select-option :value="-2">已取消</a-select-option>
              <a-select-option :value="9">已完成</a-select-option>
              <a-select-option :value="-1">已拒绝</a-select-option>
            </a-select>
          </a-form-item> -->
      </template>
      <!-- 操作按钮 -->
      <!-- <template #operateBtn>
        <a-button>按钮</a-button>
      </template> -->
      <!-- 表格主体 -->
      <div class="table-data shop-table">
        <a-table ref="tableRef" :columns="columns" :row-key="record => record.taskId" :data-source="shopPlantList"
          bordered :scroll="{ x: 2600, y: 400 }" :pagination="false" :loading="loading" @change="handleTableChange">
          <template #operation="{ record }">
            <div>
              {{ record.id }}
              <a-dropdown>
                <a class="ant-dropdown-link" @mouseenter="detailData = record">
                  操作
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <a @click="transportShow = true, shopPlantStatus = record.taskStatus.value">详情</a>
                    </a-menu-item>
                    <a-menu-item v-if="record.taskStatus?.value === 0">
                      <a-popconfirm title="确认接单" ok-text="确认" cancel-text="取消" @confirm="order(record, 1)">
                        <a>接单</a>
                      </a-popconfirm>
                    </a-menu-item>
                    <a-menu-item v-if="record.taskStatus?.value === 0">
                      <a-popconfirm ok-text="确认" cancel-text="取消" @confirm="order(record, 0)">
                        <template #title>
                          <div>
                            <div>确认拒绝</div>
                            <div><a-textarea v-model:value="refuseRemark" placeholder="请填写拒绝原因" :rows="4" /></div>
                          </div>
                        </template>
                        <a>拒单</a>
                      </a-popconfirm>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
          <template #taskStatus="{ record }">
            <div>
              <span v-show="record.taskStatus.value !== -1"
                :style="{ color: record.taskStatus.value === -2 ? '#d9d9d9' : statusColor[record.taskStatus.value] }">{{
                  record.taskStatus.label }}</span>
              <span v-show="record.taskStatus.value === -1" style="color:#f00">{{ record.taskStatus.label }}</span>
            </div>
          </template>
          <template #brand="{ record }">
            <div>
              <span v-show="record.brand && record.brand !== '' && record.model && record.model !== ''">{{ record.brand +
                '-' +
                record.model }}</span>
              <span v-show="!record.brand && !record.model">暂无车型信息</span>
            </div>
          </template>
          <template #remark="{ record }">
            <div>
              <div class="remark-content">
                <span v-show="record.remark?.split('').length < 39">{{ record.remark }}</span>
                <a-tooltip>
                  <template #title>{{ record.remark }}</template>
                  <span v-show="record.remark?.split('').length > 39">{{ record.remark + record.remark?.split(',').length
                  }}</span>
                </a-tooltip>
              </div>
            </div>
          </template>
          <template #prescription="{ record }">
            <div>
              <a>查看>></a>
            </div>
          </template>
          <template #report="{ record }">
            <div @mousemove="detailData = record">
              <a @click="abnormalReportShow = true">查看明细>></a>
            </div>
          </template>
        </a-table>
        <div style="text-align:right;margin-top:10px"><a-button @click="nextPage"
            :disabled="shopPlantList.length >= pagination.total">下一页</a-button></div>
      </div>
    </GlobalTable>
    <!-- 异常列表(明细) -->
    <a-modal v-model:visible="abnormalReportShow" title="异常列表" footer='' width="50%">
      <AbnormalReport :vinNo="detailData.vinNo" :windowShow="abnormalReportShow" :vehicleDetail="detailData"
        @statusShow="abnormal" />
    </a-modal>
    <!-- 发运任务(详情) -->
    <TransportDetail :windowShow="transportShow" :type="1" :shopPlantStatus="shopPlantStatus" :taskDetail="detailData"
      @statusShow="transDetail" />
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { shopPlantStatistics, getShopPlantList, shopPlantReceiving } from '@/api/store/shopPlantList'
import GlobalTable from '@/components/GlobalTable'
import AbnormalReport from '../comon/abnormalReport'
import TransportDetail from '../smallVehicleOperate/transportDetail.vue'

export default {
  components: {
    GlobalTable,
    AbnormalReport,
    TransportDetail
  },
  setup () {
    const state = reactive({
      startIndex: 0,
      refuseRemark: '',
      vEle: null,
      timerId: null,
      loading: false,
      globalIndex: null,
      taskStatus: null,
      shopPlantStatus: null,
      transportShow: false,
      abnormalReportShow: false,
      statusColor: {
        9: '#0C8226', // 已完成
        1: '#3470ff', // 已接单
        2: '#0066cc', // 处理中
      },
      searchForm: {
        vinNo: '',
        arranger: '',
        startAddress: {},
        endAddress: {}
      },
      mirrorSearchForm: {},
      detailData: { vinNo: '' },
      pagination: {
        current: 1,
        pageSize: 10,
        total: null,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      dataSource: [],
      shopPlantList: [],
      mirrorShopList: [],
      statisticsData: [],
      columns: [
        {
          title: '操作',
          align: 'center',
          width: '3%',
          slots: {
            customRender: 'operation'
          }
        },
        {
          title: '车牌号',
          align: 'center',
          width: '3%',
          dataIndex: 'vinNo'
        },
        {
          title: '当前状态',
          align: 'center',
          width: '3%',
          slots: {
            customRender: 'taskStatus'
          }
        },
        {
          title: '派单任务',
          align: 'center',
          width: '3%',
          dataIndex: 'taskName'
        },
        {
          title: '派单价',
          align: 'center',
          width: '3%',
          dataIndex: 'taskAmt'
        },
        {
          title: '派单时间',
          align: 'center',
          width: '3%',
          dataIndex: 'scheduleTime'
        },
        {
          title: '派单人',
          align: 'center',
          width: '3%',
          dataIndex: 'arranger'
        },
        {
          title: '起运地',
          align: 'center',
          width: '3%',
          dataIndex: 'lineStart'
        },
        {
          title: '目的地',
          align: 'center',
          width: '3%',
          dataIndex: 'lineEnd'
        },
        {
          title: '接单时间',
          align: 'center',
          width: '3%',
          dataIndex: 'receivingTime'
        },
        {
          title: '车型',
          align: 'center',
          width: '3%',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '车辆状况',
          align: 'center',
          width: '3%',
          dataIndex: 'status.label'
        },
        {
          title: '对接人',
          align: 'center',
          width: '3%',
          dataIndex: 'dockingPerson'
        },
        {
          title: '对接人电话',
          align: 'center',
          width: '3%',
          dataIndex: 'dockingMobile'
        },
        {
          title: '备注',
          align: 'center',
          width: '3%',
          dataIndex: 'remark',
          slots: {
            customRender: 'remark'
          }
        },
        {
          title: '上报',
          align: 'center',
          fixed: 'right',
          width: '2%',
          slots: {
            customRender: 'report'
          }
        },
      ]
    })

    onMounted(() => {
      state.mirrorShopList = JSON.stringify(state.dataSource)
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      let DOM = document.querySelector('.shop-table .ant-table-body')
      // DOM.addEventListener('scroll', e => {
      //   if (e.target.scrollTop > 0) {
      //     clearTimeout(state.timerId)
      //     state.timerId = setTimeout(() => {
      //       if (e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < 10) {
      //         if (state.shopPlantList.length < state.pagination.total) state.pagination.current++, loadData()
      //       }
      //       state.timerId = null
      //     }, 500)
      //   }
      // })
    })
    const loadData = (type) => {
      state.loading = true
      getShopPlantList({
        taskStatus: state.taskStatus,
        ...state.searchForm,
        current: state.pagination.current,
        size: 10
      }).then(res => {
        if (res.code === 10000) {
          if (res.data) {
            state.pagination.total = res.data.total
            state.dataSource = JSON.stringify(res.data.records)
            JSON.parse(state.dataSource).forEach(item => {
              state.shopPlantList.push(item)
            })
            setTimeout(() => {
              if (state.shopPlantList.length >= state.pagination.total) {
                state.pagination.current--
              }
            }, 1000)
          }
        }
      }).finally(() => {
        state.loading = false
      })
      if (!type) getStatis()
    }
    const getStatis = () => {
      shopPlantStatistics({}).then(res => {
        if (res.code === 10000) {
          if (res.data) {
            state.statisticsData = res.data
          }
        }
      })
    }
    // 异常列表回传
    const abnormal = e => {
      state.abnormalReportShow = false
    }
    // 详情回传
    const transDetail = e => {
      if (e.orderVehicleId) state.taskDetail = e
      if (e !== undefined && !e.orderVehicleId) state.transportShow = e
      else state.transportShow = true
    }
    // 接拒单
    const order = (record, num) => {
      let status = true
      if (num === 0) status = state.refuseRemark != ''
      if (status === true) {
        shopPlantReceiving({
          remark: state.refuseRemark != '' ? state.refuseRemark : '',
          taskId: record.taskId,
          type: num
        }).then(res => {
          if (res.code === 10000) {
            state.shopPlantList = []
            loadData()
            message.success(res.msg)
          }
        })
      } else message.error('请填写拒绝原因')
    }
    const leftMove = () => {
      let DOM = document.querySelector('.shop-plant .data-statistics')
      let left = Number(DOM.style.left.replace('vw', ''))
      if (left < 0) {
        DOM.style.left = left + 8 + 'vw'
      }
    }
    const rightMove = () => {
      let DOM = document.querySelector('.shop-plant .data-statistics')
      let left = Number(DOM.style.left.replace('vw', ''))
      DOM.style.left = left - 8 + 'vw'
    }
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startAddress = {}
      state.searchForm.startAddress.province = value[0]
      state.searchForm.startAddress.city = value[1]
      state.searchForm.startAddress.area = value[2]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endAddress = {}
      state.searchForm.endAddress.province = value[0]
      state.searchForm.endAddress.city = value[1]
      state.searchForm.endAddress.area = value[2]
    }
    const searchData = () => {
      state.shopPlantList = []
      state.pagination.current = 1
      loadData()
      // state.loading = true
      // state.shopPlantList = JSON.parse(state.dataSource)
      // // 查询筛选 ↓
      // for (const key in state.searchForm) {
      //   if (state.searchForm[key] == '') continue
      //   if (state.searchForm[key].value === null) continue // 选择框未选择则跳出本次循环并开始下一循环
      //   state.shopPlantList = JSON.parse(JSON.stringify(state.shopPlantList)).filter(item => {
      //     console.log(item);
      //     return JSON.stringify(state.searchForm[key]) == JSON.stringify(item[key])
      //   })
      // }
      // setTimeout(() => {
      //   console.log(state.shopPlantList.filter(item => item.vinNo == state.searchForm.vinNo));
      //   fictitiousScroll()
      //   state.loading = false
      // }, JSON.parse(state.mirrorShopList).length * 2 < 800 ? 800 : JSON.parse(state.mirrorShopList).length * 2)
    }
    const resetData = () => {
      state.shopPlantList = []
      state.pagination.current = 1
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      loadData()
    }
    const statisticsSearch = (item, index) => {
      let DOMS = document.querySelectorAll('.shop-plant .statistics-content')
      state.shopPlantList = []
      if (state.globalIndex !== index) {
        state.globalIndex = index
        for (let i = 0; i < DOMS.length; i++) {
          DOMS[i].style.color = '#344563'
          DOMS[i].style.background = ''
          if (i === index) {
            DOMS[index].style.color = '#fff'
            DOMS[index].style.background = 'rgba(0,102,204,.8)'
            DOMS[index].style.borderRadius = '8px'
          }
        }
        state.taskStatus = item.taskStatus.value
        state.pagination.current = 1
        loadData(1)
      } else {
        DOMS[state.globalIndex].style.color = '#344563'
        DOMS[state.globalIndex].style.background = ''
        state.taskStatus = null
        state.globalIndex = null
        state.pagination.current = 1
        loadData(1)
      }
    }
    const nextPage = () => {
      state.pagination.current++
      loadData()
    }

    return {
      ...toRefs(state),
      leftMove,
      rightMove,
      nextPage,
      searchData,
      resetData,
      loadData,
      getStatis,
      order,
      abnormal,
      setStartAddress,
      setEndAddress,
      statisticsSearch,
      transDetail
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

.remark-content {
  width: 80%;
  max-height: 80px;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>