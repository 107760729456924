import { postBodyRequest, getRequest } from '@/utils/axios'

export function checkWarehousePage (params) {
  return postBodyRequest('/trans/order/vehicle/stock/inventory/page', params)
}
export function newCheckWarehousePage (params) {
  return postBodyRequest('/trans/order/vehicle/stock/inventory/list', params)
}

export function check (params) {
  return postBodyRequest('/trans/order/vehicle/stock/inventory', params)
}

// 详情
export function checkDetail (params) {
  return postBodyRequest('/trans/order/vehicle/stock/warehousing/latest', params)
}

// 详情
export function transDetail (orderVehicleId) {
  return postBodyRequest(`/trans/order/vehicle/${orderVehicleId}/trans/last`, {})
}

export function lookImgs (id) {
  return getRequest(`/trans/order/vehicle/stock/inventory/${id}/file`, {})
}

export function inventoryStatistics (params) {
  return postBodyRequest('/trans/order/vehicle/stock/inventory/statistics', params)
}

export function changeShop (params) {
  return postBodyRequest('/trans/order/vehicle/stock/change/shop', params)
}
export function getAddressData (params) {
  return postBodyRequest('/trans/order/vehicle/stock/app/inventory/stats', params)
}
