<template>
  <a-modal :visible="manualSchedulingShow" title="派单" footer="" width="70%" @cancel="cancelClose"
    :maskClosable="false" :bodyStyle="{ padding: '0', paddingLeft: '10px' }">
    <div style="display:flex;width:100%">
      <div class="left-content">
        <!-- 发运车辆 -->
        <div>
          <div class="content-title">发运车辆</div>
          <div class="transport">
            <div class="transport-choose" v-for="item in recordsList" :key="item.orderId">
              <span>{{ item.vinNo }}&emsp;{{ item.startAddress + '————' + item.endAddress }}</span>
              <!-- <a>取消装车</a> -->
            </div>
          </div>
        </div>
        <!-- 选择发运任务 -->
        <div>
          <div class="content-title">选择发运任务</div>
          <div class="transport">
            <a-radio-group v-model:value="taskIds">
              <a-radio :value="item.taskIds" v-for="(item, index) in taskList" :key="index"
                @click.stop="planChange(item)">
                <span class="choose-title">{{ item.taskName + '：' }}</span>
                <span class="choose-content" v-if="item.startAddress == '' && item.endAddress == ''">暂无数据</span>
                <span class="choose-content" v-else>{{ item.startAddress + '——' + item.endAddress }}</span>
              </a-radio>
            </a-radio-group>
            <div class="operate-btn">
              <a-button class="confirm-btn" type="primary" @click="choosePlant">确定</a-button>
            </div>
          </div>
        </div>
        <!-- 选择发运线路 -->
        <div v-show="taskArr.length > 0">
          <div class="content-title">发运线路</div>
          <div class="transport" style="padding:10px;display:flex;justify-content: space-between;">
            <span v-show="lineData.name">{{ lineData.name }}</span>
            <span>
              <span v-show="lineData.startAddress !== ''">
                {{ lineData.startAddress || recordsList[0]?.startAddress }} ————
              </span>
              <span v-show="lineData.endAddress !== ''">
                {{ lineData.endAddress || recordsList[0]?.endAddress }}
                <a-cascader v-if="changeLineShow && lineChooseShow && manualSchedulingShow"
                  :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true"
                  @change="setEndAddress" placeholder="请输入终点" />
              </span>
            </span>
            <span v-show="lineChooseShow"><a @click="confirmLine">{{ changeLineShow ? '确认线路' : '更换线路' }}</a></span>
          </div>
        </div>

        <!-- 选择承运商 -->
        <div v-show="taskArr.length > 0">
          <div class="content-title">承运商</div>
          <div class="transport" style="padding:10px;display:flex;justify-content: space-between;">
            <span>{{ carrier.carrierName }}</span>
            <a @click="carrierShow = !carrierShow">{{ carrier.carrierName ? '更换承运商' : '选择承运商' }}</a>
          </div>
          <div v-show="carrierShow">
            <Carrier ref="carrierRef" :carrierId="carrierId" @confirmCarrier="confirmCarrier" />
          </div>
        </div>
        <!-- 设置运费 -->
        <div v-show="taskArr.length > 0 && carrier.carrierName">
          <div class="content-title" v-if="taskArr.length > 0">
            <span>运费</span>
            <span style="color:#0066CC">单台总费用：￥{{ taskArr.reduce((t, v) => t + v.amt, 0) }}</span>
          </div>
          <div class="transport"
            style="padding:10px 10px 0 10px;display:flex;justify-content: space-between;flex-wrap: wrap;">
            <div class="set-place" v-for="(item, index) in taskArr" :key="index">
              <span v-show="item.type.value == 0">验车：</span>
              <span v-show="item.type.value == 1">提车：</span>
              <span v-show="item.type.value == 2">干线：</span>
              <span v-show="item.type.value == 3">送车：</span>
              <div class="input-div">
                <div class="input-data">
                  <span>运费：</span>
                  <a-input-number v-show="item != 2" v-model:value="item.amt" :min="0" style="width:100px" />
                  <a-input v-show="item == 2" v-model:value="value" style="width:100px;">
                    <template #addonAfter>
                      <setting-outlined @click="remarkShow = !remarkShow" />
                    </template>
                  </a-input>
                </div>
                <div class="input-data">
                  <span>油卡：</span>
                  <a-input-number v-model:value="item.oilAmt" :min="0" style="width:100px" />
                </div>
              </div>
            </div>
            <!-- 备注 -->
            <div v-show="remarkShow" class="set-place" style="margin-top:15px;width:100%">
              <span>&emsp;&emsp;&emsp;</span>
              <div class="input-div" style="flex:1">
                <div class="input-data" style="flex:1">
                  <span>备注</span>
                  <a-input v-model:value="value" style="flex:1" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 备注 -->
        <div v-show="taskArr.length > 0 && carrier.carrierName">
          <div class="content-title">
            <span>备注</span>
          </div>
          <a-input v-model:value="remark" />
        </div>
        <!-- 确认派单 -->
        <div v-show="taskArr.length > 0 && carrier.carrierName" style="margin:15px 0"><a-button class="confirm-btn"
            type="primary" @click="confirmDispatch">确定</a-button>
        </div>
      </div>
      <div class="right" style="margin-left:0;padding-left:10px;border-left: 1px solid #ccc;flex:1">
        <Record v-if="detail.orderVehicleId" :vehicleList="[]" :vehiclesId="detail.orderVehicleId"
          :orderId="detail.orderId" />
      </div>
    </div>
  </a-modal>
  <!-- 中转地 -->
  <a-modal v-model:visible="transitChooseShow" title="是否选择中转地" @ok="transitConfirm"
    @cancel="transitChooseShow = false, whether = null" :destroyOnClose="true">
    <a-radio-group v-model:value="whether">
      <a-radio :value="1">是</a-radio>
      <a-radio :value="2">否</a-radio>
    </a-radio-group>
    <a-divider />
    <a-cascader v-if="whether === 1" :options="$store.state.app.PClist" :show-search="{ filter }" :changeOnSelect="true"
      @change="setAddress" placeholder="中转地选择" />
    <a-input v-model:value="arriveStore.details" placeholder="输入详细地址" />
  </a-modal>
  <ReachAddress :windowShow="reachChooseShow" @address="changeEndAddress" />
  <PlantDetail :windowShow="monitorShow" :vehicleDetailData="vehicleDetailData" @statusShow="getMonitorData" />
</template>

<script>
import { reactive, toRefs, ref, watch, onMounted } from 'vue'
import { batchManualOperate, dispatchArrange, getDispatchList } from '@/api/transport/transport'
import { SettingOutlined } from '@ant-design/icons-vue'
// import { transPlan, transDepart, transfer, setAssembled, transArrive, advanceLoading, getAdvance, getTransData, changeCarrier, changeDriver } from '@/api/transport/truck'
import { lineChoose, linePage } from '@/api/transport/transportUse'
import { message } from 'ant-design-vue'
import Record from '@/views/transportCapacity/transportUse/comon/record.vue'
import Carrier from '../comon/carrierMod.vue'
import ReachAddress from '../comon/reachAddress.vue'
import PlantDetail from './plantDetail.vue'
import Inspector from '@/views/components/inspector'

export default {
  props: {
    manualSchedulingShow: Boolean,
    recordList: Array,
    detail: Object,
    type: Number // 派单0 调度1
  },
  components: {
    Record,
    Carrier,
    SettingOutlined,
    PlantDetail,
    Inspector,
    ReachAddress
  },
  setup (props, context) {
    const carrierRef = ref(null)
    const state = reactive({
      lineShow: false,
      monitorShow: false,
      bigCarShow: false,
      recordShow: false,
      carrierShow: false,
      remarkShow: false,
      lineLoading: false,
      lineChooseShow: false,
      changeLineShow: false,
      reachChooseShow: false,
      manualSchedulingShow: false,
      transitChooseShow: false,
      detailData: props.detail,
      arriveStore: {
        city: '',
        area: '',
        province: '',
        details: ''
      }, // 中转地
      startAddressData: {},
      endAddressData: {},
      vehicleDetailData: {},
      carrier: {},
      planData: {},
      taskDetail: {},
      linePage: {
        current: 1
      },
      transportType: null,
      whether: null,
      lineData: {},
      lineList: [],
      endId: [],
      startId: [],
      orderVehicleIds: [],
      recordsList: [],
      taskList: [],
      planId: '',
      taskIds: '',
      remark: '',
      taskArr: [],
      carrierId: '',
      dockingMobile: '',
      dockingPerson: ''
    })
    const cancelClose = () => {
      state.manualSchedulingShow = false
      state.taskArr = []
      state.lineData = {}
      state.carrier = {}
      state.carrierId = ''
      state.lineShow = false
      context.emit('statusShow', state.manualSchedulingShow)
    }
    watch(() => props.manualSchedulingShow, (newVal, oldVal) => {
      if (newVal !== false) {
        state.detailData = props.detail
        getDispatchList(state.detailData.orderVehicleId).then(res => {
          if (res.code === 10000) {
            res.data.completeness = state.detailData.completeness
            res.data.orderVehicleId = state.detailData.orderVehicleId
            state.vehicleDetailData = res.data
            // console.log('派单任务列表', state.dispatchDetail.tasks);
          }
        })
        state.recordsList = [state.detailData]
        batchManualOperate(state.detailData.orderVehicleId).then(res => {
          if (res.code === 10000) {
            state.taskList = res.data
            if (state.taskList.length <= 0) {
              message.info('该车辆无未安排任务')
              // setTimeout(() => {
              //   state.monitorShow = true
              // }, 500)
              setTimeout(() => {
                cancelClose()
              }, 1000)
            }
          }
        })
      }
    })
    watch(() => props.recordList, (newVal, oldVal) => {
      if (newVal !== oldVal) state.recordsList = newVal
    })
    onMounted(() => {
      setTimeout(() => {
        state.recordShow = true
      }, 1000)
    })
    // 选择发运任务
    const planChange = (item) => {
      state.taskArr = []
      state.lineData = {}
      state.carrier = {}
      state.carrierId = ''
      state.lineShow = false
      state.planData = item
    }
    // 确认发运任务
    const choosePlant = () => {
      state.taskArr = []
      state.taskIds.split(',').forEach(item => {
        state.taskArr.push({
          amt: 0,
          oilAmt: 0,
          type: { value: Number(item) }
        })
      })
      if (state.taskIds.split(',').includes('2') || state.taskIds.split(',').includes('3')) state.reachChooseShow = true
      setTimeout(() => {
        state.transitChooseShow = state.taskIds.split(',').includes('2')
        state.lineChooseShow = state.taskIds.split(',').includes('2')
      }, 100)
      state.lineData.startAddress = state.planData.startAddress
      state.lineData.endAddress = state.planData.endAddress
      // getWhether()
    }
    // 起始地更改---停用
    const setStartAddress = (value, selectedOptions) => {
      state.startAddressData.province = Number(value[0])
      state.startAddressData.city = Number(value[1])
      state.startAddressData.area = Number(value[2])

    }
    // 提车任务修改目的地地址
    const changeEndAddress = e => {
      if (e.addressDetails) {
        state.lineData.endAddress = e.address + e.addressDetails
        state.dockingPerson = e.dockingPerson
        state.dockingMobile = e.dockingMobile
        state.reachChooseShow = false
      } else state.reachChooseShow = false
    }
    // 获取推荐线路---停用
    const getLine = () => {
      state.lineLoading = true
      linePage({ // 获取路线
        current: state.linePage.current,
        size: state.linePage.pageSize,
        startCityId: state.lineData.startCityId,
        endCityId: state.lineData.endCityId
      }).then(res => {
        if (res.code === 10000) {
          state.linePage.total = res.data.total
          state.lineList = res.data.records
          state.lineLoading = false
        }
      })
    }
    // 获取默认路线---停用
    const getWhether = () => {
      if (state.detailData.orderVehicleId) {
        state.lineLoading = true
        lineChoose({
          transportType: state.transportType,
          orderVehicleIds: [state.detailData.orderVehicleId]
        }).then(res => {
          if (res.code === 10000) {
            state.lineData = res.data
            state.lineLoading = false
            state.lineShow = true
          }
        }).catch(err => { console.log(err) })
      }
    }
    // 自定义搜索线路---停用
    const searchLine = () => {
      state.lineShow = true
      state.lineLoading = true
      linePage({ // 获取路线 m
        current: state.linePage.current,
        size: state.linePage.pageSize,
        startProvinceId: state.startAddressData.province,
        startAreaId: state.startAddressData.area,
        startCityId: state.startAddressData.city,
        endProvinceId: state.endAddressData.province,
        endCityId: state.endAddressData.city,
        endAreaId: state.endAddressData.area
      }).then(res => {
        if (res.code === 10000) {
          state.linePage.total = res.data.total
          state.lineList = res.data.records
          state.lineLoading = false
        }
      })
    }
    // 线路更换目的地
    const setEndAddress = (value, selectedOptions) => {
      state.endAddressData.province = Number(value[0])
      state.endAddressData.city = Number(value[1])
      state.endAddressData.area = Number(value[2])
      state.endAddressData.provinceLabel = selectedOptions[0]?.label || ''
      state.endAddressData.cityLabel = selectedOptions[1]?.label || ''
      state.endAddressData.areaLabel = selectedOptions[2]?.label || ''
    }
    // 确认路线
    const confirmLine = item => {
      state.changeLineShow = !state.changeLineShow
      state.lineData.endAddress = state.endAddressData.provinceLabel + state.endAddressData.cityLabel + state.endAddressData.areaLabel
    }

    // 选择承运商
    const confirmCarrier = e => {
      if (e) {
        state.carrierId = e.id
        state.carrier = e
        state.carrierShow = false
      }
    }

    // 确认派单
    const confirmDispatch = async () => {
      let submit = {
        orderId: state.detailData.orderId,
        orderVehicleId: state.detailData.orderVehicleId,
        carrierId: state.carrierId,
        remark: state.remark,
        carrierName: state.carrier.carrierName,
        taskFees: state.taskArr,
        taskIds: state.taskIds,
        lineStart: state.lineData.startAddress,
        lineEnd: state.lineData.endAddress,
        taskType: state.carrier.isSettled?.value === 1 ? 6 : 1, // 人工为1；外派为6
        remark: state.remark,
        dockingPerson: state.dockingPerson,
        dockingMobile: state.dockingMobile,
        unloadingAddress: state.arriveStore
        // taskIds:''
      }
      await dispatchArrange(submit).then(res => {
        if (res.code === 10000) {
          submit = {}
          state.vehicleDetailData.taskId = res.data
          state.lineShow = false
          state.monitorShow = true
          cancelClose()
          message.success('派单成功')
        }
      })
    }
    // 停用！！！
    const monitorDetail = e => {
      if (e.orderVehicleId) state.taskDetail = e
      if (e !== undefined && !e.orderVehicleId) state.monitorShow = e
      else state.monitorShow = true
    }

    const getMonitorData = e => {
      if (e === false) state.monitorShow = e
      else state.monitorShow = true
    }
    // 选择中转地
    const setAddress = (e, v) => {
      switch (e.length) {
        case 0:
          state.arriveStore.province = e[0]
          break
        case 2:
          state.arriveStore.city = e[1]
          break
        case 2:
          state.arriveStore.area = e[2]
          break
      }
    }
    // 确认---中转地
    const transitConfirm = () => {
      state.transitChooseShow = false
    }
    return {
      ...toRefs(state),
      carrierRef,
      planChange,
      choosePlant,
      getLine,
      changeEndAddress,
      confirmCarrier,
      getWhether,
      confirmLine,
      searchLine,
      cancelClose,
      confirmDispatch,
      setStartAddress,
      setEndAddress,
      getMonitorData,
      monitorDetail,
      transitConfirm,
      setAddress,
    }
  }
}
</script>

<style lang="less" scoped>
a {
  color: #0066CC;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #eee;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(185, 183, 183);
  border-radius: 10px;
}

:deep(.ant-input-number) {
  border: none !important;
}

:deep(.ant-input-group-addon) {
  border: none;
  background-color: #fff;
}

:deep(.ant-input-group-wrapper) {
  .ant-input {
    border: none !important;
  }
}

.ant-input-number:outline {
  border: none !important;
}


:deep(.ant-radio-wrapper:hover .ant-radio-inner, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner) {
  border-color: #344563 !important;
}

:deep(.ant-radio-wrapper-checked) {
  .ant-radio-inner {
    border-color: #344563;
    background-color: #344563;
  }

  .ant-radio-inner::after {
    background-color: #fff !important;
  }
}

:deep(.ant-radio-group) {
  display: flex;
  flex-direction: column !important;
}

:deep(.ant-radio-wrapper) {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-left: 0 !important;
}

.left-content {
  width: 65%;
  padding-right: 15px;
  max-height: 90%;
  overflow-y: scroll;
}

.line-div {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 12px;
  background-color: #fafafb;

  .confirm {
    display: none;
  }
}

.line-div:hover .confirm {
  display: block;
}

.set-place {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .input-div {
    display: flex;
    align-items: center;

    .input-data {
      margin-right: 10px;
      padding-left: 5px;
      display: flex;
      align-items: center;
      border: 1px solid #ccc;

      :deep(.ant-input) {
        border: none !important;
      }
    }
  }
}

.operate-btn {
  margin: 10px;
  display: flex;
  justify-content: flex-end;
}

.ant-btn {
  background-color: #F0F2F5;
}

:deep(.ant-btn-primary) {
  color: #fff;
  border-radius: 0;
  border-color: #344563;
  background-color: #344563;
}

.ant-btn-primary:hover {
  color: #000;
  background-color: #fff;
}

.content-title {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  color: #344563;
  font-weight: 700;
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
}

.transport {
  border: 1px solid #eee;
  padding-top: 10px;

  .transport-choose {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .transport-choose:last-child {
    padding-top: 0 !important;
  }

  .choose-title {
    font-size: 12px;
    color: #344563;
    font-weight: 700;
  }

  .choose-content {
    font-size: 12px;
    color: #6B778C;
  }
}
</style>