<template>
  <div class="choose-div">
    <div>
      <a-tag v-for="(item, index) in routeList" :key="index">
        {{ item.label }}
        <a @click="deleteRoute(item, index)" style="color: #f00">X</a>
      </a-tag>
      <a-cascader v-if="inputShow" style="width:80px;height:22px" size="small" :options="$store.state.app.OnlyCityList"
        :show-search="{ filter }" :changeOnSelect="true" @change="addVia" placeholder="请选择" />
      <a-tag v-show="!inputShow" @click="inputShow = true" style="background: #fff; border-style: dashed">
        <plus-outlined />
        添加
      </a-tag>
    </div>
    <div class="confirm-btn"><a-button type="primary" size="small" @click="confirmChoose">确定</a-button></div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
  setup (props, context) {
    const state = reactive({
      routeList: [],
      inputShow: false,
    })
    // 添加途经点
    const addVia = (value, selectedOptions) => {
      const DOM = document.querySelectorAll('.ant-tag')
      const aTag = {
        label: selectedOptions[0].label,
        value: value[0]
      }
      const allow = state.routeList.findIndex(item => aTag.value === item.value)
      if (allow < 0) {
        for (let i = 0; i < DOM.length; i++) {
          if (DOM[i].style.borderColor === 'rgb(255, 0, 0)') DOM[i].style.border = '1px solid #d9d9d9'
        }
        state.routeList.push(aTag)
        state.inputShow = false
      } else {
        message.error('添加失败：已经存在该点')
        DOM[allow].style.border = '1px solid #f00'
      }
    }
    // 删除途经点
    const deleteRoute = (item, index) => {
      state.routeList.splice(index, 1)
    }
    // 途经点确认
    const confirmChoose = () => {
      context.emit('passingPointData', state.routeList)
    }
    return {
      ...toRefs(state),
      addVia,
      deleteRoute,
      confirmChoose
    }
  }
}
</script>

<style lang="less" scoped>
.choose-div{
  position: relative;
  padding-right:10%;
  .confirm-btn{
    position: absolute;
    right:0%;
    bottom:0;
  }
}
</style>