<template>
  <div class="fz-14">
    <template v-if="item.subTitle">
      <div class="m-t1 m-b1"><span @click="openList">
        <CountTo color="#0066CC" fontSize="16px" :endVal="item.num" />
        &nbsp;{{ item.unit }}</span>{{item.title}}</div>
      <div style="color:#999">{{ item.subTitle }}</div>
      <div class="line"></div>
    </template>
    <template v-if="!item.subTitle">
      <div class="m-t1 m-b1 flex ju-between">{{item.title}}<span @click="openList">
        <CountTo color="#0066CC" fontSize="16px" :endVal="item.num" />&nbsp;{{ item.unit }}</span></div>
    </template>
    <div class="flex wrap">
      <div v-for="city in item.subItemList" :key="city.id" class="cityBox">
        {{city.title}}&nbsp;&nbsp;{{ item.total }}
       
      </div>
    </div>
    <ComingMoadl v-model:visible="visible"></ComingMoadl>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import ComingMoadl from './components/ComingMoadl'
import CountTo from '@/components/countTo'
const props = defineProps({
  item: {
    type: Object,
    default: {}
  }
})
const visible = ref(false)
const openList = () => {
  visible.value = true
}
</script>

<style lang="less" scoped>
.line {
  width: 100%;
  height: 1px;
  background: #eee;
  margin: 8px 0;
}

.cityBox {
  padding: 2px 6px;
  border: 1px solid #eee;
  font-size: 12px;
  border-radius: 2px;
  margin-right: 4px;
  margin-bottom: 4px;
}
</style>