<template>
  <a-modal :visible="detailShow" :footer="null" width="70%" :bodyStyle="{ padding: 0, paddingLeft: '15px' }"
    @cancel="cancelClose">
    <template #title>
      <div>
        {{ bigCarNo || '暂无车牌' }}
      </div>
    </template>

    <div style="display:flex">
      <div class="left-content" style="flex:1">
        <!-- 运输车信息 -->
        <div>
          <div class="content-title">运输车信息</div>
          <div class="content-data">
            <a-row :gutter="[24, 8]">
              <a-col :span="2.5">
                <span>线路名称</span>
              </a-col>
              <a-col :span="7">
                <span>{{ detailData.lineName }}</span>
              </a-col>
              <a-col :span="3">
                <span>状态</span>
              </a-col>
              <a-col :span="4">
                <span>{{ detailData.truckStatus?.label }}</span>
              </a-col>
              <a-col :span="3">
                <span>是否到达</span>
              </a-col>
              <a-col :span="4">
                <span>{{ detailData.truckStatus?.value === 4 ? '是' : '否' }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="2.5">
                <span>已占&emsp;&emsp;</span>
              </a-col>
              <a-col :span="7">
                <span>{{ detailData.usedParkPlace }}</span>
              </a-col>
              <a-col :span="3">
                <span>空位</span>
              </a-col>
              <a-col :span="4">
                <span>{{ remainingPosition }}</span>
              </a-col>
              <a-col :span="3">
                <span>大车总运费</span>
              </a-col>
              <a-col :span="4">
                <span>{{ detailData.freightCost }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="2.5">
                <span>超期&emsp;&emsp;</span>
              </a-col>
              <a-col :span="7">
                <span>{{ detailData.gpsLine?.isOverDue?.value }}</span>
              </a-col>
              <a-col :span="3">
                <span>GPS类型</span>
              </a-col>
              <a-col :span="4">
                <span>{{ detailData.gpsType?.label }}</span>
              </a-col>
              <a-col :span="3">
                <span>GPS状态</span>
              </a-col>
              <a-col :span="4">
                <span>{{ detailData.gpsLine?.gpsStatus?.label }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="2.5">
                <span>司机姓名</span>
              </a-col>
              <a-col :span="7">
                <span>{{ detailData.driverName }}</span>
              </a-col>
              <a-col :span="3">
                <span>司机电话</span>
              </a-col>
              <a-col :span="4">
                <span>{{ detailData.driverTel }}</span>
              </a-col>
              <a-col :span="3">
                <span>安排时间</span>
              </a-col>
              <a-col :span="4">
                <span>{{ detailData.loadingTime }}</span>
              </a-col>
            </a-row>
          </div>
        </div>
        <!-- 小车列表 -->
        <div>
          <!-- 历史装运车 -->
          <div v-show="detailData.truckStatus?.value === 4">
            <div class="content-title">历史装运小车</div>
            <div>
              <a-table ref="tableRef" size="small" :columns="columns" :row-key="record => record.orderVehicleId"
                :data-source="historyList" bordered :pagination="false" :loading="loading">
                <template #brand="{ record }">
                  <div>
                    <span>{{ record.brand + record.model }}</span>
                  </div>
                </template>
                <template #address="{ record }">
                  <div>
                    <div>起：{{ record.startAddress }}</div>
                    <div>终：{{ record.endAddress }}</div>
                  </div>
                </template>
              </a-table>
            </div>
          </div>
          <!-- 已安排 -->
          <div v-show="detailData.truckStatus?.value !== 4">
            <div class="content-title">已安排车</div>
            <div>
              <a-table ref="tableRef" size="small" :columns="columns" :row-key="record => record.orderVehicleId"
                :data-source="translistData" bordered :pagination="false" :loading="loading">
                <template #brand="{ record }">
                  <div>
                    <span>{{ record.brand + record.model }}</span>
                  </div>
                </template>
                <template #address="{ record }">
                  <div>
                    <div>起：{{ record.startAddress }}</div>
                    <div>终：{{ record.endAddress }}</div>
                  </div>
                </template>
                <template #operation="{ record }">
                  <div>
                    <a-popconfirm ok-text="确认" cancel-text="关闭" @confirm="quitLoading(record)">
                      <template #title>
                        <div v-show="detailData.truckStatus?.value !== 1">
                          <div>确认{{ detailData.truckStatus?.value === 1 ? '卸车' : '退板' }}吗？请输入原因</div>
                          <div><a-input v-model:value="quitLoadingcause" /></div>
                        </div>
                        <div v-show="detailData.truckStatus?.value === 1">确认卸车？</div>
                      </template>
                      <a>{{ detailData.truckStatus?.value === 1 ? '卸车' : '退板' }}</a>
                    </a-popconfirm>
                    <a-divider v-show="detailData.truckStatus?.value !== 1" type="vertical" />
                    <a-popconfirm ok-text="确认" cancel-text="关闭" @confirm="transitOperation(record)">
                      <template #title>
                        <div>
                          <div>选择中转地</div>
                          <div><a-cascader :options="$store.state.app.PClist" :show-search="{ filter }"
                              placeholder="请选择中转地点" style="width: 100%" @change="transitAddress" /></div>
                        </div>
                      </template>
                      <a v-show="detailData.truckStatus?.value !== 1">中途到达</a>
                    </a-popconfirm>
                  </div>
                </template>
              </a-table>
            </div>
          </div>
          <div style="text-align: right;margin-top:10px">
            <a-popconfirm title="确定到达?" @confirm="confirmArrival">
              <a-button v-show="detailData.truckStatus?.value === 2" type="primary">到达</a-button>
            </a-popconfirm>
            <a-popconfirm title="确定发车?" @confirm="receivables = true">
              <a-button v-show="detailData.truckStatus?.value === 1" type="primary">发车</a-button>
            </a-popconfirm>
          </div>
        </div>
        <!-- 运输线路详情 -->
        <div>
          <div class="content-title">运输线路详情</div>
          <div class="content-data">
            <a-row :gutter="[24, 8]">
              <a-col :span="5">
                <span>总运输里程（Km）</span>
              </a-col>
              <a-col :span="3">
                <span>{{ detailData.gpsLine?.totalTransportKm }}</span>
              </a-col>
              <a-col :span="5">
                <span>实际剩余公里数（Km）</span>
              </a-col>
              <a-col :span="3">
                <span>{{ detailData.gpsLine?.remainingKm }}</span>
              </a-col>
              <a-col :span="4">
                <span>每日应行驶路程</span>
              </a-col>
              <a-col :span="4">
                <span>{{ detailData.gpsLine?.defaultKm }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="5">
                <span>是否超期</span>
              </a-col>
              <a-col :span="3">
                <span>{{ detailData.gpsLine?.isOverDue.label }}</span>
              </a-col>
              <a-col :span="5">
                <span>预计需时（小时）</span>
              </a-col>
              <a-col :span="3">
                <span>{{ detailData.gpsLine?.totalTransportTime }}</span>
              </a-col>
              <a-col :span="4">
                <span>发车时间</span>
              </a-col>
              <a-col :span="4">
                <span>{{ detailData.gpsLine?.departureTime }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="5">
                <span>已行驶时间（小时）</span>
              </a-col>
              <a-col :span="3">
                <span>{{ detailData.gpsLine?.transportTime }}</span>
              </a-col>
              <a-col :span="5">
                <span>记录时间（小时）</span>
              </a-col>
              <a-col :span="3">
                <span>{{ detailData.gpsLine?.transportTime }}</span>
              </a-col>
            </a-row>
          </div>
        </div>
        <!-- 运输位置 -->
        <div>
          <div class="content-title" style="display: flex;justify-content: space-between;">
            <div>
              <span style="margin-right:10px" @click="addPositionShow = true">运输位置</span>
              <span style="color:#B5BBC5;font-weight: normal;">共{{ transPosition.length || 0 }}条</span>
            </div>
            <a @click="mapShow = true, createMap(), newTracking()">地图预览</a>
          </div>
          <div class="content-data">
            <a-button type="primary" @click="addPositionShow = true">+新增位置</a-button>
            <div v-show="transPosition.length <= 0" style="text-align: center;color:#6B778C">暂无在途信息</div>
            <a-steps v-show="transPosition.length > 0" progress-dot :current="1" direction="vertical">
              <a-step v-for="(item, index) in transPosition" :key="index">
                <template #title>
                  <div style="display: flex;justify-content: space-between;">
                    <div>
                      <div style="display:flex">
                        {{ item.location }}
                        <a-typography-paragraph :copyable="{ text: item.location }">
                        </a-typography-paragraph>
                      </div>
                    </div>
                    <a v-show="index === 0" @click="positionChange = true, changeAddressData = item">修改</a>
                  </div>
                </template>
                <template #description>
                  <div>
                    <div>备注：{{ item.remark }}</div>
                    <div>{{ item.operator }}<span style="margin-left:10px">{{ item.recordTime }}</span></div>
                  </div>
                </template>
              </a-step>
            </a-steps>
          </div>
        </div>
      </div>
      <div style="width:30%;padding-left:15px">
        <Record v-if="vehicleList.length > 0" :vehicleList="vehicleList" :vehiclesId="vehicleList[0].id" />
        <div v-else>暂无数据</div>
      </div>
    </div>
    <!-- 新增位置 -->
    <a-modal v-model:visible="addPositionShow" title="新增位置" @ok="confirmAdd" :confirmLoading="btnLoading">
      <div>
        <div style="margin: 15px 0">所在地区<span style="color:#f00">*</span></div>
        <a-cascader :options="$store.state.app.cityDict" v-model:value="address" :changeOnSelect="true"
          :show-search="{ filter }" @change="setAddress" placeholder="请选择地址" />
        <div style="margin: 15px 0">详细地址</div>
        <a-input v-model:value="formNowLocation.details" placeholder="详细地址" />
      </div>
    </a-modal>
    <!-- 地图路线预览 -->
    <a-modal v-model:visible="mapShow" title="地图预览" width="40%" @ok="mapShow = false">
      <div v-show="mapLoadShow" style="text-align: center;"> <a-spin size="large" /></div>
      <div v-show="!mapLoadShow" id="containerMap">
      </div>
    </a-modal>
    <!--修改最近一条位置信息-->
    <a-modal v-model:visible="positionChange" title="修改位置信息" :confirm-loading="confirmLoading"
      @ok="confirmChangeLocation">
      <div>
        (原) {{ changeAddressData.location }}——>>
        <a-cascader :options="$store.state.app.cityDict" :changeOnSelect="true" :show-search="{ filter }"
          @change="setChangeAddress" placeholder="请选择地址" />
        <a-input style="margin-top:10px" v-model:value="formNowLocation.details" placeholder="详细地址" />
      </div>
    </a-modal>
    <!-- 发车----输入实际收款方式 -->
    <a-modal v-model:visible="receivables" title="填写收款方式" @ok="startCar" :confirmLoading="btnLoading">
      <h1>总金额<span style="color:#f00">{{ detailData.freightCost * 1 }}</span></h1>
      <a-input addon-before="油卡" v-model:value="depart.oilCard" type='number' size="large">
        <template #suffix>
          <a-tooltip title="全部油卡">
            <EnterOutlined @click="depart.oilCard = detailData.freightCost * 1, depart.bankCard = 0"
              style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </template>
      </a-input>
      <a-divider type="vertical" />
      <a-input addon-before="转账" v-model:value="depart.bankCard" type='number' size="large">
        <template #suffix>
          <a-tooltip title="全部转账">
            <EnterOutlined @click="depart.bankCard = detailData.freightCost * 1, depart.oilCard = 0"
              style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </template>
      </a-input>
    </a-modal>
  </a-modal>
</template>

<script>
import { reactive, toRefs, watch, ref } from 'vue'
import { message } from 'ant-design-vue'
import { EnterOutlined } from '@ant-design/icons-vue'
import { vehicleId, vehicleIdHis } from '@/api/transport/vehicle'
import { transEdit } from '@/api/trans/ops/edit'
import { carUnloadVehicle } from '@/api/transport/transport'
import { truckGpsPage } from '@/api/transport/truck'
import { detail, transfer, truckGpsAdd, tracking, transArrive, transDepart } from '@/api/transport/truck'
import Record from '@/views/transportCapacity/transportUse/comon/record.vue'

export default {
  props: {
    detailShow: Boolean,
    transTruckId: String,
    bigCarNo: String
  },
  components: {
    Record,
    EnterOutlined
  },
  setup (props, context) {
    const state = reactive({
      mapShow: false,
      detailShow: false,
      btnLoading: false,
      receivables: false,
      addPositionShow: false,
      mapLoadShow: false,
      confirmLoading: false,
      positionChange: false,
      map: null,
      address: '',
      depart: {
        oilCard: 0,
        bankCard: 0
      },
      changeAddressData: {},
      quitLoadingcause: '',
      remainingPosition: '',
      transTruckId: '',
      bigCarNo: '',
      formNowLocation: {
        details: ''
      },
      detailData: {},
      transit: {},
      markers: [],
      newList: [],
      vehicleList: [],
      historyList: [],
      translistData: [],
      transPosition: [],
      columns: [
        {
          title: '车牌号',
          dataIndex: 'vinNo',
          width: '13%',
          align: 'center'
        },
        {
          title: '车型',
          dataIndex: 'brand',
          width: '13%',
          align: 'center',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '单台价格',
          dataIndex: 'transportFee',
          width: '13%',
          align: 'center'
        },
        {
          title: '装车人',
          dataIndex: 'loadingOperator',
          width: '13%',
          align: 'center'
        },
        {
          title: '起始地',
          dataIndex: 'address',
          width: '15%',
          align: 'left',
          slots: {
            customRender: 'address'
          }
        },
        {
          title: '当前状态',
          dataIndex: 'transportType.label',
          width: '13%',
          align: 'center'
        },
        {
          title: '操作',
          align: 'center',
          width: '15%',
          slots: {
            customRender: 'operation'
          }
        },
      ]
    })
    watch(() => props.detailShow, (newVal, oldVal) => {
      state.detailShow = newVal
      if (state.detailShow === true) {
        state.bigCarNo = props.bigCarNo
        state.transTruckId = props.transTruckId
        getDetailData()
        getLocation()
      }
    })
    const getDetailData = () => {
      if (state.transTruckId !== '') {
        detail(state.transTruckId) // 运输车信息
          .then(res => {
            if (res.code === 10000) {
              if (res.data.truckStatus?.value !== 4) getVehicleId()
              else getHistoryData()
              state.detailData = res.data
              state.remainingPosition = state.detailData.totalParkPlace - state.detailData.usedParkPlace
            }
          })
      }
    }
    // 已安排车
    const getVehicleId = () => {
      state.vehicleList = []
      vehicleId(
        state.transTruckId, ({
          current: 1,
          size: 999999
        })
      ).then(res => {
        if (res.code === 10000) {
          state.translistData = res.data.records
          state.translistData.forEach(item => {
            state.vehicleList.push({
              id: item.orderVehicleId,
              vinNo: item.vinNo
            })
          })
        }
      })
    }
    // 已装载的小车列表
    const getHistoryData = () => {
      vehicleIdHis(
        state.transTruckId, ({
          current: 1,
          size: 999999
        })
      ).then(res => {
        if (res.code === 10000) {
          state.vehicleList = []
          state.historyList = res.data.records
          state.historyList.forEach(item => {
            state.vehicleList.push({
              id: item.orderVehicleId,
              vinNo: item.vinNo
            })
          })
        }
      })
    }
    // 退板-卸车
    const quitLoading = record => {
      if (state.detailData.truckStatus.value === 1) { // 卸车
        carUnloadVehicle({ ids: [record.transOrderTruckVehicleId] }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            getVehicleId()
          }
        })
      } else { // 退板
        transEdit({
          params: {
            transportTruckId: state.detailData.transTruckId || props.detailData.transTruckId,
            totvIds: [record.transOrderTruckVehicleId]
          },
          type: {
            value: 1
          },
          reason: state.quitLoadingcause,
          subType: 100
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            getVehicleId()
          }
        })
      }
    }
    // 选择中转地
    const transitAddress = (e, v) => {
      state.transit.province = e[0]
      state.transit.city = e[1]
      // state.transit.area = e[2]
    }
    // 发车
    const startCar = () => {
      if (Number(state.depart.oilCard) + Number(state.depart.bankCard) === Number(state.detailData.freightCost)) {
        state.btnLoading = true
        transDepart(state.detailData.transTruckId, state.depart).then(res => {
          if (res.code === 10000) {
            state.receivables = false
            message.success(res.msg)
            loadData()
          }
        }).finally(() => { state.btnLoading = false })
      } else {
        message.error('金额填写数目错误,请检查')
      }
    }
    // 中途到达
    const transitOperation = (record) => {
      transfer(state.transTruckId, {
        totvId: record.transOrderTruckVehicleId,
        stopAddress: state.transit,
        transTruckId: state.transTruckId,
        transOrderVehicleIds: [record.transOrderTruckVehicleId]
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          getVehicleId()
        }
      })
    }
    // 到达
    const confirmArrival = () => {
      transArrive(state.detailData.transTruckId, {})
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            getDetailData()
          }
        })
    }
    const setAddress = (value) => {
      state.formNowLocation.province = value[0]
      state.formNowLocation.city = value[1]
      state.formNowLocation.area = value[2]
      if (value[0] === undefined) {
        state.formNowLocation.province = ''
      }
    }
    // 确认新增位置
    const confirmAdd = () => {
      var NowLocation = {
        transTruckId: state.transTruckId,
        addressDTO: state.formNowLocation,
        remark: '手动新增'
      }
      if (state.formNowLocation.city) requestAPI()
      else message.error('请选择地区')
      function requestAPI () {
        state.btnLoading = true
        truckGpsAdd(NowLocation).then(res => {
          if (res.code === 10000) {
            message.success('添加成功')
            state.addPositionShow = false
            getLocation()
            state.address = ''
            state.formNowLocation.details = ''
          }
        }).finally(() => { state.btnLoading = false })
      }
    }
    // 运输位置
    const getLocation = () => {
      truckGpsPage(state.transTruckId, {}).then(res => {
        if (res.code === 10000) {
          state.transPosition = res.data.records
        }
      })
    }
    const cancelClose = () => {
      state.detailShow = false
      context.emit('status', state.detailShow)
    }
    // 创建地图实例
    const createMap = ref => {
      /* eslint-disable */
      state.mapLoadShow = true
      // 创建Map实例
      setTimeout(() => {
        if (state.map === null) {
          state.map = new LKMap.Map('containerMap', {
            center: [109.128713, 31.383405],
            zoom: 4
          })
        }
      }, 50)
    }
    //  车辆轨迹
    const newTracking = () => {
      tracking(state.transTruckId).then((res) => {
        if (res.code === 10000) {
          state.newList = res.data
          let data = res.data
          createMap()
          if (data.length > 0) {
            markMap(data)
          } else {
            message.info("该车辆没有位置信息")
            state.mapShow = false
          }
        } else {
          message.error(res.msg)
        }
      })
    }
    // 路书更替
    const markMap = (data) => {
      if (state.markers.length > 0) {
        state.markers.forEach(item => {
          item.remove()
        })
      }
      var drivingService
      drivingService = new LKMap.Driving_v2({
        costing: 'auto',	  // 路线规划方式  auto：轿车
        alternates: 0      //查找至多n条路线（n =< 3）
      })
      let coordinate = []
      data.forEach(item => {
        coordinate.push({ lat: Number(item.lat), lon: Number(item.lon) })
      })
      // 路线数组 data
      setTimeout(() => {
        var params = {
          // locations: [{ lon: coordinate[0].lon, lat: coordinate[0].lat }, { lon: coordinate[coordinate.length - 1].lon, lat: coordinate[coordinate.length - 1].lat }],
          locations: coordinate,
          costing: 'auto',	  // 路线规划方式  auto：轿车
          alternates: 0      //查找至多n条路线（n =< 3）
        }
        drivingService.search(params, (status, result) => {
          showLine(result)	// 绘制线路
          addIcon()	// 添加起点坐标
        })
      }, 100)
      // 添加起点终点
      function addIcon () {
        state.markers = [];
        for (var i in coordinate) {
          var anchor = "center";
          var icon = new LKMap.Icon({
            size: new LKMap.Size(30, 30),
            image: 'https://lkimgyt.luokuang.com/1612506398655.png',
            imageSize: new LKMap.Size(30, 30),
            // imageOffset: new LKMap.Pixel(0, 0)
          });
          if (i == 0) {
            icon = new LKMap.Icon({
              size: new LKMap.Size(39, 48),
              image: 'https://lkimgyt.luokuang.com/1591930232132.png',
              imageSize: new LKMap.Size(39, 48),
              // imageOffset: new LKMap.Pixel(0, 0)
            });
            anchor = "bottom";
          } else if (i == coordinate.length - 1) {
            icon = new LKMap.Icon({
              size: new LKMap.Size(39, 48),
              image: 'https://lkimgyt.luokuang.com/1591930232633.png',
              imageSize: new LKMap.Size(39, 48),
              // imageOffset: new LKMap.Pixel(0, 0)
            });
            anchor = "bottom";
          }
          var position = new LKMap.LngLat(coordinate[i]['lon'], coordinate[i]['lat'])
          // var inforWindow = new LKMap.InforWindow({
          //   anchor: 'bottom',
          //   content: '<h3>箩筐地图</h3><p>地址：北京市朝阳区光华路光华SOHO</p><p>电话：010-66016602</p>',
          //   position: position,
          // });
          // inforWindow.open(map, position);
          state.markers[i] = new LKMap.Marker({
            position: position,
            icon: icon,
            label: {
              // item.gpsTime + '</br>' + '已到达' + item.latestLocation
              content: data[i]['gpsTime'] + '<br/>' + data[i]['latestLocation']
            },
            anchor: 'bottom',
          })
          state.markers[i].setMap(state.map);
          // state.markers[i].setContent("<div class='marker-demo'><h3>箩筐地图</h3><p>地址：北京市朝阳区光华路光华SOHO</p><p>电话：010-66016602</p></div>")
        }
        state.mapLoadShow = false
      }

      // 绘制线路
      function showLine (result) {
        var polyline = []
        var color = ['#a9a4a4', '#3471e2', '#e44d33'];
        for (var i in result.routes) {
          polyline[i] = new LKMap.Polyline({
            path: result.routes[i].geometry.coordinates,
            strokeWeight: 8,
            lineJoin: 'round',
            lineCap: 'round',
            strokeColor: '#3471e2',
            showDir: true,
          });
          polyline[i].setMap(state.map);
        }
        setTimeout(() => {
          state.map.setFitView(null, {
            padding: {
              top: 100,
              bottom: 100,
              left: 0,
              right: 0,
            }
          });
        }, 500)
      }
    }
    const setChangeAddress = (e, v) => {
      state.formNowLocation.province = e[0]
      state.formNowLocation.city = e[1]
      state.formNowLocation.area = e[2]
      if (e[0] === undefined) {
        state.formNowLocation.province = ''
      }
    }
    // 修改最近一次运输位置信息
    const confirmChangeLocation = () => {
      state.confirmLoading = true
      const data = JSON.parse((JSON.stringify((state.formNowLocation))))
      delete data.details
      truckGpsAdd({
        addressDTO: data,
        gpsId: state.changeAddressData.gpsId,
        remark: '手动修改',
        details: state.formNowLocation.details,
        transTruckId: state.transTruckId
      }).then(res => {
        if (res.code === 10000) {
          message.success('修改成功')
          getLocation()
          state.positionChange = false
          state.confirmLoading = false
        }
      })
    }

    return {
      ...toRefs(state),
      cancelClose,
      quitLoading,
      setAddress,
      transitAddress,
      confirmAdd,
      transitOperation,
      newTracking,
      createMap,
      startCar,
      confirmArrival,
      setChangeAddress,
      confirmChangeLocation
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

:deep(.ant-steps-item-content) {
  width: auto !important;
}

:deep(.ant-steps-item-title) {
  width: 100%;
}

#containerMap {
  width: 100%;
  height: 400px !important;
  font-family: '微软雅黑';
}
</style>