import { postBodyRequest } from '@/utils/axios'

// export function getVehiclePage (params) {
//   return postBodyRequest('/trans/transport/vehicle/incomplete', params)
// }

export function getVehiclePage (params) {
  return postBodyRequest('/trans/transport/vehicle/todo', params)
}

export function lineChoose (params) {
  return postBodyRequest('/trans/order/vehicle/recommend/line', params)
}

export function linePage (params) {
  return postBodyRequest('/trans/transport/line/page', params)
}

export function getCarrier (params) {
  return postBodyRequest('/trans/order/vehicle/recommend/carrier', params)
}

export function gpsKm (params) {
  return postBodyRequest('/trans/transport/truck/gps/km', params)
}
