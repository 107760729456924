import {
  postBodyRequest
} from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/bs/offer/quotation/line', params)
}
// 归属分页
export function ascriptionPage (params) {
  return postBodyRequest('/bs/offer/belong/page', params)
}
// 归属上传
export function ascriptionPageId (fileId, params) {
  return postBodyRequest(`/bs/offer/belong/${fileId}`, params)
}
// 直达分页
export function throughPage (params) {
  return postBodyRequest('/bs/offer/through/page', params)
}
// 直达上传
export function throughPageId (fileId, params) {
  return postBodyRequest(`/bs/offer/through/${fileId}`, params)
}

// 批量报价上传分页
export function batchOfferPage (params) {
  return postBodyRequest('/bs/offer/batchOffer/page', params)
}
// 批量报价 绑定文件
export function offerFile (fileId, params) {
  return postBodyRequest(`/bs/offer/batchOffer/file/${fileId}`, params)
}
// 批量报价明细
export function batchOfferDatail (offerFileId, params) {
  return postBodyRequest(`/bs/offer/batchOffer/${offerFileId}/detail`, params)
}
// 开始批量报价
export function batchOfferstart (offerFileId, params) {
  return postBodyRequest(`/bs/offer/batchOffer/${offerFileId}/start`, params)
}

export function transCarrierData (params) {
  return postBodyRequest('/carrier/carrier/line/trans', params)
}
export function getHistoricalData (params) {
  return postBodyRequest('/bs/offer/through/record/page', params)
}
export function cline (params) {
  return postBodyRequest('/market/c/end/line/list', params)
}
