<template>
  <div ref="mod">
    <a-modal :get-container="() => $refs.mod" :visible="showDOM" centered width="70%" footer="" @cancel="handleCancel">
      <template #title>
       <div class="flex pos-rel">
        <div class="">
          <img style="width: 30px;height: 30px;" src="../../../assets/images/repository/icon.png" alt="">
          <span class="titleTips">知识库应用</span>
        </div>
        <div class="pos-abs" style="right: 40px;">
          <span class="feedBox flex al-center ju-center cur-p" @click="feedBtn">反馈意见</span>
        </div>
       </div>
      </template>
      <div class="flex">
        <!-- 左边内容 -->
        <div class="contenBox">
          <div class="topBox">
            <div class="defaultBox flex-d al-center" v-if="dataList.length==0&&!isLoding">
              <div>
                <img class="dfImg " src="../../../assets/images/repository/u68.png" alt="">
              </div>
              <div class="tipsT ">
                你好，我是知识库助手
              </div>
              <div class="tipsB">
                我能根据您输入和选择的关键词，为您提供相关知识解答！
              </div>
            </div>
            <div v-else class="questionBox">
              <div v-for=" item in dataList" :key="item.id">
                <!-- 问题 -->
                <div>
                  <img class="quesImg" src="../../../assets/images/repository/u71.png" alt="">
                  <span>{{ item.title }}</span>
                </div>
                <!-- 回答 -->
                <div class="siiContent flex">
                  <img class="siiImg" src="../../../assets/images/repository/u68.png" alt="">
                  <div class="siiText">
                    <div v-html="item.changeCon"></div>
                    <div v-if="item.fileUrls&&item.isIssEnd" class="flex wrap ju-between "> 
                      <a-image-preview-group>
                         <div  v-for="img in item.fileUrls" class="imgStyle" :key="img.id">
                            <a-image  @click="addImg(item)"  style="" :src="img.url" alt="" >
                              <template #placeholder>
                                <a-image
                                  :src="img.previewUrl"
                                  :preview="false"
                                />
                              </template>
                            </a-image>
                         </div>
                      </a-image-preview-group>
                    </div>
                    <!-- 是否有用 -->
                    <div v-if="item.isIssEnd&&item.content!=='未获取到数据'" class="selectBox  flex pos-rel">
                      <div v-if="item.isYes" @click="addBe(item)" class="selectBoxItemChnege selectBoxItem m-r1">
                        <img src="../../../assets/images/repository/yes.png" alt="">
                        <span>有用</span>
                      </div>
                      <div @click="addBe(item)" v-else class="selectBoxItem m-r1">
                        <img src="../../../assets/images/repository/yesH.png" alt="">
                        <span> 有用</span>
                      </div>
                      <div v-if="item.isNo" @click="addNo(item)" class="selectBoxItem selectBoxItemNo">
                        <img src="../../../assets/images/repository/no.png" alt="">
                        <span>无用</span>
                      </div>
                      <div v-else @click="addNo(item)" class="selectBoxItem">
                        <img src="../../../assets/images/repository/noH.png" alt="">
                        <span>无用</span>
                      </div>
                      <div class="pos-abs" style="right: 0;">
                        <a @click="copyAdd(item)">点此复制</a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 推荐 -->
                <div v-if="item.isIssEnd" class="recommendBox ">
                  <!-- 平级 -->
                  <div class="flex wrap">
                    <div class="optItem" @click="optItem(opt)" v-for="opt in item.relateContent" :key="opt.id">
                      {{ opt.title }}
                    </div>
                  </div>
                  <!-- 推荐 -->
                  <a v-if="item.subContent">为您推荐相关问题:</a>
                  <div class="flex wrap m-t1 m-b2">
                    <div class="optItem" @click="optItem(opt)" v-for="opt in item.subContent" :key="opt.id">
                      {{ opt.title }}
                    </div>
                  </div>
                </div>
              </div>
              <div style="height: 100px;"></div>
            </div>
            <div class="flex ju-center "  v-if="isLoding">
              <a-spin />
            </div>
          </div>
          <div class="flex-d al-center">
            <div style="height: 35px;" class="flex ju-center">
              <a-button  v-if="isEnd" type="primary" @click="stopAnswer">停止回答</a-button>
            </div>
            <div class="textArea flex">
              <input class="tAr" @keydown.enter="enterAdd" v-model="value2" placeholder="在这里输入关键字" />
              <div class="flex ju-end">
                <div @click="enterAdd" v-if="IfNull(value2)&&!isEnd" class="btnBox btnChange">
                  <img src="../../../assets/images/repository/plan.png" alt="">
                </div>
                <div v-else class="btnBox">
                  <img src="../../../assets/images/repository/plan.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边内容 -->
        <div class="rightBox">
          <div class="rigTitle flex al-center">
            <img class="rigImg m-r1" src="../../../assets/images/repository/more.png" alt="">
            <span style="color: #344563;font-weight: 700;font-size: 16px;">全部知识</span>
          </div>
          <div class="rigHistory m-t2 flex al-center">
            <span class="m-b1" >历史记录：</span>
            <div class="hisItem m-b1 cur-p" @click="hisAdd(item)" v-for="item in historyList" :key="item.id">
              <span>{{item}}</span>
            </div>
          </div>
          <div class="rigContent">
            <div class="tagsBox flex al-center">
              <div v-for="(item,index) in tagsList" :class="index == recordIdx?'changeColor':''" @click="tagsBtn(item,index)" :key="item.id" class="rigItem cur-p">
                {{ item.typeName }}
              </div>
            </div>
            <div class="issueBox">
              <div v-if="issueLoading" class="flex ju-center m-t3"><a-spin /></div>
              <div v-else class="issueItem cur-p" :class="issueIdx == index?'changeBor':''" @click="issueBtn(item,index)" v-for="(item,index) in issueList" :key="item.id">
                <span>{{ item.title }}</span>
                <div class="issContent">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal v-model:visible="feedShow" title="反馈意见" width="40%" @ok="handleOk" @cancel="feedValue = ''">
       <div class="feedTitel m-b1"><span>*</span>反馈内容</div>
       <a-textarea style="resize: none;" v-model:value="feedValue" placeholder="请输入反馈意见" :rows="12" />
    </a-modal>
    
  </div>
</template>

<script setup>
import { onMounted, ref,watch } from "vue";
import { oaGetKeyType, oaGetTypeId, oaKeyPrompt, oaUpdetaType ,oaFeedbackAdd} from '@/api/repository/repository'
import { message } from 'ant-design-vue';
import useClipboard from 'vue-clipboard3'
const { toClipboard } = useClipboard()
const tagsList = ref([]);//标签列表
const recordIdx = ref(0);//记录标签下标
const issueList = ref([]);//问题列表
const value2 = ref("");//输入的值
const dataList = ref([]); //问题内容答复列表
const historyList = ref([]); //历史记录
const isLoding = ref(false)
const feedShow = ref(false)
const feedValue = ref('')
const issueLoading = ref(false)
const issueIdx = ref(null)
const props = defineProps({
  showDOM: {
    type: Boolean,
    default: false
  }
})
//点击图片预览
 const addImg = (item) => {
  item.isBig = true;
 }
//点击标签事件
const tagsBtn = (item, index) => {
  recordIdx.value = index
  issueIdx.value = null
  issueLoading.value = true
  oaGetTypeId(item.typeId).then(res => {
    issueList.value = res.data
    issueLoading.value = false
  })

}
//反馈弹窗
const feedBtn = () =>{
  feedShow.value = true
}
//反馈确定事件
const handleOk = () => {
  if(!IfNull(feedValue.value)){
    message.warning('请输入反馈内容后在提交')
    return
  }
  oaFeedbackAdd({
    content: feedValue.value
  }).then(res =>{
    if(res.code == 10000){
      message.success(res.msg)
      feedShow.value = false
      feedValue.value = ""
    }
  })
}
//点击tags问题事件
const issueBtn = (item,index) => {
  if (isEnd.value) return
  issueIdx.value = index
  reqIssPrompt(item.contentId, item.title)
}
//点击推荐问题
const optItem = (item) => {
  reqIssPrompt(item.contentId, item.title)
}
//点击复制
const copyAdd = async (item) => {
  try {
    await toClipboard(item.content)
    message.success('复制成功');
  } catch {
  }
}
const emit = defineEmits(['update:showDOM'])
const handleCancel = () => {
  emit('update:showDOM', false)
};
// 发送 按钮
const enterAdd = async () => {
  try {
    await reqIssPrompt('', value2.value)
    if (localStorage.getItem('oaHistory')) {
      let list = JSON.parse(localStorage.getItem('oaHistory'))
      if (list.length == 10) {
        list.shift()
      }
      list.unshift(value2.value)
      list = list.filter((item, index) => {
        return list.indexOf(item) == index
      })
      historyList.value = list
      localStorage.setItem('oaHistory', JSON.stringify(list))
    } else {
      let list = []
      list.push(value2.value)
      historyList.value = list
      localStorage.setItem('oaHistory', JSON.stringify(list))
    }
    value2.value = ''
  }
  catch {
  }
};
// 点击有用标签 
const addBe = async (item) => {
  await reqUpdetaType({
    recordId: item.id,
    fondType: {
      label: '有用',
      value: 1
    }
  })
  item.isYes = true;
  item.isNo = false
}
//点击无用
const addNo = async (item) => {
  await reqUpdetaType({
    recordId: item.id,
    fondType: {
      label: '无用',
      value: 0
    }
  })
  item.isNo = true;
  item.isYes = false
}
// 获取标签
const getType = () => {
  oaGetKeyType().then(res => {
    tagsList.value = res.data
    tagsBtn(res.data[0], 0)
  })
}
//点击历史记录
const hisAdd = (item) => {
  reqIssPrompt('', item)
}
const isEnd = ref(false)//记录是否结束回答
//获取问题解答
const reqIssPrompt = (id = "", keyword = "") => {
  if (isEnd.value) return
  isLoding.value = true
  oaKeyPrompt({
    id: id,
    keyword: keyword
  }).then((res) => {
    isLoding.value = false
    if (res.code !== 10000) return
    let msg = {
      title: keyword,
      content: res.data.content.content[0],
      id: res.data.content.recordId,
      changeCon: "",
      relateContent: res.data.relateContent,//相关问题
      subContent: res.data.subContent,//推荐问题
      fileUrls:res.data.content.fileUrls
    }
    dataList.value.push(msg)
    wirte(msg.content, msg.id, 50,keyword)
  });
}
function highlightKeyword(text, keyword) {
  var regex = new RegExp(keyword, 'gi');
  // 使用replace函数，匹配到的关键词会被替换为带有高亮标记的HTML片段
  var highlightedText = text.replace(regex, function(match) {
    return '^' + match + '\b';
  });
  return highlightedText;
}
//更新有用无用
const reqUpdetaType = (msg) => {
  oaUpdetaType(msg).then((res) => {
    if (res.code !== 10000) return
    message.success('反馈成功')
  })
}
onMounted(() => {
  getType()
  if (localStorage.getItem('oaHistory')) {
    historyList.value = JSON.parse(localStorage.getItem('oaHistory'))
  }
})
let container
const scrs = () => {
  if (!container) {
    container = document.querySelector('.questionBox');
  }
  if (container.scrollHeight > 600) {                    //500是你的dom元素高度
    container.scrollTop = container.scrollHeight
  }
}
const stopAns = ref(false)
const wirte = (str = "", id = "", time = 50, keyword = '') => {
  isEnd.value = true;
  let idx = 0
  dataList.value.forEach((item, index) => {
    if (item.id == id) {
      idx = index
    }
  })
  if (typeof str != "string") return;
  setTimeout(() => {
    str = highlightKeyword(str,keyword)
    let words = str.split('')
    const timer = setInterval(() => {
      if (words.length > 0&&!stopAns.value) {
        let del = words.shift();
        if(del == '^'){
          del = '<a>'
        }
        if(del == '\b'){
          del = '</a>'
        }
        del = del.replace(/\n/g, "<br/>")
        dataList.value[idx].changeCon = dataList.value[idx].changeCon + del
        scrs()
      } else {
        const timeOut = setTimeout(() => {
          scrs()
          clearInterval(timer);
          isEnd.value = false;
          isIdShow(id)
          clearTimeout(timeOut);
          stopAns.value = false 
        }, 500);
      }
    }, time);
  }, 1000)

};
// 停止回复
const stopAnswer = () => {
  stopAns.value = true
}
// watch(()=>props.showDOM,(val) =>{
//   if(val){
//     getType()
//   }
// })
const isIdShow = (id) => {
  dataList.value.forEach(item => {
    if (item.id == id) {
      item.isIssEnd = true
    }
  })
}
function IfNull (strings) {
  var str = strings;
  str = str.replace(/(^\s*)|(\s*$)/g, "");
  str = str.replace(/(^　*)|(　*$)/g, "");
  if (str == '') {
    return false;
  }
  return true;
}
</script>

<style lang="less" scoped>
.contenBox {
  width: 75%;
  min-height: 600px;
  background: #E8E9F2;
  padding: 20px 0;
}

.titleTips {
  font-size: 16px;
  color: #0066cc;
  margin-left: 10px;
  font-weight: 700;
}

:deep(.ant-modal-body) {
  padding: 0;
}
.topBox {
  min-height: 660px;
}

.defaultBox {
  text-align: center;
  color: #6b778c;
  font-size: 12px;

  .dfImg {
    width: 46px;
    height: 46px;
  }

  .tipsT {
    color: #344563;
    font-size: 20px;
    font-weight: 700;
    margin: 15px 0;
  }

  .tipsB {
    color: #6b778c;
    font-size: 14px;
  }
}

.questionBox {
  overflow-y: auto;
  height: 660px;
  padding: 20px;

  .quesImg {
    width: 34px;
    height: 34px;
    margin-right: 20px;
  }

  .siiContent {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .siiText {
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 15px;
  }

  .siiImg {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

  .selectBox {
    border-top: 1px solid rgb(246, 246, 246);
    margin-top: 20px;
    padding-top: 20px;
    img {
      width: 16px;
      height: 16px;
    }

    .selectBoxItem {
      width: 80px;
      height: 28px;
      padding: 0 12px;
      border-radius: 20px;
      border: 1px solid rgb(193, 199, 208);
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #6b778c;
      cursor: pointer;
    }

    .selectBoxItemChnege {
      color: #0066cc;
      border: 1px solid #0066cc;
    }

    .selectBoxItemNo {
      color: #f90505;
      border: 1px solid #f90505;
    }
  }
  .recommendBox {
    padding: 0 64px;
    .optItem {
      padding: 8px 18px;
      border-radius: 30px;
      background: #ffffff;
      color: #6b778c;
      font-size: 14px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
.imgStyle{
  width: 49%;
  border: 1px solid #f6f6f6;
  margin-bottom: 10px;
   margin-top: 5px;
   background: #f6f6f6;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 5px;
}
:deep(.ant-image-img){
  width: 100%;
   object-fit: contain;
  height: 418px;
}
.rightBox {
  width: 24%;
  min-height: 600px;
  background: #E8E9F2;
  margin-left: 1%;
  padding: 10px;
  .rigImg {
    width: 18px;
    height: 18px;
  }
  .rigHistory {
    font-size: 14px;
    color: #6b778c;
    flex-wrap: wrap;

    .hisItem {
      color: #0066cc;
      margin-right: 10px;
    }
  }

  .tagsBox {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    .rigItem {
      margin: 5px 0;
      padding: 5px 18px;
      border-radius: 20px;
      display: inline-block;
      background: #fff;
      font-size: 13px;
      color: #333333;
      margin-right: 6px;
    }
    .changeColor {
      background: #0066cc;
      color: #ffffff;
    }
  }

  .issueBox {
    height: 600px;
    overflow-y: auto;
  }

  .issueItem {
    margin-top: 10px;
    background: #ffffff;
    padding: 10px;
    font-size: 14px;
    color: #344563;
    padding-right: 20px;
    border-radius: 10px;
    border: 1px solid #ffffff;
  }

  .issContent {
    margin-top: 4px;
    font-size: 12px;
    color: #6b778c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .changeBor{
    border: 1px solid #0066CC;
  }
}
.tagsBox::-webkit-scrollbar {
  height: 10px;
  cursor: pointer;
}
/* 滚动条的轨道 */
.tagsBox::-webkit-scrollbar-track {
  /* 设置滚动条轨道的背景色 */
  background-color: #fff;
  cursor: pointer;
}
/* 滚动条的滑块 */
.tagsBox::-webkit-scrollbar-thumb {
  /* 设置滚动条滑块的背景色 */
  background-color: #0066cc;
  width: 50px;
  border-radius: 8px;
  cursor: pointer;
}
.issueBox::-webkit-scrollbar {
  /* 设置滚动条的宽度：如果设置为0就是隐藏滚动条但是滚动效果还在非常完美 */
  width: 4px;
}
/* 滚动条的轨道 */
.issueBox::-webkit-scrollbar-track {
  /* 设置滚动条轨道的背景色 */
  background-color: #f4f5f9;
}
/* 滚动条的滑块 */
.issueBox::-webkit-scrollbar-thumb {
  /* 设置滚动条滑块的背景色 */
  background-color: #0066cc;
  border-radius: 8px;
}
.textArea {
  width: 96%;
  border: 1px solid #fff;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
}
.tAr {
  width: 100%;
  outline: none;
  border: none;
  resize: none;
}
.btnBox {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  position: relative;
  right: 0;
  background: #e0e3e8;
  img {
    width: 21px;
    height: 21px;
  }
}

.btnChange {
  background: #0066cc;
}

.feedBox{
  width: 82px;
  height: 26px;
  color: #6b778c;
  font-size: 12px;
  background: #E8E9F2;
  border-radius: 20px;
}
.feedTitel{
  color: #344563;
  font-size: 14px;
  span{
    color: red;
  }
}
</style>
