import {
  fileUpload,
  getRequest,
  postBodyRequest
} from '@/utils/axios'
// 标签分页
export function page (params) {
  return postBodyRequest('/trans/transport/label/page', params)
}
// 标签新增
export function labelAdd (params) {
  return postBodyRequest('/trans/transport/label/add', params)
}
// 标签状态设置 启用-停用
export function labelIsEnable (id) {
  return getRequest(`/trans/transport/label/is-enable?id=${id}`)
}
// 标签列表
export function labelList (params) {
  return postBodyRequest('/trans/transport/label/list', params)
}