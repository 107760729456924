<template>
  <div style="display:flex">
    <div class="left">
      <div class="text-center" style="margin:15px 0;font-weight:bold" v-if="transBase">
        <img class="img" src="@/assets/images/carrier/basic.png">&emsp;基本信息
      </div>
      <div class="order-data" v-if="transBase">
        <a-row :gutter="[24,12]">
          <a-col :span="12">
            <span class="order-data-title">线路名称</span>
            <span class="font-color2">{{transBase.lineName}}</span>
          </a-col>
          <!--          <a-col :span="12">-->
          <!--            <span class="order-data-title">运输类型</span>-->
          <!--            <span class="font-color2">{{transBase.transportType.label}}</span>-->
          <!--          </a-col>-->
          <a-col :span="12">
            <span class="order-data-title">承运商</span>
            <span class="font-color2">{{transBase.carrierName}}</span>
          </a-col>
          <!--          <a-col :span="12">-->
          <!--            <span class="order-data-title">司机姓名</span>-->
          <!--            <span class="font-color2">{{transBase.lineName}}</span>-->
          <!--          </a-col>-->
          <a-col :span="12">
            <span class="order-data-title">运输车牌</span>
            <span class="font-color2">{{transBase.carNo}}</span>
          </a-col>
          <!--          <a-col :span="12">-->
          <!--            <span class="order-data-title">司机电话</span>-->
          <!--            <span class="font-color2">{{transBase.lineName}}</span>-->
          <!--          </a-col>-->
          <a-col :span="12">
            <span class="order-data-title">卸车人</span>
            <span class="font-color2">{{transBase.unloadingOperator}}</span>
          </a-col>
          <a-col :span="12">
            <span class="order-data-title">卸车时间</span>
            <span class="font-color2">{{transBase.unloadingTime}}</span>
          </a-col>
        </a-row>
      </div>
      <div class="text-center" style="margin:15px 0;font-weight:bold">
        <img class="img" src="@/assets/images/carrier/basic.png">&emsp;车辆入库&emsp;
      </div>
      <div style="padding:5px;border:1px solid #ddd" v-if="putWarehouse">
        <div style="background:#FAFAFA;margin-bottom:8px;padding:8px;">
          <a-row :gutter="[24,12]">
            <a-col :span="8">
              <span class="order-data-title">入库类型</span>
              <span class="font-color2">{{putWarehouse.statusName}}</span>
            </a-col>
            <a-col :span="8">
              <span class="order-data-title">入库人</span>
              <span class="font-color2">{{putWarehouse.operation}}</span>
            </a-col>
            <a-col :span="8">
              <span class="order-data-title">入库时间</span>
              <span class="font-color2">{{putWarehouse.operationTime}}</span>
            </a-col>
            <!-- <a-col :span="8">
              <span class="order-data-title">原因</span>
              <span class="font-color2">无字段</span>
            </a-col> -->
            <a-col :span="8">
              <span class="order-data-title">照片</span>
              <span class="font-color2">
                <a-image v-for="(item,index) in putWarehouse.fileUrls" :key="index" :width="20" :src="item" />
              </span>
            </a-col>
            <a-col :span="8" class="dfx-jc-sb">
              <div class="order-data-title">备注</div>
              <div class="font-color2" style="flex:1;color:#f00">{{putWarehouse.remark}}</div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="text-center" style="margin:15px 0;font-weight:bold">
        <img class="img" src="@/assets/images/carrier/basic.png">&emsp;盘库记录&emsp;<a @click="checkShow=true">盘库</a>
      </div>
      <div style="padding:5px;border:1px solid #ddd">
        <div v-show="checkRecord.length > 0" style="background:#FAFAFA;margin-bottom:8px;padding:8px;" v-for="item in checkRecord" :key="item.id">
          <a-row :gutter="[24,12]">
            <a-col :span="8">
              <span class="order-data-title">盘库类型</span>
              <span class="font-color2">{{item.statusName}}</span>
            </a-col>
            <a-col :span="8">
              <span class="order-data-title">盘库人</span>
              <span class="font-color2">{{item.operation}}</span>
            </a-col>
            <a-col :span="8">
              <span class="order-data-title">盘库时间</span>
              <span class="font-color2">{{item.operationTime}}</span>
            </a-col>
            <!-- <a-col :span="8">
              <span class="order-data-title">原因</span>
              <span class="font-color2">无字段</span>
            </a-col> -->
            <a-col :span="8">
              <span class="order-data-title">照片</span>
              <a v-if="item.fileIds" @click="lookImg(item)">查看</a>
            </a-col>
            <a-col :span="8" class="dfx-jc-sb">
              <span class="order-data-title">备注</span>
              <div class="font-color2" style="flex:1">{{item.remark}}</div>
            </a-col>
            <a-col :span="8" class="dfx-jc-sb" v-show="item.abnormalType">
              <span class="order-data-title">异常原因</span>
              <div class="font-color2" style="flex:1;color:#f00">{{item.abnormalType?item.abnormalType.label:''}}</div>
            </a-col>
          </a-row>
        </div>
        <a-empty v-show="checkRecord.length <= 0" />
      </div>
    </div>
    <div class="right">
      <Record ref="recordRef" v-if="vehiclesId!== ''" :vehicleList="vehicleList" :vehiclesId="vehicleList[0].id" :orderId="orderId" />
    </div>
  </div>
  <a-modal v-model:visible="checkShow" title="盘库" @ok="confirm" :destroyOnClose="true">
    <div>盘库类型</div>
    <a-select v-model:value="checkType" style="width: 120px">
      <a-select-option :value="1">正常</a-select-option>
      <a-select-option :value="2">异常上报</a-select-option>
    </a-select>
    <div v-show="checkType===2">原因</div>
    <a-select v-show="checkType===2" v-model:value="reason" style="width: 120px">
      <a-select-option :value="1">个人原因</a-select-option>
      <a-select-option :value="2">车辆异常</a-select-option>
      <a-select-option :value="3">同行原因</a-select-option>
    </a-select>
    <div>照片</div>
    <div class="row-div-upload">
      <a-upload multiple v-model::file-list="fileList" :remove="handleRemove" list-type="picture-card" :before-upload="beforeUpload">
        <div>
          <plus-outlined></plus-outlined>
          <div class="ant-upload-text">上传图片</div>
        </div>
      </a-upload>
      <div v-if="fileList.length > 0 ">
        <a-button :loading="fileLoading" @click="handleChange()">请点击上传保存</a-button>
      </div>
    </div>
    <div>备注</div>
    <a-textarea v-model:value="remark" placeholder="" :rows="4" />
  </a-modal>
  <a-modal v-model:visible="imgShow" :width="900" title="照片">
    <div style="display:flex;flex-wrap: wrap;justify-content: space-around;">
      <a-image-preview-group v-show="!loadImg">
        <a-image v-for="item in imgList" :key="item.id" style="margin-bottom:15px;" :width="200" :height="200" :src="item.url">
          <template #placeholder>
            <a-image :src="item.previewUrl" :width="200" :height="200" :preview="true" />
          </template>
        </a-image>
      </a-image-preview-group>
      <a-spin v-show="loadImg" />
    </div>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { check, checkDetail, transDetail, lookImgs } from '@/api/store/checkWarehouse'
import {uploadVehicle as upload} from '@/api/global'
import Record from '@/views/transportCapacity/transportUse/comon/record.vue'
import { message } from 'ant-design-vue'

export default {
  components: {
    Record,
    PlusOutlined
  },
  props: {
    orderVehicleId: String,
    orderVehicleNo: String,
    reload: Function
  },
  setup (props,context) {
    const recordRef = ref(null)
    const state = reactive({
      remark: '',
      reason: '',
      checkType: '',
      checkRecord: [],
      putWarehouse: {},
      transBase: {},
      transPreBase: {},
      fileList: [],
      imgList: [],
      loadImg: false,
      imgShow: false,
      fileLoading: false,
      loading: false,
      vehicleList: [],
      vehiclesId: '',
      addForm: {
        fileIds: []
      },
      checkShow: false,
      previewVisible: false,
      previewImage: ''
    })
    const loadData = () => {
      state.vehiclesId = props.orderVehicleId
      checkDetail({
        // abnormalType: state.reason,
        // fileIds: state.addForm.fileIds,
        // inventoryType: state.checkType,
        orderVehicleId: state.vehiclesId,
        remark: state.remark
      }).then(res => {
        if (res.code === 10000) {
          state.checkRecord = res.data.inventoryList
          state.putWarehouse = res.data.warehouseLatest
        }
      })
      transDetail(props.orderVehicleId).then(res => {
        if (res.code === 10000) {
          state.transBase = res.data.last
          state.transPreBase = res.data.now
        }
      })
    }
    const confirm = () => {
      if (state.addForm.fileIds.length > 0) {
        if (state.checkType === 1) state.reason = ''
        check({
          abnormalType: state.reason,
          fileIds: state.addForm.fileIds,
          inventoryType: state.checkType,
          orderVehicleId: props.orderVehicleId,
          remark: state.remark
        }).then(res => {
          if (res.code === 10000) {
            state.checkShow = false
            message.success(res.msg)
            state.reason = ''
            state.remark = ''
            state.fileList = []
            state.checkType = ''
            deleteEvery()
            loadData()
            recordRef.value.loadData()
            context.emit('status',true)
            // props.reload(2)
            // props.reload(3)
          }
        })
      } else message.error('请上传至少一张图片')
    }
    onMounted(() => {
      if (props.orderVehicleId && props.orderVehicleNo) state.vehicleList = [{ id: props.orderVehicleId, vinNo: props.orderVehicleNo }]
      loadData()
    })
    // 查看图片
    const lookImg = item => {
      state.imgShow = true
      state.loadImg = true
      lookImgs(item.id)
        .then(res => {
          if (res.code === 10000) {
            state.imgList = res.data
            state.loadImg = false
          }
        })
    }
    // 图片移除
    const handleRemove = file => {
      const index = state.fileList.indexOf(file)
      const newfileList = state.fileList.slice()
      newfileList.splice(index, 1)
      state.fileList = newfileList
      state.addForm.fileIds.splice(index, 1)
    }
    // 删除所有已展示图片
    const deleteEvery = () => {
      const images = document.querySelectorAll('.ant-upload-list-picture-card-container')
      state.addForm.fileIds = []
      images.forEach(item => {
        item.remove()
      })
    }
    // 选中图片
    const beforeUpload = file => {
      state.fileList.push(file)
      return false
    }
    // 图片上传
    const handleChange = () => {
      const len = state.fileList.length
      state.fileLoading = true
      updateBatch(0, len, state.fileList)
    }
    // 图片上传
    const updateBatch = (index, len, imageList) => {
      if (index <= len - 1) {
        const formData = new FormData()
        formData.append('file', imageList[index])
        upload(formData).then(res => {
          if (res.code === 10000) {
            state.addForm.fileIds.push(res.data.id)
            // data.fileUrl = res.data.previewUrl
            if (index === len - 1) {
              setTimeout(() => {
                state.fileLoading = false
                state.fileList = []
                message.success('图片上传成功')
              }, 1000)
            } else {
              updateBatch(++index, len, imageList)
            }
          }
        })
      } else {
        return false
      }
    }
    return {
      ...toRefs(state),
      lookImg,
      confirm,
      recordRef,
      deleteEvery,
      handleChange,
      handleRemove,
      beforeUpload
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
:deep(.ant-image-img) {
  height: 100% !important;
}
</style>
