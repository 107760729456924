<template>
  <div>
    <div class="itemTitle m-t1 flex ju-between al-center">
     <div>
      <span style="font-weight: bold;"> {{ item.title }}</span>&nbsp;
      <CountTo color="#0066CC" fontSize="16px" :endVal="item.num" />
     </div>
      <a-tooltip :title="item.define">
        <img class="tipsIcon" src="@/assets/images/newTransport/u60.png" alt="">
      </a-tooltip>
    </div>
    <div  v-if="item.subTitle" class="line"></div>
    <div v-if="!item.subTitle" class="itemBox flex wrap ju-between" style="margin-top: 0;font-size: 13px;">
      <div v-for="opt in item.subItemList" :key="opt.id" @click="openList(opt)" :style="{borderColor:colorType[opt.colorType]}" class="flex al-center stateStyle ju-between">
        {{ opt.title }}&nbsp;&nbsp;<span :style="{color:colorType[opt.colorType]}">{{ opt.num }}</span>
      </div>
    </div>
    <div v-else class="itemBox tips" style="font-size: 12px;color:#999">
      <span>{{ item.subTitle }}</span>
    </div>
    <div v-if="visible">
      <component :is="componentType" :idx="idx" v-model:visible="visible"></component>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import notTest from './components/notTest.vue'
import notEntrust from './components/notEntrust.vue';
import notPush from './components/notPush.vue';
import CountTo from '@/components/countTo'
import { colorType } from '@/utils/enum';
const props = defineProps({
  item: {
    type: Object,
    default: {}
  }
})
const componentType = ref(null)
const visible = ref(false)
const idx = ref(0)
const openList = (opt) => {
  switch (props.item.modalType) {
    case 1:
      componentType.value = notEntrust
      break;
    case 2:
      componentType.value = notTest
      break;
    case 3:
      componentType.value = notPush
      break;
  }
  if(opt){
    idx.value = opt.idx
  }
  console.log(idx.value);
  visible.value = true
}

</script>

<style lang="less" scoped>
.itemTitle {
  padding: 10px 0 0 10px;
  background: #f7f8fc;
  border-radius: 5px 5px 0 0;
}
.itemBox {
  padding: 0 10px;
  background: #f7f8fc;
  font-size: 14px;
  color: #333;
  padding-bottom: 10px;
  border-radius:  0 0 5px 5px;
  .tips {
    font-size: 12px;
     
    color: #999;
  }
}
.stateStyle {
  margin-top: 10px;
  padding: 4px 8px;
  background: #edf0f9;
  border-radius: 4px;
  width: 48%;
  border-left: 5px solid transparent;
}

.tips {
  border-radius:  0 0 5px 5px;
  padding-top: 10px;
}
.line{
  width: 100%;
  height: 6px;
  background: #F7F8FC;
  border-bottom: solid 1px #fff;
}
.tipsIcon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  cursor: pointer;
}
</style>