<template>
  <div style="position: relative;">
    <div class="data-paginate" v-show="statisticsList.length > 10">
      <LeftCircleFilled style="font-size:20px;color:#B0B8BF;cursor: pointer;" @click="leftMove" />
      <RightCircleFilled style="font-size:20px;color:#B0B8BF;cursor: pointer;margin-left:5px" @click="rightMove" />
    </div>
    <!-- 头部信息统计模块 -->
    <div class="shop-content-out">
      <div class="data-statistics">
        <div v-for="(item, index) in statisticsList" :key="index" class="data-statistics-content">
          <div class="statistics-content" :style="{ flex: index === statisticsList.length - 1 ? '1' : '' }"
            @click="statisticsSearch(item, index)">
            <div class="statistics-title">{{ item.cityName }}</div>
            <div class="data-statistics-data">{{ item.count }}</div>
            <div v-show="item.status === 1" class="data-status">待审核 00</div>
          </div>
          <a-divider type="vertical" style="height: 60px; background-color: #eee" />
        </div>
      </div>
    </div>
    <a-divider style="height: 10px;margin-top:0; background-color: #EEEEEE;border:none" />
    <GlobalTable :loading="loading">
      <template #headerSearch>
        <div style="display:flex;justify-content: space-between;">
          <a-form layout="inline" :model="searchForm">
            <a-form-item label="">
              <a-cascader style="width:200px" :show-search="{ filter }" :options="$store.state.app.PClist"
                v-model:value="searchForm.start" :changeOnSelect="true" @change="setStartAddress" placeholder="起始地" />
            </a-form-item>
            <a-form-item label="">
              <a-cascader style="width:200px" :show-search="{ filter }" :options="$store.state.app.PClist"
                v-model:value="searchForm.end" :changeOnSelect="true" @change="setEndAddress" placeholder="目的地" />
            </a-form-item>
            <a-form-item label="">
              <a-input v-model:value="searchForm.vinNo" style="width:120px" placeholder="车牌号" />
            </a-form-item>
            <a-form-item label="">
              <a-cascader v-model:value="searchForm.brand" placeholder="车辆型号" :options="$store.state.app.carBrand"
                :show-search="{ filter }" />
            </a-form-item>
            <a-form-item label="">
              <a-input v-model:value="searchForm.orderId" style="width:120px" placeholder="订单号" />
            </a-form-item>
            <a-form-item label="">
              <div class="operate-btn">
                <a-button class="header-search-btn" style="margin-right:5px" @click="searchData" :loading="loading">搜索</a-button>
                <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </template>
      <div class="table-data">
        <span>共{{ pagination.total }}条数据</span>
        <a-table size="small" :columns="columns" :row-key="record => record.vinNo" :data-source="outList" bordered
          :pagination="pagination" :loading="loading" @change="pageChange">
          <template #operation="{ record }">
            <div>
              <a-popconfirm title="确认是否立即出库?" ok-text="是" cancel-text="否" @confirm="confirmOut(record)">
                <a type="primary" size="small" style="font-size: 12px">立即出库</a>
              </a-popconfirm>
            </div>
          </template>
          <template #vehicle="{ record }">
            <div>
              {{ record.vinNo + '-' + record.brand + record.model }}
            </div>
          </template>
          <template #outImg="{record}">
            <div>
              <a-image v-if="record.fileRealUrl" :src="record.fileRealUrl" style="width:25px;height:25px"/>
              <span v-show="!record.fileRealUrl">暂无盘库照片</span>
            </div>
          </template>
          <template #address="{ record }">
            <div style="text-align:left">
              <div style="maring-right:10px">起：{{ record.startAddress }}</div>
              <div>终：{{ record.endAddress }}</div>
            </div>
          </template>
          <template #detail="{ record }">
            <div>
              <a @click="lookDetail(record)">查看>></a>
            </div>
          </template>
        </a-table>
      </div>
    </GlobalTable>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons-vue'
import { newOutWarehousePage, getAddressData, out } from '@/api/store/outWarehouse'
import { message } from 'ant-design-vue'
import GlobalTable from '@/components/GlobalTable'
import CheckDetail from '@/views/store/storeUse/detail/checkDetail.vue'

export default {
  components: {
    LeftCircleFilled,
    RightCircleFilled,
    GlobalTable,
    CheckDetail
  },
  setup (props, context) {
    const state = reactive({
      loading: false,
      searchOrgId: null,
      searchForm: {
        vinNo: '',
        orderId: '',
        startAddress: {},
        endAddress: {}
      },
      mirrorSearchForm: {},
      element: {},
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['5', '10', '15', '20'],
        pageSize: 10
      },
      statisticsList: [],
      outList: [],
      columns: [
        {
          title: '操作',
          align: 'center',
          width: '3%',
          slots: {
            customRender: 'operation'
          }
        },
        {
          title: '订单号',
          dataIndex: 'orderId',
          width: '9.5%',
          align: 'center'
        },
        {
          title: '车牌号/车架-车型',
          width: '9.5%',
          align: 'center',
          slots: {
            customRender: 'vehicle'
          }
        },
        {
          title: '起-终',
          width: '9.5%',
          align: 'center',
          slots: {
            customRender: 'address'
          }
        },
        {
          title: '最后一次盘库状态',
          dataIndex: 'inventoryStatus.label',
          width: '9.5%',
          align: 'center'
        },
        {
          title: '最后一次盘库照片',
          dataIndex: 'outImg',
          width: '9.5%',
          align: 'center',
          slots: {
            customRender: 'outImg'
          }
        },
        {
          title: '车辆详情',
          dataIndex: 'detail',
          width: '9.5%',
          align: 'center',
          slots: {
            customRender: 'detail'
          }
        }
      ]
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const loadData = (type) => {
      state.loading = true
      newOutWarehousePage({
        ...state.searchForm,
        isShop: 1,
        orgCityId: state.searchOrgId,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          if (res.data) {
            state.pagination.total = res.data.length
            state.outList = res.data
          } else message.error('暂无数据')
        }
      }).finally(() => { state.loading = false })
      if(!type) getStatistics()
    }
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startAddress = {}
      state.searchForm.startAddress.province = value[0]
      state.searchForm.startAddress.city = value[1]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endAddress = {}
      state.searchForm.endAddress.province = value[0]
      state.searchForm.endAddress.city = value[1]
    }
    const getStatistics = () => {
      getAddressData().then(res => {
        if (res.code === 10000) {
          state.statisticsList = res.data
        }
      })
    }
    const statisticsSearch = (item, index) => {
      let DOMS = document.querySelectorAll('.shop-content-out .statistics-content')
      if (state.globalIndex !== index) {
        state.globalIndex = index
        for (let i = 0; i < DOMS.length; i++) {
          DOMS[i].style.color = '#344563'
          DOMS[i].style.background = ''
          if (i === index) {
            DOMS[index].style.color = '#fff'
            DOMS[index].style.background = 'rgba(0,102,204,.8)'
            DOMS[index].style.borderRadius = '8px'
          }
        }
        state.searchOrgId = item.cityId
        state.pagination.current = 1
        loadData(1)
      } else {
        DOMS[state.globalIndex].style.color = '#344563'
        DOMS[state.globalIndex].style.background = ''
        state.searchOrgId = null
        state.globalIndex = null
        state.pagination.current = 1
        loadData(1)
      }
    }
    const pageChange = page => {
      state.pagination = page
    }
    const resetData = () => {
      state.loading = true
      state.outList = []
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.pagination.current = 1
      loadData()
    }
    const lookDetail = (record) => {
      state.element = record
      context.emit('detail', record)
    }
    const searchData = () => {
      state.pagination.current = 1
      state.outList = []
      loadData()
    }
    // 单台车出库
    const confirmOut = item => {
      out({
        orderVehicleIds: [item.orderVehicleId]
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
        }
      })
    }
    const leftMove = () => {
      let DOM = document.querySelector('.shop-content-out .data-statistics')
      let left = Number(DOM.style.left.replace('vw', ''))
      if (left < 0) {
        DOM.style.left = left + 8 + 'vw'
      }
    }
    const rightMove = () => {
      let DOM = document.querySelector('.shop-content-out .data-statistics')
      let left = Number(DOM.style.left.replace('vw', ''))
      DOM.style.left = left - 8 + 'vw'
    }
    return {
      ...toRefs(state),
      leftMove,
      rightMove,
      loadData,
      lookDetail,
      searchData,
      resetData,
      pageChange,
      confirmOut,
      getStatistics,
      setStartAddress,
      setEndAddress,
      statisticsSearch
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';

.data-paginate {
  position: absolute;
  right: 10px;
  top: -10px;
  z-index: 99;
}
</style>