import { postBodyRequest, exportRequest, getRequest } from '@/utils/axios'

export function statistics (carrierId, params) {
  return postBodyRequest(`/trans/finance/settlement/${carrierId}/carrier/total`, params)
}

export function billOrder (carrierId, params) {
  return postBodyRequest(`/trans/finance/settlement/${carrierId}/billOrder`, params)
}

export function billVipOrder (params) {
  return postBodyRequest('/trans/finance/settlement/billOrder', params)
}

export function billCarrierOrder (carrierId, params) {
  return postBodyRequest(`/trans/finance/settlement/${carrierId}/carrier/billOrder`, params)
}

export function billCarrierTrans (carrierId, params) {
  return postBodyRequest(`/trans/finance/settlement/${carrierId}/carrier/billTrans`, params)
}

export function vehicleBillDetails (params) {
  return postBodyRequest('/trans/finance/vehicle/quantity/details', params)
}

export function vehicleBillDetailsPage (params) {
  return postBodyRequest('/trans/finance/vehicle/quantity/page', params)
}

export function receivablePayable (params) {
  return postBodyRequest('/trans/finance/bill/receivablePayable', params)
}
export function receivablePayableExport (params, fileName) {
  return exportRequest('/trans/finance/bill/receivablePayable/export', params, fileName)
}
export function receivablePayableDetail (params) {
  return postBodyRequest('/trans/finance/bill/receivablePayable/detail', params)
}
export function receivablePayableDetailExport (params, fileName) {
  return exportRequest('/trans/finance/bill/receivablePayable/detail/export', params, fileName)
}

export function vehicleBillExport (params, fileName) {
  return exportRequest('/trans/finance/vehicle/quantity/export', params, fileName)
}

export function billOrderExport (carrierId, params, fileName) {
  return exportRequest(`/trans/finance/bill/${carrierId}/billOrder/export`, params, fileName)
}
//财务-发票明细-导出
export function billInvoiceExport (params, fileName) {
  return exportRequest('/trans/finance/bill-invoice/export', params, fileName)
}

export function orderReportDetails (params) {
  return postBodyRequest('/trans/finance/order/details', params)
}

export function orderReportPage (params) {
  return postBodyRequest('/trans/finance/order/page', params)
}

export function orderReportExport (params, fileName) {
  return exportRequest('/trans/finance/order/export', params, fileName)
}

export function billAdditionalFee (params) {
  return postBodyRequest('/trans/finance/settlement/billAdditionalFee', params)
}

export function orderRefund (customerId, params) {
  return postBodyRequest(`/trans/finance/settlement/${customerId}/orderRefund`, params)
}

export function transReportPage (params) {
  return postBodyRequest('/trans/finance/trans/page', params)
}

export function transReport (params) {
  return postBodyRequest('/trans/finance/trans/details', params)
}

export function transReportExport (params, fileName) {
  return exportRequest('/trans/finance/trans/export', params, fileName)
}

export function reconciliationPage (params) {
  return postBodyRequest('/trans/finance/reconciliation/page', params)
}

export function reconciliationAdd (params) {
  return postBodyRequest('/trans/finance/reconciliation/add', params)
}

export function reconciliationDetail (id) {
  return postBodyRequest(`/trans/finance/reconciliation/${id}`)
}

export function reconciliationExport (id, type, fileName) {
  return exportRequest(`/trans/finance/reconciliation/export/${id}/${type}`, {}, fileName)
}

export function reconciliationDelete (id) {
  return postBodyRequest(`/trans/finance/reconciliation/${id}/delete`, {})
}

export function reconciliationEdit (params) {
  return postBodyRequest('/trans/finance/reconciliation/edit', params)
}
export function reconciliationDeleteByDetailId (id, detailId, type) {
  return postBodyRequest(`/trans/finance/reconciliation/${id}/delete/${detailId}/${type}`, {})
}

// 新版应收应付
export function receivablePayableNewExport (params, fileName) {
  return exportRequest('/trans/finance/bill/receivablePayable/new/export', params, fileName)
}

export function receivablePayableNewTransExport (params, fileName) {
  return exportRequest('/trans/finance/bill/receivablePayable/trans/export', params, fileName)
}

export function receivablePayableNewArrivalExport (params, fileName) {
  return exportRequest('/trans/finance/bill/receivablePayable/arrival/export', params, fileName)
}

export function receivablePayableNewOrderExport (params, fileName) {
  return exportRequest('/trans/finance/bill/receivablePayable/order/export', params, fileName)
}

export function receivablePayableNewOrderInnerExport (params, fileName) {
  return exportRequest('/trans/finance/bill/receivablePayable/orderInner/export', params, fileName)
}

export function getsandqrcode (params) {
  return postBodyRequest('/trans/aggregate/getReconcQRCode', params)
}

export function reconciliationMerge (params) {
  return postBodyRequest('/trans/finance/reconciliation/merge', params)
}

export function splitTransBill (ftsId) {
  return getRequest(`/trans/finance/reconciliation/split/trans/${ftsId}`, null)
}
