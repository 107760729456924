import {
  createApp,
  h
} from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/locale'
import storage from 'store'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import './global.less'
import Cookies from 'js-cookie'

import bootstrap from '@/core/bootstrap'

import moment from 'moment'
import Print from 'vue3-print-nb'
import './permission' // permission control


if (Cookies.get('accessToken') !== undefined && Cookies.get('accessToken') !== '') {
  storage.set('accessToken', Cookies.get('accessToken'))
}
createApp({
  beforeCreate: bootstrap,
  render: () => h(App)
})
  .use(i18n)
  .use(store)
  .use(router)
  .use(Print)
  .use(Antd)
  .use(moment)
  .mount('#app')
