import {
  getRequest,
  postBodyRequest
} from '@/utils/axios'

// 门店任务分页
export function getShopPlantList (params) {
  return postBodyRequest('/trans/order/vehicle-task/carrier/page', params)
}
// 门店数据统计
export function shopPlantStatistics (params) {
  return postBodyRequest('/trans/order/vehicle-task/carrier/statistics', params)
}
// 门店任务接拒单
export function shopPlantReceiving (params) {
  return postBodyRequest('/trans/order/vehicle-task/receiving', params)
}
