<template>
  <a-drawer v-model:visible="modalVisible" title="GPS详情" placement="right" width="86%" :getContainer="false" destroyOnClose>
    <a-table :dataSource="listVehicleTransData" :row-key="record => record.createTime"  :pagination="false">
      <a-table-column title="序号">
        <template #default="{ index }">{{index+1}}</template>
      </a-table-column>
<!--      <a-table-column title="派车人" data-index="dispatcher" />-->
<!--      <a-table-column title="运输车牌" data-index="carNo" />-->
      <a-table-column title="线路" data-index="lineName" />
      <a-table-column title="GPS操作人" data-index="operator" />
      <a-table-column title="操作时间" data-index="createTime" />
      <a-table-column title="更新地址" data-index="location" />
    </a-table>
  </a-drawer>
</template>

<script>
import { vehicleGpsDetail } from '@/api/transport/truck'
import { reactive, toRefs } from 'vue'

export default ({
  setup () {
    const state = reactive({
      loading: false,
      modalVisible: false,
      listVehicleTransData: [],
      pagination: {},
      listData: [],
      orderVehicleId: ''
    })

    const onvisible = (parameter) => {
      state.listVehicleTransData = []
      state.modalVisible = parameter
    }
    const onorderVehicleId = (parameter) => {
      state.loading = true
      state.orderVehicleId = parameter
      vehicleGpsDetail(state.orderVehicleId, {
      }).then(res => {
        if (res.code === 10000) {
          state.listVehicleTransData = res.data
          state.loading = false
        }
      })
    }
    return {
      ...toRefs(state),
      onvisible,
      onorderVehicleId
    }
  }
})
</script>
