<template>
  <a-modal v-model:visible="positionShowModal" title="位置查询" :zIndex="GPSindex" @cancel="closePositionShowModal" :footer="null">
    <a-row>
      <a-col :span="20">
        <a-form-item label="大车车牌">
          <a-input v-model:value="positionCarNo" />
        </a-form-item>
      </a-col>
      <a-col :span="20">
        <a-radio-group v-model:value="gpsType">
          <a-radio :value=1>智运GPS</a-radio>
          <a-radio :value=4>途强GPS</a-radio>
        </a-radio-group>
      </a-col>
      <a-col :span="4">
        <a-button type="primary" :loading="positionLoading" @click="queryPosition">查询</a-button>
      </a-col>
      <a-col :span="20" v-if="presentPosition!=''">
        {{presentPosition}}
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>

import { toRefs, reactive } from 'vue'
import { message } from 'ant-design-vue'
import { transTransportTruckGpsCarNoInfo } from '@/api/transport/transport'
export default {
  props:{
    GPSindex: Number
  },
  setup (props) {
    const state = reactive({
      positionShowModal: false,
      positionLoading: false,
      positionCarNo: '',
      gpsType: 1,
      GPSindex: props.GPSindex,
      presentPosition: ''
    })

    const queryPosition = () => {
      state.positionLoading = true
      transTransportTruckGpsCarNoInfo({ carNo: state.positionCarNo }, state.positionCarNo, state.gpsType).then(res => {
        if (res.code === 10000) {
          state.presentPosition = res.data
        }
        state.positionLoading = false
      }).catch(err => {
        state.positionLoading = false
        message.success(err)
      })
    }
    const closePositionShowModal = () => {
      state.positionCarNo = ''
      state.presentPosition = ''
    }
    const onSwitch = (parameter, carNoQuery) => {
      state.positionShowModal = parameter
    }

    const onAutoQuery = (parameter, carNoQuery) => {
      state.positionShowModal = parameter
      state.positionCarNo = carNoQuery
    }

    return {
      onSwitch,
      onAutoQuery,
      ...toRefs(state),
      queryPosition,
      closePositionShowModal
    }
  }

}
</script>
<style lang="less" scoped>
</style>
