import { postBodyRequest, getRequest } from '@/utils/axios'

export function getPage (params) {
  return postBodyRequest('/carrier/carrier/driver/page', params)
}

export function addDriver (params) {
  return postBodyRequest('/carrier/carrier/driver/add', params)
}

export function driverDetail (carrierId, carrierDriverId, params) {
  return getRequest(`/carrier/carrier/${carrierId}/driver/${carrierDriverId}`, params)
}

export function deleteDriver (params) {
  return getRequest('/carrier/carrier/driver/delete', params)
}

export function edit (params) {
  return postBodyRequest('/carrier/carrier/driver/edit', params)
}

export function editPhoto (params) {
  return postBodyRequest('/carrier/carrier/driver/photo', params)
}
export function getCarrierIdCarrierDriverId (carrierId, carrierDriverId) {
  return getRequest(`/carrier/carrier/${carrierId}/driver/${carrierDriverId}`, {})
}

export function Freepage (params) {
  return postBodyRequest('/carrier/carrier/driver/page/free', params)
}
