<template>
  <a-modal :visible="windowShow" title="异常审核" footer="" width="70%" @cancel="cancelClose"
    :bodyStyle="{ paddingRight: 0 }">
    <div>
      <div class="search-div">
        <a-form layout="inline" :model="searchForm">
          <a-form-item label="提交时间">
            <a-range-picker style="width:200px" format="YYYY-MM-DD" v-model:value="chooseTime" @change="timeChange" />
          </a-form-item>
          <a-form-item label="上报类型">
            <a-select style="width:120px" v-model:value="searchForm.reportType" placeholder="选择">
              <a-select-option :value="2">异常上报</a-select-option>
              <a-select-option :value="1">费用上报</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="审核状态">
            <a-select style="width:120px" v-model:value="searchForm.auditStatus" placeholder="选择">
              <a-select-option :value="0">待审核</a-select-option>
              <a-select-option :value="1">已通过</a-select-option>
              <a-select-option :value="2">未通过</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="车牌号">
            <a-input style="width:120px" v-model:value="searchForm.carNo" placeholder="" />
          </a-form-item>
          <a-form-item label="上报人">
            <a-input style="width:130px" v-model:value="searchForm.explainer" placeholder="输入上报人名称" />
          </a-form-item>
          <a-form-item label="">
            <div class="operate-btn">
              <a-button class="header-search-btn" @click="searchData" :loading="loading">搜索</a-button>
              <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <div>共<span style="color:#0066CC">{{ total }}</span>个上报</div>
      <!-- 异常上报内容 -->
      <div class="left-content" style="width:100%">
        <div class="content-data" style="margin-bottom:10px;position: relative" v-for="(item, index) in dataList"
          :key="index">
          <a-row :gutter="[24, 6]">
            <a-col :span="2">
              <span class="data-color">上报类型</span>
            </a-col>
            <a-col :span="4">
              <span class="title-color">{{ item.reportType?.label }}</span>
            </a-col>
            <a-col :span="2">
              <span class="data-color">上报车辆</span>
            </a-col>
            <a-col :span="4">
              <span class="title-color">{{ item.carNo }}</span>
            </a-col>
            <a-col :span="2">
              <span class="data-color">上报人</span>
            </a-col>
            <a-col :span="4">
              <span class="title-color">{{ item.explainer }}</span>
            </a-col>
            <a-col :span="2">
              <span class="data-color">上报时间</span>
            </a-col>
            <a-col :span="4">
              <span class="title-color">{{ item.explainTime }}</span>
            </a-col>
            <!-- 分隔线 -->
            <a-col :span="2">
              <span class="data-color">异常类型</span>
            </a-col>
            <a-col :span="4">
              <span class="title-color">{{ item.exceptionType?.label }}</span>
            </a-col>
            <a-col :span="2" v-show="item.reportType?.value === 1">
              <span class="data-color">费用金额</span>
            </a-col>
            <a-col :span="4" v-show="item.reportType?.value === 1">
              <span class="title-color">{{ item.amount }}</span>
            </a-col>
            <a-col :span="2">
              <span class="data-color">异常原因</span>
            </a-col>
            <a-col :span="4">
              <span class="title-color">{{ item.exceptionSubType?.label }}</span>
            </a-col>
            <a-col :span="2">
              <span class="data-color">异常照片</span>
            </a-col>
            <a-col :span="4">
              <a-image v-if="item.fileRealUrl" :width="20" :height="20" :src="item.fileRealUrl" />
            </a-col>
            <a-col :span="2">
              <span class="data-color">所属任务段</span>
            </a-col>
            <a-col :span="4">
              <span class="title-color">{{ item.transportType?.label }}</span>
            </a-col>
            <!-- 分隔线 -->
            <a-col :span="2">
              <span class="data-color">备注</span>
            </a-col>
            <a-col :span="4">
              <span class="title-color">{{ item.exceptionExplain }}</span>
            </a-col>
            <a-col :span="2">
              <span class="data-color">审核结果</span>
            </a-col>
            <a-col :span="4">
              <span class="title-color" :style="{ color: item.auditStatus.value === 1 ? '#0C8226' : '#D9001B' }">{{
                item.auditStatus?.label }}</span>
              <a-tooltip>
                <template #title>{{ item.auditRemark || '没有审核备注' }}</template>
                <MessageOutlined
                  :style="{ color: item.auditStatus.value === 1 ? '#0C8226' : '#D9001B', marginLeft: '5px' }" />
              </a-tooltip>
            </a-col>
            <a-col :span="2">
              <span class="data-color">审核人</span>
            </a-col>
            <a-col :span="4">
              <span class="title-color">{{ item.follower || '' }}</span>
            </a-col>
            <a-col :span="2">
              <span class="data-color">审核时间</span>
            </a-col>
            <a-col :span="4">
              <span class="title-color">{{ item.followTime || '' }}</span>
            </a-col>
          </a-row>
          <div class="check-btn" v-show="item.auditStatus?.value !== 2 && item.auditStatus?.value !== 1">
            <a-button class="blue-btn" @click="checkForm.id = item.id, checkShow = true">立即审核</a-button>
          </div>
        </div>
        <div style="text-align: center;">
          <a v-show="dataList.length < total" @click="getMoreData">加载更多数据...</a>
          <span v-show="dataList.length >= total" style="color:#0066CC">没有更多数据啦~</span>
        </div>
      </div>
    </div>
  </a-modal>
  <a-modal v-model:visible="checkShow" title="审核" @ok="confirmCheck" @cancel="checkForm = JSON.parse(mirrorCheckForm)">
    <a-form :model="checkForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
      <a-form-item label="审核结果">
        <a-select v-model:value="checkForm.auditStatus" placeholder="同意或拒绝">
          <a-select-option :value="1">同意</a-select-option>
          <a-select-option :value="2">拒绝</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea v-model:value="checkForm.auditRemark" placeholder="填写备注信息" :rows="4" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRefs, watch, onMounted } from 'vue'
import { abnomalList, checkOperate } from '@/api/transport/transport'
import { message } from 'ant-design-vue'
import { MessageOutlined } from '@ant-design/icons-vue'

export default {
  props: {
    windowShow: Boolean
  },
  components: {
    MessageOutlined
  },
  setup (props, context) {
    const state = reactive({
      windowShow: false,
      loading: false,
      checkShow: false,
      chooseTime: '',
      total: '',
      searchForm: {
        explainStartTime: null,
        explainEndTime: null,
        auditStatus: null,
        reportType: null,
        explainer: ''
      },
      checkForm: {
        id: '',
        carNo: '',
        auditRemark: null,
        auditStatus: null
      },
      mirrorCheckForm: {},
      mirrorSearch: {},
      dataList: [],
      pagination: {
        current: 1,
        size: 5
      }
    })

    watch(() => props.windowShow, (newVal, oldVal) => {
      state.windowShow = props.windowShow
      if (state.windowShow === true) {
        loadData()
      }
    })
    onMounted(() => {
      state.mirrorSearch = JSON.stringify(state.searchForm)
      state.mirrorCheckForm = JSON.stringify(state.checkForm)
    })
    const loadData = () => {
      state.loading = true
      abnomalList({
        ...state.searchForm,
        ...state.pagination
      }).then(res => {
        if (res.code === 10000) {
          if (res.data.records.length <= 0 && state.pagination.current > 1) state.pagination.current--
          state.total = res.data.total
          res.data.records.forEach(item => {
            state.dataList.push(item)
          })
        }
      }).finally(() => { state.loading = false })
    }
    const searchData = () => {
      state.dataList = []
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.dataList = []
      state.pagination.current = 1
      state.chooseTime = ''
      state.searchForm = JSON.parse(state.mirrorSearch)
      loadData()
    }
    const getMoreData = () => {
      state.pagination.current++
      loadData()
    }
    const cancelClose = () => {
      state.windowShow = false
      context.emit('statusShow', state.windowShow)
    }
    const timeChange = (e, v) => {
      state.searchForm.explainStartTime = e[0]
      state.searchForm.explainEndTime = e[1]
    }
    const confirmCheck = () => {
      if (state.checkForm.auditStatus) {
        if (state.checkForm.auditStatus !== 2) requestAPI()
        else {
          if (state.checkForm.auditStatus === 2 && state.checkForm.auditRemark) {
            requestAPI()
          } else message.error('请填写拒绝原因')

        }
      } else message.error('请选择同意或拒绝')
      function requestAPI () {
        checkOperate(state.checkForm).then(res => {
          if (res.code === 10000) {
            message.success('审核结果已提交')
            state.checkShow = false
            searchData()
          }
        })
      }
    }

    return {
      ...toRefs(state),
      cancelClose,
      timeChange,
      searchData,
      resetData,
      getMoreData,
      confirmCheck
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

:deep(.ant-row) {
  font-size: 14px;
}

:deep(.ant-image-img) {
  width: 25px;
  height: 25px;
}

.left-content {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.check-btn {
  position: absolute;
  right: 10px;
  bottom: 0;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>