<template>
  <div>
    <a-modal :visible="visible" :footer="null" width="90%" @cancel="cancel">
      <template #title>
        <div>
          派单操作
        </div>
      </template>
      <div class="flex">
        <div style="width: 58%">
          <div class="titel m-b1">已选车辆</div>
          <div class="tableBox">
            <a-table size="small" :columns="columnsSelected" bordered :row-key="record => record.id" :data-source="selectedList" :pagination="false">
              <template #index="{ index }">
                {{ index + 1  }}
              </template>
              <template #brand="{ record }">
                {{ record.brand }}{{ record.model }}
              </template>
              <template #add="{record}">
                <a v-if="!confirmStatus" @click="remove(record)">移除</a>
                <span v-else class="disabled">移除</span>
              </template>
            </a-table>
          </div>
          <div class="flex ju-end m-t1"><a-button @click="submit" :disabled="selectedList.length===0 || confirmStatus" type="primary">确认选择</a-button></div>
          <div class="titel m-t1">可选车辆</div>
          <SearchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
          <a-table size="small" :columns="columnsOptional" :loading="loading" @change="handleTableChange" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange ,getCheckboxProps: checkBox}" bordered :row-key="record => record.orderVehicleId" :data-source="optionalList" :pagination="pagination">
            <template #brand="{ record }">
              {{ record.brand }}{{ record.model }}
            </template>
            <template #transportType="{ record }">
              {{ record.transportType.label }} {{ record.transStatus.label }}
            </template>
            <template #add="{record}">
              <a v-if="!confirmStatus" @click="celAdd(record)">选择</a>
              <span v-else class="disabled">选择</span>
            </template>
          </a-table>
        </div>
        <div class="line"></div>
        <div style="width:40%;padding: 10px" v-if="confirmStatus">
          <submitInfo ref="infoRef" @status="status"></submitInfo>
        </div>
      </div>
    </a-modal>
  

  </div>
</template>

<script setup>
import { nextTick, onMounted, ref} from 'vue'
import SearchList from '@/components/searchList'
import { vehicleAddVehiclePage } from '@/api/transport/transport' 
import { message } from 'ant-design-vue';
import submitInfo from './components/submitInfo.vue'
import { cloneDeep } from 'lodash-es';

const emit = defineEmits(['update:visible','status'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})

const infoRef = ref(null)
const selectedList = ref([])
const optionalList = ref([])
const selectedRowKeys = ref([])
const confirmStatus = ref(false)
const loading = ref(false)
const searchMsg = ref({})
const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: true,
  showTotal: (total, range) => `共 ${total} 条`
})

//搜索
const searchAdd = (e) => {
  pagination.value.current = 1
  searchMsg.value = cloneDeep(e)
  reqGetVehcileAll()
}
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  reqGetVehcileAll()
}
const handleTableChange = (e) => {
  pagination.value = e
  reqGetVehcileAll()
}
const celAdd = (e) => {
  if (selectedRowKeys.value.length > 0) {
    let status = selectedRowKeys.value.includes(e.orderVehicleId)
    if (status) {
      message.error('该车已经是选中状态了')
    } else {
      selectedRowKeys.value.push(e.orderVehicleId)
      selectedList.value.push(e)
    }
  } else {
    selectedRowKeys.value.push(e.orderVehicleId)
    selectedList.value.push(e)
  }
}

const status = () => {
  cancel()
  emit('status')
}

const remove = (e) => {
  let index = selectedList.value.findIndex(item => item.vinNo == e.vinNo)
  selectedList.value.splice(index, 1)
  selectedRowKeys.value.splice(index, 1)
}
const onSelectChange = (e, v) => {
  selectedRowKeys.value = e
  selectedList.value = v
}
const submit = () => {
  let orderId = selectedList.value[0].orderId
  let flag = selectedList.value.every(item => item.orderId === orderId)
  if(!flag){
   message.error('批量派单只能选择同一订单的车辆，请重新选择')
   return
  }
  confirmStatus.value = true
  reqGetVehcileAll()
  nextTick(() => {
    infoRef.value.reqVehicleTaskType(selectedList.value.map(item => item.orderVehicleId))
  })
}
function filterArr (arr, path) {
  let data = arr[0]
  let flag = path.map(item => arr.every(opt => opt[item] === data[item]))
  return flag
}
const checkBox = record => {
  let obj = {}
  if (confirmStatus.value === true) {
    obj.disabled = true
  } else {
    obj.disabled = false
  }
  return obj
}

const cancel = () => {
  emit('update:visible', false)
}
const reqGetVehcileAll = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    page: pagination.value.pageSize
  }
  Object.assign(msg, searchMsg.value)
  vehicleAddVehiclePage(msg).then(res => {
    if (res.code !== 10000) return
    optionalList.value = res.data.records
    pagination.value.total = res.data.total
    console.log(res);
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  reqGetVehcileAll()
})


const columnsOptional = ref([
  {
    title: '车牌号',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '车型',
    dataIndex: 'brand',
    slots: { customRender: 'brand' },
    align: 'center',
  },
  {
    title: '运输类型',
    dataIndex: 'transportType',
    slots: { customRender: 'transportType' },
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起运地',
    dataIndex: 'startAddress',
    align: 'center',
  },
  {
    title: '目的地',
    dataIndex: 'endAddress',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: { customRender: 'add' },
    align: 'center',
    width: 60
  }
])
const columnsSelected = ref([
  {
    title: '车牌号',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '车型',
    dataIndex: 'brand',
    slots: { customRender: 'brand' },
    align: 'center',
  },
  {
    title: '运输类型',
    dataIndex: 'transportType.label',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center',
  },
  {
    title: '起运地',
    dataIndex: 'startAddress',
    align: 'center',
  },
  {
    title: '目的地',
    dataIndex: 'endAddress',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: { customRender: 'add' },
    align: 'center',
    width: 60
  }
])
const iptData = ref([
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "orderId",
    width: 150,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "select",
    placeholder: "任务段",
    value: null,
    prop: "type",
    width: 150,
    opt: [
      {name:'验',id:0},
      {name:'提',id:1},
      {name:'干',id:2},
      {name:'送',id:3}
    ]
  },
])
</script>

<style lang="less" scoped>
.titel {
  font-size: 14px;
  font-weight: 600;
  color: #344563;
}

.line {
  width: 10px;
  height: auto;
  margin: 0 10px;
  background: rgb(244, 245, 249);
}
.text {
  font-size: 16px;
  color: #191919;
  font-weight: 700;
}
.taskBox {
  width: 100%;
  padding: 20px 20px 10px 20px;
  border: 1px solid #eee;
}

.taskItem {
  font-size: 12px;
  font-weight: 700;
}
.taskValue {
  font-size: 12px;
  color: #6b778c;
}

.infoBox {
  border: 1px solid #d9d9d9;
  padding: 6px 8px;
  border-radius: 4px;
}
.chengeStyle {
  :deep(.ant-form-item-label) {
    width: 100%;
    text-align: left;
  }
  :deep(.ant-form-item) {
    margin-bottom: 5px;
  }
}

.tableBox {
  max-height: 220px;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: #eee;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(185, 183, 183);
  border-radius: 10px;
}

.disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: no-drop;
}
</style>