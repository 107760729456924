<template>
  <a-modal :visible="windowShow" footer="" @ok="handleOk" width="70%" @cancel="cancelClose"
    :bodyStyle="{ padding: '0 0 15px 15px' }">
    <template #title>
      <div>
        <span v-if="monitorData.trans">{{ '发运详情——' + monitorData.trans[0]?.vinNo || '' }}</span>
      </div>
    </template>
    <div style="display:flex">
      <div class="left-content">
        <!-- 基本信息 -->
        <div>
          <div class="content-title">
            <span>派单基本信息</span>
            <a @click="abnormalReportShow = true">异常列表</a>
          </div>
          <div class="content-data" v-if="type !== 1">
            <a-row :gutter="[24, 8]">
              <a-col :span="3">
                <span class="title-color">车牌号&emsp;</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color" v-if="monitorData.trans">{{ monitorData.trans[0]?.vinNo }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">是否验车打款</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ monitorData.isInspectionPay?.label || '' }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">取车类型</span>
              </a-col>
              <a-col :span="5">
                <span v-if="monitorData.trans">{{ monitorData.trans[0]?.pickType?.label || '' }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">车型&emsp;&emsp;</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color" v-if="monitorData.trans">{{ monitorData.trans[0]?.brand +
                  monitorData.trans[0]?.model }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">是否交车条</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ monitorData.isDeliveryCard?.label || '' }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">取车地址</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">
                  <span>{{ monitorData.lineStart }}</span>
                </span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">派单任务</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color">
                  <span>{{ monitorData.taskName }}</span>
                </span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">派单人</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ monitorData.arranger }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">派单价格</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ monitorData.totalAmt }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">承运商&emsp;</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color">{{ monitorData.carrierName }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">派单时间</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ monitorData.scheduleTime }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">结算方式</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ taskDetail.orderSettlement?.label }}</span>
              </a-col>
            </a-row>
          </div>
          <div class="content-data" v-else>
            <a-row :gutter="[24, 8]">
              <a-col :span="3">
                <span class="title-color">派单任务</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color">{{ monitorData.taskName }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">车牌</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ taskDetail.vinNo }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">当前状态</span>
              </a-col>
              <a-col :span="5">
                <span v-if="monitorData.trans">{{ monitorData.taskStatus?.label }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">派单价&emsp;</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color">{{ monitorData.totalAmt }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">车型</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ taskDetail.brand + ' ' + taskDetail.model }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">验车打款</span>
              </a-col>
              <a-col :span="5">
                <span>{{ monitorData.isInspectionPay?.label || '' }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">派单时间</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color">{{ monitorData.scheduleTime }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">车辆状态</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ monitorData.status?.label || '' }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">收款状态</span>
              </a-col>
              <a-col :span="5">
                <span>{{ monitorData.settlementStatus?.label || '' }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">派单人&emsp;</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color">{{ monitorData.arranger }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">接单时间</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ monitorData.receivingTime }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">交车条</span>
              </a-col>
              <a-col :span="5">
                <span>{{ monitorData.isDeliveryCard?.label || '' }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">起运地&emsp;</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color">{{ monitorData.lineStart }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">目的地</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ monitorData.lineEnd }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">等通知放车</span>
              </a-col>
              <a-col :span="5">
                <span>{{ monitorData.isNoticeReleaseCar?.label }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">派单备注</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color" style="word-break: break-all;color:#f00">{{ monitorData.remark }}</span>
              </a-col>
            </a-row>
          </div>
        </div>
        <!-- 任务时效 -->
        <div class="plant-time">
          <div class="content-title">任务时效</div>
          <div class="content-data">
            <a-row :gutter="[24, 4]">
              <a-col :span="3">
                接单时间
              </a-col>
              <a-col :span="8">
                {{ monitorData.receivingTime }}
              </a-col>
              <a-col :span="3">
                完成时间
              </a-col>
              <a-col :span="6">
                {{ monitorData.finishTime }}
              </a-col>
            </a-row>
            <!-- <a-steps progress-dot :current="3">
              <a-step status="">
                <template #title>
                  <div>
                    <span>接单</span>
                  </div>
                </template>
                <template #description>
                  <div>
                    <div>
                      最晚接单时间：XX-XX 00:00
                    </div>
                    <div>
                      实际接单时间：XX-XX 00:00
                    </div>
                    <div>
                      接单时效：X分钟
                    </div>
                  </div>
                </template>
              </a-step>
              <a-step status="">
                <template #title>
                  <div>
                    <span>运输</span>
                  </div>
                </template>
                <template #description>
                  <div>
                    <div>
                      最晚到达时间：XX-XX 00:00
                    </div>
                    <div>
                      实际到达时间：XX-XX 00:00
                    </div>
                    <div>
                      运输时效：X分钟
                    </div>
                  </div>
                </template>
              </a-step>
              <a-step status="">
                <template #title>
                  <div>
                    <span>送车</span>
                  </div>
                </template>
                <template #description>
                  <div>
                    <div>
                      送车时间：XX-XX 00:00
                    </div>
                    <div>
                      接单时效：X分钟
                    </div>
                  </div>
                </template>
              </a-step>
            </a-steps> -->
          </div>
        </div>
        <!-- 验提干送 -->
        <div v-for="item in monitorData.trans" :key="item.transportType?.value">
          <div class="content-title">
            <div>
              <span>
                <span style="margin-right:10px">{{ item.transportType?.label }}</span>
              </span>
              <a v-show="item.transportType?.value === 0" style="font-weight:normal;" @click="updateImg">
                <span>车辆照片</span>
                <span style="margin-left:10px">{{ item.picNum || '0' }}张</span>
              </a>
            </div>
            <div v-show="item.transportType?.value === 0">
              <span class="operation">
                <img class="img" src="@/assets/images/carrier/QRC.png" @click="handleCope()">
                <div class="QRC-img" v-show="QRCshow">
                  <div>
                    <div>车架号：<a-tag color="green">{{ taskDetail.vinNo }}</a-tag></div>
                    <div>线路：<span style="color:#f00">{{ monitorData.lineStart + '——' + monitorData.lineEnd }}</span></div>
                    <img v-show="!QRCloading" class="img" :src="QRCurl" style="width:100%;height:100%">
                    <div style="text-align: center"><a-spin v-show="QRCloading" size="large" /></div>
                  </div>
                </div>
              </span>
              <a-divider type="vertical" />
              <span><img class="img" src="@/assets/images/carrier/employee.png" @click="lookInspectioner(item)"></span>
            </div>
          </div>
          <div class="content-data" style="position: relative;">
            <div v-show="spinShow" class="loading-spin"><a-spin size="large" /></div>
            <a-row :gutter="[24, 8]" v-show="item.transportType?.value === 0">
              <a-col :span="3">
                <span class="title-color">任务报价</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.taskOffer }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">运费</span>
              </a-col>
              <a-col :span="5">
                <a @click="editTransportFee(item)">{{ item.transportFee || '' }}</a>
              </a-col>
              <a-col :span="3">
                <span class="title-color">验车地址</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.startAddress }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">当前状态</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.truckStatus?.label }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">预约验车时间</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.scheduleTime }}</span>
              </a-col>
              <a-col :span="3" v-show="item.departure">
                <span class="title-color">安排人</span>
              </a-col>
              <a-col :span="5" v-show="item.departure">
                <span class="data-color">{{ item.departure }}</span>
              </a-col>
              <a-col :span="3" v-show="item?.departureTime">
                <span class="title-color">安排时间</span>
              </a-col>
              <a-col :span="5" v-show="item?.departureTime">
                <span class="data-color">{{ item?.departureTime }}</span>
              </a-col>
              <a-col :span="3" v-show="item.driverName">
                <span class="title-color">验车司机</span>
              </a-col>
              <a-col :span="5" v-show="item.driverName">
                <span class="data-color">{{ item?.driverName }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">验车完成时间</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.unloadingTime }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">对账信息</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.settlementStatus?.label }}</span>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 8]" v-show="item.transportType?.value === 1">
              <a-col :span="3">
                <span class="title-color">任务报价</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.taskOffer }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">提车运费</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">
                  <span style="margin-right:10px">运费：<a @click="editTransportFee(item)">￥{{ item.transportFee }}</a>
                  </span>
                  <span>油卡：￥{{ item.oilAmt || 0 }}</span>
                </span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">提车到达地址</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.startAddress }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">当前状态</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.truckStatus?.label }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">提车类型</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.pickType?.label }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">司机</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.driverName || '' }}</span>
                <span class="data-color" style="margin-left:10px" v-show="item.carNo">{{ item.carNo }}</span>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 8]" v-show="item.transportType?.value === 2">
              <a-col :span="3">
                <span class="title-color">任务报价</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.taskOffer }}</span>
              </a-col>
              <a-col :span="2">
                <span class="title-color">费用</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color">
                  <span style="margin-right:10px">运费：<a @click="editTransportFee(item)">￥{{ item.transportFee }}</a>
                  </span>
                  <span>油卡：￥{{ item.oilAmt }}</span>
                </span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">发运线路</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.startAddress + '-' + item.endAddress }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">当前状态</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.truckStatus?.label }}</span>
              </a-col>
              <!-- <a-col :span="3">
                <span class="title-color">GPS类型</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.gpsType ? item.gpsType?.label : '无GPS' }}</span>
                <a style="margin-left:5px" v-show="item.truckStatus?.value !== 4 && item.truckStatus?.value !== 5"
                  @click="changeGPSshow = true,record=item">修改</a>
                </a-col> -->
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">板车类型</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.truckType?.label }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">板车车牌号</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.carNo }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">司机&emsp;&emsp;</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ inspectorData.driverName }}</span>
              </a-col>
              <a-col :span="3" v-show="item.orderVehicleTransType === 1">
                <span class="title-color">装车时间</span>
              </a-col>
              <a-col :span="5" v-show="item.orderVehicleTransType === 1">
                <span class="data-color">{{ item.loadingTime }}</span>
              </a-col>
              <a-col :span="3" v-show="item.orderVehicleTransType === 2">
                <span class="title-color">发车人</span>
              </a-col>
              <a-col :span="5" v-show="item.orderVehicleTransType === 2">
                <span class="data-color">{{ item.departure }}</span>
              </a-col>
              <a-col :span="3" v-show="item.orderVehicleTransType === 1">
                <span class="title-color">装车人&emsp;</span>
              </a-col>
              <a-col :span="5" v-show="item.orderVehicleTransType === 1">
                <span class="data-color">{{ item.loadingOperator }}</span>
              </a-col>
              <a-col :span="3" v-show="item.orderVehicleTransType === 2">
                <span class="title-color">发车时间</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.departureTime }}</span>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 8]" v-show="item.transportType?.value === 3">
              <a-col :span="3">
                <span class="title-color">任务报价</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.taskOffer }}</span>
              </a-col>
              <a-col :span="2">
                <span class="title-color">费用</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color">
                  <span style="margin-right:10px">运费：<a @click="editTransportFee(item)">￥{{ item.transportFee }}</a>
                  </span>
                  <span>油卡：￥{{ item.oilAmt }}</span>
                </span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">送车地址</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.startAddress }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">当前状态</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.truckStatus?.label }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">板车类型</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.truckType?.label }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">板车车牌号</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.carNo }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">承运商名称</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ item.carrierName }}</span>
              </a-col>
            </a-row>
            <!-- shopPlantStatus判断是否为门店任务进入，taskType判断是否为外派 arrangeShow-->
            <div style="margin-top:15px" v-if="shopPlantStatus !== -1 && shopPlantStatus">
              <div v-show="item.transportType?.value === 0">
                <a-button type="primary" :loading="btnLoading"
                  v-show="item.orderVehicleTransType === -1 || item.orderVehicleTransType === 0"
                  @click="arrangeShow = true, record = item">安排验车</a-button>
                <a-button type="primary" :loading="btnLoading" v-show="item.orderVehicleTransType === 2 && type !== 1"
                  :disabled="item.disabled" @click="nullVisible = true">放空</a-button>
                <a-button type="primary" :loading="btnLoading"
                  v-show="item.orderVehicleTransType === 2 && item.transportType.value === 0" :disabled="item.disabled"
                  @click="arrangeShow = true, record = item">修改验车安排</a-button>
                <a-button type="primary" :loading="btnLoading"
                  v-show="item.orderVehicleTransType === 2 && item.transportType.value !== 0" :disabled="item.disabled"
                  @click="arrangeTransport(item)">修改安排</a-button>
                <a-button type="primary" :loading="btnLoading"
                  @click="addReportShow = true, abnormalTask = item.taskId">异常上报</a-button>

                <a-popconfirm :title="confirmTitle" ok-text="确定" cancel-text="取消" @confirm="inspectorComplete">
                  <a-button type="primary" :loading="btnLoading" :disabled="item.disabled"
                    v-show="item.orderVehicleTransType === 2" v-if="!item.disabled" @click="prompt">验车完成</a-button>
                </a-popconfirm>
              </div>
              <div v-show="item.transportType?.value !== 0">
                <a-button type="primary" :loading="btnLoading" :disabled="item.disabled"
                  v-show="item.orderVehicleTransType === -1 || item.orderVehicleTransType === 0" :v-if="!item.disabled"
                  @click="arrangeTransShow = true, record = item">安排发运</a-button>

                <a-button type="primary" :loading="btnLoading" :disabled="item.disabled"
                  v-show="item.orderVehicleTransType === 1" v-if="!item.disabled"
                  @click="arrangeTransport(item)">修改装车发运</a-button>

                <!-- <a-button type="primary" :loading="btnLoading" :disabled="item.disabled"
                  v-show="item.orderVehicleTransType === 1" v-if="!item.disabled"
                  @click="cancelLoadVeh(item)">取消装车发运</a-button> -->

                <a-button type="primary" :loading="btnLoading" v-show="item.orderVehicleTransType === 1"
                  @click="addReportShow = true, abnormalTask = item.taskId">异常上报</a-button>

                <a-button type="primary" :loading="btnLoading" :disabled="item.disabled"
                  v-show="item.orderVehicleTransType === 1" v-if="!item.disabled"
                  @click="receivables = true, record = item">发车</a-button>

                <a-popconfirm :title="`是否确认${item.transportType?.value === 1 ? '提' : '送'}车`" ok-text="确定" cancel-text="取消"
                  @confirm="unloadVeh(item)">
                  <a-button type="primary" :loading="btnLoading" :disabled="item.disabled"
                    v-show="item.orderVehicleTransType === 2" v-if="!item.disabled">
                    <!-- <span>{{ item.transportType?.value === 2 ? '卸' : '提' }}车完成</span> -->
                    <span v-show="item.transportType?.value === 1">提车完成</span>
                    <span v-show="item.transportType?.value === 2">发运完成</span>
                    <span v-show="item.transportType?.value === 3">送车完成</span>
                  </a-button>
                </a-popconfirm>

                <a-popconfirm ok-text="是" cancel-text="否" @confirm="repealClick(item)">
                  <template #title>
                    <div>
                      <p>确认撤销？</p>
                      <a-textarea v-model:value="repealRemark" :disabled="disabled" placeholder="备注信息" :rows="2" />
                    </div>
                  </template>
                  <a-button type="primary" :loading="btnLoading"
                    v-show="item.orderVehicleTransType === 9 && !shopPlantStatus" :disabled="disabled">撤销到达</a-button>
                </a-popconfirm>
              </div>
            </div>
            <!-- 放空列表 -->
            <div v-show="item.transportType?.value === 0 && !disabled">
              <!-- <a style="margin:10px 0" @click="nullTableShow = !nullTableShow">放空列表</a> -->
              <a-table v-show="nullTableShow" :columns="nullTable" :data-source="nullTableData" :loading="missStatus"
                :row-key="record => record.time" bordered size="small" :pagination="nullPagination"
                @change="nullTableChange" />
            </div>
          </div>
        </div>
        <!-- 运输位置 -->
        <div v-if="locationShow">
          <div class="content-title" style="display: flex;justify-content: space-between;">
            <div>
              <span style="margin-right:10px">运输位置</span>
              <span style="color:#B5BBC5;font-weight: normal;">共{{ locationData.length || 0 }}条</span>
            </div>
          </div>
          <div class="content-data">
            <a-button v-show="monitorData.executeType?.value === 2 && monitorData.taskStatus?.value >= 2" type="primary"
              @click="addPositionShow = true">+新增位置</a-button>
            <a-steps v-show="locationData.length >= 0" progress-dot :current="1" direction="vertical">
              <a-step v-for="(item, index) in locationData" :key="index">
                <template #title>
                  <div style="display: flex;justify-content:space-between">
                    <div style="display:flex">
                      <div>{{ item.location }}</div>
                      <a-typography-paragraph :copyable="{ text: item.location }">
                      </a-typography-paragraph>
                    </div>
                    <a v-show="index === 0 && monitorData.executeType?.value === 2"
                      @click="positionChange = true, changeAddressData = item">修改</a>
                  </div>
                </template>
                <template #description>
                  <div>
                    <div style="color:#6B778C">备注：{{ item.remark }}</div>
                    <div>{{ item.operator }}<span style="margin-left:10px;color:#6B778C">{{ item.recordTime }}</span>
                    </div>
                  </div>
                </template>
              </a-step>
            </a-steps>
            <div v-show="locationData.length <= 0" style="text-align: center;color:#6B778C">暂无在途信息</div>
          </div>
        </div>
      </div>
      <div class="right-content">
        <Record v-if="recordShow" :vehicleList="[]" :vehiclesId="monitorData.orderVehicleId"
          :orderId="monitorData.orderId" />
      </div>
    </div>
  </a-modal>
  <a-modal v-model:visible="arrangeShow" title="安排验车" :footer="null" width="40%" :destroyOnClose="true">
    <div v-if="monitorData.taskId">
      <Inspector :taskId="monitorData.taskId" :editData="inspectorData"
        :carrier="{ orderId: monitorData.orderId, carrierName: monitorData.carrierName, orderVehicleIds: [monitorData.orderVehicleId] }"
        :appCarrier="1" @status="getInspector" />
      <!-- <BigcarChoose :carrierId="monitorData.carrierId" @carDriverData="getBigCarData" /> -->
    </div>
  </a-modal>
  <a-modal v-model:visible="expenseShow" title="填写验车费用" :destroyOnClose="true" @ok="confirmInspector"
    :confirmLoading="btnLoading">
    <a-input addon-before="费用" v-model:value="expense" type='number' size="large" :min="0">
    </a-input>
  </a-modal>
  <!-- 新增位置 -->
  <a-modal v-model:visible="addPositionShow" title="新增位置" @ok="confirmAdd" :confirmLoading="btnLoading">
    <div>
      <div style="margin: 15px 0">所在地区<span style="color:#f00">*</span></div>
      <a-cascader :options="$store.state.app.cityDict" v-model:value="address" :changeOnSelect="true"
        :show-search="{ filter }" @change="setAddress" placeholder="请选择地址" />
      <div style="margin: 15px 0">详细地址</div>
      <a-input v-model:value="formNowLocation.details" placeholder="详细地址" />
    </div>
  </a-modal>
  <!--修改最近一条位置信息-->
  <a-modal v-model:visible="positionChange" title="修改位置信息" :confirm-loading="btnLoading" @ok="confirmChangeLocation">
    <div>
      (原) {{ changeAddressData.location }}——>>
      <a-cascader :options="$store.state.app.cityDict" :changeOnSelect="true" :show-search="{ filter }"
        @change="setChangeAddress" placeholder="请选择地址" />
      <a-input style="margin-top:10px" v-model:value="formNowLocation.details" placeholder="详细地址" />
    </div>
  </a-modal>
  <!-- 选择大车或司机 -->
  <a-modal v-model:visible="changeBigShow" width="50%" title="选择大车或司机" @ok="confirmBigCar" :confirmLoading="btnLoading">
    <div>
      <BigcarChoose ref="bigCarChooseRef" :carrierId="record.carrierId" @carDriverData="getBigCarData" />
    </div>
  </a-modal>
  <!-- 更改GPS类型 -->
  <a-modal v-model:visible="changeGPSshow" title="修改GPS类型" @ok="confirmGPSchange" :confirmLoading="btnLoading">
    <div>
      GPS类型：
      <a-select v-model:value="GPStype" style="width: 120px">
        <a-select-option :value="1">智运GPS</a-select-option>
        <a-select-option :value="2">超越GPS</a-select-option>
        <a-select-option :value="3">关闭GPS</a-select-option>
        <a-select-option :value="4">途强GPS</a-select-option>
      </a-select>
    </div>
  </a-modal>
  <!-- 放空 -->
  <a-modal v-model:visible="nullVisible" title="填写放空费" @ok="nullBtn" :destroyOnClose="true">
    <p> 放空费：
      <a-input v-model:value="nullMoney" type="number" style="width:30%" />元
    </p>
    <span style="color:#f00">注：放空费的产生</span>
  </a-modal>
  <!-- 提干送安排发运 -->
  <a-modal width="45%" v-model:visible="arrangeTransShow" title="安排发运" :confirmLoading="arrangeTransLoading"
    @ok="confirmTrans" @cancel="closeArrange">
    <div>
      <div>
        <div class="content-title">
          <span>安排线路</span>
          <!-- <a style="font-weight: normal;margin-left:15px" @click="lineShow = !lineShow">{{ lineData.name || '请选择' }}</a> -->
          <span style="font-weight: normal;margin-left:15px">{{ record.startAddress + '—' + record.endAddress }}</span>
        </div>
        <!-- <div v-show="lineShow">
          <LineChoose @lineData="getLineData" />
        </div> -->
      </div>
      <!-- <div v-show="lineData.id">
        <div class="content-title">
          <span>
            <span>途经点</span>
            <a style="font-weight: normal;margin-left:15px" @click="pointShow = !pointShow">选择</a>
            <span style="margin-left:10px" v-for="item in passingPointData" :key="item.value">{{ item.label }}</span>
          </span>
        </div>
        <div v-show="pointShow" class="transport" style="padding:10px;">
          <PassingPoint @passingPointData="getPassingPointData" />
        </div>
      </div> -->
      <div>
        <div class="content-title">
          <span>承运商</span>
          <a style="font-weight: normal;margin-left:15px" @click="carrierShow = !carrierShow">{{ carrierData.carrierName
            || '请选择' }}</a>
        </div>
        <div style="padding:5px;border:1px solid #eee" v-show="carrierShow">
          <CarrierMod ref="carrierRef" :carrierId="record.carrierId" :type="1" @confirmCarrier="chooseCarrier" />
        </div>
      </div>
      <div v-if="carrierData.id">
        <div class="content-title">
          <span>
            <span>运输工具</span>
            <a style="font-weight: normal;margin-left:15px" @click="bigCarShow = !bigCarShow">{{ bigCarData.id ? '切换' :
              '选择' }}</a>
            <span style="margin-left:10px" v-if="bigCarData.truckType">{{ bigCarData.truckType?.label }}</span>
            <span style="margin-left:10px" v-show="bigCarData.id && !bigCarData.truckType">地跑{{ bigCarData.name }}</span>
            <span style="margin-left:10px" v-show="bigCarData.carNo">{{ bigCarData.carNo }}</span>
            <span style="margin-left:10px" v-show="bigCarData.truckType?.value !== 4">{{ bigCarData.name }}</span>
          </span>
        </div>
        <div class="content-data" v-show="bigCarShow">
          <BigcarChoose ref="bigCarChooseRef" :carrierId="carrierData.id" @carDriverData="getBigCarData" />
        </div>
      </div>
      <div v-show="bigCarData.name || bigCarData.transTruckId">
        <div class="content-title">
          <div style="color:#344563;font-weight: 700;">运费：</div>
        </div>
        <div>
          <div class="input-div">
            <div class="input-data">
              <span>运费：</span>
              <a-input-number v-model:value="transportAmt" :min="0" style="width:100px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
  <!-- 验车凭证 -->
  <a-modal v-model:visible="evidenceShow" title="凭证信息" footer="" width="350px">
    <div class="evidenceDOM">
      <!-- <p><img class="img" src="@/assets/images/log.png" style="width:50%;height:100%" /></p> -->
      <p class="font-color2">司机姓名：{{ proofData?.driverName }}</p>
      <p class="font-color2"></p>
      <p class="font-color2">联系电话：{{ proofData?.driverTel }}</p>
      <p class="font-color2"></p>
      <p class="font-color2">身份证号：{{ proofData?.driverIdNumber }}</p>
    </div>
    <template #footer>
      <!-- <a-button @click="arrangeShow = true, evidenceShow = false">修改验车人</a-button> -->
    </template>
  </a-modal>
  <!-- 运费修改 -->
  <a-modal v-model:visible="priceChengShow" title="运费修改" @ok="confirmChangePrice" :confirmLoading="btnLoading">
    <div style="margin-bottom:15px">
      <div>运费：</div>
      <a-input v-model:value="transportFeeEdit.transportFee" type="number" :min="0" />
    </div>
    <div>
      <span>原因：</span>
      <a-textarea :maxlength="200" v-model:value="transportFeeEdit.reason" placeholder="请说明您要修改的原因" />
    </div>
  </a-modal>
  <!-- 发车运费填写 -->
  <a-modal v-model:visible="receivables" title="填写收款方式" @ok="transVehicle" :confirmLoading="btnLoading">
    <a-input addon-before="油卡" v-model:value="driver.oilCard" type='number' size="large" :min="0">
    </a-input>
    <a-divider type="vertical" />
    <a-input addon-before="转账" v-model:value="driver.bankCard" type='number' size="large" :min="0">
    </a-input>
  </a-modal>
  <!-- 安排发运时的费用 -->
  <a-modal v-model:visible="transFeeShow" title="填写收款方式" @ok="fillData" :confirmLoading="btnLoading">
    <!-- <h1 v-show="operateDetail.isChoose?.value === 1">总金额<span style="color:#f00">{{ operateDetail.transportFee }}</span>
    </h1> -->
    <a-input v-if="bigCarData.truckStatus?.value !== 2" addon-before="油卡" v-model:value="driver.oilCard" type='number'
      size="large" :min="0">
      <!-- <template #suffix v-show="operateDetail.isChoose?.value === 1">
        <a-tooltip title="全部油卡">
          <EnterOutlined @click="driver.oilCard = operateDetail.transportFee, driver.bankCard = 0"
            style="color: rgba(0, 0, 0, 0.45)" />
        </a-tooltip>
      </template> -->
    </a-input>
    <a-divider type="vertical" />
    <a-input addon-before="转账" v-model:value="driver.bankCard" type='number' size="large" :min="0">
      <!-- <template #suffix v-show="operateDetail.isChoose?.value === 1">
        <a-tooltip title="全部转账">
          <EnterOutlined @click="driver.bankCard = operateDetail.transportFee, driver.oilCard = 0"
            style="color: rgba(0, 0, 0, 0.45)" />
        </a-tooltip>
      </template> -->
    </a-input>
    <div style="margin-top:10px">
      <a-cascader style="width:100%" :options="$store.state.app.cityTypeDict" :show-search="{ filter }"
        :changeOnSelect="true" @change="stopAddressChange" placeholder="请选择中转地点" />
      <div style="display:flex;align-items:center;margin-top:10px">
        是否门店：
        <a-select v-model:value="isShop" style="width: 120px" placeholder="是否门店">
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
      </div>
    </div>
  </a-modal>
  <InspectionImg ref="imgListRef" :orderVehicleId="monitorData.orderVehicleId" :loadInspectList="[]" />
  <a-modal v-model:visible="abnormalReportShow" title="异常列表" footer='' width="50%">
    <AbnormalReport v-if="monitorData.trans" :windowShow="abnormalReportShow" :vinNo="monitorData.trans[0]?.vinNo"
      :vehicleDetail="monitorData" @statusShow="abnormalData" />
  </a-modal>
  <AddReport :windowShow="addReportShow" :vehicleDetail="monitorData" @reportData="addReportData" />
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue'
import { FileZipOutlined, EnterOutlined } from '@ant-design/icons-vue'
import { getMonitorDetail, arrangeTrnas, beginDepart, vehicleArrive, addReport, carUnloadVehicle, changeTransType, coverPit, plantPit } from '@/api/transport/transport'
import { vehicleGpsDetail, truckGpsAdd, changeGPStype } from '@/api/transport/truck'
import { transEdit } from '@/api/trans/ops/edit'
import { vent, ventPage, done, take, QRCurl, inspectionChange, inspectionChangeEdit } from '@/api/transport/inspection'
import { message } from 'ant-design-vue'
import CarrierMod from '../comon/carrierMod.vue'
import Record from '@/views/transportCapacity/transportUse/comon/record.vue'
import BigcarChoose from '@/views/components/bigCarChoose'
import Inspector from '@/views/components/inspector'
import InspectionImg from '@/views/components/vehicleUpload/inspectionImg'
import LineChoose from '../comon/lineChoose.vue'
import AbnormalReport from '../comon/abnormalReport'
import PassingPoint from '../comon/passingPoint.vue'
import AddReport from '../comon/addReport.vue'

export default {
  props: {
    windowShow: Boolean,
    taskDetail: Object,
    shopPlantStatus: Number,
    type: Number
  },
  components: {
    Record,
    Inspector,
    CarrierMod,
    BigcarChoose,
    InspectionImg,
    LineChoose,
    PassingPoint,
    AbnormalReport,
    AddReport
  },
  setup (props, context) {
    const carrierRef = ref(null)
    const bigCarChooseRef = ref(null)
    const imgListRef = ref(null)
    const state = reactive({
      windowShow: false,
      recordShow: false,
      btnLoading: false,
      spinShow: false,
      changeGPSshow: false,
      positionChange: false,
      receivables: false,
      transFeeShow: false,
      expenseShow: false,
      changeBigShow: false,
      evidenceShow: false,
      addReportShow: false,
      addPositionShow: false,
      arrangeTransLoading: false,
      carrierShow: false,
      disabled: false, // 用于区分人工和外派，外派则全部禁用
      abnormalTask: '',
      shopPlantStatus: null, // 用于判断是否为门店任务打开 true放开所有按钮 false外派隐藏按钮  -1为门店任务拒绝单
      abnormalReportShow: false,
      arrangeShow: false,
      pointShow: false,
      locationShow: false,
      arrangeTransShow: false,
      nullVisible: false,
      nullTableShow: false,
      missStatus: false,
      QRCloading: false,
      QRCshow: false,
      lineShow: false,
      priceChengShow: false,
      bigCarShow: false,
      type: null, // 区分小车任务详情和门店任务详情 1为门店任务详情,其他为小车任务详情
      expense: 0, // 验车费用
      isShop: null,
      GPStype: null,
      nullMoney: 0,
      transportAmt: null,
      QRCurl: '',
      address: '',
      repealRemark: '',
      confirmTitle: '',
      inspectDriverId: '',
      carrierData: {},
      transit: {},
      changeAddressData: {},
      record: {}, // 任务详情
      locationData: [], // 运输位置List
      taskDetail: {}, // 小车操作列表的record
      taskDetail: {}, // 操作详情的detail
      monitorData: {}, // 本页面获得的数据
      inspectorData: {}, // 验车人信息
      proofData: {},
      lineData: {}, // 线路选择数据
      bigCarData: {}, // 选择大车和司机
      nullTableData: [], // 放空列表
      passingPointData: [], // 途经点
      formNowLocation: {
        details: ''
      },
      transportFeeEdit: {
        transportFee: '',
        reason: '',
        totvId: '',
        transTruckId: ''
      },
      driver: {
        oilCard: 0,
        bankCard: 0,
      },
      nullTable: [
        {
          title: '验车人',
          dataIndex: 'driverName'
        },
        {
          title: '司机电话',
          dataIndex: 'driverTel'
        },
        {
          title: '放空费',
          dataIndex: 'amt'
        },
        {
          title: '放空时间',
          dataIndex: 'time'
        },
        {
          title: '对账结算',
          dataIndex: ''
        }
      ],
      nullPagination: {
        current: 1,
        pageSize: 4,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['4', '10', '20', '50'],
        showTotal: (total, range) => `共 ${total} 条`
      },
    })
    watch(() => props.windowShow, (newVal, oldVal) => {
      state.windowShow = props.windowShow
      state.taskDetail = props.taskDetail
      state.type = props.type
      if (props.shopPlantStatus !== null && props.shopPlantStatus !== undefined) state.shopPlantStatus = props.shopPlantStatus
      if (state.windowShow === true) {
        setTimeout(() => {
          getDetailData()
        }, 100)
      }
    })
    // 获取详细数据
    const getDetailData = () => {
      state.monitorData.trans = []
      state.lineData = {}
      state.bigCarData = {}
      getMonitorDetail(state.taskDetail.orderVehicleId, state.taskDetail.taskId).then(res => {
        if (res.code === 10000) {
          state.monitorData = res.data
          if (state.monitorData.trans.length > 0) {
            state.monitorData.taskIds.split(',').forEach((item, index) => {
              state.monitorData.trans[index].taskId = item
              state.monitorData.trans[index].executeType = state.monitorData.executeType.value
              // 用于控制流程按钮的禁用状态 executeType.value为当前正在执行的任务，因此在任务完成之前，后面所有任务都应为禁用状态
              state.monitorData.trans[index].disabled = state.monitorData.trans[index].executeType != Number(state.monitorData.trans[index].taskId)
            })
          }
          state.recordShow = true
          if (state.monitorData.trans[0].inspection) state.inspectorData = state.monitorData.trans[0].inspection
          if (props.type === 1) getLocation()
          Missrecord()
        }
      }).finally(() => { state.spinShow = false })
    }
    // 获取运输位置---门店列表方式进入调用
    const getLocation = () => {
      vehicleGpsDetail(state.monitorData.orderVehicleId).then(res => {
        if (res.code === 10000) {
          if (state.monitorData.taskIds.search('2') === -1) state.locationShow = false
          else state.locationShow = true
          state.locationData = res.data
        }
      })
    }
    const cancelClose = () => {
      state.windowShow = false
      state.recordShow = false
      state.inspectorData = {}
      clearData()
      context.emit('statusShow', state.windowShow)
    }
    const clearData = () => {
      state.QRCurl = ''
      state.address = ''
      state.repealRemark = ''
      state.confirmTitle = ''
      state.inspectDriverId = ''
      state.carrierData = {}
      state.transit = {}
      state.changeAddressData = {}
      state.record = {} // 任务详情
      state.locationData = [] // 运输位置List
      state.taskDetail = {} // 小车操作列表的record
      state.taskDetail = {} // 操作详情的detail
      state.monitorData = {} // 本页面获得的数据
      state.inspectorData = {} // 验车人信息
      state.proofData = {}
      state.lineData = {} // 线路选择数据
      state.bigCarData = {} // 选择大车和司机
      state.nullTableData = [] // 放空列表
      state.passingPointData = [] // 途经点
    }
    // 单台异常上报
    const abnormalData = e => {
      if (e !== undefined) {
        state.abnormalReportShow = e
      }
    }
    // 新增异常上报
    const addReportData = e => {
      if (e.reportType !== null && e.reportType !== undefined && e.reportType !== 0) {
        delete e.belong
        let submit = {
          ...e,
          totvId: state.monitorData.totvId,
          orderVehicleId: state.monitorData.orderVehicleId,
          taskId: state.abnormalTask,
        }
        addReport(submit).then(res => {
          if (res.code === 10000) {
            state.addReportShow = false
            message.success('上报成功')
          }
        })
      } else state.addReportShow = false
    }
    // 验车图片
    const updateImg = () => {
      imgListRef.value.inspectionImg = true
      imgListRef.value.loadData(state.monitorData.orderVehicleId)
    }
    // 验车人回传接收
    const getInspector = e => {
      if (e) {
        state.inspectorData = e
        state.expenseShow = true
        state.arrangeShow = false
      }
    }
    const confirmInspector = () => {
      if (state.record.orderVehicleTransType !== -1) {
        state.btnLoading = true
        inspectionChangeEdit({
          amt: state.expense,
          orderVehicleId: state.monitorData.orderVehicleId,
          carrierId: state.monitorData.carrierId,
          carrierName: state.monitorData.carrierName,
          driverId: state.inspectorData.id,
          driverName: state.inspectorData.name,
          driverType: state.inspectorData.driverType,
          isNull: state.inspectorData.driverType,
          orderId: state.monitorData.orderId,
          driverTel: state.inspectorData.mobile,
          driverIdNumber: state.monitorData.idNumber,
          taskId: state.monitorData.taskId,
        }).then(res => {
          if (res.code === 10000) {
            getDetailData()
            state.expense = 0
            message.success('修改成功')
          }
        }).finally(() => { state.btnLoading = false, state.inspectorFeeShow = false, state.expenseShow = false })
      } else {
        state.btnLoading = true
        inspectionChange({
          amt: state.expense,
          carrierId: state.monitorData.carrierId,
          carrierName: state.monitorData.carrierName,
          driverId: state.inspectorData.id,
          driverName: state.inspectorData.name,
          driverType: state.inspectorData.driverType,
          isNull: state.inspectorData.driverType,
          orderId: state.monitorData.orderId,
          driverTel: state.inspectorData.mobile,
          driverIdNumber: state.inspectorData.idNumber,
          taskId: state.monitorData.taskId,
          orderVehicleIds: [state.monitorData.orderVehicleId]
        }).then(res => {
          if (res.code === 10000) {
            message.success('安排成功')
            getDetailData()
            state.expense = 0
            state.arrangeShow = false
          }
        }).finally(() => { state.btnLoading = false, state.inspectorFeeShow = false, state.expenseShow = false })
      }
    }
    // 生成小程序码
    const handleCope = () => {
      state.QRCshow = !state.QRCshow
      if (state.QRCshow === true) {
        state.QRCloading = true
        const wXprogramQRcode = { scene: state.monitorData.orderVehicleId, type: 1 }
        QRCurl(wXprogramQRcode)
          .then(res => {
            if (res.code === 10000) {
              state.QRCurl = 'data:image/png;base64,' + res.data
              state.QRCloading = false
            }
          }).catch(err => console.log(err))
      }
    }
    // 查看验车凭证
    const lookInspectioner = (item) => {
      state.proofData = item
      state.evidenceShow = true
    }
    // 放空
    const nullBtn = (item) => {
      if (state.nullMoney >= 0) {
        vent({
          amt: state.nullMoney,
          orderId: state.monitorData.orderId,
          inspectDriverId: state.inspectorData.id
        }).then(res => {
          if (res.code === 10000) {
            message.success('订单放空' + res.msg)
            state.nullVisible = false
            getDetailData()
          }
        }).catch(err => console.log(err))
      } else message.error('放空金额不能为负数')
    }
    // 放空记录
    const Missrecord = () => {
      state.missStatus = true
      ventPage({
        current: state.nullPagination.current,
        size: state.nullPagination.pageSize,
        orderId: state.monitorData.orderId
      }).then(res => {
        if (res.code === 10000) {
          state.nullTableData = res.data.records
          state.nullPagination.total = res.data.total
          state.missStatus = false
        }
      }).catch(err => console.log(err))
    }
    // 放空表格分页
    const nullTableChange = (pag) => {
      state.nullPagination.current = pag.current
      Missrecord()
    }

    // 验车完成前提示确认框--判断验车照片的数量大于0
    const prompt = () => {
      let num = state.monitorData.trans.reduce((t, v) => t + v.picNum, 0)
      if (num <= 0) state.confirmTitle = '检测到你还未上传过图片,确认完成吗?'
      else state.confirmTitle = '确认验车完成 '
    }
    // 验车完成
    const inspectorComplete = () => {
      state.spinShow = true
      done(state.monitorData.orderVehicleId, state.monitorData.taskId).then(res => {
        if (res.code === 10000) {
          message.success('验车' + res.msg)
          getDetailData()
        }
      }).finally(() => { state.spinShow = false })
    }
    // 确认提车完成
    const takeCarComplet = () => {
      state.spinShow = true
      take(state.monitorData.orderId).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          getDetailData()
        }
      }).catch(err => console.log(err))
        .finally(() => { state.spinShow = false })
    }
    // 线路选择数据回传接收
    const getLineData = e => {
      if (e) {
        state.lineData = e
        state.lineShow = false
      }
    }
    // 选择途经点
    const getPassingPointData = e => {
      if (e) {
        state.passingPointData = e
        state.pointShow = false
      }
    }
    const setChangeAddress = (e, v) => {
      state.formNowLocation.province = e[0]
      state.formNowLocation.city = e[1]
      state.formNowLocation.area = e[2]
      if (e[0] === undefined) {
        state.formNowLocation.province = ''
      }
    }
    // 选择大车和司机或地跑
    const getBigCarData = (e) => {
      state.bigCarData = e
      if (e) {
        if (state.bigCarData.transTruckId === undefined && state.record.truckStatus.value !== 0) confirmBigCar()
        else {
          if (!state.bigCarData.transTruckId) {
            // confirmTrans()
          } else {
            if (state.bigCarData.truckStatus.value !== 3 && state.bigCarData.truckStatus.value !== 2) {
              state.bigCarShow = false
            } else message.error('请选择空闲中的大车'), state.bigCarData = {}
          }
        }
      }
    }
    // 安排装车发运任务
    const confirmTrans = () => {
      let submit = {
        carrierDriverId: state.bigCarData.id,
        carrierId: state.carrierData.id,
        carrierTruckId: state.bigCarData.carrierTruckId,
        freight: state.transportAmt,
        passPoints: state.passingPointData,
        orderVehicleId: state.monitorData.orderVehicleId,
        taskId: state.monitorData.taskId,
        transportType: { name: state.record.transportType.label, value: state.record.transportType.value }
      }
      if (state.transportAmt !== null) {
        state.arrangeTransLoading = true
        arrangeTrnas(submit).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            getDetailData()
            closeArrange()
          }
        }).finally(() => { state.arrangeTransLoading = false })
      } else message.error('请填写运费')
    }
    // 安排发运-关闭弹窗
    const closeArrange = () => {
      state.arrangeTransShow = false
      state.bigCarData = {}
      state.carrierData = {}
      state.transportAmt = 0
    }
    // 修改、安排大车和司机
    const confirmBigCar = () => {
      if (!state.bigCarData.truckStatus?.value) requireAPI()
      else {
        switch (state.bigCarData.truckStatus?.value) {
          case 1: // 空闲
            if (state.bigCarData.truckStatus) {
              if (state.bigCarData.truckStatus.value === 3 || state.bigCarData.truckStatus.value === 1 || state.bigCarData.truckStatus.value === 2) {
                requireAPI()
              }
            } else {
              if (state.bigCarData.name || state.bigCarData.driver === true) {
                if (state.bigCarData.carrierTruckId || state.bigCarData.driver === true) { // 更换大车及司机
                  requireAPI()
                }
              } else message.error('请选择司机')
            }
            break;
          case 2: // 安排中
            message.error('请选择空闲中的大车')
            // state.fillData = true
            break;
          case 3: // 运输中
            message.error('请选择空闲中的大车')
            // state.fillData = true
            break;
        }
      }
      function requireAPI () {
        state.btnLoading = true
        changeTransType(state.record.transTruckId || 0, state.bigCarData.carrierTruckId || 0, state.bigCarData.id || 0)
          .then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              getDetailData()
              state.changeBigShow = false
              setTimeout(() => {
                state.bigCarData = {}
                bigCarChooseRef.value.detail = {}
                bigCarChooseRef.value.clearStatus()
              }, 50)
            }
          }).finally(() => { state.btnLoading = false })
      }
    }
    // 确认发运
    const transVehicle = () => {
      state.spinShow = true
      state.btnLoading = true
      let submit = {
        // cash: Number(state.record.transportFee),
        oilCard: state.driver.oilCard,
        bankCard: state.driver.bankCard,
        taskId: state.monitorData.taskId,
        totvId: state.record.totvId,
        transTruckId: state.record.transTruckId,
        transOrderVehicleIds: [state.record.vehicleId]
      }
      beginDepart(submit).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.driver.oilAmt = 0
          state.driver.bankCard = 0
          getDetailData()
        }
      }).finally(() => { state.spinShow = false, state.btnLoading = false, state.receivables = false })
    }
    // 卸车
    const unloadVeh = (item) => {
      state.spinShow = true
      let submit = {
        taskId: state.monitorData.taskId,
        totvId: item.totvId
      }
      vehicleArrive(submit).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          getDetailData()
        }
      }).finally(() => { state.spinShow = false })
    }
    // 撤销到达
    const repealClick = item => {
      state.spinShow = true
      transEdit({
        params: {
          transportTruckId: item.transTruckId,
          totvId: item.totvId
        },
        type: {
          value: 1
        },
        reason: state.repealRemark,
        subType: 104
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          getDetailData()
        }
      }).finally(() => { state.spinShow = false })
    }
    const editTransportFee = item => {
      state.transportFeeEdit.totvId = item.totvId
      state.transportFeeEdit.transportFee = item.transportFee
      state.transportFeeEdit.transTruckId = item.transTruckId
      state.priceChengShow = true
    }
    // 确认修改费用
    const confirmChangePrice = () => {
      state.spinShow = true
      state.btnLoading = true
      transEdit({
        params: {
          transportTruckId: state.transportFeeEdit.transTruckId,
          totvId: state.transportFeeEdit.totvId,
          changeFee: state.transportFeeEdit.transportFee
        },
        type: {
          value: 1
        },
        reason: state.transportFeeEdit.reason,
        subType: 101
      }).then(res => {
        if (res.code === 10000) {
          message.success('运费已修改')
          getDetailData()
        }
      }).finally(() => {
        state.spinShow = false
        state.btnLoading = false
        state.transportFeeEdit.reason = ''
      })
    }
    // 修改安排
    const arrangeTransport = (item) => {
      state.changeBigShow = true
      state.record = item
      setTimeout(() => {
        bigCarChooseRef.value.loadData()
      }, 200)
    }
    // 取消装车
    const cancelLoadVeh = (item) => {
      state.btnLoading = true
      carUnloadVehicle({ ids: [item.totvId] })
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            getDetailData()
          }
        }).finally(() => { state.btnLoading = false })
    }
    // 确认新增位置
    const confirmAdd = () => {
      var NowLocation = {
        transTruckId: state.monitorData.transTruckId,
        addressDTO: state.formNowLocation,
        remark: '手动新增'
      }
      if (state.formNowLocation.city) requestAPI()
      else message.error('请选择地区')
      function requestAPI () {
        state.btnLoading = true
        truckGpsAdd(NowLocation).then(res => {
          if (res.code === 10000) {
            message.success('添加成功')
            state.addPositionShow = false
            getLocation()
            state.address = ''
            state.formNowLocation.details = ''
          }
        }).finally(() => { state.btnLoading = false })
      }
    }
    // 修改最近一次运输位置信息
    const confirmChangeLocation = () => {
      state.confirmLoading = true
      const data = JSON.parse((JSON.stringify((state.formNowLocation))))
      delete data.details
      truckGpsAdd({
        addressDTO: data,
        gpsId: state.changeAddressData.gpsId,
        details: state.formNowLocation.details,
        remark: '手动修改',
        transTruckId: state.transTruckId
      }).then(res => {
        if (res.code === 10000) {
          message.success('修改成功')
          getLocation()
          state.positionChange = false
          state.confirmLoading = false
        }
      })
    }
    const chooseCarrier = e => {
      if (e) {
        state.carrierData = e
        state.carrierShow = false
        if (e.id) {
          setTimeout(() => {
            bigCarChooseRef.value.loadData()
          }, 200)
        }
      }
    }
    const stopAddressChange = (e, v) => {
      state.transitValue = e
      state.transit.province = e[0]
      state.transit.city = e[1]
      state.transit.area = e[2]
    }
    const setAddress = (value) => {
      state.formNowLocation.province = value[0]
      state.formNowLocation.city = value[1]
      state.formNowLocation.area = value[2]
      if (value[0] === undefined) {
        state.formNowLocation.province = ''
      }
    }
    // 安排中装车-中途补位
    const fillData = () => {
      if (state.isShop !== null) {
        let submit = {
          freight: state.driver.bankCard,
          isShop: state.isShop,
          carrierDriverId: state.bigCarData.carrierDriverId,
          carrierId: state.bigCarData.carrierId,
          carrierTruckId: state.bigCarData.carrierTruckId,
          orderVehicleId: state.record.vehicleId,
          taskId: state.monitorData.taskId,
          transOrderVehicleIds: [state.record.tovId],
          transTruckId: state.bigCarData.transTruckId,
          transit: state.transit
        }
        if (state.bigCarData.truckStatus.value === 2) {
          plantPit(submit).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              closeArrange()
              state.transFeeShow = false
              state.changeBigShow = false
              state.transit = {}
              state.bigCarData = {}
              state.isShop = 0
              state.driver.oilAmt = 0
              state.driver.bankCard = 0
              getDetailData()
            }
          }).finally(() => { state.btnLoading = false })
        } else {
          if (state.driver.oilCard * 1 + state.driver.bankCard * 1 > 0) {
            state.confirmLoading = true
            coverPit({ ...submit, ...state.driver }).then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                state.transFeeShow = false
                state.changeBigShow = false
                state.transit = {}
                state.bigCarData = {}
                state.isShop = 0
                state.driver.oilAmt = 0
                state.driver.bankCard = 0
                getDetailData()
              }
            }).finally(() => { state.confirmLoading = false })
          } else message.error('金额不能为0')
        }
      } else {
        message.error('请选择是否门店')
      }

    }
    // 确认修改GPS类型
    const confirmGPSchange = () => {
      if (state.GPStype !== '') {
        state.btnLoading = true
        changeGPStype({
          gpsType: state.GPStype,
          trunkId: state.record.carrierTruckId
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.GPStype = null
            state.changeGPSshow = false
            getDetailData()
          }
        }).finally(() => { state.btnLoading = false })
      } else message.error('请选择')
    }
    return {
      ...toRefs(state),
      bigCarChooseRef,
      carrierRef,
      prompt,
      fillData,
      setAddress,
      confirmAdd,
      confirmGPSchange,
      confirmChangeLocation,
      confirmInspector,
      getLocation,
      repealClick,
      handleCope,
      imgListRef,
      clearData,
      cancelClose,
      setChangeAddress,
      stopAddressChange,
      closeArrange,
      getBigCarData,
      nullBtn,
      chooseCarrier,
      arrangeTransport,
      cancelLoadVeh,
      lookInspectioner,
      updateImg,
      getInspector,
      nullTableChange,
      inspectorComplete,
      takeCarComplet,
      getLineData,
      confirmBigCar,
      confirmTrans,
      transVehicle,
      unloadVeh,
      addReportData,
      abnormalData,
      getPassingPointData,
      confirmChangePrice,
      editTransportFee
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #eee;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(185, 183, 183);
  border-radius: 10px;
}

:deep(.ant-steps-item-title) {
  align-items: center;
}

:deep(.ant-typography) {
  margin-bottom: 0;
}

:deep(.ant-steps-item-content) {
  width: auto !important;

  .ant-steps-item-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .ant-steps-item-title>div {
    width: 100%;
  }
}

:deep(.ant-input-number) {
  border: none;
}

.input-div {
  display: flex;
  align-items: center;

  .input-data {
    margin-right: 10px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;

    :deep(.ant-input) {
      border: none !important;
    }
  }
}

.plant-time {
  :deep(.ant-steps-item-tail) {
    margin-left: 49% !important;
  }

  :deep(.ant-steps-item-content) {
    width: 100% !important;
  }

  .ant-steps-item:last-child {
    flex: 1 !important;
  }

  :deep(.ant-steps-item-tail) {
    margin-left: 49%;
  }

  :deep(.ant-steps-item-icon) {
    margin-left: 48% !important;
  }

  :deep(.ant-btn-primary) {
    margin-right: 10px;
  }

  :deep(.ant-steps-icon-dot) {
    background: #0C8226 !important;
  }

  :deep(.ant-steps-item-tail::after) {
    color: #0C8226 !important;
    background-color: #0C8226 !important;
  }
}

:deep(.ant-btn-primary) {
  margin-right: 10px
}

.loading-spin {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, .8);
  z-index: 10;
}

.content-title {
  margin: 15px 0 5px 0;
  color: #344563;
  font-weight: 700;
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
}

.content-data {
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 3px;
  background: rgba(242, 242, 242, .3);

  span {
    font-size: 12px;
  }
}

.operation {
  position: relative;

  .QRC-img {
    width: 200px;
    border: 1px solid #ccc;
    padding: 5px 20px;
    right: 100%;
    position: absolute;
    background-color: #fff;
    z-index: 9;
  }
}
</style>