import { postBodyRequest, getRequest } from '@/utils/axios'

export function outWarehousePage (params) {
  return postBodyRequest('/trans/order/vehicle/stock/inventory/page', params)
}
export function newOutWarehousePage (params) {
  return postBodyRequest('/trans/order/vehicle/stock/outware/list', params)
}

export function totalOutWarehouse (password, params) {
  return getRequest(`/trans/order/vehicle/stock/${password}`, params)
}

export function out (params) {
  return postBodyRequest('/trans/order/vehicle/stock/exWarehouse', params)
}
export function getAddressData (params) {
  return postBodyRequest('/trans/order/vehicle/stock/app/outware/stats', params)
}
