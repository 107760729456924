<template>
  <div>
    <div class="full-screen" style="margin-right:15px">
      <div>
        <!-- 基本信息 -->
        <div class="font-color2 detail-title" style="margin:15px 0;">
          <img class="img" src="@/assets/images/carrier/basic.png">&emsp;<span
            style="font-weight:bold;color:#344563">基本信息</span>
        </div>
        <div v-if="detailData" class="basic" @click="detailShow = !detailShow">
          <p class="basic-data font-color2">
            <span style="width:15%">{{ detailData.vinNo }}</span>
            <span style="width:15%">{{ detailData.brand + detailData.model }}</span>
            <span style="flex:1">{{ detailData.startAddress }}——{{ detailData.endAddress }}</span>
            <span style="width:15%;text-align: end;"><a class="text-cancel" @click.stop="takeShow = !takeShow">信息
              </a></span>
          </p>
          <div class="font-color" style="font-size:12px">
            <a-row :gutter="[24, 0]">
              <a-col :span="3">
                <span>业务员：{{ detailData.salesman }}</span>
              </a-col>
              <a-col :span="6">
                <span>下单时间：{{ detailData.pushTime }}</span>
              </a-col>
              <a-col :span="3">
                <span class="font-color2">运输类型：{{ detailData.transportType ? detailData.transportType.label : '' }}</span>
              </a-col>
              <a-col :span="3"
                v-if="detailData.transportType && detailData.transportType.value === 1 && detailData.pickType">
                <span class="font-color2">取车方式：{{ detailData.pickType.label }}</span>
              </a-col>
              <a-col :span="3"
                v-if="detailData.transportType && detailData.transportType.value === 3 && detailData.takeType">
                <span class="font-color2">送车方式：{{ detailData.takeType.label }}</span>
              </a-col>
              <a-col :span="12"
                v-if="detailData.transportType && detailData.transportType.value === 1 && detailData.placeInAddress">
                <span class="font-color2">取车地址：{{ detailData.placeInAddress }}</span>
              </a-col>
              <a-col :span="12"
                v-if="detailData.transportType && detailData.transportType.value === 3 && detailData.takeOutAddress">
                <span class="font-color2">送车地址：{{ detailData.takeOutAddress }}</span>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 0]">
              <a-col :span="12">
                <span style="color: #a02323;font-weight: bold">订单备注：{{ detailData.orderRemark }}</span>
              </a-col>
              <a-col :span="12">
                <span style="color: #a02323;font-weight: bold">小车备注：{{ detailData.remark }}</span>
              </a-col>
            </a-row>
          </div>
          <!-- 取车信息 -->
          <div class="take-car-data font-color2" v-show="takeShow">
            <a-row :gutter="[24, 12]">
              <a-col :span="12">
                交车人姓名：{{ detailData.picker }}
              </a-col>
              <a-col :span="12">
                交车人电话：{{ detailData.pickerMobile }}
              </a-col>
              <!-- <a-col :span="12">
                任务类型：无字段
              </a-col> -->
              <a-col :span="12">
                取车地址：{{ detailData.placeInAddress }}
              </a-col>
              <!-- <a-col :span="12">
                预计时间：无字段
              </a-col> -->
              <a-col :span="12">
                备注：{{ detailData.remark === '' ? '无备注' : detailData.remark }}
              </a-col>
            </a-row>
          </div>
        </div>
        <!-- 预装任务 -->
        <div v-if="advanceShow">
          <div class="text-center" style="margin:15px 0;font-weight:bold">
            <img class="img" src="@/assets/images/carrier/basic.png">&emsp;预装任务
          </div>
          <div class="order-data">
            <a-row :gutter="[24, 12]">
              <a-col :span="8">
                <span class="detail-look-title">已选择承运商</span>
                <span class="font-color2">{{ advanceData.stowageJson.carrierData.carrierName }}</span>
              </a-col>
              <a-col :span="8">
                <span class="detail-look-title">负责人</span>
                <span class="font-color2">{{ advanceData.stowageJson.carrierData.charge }}</span>
              </a-col>
              <a-col :span="8">
                <span class="detail-look-title">负责人电话</span>
                <span class="font-color2">{{ advanceData.stowageJson.carrierData.chargeMobile }}</span>
              </a-col>
              <a-col :span="24">
                <span class="detail-look-title">已选择线路</span>
                <span class="font-color2">{{ advanceData.stowageJson.IDs.lineData.lineName }}</span>
              </a-col>
              <a-col :span="8">
                <span class="detail-look-title">已选择大车</span>
                <span class="font-color2">{{ advanceData.stowageJson.bigCarData ? advanceData.stowageJson.bigCarData.carNo
                  : '无' }}</span>
              </a-col>
              <a-col :span="8">
                <span class="detail-look-title">司机姓名</span>
                <!-- <span class="font-color2">{{advanceData.bigCarData?advanceData.bigCarData.driver:''}}</span> -->
                <span class="font-color2">{{ advanceData.carrierDriverName ? advanceData.carrierDriverName : '无' }}</span>
              </a-col>
              <a-col :span="8">
                <span class="detail-look-title">司机电话</span>
                <!-- <span class="font-color2">{{advanceData.bigCarData?advanceData.bigCarData.driverMobile:''}}</span> -->
                <span class="font-color2">{{ advanceData.stowageJson.bigCarData ?
                  advanceData.stowageJson.bigCarData.mobile : '无' }}</span>
              </a-col>
              <a-col :span="8">
                <span class="detail-look-title">任务安排人</span>
                <span class="font-color2">{{ arrangerData.arranger }}</span>
              </a-col>
              <a-col :span="8">
                <span class="detail-look-title">安排时间</span>
                <span class="font-color2">{{ arrangerData.scheduleTime }}</span>
              </a-col>
              <a-col :span="8">
                <span class="detail-look-title">备注信息</span>
                <span class="font-color2">{{ arrangerData.remark }}</span>
              </a-col>
            </a-row>
          </div>
          <div style="display:flex;justify-content: end;margin-top:10px">
            <a-button style="margin-right:10px" @click="advanceShow = false, advanceData = {}">取消任务</a-button>
            <a-popconfirm title="确定使用该任务?" @confirm="confirmUse">
              <a-button type="primary">确认使用</a-button>
            </a-popconfirm>
          </div>
        </div>
        <!-- 选择路线 -->
        <div v-show="props.name !== undefined || !advanceShow">
          <div class="font-color2 detail-title" style="margin:15px 0;">
            <img class="img" src="@/assets/images/carrier/accept.png">
            <span style="font-weight:bold;color:#344563">&emsp;选择线路</span>&emsp;&emsp;<a v-show="!advanceData.orderId"
              v-if="planId === ''" @click="lineShow = !lineShow">{{ lineName === '' ? '选择' : '已选择/切换' }}</a>
            <span v-show="km !== ''" class="mlg-15">线路距离：{{ km }}公里</span>
            <span v-show="lineName !== ''" class="mlg-15">{{ lineName }}</span>
            <span v-show="storeName !== ''" class="mlg-15">{{ storeName }}</span>
          </div>
          <div>
            <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" v-model:value="startId"
              :changeOnSelect="true" @change="setStartAddress" placeholder="请输入起点" />
            ——
            <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" v-model:value="endId"
              :changeOnSelect="true" @change="setEndAddress" placeholder="请输入终点" />
              <a @click="carrierShow=true">确认</a>
          </div>
          <!-- <div>
            <p v-if="transportTypeV === 1" v-show="lineShow && planId === ''">
              <span>{{ detailData.placeInAddress }}</span>
              ——
              <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" v-model:value="endId"
                :changeOnSelect="true" @change="setEndAddress" placeholder="请输入终点" />
              <a @click="searchLine(), linePage.current = 1">搜索</a>
              <a-divider type="vertical" />
              <a @click="getLine(), linePage.current = 1">系统推荐</a>
            </p>
            <p v-if="transportTypeV === 2" v-show="lineShow && planId === ''">
              <span>{{ lineData.startAddress }}</span>
              ——
              <a-cascader :options="$store.state.app.PClist" :show-search="{ filter }" v-model:value="endId"
                :changeOnSelect="true" @change="setEndAddress" placeholder="请输入终点" />
              <a @click="searchLine(), linePage.current = 1">搜索</a>
              <a-divider type="vertical" />
              <a @click="getLine(), linePage.current = 1">系统推荐</a>
            </p>
            <p v-if="transportTypeV === 3" v-show="lineShow && planId === ''">
              <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" v-model:value="startId"
                :changeOnSelect="true" @change="setStartAddress" placeholder="请输入终点" />
              ——
              <span>{{ lineData.endAddress }}</span>
              <a-divider type="vertical" />
              <a @click="searchLine(), linePage.current = 1">搜索</a>
              <a-divider type="vertical" />
              <a @click="getLine(), linePage.current = 1">系统推荐</a>
            </p>
            <div v-show="lineShow && !lineLoading">
              <div class="line-list font-color2" v-for="(item, index) in lineList" :key="index"
                @click="confirmLine(item)">
                <span>{{ item.name }}</span>
                <a class="confirm">确认选择</a>
              </div>
            </div>
            <a-skeleton v-show="lineLoading" active :paragraph="{ rows: 5 }" />
            <div v-show="lineList.length > 0 && lineShow">
              <a-pagination size="small" :current="linePage.current" :total="linePage.total" :pageSize="linePage.pageSize"
                @change="lineListChange" />
            </div>
            <span v-show="lineList.length <= 0 && !lineLoading && startAddress.province"><a
                @click="addLine">点击添加</a></span>
          </div> -->
        </div>
        <!-- 选择承运商 -->
        <div v-show="carrierShow">
          <Carrier ref="carrierRef" :carrierId="carrierId" :record="record" :carrierList="carrierList"
            @confirm="carrierData" />
        </div>
        <a-button v-show="carrierId !== '' && props.name !== undefined" size="small" class="mrg-10"
          @click="remarkShow = true">直接预装</a-button>
        <a-button v-show="carrierId !== '' && props.name !== undefined" size="small"
          @click="bigCarShow = true">选择大车</a-button>
        <!-- 选择大车 -->
        <div v-show="bigCarShow">
          <div class="detail-title" style="margin:15px 0;">
            <img class="img" src="@/assets/images/carrier/bigCar.png">
            &emsp;<span style="font-weight:bold;">选择大车</span>&emsp;&emsp;
            <a @click="bigCarChoose = !bigCarChoose">{{ bigCarData.carNo === '' || bigCarData.carNo === undefined ? '选择' :
              '已选择/切换' }}</a>
            <span v-show="bigCarData.carNo !== ''" style="margin-left:15px;">{{ bigCarData.carNo }}</span>
          </div>
          <div class="big-table" v-show="!tableChange">
            <BigCarDriver v-if="carrierId !== ''" :carrierId="carrierId" @carDriverData="carDriverData" />
          </div>
          <div class="big-table" v-show="tableChange">
            <a-table :data-source="bigCarTable" v-model:columns="bigCarColumns" :pagination="false" :loading="loading"
              :rowKey="(record, index) => { return index }" size="small">
              <template #price>
                <div>
                  <span v-show="!priceEditStatus">{{ price !== '' && price !== undefined ? price : '暂无' }}</span>
                </div>
              </template>
              <template #operation="{ record }">
                <div>
                  <a-button :disabled="completeShow" type="link" size="small" @click="changeCar">{{ record.carNo === '' ||
                    record.carNo === undefined ? '更换地跑司机' : '更换大车及司机' }}</a-button>
                </div>
              </template>
            </a-table>
          </div>
          <a-space v-if="props.name === undefined" style="margin-top:25px">
            <a-button v-show="loadCarBtn && !addplanSuccess" type="primary" @click="chooseCar = true">装车</a-button>
            <a-button v-show="!completeShow && addplanSuccess" type="primary" @click="receivables = true">发车</a-button>
            <a-popconfirm title="确定中途到达?" @confirm="transitShow = true">
              <a-button v-show="completeShow" type="primary" style="background:#0077B8">中途到达</a-button>
            </a-popconfirm>
            <a-popconfirm title="确定到达?" @confirm="confirmArrival">
              <a-button v-show="completeShow" type="primary" style="background:#07C160">到达</a-button>
            </a-popconfirm>
          </a-space>
          <a-button v-show="props.name !== undefined && bigCarTable.length > 0"
            @click="remarkShow = true">新增预配载</a-button>
        </div>
      </div>
    </div>
    <!--    <div class="right" style="flex:1">-->
    <!--      <Record v-if="vehiclesId!== ''" :vehicleList="vehicleList" :vehiclesId="vehiclesId" :orderId="orderId"/>-->
    <!--    </div>-->
    <!-- 是否到达门店 -->
    <a-modal v-model:visible="chooseShow" title="请配置到达门店" @ok="confirmChoose" :destroyOnClose="true">
      <a-form>
        <a-form-item label="是否到达门店">
          <a-select :options="$store.state.enumAll.BooleanFlagEnum" style="width: 120px" @change="whetherArrival" />
        </a-form-item>
        <a-form-item label="卸车地">
          <a-cascader :options="$store.state.app.PClist" :show-search="{ filter }" :changeOnSelect="true"
            @change="setAddress" placeholder="卸车地选择" />
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 选择司机 -->
    <a-modal width="50%" v-model:visible="deiverShow" title="选择司机" @ok="deiverShow = false">
      <Driver :carrierId="carrierId" @onondriverName="chooseDriver" />
    </a-modal>
    <!-- 输入实际收款方式 -->
    <a-modal v-model:visible="receivables" title="填写收款方式" @ok="startCar">
      <h1>总金额<span style="color:#f00">{{ price }}</span></h1>
      <a-input addon-before="油卡" v-model:value="driver.oilCard" type='number' size="large">
        <template #suffix>
          <a-tooltip title="全部油卡">
            <EnterOutlined @click="driver.oilCard = price, driver.bankCard = 0" style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </template>
      </a-input>
      <a-divider type="vertical" />
      <a-input addon-before="转账" v-model:value="driver.bankCard" type='number' size="large">
        <template #suffix>
          <a-tooltip title="全部转账">
            <EnterOutlined @click="driver.bankCard = price, driver.oilCard = 0" style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </template>
      </a-input>
    </a-modal>
    <!-- 中转点选择(中途到达专用) -->
    <a-modal v-model:visible="transitShow" title="请选择中转地点" @ok="transitOperation">
      <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" placeholder="请选择中转地点" style="width: 100%"
        @change="transitAddress" />
    </a-modal>
    <!-- 到达(仅展示作用) -->
    <!-- <a-modal v-model:visible="arrivalShow" title="确认到达?" :width="800" @ok="confirmArrival">
      <a-table :data-source="BIGlistData" :loading="BIGloading" v-model:columns="BIGArrivecolumns" :rowKey="(record,index)=>{return index}" size="small" :pagination="false">
        <template #lineName="{record}">
          <span style="color:red" v-if="record.lineName != null">{{ record.lineName.split('-')[1] }}</span>
        </template>
      </a-table>
    </a-modal> -->
    <!-- 预装备注 -->
    <a-modal v-model:visible="remarkShow" title="新增预配载任务" @ok="confirmRemark">
      <div v-show="carrierDriverId !== ''">
        报价：
        <a-input v-model:value="price" type="number" />
      </div>
      备注：
      <a-textarea v-model:value="remark" placeholder="请填写备注" :rows="4" />
    </a-modal>
  </div>
  <!-- 中转地选择(新增预装) -->
  <a-modal v-model:visible="transitChooseShow" title="是否选择中转地" @ok="transitConfirm"
    @cancel="transitChooseShow = false, whether = null" :destroyOnClose="true">
    <a-radio-group v-model:value="whether">
      <a-radio :value="1">是</a-radio>
      <a-radio :value="2">否</a-radio>
    </a-radio-group>
    <a-divider />
    <a-cascader v-if="whether === 1" :options="$store.state.app.PClist" :show-search="{ filter }" :changeOnSelect="true"
      @change="setAddress" placeholder="中转地选择" />
  </a-modal>

  <a-modal v-model:visible="whetherOfficeShow" title="是否到达办事处" @ok="whetherConfirm" :destroyOnClose="true">
    <a-form-item label="是否到达门店">
      <a-select :options="$store.state.enumAll.BooleanFlagEnum" style="width: 120px" @change="whetherArrival" />
    </a-form-item>
  </a-modal>
  <!-- 正常装车填写报价 -->
  <a-modal v-model:visible="chooseCar" title="填写报价" :confirmLoading="confirmLoading" @ok="loadCar">
    <a-input v-model:value="price" type="number" />
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import { message } from 'ant-design-vue'
import { EnterOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { changeTransType } from '@/api/transport/transport'
import { lineChoose, linePage, getCarrier, gpsKm } from '@/api/transport/transportUse'
import { transPlan, transDepart, transfer, setAssembled, transArrive, advanceLoading, getAdvance, getTransData, changeCarrier, changeDriver } from '@/api/transport/truck'
import { vehicleId } from '@/api/transport/vehicle'
import { add as lineAdd } from '@/api/trans/line'
import Driver from '@/views/components/driver'
import Carrier from '../comon/carrierChoose.vue'
import BigCarDriver from '../comon/bigCarDriver.vue'
export default {
  props: { vehiclesArr: Array, name: String, close: Function },
  components: {
    Driver,
    Carrier,
    BigCarDriver,
    EnterOutlined
  },
  setup (props) {
    const carrierRef = ref(null)
    const store = useStore()
    const state = reactive({
      cityList: [],
      orderVehicleIds: [],
      transOrderVehicleIds: [],
      transOrderVehicleId: [],
      props: props,
      whether: 0,
      start: '',
      end: '',
      km: '',
      transportTypeV: '',
      lineStartId: '',
      lineEndId: '',
      storeName: '',
      remark: '',
      price: '',
      priceEdit: '',
      planId: '',
      lineName: '',
      unloadings: '',
      carrierDriverId: '',
      carrierId: '',
      vehiclesId: '',
      transportType: '',
      transOrderTruckVehicleId: '',
      chooseCar: false,
      loadCarBtn: false,
      carrierShow: false,
      confirmLoading: false,
      whetherOfficeShow: false,
      transitChooseShow: false,
      addplanSuccess: false,
      bigCarShow: false,
      lineLoading: false,
      remarkShow: false,
      completeShow: false,
      advanceShow: false,
      transitShow: false,
      arrivalShow: false,
      receivables: false,
      chooseShow: false,
      tableChange: false,
      deiverShow: false,
      bigCarChoose: false,
      lineShow: false,
      priceEditStatus: false,
      detailShow: false,
      takeShow: false,
      endId: [],
      startId: [],
      vehicleList: [],
      lineList: [],
      lineData: {
        isLock: { value: '' }
      },
      isShop: { value: '' },
      startAddress: {},
      endAddress: {},
      startAddressPland: {},
      endAddressPland: {},
      gpsKmEnd: '',
      advanceloadData: {},
      carrierData: {},
      advanceData: {},
      bigCarData: {},
      arrangerData: {},
      BIGlistData: [],
      lineId: '',
      carrierList: [],
      bigCarTable: [],
      detailData: {},
      transit: {},
      linePage: {
        total: 0,
        current: 1,
        pageSize: 5
      },
      driver: {
        oilCard: 0,
        bankCard: 0
      },
      arriveStore: {
        isShop: { label: '', value: '' }
      },
      bigCarColumns: [
        {
          title: '车辆类型',
          dataIndex: 'truckType.label'
        },
        {
          title: '车牌号',
          dataIndex: 'carNo'
        },
        {
          title: '大车司机',
          dataIndex: 'name'
        },
        {
          title: '司机电话',
          dataIndex: 'mobile'
        },
        {
          title: '剩余空位',
          dataIndex: 'vacancy'
        },
        {
          title: '历史承运次数',
          dataIndex: 'historyFrequency'
        },
        {
          title: '报价',
          slots: {
            customRender: 'price'
          }
        },
        {
          title: '选项',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      BIGArrivecolumns: [
        {
          title: '大车车牌',
          dataIndex: 'carNo',
          key: 'carNo',
          ellipsis: true
        },

        {
          title: '派车人',
          dataIndex: 'dispatcher',
          key: 'dispatcher',
          ellipsis: true
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          key: 'carrierName',
          ellipsis: true
        },
        {
          title: '大车司机',
          dataIndex: 'driverName',
          key: 'driverName',
          ellipsis: true
        },
        {
          title: '司机电话',
          dataIndex: 'driverTel',
          key: 'driverTel',
          ellipsis: true
        },
        {
          title: '运输状态',
          dataIndex: 'truckStatus.label',
          key: 'truckStatus.label',
          ellipsis: true
        },
        {
          title: '使用车位',
          dataIndex: 'usedParkPlace',
          key: 'usedParkPlace',
          ellipsis: true
        },

        {
          title: '线路终点',
          dataIndex: 'lineName',
          key: 'lineName',
          ellipsis: true,
          slots: {
            customRender: 'lineName'
          }
        }

      ]
    })
    // 发车
    const startCar = () => {
      transDepart(state.planId, state.driver).then(res => {
        if (res.code === 10000) {
          state.receivables = false
          message.success(res.msg)
          state.completeShow = true
        }
      })
    }
    // 更换大车
    const changeCar = () => {
      state.tableChange = false
    }
    // 搜索线路
    const setStartAddress = (value, selectedOptions) => {
      state.startAddressPland.province = value[0]
      state.startAddressPland.city = value[1]
      state.startAddressPland.area = value[2]
      // if (state.transportTypeV === 3) {
      //   if (Number(value[0]) === state.lineData.startProvinceId) {
      //     if (Number(value[1]) === state.lineData.startCityId) {
      //       state.startAddress.province = Number(value[0])
      //       state.startAddress.area = Number(value[1])
      //       state.startAddress.city = Number(value[2])
      //     } else {
      //       message.error('市级不可更改,请重试')
      //       state.startId[1] = String(state.lineData.startCityId)
      //     }
      //   } else {
      //     message.error('省份不可更改,请重试')
      //     state.startId[0] = String(state.lineData.startProvinceId)
      //   }
      // } else {
      //   state.startAddress.province = value[0]
      //   state.startAddress.city = Number(value[1])
      //   state.startAddress.area = value[2]
      // }
    }

    const setEndAddress = (value, selectedOptions) => {
      state.endAddressPland.province = value[0]
      state.endAddressPland.city = value[1]
      state.endAddressPland.area = value[2]
      // switch (state.transportTypeV) {
      //   case 1:
      //     if (Number(value[0]) === state.lineData.endProvinceId) {
      //       if (Number(value[1]) === state.lineData.endCityId) {
      //         state.endAddress.province = Number(value[0])
      //         state.endAddress.city = Number(value[1])
      //         state.endAddress.area = Number(value[2])
      //         state.gpsKmEnd = selectedOptions[0].label + selectedOptions[1].label + selectedOptions[2].label
      //         gpsKm({ // 获取路线 m
      //           orderId: state.detailData.orderId,
      //           start: state.endAddress,
      //           end: state.endAddress,
      //           startAddress: '',
      //           endAddress: state.gpsKmEnd
      //         }).then(res => {
      //           if (res.code === 10000) {
      //             message.success('线路距离：' + res.data + '公里')
      //             state.km = res.data
      //           }
      //         })
      //       } else {
      //         message.error('市级不可更改,请重试')
      //         state.endId[1] = String(state.lineData.endCityId)
      //       }
      //     } else {
      //       message.error('省份不可更改,请重试')
      //       state.endId[0] = String(state.lineData.endProvinceId)
      //     }
      //     break
      //   case 2:
      //     state.startAddress.province = state.lineData.startProvinceId
      //     state.startAddress.city = state.lineData.startCityId
      //     // state.startAddress.area = state.lineData.startAreaId

      //     state.endAddress.province = Number(value[0])
      //     state.endAddress.city = Number(value[1])
      //     // state.endAddress.area = Number(value[2])
      //     break
      //   case 3:
      //     if (Number(value[0]) === state.lineData.endProvinceId) {
      //       if (Number(value[1]) === state.lineData.endCityId) {
      //         state.endAddress.province = Number(value[0])
      //         state.endAddress.city = Number(value[1])
      //         state.endAddress.area = Number(value[2])
      //       } else {
      //         message.error('市级不可更改,请重试')
      //         state.endId[1] = String(state.lineData.endCityId)
      //       }
      //     } else {
      //       message.error('省份不可更改,请重试')
      //       state.endId[0] = String(state.lineData.endProvinceId)
      //     }
      //     break
      // }
    }
    // 自定义搜索线路
    const searchLine = () => {
      state.lineLoading = true
      state.endAddress.area = state.lineData.endAreaId
      linePage({ // 获取路线 m
        current: state.linePage.current,
        size: state.linePage.pageSize,
        startProvinceId: state.startAddress.province,
        startAreaId: state.startAddress.area,
        startCityId: state.startAddress.city,
        endProvinceId: state.endAddress.province,
        endCityId: state.endAddress.city,
        endAreaId: state.endAddress.area
      }).then(res => {
        if (res.code === 10000) {
          state.linePage.total = res.data.total
          state.lineList = res.data.records
          state.lineLoading = false
        }
      })
    }
    // 获取推荐线路
    const getLine = () => {
      state.lineLoading = true
      // state.startAddress.city = state.lineData.startCityId
      // state.endAddress.city = state.lineData.endCityId
      if (state.lineData.startCityId === null && state.lineData.endCityId === null) {
        setTimeout(() => {
          message.info('当前车辆没有推荐线路')
          state.lineList = []
          state.lineLoading = false
        }, 300)
      } else {
        state.start = ''
        state.end = ''
        linePage({ // 获取路线
          current: state.linePage.current,
          size: state.linePage.pageSize,
          startCityId: state.lineData.startCityId,
          endCityId: state.lineData.endCityId
        }).then(res => {
          if (res.code === 10000) {
            state.linePage.total = res.data.total
            state.lineList = res.data.records
            state.lineLoading = false
          }
        })
      }
    }
    // 线路分页
    const lineListChange = page => {
      state.linePage.current = page
      if (state.startAddress.city !== undefined && state.endAddress.city !== undefined) searchLine()
      else getLine()
    }
    // 获取默认路线
    const getWhether = () => {
      lineChoose({
        transportType: state.transportType,
        orderVehicleIds: state.orderVehicleIds
      }).then(res => {
        if (res.code === 10000) {
          state.lineData = res.data
          state.endId = [String(state.lineData.endProvinceId), String(state.lineData.endCityId)]
          state.startAddressPland.province = state.lineData.startProvinceId
          state.startAddressPland.area = state.lineData.startAreaId
          state.startAddressPland.city = state.lineData.startCityId
          state.startId = [String(state.lineData.startProvinceId), String(state.lineData.startCityId)]
          state.endAddressPland.province = state.lineData.endProvinceId
          state.endAddressPland.city = state.lineData.endCityId
          state.endAddressPland.area = state.lineData.endAreaId
          getLine()
          state.lineShow = true
        }
      }).catch(err => { console.log(err) })
    }
    // 获取承运商
    const getCarrierList = () => {
      // 承运商
      getCarrier({
        startCity: state.lineData.startCityId,
        endCity: state.lineData.endCityId,
        transportType: state.props.name === undefined ? state.props.vehiclesArr[0].transportType.value : state.props.vehiclesArr[0].preStowageType,
        orderVehicleIds: state.orderVehicleIds
      }).then(res => {
        if (res.code === 10000) {
          state.carrierList = res.data
          state.carrierShow = true
        }
      }).catch(err => { console.log(err) })
    }
    // 获取已安排小车列表
    const getSmallCar = () => {
      vehicleId(state.planId, ({
        current: 1,
        size: 5
      })).then(res => {
        if (res.code === 10000) {
          state.transOrderVehicleId = []
          res.data.records.forEach(item => {
            state.vehicleList.push({ id: item.orderVehicleId, vinNo: item.vinNo })
            state.transOrderVehicleId.push(item.transOrderTruckVehicleId)
          })
          if (res.data.records.length > 0) state.vehiclesId = res.data.records[0].orderVehicleId // 传入消息发送组件 默认第一辆车ID
          sessionStorage.removeItem('advance')
        }
      })
    }
    // 获取预装数据
    const loadData = () => {
      if (props.name === undefined) {
        getAdvance(state.orderVehicleIds[0], props.vehiclesArr[0].transportType.value)
        .then(res => {
          if (res.code === 10000 && res.data) {
            if (props.vehiclesArr[0].orderVehicleId === res.data.orderVehicleId) {
                state.advanceData = res.data
                state.advanceData.stowageJson = JSON.parse(res.data.stowageJson)
                state.arrangerData = {
                  remark: res.data.remark,
                  arranger: res.data.arranger,
                  scheduleTime: res.data.scheduleTime
                }
                if (typeof state.advanceData.stowageJson !== 'string') state.advanceShow = true
              }
            }
          }).catch(err => { console.log(err) })
      }
      state.transportTypeV = props.vehiclesArr[0].transportType.value
      getTransData(state.orderVehicleIds[0], state.transportType)
        .then(res => {
          if (res.code === 10000) {
            state.detailData = res.data
          }
        }).catch(err => { console.log(err) })
    }
    // 是否到达门店
    const whetherArrival = (e, v) => {
      state.arriveStore.isShop = v
    }
    // 确认到达门店及卸车地
    const confirmChoose = () => {
      state.chooseShow = false
      state.lineShow = true
      message.success('配置完成')
      getWhether()
    }
    // 确认路线
    const confirmLine = item => {
      state.lineEndId = item.endCity
      state.lineStartId = state.lineData.startCityId
      state.startAddressPland = {
        province: item.startProvinceId || '',
        city: item.startCityId || '',
        area: item.startAreaId || ''
      }
      state.endAddressPland = {
        province: item.endProvinceId || '',
        city: item.endCityId || '',
        area: item.endAreaId || ''
      }
      console.log(state.startAddressPland, state.endAddressPland, item);
      if (state.transportType === 2) state.transitChooseShow = true
      else {
        const index = state.cityList.findIndex(item => item.cityId === state.lineEndId)
        if (index >= 0 && state.transportTypeV !== 3) {
          state.whetherOfficeShow = true
        } else {
          getCarrierList()
        }
        state.lineShow = false
      }
      state.lineName = item.name
      state.lineId = item.id
    }
    // 卸车地
    const setAddress = (value, selectedOptions) => {
      switch (selectedOptions.length) {
        case 1:
          state.arriveStore.province = value[0]
          state.unloadings = selectedOptions[0].label
          break
        case 2:
          state.arriveStore.city = value[1]
          state.lineEndId = Number(value[1])
          state.unloadings = selectedOptions[0].label + selectedOptions[1].label
          break
        case 3:
          state.arriveStore.area = value[2]
          state.unloadings = selectedOptions[0].label + selectedOptions[1].label + selectedOptions[2].label
          break
      }
    }
    // 确认中转地
    const transitConfirm = () => {
      const index = state.cityList.findIndex(item => item.cityId === Number(state.lineEndId)) >= 0
      if (state.whether === 1) {
        // state.startAddress.city = state.lineData.startCityId
        state.endAddress.city = Number(state.arriveStore.city)
        state.startAddress.city = state.lineStartId
        state.start = ''
        state.end = ''
        searchLine()
        setTimeout(() => {
          if (state.lineList.length > 0) {
            state.lineName = state.lineList[0].name
            state.lineId = state.lineList[0].id
          } else message.error('当前中转地没有推荐线路,请新增')
        }, 500)
      } else {
        state.storeName = ''
      }
      if (index === true) {
        state.whetherOfficeShow = true
      } else {
        state.storeName = '目的地不存在办事处'
        state.transitChooseShow = false
        getCarrierList()
      }
    }
    // 确认是否办事处
    const whetherConfirm = async () => {
      const index = state.cityList.findIndex(item => item.cityId === state.lineEndId)
      if (state.arriveStore.isShop.value === 1) {
        if (index >= 0) {
          state.storeName = state.cityList[index].cityName + '办事处'
        }
      } else {
        state.storeName = '不到达办事处'
      }
      getCarrierList()
      state.whetherOfficeShow = false
      state.transitChooseShow = false
      state.lineShow = false
    }
    // 确认承运商
    const carrierData = e => {
      if (e) {
        state.carrierId = e.id
        state.carrierData = e
        if (state.planId === '') {
          if (state.props.name === undefined) { // undefined为正常任务 其他则为预装
            state.bigCarShow = true
            setAssembled({
              transLine: {
                carrierId: state.carrierId,
                // lineId: state.lineId,
                startAddress: state.startAddressPland,
                endAddress: state.endAddressPland
              }
            }).then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                state.planId = res.data // 任务ID
                if (state.advanceData !== undefined && state.advanceData.stowageJson) {
                  if (state.advanceData.stowageJson.bigCarData) {
                    state.bigCarTable[0] = state.advanceData.stowageJson.bigCarData
                    state.tableChange = true
                    if (state.advanceData.stowageJson.bigCarData.carNo) state.bigCarData.carNo = state.advanceData.stowageJson.bigCarData.carNo
                    if (state.advanceData.stowageJson.IDs.carrierTruckId) { // 更换大车及司机
                      changeTransType(state.planId, state.carrierTruckId, state.carrierDriverId)
                        .then(res => {
                          if (res.code === 10000) {
                            message.success(res.msg)
                            state.loadCarBtn = true
                            if (state.price !== '') confirmLoadCar(true)
                          }
                        })
                    } else if (state.advanceData.stowageJson.IDs.carrierDriverId) { // 更换地跑司机
                      changeDriver(state.planId, state.carrierDriverId)
                        .then(res => {
                          if (res.code === 10000) {
                            message.success(res.msg)
                            state.loadCarBtn = true
                            if (state.price !== '') confirmLoadCar(true)
                          }
                        })
                    }
                  }
                } else {
                  state.bigCarShow = true
                }
              }
            })
          } else { // 预装存值
            state.advanceloadData = {
              carrierId: state.carrierId,
              carrierName: state.carrierData.carrierName,
              orderId: state.props.vehiclesArr[0].orderId,
              orderVehicleId: state.orderVehicleIds[0],
              transportLineId: state.lineId,
              transportLineName: state.lineName,
              transportType: state.props.vehiclesArr[0].preStowageType,
              stowageJson: {
                vinNo: state.props.vehiclesArr[0].vinNo,
                IDs: {
                  price: state.price,
                  arriveStore: state.arriveStore,
                  lineData: { lineId: state.lineId, lineName: state.lineName, unloadings: state.unloadings, storeName: state.storeName },
                  carrierId: state.carrierId
                },
                carrierData: state.carrierData
              }
            }
          }
        } else if (state.planId !== '') {
          changeCarrier(state.planId, e.id)
            .then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                state.carrierId = e.id
              }
            })
        }
      }
    }
    // 选择大车组件数据回传
    const carDriverData = e => {
      if (e) {
        state.carrierDriverId = e.id
        state.carrierTruckId = e.carrierTruckId
        // 静态赋值 ↓
        state.tableChange = true
        state.deiverShow = false
        state.bigCarTable[0] = e
        // ↑
        if (state.planId !== '') { // 更换大车或司机
          if (e.carrierTruckId) { // 更换大车及司机
            changeTransType(state.planId, state.carrierTruckId, state.carrierDriverId)
              .then(res => {
                if (res.code === 10000) {
                  message.success(res.msg)
                }
              })
          } else { // 更换地跑司机
            changeDriver(state.planId, state.carrierDriverId)
              .then(res => {
                if (res.code === 10000) {
                  message.success(res.msg)
                }
              })
          }
        } else {
          if (state.props.name === undefined) {
          } else {
            const idObject = {
              carrierTruckId: state.carrierTruckId,
              price: e.price,
              carrierDriverId: state.carrierDriverId
            }
            const object = {
              carrierDriverId: state.carrierDriverId,
              carrierDriverName: e.name,
              carrierTruckId: state.carrierTruckId,
              carrierTruckName: e.carNo
            }
            state.advanceloadData.stowageJson.bigCarData = state.bigCarTable[0]
            state.advanceloadData.stowageJson.IDs = Object.assign(idObject, state.advanceloadData.stowageJson.IDs)
            state.advanceloadData = Object.assign(object, state.advanceloadData)
            state.remarkShow = true
            confirmLoadCar()
          }
        }
        if ((!state.completeShow && state.addplanSuccess === true) === true) state.loadCarBtn = false
        else state.loadCarBtn = true
      }
    }
    // 装车
    const loadCar = () => {
      if (state.props.name === undefined) {
        state.confirmLoading = true
        const isShop = state.arriveStore.isShop
        delete state.arriveStore.isShop
        transPlan({
          transit: state.arriveStore,
          freight: state.price,
          transTruckId: state.planId,
          transOrderVehicleIds: state.transOrderVehicleIds,
          isShop: isShop
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.chooseCar = false
            state.addplanSuccess = true
            state.loadCarBtn = false
            getSmallCar()
            // vehicleId(state.planId, ({
            //   current: 1,
            //   size: 5
            // })).then(res => {
            //   if (res.code === 10000) {
            //     state.transOrderTruckVehicleId = res.data.records[0].transOrderTruckVehicleId
            //     res.data.records.forEach(item => {
            //       state.vehicleList.push({ id: item.orderVehicleId, vinNo: item.vinNo })
            //     })
            //     if (res.data.records.length > 0) state.vehiclesId = res.data.records[0].orderVehicleId // 传入消息发送组件 默认第一辆车ID
            //   }
            // })
          } else state.loadCarBtn = true
        }).finally(() => {
          state.confirmLoading = false
        })
      }
    }
    // 确认预备载自动装车
    const confirmLoadCar = (status) => {
      if (state.props.name === undefined) { // undefined为常规装车 其他则为预装车
        transPlan({
          freight: state.price,
          transit: state.arriveStore,
          transTruckId: state.planId,
          transOrderVehicleIds: state.transOrderVehicleIds,
          isShop: state.arriveStore.isShop
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            if (status === true) {
              if (state.bigCarTable.length > 0) state.tableChange = true
              else state.tableChange = false
              state.advanceShow = false
              state.deiverShow = false
              if (state.planId !== '') state.loadCarBtn = false
              if (state.loadCarBtn === false) {
                state.addplanSuccess = true
                state.completeShow = false
              }
              // carrierRef.value.choose(state.advanceData.carrierData)
            }
            getSmallCar()
          }
        })
      }
    }
    // 添加预备在任务
    const confirmRemark = () => {
      state.advanceloadData.stowageJson.IDs.price = state.price
      state.advanceloadData.isShop = state.arriveStore.isShop
      state.advanceloadData.remark = state.remark
      state.advanceloadData.stowageJson = JSON.stringify(state.advanceloadData.stowageJson)
      state.remarkShow = false
      advanceLoading(state.orderVehicleIds[0], state.advanceloadData)
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.props.close()
            loadData()
          }
        }).catch(err => { console.log(err) })
    }
    // 使用预装任务
    const confirmUse = () => {
      state.arriveStore = state.advanceData.stowageJson.IDs.arriveStore
      state.lineName = state.advanceData.stowageJson.IDs.lineData.lineName
      state.lineId = state.advanceData.stowageJson.IDs.lineData.lineId
      state.unloadings = state.advanceData.stowageJson.IDs.lineData.unloadings
      state.storeName = state.advanceData.stowageJson.IDs.lineData.storeName
      state.lineShow = false
      state.advanceShow = false
      state.carrierId = state.advanceData.stowageJson.IDs.carrierId
      state.carrierDriverId = state.advanceData.stowageJson.IDs.carrierDriverId
      state.carrierTruckId = state.advanceData.stowageJson.IDs.carrierTruckId
      state.price = state.advanceData.stowageJson.IDs.price
      state.carrierShow = true
      carrierRef.value.choose(state.advanceData.stowageJson.carrierData)
    }
    // 中转地
    const transitAddress = (e, v) => {
      state.transit.province = e[0]
      state.transit.city = e[1]
      state.transit.area = e[2]
    }
    // 选择中转地 确认-----中途到达
    const transitOperation = () => {
      transfer(state.planId, {
        stopAddress: state.transit,
        transTruckId: state.planId,
        transOrderVehicleIds: state.transOrderVehicleId // 已安排车ID
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.props.close()
        }
      })
    }
    // 到达
    const confirmArrival = () => {
      transArrive(state.planId, {})
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.arrivalShow = false
            state.props.close()
          }
        })
    }
    // 自动添加线路
    const addLine = () => {
      lineAdd({ transLine: { type: 1, isEnable: 1, startAddress: state.startAddress, endAddress: state.endAddress } }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          searchLine()
        }
      }).catch(err => {
        console.log(err)
      })
    }
    onMounted(() => {
      props.vehiclesArr.forEach(item => {
        state.orderVehicleIds.push(item.orderVehicleId)
        state.transOrderVehicleIds.push(item.transOrderVehicleId)
      })
      state.props = props
      state.transportType = state.props.name === undefined ? state.props.vehiclesArr[0].transportType.value : state.props.vehiclesArr[0].preStowageType
      loadData()
      getWhether()
      state.cityList = store.state.app.storeCity
    })
    return {
      ...toRefs(state),
      startCar,
      getLine,
      loadCar,
      carDriverData,
      whetherConfirm,
      lineListChange,
      searchLine,
      setStartAddress,
      setEndAddress,
      carrierRef,
      confirmUse,
      confirmRemark,
      confirmArrival,
      transitAddress,
      transitConfirm,
      transitOperation,
      whetherArrival,
      setAddress,
      confirmChoose,
      changeCar,
      confirmLine,
      carrierData,
      loadData,
      addLine
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
</style>
