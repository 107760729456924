<template>
  <a-modal v-model:visible="modalShow" width="90%" footer="" @cancel="cancel"
    :bodyStyle="{ padding: '0 0 15px 0' }">
    <div class="window-content">
      <a-tabs @change="tabsChange">
        <a-tab-pane key="1" tab="小车操作">
          <SmallVehicleOperate :userData="{ userData: userData, groupListData: groupListData }" ref="smallVehRef" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="大车操作">
          <BigVehicleOperate :userData="{ userData: userData, groupListData: groupListData }" ref="bigVehicleRef" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="派单任务">
          <TaskAssignment  v-if="keyVla == 3" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, watch, ref } from 'vue'

import { getGroupList, getIdentity } from '@/api/transport/dispatch'
import SmallVehicleOperate from './smallVehicleOperate/smallVehicleOperate.vue'
import BigVehicleOperate from './bigVehicleOperate/bigVehicleOperate.vue'
import ShopPlantList from './shopPlant/shopPlantList.vue'
import TaskAssignment from './taskAssignment/taskAssignment.vue'

export default {
  components: {
    SmallVehicleOperate,
    BigVehicleOperate,
    ShopPlantList,
    TaskAssignment
  },
  props: {
    showDOM: Boolean,
    transportIndex: Number
  },
  setup (props, context) {
    const smallVehRef = ref(null)
    const bigVehicleRef = ref(null)
    const shopPlantRef = ref(null)
    const keyVla = ref(0)
    const state = reactive({
      modalShow: props.showDOM,
      small: false,
      big: false,
      shop: false,
      userData: {
        empName: '',
        empType: { value: 0 }
      },
      groupListData: [],
      transportIndex: props.transportIndex
    })
    watch(async () => props.showDOM, (newVal, oldVal) => {
      state.modalShow = props.showDOM
      if (state.modalShow === true) {
        groupList()
      }
    })
    const tabsChange = (e) => {
      if (e == 2 && state.big === false) {
        setTimeout(() => {
          bigVehicleRef.value.loadData(2)
          bigVehicleRef.value.getEmpData()
          state.big = true
        }, 50)
      }
      if (e == 3 && state.shop === false) {
          keyVla.value = e
      }
    }
    // 获取当前账户身份信息
    const getAccountData = () => {
      getIdentity().then(res => {
        if (res.code === 10000) {
          if (res.data) {
            state.userData = res.data
          }
        }
      })
    }
    // 获取小组成员LIST
    const groupList = () => {
      getAccountData()
      getGroupList().then(res => {
        if (res.code === 10000) {
          if (res.data) {
            state.groupListData = res.data
            setTimeout(() => {
              smallVehRef.value.getEmpData()
            }, 50)
            if (state.groupListData && state.groupListData.length > 0) {
              state.empShow = state.groupListData.find(item => item.empId == state.userData.empId) !== undefined
            }
          }
        }
      })
    }
    const cancel = () => {
      state.modalShow = false
      context.emit('statusShow', false)
    }

    return {
      ...toRefs(state),
      tabsChange,
      cancel,
      smallVehRef,
      bigVehicleRef,
      shopPlantRef,
      keyVla
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';

:deep(.ant-modal-body) {
  padding: 10px !important;
  padding-left: 0 !important;
}

.ant-modal-body {
  padding: 10px !important;
  padding-left: 0 !important;
}

.window-content {
  :deep(.ant-tabs-ink-bar) {
    display: none !important;
  }

  :deep(.ant-tabs-tab) {
    margin: 0 !important;
    color: #6B778C !important;
    font-weight: bold !important;
    font-size: 18px;
    padding: 10px 40px !important;
    background-color: #F2F2F2;
  }

  :deep(.ant-tabs-tab-active) {
    color: #fff !important;
    background-color: #0066CC !important;
  }
}
</style>