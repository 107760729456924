import { postBodyRequest, getRequest } from '@/utils/axios'
// 1.创建会员
export function createMember (params) {
  return getRequest('/pay/yunst/member/createMember', params)
}
// 2．发送短信验证码 codeType 9-绑定手机，6-解绑手机
export function sendVerificationCode (params) {
  return postBodyRequest('/pay/yunst/member/sendVerificationCode', params)
}
// 3．绑定手机
export function bindPhone (params) {
  return postBodyRequest('/pay/yunst/member/bindPhone', params)
}
// 4．修改绑定手机
export function updatePhoneByPayPwd (params) {
  return postBodyRequest('/pay/yunst/member/updatePhoneByPayPwd', params)
}
// 5．解绑手机
export function unbindPhone (params) {
  return postBodyRequest('/pay/yunst/member/unbindPhone', params)
}
// 6．个人会员需做实名认证接口
export function setRealName (params) {
  return postBodyRequest('/pay/yunst/member/setRealName', params)
}
// 7．企业会员需做设置企业信息接口
export function setCompanyInfo (params) {
  return postBodyRequest('/pay/yunst/member/setCompanyInfo', params)
}
// 8．企业会员需做影印件采集接口
export function idCardCollect (params) {
  return postBodyRequest('/pay/yunst/member/idCardCollect', params)
}
// 9．企业会员需做企业信息审核结果通知接口
export function verifyResultBackUrl (params) {
  return postBodyRequest('/pay/yunst/member/verifyResultBackUrl', params)
}
// 10．企业会员需做影印件核对结果异步通
export function ocrComparisonResultBackUrl (params) {
  return postBodyRequest('/pay/yunst/member/ocrComparisonResultBackUrl', params)
}
// 11．企业会员可以添加对公账户，需接入企业会员绑定对公户接口
export function bindCompanyAccount (params) {
  return postBodyRequest('/pay/yunst/member/bindCompanyAccount', params)
}
// 12．账户提现协议签约接口
export function signAcctProtocol (params) {
  return getRequest('/pay/yunst/member/signAcctProtocol', params)
}
//  13．账户协议签约查询接口
export function signContractQuery (params) {
  return getRequest('/pay/yunst/member/signContractQuery', params)
}
// 14.1 请求绑定银行卡
export function applyBindBankCard (params) {
  return postBodyRequest('/pay/yunst/member/applyBindBankCard', params)
}
// 14.2 确认绑定银行卡
export function bindBankCard (params) {
  return postBodyRequest('/pay/yunst/member/bindBankCard', params)
}
// 14.3 查询绑定银行卡
export function queryBankCard (params) {
  return postBodyRequest('/pay/yunst/member/queryBankCard', params)
}
// 14.4 解绑银行卡
export function unbindBankCard (params) {
  return postBodyRequest('/pay/yunst/member/unbindBankCard', params)
}
// 15.1 锁定会员
export function lockMember (params) {
  return getRequest('/pay/yunst/member/lockMember', params)
}
// 15.2 解锁会员
export function unlockMember (params) {
  return getRequest('/pay/yunst/member/unlockMember', params)
}
// 16 获取会员信息
export function getMemberInfo (params) {
  return getRequest('/pay/yunst/member/getMemberInfo', params)
}
// 17 查询余额
export function queryBalance (params) {
  return getRequest('/pay/yunst/order/queryBalance', params)
}
// 18 提现申请
export function withdrawApply (params) {
  return postBodyRequest('/pay/yunst/order/withdrawApply', params)
}
// 19 确认支付（后台+短信验证码确认）
export function payByBackSMS (params) {
  return postBodyRequest('/pay/yunst/order/payByBackSMS', params)
}
// 20 提现列表
export function withdrawApplyList (params) {
  return postBodyRequest('/pay/yunst/order/withdrawApplyList', params)
}
// 21 提现申请列表
export function refundList (params) {
  return postBodyRequest('/pay/yunst/order/refundList', params)
}
// 22 退款
export function refund (params) {
  return postBodyRequest('/pay/yunst/order/refund', params)
}
// 23 消费申请列表
export function consumeApplyList (params) {
  return postBodyRequest('/pay/yunst/order/consumeApplyList', params)
}
// 23 消费申请列表
export function getMemberList (params) {
  return postBodyRequest('/pay/yunst/member/getMemberList', params)
}
//  获取会员关系表
export function getMemberRel (params) {
  return postBodyRequest('/pay/yunst/pay/getMemberRel', params)
}
// 绑定会员关系表
export function addMemberRel (params) {
  return postBodyRequest('/pay/yunst/pay/addMemberRel', params)
}

// 设置主账号
export function setMainAccount (params) {
  return getRequest('/pay/yunst/pay/setMainAccount', params)
}
// 删除账号
export function deleteAccount (params) {
  return getRequest('/pay/yunst/pay/unbindMemberRel', params)
}
// 回单下载
export function receiptDownload (params) {
  return getRequest('/pay/yunst/order/eleReceiptDownload', params)
}