<template>
  <div>
    <a-modal :visible="visible" :title="title" footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
        <div v-for="(item,index) in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.id ?'changeItem':''">
         <span v-if="index !== 0"> {{ typeKey }}-</span>{{ item.name }}&nbsp;&nbsp;{{ item.total }}
        </div>
      </div> 
      <SearchList :iptData="iptData"></SearchList>
      <div class="m-t1 tableBox">
        <a-table :dataSource="data" :columns="columns" bordered :pagination="false">
          <template #add="{ record }">
            <a>操作</a>
          </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>
  
  <script setup>
import { ref } from 'vue'
import SearchList from '@/components/searchList'

const emit = defineEmits(['update:visible'])
const props = defineProps({
  title:{
    type: String,
    default: ''
  },
  visible: {
    type: Boolean,
    default: false
  },
  typeKey:{
    type: String,
    default: ''
  },
  iptData: {
    type: Array,
    default: () => []
  },
  data: {
    type: Array,
    default: () => []
  },
  columns:{
    type: Array,
    default: () => []
  }
})
const cancel = () => {
  emit('update:visible', false)
}
const indexKey = ref(1)



const changeBtn = (e) => {
  indexKey.value = e.id
}


 const tabstatus = ref([
  {
    name: '全部',
    id: 1,
    total: '192'
  },
  {
    name: '正常',
    id: 2,
    total: '192'
  },
  {
    name: '预警',
    id: 3,
    total: '192'
  },
  {
    name: '超时',
    id: 4,
    total: '192'
  },
  {
    name: '严重',
    id: 5,
    total: '192'
  },
])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
}
</style>