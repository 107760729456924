<template>
  <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
    <a-form-item label="别名">
      <a-input v-model:value="addForm.alias" placeholder="请填写" />
    </a-form-item>
    <a-form-item label="所属区域" name="addressArray">
      <a-cascader v-model:value="addForm.addressArray" :options="$store.state.app.cityDict" :show-search="{ filter }"
        :changeOnSelect="true" @change="setAddress" placeholder="请选择" />
    </a-form-item>
    <!-- <a-form-item label="移动地图上红点定位详细地址">
      <div class="map">
        <OptionalMap @onData="onData" :num="0" :city="city" :adCode="adCode" :details="addForm.addressDetails" ref="map" />
      </div>
    </a-form-item> -->
    <a-form-item label="详细地址" name="addressDetails">
      <a-input v-model:value="addForm.addressDetails" placeholder="请填写详细地址" />
      <!-- <a-button class="form-input-button" @click="onAddressSearch">搜索</a-button> -->
    </a-form-item>
    <a-form-item label="对接人" name="dockingPerson">
      <a-input v-model:value="addForm.dockingPerson" placeholder="请填写对接人" />
    </a-form-item>
    <a-form-item label="对接人电话" name="dockingMobile">
      <a-input v-model:value="addForm.dockingMobile" placeholder="请填写对接人电话" />
    </a-form-item>
    <a-form-item label="身份证号码" name="idNumber">
      <a-input v-model:value="addForm.idNumber" placeholder="请填写身份证号码" />
    </a-form-item>
    <div style="display:flex;justify-content: space-around;">
      <div @click="flag = 8" style="display:flex;flex-direction: column;">
        <div><span style="color:#f00">*</span>对接人身份证-正面</div>
        <!-- <a-image v-if="fileImg.positive" style="width:150px;height:150px" :src="fileImg.idCardFront" /> -->
        <a-upload v-model:file-list="fileList" list-type="picture-card" name="avatar" class="avatar-uploader"
          :show-upload-list="false" :before-upload="beforeUpload" @change="imgUpload">
          <img v-if="addForm.idCardFrontFileUrl" style="width:100px;height:100px" :src="addForm.idCardFrontFileUrl.url" />
          <div v-else>
            <loading-outlined v-if="positiveLoad"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class="ant-upload-text">上传</div>
            <!-- <div><a>上传</a></div> -->
          </div>
        </a-upload>
      </div>
      <div @click="flag = 9" style="display:flex;flex-direction: column;">
        <div><span style="color:#f00">*</span>对接人身份证-反面</div>
        <!-- <a-image v-if="fileImg.idCardEnd" style="width:150px;height:150px" :src="fileImg.idCardEnd" /> -->
        <a-upload v-model:file-list="fileList" list-type="picture-card" name="avatar" class="avatar-uploader"
          :show-upload-list="false" :before-upload="beforeUpload" @change="imgUpload">
          <img v-if="addForm.idCardEndFileUrl" style="width:100px;height:100px" :src="addForm.idCardEndFileUrl.url" />
          <div v-else>
            <loading-outlined v-if="wrongSideLoad"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class="ant-upload-text">上传</div>
            <!-- <div><a>上传</a></div> -->
          </div>
        </a-upload>
      </div>
    </div>
    <div style="text-align: right;"><a-button v-show="!btnShow" style="background-color: #2684FF;color:#fff" @click="onData">{{ addForm.id ? '确认编辑'
      : '新增' }}</a-button></div>
  </a-form>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import OptionalMap from '@/views/components/optionalMap'
import { fileUploadPublic } from '@/api/global'
import { idCardCollect } from '@/api/carrier/cloudFlash'
import { getDetailData } from '@/api/carrier/addressList'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
export default {
  props: {
    addressArray: Array,
    detailData: Object,
    btnShow: Boolean
  },
  components: { OptionalMap, PlusOutlined, LoadingOutlined },
  setup (props, context) {
    const formRef = ref(null)
    const map = ref(null)
    const state = reactive({
      clearShow: true,
      adCode: null,
      positiveLoad: false,
      wrongSideLoad: false,
      flag: 0,
      btnShow: props.btnShow,
      imgFileList: [],
      addForm: {
        addressArray: props.addressArray,
        dockingPerson: null,
        dockingMobile: null,
        idNumber: null,
        idCardFront: null, // 正面
        idCardEnd: null, // 反面
        idCardEndFileUrl: null,
        idCardFrontFileUrl: null
      },
      fileImg: {
        idCardFront: null,
        idCardEnd: null
      },
      rules: {
        addressArray: { required: true, message: '请选择所属区域', trigger: 'blur', type: 'array' },
        addressDetails: { required: true, message: '请填写详细地址', trigger: 'blur' },
        dockingPerson: { required: true, message: '请填写对接人', trigger: 'blur' },
        dockingMobile: { required: true, message: '请填写对接人电话', trigger: 'blur' },
        idNumber: { required: true, message: '请填写身份证号码', trigger: 'blur' }
      },
      loading: false,
      city: ''
    })
    onMounted(() => {
      state.mirrorAddForm = JSON.stringify(state.addForm)
    })
    const getData = () => {
      if (props.detailData.id) {
        getDetailData({
          id: props.detailData.id
        }).then(res=>{
          if(res.code === 10000){
            res.data.addressArray = [String(res.data.addressProvince),String(res.data.addressCity),String(res.data.addressArea)]
            state.addForm = res.data
          }
        })
      }
    }
    const clearData = () => {
      state.addForm = JSON.parse(state.mirrorAddForm)
    }
    // 选择城市
    const setAddress = (value, selectedOptions) => {
      if (selectedOptions.length > 0) {
        state.adCode = selectedOptions[selectedOptions.length - 1].code
        state.addForm.addressProvince = value[0]
        state.city = selectedOptions[0].label
        if (value.length > 1) {
          state.addForm.addressCity = value[1]
          state.city = state.city + selectedOptions[1].label
          state.addMapShow = false
        }
        if (value.length > 2) {
          state.addForm.addressArea = value[2]
          state.city = state.city + selectedOptions[2].label
          state.addMapShow = false
        }
        setTimeout(() => {
          state.addMapShow = true
          state.addForm.address = state.city
          state.addForm.lat = ''
          state.addForm.lon = ''
          state.addForm.addressDetails = ''
        }, 500)
      }
    }
    // 给父组件传值
    const onData = data => {
      formRef.value.validate().then(() => {
        if (state.addForm.idCardFront !== null && state.addForm.idCardEnd !== null) {
          // 地图取消-暂停用
          // state.addForm.lat = data.lat
          // state.addForm.lon = data.lng
          // state.addForm.addressDetails = data.address
          // state.addForm.address = data.addressComponents.province + ' ' + data.addressComponents.city + ' ' + data.addressComponents.district
          state.addForm.lat = 0.0
          state.addForm.lon = 0.0
          context.emit('acceptCode', state.addForm)
          // if (data !== undefined) context.emit('acceptCode', state.addForm)
          setTimeout(() => {
            state.addForm = JSON.parse(state.mirrorAddForm)
          }, 1000)
        } else message.error('请上传身份证照片')
      })
    }
    const beforeUpload = file => {
      state.imgFileList.push(file)
    }
    // 图片上传
    const imgUpload = info => {
      if (state.flag === 8) state.positiveLoad = true
      if (state.flag === 9) state.wrongSideLoad = true
      const formData = new FormData()
      formData.append('file', state.imgFileList[0])
      state.imgFileList = []
      fileUploadPublic(formData).then(res => {
        if (res.code === 10000) {
          state.imgFileList = []
          if (state.flag === 8) {
            // state.fileImg.idCardFront = res.data.url
            state.addForm.idCardFront = res.data.id
            state.addForm.idCardFrontFileUrl = res.data
          }
          if (state.flag === 9) {
            // state.fileImg.idCardEnd = res.data.url
            state.addForm.idCardEnd = res.data.id
            state.addForm.idCardEndFileUrl = res.data
          }
        }
      }).finally(() => {
        state.positiveLoad = false
        state.wrongSideLoad = false
        state.flag = 0
      })
    }
    const onAddressSearch = () => {
      state.addMapShow = false
      setTimeout(() => {
        state.addMapShow = true
      }, 500)
      map.value.addressSearch()
    }

    const open = () => {
      state.addMapShow = true
    }
    return {
      ...toRefs(state),
      formRef,
      setAddress,
      onData,
      getData,
      clearData,
      beforeUpload,
      imgUpload,
      map,
      onAddressSearch,
      open
    }
  }
}
</script>

<style lang="less" scoped>
.map {
  width: 100%;
  height: 300px;
}

.form-input-button {
  height: 36px;
  margin-left: 10px;
}

:deep(.ant-form-item) {
  margin-bottom: 10px;
}

.avatar-uploader>.ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
