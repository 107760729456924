import { postBodyRequest } from '@/utils/axios'

export function add (params) {
  return postBodyRequest('/market/crm/contact/add', params)
}
export function edit (params) {
  return postBodyRequest('/market/crm/contact/edit', params)
}

export function contactCustomerIdPage (customerId, params) {
  return postBodyRequest(`/market/crm/contact/${customerId}/page`, params)
}
