<template>
  <div>
    <div class=" itemBox">
      <div class="flex ju-between al-center">{{ item.title }} <span>{{ item.total }}</span></div>
      <div class="flex fz-12 ju-between m-t1">
        <div v-for="items in item.list" :key="items.id">
          <span :style="{ color: items.color}">{{ items.title }}&nbsp;{{ items.total }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const props =defineProps({
  item: {
    type: Object,
    default: {}
  }
})

</script>

<style lang="less" scoped>
.itemBox {
  margin-top: 10px;
  padding: 10px;
  background: #f7f8fc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  .tips {
    font-size: 12px;
    color: #999;
  }
}
</style>