import { postBodyRequest, getRequest } from '@/utils/axios'

export function documentaryPage (params) {
  return postBodyRequest('/trans/order-documentary/page', params)
}
export function follow (orderId) {
  return postBodyRequest(`/trans/order-documentary/${orderId}/follow`)
}
export function addFollow (params) {
  return postBodyRequest('/trans/order-documentary/add',params)
}
export function followList (orderVehicleId,params) {
  return postBodyRequest(`/trans/order-documentary/${orderVehicleId}/vehicle-page`,params)
}
export function resultFollow (params) {
  return postBodyRequest('/trans/order-documentary/handle',params)
}
export function followAbnormalPage (params) {
  return postBodyRequest('/trans/order-documentary/all-vehicle-page',params)
}
export function followDetailList (vehicleDocumentaryId) {
  return postBodyRequest(`/trans/order-documentary/${vehicleDocumentaryId}/vehicle-documentary-list`)
}
export function abnormalImgs (params) {
  return postBodyRequest('/trans/order/vehicle/inspect/files', params)
}
//小车在途
export function vehicleInTransitPage (params) {
  return postBodyRequest('/trans/order/vehicle/transit-page', params)
}
// 装车计划
export function loadingPalyPage (params) {
  return postBodyRequest('/trans/order/vehicle/loading-plan/page', params)
}
// 拆分订单
export function splitVehicle (params) {
  return postBodyRequest('/trans/order/split-vehicle', params)
}
