import { postBodyRequest, getRequest } from '@/utils/axios'

export function employeeList (params) {
  return postBodyRequest('/cis/employee/list', params)
}

export function getPageList (params) {
  return postBodyRequest('/market/crm/config/list', params)
}

export function addRules (params) {
  return postBodyRequest('/market/crm/config/add', params)
}

export function editConfig (params) {
  return postBodyRequest('/market/crm/config/edit', params)
}

export function getLabelType (params) {
  return getRequest('/market/crm/label/getCustomerLabelType', params)
}

export function getLabelPage (params) {
  return postBodyRequest('/market/crm/label/customerLabelQuery', params)
}

export function addLabel (params) {
  return postBodyRequest('/market/crm/label/addCustomerLabel', params)
}

export function deleteLabel (params) {
  return getRequest('/market/crm/label/deleteCustomerLabel', params)
}

export function CustomerLabelType (params) {
  return getRequest('/market/crm/label/getCustomerLabelType', params)
}
export function returnLook (orgId, params) {
  return getRequest(`/market/crm/config/fall-out-config/${orgId}`, params)
}
export function confirmReturnEdit (params) {
  return postBodyRequest('/market/crm/config/fall-out-config', params)
}