<template>
  <a-drawer title="选择客户" v-model:visible="drawerVisible" width="600" :bodyStyle="{padding:0}">
    <a-card>
      <a-space>
        <a-input-search class="extra-content-search" v-model:value="name" placeholder="搜索栏" @search="searchData" />
        <!-- <a-button @click="modalVisible = true" danger>新增客户</a-button> -->
      </a-space>
    </a-card>
    <a-list :rowKey="item => item.id" :data-source="listData" itemLayout="vertical">
      <template #renderItem="{ item }">
        <a-list-item class="list-item" @click="checkItem(item)">
          <a-list-item-meta>
            <template #avatar>
              <a-avatar shape="square">{{ item.name[0]}}</a-avatar>
            </template>
            <template #title>
              {{item.name}}
            </template>
            <template #description>
              {{item.certificateNumber}}
            </template>
          </a-list-item-meta>

          <a-descriptions :column="1" size="small">
            <a-descriptions-item label="电话">{{item.mobile}}</a-descriptions-item>
            <a-descriptions-item label="地址">{{item.address}}</a-descriptions-item>
            <a-descriptions-item label="对接人">{{item.receiver}} </a-descriptions-item>
            <a-descriptions-item label="对接人电话">{{item.receiverMobile}}</a-descriptions-item>
            <a-descriptions-item>
              <a-tag color="warning">{{item.customerType.label}}</a-tag>
              <a-tag color="processing">{{item.businessType.label}}</a-tag>
              <a-tag color="success">{{item.settlementMode.label}}</a-tag>
            </a-descriptions-item>
          </a-descriptions>
        </a-list-item>
      </template>
      <template #loadMore>
        <div :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }">
          <a-spin v-if="loadingMore" />
          <a-button v-else-if="!loadingMore && pagination.total > (pagination.current*pagination.pageSize)" @click="loadData" type="link">点击加载更多</a-button>
          <a-divider v-else>已经到底了</a-divider>
        </div>
      </template>
    </a-list>
  </a-drawer>
  <a-modal v-model:visible="modalVisible" :width="600" :title="form.id ? '修改':'新增'" okText="保存" @ok="save">
    <a-form ref="formRef" :model="form" :rules="rules" :labelCol="{flex:'100px'}" :wrapperCol="{flex:'auto'}">
      <a-form-item label="客户类型" :name="['customerType','value']">
        <a-radio-group name="customerType" v-model:value="form.customerType.value">
          <template v-for="item in customerTypes" :key="item.value">
            <a-radio-button :value="item.value">{{item.label}}</a-radio-button>
          </template>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="业务类型" :name="['businessType','value']" v-if="form.customerType.value !== 1">
        <a-radio-group name="businessType" v-model:value="form.businessType.value">
          <template v-for="item in businessTypes" :key="item.value">
            <a-radio-button :value="item.value">{{item.label}}</a-radio-button>
          </template>
        </a-radio-group>
      </a-form-item>
      <template v-if="form.customerType.value === 2">
        <a-form-item label="企业名称" name="name" :rules="{ required: true, message: '请填写企业名称', trigger: 'change' }">
          <a-select v-model:value="form.name" placeholder="请输入企业名称,大于3个字，才可搜索" :filter-option="false" :show-arrow="false" @search="entpSearch" @select="entpSelect" show-search>
            <a-select-option v-for="item in enterprises" :key="item.creditCode" :value="item.name">
              <span>{{ item.name }}</span>
              <span style="float:right;">{{ item.operName }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
      </template>
      <template v-else>
        <a-form-item label="姓名" name="name" :rules="{ required: true, message: '请输入客户姓名', trigger: 'blur' }">
          <a-input v-model:value="form.name" placeholder="请输入客户姓名" />
        </a-form-item>
      </template>
      <a-form-item :label="form.customerType.value === 2 ? '企业信用代码':'证件号'">
        <a-input v-model:value="form.certificateNumber" />
      </a-form-item>
      <a-form-item label="电话号" name="mobile">
        <a-input v-model:value="form.mobile" />
      </a-form-item>
      <a-form-item label="地址" name="address">
        <a-input v-model:value="form.address" />
      </a-form-item>
      <a-form-item label="结算方式" :name="['settlementMode','value']">
        <a-radio-group name="radioGroup" v-model:value="form.settlementMode.value">
          <template v-for="item in settlementModes" :key="item.value">
            <a-radio :value="item.value">{{item.label}}</a-radio>
          </template>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="是否共享">
        <a-radio-group name="radioGroup" v-model:value="form.share">
          <template v-for="item in shareModes" :key="item.value">
            <a-radio :value="item.value">{{item.label}}</a-radio>
          </template>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="对接人">
        <a-input v-model:value="form.receiver" />
      </a-form-item>
      <a-form-item label="联系电话">
        <a-input v-model:value="form.receiverMobile" />
      </a-form-item>
      <a-form-item label="承运商绑定">
        <a-select v-model:value="form.carrierId" placeholder="搜索并选择承运商" @search="fetchCarrier" :filterOption="false" show-search>
          <template v-if="fetching" #notFoundContent>
            <a-spin size="small" />
          </template>
          <a-select-option v-if="!carriers.length && form.carrierId" :value="form.carrierId">{{ form.carrierName }}</a-select-option>
          <a-select-option v-for="item in carriers" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea v-model:value="form.remark" :rows="4" :maxlength="200" showCount />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { pageAll as page, add as addItem } from '@/api/crm/customer'
import { fuzzy } from '@/api/crm/carrer/carrier'
import { enterprise } from '@/api/system/setting'

import { computed, reactive, toRefs, onMounted, ref } from 'vue'
import { message } from 'ant-design-vue'
import { mobileValidate } from '@/utils/validate'
export default {
  props: {
    visible: { type: Boolean, default: false }
  },
  setup (props, { emit }) {
    const formRef = ref()

    const state = reactive({
      drawerVisible: computed({
        get: () => props.visible,
        set: val => emit('update:visible', val)
      }),
      loadingMore: false,
      pagination: {},
      name: '',
      listData: [],
      modalVisible: false,
      form: {
        customerType: { value: 1 },
        settlementMode: { value: 1 },
        businessType: { value: 1 },
        share: 1
      },
      rules: {
        // certificateNumber: [{ required: true, message: '请填写证件号', trigger: 'blur' }],
        mobile: [{ required: true, message: '请填写电话号码', trigger: 'blur' }, { pattern: mobileValidate, message: '电话号码格式不正确', trigger: 'blur' }],
        address: [{ required: true, message: '请填写客户地址', trigger: 'blur' }],
        businessType: {
          value: [{ required: true, message: '请选择业务类型', trigger: 'change', type: 'number' }]
        },
        customerType: { value: [{ required: true, message: '请选择客户类型', trigger: 'change', type: 'number' }] },
        settlementModes: { value: [{ required: true, message: '请选择结算方式', trigger: 'change', type: 'number' }] },
        receiver: [{ required: true, message: '请填写客户对接人', trigger: 'blur' }],
        receiverMobile: [{ required: true, message: '请填写电话号码', trigger: 'blur' }, { pattern: mobileValidate, message: '电话号码格式不正确', trigger: 'blur' }]
      },
      businessTypes: [
        { label: '个人散单', value: 1 },
        { label: '同行业务', value: 2 },
        { label: '大客户业务', value: 3 },
        { label: '平台业务', value: 4 }
      ],
      customerTypes: [
        { label: '个人', value: 1 },
        { label: '企业', value: 2 }
      ],
      settlementModes: [
        { label: '实时', value: 1 },
        { label: '月结', value: 2 },
        { label: '记账', value: 3 }
      ],
      shareModes: [
        { label: '不共享', value: 0 },
        { label: '共享', value: 1 }
      ],
      enterprises: [],
      fetching: false,
      timeOutId: null,
      carriers: []
    })

    const loadData = async () => {
      state.loadingMore = true
      const { data, data: { records } } = await page({
        name: state.name,
        current: state.pagination.current + 1,
        size: state.pagination.pageSize
      })
      state.listData.push(...records)
      state.pagination.current = data.current
      state.pagination.pageSize = data.size
      state.pagination.total = data.total
      state.loadingMore = false
    }
    onMounted(loadData)

    const checkItem = item => {
      state.drawerVisible = false
      emit('select', item)
    }

    const searchData = () => {
      state.listData = []
      state.pagination.current = 0
      loadData()
    }

    const entpSearch = async val => {
      if (val.length > 3 && state.form.customerType.value === 2) {
        const filterVal = val.replace(new RegExp('[a-z]', 'ig'), '').replace(new RegExp('[0-9]', 'g'), '')
        if (filterVal.length < 4) {
          return
        }
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          enterprise({ name: val }).then((res) => {
            if (res.code === 10000) {
              state.enterprises = res.data
            } else {
              state.enterprises = []
            }
          })
        }, 500)
      } else {
        state.enterprises = []
      }
    }

    const entpSelect = async (val, option) => {
      state.form.certificateNumber = option.key
    }

    const fetchCarrier = value => {
      if (value.length > 1) {
        state.fetching = true
        if (state.timeOutId !== null) {
          clearTimeout(state.timeOutId)
        }
        state.timeOutId = setTimeout(() => {
          fuzzy({ name: value }).then(res => {
            if (res.code === 10000) {
              state.carriers = res.data
            } else state.carriers = []
            state.fetching = false
          })
        }, 1000)
      } else {
        state.carriers = []
      }
    }

    const save = () => {
      formRef.value
        .validate()
        .then(() => {
          if (state.form.customerType.value === 1 && state.form.name.length > 4) {
            message.error('个人类型，名字不能大于4个字，如需请选择企业类型')
            return
          }
          if (state.form.customerType.value === 2 && state.form.name.length <= 4) {
            message.error('企业类型，名字不能小于5个字，如需请选择个人类型')
            return
          }
          state.confirmLoading = true
          addItem({ customer: state.form }).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.modalVisible = false
              state.confirmLoading = false
              state.form = {
                name: undefined,
                customerType: { value: 1 },
                settlementMode: { value: 1 },
                businessType: { value: 1 }
              }
              loadData()
            }
          }).catch(err => {
            console.log(err)
          })
        })
        .catch(error => {
          console.log(error)
        })
    }

    return {
      formRef,
      ...toRefs(state),
      loadData,
      checkItem,
      searchData,
      entpSearch,
      entpSelect,
      fetchCarrier,
      save
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
