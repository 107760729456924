import {
  postBodyRequest,
  getRequest,
  exportRequest
} from '@/utils/axios'
// 所有运输车辆(跟踪)-分页
export function page (params) {
  return postBodyRequest('/trans/transport/truck/page', params)
}
// 所有运输车辆(跟踪)-分页   所有空闲的运输车分页
export function carrierTruckFreePage (params) {
  return postBodyRequest('/carrier/carrier/truck/page/free', params)
}
// 所有小车分页
export function smallVehcileAll (params) {
  return postBodyRequest('/trans/order/vehicle/page/all', params)
}
// 运输任务详情
export function detail (params) {
  return getRequest(`/trans/transport/truck/${params}`)
}
// 运输任务-在途跟踪
export function tracking (transTruckId) {
  return getRequest(`/trans/transport/truck/tracking/${transTruckId}`)
}
// 查看车辆详情
export function vehicleDetail (vehicleId) {
  return postBodyRequest(`/trans/order/vehicle/${vehicleId}`, {})
}
// 查看车辆详情 --图片
export function vehicleDetailPicNum (vehicleId) {
  return postBodyRequest(`/trans/order/vehicle/${vehicleId}/picNum`, {})
}
// 查看车辆GPS详情
export function vehicleGpsDetail (vehicleId) {
  return postBodyRequest(`/trans/order/vehicle/${vehicleId}/gps`, {})
}
// 查看车辆运输详情
export function vehicleTransDetail (vehicleId) {
  return postBodyRequest(`/trans/order/vehicle/${vehicleId}/trans`, {})
}
// 查询未完成的订单的客户车辆信息
export function incomplete (params) {
  return postBodyRequest('/trans/transport/vehicle/incomplete', params)
}
// 查询订单的客户车辆信息
export function incompleteDetail (orderId, params) {
  return postBodyRequest(`/trans/order/${orderId}/vehicles`, params)
}
// 查询新任务的订单的客户车辆信息
export function incompletenew (params) {
  return postBodyRequest('/trans/transport/vehicle/incomplete/new', params)
}
// 车辆交车
export function vehicleDelivery (vehicleId, params) {
  return postBodyRequest(`/trans/transport/vehicle/${vehicleId}/delivery`, params)
}
// 车辆批量交车
export function vehicleBatchDelivery (params) {
  return postBodyRequest('/trans/transport/vehicle/batch/delivery', params)
}

// 车辆交车查看
export function vehicleDeliveryInfo (vehicleId) {
  return getRequest(`/trans/transport/vehicle/${vehicleId}/delivery`)
}
// 运输任务新增
export function addTask (params) {
  return postBodyRequest('/trans/transport/truck/task', params)
}

// 运输安排车  发车
export function transDepart (transTruckId, params) {
  return postBodyRequest(`/trans/transport/truck/${transTruckId}/depart`, params)
}
// 运输安排车-发车(新)
export function depart ( params) {
  return postBodyRequest('/trans/order/vehicle/depart', params)
}
// 运输安排车  装车
export function transPlan (params) {
  return postBodyRequest(`/trans/transport/truck/${params.transTruckId}/plan`, params)
}
// 运输安排车 中途停车
export function transfer (transTruckId, params) {
  return postBodyRequest(`/trans/transport/truck/${transTruckId}/transfer`, params)
}
// 运输安排车 中途上车
export function pitTruck (transTruckId, params) {
  return postBodyRequest(`/trans/transport/truck/${transTruckId}/pitTruck`, params)
}
// 运输安排车  到达
export function transArrive (transTruckId, params) {
  return postBodyRequest(`/trans/transport/truck/${transTruckId}/arrive`, params)
}
// 运输安排车  取消
export function transCancel (transTruckId, params) {
  return postBodyRequest(`/trans/transport/truck/${transTruckId}/cancel`, params)
}

// 查询未完成的订单的客户车辆信息
export function vehicleAll (params) {
  return postBodyRequest('/trans/transport/vehicle/all', params)
}
// 运输任务-位置分页
export function truckGpsPage (transTruckId, params) {
  return postBodyRequest(`/trans/transport/truck/${transTruckId}/gps/page`, params)
}
// 运输任务-新增地理位置
export function truckGpsAdd (params) {
  return postBodyRequest('/trans/transport/truck/gps/add', params)
}

// 通过承运商车辆ID获取最新的运输信息
export function truckByCarrierTruckId (carrierTruckId) {
  return getRequest(`/trans/transport/truck/carrier/${carrierTruckId}`)
}

// 承运商运单发送
export function configPage (transportId, params) {
  return postBodyRequest(`/trans/transport/vehicle/config/${transportId}/page`, params)
}

export function configEdit (params) {
  return postBodyRequest('/trans/transport/vehicle/config/edit', params)
}

export function configData (id, params) {
  return postBodyRequest(`/trans/transport/vehicle/config/${id}`, params)
}
export function getOrderSandPayList (params) {
  return getRequest('/trans/orderpay/getOrderSandPayList/', params)
}

// 获取提车人信息
export function giveData (orderId, params) {
  return getRequest(`/trans/order/getPickerInfo/${orderId}`, params)
}

// 跟踪导出
export function truckPageExport (params, fileName) {
  return exportRequest('/trans/transport/truck/page/export', params, fileName)
}
// 途经点
export function setAssembled (params) {
  return postBodyRequest('/trans/transport/truck/assembled', params)
}

// 预装车
export function advanceLoading (vehicleId, params) {
  return postBodyRequest(`/trans/transport/vehicle/${vehicleId}/preStowage`, params)
}

// 获取预装车信息
export function getAdvance (vehicleId, transportType, params) {
  return getRequest(`/trans/transport/vehicle/${vehicleId}/stowage/${transportType}`, params)
}

// 预装车列表查看
export function advanceListLook (id, params) {
  return getRequest(`/trans/transport/vehicle/stowage/${id}`, params)
}

// 查看车辆详情
export function vehicleSettlement (reconciliationId) {
  return getRequest(`/trans/finance/reconciliation/trans/${reconciliationId}`, {})
}

// 获取城市对应办事处
export function getOffice () {
  return getRequest('/cis/system/officeCity/11')
}

// 获取车辆发运基本信息
export function getTransData (orderVehicleId, transportType) {
  return getRequest(`/trans/order/vehicle/${orderVehicleId}/preTrans/${transportType}`)
}
// 更改GPS类型
export function changeGPStype (params) {
  return postBodyRequest('/carrier/carrier/trunk/gpsedit', params)
}
// 更改是否到达门店
export function changeStore (params) {
  return postBodyRequest('/trans/order/vehicle/stock/change/store', params)
}

// 更改承运商
export function changeCarrier (transTruckId, carrierId, params) {
  return postBodyRequest(`/trans/transport/truck/${transTruckId}/changeCarrier/${carrierId}`, params)
}

// 更改司机
export function changeDriver (transTruckId, driverId, params) {
  return postBodyRequest(`/trans/transport/truck/${transTruckId}/changeDriver/${driverId}`, params)
}

// 获取预装列表最新一条数据
export function getAdvanceNew (orderVehicleId) {
  return getRequest(`/trans/transport/vehicle/stowage/last/${orderVehicleId}`, {})
}
// 大车任务统计信息
export function getBigCarStatistics (orderVehicleId) {
  return postBodyRequest('/trans/transport/truck/count', {})
}

// 车辆-日志-分页
export function schecule (params) {
  return postBodyRequest('/trans/order/vehicle/log/page', params)
}

// 车辆日志
export function getVehiclePage (params) {
  return postBodyRequest('/trans/order/vehicle/log/page', params)
}
// 发送信息(计划添加)
export function sendData (params) {
  return postBodyRequest('/trans/order/vehicle/schedule/add', params)
}

// 订单信息详情
export function orderDetails (orderId) {
  return getRequest(`/trans/order/${orderId}`)
}


// 获取预装车列表数据
export function advanceList (params) {
  return postBodyRequest('/trans/transport/vehicle/stowage/page', params)
}


// 日志聊天 都有谁聊了
export function employeeAll (orderVehicleId) {
  return postBodyRequest(`/trans/order/vehicle/log/${orderVehicleId}/employeeAll`, {})
}

// 车辆运输-任务详情
export function vehicleTasks (orderVehicleId) {
  return postBodyRequest(`/trans/order/vehicle/${orderVehicleId}/tasks`,{})
}
