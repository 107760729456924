<template>
  <div>
    <span v-if="title" class="text">外派</span>
    <div style="width:100%" class="m-t1">
      <div class="titel m-b1">选择派单任务</div>
      <div class="taskBox">
        <div v-for="(item,index) in TaskSegment" :key="item.id" class="flex m-b1 ju-between">
          <div class="flex">
            <div class="taskItem">{{ item.taskName }}：
            </div>
            <div class="taskValue">{{ item.startAddress }}<span v-if="item.endAddress">&nbsp;—&nbsp;{{ item.endAddress }}</span></div>
          </div>
          <a-checkbox @change="checkedChange(item,index)" :checked="item.checked"></a-checkbox>
        </div>
        <div class="flex ju-end m-t2">
          <!-- <a-button type="primary" @click="taskOk">确定</a-button> -->
        </div>
      </div>

      <div v-if="formStatus">
        <!-- <span>成都骥际物流有限公司</span><a @click="carrierChangeShow = true">更换承运商</a> -->
        <div class="titel m-t1">承运商<span class="fz-12" style="color:#b9b7b7;font-weight:400">（固化承运商）</span></div>
        <div class="" style="width:100%">
          <a-select style="width:100%" v-model:value="carrierName" show-search :filterOption="filterOption">
            <template #suffixIcon>
              <div style="width:100px;margin-left:-70px"><a>更换承运商</a></div>
            </template>
            <a-select-option v-for="opt in carrierList" :key="opt.id" :value="opt.id" :lable="opt.carrierName">
              {{ opt.carrierName }}</a-select-option>
          </a-select>
        </div>
        <div class="titel m-t1">发运路线</div>
        <div class="infoBox flex ju-between"><span>{{ checkedValue.startAddress  }} — {{ fromOptions.endAddressText}}</span>
          <div style="white-space: nowrap"><a @click="lineChange" v-if="checkedValue.lastType?.value === 2" class="fz-12">更换路线</a></div>
        </div>
        <div class=" m-t1"><span class="titel m-r2">取车信息</span> </div>
        <div class=" flex ju-between" style="margin-top:5px">
          <a-input style="width:48%" v-model:value="pickUpInfo.pickAddress" placeholder="详细地址"></a-input>
          <a-input style="width:25%;margin:0 10px" v-model:value="pickUpInfo.pickPerson" placeholder="联系人"></a-input>
          <a-input style="width:27%" v-model:value="pickUpInfo.pickerMobile" placeholder="电话"></a-input>
        </div>
        <div class=" m-t1"><span class="titel m-r2">送车信息</span> </div>
        <div class=" flex ju-between" style="margin-top:5px">
          <a-input style="width:48%" v-model:value="carDeliveryInfo.deliveryAddress" placeholder="详细地址"></a-input>
          <a-input style="width:25%;margin:0 10px" v-model:value="carDeliveryInfo.deliveryPerson" placeholder="联系人"></a-input>
          <a-input style="width:27%" placeholder="电话" v-model:value="carDeliveryInfo.deliveryMobile"></a-input>
        </div>

        <div class="titel m-t1 flex ju-between"><span>单台运费</span><a class="fz-12">单台总费用：￥{{ munAll }}</a></div>
        <div class="infoBox" style="padding: 10px ">
          <div class="flex ">
            <div v-for="(item) in taskFormList" :key="item.id" class="m-r2">
              <span>{{item.label}}：<a-input-number :placeholder="item.placeholder" @blur="changeBlur(item)" v-model:value="item.value"></a-input-number></span>
            </div>
          </div>
          <a-divider style="height: 1px; background-color: #eee;margin:10px 0" />
          <div class="">
            <div style="color:red;margin-bottom:5px">共{{selectedList.length}}辆 总运费：￥{{ munAll*selectedList.length }}</div>
            <div class="flex al-center">
              <span class="m-r2">含油卡：<a-input-number v-model:value="fromOptions.oilAmt" placeholder="填写"></a-input-number></span>
              <a-input style="width:40%" v-model:value="costChangeRemark" v-if="showRemake" placeholder="运费修改备注"></a-input>
            </div>
          </div>
        </div>
        <div class="flex m-t1">
          <div class="titel">是否代收运费</div>
          <div class="m-l2">
            <a-radio-group v-model:value="isPeerCollection">
              <a-radio :value="1">是</a-radio>
              <a-radio class="m-l3" :value="0">否</a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="m-t1" v-if="isPeerCollection === 1">到收金额：{{ replaceAmt }}</div>
        <div class="titel m-t1">备注</div>
        <a-input v-model:value="fromOptions.remake" style="width:100%"></a-input>
        <div class="m-t1">
          <a-button type="primary" @click="ConfirmOk">确认派单</a-button>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="lineVisible" title="修改路线" width="22%" @ok="lineOk">
      <div class="flex al-center">
        <div ref="getWidth">{{checkedValue.startAddress}}&nbsp;——&nbsp;</div>
        <div :style="{width:`calc(100% - ${elementWidth})`}"><a-cascader change-on-select style="width: 100%;" @change="cascaderChange" v-model:value="fromOptions.endAddress" :options="$store.state.app.cityDict" value="label" :show-search="{ filter }" placeholder="请选择终点" /></div>
      </div>
      <!-- <div class="m-t1 m-b1">
        <a-input style="width:100%" v-model:value="fromOptions.details" placeholder="详细地址"></a-input>
      </div>
      <div class="chengeStyle">
        <a-form ref="formRef" :model="fromOptions" :rules="rulesOpts">
          <a-form-item label="联系人" name="dockingPerson">
            <a-input v-model:value="fromOptions.dockingPerson" placeholder="填写" />
          </a-form-item>
          <a-form-item label="联系人电话" name="dockingMobile">
            <a-input v-model:value="fromOptions.dockingMobile" placeholder="填写" />
          </a-form-item>
        </a-form>
      </div> -->
    </a-modal>
  </div>
</template>

<script setup>
import { nextTick, reactive, ref, computed, watch } from 'vue'
import { vehicleTaskType, vehicleTaskArrange, peerCollectionFee } from '@/api/transport/transport'
import { carrierSettled, linePriceInquiry } from '@/api/carrier/carrierList'
import { message } from 'ant-design-vue';


const emit = defineEmits(['update:visible', 'status'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: Boolean,
    default: true
  },
})
const selectedList = ref([])
const lineVisible = ref(false)
const getWidth = ref(null)
const formRef = ref(null)
const elementWidth = ref('')
const confirmStatus = ref(false)
const TaskSegment = ref([])
const checkedValue = ref({})
const formStatus = ref(false)
const carrierList = ref([])
const carrierName = ref('')
const remakeAddress = ref([])
const dryAmount = ref(0)
const showRemake = ref(false)
const costChangeRemark = ref('')
const taskIds = ref('')
const isPeerCollection = ref(null)
const replaceAmt = ref(0)
const fromOptions = ref({
  endAddressText: '',
  endAddress: '',
  details: '',
  dockingPerson: '',
  dockingMobile: '',
  oilAmt: null,
  remake: ''
})
const pickUpInfo = reactive({
  pickAddress: '',
  pickPerson: '',
  pickerMobile: ''
})
const carDeliveryInfo = reactive({
  deliveryAddress: '',
  deliveryPerson: '',
  deliveryMobile: ''
})
const rulesOpts = ref({
  dockingPerson: { required: true, message: '请填写联系人', trigger: 'blur' },
  dockingMobile: { required: true, message: '请填写联系人', trigger: 'blur' }
})

const taskFormList = ref([])
const taskForm = ref([
  {
    label: '验',
    value: null,
    type: 0,
    prop: 'testing',
    placeholder: '请填写'
  },
  {
    label: '提',
    value: null,
    type: 1,
    prop: 'carry',
    placeholder: '请填写'
  },
  {
    label: '干',
    value: null,
    type: 2,
    prop: 'dry',
    placeholder: '请填写'
  },
  {
    label: '送',
    value: null,
    type: 3,
    prop: 'give',
    placeholder: '请填写'
  },
])
let checkPriceMsg = {
  startProvince: '',
  startCity: '',
  startArea: '',
  endProvince: '',
  endCity: '',
  endArea: '',
  carrierId: '',
  transport: 1
}


const filterOption = (input, option) => {
  return option.lable.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const ConfirmOk = () => {
  let taskFees = []
  let str = ''
  taskFormList.value.forEach((item) => {
    if (item.value == null) {
      str += ' ' + item.label + ' '
    } else {
      taskFees.push({
        type: item.type,
        amt: item.value
      })
    }
  })
  if (str) {
    message.error(`请填写${str}的费用`)
    return
  }
  if (Number(fromOptions.value.oilAmt) > munAll.value * selectedList.value.length) {
    message.error('油卡金额不能大于运费金额')
    return
  }
  let unloadingAddress = {}
  let fromOption = fromOptions.value
  if (fromOption.endAddress[0]) {
    unloadingAddress = {
      province: fromOption.endAddress[0],
      city: fromOption.endAddress[1],
      area: fromOption.endAddress[2] ? fromOption.endAddress[2] : '',
      details: fromOption.details
    }
  }
  let msg = {
    taskIds: taskIds.value,
    orderVehicleIds: selectedList.value,
    carrierId: carrierName.value,
    unloadingAddress: unloadingAddress,
    taskFees: taskFees,
    totalAmt: munAll.value * selectedList.value.length,
    oilAmt: fromOption.oilAmt,
    remark: fromOption.remake,
    costChangeRemark: costChangeRemark.value,
    isPeerCollection: isPeerCollection.value
  }
  Object.assign(msg, pickUpInfo, carDeliveryInfo)
  vehicleTaskArrange(msg).then(res => {
    if (res.code !== 10000) return
    emit('status')
    message.success('派发成功')
  })
}

const changeBlur = (e) => {
  if (e.prop === 'dry') {
    if (e.value !== dryAmount.value) {
      showRemake.value = true
    } else {
      showRemake.value = false
    }
  }
}

const cascaderChange = (e, v) => {
  remakeAddress.value = v
}

const checkedChange = (e, index) => {
  if (e.checked) return
  e.checked = true
  checkedValue.value = e
  TaskSegment.value.forEach((item, idx) => {
    if (idx !== index) {
      item.checked = false
    }
  })

  taskOk(e)
}

const taskOk = (e) => {
  taskIds.value = e.taskIds
  let arr = e.taskIds.split(',')
  if (arr.includes('1')) {
    pickUpInfo.pickAddress = e.startAddress
    pickUpInfo.pickPerson = e.consignor
    pickUpInfo.pickerMobile = e.consignorMobile
  } else {
    pickUpInfo.pickAddress = ''
    pickUpInfo.pickPerson = ''
    pickUpInfo.pickerMobile = ''
  }
  if (arr.includes('3')) {
    carDeliveryInfo.deliveryAddress = e.endAddress
    carDeliveryInfo.deliveryPerson = e.picker
    carDeliveryInfo.deliveryMobile = e.pickerMobile
  } else {
    carDeliveryInfo.deliveryAddress = ''
    carDeliveryInfo.deliveryPerson = ''
    carDeliveryInfo.deliveryMobile = ''
  }
  let list = []
  arr.forEach(item => {
    list.push(taskForm.value[Number(item)])
  })
  taskFormList.value = list
  checkPriceMsg.startProvince = e.start.province
  checkPriceMsg.startCity = e.start.city
  checkPriceMsg.startArea = e.start.area
  checkPriceMsg.endProvince = e.end.province
  checkPriceMsg.endCity = e.end.city
  checkPriceMsg.endArea = e.end.area
  carrierSettled().then(res => {
    if (res.code !== 10000) return
    fromOptions.value.endAddressText = checkedValue.value.endAddress
    carrierName.value = res.data && res.data.length > 0 ? res.data[0].id : ''
    carrierList.value = res.data
    formStatus.value = true
    checkPriceMsg.carrierId = carrierName.value
    reqLinePriceInquiry(checkPriceMsg)
  })
  peerCollectionFee({
    orderVehicleIds: selectedList.value,
  }).then(res => {
    if (res.code !== 10000) return
    replaceAmt.value = res.data.peerCollectionAmt
  })
}

const reqLinePriceInquiry = (msg) => {
  if (!msg.carrierId) return
  showRemake.value = false
  linePriceInquiry(msg).then(res => {
    if (res.code !== 10000) return
    let num = Number(res.data.transportPrice)
    if (/^[\d.]+$/.test(num)) {
      taskFormList.value.forEach(item => {
        if (item.prop === 'dry') {
          item.value = num
        }
      })
      dryAmount.value = num
    } else {
      taskFormList.value.forEach(item => {
        if (item.prop === 'dry') {
          item.value = null
          item.placeholder = res.data.transportPrice
        }
      })
      dryAmount.value = 0
    }
  })
}

// 路线更改
const lineOk = () => {
  if (!remakeAddress.value[0]?.label) {
    message.error('请选择终点')
    return
  }
  if (!remakeAddress.value[1]?.label) {
    message.error('请选择到达的城市')
    return
  }
  let str = remakeAddress.value.length == 3 ? remakeAddress.value[2]?.label : ''
  fromOptions.value.endAddressText = remakeAddress.value[0].label + remakeAddress.value[1].label + str
  let address = fromOptions.value.endAddress
  checkPriceMsg.endProvince = address[0]
  checkPriceMsg.endCity = address[1]
  checkPriceMsg.endArea = address[2] ? address[2] : ''
  reqLinePriceInquiry(checkPriceMsg)
  lineVisible.value = false
}
const lineChange = () => {
  lineVisible.value = true
  nextTick(() => {
    elementWidth.value = Math.ceil(getWidth.value.getBoundingClientRect().width + 1) + 'px'
  })
}



const reqVehicleTaskType = (orderVehicleIds) => {
  selectedList.value = orderVehicleIds
  vehicleTaskType({
    orderVehicleIds: orderVehicleIds
  }).then(res => {
    if (res.code !== 10000) return
    confirmStatus.value = true
    res.data.forEach(item => {
      item.checked = false
    })
    TaskSegment.value = res.data
  })
}

watch(() => carrierName.value, (val, oldVal) => {
  if (oldVal) {
    checkPriceMsg.carrierId = val
    reqLinePriceInquiry(checkPriceMsg)
  }
})
const munAll = computed(() => {
  let num = 0
  taskFormList.value.forEach(item => {
    num += Number(item.value)
  })
  return num
})
defineExpose({
  reqVehicleTaskType
})

</script>

<style lang="less" scoped>
.titel {
  font-size: 14px;
  font-weight: 600;
  color: #344563;
}

.line {
  width: 10px;
  height: auto;
  margin: 0 10px;
  background: rgb(244, 245, 249);
}
.text {
  font-size: 16px;
  color: #191919;
  font-weight: 700;
}
.taskBox {
  width: 100%;
  padding: 20px 20px 10px 20px;
  border: 1px solid #eee;
}

.taskItem {
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
}
.taskValue {
  font-size: 12px;
  color: #6b778c;
}

.infoBox {
  border: 1px solid #d9d9d9;
  padding: 6px 8px;
  border-radius: 4px;
}
.chengeStyle {
  :deep(.ant-form-item-label) {
    width: 100%;
    text-align: left;
  }
  :deep(.ant-form-item) {
    margin-bottom: 5px;
  }
}

.tableBox {
  max-height: 220px;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: #eee;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(185, 183, 183);
  border-radius: 10px;
}

.disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: no-drop;
}
</style>