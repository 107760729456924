<template>
  <a-modal v-model:visible="windowShow" title="交车" @ok="confirmClick" width="40%" @cancel="closeWindow">
    <a-form-item v-if="form.paidAmt > 0" label="到收款金额">
      <span style="color:red"> {{ form.paidAmt }}</span>
    </a-form-item>
    <a-upload v-model:file-list="imgFileList" accept="image/*" list-type="picture" :remove="handleRemove"
      :before-upload="beforeUpload">
      <a-button :disabled="imgFileList.length > 0">
        <CameraOutlined />
        上传交车条
      </a-button>
    </a-upload>
    <a-row>
      <!-- <a-col :span="3">
        <a-form-item>
          <span><span style="color:red">* </span>收款对象</span>
        </a-form-item>
      </a-col> -->
      <a-col :span="24">
        <a-form-item title="收款对象">
          <a-radio-group v-model:value="form.deliveryType">
            <a-radio :value="1">承运商收款</a-radio>
            <a-radio :value="2">办事处收款</a-radio>
            <a-radio :value="3">业务员收款</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item :wrapperCol="{ flex: 'auto' }" v-if="form.deliveryType === 2">
          <a-select v-model:value="form.officeOrgId" allowClear style=" width: 200px;">
            <a-select-option v-for=" item in $store.state.app.orgTypeList" :key="item.id">{{ item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="5">
        <a-form-item>
          <span><span style="color:red">* </span>是否到达办事处：</span>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item>
          <a-radio-group v-model:value="form.deliveryOfficeOrg">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <span>交车说明：</span>
      <a-textarea v-model:value="form.remark" />
    </a-row>
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { vehicleAll, vehicleDelivery, vehicleDeliveryInfo, vehicleBatchDelivery } from '@/api/transport/truck'
import {uploadVehicle as upload} from '@/api/global'
import { message } from 'ant-design-vue'

export default {
  props: {
    vehicleId: String,
    paidAmt: Number,
    orderSettlement: Number
  },
  setup (props, context) {
    const state = reactive({
      windowShow: false,
      batchVisible: false,
      imgFileList: [],
      datakey: [],
      form: {
        paidAmt: null,
        officeOrgId: null,
        deliveryType: null,
        vehicleId: props.vehicleId,
        remark: '',
        deliveryOfficeOrg: null
      },
      mirrorForm: {}
    })
    onMounted(() => {
      state.mirrorForm = JSON.stringify(state.form)
    })
    const handleRemove = file => {
      const index = state.imgFileList.indexOf(file)
      const newFileList = state.imgFileList.slice()
      newFileList.splice(index, 1)
      state.imgFileList = newFileList
    }
    const beforeUpload = file => {
      state.imgFileList = [...state.imgFileList, file]
      return false
    }
    const getDataProps = () => {
      state.windowShow = true
      state.form.vehicleId = props.vehicleId
      if (props.vehicleId) {
        vehicleDeliveryInfo(state.form.vehicleId).then((res) => {
          if (res.code === 10000) {
            if (res.data === null) {
              state.form.deliveryOfficeOrg = null
              state.form.deliveryType = null
              state.form.remark = ''
              state.imgFileList = []
            } else {
              state.form = res.data
            }
            if (props.orderSettlement) {
              if (props.orderSettlement === 1 | props.orderSettlement === 3) {
                state.form.paidAmt = props.paidAmt
              } else {
                state.form.paidAmt = 0
              }
            }
          }
        })
      }
    }
    const confirmClick = () => {
      if (state.form.deliveryOfficeOrg === undefined || state.form.deliveryOfficeOrg < 0 || state.form.deliveryOfficeOrg > 2) {
        message.success('没选择是否到办事处')
        return false
      }
      if (state.batchVisible === true) {
        if (state.imgFileList.length > 0) {
          const formData = new FormData()
          state.imgFileList.forEach(file => {
            formData.append('file', file.originFileObj)
          })
          upload(formData).then(res => {
            if (res.code === 10000) {
              state.form.fileId = res.data.id
              state.form.orderVehicleId = ''
              state.form.orderVehicleIds = state.datakey
              vehicleBatchDelivery(state.form
              ).then((res) => {
                if (res.code === 10000) {
                  message.success(res.msg)
                  state.form = {
                    paidAmt: 0,
                    officeOrgId: '',
                    vehicleId: '',
                    deliveryOfficeOrg: undefined
                  }
                  context.emit('successUpload', true)
                  closeWindow()
                }
                state.driverLoading = false
              })
            }
          })
          state.visible = false
        } else {
          message.error('收车条必须传')
        }
      } else {
        if (state.imgFileList.length > 0) {
          const formData = new FormData()
          state.imgFileList.forEach(file => {
            formData.append('file', file.originFileObj)
          })
          upload(formData).then(res => {
            if (res.code === 10000) {
              state.form.fileId = res.data.id
              vehicleDelivery(state.form.vehicleId, state.form)
                .then((res) => {
                  if (res.code === 10000) {
                    message.success(res.msg)
                    state.form = {
                      paidAmt: 0,
                      officeOrgId: '',
                      vehicleId: '',
                      deliveryOfficeOrg: undefined
                    }
                    context.emit('successUpload', true)
                    closeWindow()
                  }
                  state.driverLoading = false
                })
            }
          })
        } else {
          message.error('收车条必须传')
        }
      }
    }
    const closeWindow = () => {
      state.windowShow = false
      state.form = JSON.parse(state.mirrorForm)
      state.imgFileList = []
    }
    return {
      ...toRefs(state),
      getDataProps,
      handleRemove,
      beforeUpload,
      confirmClick,
      closeWindow
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>