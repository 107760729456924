<template>
  <a-modal :visible="windowShow" footer="" :maskClosable="false" width="90%"
    :bodyStyle="{ padding: '0', paddingLeft: '10px' }" @cancel="closeWindow">
    <template #title>
      <div class="title-content">
        <slot name="titleContent"></slot>
      </div>
    </template>
    <div class="window-content">
      <div class="left-content">
        <slot name="leftContent"></slot>
      </div>
      <div class="right-content">
        <slot name="rightContent"></slot>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue'


export default {
  props: { windowShow: Boolean,clearData: Function },
  components: {
  },
  setup (props, context) {
    const state = reactive({
      windowShow: true,
      title: '无标题',
      data: null,
    })
    watch(() => props.windowShow, (newVal, oldVal) => {
      state.data = props.data
      state.windowShow = newVal
      // state.transportModelShow = false
      // if (state.windowShow === true) {
      //   setTimeout(() => {
      //     if (state.data.orderId) {
      //       state.transportModelShow = true
      //       operateModelRef.value.getData()
      //     }
      //   }, 1)
      // }
    })

    const closeWindow = () => {
      context.emit('statusShow', false)
      if(props.clearData) props.clearData()
    }
    return {
      ...toRefs(state),
      closeWindow
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>