<template>
<div>
  <AddPlan ref="truckPlan" :windowShow="addPlanShow" 
  @statusShow="closePlanModal" />
</div>
</template>

<script setup>
import { ref } from 'vue'
import AddPlan from '@/views/components/transport/bigVehicleOperate/addPlan.vue'
const emit = defineEmits(['update:addPlanShow'])
const props = defineProps({
  addPlanShow: {
    type: Boolean,
    default: false
  },
 
})
const closePlanModal = (e) => {
   emit('update:addPlanShow', false)
}
</script>

<style lang="less" scoped>

</style>