<template>
  <div style="display:flex;">
    <div class="left">
      <!-- 订单信息 -->
      <div>
        <div class="text-center" style="margin:15px 0;font-weight:bold;">
          <img class="img" src="@/assets/images/carrier/basic.png"><span>基本信息</span>
          <span v-if="vehDetail.isPlaceIn && vehDetail.isPlaceIn.value === 1"
            @click="pickVisible = !pickVisible"><a>取车委托</a></span>
          <span @click="transVisible = !transVisible"><a>运车委托</a></span>
          <span v-if="vehDetail.isTakeOut && vehDetail.isTakeOut.value === 1"
            @click="takeVisible = !takeVisible"><a>送车委托</a></span>
        </div>
        <div class="order-data">
          <a-row :gutter="[24, 8]">
            <a-col :span="12">
              <span class="order-data-title">客户来源</span>
              <span class="font-color2">{{ vehDetail.isInside ? vehDetail.isInside.label : '' }}</span>
            </a-col>
            <a-col :span="12">
              <span class="order-data-title">优惠券（元）</span>
              <span class="font-color2">{{ vehDetail.coupon }}</span>
            </a-col>
            <a-col :span="12">
              <span class="order-data-title">订单号</span>
              <span class="font-color2" style="display: inline-block;width: 50%">
                <a-typography-paragraph :copyable="{ text: vehDetail.orderId }">
                  {{ vehDetail.orderId }}
                </a-typography-paragraph>
              </span>
            </a-col>
            <a-col :span="12">
              <span class="order-data-title">客户名称</span>
              <span class="font-color2">{{ vehDetail.customerName }}</span>
            </a-col>
            <a-col :span="12">
              <span class="order-data-title">车型</span>
              <span class="font-color2">{{ vehDetail.brand + vehDetail.model }}</span>
            </a-col>
            <a-col :span="12">
              <span class="order-data-title">结算方式</span>
              <span class="font-color2">{{ vehDetail.orderSettlement ? vehDetail.orderSettlement.label : '' }}</span>
            </a-col>
            <a-col :span="12">
              <span class="order-data-title">成本报价</span>
              <span class="font-color2">{{ vehDetail.amt }}</span>
            </a-col>
            <a-col :span="12">
              <span class="order-data-title">客户报价</span>
              <span class="font-color2">{{ vehDetail.thirdOfferAmt }}</span>
            </a-col>
            <a-col :span="12">
              <span class="order-data-title">始发地</span>
              <span class="font-color2">{{ vehDetail.startAddress }}</span>
            </a-col>
            <a-col :span="12">
              <span class="order-data-title">目的地</span>
              <span class="font-color2">{{ vehDetail.endAddress }}</span>
            </a-col>
            <!-- <a-col :span="12">
              <span class="order-data-title">小车状态</span>
              <span class="font-color2">{{vehDetail.transStatus?vehDetail.transStatus.label:''}}</span>
            </a-col> -->
            <a-col :span="12" v-if="vehDetail.pickType && vehDetail.isPlaceIn && vehDetail.isPlaceIn.value === 1">
              <span class="order-data-title">取车类型</span>
              <span class="font-color2">{{ vehDetail.pickType ? vehDetail.pickType.label : '' }}<b>{{ vehDetail.transTime
              }}</b></span>
            </a-col>
            <a-col :span="12" v-if="vehDetail.takeType && vehDetail.isTakeOut && vehDetail.isTakeOut.value === 1">
              <span class="order-data-title">送车类型</span>
              <span class="font-color2">{{ vehDetail.takeType ? vehDetail.takeType.label : '' }}</span>
            </a-col>
            <a-col :span="12" class="dfx-jc-sb" v-if="vehDetail.isPlaceIn && vehDetail.isPlaceIn.value === 1">
              <div class="order-data-title">取车地址</div>
              <div class="font-color2" style="flex:1">{{ vehDetail.placeInAddress }}</div>
            </a-col>
            <a-col :span="12" class="dfx-jc-sb" v-if="vehDetail.isTakeOut && vehDetail.isTakeOut.value === 1">
              <div class="order-data-title">送车地址</div>
              <div class="font-color2" style="flex:1">{{ vehDetail.takeOutAddress }}</div>
            </a-col>
            <a-col :span="12" class="dfx-jc-sb" v-if="vehDetail.isPlaceIn">
              <div class="order-data-title">是否取车</div>
              <div class="font-color2" style="flex:1">{{ vehDetail.isPlaceIn.label }}</div>
            </a-col>
            <a-col :span="12" class="dfx-jc-sb" v-if="vehDetail.isTakeOut">
              <div class="order-data-title">是否送车</div>
              <div class="font-color2" style="flex:1">{{ vehDetail.isTakeOut.label }}</div>
            </a-col>
            <a-col :span="12">
              <span class="order-data-title">订单状态</span>
              <span class="font-color2">{{ vehDetail.orderStatus ? vehDetail.orderStatus.label : '' }}</span>
            </a-col>
            <a-col :span="12">
              <span class="order-data-title">结算状态</span>
              <span class="font-color2">{{ vehDetail.settlementStatus ? vehDetail.settlementStatus.label : '' }}</span>
              <a-tag color="red" v-if="vehDetail.settledAmt && vehDetail.settledAmt > 0">已结金额{{ vehDetail.settledAmt
              }}</a-tag>
            </a-col>

            <!--            <a-col :span="12">-->
            <!--              <span class="order-data-title">当前状态</span>-->
            <!--              <span class="font-color2">{{vehDetail.transStatus && vehDetail.transStatus.label}}</span>-->
            <!--            </a-col>-->
            <a-col :span="12">
              <span class="order-data-title">合同总金额</span>
              <span>{{ vehDetail.contractAmt }}</span>
              <span v-show="vehDetail.paymentArrival > 0"> 订单到付：<a-tag color="red">{{ vehDetail.paymentArrival
              }}</a-tag></span>
              <span v-show="vehDetail.refundFee > 0"> 返款：<a-tag color="red">{{ vehDetail.refundFee }}</a-tag></span>
            </a-col>
            <a-col :span="12" v-show="vehDetail.paymentArrival > 0">
              <span class="order-data-title">等通知放车</span>
              <span class="font-color2">是</span>
            </a-col>
            <a-col :span="12">
              <span class="order-data-title">产品名</span>
              <span class="font-color2">{{ vehDetail.productName }}</span>
            </a-col>
            <a-col :span="12" style="display:flex">
              <div class="order-data-title">小车备注</div>
              <div class="font-colorF00" style="flex:1">{{ vehDetail.remark }}</div>
            </a-col>
            <a-col :span="12" style="display:flex">
              <div class="order-data-title">订单备注</div>
              <div class="font-colorF00" style="flex:1">{{ vehDetail.orderRemark }}</div>
            </a-col>
            <a-col :span="12" style="display:flex">
              <div class="order-data-title">当前调度者</div>
              <div class="font-colorF00" style="flex:1">{{ vehDetail.currentShipper }}</div>
            </a-col>
          </a-row>
        </div>
      </div>
      <!-- 运输信息 -->
      <div>
        <div class="text-center" style="margin:15px 0;font-weight:bold">
          <img class="img" src="@/assets/images/carrier/basic.png"><span>运输信息</span>
          <span style="color:#fff;background-color:#0066CC;padding:0 5px;cursor: pointer" @click="orderVehicleImg()">照片{{
            vehDetail.picNum }}</span>
        </div>
        <a-table :columns="columns" :data-source="dataSource" :pagination="false" bordered
          :row-key="record => record.totvId" :scroll="{ x: 1200 }" size="small">
          <template #carrierName="{ record }">
            <div><a @click="carrierEdit(record)">{{ record.carrierName }}</a></div>
          </template>
          <template #transportFee="{ record }">
            <div>
              <a @click="editMoney(record)">{{ record.transportFee }}</a>
            </div>
          </template>
          <template #settlementStatus="{ record }">
            对账：<a @click="settlementData(record.ftsId, record.billStatus)">{{ record.billStatus ? record.billStatus.label
              : '' }}</a>
            <a-divider type="vertical" />
            <span>{{ record.settlementStatus ? record.settlementStatus.label : '' }}</span>
          </template>
          <template #repeal="{ record }">
            <div v-show="record.index + 1 === dataSource.length">
              <a-popconfirm ok-text="是" cancel-text="否" @confirm="repealClick(record)">
                <template #title>
                  <div>
                    <p>确认撤销？</p>
                    <a-textarea v-model:value="repealRemark" placeholder="备注信息" :rows="4" />
                  </div>
                </template>
                <a>撤销到达</a>
              </a-popconfirm>
            </div>
          </template>
        </a-table>
      </div>
      <!-- 安排验车 -->
      <!-- <div v-show="dataSource.orderStatus && dataSource.orderStatus.value !== 0 && dataSource.orderStatus.value !== 6"> -->
      <div v-if="inspectionList.length > 0" style="margin-bottom:15px">
        <div class="font-color2 text-center" style="margin:10px 0;">
          <img class="img" src="@/assets/images/carrier/inspection.png"><span>验车任务</span>
        </div>
        <div style="padding:5px;border:1px solid #ddd" v-for="(item, index) in inspectionList" :key="item.id">
          <div class="task" style="align-items:center">
            <div>
              <span class="NO">任务{{ index + 1 }}</span>
            </div>
            <div class="font-color2" style="display:flex;justify-content: space-between;padding:0 10px;">
              <div>
                <span>{{ item.driverName }}</span>
                <a-divider type="vertical" />
                <span>{{ item.driverTel }}</span>
                <a-divider type="vertical" />
                <span>{{ item.carrierName }}</span>
                <a-divider type="vertical" />
                <span>{{ item.driverType === null ? '' : item.driverType.label }}</span>
              </div>
            </div>
            <div style="flex:1">
              <div style="display:flex;justify-content: flex-end;padding:0 10px;">
                <div class="font-color2">
                  <span class="operation" style="position: relative;">
                    <span class="operation" style="margin-right:15px"><img class="img"
                        src="@/assets/images/carrier/employee.png" @click="look(item)"></span>
                    <img class="img" src="@/assets/images/carrier/QRC.png" @click="handleCope(item)">
                    <div class="QRC-img" v-show="item.QRCshow">
                      <div>
                        <div>车架号：<a-tag color="green" v-for="v in item.orderVehicles" :key="v.vinNo">{{ v.vinNo ||
                          '' }}</a-tag>
                        </div>
                        <div>线路：<span style="color:#f00">{{ vehDetail.startAddress + '——' + vehDetail.endAddress }}</span>
                        </div>
                        <img v-show="!QRCloading" class="img" :src="QRCurl" style="width:100%;height:100%">
                        <div style="text-align: center">
                          <a-spin v-show="QRCloading" size="large" />
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- 预装列表 -->
      <div v-if="propsIndex === 0 && element.preStowageType > 0">
        <AdvanceList :orderVehicleId="orderVehicleId" :element="element" />
      </div>
    </div>
    <div class="right">
      <Record v-if="vehiclesId !== ''" :vehicleList="vehicleList" :vehiclesId="vehiclesId" :orderId="orderId" />
    </div>
  </div>
  <!-- 修改金额 -->
  <a-modal v-model:visible="editShow" title="修改内容" :destroyOnClose="true" @ok="confirmEdit">
    金额：
    <a-input v-model:value="money" type="number" />
    <a-divider />
    备注：
    <a-textarea :maxlength="200" v-model:value="reason" placeholder="请说明您要修改的原因" />
  </a-modal>
  <!-- 结算信息 -->
  <a-modal v-model:visible="settlementShow" :width="400" footer="" :destroyOnClose="true">
    <a-row :gutter="[24, 12]">
      <a-col :span="12">
        <span class="order-data-title">账单号</span>
        <span class="font-color2" style="display: inline-block;width: 50%">
          <a-typography-paragraph :copyable="{ text: settlementDetail.id }">
            {{ settlementDetail.id }}
          </a-typography-paragraph>
        </span>
      </a-col>
      <a-col :span="12">
        <span class="order-data-title">客户名称</span>
        <span class="font-color2">{{ settlementDetail.customerName }}</span>
      </a-col>
      <a-col :span="12">
        <span class="order-data-title">对账人</span>
        <span class="font-color2">{{ settlementDetail.sponsor }}</span>
      </a-col>
      <a-col :span="12">
        <span class="order-data-title">对账时间</span>
        <span class="font-color2">{{ settlementDetail.createTime }}</span>
      </a-col>
    </a-row>
  </a-modal>
  <!-- 新增预装 -->
  <a-modal v-model:visible="addAdvanceShow" :width="1200" footer="" @cancel="loadData" :destroyOnClose="true">
    <template #title>
      <div>{{ element.vinNo }}</div>
    </template>
    <OperationDetail :type="element.position" :vehiclesArr="chooseList" :close="closeOperation" name="preparation" />
  </a-modal>
  <a-modal v-model:visible="pickVisible" title="取车委托" width="350px">
    <div>
      <p class="font-color2">{{ vehDetail.brand + vehDetail.model }} {{ vehDetail.vinNo }}</p>
      <p class="font-color2">收车人姓名：{{ vehDetail.consignor }}</p>
      <p class="font-color2">收车人电话：{{ vehDetail.consignorMobile }}</p>
      <p class="font-color2">收车人身份证：{{ vehDetail.consignorCertificateNumber }}</p>
      <p class="font-color2">{{ vehDetail.pickType ? vehDetail.pickType.label : '' }}{{ vehDetail.placeInAddress }}</p>
    </div>
  </a-modal>
  <a-modal v-model:visible="transVisible" title="运车委托" width="350px">
    <div>
      <p class="font-color2">{{ vehDetail.brand + vehDetail.model }} {{ vehDetail.vinNo }}</p>
      <p class="font-color2">收车人姓名：{{ vehDetail.picker }}</p>
      <p class="font-color2"  v-if="vehDetail.pushTime">收车人电话：{{ vehDetail.pickerMobile }}</p>
      <p class="font-color2">收车人身份证：{{ vehDetail.pickerCertificateNumber }}</p>
      <p class="font-color2"><span v-show="vehDetail.paymentArrival > 0"> 订单到付：<a-tag color="red">{{
        vehDetail.paymentArrival }}</a-tag>等通知放车</span></p>
      <p class="font-color2">{{ vehDetail.endAddress }}</p>
    </div>
  </a-modal>
  <a-modal v-model:visible="takeVisible" title="送车委托" width="350px">
    <div>
      <p class="font-color2">{{ vehDetail.brand + vehDetail.model }} {{ vehDetail.vinNo }}</p>
      <p class="font-color2">收车人姓名：{{ vehDetail.picker }}</p>
      <p class="font-color2"  v-if="vehDetail.pushTime">收车人电话：{{ vehDetail.pickerMobile }}</p>
      <p class="font-color2">收车人身份证：{{ vehDetail.pickerCertificateNumber }}</p>
      <p class="font-color2"><span v-show="vehDetail.paymentArrival > 0"> 订单到付：<a-tag color="red">{{
        vehDetail.paymentArrival }}</a-tag></span>等通知放车</p>
      <p class="font-color2">{{ vehDetail.takeType ? vehDetail.takeType.label : '' }}<span
          v-if="vehDetail.takeOutAddress">送到：{{ vehDetail.takeOutAddress }}</span></p>
    </div>
  </a-modal>
  <!-- 验车凭证 -->
  <a-modal v-model:visible="evidenceImg" title="凭证信息" footer="" width="350px">
    <div class="evidenceDOM">
      <!-- <p><img class="img" src="@/assets/images/log.png" style="width:50%;height:100%" /></p> -->
      <p class="font-color2">司机姓名：{{ editData.driverName }}</p>
      <p class="font-color2"></p>
      <p class="font-color2">联系电话：{{ editData.virtualNumber === '' ? editData.driverTel : editData.virtualNumber }}</p>
      <p class="font-color2"></p>
      <p class="font-color2">身份证号</p>
      <p class="font-color2">{{ editData.driverIdNumber }}</p>
    </div>
    <template #footer>
      {{ '' }}
      <!-- <a-button @click="inspectorStratus=true" v-show="vehDetail.orderStatus && vehDetail.orderStatus.value <= 7">修改验车人</a-button> -->
      <!-- <a-button type="primary" @click="download">下载凭证</a-button> -->
    </template>
  </a-modal>
  <!-- 验车照片 -->
  <InspectionImg ref="imgListRef" :orderVehicleId="orderVehicleId" :loadInspectList="null" />
  <!--修改承运商-->
  <a-modal v-model:visible="carrierShow" title="修改承运商" width="50%" :destroyOnClose="true">
    <div>
      <CarrierList @carrier="carrierChange" />
    </div>
  </a-modal>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from 'vue'
import { vehicleDetail, vehicleSettlement, vehicleDetailPicNum } from '@/api/transport/truck'
import { transEdit } from '@/api/trans/ops/edit'
import { driverPage, QRCurl } from '@/api/transport/inspection'
import Record from '../comon/record.vue'
import OperationDetail from './operationDetail.vue'
import AdvanceList from '../comon/advanceList.vue'
import { message } from 'ant-design-vue'
import InspectionImg from '@/views/components/vehicleUpload/inspectionImg'
import CarrierList from '../comon/carrierList'
export default {
  components: {
    Record,
    InspectionImg,
    OperationDetail,
    AdvanceList,
    CarrierList
  },
  props: { element: Object, orderId: String, orderVehicleId: String, index: Number },
  setup (props) {
    const imgListRef = ref(null)
    const state = reactive({
      propsIndex: '',
      data: '',
      money: '',
      reason: '',
      repealRemark: '',
      orderVehicleId: '',
      vehiclesId: '',
      QRCurl: '',
      QRC: false,
      QRCloading: false,
      evidenceImg: false,
      carrierShow: false,
      pickVisible: false,
      transVisible: false,
      takeVisible: false,
      editShow: false,
      addAdvanceShow: false,
      settlementShow: false,
      inspectionList: [],
      dataSource: [],
      vehicleList: [],
      chooseList: [],
      editData: {},
      record: {},
      settlementDetail: {},
      element: {},
      vehDetail: {},
      columns: [
        {
          title: '大车车牌',
          dataIndex: 'carNo',
          width: '10%'
        },
        {
          title: '运输类型',
          width: '9%',
          dataIndex: 'transportType.label'
        },
        {
          title: '承运商',
          width: '10%',
          slots: {
            customRender: 'carrierName'
          }
        },
        {
          title: '运费',
          dataIndex: 'transportFee',
          width: '8%',
          slots: {
            customRender: 'transportFee'
          }
        },
        {
          title: '运输状态',
          dataIndex: 'truckStatus.label',
          width: '9%'
        },
        {
          title: '运输路线',
          dataIndex: 'lineName',
          width: '9%'
        },
        {
          title: '对账结算',
          dataIndex: 'settlementStatus',
          width: '14%',
          slots: {
            customRender: 'settlementStatus'
          }
        },
        {
          title: '装车时间',
          dataIndex: 'loadingTime',
          width: '10%'
        },
        {
          title: '装车人',
          dataIndex: 'loadingOperator',
          width: '10%'
        },
        {
          title: '发车时间',
          dataIndex: 'departureTime',
          width: '10%'
        },
        {
          title: '发车人',
          dataIndex: 'departure',
          width: '10%'
        },
        {
          title: '卸车时间',
          dataIndex: 'unloadingTime',
          width: '10%'
        },
        {
          title: '卸车人',
          dataIndex: 'unloadingOperator',
          width: '10%'
        },
        {
          title: '卸车地',
          dataIndex: 'endAddress',
          width: '10%'
        },
        {
          title: '操作',
          width: '100px',
          slots: {
            customRender: 'repeal'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 4,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['4', '10', '20', '50'],
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    // 查看验车人
    const look = item => {
      state.evidenceImg = true
      state.editData = item
    }
    // 生成小程序码
    const handleCope = item => {
      if (item.id !== '' && item.id !== undefined && item.id !== null) {
        item.QRCshow = !item.QRCshow
        if (item.QRCshow === true) {
          state.QRC = true
          state.QRCloading = true
          const wXprogramQRcode = { scene: item.id, type: 1 }
          QRCurl(wXprogramQRcode)
            .then(res => {
              if (res.code === 10000) {
                state.QRCurl = 'data:image/png;base64,' + res.data
                state.QRCloading = false
              }
            }).catch(err => console.log(err))
        }
      }
    }
    const loadInspectList = () => {
      driverPage({ current: 1, orderId: state.orderId })
        .then(res => {
          if (res.code === 10000) {
            state.inspectionList = res.data.records
            // state.vehicleList = []
            // if (res.data.records.length > 0) {
            //   res.data.records.forEach((item, index) => {
            //     item.orderVehicles.forEach((item, index) => {
            //       state.vehicleList.push(item)
            //       state.vehiclesId = item.id
            //     })
            //   })
            // }
          }
        }).catch(err => { console.log(err) })
    }
    // 修改金额
    const editMoney = record => {
      state.record = record
      state.editShow = true
    }
    // 确认修改金额
    const confirmEdit = () => {
      if (state.money !== '') {
        transEdit({
          params: {
            transportTruckId: state.record.transTruckId,
            totvId: state.record.totvId,
            changeFee: state.money
          },
          type: {
            value: 1
          },
          reason: state.reason,
          subType: 101
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.editShow = false
            loadData()
          }
        })
      }
    }
    // 新增预装弹出
    const addData = () => {
      if (state.element.preStowageType > 0) {
        state.addAdvanceShow = true
      } else message.info('当前车辆状态不可新增预装')
    }
    const loadData = () => {
      vehicleDetail(state.orderVehicleId)
        .then(res => {
          if (res.code === 10000) {
            state.vehDetail = res.data
            state.dataSource = res.data.details
            state.vehicleList = []
            state.vehiclesId = state.orderVehicleId
            state.dataSource.forEach((item, index) => {
              item.index = index
              state.vehicleList.push({
                id: item.vehicleId,
                vinNo: item.vinNo
              })
            })
          }
        })
    }
    const loadDataPicNum = () => {
      vehicleDetailPicNum(state.orderVehicleId)
        .then(res => {
          if (res.code === 10000 && res.data) {
            state.vehDetail.picNum = res.data
          }
        })
    }
    const settlementData = (id, status) => {
      if (status && status.value === 1) {
        state.settlementShow = true
        state.settlementDetail = {}
        vehicleSettlement(id)
          .then(res => {
            if (res.code === 10000) {
              state.settlementDetail = res.data
            }
          })
      } else {
        message.warning('还未对账')
      }
    }
    const closeOperation = () => {
      state.addAdvanceShow = false
      loadData()
    }
    const orderVehicleImg = () => {
      if (state.vehDetail.orderStatus.value === 0 || state.vehDetail.orderStatus.value === 3) {
        message.warning('当前状态不上传照片，请于验车环节处理')
      } else {
        state.inspectionImg = true
        imgListRef.value.inspectionImg = true
        imgListRef.value.loadData(state.orderVehicleId)
      }
    }
    const carrierEdit = record => {
      state.record = record
      state.carrierShow = true
    }
    // 修改承运商
    const carrierChange = (e) => {
      if (e) {
        transEdit({
          params: {
            transportTruckId: state.record.transTruckId,
            totvId: state.record.totvId,
            carrierId: e.id,
            carrierName: e.carrierName
          },
          type: {
            value: 1
          },
          reason: e.reason,
          subType: 102
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.carrierShow = false
            loadData()
          }
        })
      }
    }
    const repealClick = record => {
      transEdit({
        params: {
          transportTruckId: record.transTruckId,
          totvId: record.totvId
        },
        type: {
          value: 1
        },
        reason: state.repealRemark,
        subType: 104
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
        }
      })
    }
    onMounted(() => {
      if (props.index !== undefined) {
        state.propsIndex = props.index
      }
      if (props.orderId) state.orderId = props.orderId
      if (props.orderVehicleId) state.orderVehicleId = props.orderVehicleId
      if (props.element) {
        state.element = props.element
        state.chooseList[0] = state.element
      }
      loadData()
      loadInspectList()
    })
    return {
      ...toRefs(state),
      imgListRef,
      repealClick,
      carrierEdit,
      carrierChange,
      closeOperation,
      editMoney,
      handleCope,
      look,
      addData,
      confirmEdit,
      loadData,
      orderVehicleImg,
      loadDataPicNum,
      settlementData
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

:deep(.ant-typography) {
  margin-bottom: 0em !important;
}

:deep(.ant-modal-body .ant-col) {
  font-size: 12px !important;
  line-height: 14px !important;
}
</style>
