<template>
<div>
  <LocationQuery></LocationQuery>
</div>
</template>

<script setup>
import { ref } from 'vue'
import LocationQuery from '../../components/LocationQuery'


</script>

<style lang="less" scoped>

</style>