<template>
  <div>
    <div class="carrier font-color2">
      <a-space>
        <a-input v-model:value="carrierName" placeholder="请输入承运商名称" />
        <a-button type="primary" @click="search">搜索</a-button>
      </a-space>
      <div v-show="!loading" class="carrier-data" v-for="(item,index) in carrierData" :key="index">
        <a-row :gutter="[24,6]">
          <a-col :span="20">
            <span>{{item.carrierName}}</span>
          </a-col>
          <a-col :span="4">
            <a-rate style="font-size:14px" :value="item.carrierLevel?item.carrierLevel.value:0" disabled />
          </a-col>
          <a-col :span="12">
            <span>负责人：{{item.charge}}</span>
          </a-col>
          <a-col :span="12">
            <span>负责人电话：{{item.chargeMobile}}</span>
          </a-col>
          <a-col :span="12">
            <span>业务类型：{{item.carrierCapacityType?item.carrierCapacityType.label:''}}</span>
          </a-col>
          <a-col :span="12">
            <span>历史承运次数：{{item.num}}</span>
          </a-col>
          <a-col :span="12">
            <span>承运商地址：{{item.carrierAddress}}</span>
          </a-col>
          <a-col :span="24">
            <a-button type="primary" @click="choose(item)">选择该承运商</a-button>
          </a-col>
        </a-row>
      </div>
      <a-skeleton v-show="loading" :active="true" :paragraph="{ rows: 20 }" />
      <a-pagination size="small" :current="pagination.current" :total="pagination.total" :defaultPageSize="5" :show-total="total => `共 ${pagination.total} 条`" @change="paginationChange" />
    </div>
  </div>
  <a-modal v-model:visible="changeReason" title="修改原因">
    <a-textarea v-model:value="reason" placeholder="原因输入" :rows="4" />
    <template #footer>
      <a-button @click="carrierDetail={},changeReason=false">取消</a-button>
      <a-popconfirm
        :title="prompt"
        ok-text="确认"
        cancel-text="取消"
        @confirm="confirmChange">
      <a-button type="primary">确定</a-button>
      </a-popconfirm>
    </template>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { getPage as carrierPage } from '@/api/carrier/carrierList'

export default {
  setup (props, context) {
    const state = reactive({
      loading: false,
      switchShow: false,
      changeReason: false,
      prompt: '',
      reason: '',
      carrierName: '',
      carrierData: [],
      carrierDetail: {},
      pagination: {
        current: 1,
        pageSize: 4,
        total: 1
      }
    })
    const getCarrier = () => {
      state.loading = true
      carrierPage({
        carrierName: state.carrierName,
        isBlock: 0,
        status: 1,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
        .then(res => {
          if (res.code === 10000) {
            state.pagination.total = res.data.total
            state.carrierData = res.data.records
            state.loading = false
          }
        })
    }
    const search = () => {
      state.switchShow = true
      state.pagination.current = 1
      getCarrier()
    }
    const choose = item => {
      state.carrierDetail = item
      state.prompt = `确认修改至承运商 （${item.carrierName}）`
      state.changeReason = true
    }
    const confirmChange = () => {
      if (state.carrierDetail !== {}) {
        state.carrierDetail.reason = state.reason
        context.emit('carrier', state.carrierDetail)
        state.changeReason = false
        setTimeout(() => {
          state.carrierDetail = {}
        }, 1000)
      }
    }
    const paginationChange = (page) => {
      state.pagination.current = page
      getCarrier()
    }
    onMounted(() => {
      getCarrier()
    })
    return {
      ...toRefs(state),
      search,
      choose,
      confirmChange,
      paginationChange
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
</style>
