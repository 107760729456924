<template>
  <div style="padding:10px">
    <!-- <div style="color:#344563;font-weight:700;">批量操作</div> -->
    <div class="arranged-content">
      <h2>已安排车</h2>
      <a-table :columns="arrangedColumns" size="small" bordered :row-key="record => record.orderVehicleId"
        :data-source="arrangedList" :pagination="false">
        <template #vinNo="{ record }">
          <div>
            <span>{{ record.vinNo }}</span>
          </div>
        </template>
        <template #brand="{ record }">
          <div>
            {{ record.brand }}{{ record.model }}
          </div>
        </template>
        <template #operation="{ record }">
          <div>
            <a v-show="!confirmStatus" @click="deleteData(record)">移除</a>
            <span class="disabled" v-show="confirmStatus">移除</span>
            <!-- &nbsp;<a v-if="!isUpdate" @click="updateImg(record)">图片上传</a> -->
          </div>
        </template>
      </a-table>
    </div>
    <div style="text-align:right;margin-top:10px">
      <a-button v-show="!confirmStatus" :disabled="arrangedList.length <= 0"
        @click="confirmChoose">确认选择</a-button>
      <!-- <a-popconfirm title="确认批量验车完成" ok-text="确定" cancel-text="取消" @confirm="inspectorComplete">
        <a-button v-show="batchType && arrangedList.length > 0" :disabled="arrangedList.length <= 0"
          type="primary">验车完成</a-button>
      </a-popconfirm>
      <a-button v-show="batchType && arrangedList.length <= 0" :disabled="true" type="primary">验车完成</a-button> -->
    </div>
    <div>
      <h2>可安排小车</h2>
      <div style="margin-bottom:10px">
        订单号：<a-input style="width:200px" v-model:value="searchForm.orderId" allowClear/>
        <a-button style="margin-left:10px" type="primary" @click="searchData">搜索</a-button>
      </div>
      <a-table :columns="columns" size="small"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, onSelect: onSelect, getCheckboxProps: checkBox }"
        :row-key="record => record.orderVehicleId" :data-source="listData" bordered :pagination="pagination"
        :loading="loading" @change="handleTableChange">
        <template #model="{ record }">
          <div>
            {{ record.brand }}{{ record.model }}
          </div>
        </template>
        <template #operation="{ record }">
          <div>
            <a v-show="!confirmStatus" @click="loadVeh(record)">选择</a>
            <span v-show="confirmStatus" class="disabled">选择</span>
          </div>
        </template>
      </a-table>
    </div>
  </div>
  <!-- 上传图片 -->
  <InspectionImg ref="imgListRef" :orderVehicleId="record.orderVehicleId" :loadInspectList="[]" />
</template>

<script>
import { reactive, toRefs, ref, watch } from 'vue'
import { getVehicleTaskList, batchInspect } from '@/api/transport/transport'
import { incompletenew } from '@/api/transport/truck'
import { message } from 'ant-design-vue'
import InspectionImg from '@/views/components/vehicleUpload/inspectionImg'

export default {
  props: { show: Boolean, batchType: Number },
  components: {
    InspectionImg
  },
  setup (props, context) {
    const imgListRef = ref(null)
    const state = reactive({
      loading: false,
      btnLoading: false,
      confirmStatus: false,
      batchType: props.batchType,
      searchForm: {
        orderId: ''
      },
      record: {},
      listData: [], // 可安排车
      arrangedList: [], // 已安排车
      selectedRowKeys: [],
      columns: [
        {
          title: '运输类型',
          dataIndex: 'transportType.label',
          width: '11%'
        },
        {
          title: '车牌号',
          width: '11%',
          dataIndex: 'vinNo'
        },
        {
          title: '车型',
          width: '8%',
          slots: {
            customRender: 'model'
          }
        },
        {
          title: '合同号',
          dataIndex: 'orderId'
        },
        {
          title: '起运地',
          dataIndex: 'startAddress',
          width: '20%'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          width: '20%'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      arrangedColumns: [
        {
          title: '车牌号',
          dataIndex: 'vinNo',
          slots: {
            customRender: 'vinNo'
          }
        },
        {
          title: '车型',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '订单号',
          dataIndex: 'orderId'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: null,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
    })
    watch(props.show, (newVal, oldVal) => {
      console.log(111);
      state.pagination.current = 1
      if (props.show === true) loadDataList()
    })
    const loadDataList = () => {
      state.loading = true
      incompletenew({
        ...state.searchForm,
        type: 0,
        inspection: props.batchType || 0,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then((res) => {
        state.listData = res.data.records
        state.pagination.current = res.data.current
        state.pagination.pageSize = res.data.size
        state.pagination.total = res.data.total
      }).finally(() => { state.loading = false })
    }
    const searchData = () => {
      state.pagination.current = 1
      loadDataList()
    }
    const loadVeh = record => {
      let status = null
      if (state.arrangedList.length > 0) status = state.arrangedList.every(item => item.orderVehicleId !== record.orderVehicleId)
      else status = true
      setTimeout(() => {
        if (status === true) {
          if (state.arrangedList.length > 0) {
            if (state.arrangedList[0].orderId === record.orderId) {
              state.arrangedList.push(record)
              state.selectedRowKeys.push(record.orderVehicleId)
            } else message.error('只有相同订单车辆才可以进行批量操作')
          } else {
            state.arrangedList.push(record)
            state.selectedRowKeys.push(record.orderVehicleId)
          }
        } else message.error('该车辆已经装车了')
      }, 10)
    }
    const deleteData = record => {
      let index = state.arrangedList.findIndex(item => item.vinNo == record.vinNo)
      state.arrangedList.splice(index, 1)
      state.selectedRowKeys.splice(index, 1)
    }
    const onSelect = e => {
      console.log(e);
    }
    const onSelectChange = (e, v) => {
      if (e.length > 0) {
        if (v[0].orderId === v[v.length - 1].orderId) {
          state.arrangedList = v
          state.selectedRowKeys = e
        } else message.error('只有相同订单车辆才可以进行批量操作')
      } else {
        state.arrangedList = v
        state.selectedRowKeys = e
      }
    }
    const handleTableChange = page => {
      state.pagination = page
      loadDataList()
    }
    // 验车图片
    const updateImg = (record) => {
      state.record = record
      imgListRef.value.inspectionImg = true
      imgListRef.value.loadData(record.orderVehicleId)
    }
    // 验车完成
    const inspectorComplete = () => {
      state.btnLoading = true
      batchInspect({ orderVehicleIds: state.selectedRowKeys }).then(res => {
        if (res.code === 10000) {
          message.success('验车' + res.msg)
          loadDataList()
          state.arrangedList = []
        }
      }).finally(() => { state.btnLoading = false })
    }
    // 确认选择的小车
    const confirmChoose = async () => {
      state.confirmStatus = true
      loadDataList()
      let num = state.arrangedList[0].transportType.value
      // 判断所选小车是否都为相同任务类型
      // console.log(state.arrangedList.every(item => item.transportType.value === num))
      let orderVehicleIds = []
      let vinNos = []
      let vehicleArr = []
      state.arrangedList.forEach(item => {
        orderVehicleIds.push(item.orderVehicleId)
        vinNos.push(item.vinNo)
        vehicleArr.push(item.brand + item.model + '-' + item.vinNo)
      })
      await context.emit('operateData', {
        orderVehicleIds: orderVehicleIds,
        transportType: state.arrangedList[0].transportType,
        orderId: state.arrangedList[0].orderId,
        vinNo: vinNos,
        vehicleArr: vehicleArr
      })
    }
    const checkBox = record => {
      let obj = {}
      if (state.confirmStatus === true) {
        obj.disabled = true
      } else {
        obj.disabled = false
      }
      return obj
    }
    const clearData = () => {
      state.confirmStatus = false
      state.arrangedList = []
      state.selectedRowKeys = []
      state.listData = []
    }

    return {
      ...toRefs(state),
      imgListRef,
      loadDataList,
      deleteData,
      updateImg,
      inspectorComplete,
      onSelectChange,
      handleTableChange,
      confirmChoose,
      loadVeh,
      checkBox,
      clearData,
      searchData
    }
  }
}
</script>

<style lang="less" scoped>
.arranged-content {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: #eee;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(185, 183, 183);
  border-radius: 10px;
}

.disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: no-drop;
}
</style>
