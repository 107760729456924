<template>
  <div>
    <div class="font-color2 detail-title" style="margin:15px 0;">
      <img class="img" src="@/assets/images/carrier/accept.png">
      &emsp;<span style="font-weight:bold;color:#344563">选择承运商</span>
      <a @click="carrierShow=!carrierShow">&emsp;{{carrierStatus.value===2?'已选择/切换':'选择'}}</a>
      <span v-if="carrierName" style="margin-left:15px;color:#6B778C">{{carrierName}}</span>
    </div>
    <div class="carrier font-color2" v-show="carrierStatus.value===1">
      <a-space>
        <a-input v-model:value="carrierName" placeholder="请输入承运商名称" />
        <a-button type="primary" @click="search">搜索</a-button>
      </a-space>
      <div v-show="!switchShow" class="carrier-data" v-for="(item,index) in carrierList" :key="index">
        <a-row :gutter="[24,6]">
          <a-col :span="19">
            <span>【推荐】{{item.carrierName}}</span>
          </a-col>
          <a-col :span="5">
            <a-rate style="font-size:14px" :value="item.carrierLevel?item.carrierLevel.value:0" disabled />
          </a-col>
          <a-col :span="12">
            <span>负责人：{{item.charge}}</span>
          </a-col>
          <a-col :span="12">
            <span>负责人电话：{{item.chargeMobile}}</span>
          </a-col>
          <a-col :span="12">
            <span>业务类型：{{item.carrierCapacityType?item.carrierCapacityType.label:''}}</span>
          </a-col>
          <a-col :span="12">
            <span>历史承运次数：{{item.num}}</span>
          </a-col>
          <a-col :span="12">
            <span>承运商地址：{{item.carrierAddress}}</span>
          </a-col>
          <a-col :span="24" v-show="carrierStatus.value===1">
            <a-button type="primary" @click="choose(item)">选择该承运商</a-button>
          </a-col>
          <a-col :span="24" v-show="carrierStatus.value===2">
            <a @click="carrierStatus.value=1">更改承运商</a>&emsp;
            <a @click="vehicleArrival=!vehicleArrival">车辆到达详情</a>
          </a-col>
        </a-row>
      </div>
      <div v-show="switchShow && !loading" class="carrier-data" v-for="(item,index) in carrierData" :key="index">
        <a-row :gutter="[24,6]">
          <a-col :span="20">
            <span>{{item.carrierName}}</span>
          </a-col>
          <a-col :span="4">
            <a-rate style="font-size:14px" :value="item.carrierLevel?item.carrierLevel.value:0" disabled />
          </a-col>
          <a-col :span="12">
            <span>负责人：{{item.charge}}</span>
          </a-col>
          <a-col :span="12">
            <span>负责人电话：{{item.chargeMobile}}</span>
          </a-col>
          <a-col :span="12">
            <span>业务类型：{{item.carrierCapacityType?item.carrierCapacityType.label:''}}</span>
          </a-col>
          <a-col :span="12">
            <span>历史承运次数：{{item.num}}</span>
          </a-col>
          <a-col :span="12">
            <span>承运商地址：{{item.carrierAddress}}</span>
          </a-col>
          <a-col :span="24" v-show="carrierStatus.value===1">
            <a-button type="primary" @click="choose(item)">选择该承运商</a-button>
          </a-col>
          <a-col :span="24" v-show="carrierStatus.value===2">
            <a @click="carrierStatus.value=1">更改承运商</a>&emsp;
<!--            <a @click="vehicleArrival=!vehicleArrival">车辆到达详情</a>-->
          </a-col>
        </a-row>
      </div>
      <a-skeleton v-show="loading" :active="true" :paragraph="{ rows: 20 }" />
      <a-pagination size="small" :current="pagination.current" :total="pagination.total" :defaultPageSize="5" :show-total="total => `共 ${pagination.total} 条`" @change="paginationChange" />
    </div>
    <!-- 已选择 -->
    <div class="carrier-data" v-show="carrierShow" v-if="carrierData.length>0">
      <a-row :gutter="[24,6]">
        <a-col :span="19">
          <span>{{chooseCarrierData.carrierName}}</span>
        </a-col>
        <a-col :span="5">
          <a-rate style="font-size:14px" :value="chooseCarrierData.carrierLevel?chooseCarrierData.carrierLevel.value:0" disabled />
        </a-col>
        <a-col :span="12">
          <span>负责人：{{chooseCarrierData.charge}}</span>
        </a-col>
        <a-col :span="12">
          <span>负责人电话：{{chooseCarrierData.chargeMobile}}</span>
        </a-col>
        <a-col :span="12">
          <span>业务类型：{{chooseCarrierData.carrierCapacityType?chooseCarrierData.carrierCapacityType.label:''}}</span>
        </a-col>
        <a-col :span="12">
          <span>历史承运次数：{{chooseCarrierData.num}}</span>
        </a-col>
        <a-col :span="12">
          <span>承运商地址：{{chooseCarrierData.carrierAddress}}</span>
        </a-col>
        <a-col :span="24">
          <a @click="carrierStatus.value=1">更改承运商</a>&emsp;
          <a @click="vehicleArrival=true">车辆到达详情</a>
        </a-col>
      </a-row>
      <div class="vehicle-arrival" v-show="vehicleArrival">
        <p class="arrival-title">
          <span>查看车辆到达详情</span>
          <a style="color:#172B4D;font-size:20px" @click="vehicleArrival=false">
            <CloseOutlined />
          </a>
        </p>
        <div>
          <div class="arrival-content">
            <div class="arrival-label">预计发车时间</div>
            <div class="font-color2" style="flex:1">2022-06-11</div>
          </div>
          <div class="arrival-content">
            <div class="arrival-label">到达门店</div>
            <div class="font-color2" style="flex:1">成都办事处</div>
          </div>
          <div class="arrival-content">
            <div class="arrival-label">地址</div>
            <div class="font-color2" style="flex:1">成都市龙泉驿区大面镇车城大道66号</div>
          </div>
          <div class="arrival-content">
            <div class="arrival-label">备注</div>
            <div class="font-color2" style="flex:1">备注信息</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
import { CloseOutlined } from '@ant-design/icons-vue'
import { getPage as carrierPage, detailData } from '@/api/carrier/carrierList'

export default {
  props: { carrierId: String, record: Object, carrierList: Array },
  components: {
    CloseOutlined
  },
  setup (props, context) {
    const state = reactive({
      carrierName: '',
      carrierData: [],
      addressList: [],
      storeList: [],
      chooseCarrierData: {},
      loading: false,
      switchShow: false,
      carrierShow: false,
      chooseMap: false,
      vehicleArrival: false,
      chooseShow: false,
      chooseCarrier: {
        time: '',
        boolean: { label: '', value: '' }
      },
      carrierStatus: { label: '未选择', value: 1 },
      pagination: {
        current: 1,
        pageSize: 4,
        total: 1
      }
    })
    const search = () => {
      state.switchShow = true
      state.pagination.current = 1
      getCarrier()
    }
    const paginationChange = (page) => {
      state.pagination.current = page
      getCarrier()
    }
    const choose = item => {
      state.chooseShow = true
      state.carrierId = item.id
      state.carrierData = []
      state.carrierData[0] = item
      state.carrierName = item.carrierName
      if (props.record === undefined) {
        state.carrierStatus = { label: '已选择', value: 2 }
      }
      state.carrierStatus.value = 2
      context.emit('confirm', state.carrierData[0])
      state.chooseCarrierData = state.carrierData[0]
    }
    const getCarrier = () => {
      state.loading = true
      carrierPage({
        carrierName: state.carrierName,
        isBlock: 0,
        status: 1,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
        .then(res => {
          if (res.code === 10000) {
            state.pagination.total = res.data.total
            state.carrierData = res.data.records
            state.loading = false
          }
        })
    }
    const loadData = (id) => {
      detailData(id, {})
        .then(res => {
          if (res.code === 10000) {
            state.chooseShow = true
            state.carrierId = res.data.id
            state.carrierData = []
            state.carrierData[0] = res.data
            state.carrierName = res.data.name
            if (props.record === undefined) {
              state.carrierStatus = { label: '已选择', value: 2 }
            }
            state.carrierStatus.value = 2
          }
        })
    }
    watch(() => props.carrierId, (newVal, oldVal) => {
      if (newVal !== undefined) state.carrierId = newVal
      loadData(newVal)
    }, { deep: true })
    // onMounted(() => {
    //   if (props.carrierList.length <= 0) search()
    // })

    return {
      ...toRefs(state),
      choose,
      search,
      paginationChange
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
</style>
