<template>
  <div>
    <div class="data">
      <div>线路名称：{{lineName}}</div>
      <div style="text-align:center">车辆台数：{{vinNos.length}}</div>
      <div>车牌号/车架号：<span style="color:#FE6E09">{{vinNos.join('、')}}</span></div>
      <div style="text-align:center">
        <a-button type="primary" size="small" @click="handleCope()">生成小程序码</a-button>
      </div>
    </div>
    <a-divider />
    <div>
      <p>请填写取送车信息</p>
      <a-table :columns="columns" :row-key="record => record.vinNo" :data-source="contractorData" :bordered="false" :pagination="contractorPag" :loading="loading" @change="contractorChange">
        <template #options="{ record }">
          <div class="editable-row-operations">
            <span>
              <a v-show="record.isPeerConfig.value != 1" @click="edit(record)">取送车辆信息配置</a>
              <span v-show="record.isPeerConfig.value != 0">已配置 <a @click="edit(record)">修改</a></span>
            </span>
          </div>
        </template>
        <template #substitute="{ record }">
          <div class="editable-row-operations">
            <a-switch checked-children="是" un-checked-children="否" v-model:checked="record.status" @change="substituteChange(record)" />
          </div>
        </template>
      </a-table>
    </div>
  </div>
  <!-- 小程序码 -->
  <a-modal v-model:visible="QRC" title="二维码" ok-text="确认" @ok="QRC=false">
    <div class="QRC">
      <div>
        <a-spin v-show="QRCloading" tip="Loading..." />
      </div>
      <div v-show="!QRCloading" style="text-align:center">
        <div>车架号：<a-tag color="green" v-for="(item,index) in vinNos" :key="index">{{item}}</a-tag>
        </div>
        <span>线路：{{lineNameData}}</span>
      </div>
      <img v-show="!QRCloading" :src="QRCurl" style="width:50%" />
    </div>
  </a-modal>
  <!-- 取送车信息配置 -->
  <a-modal v-model:visible="carDataStatus" title="取送车信息配置" @ok="carDataHand" @cancel="limit = '#1890ff'" :width="800">
    <div class="car-data">
      <div>
        <h2 style="margin-bottom:15px">交车人信息<a :style="{fontSize:'14px',color:limit}" @click="getGiveData">同步交车人信息</a></h2>
        <a-form :model="consignorForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" :name="consignorForm" :hideRequiredMark="true">
          <a-form-item label="姓名">
            <a-input v-model:value="consignorForm.consignor" placeholder="请输入取车人姓名" />
          </a-form-item>
          <a-form-item label="电话" name="mobile">
            <a-input v-model:value="consignorForm.mobile" placeholder="请输入电话号码" />
          </a-form-item>
          <a-form-item label="身份证号" name="certificateNumber">
            <a-input v-model:value="consignorForm.certificateNumber" placeholder="请输入身份证号" />
          </a-form-item>
          <a-form-item label="送车地址">
            <a-input v-model:value="consignorForm.consignorDelivery" @focus="mapShow = true;nums = 0" style="height: 38px;" placeholder="请选择送车地址" />
          </a-form-item>
        </a-form>
      </div>
      <div>
        <h2 style="margin-bottom:15px">取车人信息</h2>
        <a-form :model="pickerForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" :name="pickerForm" :hideRequiredMark="true">
          <a-form-item label="姓名">
            <a-input v-model:value="pickerForm.picker" placeholder="请输入交车人姓名" />
          </a-form-item>
          <a-form-item label="电话" name="mobile">
            <a-input v-model:value="pickerForm.mobile" placeholder="请输入电话号码" />
          </a-form-item>
          <a-form-item label="身份证号" name="certificateNumber">
            <a-input v-model:value="pickerForm.certificateNumber" placeholder="请输入身份证号" />
          </a-form-item>
          <a-form-item label="取车地址">
            <a-input v-model:value="pickerForm.pickerDelivery" @focus="mapShow = true;nums = 1" style="height: 38px;" placeholder="请选择取车地址" />
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div v-show="mapShow" class="map">
      <OptionalMap @onData="onData" :num="nums" />
    </div>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { configPage, configEdit, configData, giveData } from '@/api/transport/truck'
import { QRCurl } from '@/api/transport/inspection'
import OptionalMap from '@/views/components/optionalMap2'
import { message } from 'ant-design-vue'

export default {
  props: {
    transTruckId: String,
    lineName: String
  },
  components: {
    OptionalMap
  },
  setup (props) {
    const state = reactive({
      nums: 0,
      QRCurl: '',
      lineName: '',
      transTruckId: '',
      lineNameData: '',
      limit: '#1890ff',
      QRC: false,
      QRCloading: false,
      mapShow: false,
      carDataStatus: false,
      idObject: {},
      vinNos: [],
      contractorData: [],
      translistData: [],
      labelCol: {
        span: 8
      },
      wrapperCol: {
        span: 20
      },
      consignorForm: { // 交车人信息
        consignor: '',
        consignorMobile: '',
        consignorCertificateNumber: '',
        consignorDeliveryGps: '',
        consignorDelivery: ''
      },
      pickerForm: { // 取车人信息
        picker: '',
        pickerMobile: '',
        pickerCertificateNumber: '',
        pickerDeliveryGps: '', // 经纬度
        pickerDelivery: '' // 中文地名
      },
      columns: [
        {
          title: '车牌号',
          dataIndex: 'vinNo',
          slots: {
            customRender: 'vinNo'
          }
        },
        {
          title: '取送车信息',
          width: '40%',
          dataIndex: 'options',
          slots: {
            customRender: 'options'
          }
        },
        {
          title: '是否代收运费',
          dataIndex: 'substitute',
          slots: {
            customRender: 'substitute'
          }
        }
      ],
      contractorPag: {
        current: 1,
        pageSize: 4,
        pageSizeOptions: ['4', '8', '10'],
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    // 生成小程序码
    const handleCope = () => {
      const sss = state.contractorData.every(item => item.id !== null)
      if (sss) {
        state.QRC = true
        state.QRCloading = true
        const wXprogramQRcode = { scene: state.transTruckId, type: 3 }
        setTimeout(() => {
          QRCurl(wXprogramQRcode)
            .then(res => {
              if (res.code === 10000) {
                state.QRCurl = 'data:image/png;base64,' + res.data
                state.QRCloading = false
              }
            })
            .catch(err => console.log(err))
        }, 3000)
      } else message.error('请先将所有车辆配置人员信息')
    }
    const edit = (record) => {
      state.carDataStatus = true
      state.idObject.id = record.id
      state.idObject.orderId = record.orderId
      state.idObject.orderVehicleId = record.orderVehicleId
      state.idObject.transportId = record.transportTruckId
      state.idObject.transportOrderTruckVehicleId = record.transportOrderTruckVehicleId
      if (record.id !== null) {
        if (record.isPeerCollection.value === 0 && record.id === null) {
          state.carDataStatus = true
        }
        configData(state.idObject.id)
          .then(res => {
            if (res.code === 10000) {
              state.consignorForm.consignor = res.data.consignor
              state.consignorForm.consignorCertificateNumber = res.data.consignorCertificateNumber
              state.consignorForm.certificateNumber = res.data.consignorCertificateNumber
              state.consignorForm.consignorDelivery = res.data.consignorDelivery
              state.consignorForm.consignorMobile = res.data.consignorMobile
              state.consignorForm.mobile = res.data.consignorMobile
              state.consignorForm.consignorDeliveryGps = res.data.consignorDeliveryGps
              // -------------------------------------------------------------------------------------
              state.pickerForm.picker = res.data.picker
              state.pickerForm.pickerCertificateNumber = res.data.pickerCertificateNumber
              state.pickerForm.certificateNumber = res.data.pickerCertificateNumber
              state.pickerForm.pickerDelivery = res.data.pickerDelivery
              state.pickerForm.pickerDeliveryGps = res.data.pickerDeliveryGps
              state.pickerForm.mobile = res.data.pickerMobile
            }
          }).catch(err => console.log(err))
      } else {
        state.consignorForm = {}
        state.pickerForm = {}
        record.status = false
        if (record.isPeerCollection.value === 0) {
          state.carDataStatus = true
        }
      }
    }
    const substituteChange = record => {
      state.idObject.id = record.id
      state.idObject.orderId = record.orderId
      state.idObject.orderVehicleId = record.orderVehicleId
      state.idObject.transportId = record.transportTruckId
      state.idObject.transportOrderTruckVehicleId = record.transportOrderTruckVehicleId
      if (record.id !== null) {
        if (record.status === true) record.isPeerCollection.value = 1
        else record.isPeerCollection.value = 0
        configEdit({
          transportOrderTruckVehicleId: state.idObject.transportOrderTruckVehicleId,
          isPeerCollection: { value: record.isPeerCollection.value }
        }).then(res => {
          if (res.code === 10000) message.success(res.msg)
        }).catch(err => console.log(err))
      } else {
        record.status = false
        if (record.isPeerCollection.value === 0 && record.id === null) {
          state.consignorForm = {}
          state.pickerForm = {}
          state.carDataStatus = true
        }
      }
    }
    // 同步取车人信息
    const getGiveData = () => {
      if (state.limit !== '#ccc') {
        giveData(state.idObject.orderId)
          .then(res => {
            if (res.code === 10000) {
              state.limit = '#ccc'
              state.consignorForm.consignor = res.data.name
              state.consignorForm.mobile = res.data.mobile
              state.consignorForm.certificateNumber = res.data.certificateNumber
            }
          }).catch(err => console.log(err))
      }
    }
    const carDataHand = e => {
      // 赋值-删值
      state.consignorForm.consignorMobile = state.consignorForm.mobile
      state.consignorForm.consignorCertificateNumber = state.consignorForm.certificateNumber
      state.pickerForm.pickerMobile = state.pickerForm.mobile
      state.pickerForm.pickerCertificateNumber = state.pickerForm.certificateNumber
      delete state.pickerForm.certificateNumber
      delete state.pickerForm.mobile
      delete state.consignorForm.certificateNumber
      delete state.consignorForm.mobile

      let formData = {}
      formData = Object.assign({ ...state.consignorForm, ...state.pickerForm, ...state.idObject }) // state.contractorData
      formData.isPeerCollection = { value: 1 }
      formData.isPeerConfig = { value: 1 }
      configEdit(formData)
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
          }
        }).catch(err => console.log(err))
      state.carDataStatus = false
      // 数据初始化
      state.consignorForm = {}
      state.pickerForm = {}
      state.mapShow = false
    }
    // 接收子组件传值
    const onData = data => {
      if (state.nums === 0) {
        if (data.surroundingPois.length === 0) state.consignorForm.consignorDelivery = data.address
        else state.consignorForm.consignorDelivery = data.address + data.surroundingPois[0].title
        state.consignorForm.consignorDeliveryGps = String(data.point.lng) + ',' + String(data.point.lat)
      } else {
        if (data.surroundingPois.length === 0) state.pickerForm.pickerDelivery = data.address
        else state.pickerForm.pickerDelivery = data.address + data.surroundingPois[0].title
        state.pickerForm.pickerDeliveryGps = String(data.point.lng) + ',' + String(data.point.lat)
      }
    }
    const loadData = () => {
      configPage(state.transTruckId, {
        current: state.contractorPag.current,
        size: state.contractorPag.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.vinNos = []
          state.contractorData = res.data.records
          state.contractorPag.current = res.data.current
          state.contractorPag.pageSize = res.data.size
          state.contractorPag.total = res.data.total
          state.contractorData.forEach(item => {
            item.status = item.isPeerCollection.value === 1
            state.vinNos.push(item.vinNo)
          })
        }
      })
    }
    onMounted(() => {
      if (props.transTruckId) state.transTruckId = props.transTruckId
      if (props.lineName) state.lineName = props.lineName
      loadData()
    })

    return {
      ...toRefs(state),
      edit,
      onData,
      handleCope,
      carDataHand,
      getGiveData,
      substituteChange
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
.map {
  width: 100%;
  height: 300px;
}
.data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  div {
    width: 50%;
  }
}
.QRC {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.car-data {
  display: flex;
  justify-content: space-around;
}
</style>
