<template>
  <div class="dfx" style="padding-top:15px">
    <div style="flex:1">
      <div style="padding-right:5px;display:flex;flex-direction: column">
        <div>
          <span style="font-weight: bold;font-size: 14px;color: #344563;margin-bottom: 8px;">车辆日志</span>
          <span v-if="vehicleList.length === 1" style="font-size: 14px;margin-left: 8px;">{{vehicleList[0].vinNo}}</span>
          <a-select v-if="vehicleList.length > 1" v-model:value="ovId" style="width: 120px;margin-left: 15px" @change="vehicleChange">
            <a-select-option v-for="item in vehicleList" :key="item.id" :value="item.id">{{item.vinNo}}</a-select-option>
          </a-select>
          <div>
            <a-space>
              <div>
                <a-input v-model:value="searchForm.content" style="width:120px;" placeholder="内容查询" allowClear />
                <a-select style="width: 120px; margin-left: 8px;" :options="$store.state.enumAll.contentType" placeholder="请选择" @change="contentTypeChange" allowClear />
                <a-button type="primary" @click="loadData">搜索</a-button>
              </div>
            </a-space>
          </div>
        </div>
        <div style="height:500px;padding-right:10px;overflow-y: scroll;margin-top: 10px;">
          <div class="font-color2" v-for="(item,index) in recordList" :key="index">
            <div class="text-style"><span style="color: #B5BBC5;">[{{item.type?item.type.label:''}}] {{item.operation}}：</span><span> {{item.createTime}}</span></div>
            <div style="word-break:break-all">{{item.content}}</div>
            <a-divider style="margin:5px 0px" />
          </div>
          <div v-show="loading" style="text-align:center">
            <a-spin tip="加载中....." size="small" />
          </div>
          <div v-show="!loading&&reloadStatus" style="text-align:end"><a @click="reloadMore">加载更多...</a></div>
          <div v-show="promptShow" class="reload-data"><span>已加载全部信息</span></div>
        </div>
      </div>
    </div>
    <div>
      <a-divider style="margin-bottom:10px;margin-top:0" />
      <Send :employeeList="employeeList" :empId="empId" :vehicleId="ovId" :orderId="orderId" @ok="loadData()" />
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch } from 'vue'
import { getVehiclePage, getVehicleSendInfo } from '@/api/global'
import { schecule, sendData } from '@/api/transport/truck'
import Send from '@/views/components/sendInformation'
import { message } from 'ant-design-vue'

export default {
  components: {
    Send
  },
  props: { orderId: String, vehicleList: Array, vehiclesId: String, employeeRealList: Array },
  setup (props) {
    const state = reactive({
      messageShow: false,
      promptShow: false,
      reloadStatus: true,
      sendLoad: false,
      loading: false,
      size: 10,
      searchForm: { content: '' },
      vehicleList: [], // 小车明细
      ovId: '', // 默认第一个小车ID
      recordList: [],
      employeeList: [],
      records: [],
      orderId: '',
      planContent: '',
      empId: '',
      name: ''
    })
    const contentTypeChange = (e, v) => {
      state.searchForm.type = v
    }
    const loadData = () => {
      state.loading = true
      getVehiclePage({
        ...state.searchForm,
        current: 1,
        size: state.size,
        orderVehicleId: state.ovId
      }).then(res => {
        if (res.code === 10000) {
          state.recordList = res.data.records
          state.loading = false
          if (state.recordList.length === res.data.total) {
            state.promptShow = true
            state.reloadStatus = false
            setTimeout(() => {
              state.promptShow = false
            }, 2000)
          }
          // res.data.records.forEach(item => {
          //   state.employeeList.push({ id: item.operationId, value: item.operation })
          // })
          // state.empId = res.data.salesmanId
          // state.employeeList = []
          getVehicleSendInfo(state.ovId).then(res => {
            if (res.code === 10000) {
              state.empId = res.data.salesmanId
              state.employeeList = []
              state.employeeList.push({ id: res.data.salesmanId, value: res.data.salesman })
            }
          }).catch(err => console.log(err))
        }
      }).catch(err => console.log(err))
    }
    // 加载更多
    const reloadMore = () => {
      if (state.reloadStatus === true) {
        state.size += 10
      }
      loadData()
    }
    // 获取任务安排数据
    const setPlanData = () => {
      schecule({
        current: 1,
        size: state.size,
        orderVehicleId: state.ovId
      }).then(res => {
        if (res.code === 10000) {
          state.records = res.data.records
        }
      }).catch(err => { console.log(err) })
    }
    const vehicleChange = (e, v) => {
      loadData()
    }

    const uniq = (array) => {
      const temp = []
      const index = []
      const l = array.length
      for (let i = 0; i < l; i++) {
        for (let j = i + 1; j < l; j++) {
          if (array[i].id === array[j].id) {
            i++
            j = i
          }
        }
        temp.push(array[i])
        index.push(i)
      }
      return temp
    }

    watch(() => props.vehicleList, (newVal, oldVal) => {
      if (newVal !== undefined) state.vehicleList = newVal
    }, { deep: true })

    watch(() => props.vehiclesId, (newVal, oldVal) => {
      if (newVal !== undefined) state.ovId = newVal
      // loadData()
    }, { deep: true })
    onMounted(() => {
      if (props.vehicleList !== undefined) state.vehicleList = props.vehicleList
      if (props.orderId !== undefined) state.orderId = props.orderId
      if (props.vehiclesId !== undefined) {
        state.ovId = props.vehiclesId
        setTimeout(() => {
          loadData()
        }, 100)
      }
    })

    // watch(() => props.employeeRealList, (newVal, oldVal) => {
    //   state.employeeList = newVal
    //   state.empId = state.employeeList[0].id
    //   loadData()
    // }, { deep: true })
    const send = () => {
      state.sendLoad = true
      sendData({
        content: state.planContent,
        orderId: props.orderId,
        orderVehicleId: state.ovId
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.sendLoad = false
          // setPlanData()
        }
      }).catch(err => { console.log(err) })
    }
    return {
      ...toRefs(state),
      contentTypeChange,
      vehicleChange,
      setPlanData,
      reloadMore,
      loadData,
      uniq,
      send
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
.dfx {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.reload-data {
  color: #52c41a;
  text-align: center;
  transition: all 0.5s;
}
</style>
