<template>
  <div style="flex:1;padding: 10px 15px;width:100%;padding-top:0;">
    <div v-show="operateDetail.executeStatus?.value !== 9">
      <div class="operate-div" style="width: 100%;" v-show="operateDetail.executeType?.value === 0">
        <div class="operate-title" style="display:flex;justify-content:space-between">
          <span>安排验车</span>
          <!-- <a @click="addReportShow = true, abnormalTask = operateDetail.taskId">异常上报</a> -->
        </div>
        <div class="operate-content">
          <div style="display:flex;justify-content:space-between;color:#344563;font-size: 12px;">
            <div>验车地址：{{ operateDetail.startAddress }}</div>
            <div>预约验车时间：{{ operateDetail.subscribeTime }}</div>
          </div>
          <a-divider />
          <div style="display: flex;justify-content: space-between;margin-bottom:10px">
            <span v-show="!operateDetail.carrierName">
              <span style="color:#0066CC;margin-right:10px">系统推荐方案</span>
              <span style="color:#6B778C" v-show="operateDetail.sysRecommend">
                <span>{{ operateDetail.sysRecommend?.startAddress?.details }}</span>
                <span v-show="operateDetail.sysRecommend?.startAddress">——</span>
                <span> {{ operateDetail.sysRecommend?.endAddress?.details }}</span>
              </span>
            </span>
            <span style="color:#0066CC" v-show="operateDetail.carrierName">已选择方案</span>
            <span style="color:#6B778C">
              请发送委托和承运商确认
              <ArrowRightOutlined style="font-size: 12px;width:20px" />
              <a style="color:#0066CC" @click="entrustShow = true">委托</a>
            </span>
          </div>
          <div style="display:flex;justify-content: space-between;font-size: 12px;color:#6B778C">
            <div v-show="operateDetail.sysRecommend || operateDetail.truckStatus">
              <span style="margin-right:15px" v-show="!operateDetail.truckStatus">
              {{ operateDetail.sysRecommend?.carrierName }}</span>
              <span style="margin-right:15px" v-show="operateDetail.truckStatus">{{ operateDetail.carrierName }}</span>
              <span>费用 ￥
                <span v-show="operateDetail.carrierName === null && operateDetail.sysRecommend">{{
                  operateDetail.sysRecommend?.cost }}</span>
                <span v-show="operateDetail.carrierName !== null">{{ operateDetail.transportFee }}</span>
              </span>
              <span class="m-l1">
                {{operateDetail.truckType?.label}} {{ operateDetail.carNo }}
              </span>
            </div>
            <div v-show="!operateDetail.sysRecommend && !operateDetail.truckStatus">暂无</div>
            <div style="display: flex;align-items: center;" v-show="operateDetail.orderVehicleTransType !== -1">
              <span>验车照片</span>
              <span>&emsp;</span>
              <a-button type="primary" size="small" @click="updateImg">{{ operateDetail.picNum }}张</a-button>
            </div>
            <div style="display: flex;align-items:center" v-show="operateDetail.truckStatus === null">
              <span v-show="operateDetail.sysRecommend">如和承运商达成一致，点击<a-button type="dashed" size="small"
                  :loading="btnLoading" @click="confirm(operateDetail.executeType?.value)">确认</a-button>如未能达成点击
              </span>
              <span v-show="!operateDetail.sysRecommend">该线路没有系统推荐承运商，请点击</span>
              <a @click="arrangeShow = true, carrierData.chooseType = 2">人工选择</a>
            </div>
          </div>
          <div style="margin-top:10px" v-show="operateDetail.truckStatus?.value >= 0">
            <a-button type="dashed" :loading="btnLoading"
              v-show="operateDetail.orderVehicleTransType === -1 || operateDetail.orderVehicleTransType === 0"
              @click="arrangeShow = true">安排验车</a-button>
            <!-- <a-button type="dashed" :loading="btnLoading" v-show="operateDetail.orderVehicleTransType === 2"
              @click="arrangeShow = true">修改安排</a-button> -->
            <a-popconfirm :title="confirmTitle" ok-text="确定" cancel-text="取消" @confirm="inspectorComplete">
              <a-button type="dashed" :loading="btnLoading" @click="prompt"
                v-show="operateDetail.orderVehicleTransType === 2">验车完成</a-button>
            </a-popconfirm>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 安排验车 -->
  <a-modal v-model:visible="arrangeShow" title="安排验车"  width="40%"  @ok="getInspector" :destroyOnClose="true" >
    <div>
      <Inspector :truckStatus="operateDetail.truckStatus" ref="arrangeInfo" :carrier="carrierData" :appCarrier="1" />
    </div>
  </a-modal>
  <!-- 验车费用 -->
  <a-modal v-model:visible="inspectorFeeShow" title="验车费用" @ok="confirmInspection" :confirmLoading="btnLoading">
    <div>
      金额：<a-input v-model:value="amt" type="number" :min="0" />
    </div>
  </a-modal>
  <!-- 验车图片 -->
  <BatchInspectionImg ref="imgListRef" :batchData="batchData" :loadInspectList="[]" />
  <!-- 小程序码 -->
  <!-- <a-modal v-model:visible="QRCshow" title="小程序码" footer="">
    <div>
      <div>
        <div>车架号：<a-tag color="green">{{ detailData.vinNo }}</a-tag></div>
        <div>线路：<span style="color:#f00">{{ detailData.startAddress + '——' + detailData.endAddress }}</span>
        </div>
        <img v-show="!QRCloading" class="img" :src="QRCurl" style="width:100%;height:100%">
        <div style="text-align: center"><a-spin v-show="QRCloading" size="large" /></div>
      </div>
    </div>
  </a-modal> -->
  <!-- 委托 -->
  <a-modal v-model:visible="entrustShow" title="验车委托" footer="" :bodyStyle="{ padding: '10px' }">
    <div v-show="operateDetail.executeType?.value === 0">
      <p class="font-color2">{{ vehicleArr.join('，') }}</p>
      <p class="font-color2">交车人姓名：{{ operateDetail.driverName }}</p>
      <p class="font-color2">交车人电话：{{ operateDetail.driverTel }}</p>
      <p class="font-color2">交车人身份证：{{ operateDetail.driverIdNumber }}</p>
      <p class="font-color2">预约验车时间：{{ operateDetail.subscribeTime }}</p>
      <p class="font-color2">验车地址：{{ operateDetail.startAddress }}</p>
      <div style="text-align:right">
        <a-button type="dashed" @click="copyData(operateDetail.executeType?.value)">一键复制</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import { ArrowRightOutlined, FileZipOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { copyText } from '@/utils/util'
import { vent, done, QRCurl, inspectionChange, inspectionChangeEdit } from '@/api/transport/inspection'
import { getExecute, arrangeTrnas, changeTransType, loadVehicle, carUnloadVehicle, batchInspect, batchPlantDetail } from '@/api/transport/transport'
import Inspector from '@/views/components/inspector'
import BatchInspectionImg from '@/views/components/vehicleUpload/batchInspectionImg'

export default {
  props: {
    taskDetail: Object,
    detail: Object,
    batchData: Object
  },
  components: {
    ArrowRightOutlined,
    FileZipOutlined,
    Inspector,
    BatchInspectionImg
  },
  setup (props, context) {
    const imgListRef = ref(null)
    const state = reactive({
      addReportShow: false,
      inspectorFeeShow: false,
      btnLoading: false,
      entrustShow: false,
      arrangeShow: false,
      nullVisible: false,
      QRCloading: false,
      QRCshow: false,
      amt: 0,
      vehicleArr: [],
      inspectionData: {},
      carInfo: {},// 验车车辆选择回传数据
      batchData: {},
      abnormalTask: '',
      confirmTitle: '',
      operateDetail: {},
      carrierData: {}
    })
    const getData = () => {
      state.taskDetail = props.taskDetail
      state.batchData = props.batchData
      state.vehicleArr = props.batchData.vehicleArr
      operateData()
    }
    // 获取任务信息-状态
    const operateData = () => {
      state.btnLoading = true
      batchPlantDetail({
        orderVehicleIds: state.batchData.orderVehicleIds,
        transportType: state.batchData.transportType
      }).then(res => {
        if (res.code === 10000) {
          state.operateDetail = res.data
          state.carrierData = {
            orderId: state.batchData.orderId,
            chooseType: state.operateDetail.isSysRecommend?.value,
            carrierName: state.operateDetail.carrierName !== '' ? state.operateDetail.carrierName : state.operateDetail.sysRecommend?.carrierName,
            orderVehicleIds: state.batchData.orderVehicleIds
          }
        }
      }).finally(() => { state.btnLoading = false })
    }
    // 验车人回传接收
    const arrangeInfo = ref(null)
    const getInspector = () => {
      if(!arrangeInfo.value?.info.driverInfo?.carrierId){
        message.error('请先选择承运商')
        return
      }
      state.inspectionData = arrangeInfo.value?.info.driverInfo
      state.carInfo = arrangeInfo.value?.info.carInfo
      state.inspectorFeeShow = true
      state.arrangeShow = false
    }
    // 安排验车人
    const confirmInspection = () => {
      state.btnLoading = true
      if (state.operateDetail.truckStatus) {
        inspectionChangeEdit({
          carrierTruckId: state.carInfo.id ? state.carInfo.id : '',
          carrierTruckNo: state.carInfo.carNo ? state.carInfo.carNo : '',
          amt: state.amt,
          orderVehicleIds: state.batchData.orderVehicleIds,
          carrierId: state.inspectionData.carrierId,
          carrierName: state.inspectionData.carrierName,
          driverId: state.inspectionData.id,
          driverName: state.inspectionData.name,
          driverType: state.inspectionData.driverType,
          isNull: state.inspectionData.driverType,
          orderId: state.batchData.orderId,
          driverTel: state.inspectionData.mobile,
          driverIdNumber: state.inspectionData.idNumber,
          taskId: null
        }).then(res => {
          if (res.code === 10000) {
            message.success('修改成功')
            state.amt = 0
            operateData()
          }
        }).finally(() => { state.btnLoading = false, state.inspectorFeeShow = false })
      } else {
        inspectionChange({
          carrierTruckId: state.carInfo.id ? state.carInfo.id : '',
          carrierTruckNo: state.carInfo.carNo ? state.carInfo.carNo : '',
          amt: state.amt,
          carrierId: state.inspectionData.carrierId,
          carrierName: state.inspectionData.carrierName,
          driverId: state.inspectionData.id,
          driverName: state.inspectionData.name,
          driverType: state.inspectionData.driverType,
          isNull: state.inspectionData.driverType,
          orderId: state.batchData.orderId,
          driverTel: state.inspectionData.mobile,
          driverIdNumber: state.inspectionData.idNumber,
          orderVehicleIds: state.batchData.orderVehicleIds,
          taskId: null
        }).then(res => {
          if (res.code === 10000) {
            message.success('安排成功')
            state.amt = 0
            operateData()
          }
        }).finally(() => { state.btnLoading = false, state.inspectorFeeShow = false })
      }
    }
    // 验车图片
    const updateImg = () => {
      imgListRef.value.inspectionImg = true
      imgListRef.value.loadData(state.batchData.orderVehicleIds[0])
    }
    // 验车完成前提示确认框--判断验车照片的数量大于0
    const prompt = () => {
      // let num = state.monitorData.trans.reduce((t, v) => t + v.picNum, 0)
      if (state.operateDetail.picNum <= 0) state.confirmTitle = '检测到你还未上传过图片,确认完成吗?'
      else state.confirmTitle = '确认验车完成 '
    }
    // 验车完成
    const inspectorComplete = () => {
      state.spinShow = true
      state.btnLoading = true
      batchInspect({ orderVehicleIds: state.batchData.orderVehicleIds }).then(res => {
        if (res.code === 10000) {
          message.success('验车' + res.msg)
          context.emit('status', true) // 用于更新左侧组件列表数据
        }
      }).finally(() => { state.btnLoading = false })
    }
    // 生成小程序码
    const handleCope = () => {
      message.info('待修改批量小程序码接口')
      // state.QRCshow = !state.QRCshow
      // if (state.QRCshow === true) {
      //   state.QRCloading = true
      //   const wXprogramQRcode = { scene: state.detailData.orderVehicleId, type: 1 }
      //   QRCurl(wXprogramQRcode)
      //     .then(res => {
      //       if (res.code === 10000) {
      //         state.QRCurl = 'data:image/png;base64,' + res.data
      //         state.QRCloading = false
      //       }
      //     }).catch(err => console.log(err))
      // }
    }
    // 确认承运商委托按钮
    const confirm = (num) => {
      switch (num) {
        case 0:
          state.carrierData.chooseType = 1
          state.arrangeShow = true
          break;
        case 1:
          // if (state.operateDetail.sysRecommend) loadVeh()
          state.reachChooseShow = true
          break;
        case 2:
          if (state.operateDetail.sysRecommend) loadVeh()
          else message.info('当前任务暂无推荐信息，请选择人工')
          break;
        case 3:
          if (state.operateDetail.sysRecommend) loadVeh()
          else message.info('当前任务暂无推荐信息，请选择人工')
          break;
      }
    }
    // 一键复制委托信息
    const copyData = type => {
      switch (type) {
        case 0:
          copyText(
            `${state.operateDetail.brand + state.operateDetail.model + '' + state.operateDetail.vinNo}\n交车人姓名：${state.operateDetail.driverName || ''}\n交车人电话：${state.operateDetail.driverTel || ''}\n交车人身份证：${state.operateDetail.driverIdNumber || ''}\n预约验车时间：${state.operateDetail.subscribeTime || ''}\n验车地址：${state.operateDetail.startAddress || ''}`
          )
          message.success('文本内容已复制')
          break;
        case 1:
          copyText(
            `${state.operateDetail.brand + state.operateDetail.model + ' ' + state.operateDetail.vinNo}\n交车人姓名：${state.operateDetail.driverName}\n交车人电话：${state.operateDetail.driverTel}\n交车人身份证：${state.operateDetail.driverIdNumber}\n取车方式：${state.operateDetail.pickType.label}\n取车地址：${state.dispatchDetail.placeInAddress}`
          )
          message.success('文本内容已复制')
          break;
        case 2:
          copyText(
            `${state.operateDetail.brand + state.operateDetail.model + ' ' + state.operateDetail.vinNo}\n收车人姓名：${state.operateDetail.driverName}\n收车人电话：${state.operateDetail.driverTel}\n收车人身份证：${state.operateDetail.driverIdNumber}\n订单到付：${state.dispatchDetail.paymentArrival}\n终点城市：${state.operateDetail.endAddress}`
          )
          message.success('文本内容已复制')
          break;
        case 3:
          copyText(
            `${state.operateDetail.brand + state.operateDetail.model + ' ' + state.operateDetail.vinNo}\n收车人姓名：${state.operateDetail.driverName}\n收车人电话：${state.operateDetail.driverTel}\n收车人身份证：${state.operateDetail.driverIdNumber}\n订单到付：${state.dispatchDetail.paymentArrival} ${!dispatchDetail.isNoticeReleaseCar ? '' : '等通知放车'}\n送车方式：${state.operateDetail.takeType?.label}\n送车地址：${state.operateDetail.endAddress}`
          )
          message.success('文本内容已复制')
          break;
      }
    }

    return {
      ...toRefs(state),
      imgListRef,
      operateData,
      handleCope,
      confirm,
      updateImg,
      prompt,
      getInspector,
      confirmInspection,
      inspectorComplete,
      getData,
      copyData,
      arrangeInfo
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

:deep(.ant-input-number) {
  border: none !important;
}

:deep(.ant-input-group-addon) {
  border: none;
  background-color: #fff;
}

:deep(.ant-input-group-wrapper) {
  .ant-input {
    border: none !important;
  }
}

.ant-input-number:outline {
  border: none !important;
}

:deep(.ant-divider) {
  // width: 200%;
  margin: 10px 0;
  // margin-left: -100px;
}

:deep(.ant-input-number-input) {
  border: none;
}

:deep(.ant-btn-dashed) {
  margin-right: 10px;
  border: 1px solid #0066CC;
  color: #fff;
  background-color: #0066CC;
}

.operate-div {
  .operate-title {
    margin: 5px 0 10px 0;
    font-weight: 700;
    font-style: normal;
    color: #344563;
  }

  .operate-content {
    position: relative;
    padding: 10px;
    background-color: #FAFAFA;
    overflow: hidden;
  }
}
</style>