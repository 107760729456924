<template>
  <div>
    <div class="nav">
      11
    </div>
    <div class="flex contenBox">
      <div class="leftBox">

      </div>
      <div class="rightBox">

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'


</script>

<style lang="less" scoped>
.nav {
  width: 100%;
  height: 80px;
  z-index: 999999;
  background: #000;
  position: absolute;
  top: 0;
}
.contenBox{
  position: absolute;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
}
.leftBox {
  width: 50%;
  height: calc(100vh-80px);
  background: #fff;


}
.rightBox {
  width: 50%;
  height: calc(100vh-80px);
  background: red;
}
</style>