<template>
  <div>
    <a-modal :visible="visible" title="即将到达" footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox ">
       <div class="flex">
        <div v-for="item in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.id ?'changeItem':''">
            {{ item.name }}&nbsp;{{ item.total }}
         </div>
       </div>
         <div class="flex optBox" >
              <div v-for="opt in optList" :key="opt.id" @click="optBtn(opt)" class="optItem cur-p" :class="indexKeyOpt === opt.id ?'changeOpt':''">
                {{ opt.name }}{{ opt.total }}
              </div>
            </div>
      </div>
      <SearchList :iptData="iptData"></SearchList>
      <div class="m-t1 tableBox">
        <a-table :dataSource="dataSource" :columns="columns" bordered :pagination="false"></a-table>
      </div>
    </a-modal>
  </div>
</template>
  
  <script setup>
import { ref } from 'vue'
import SearchList from '@/components/searchList'
import enumAll from '@/store/modules/enumAll'
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
})
const cancel = () => {
  emit('update:visible', false)
}
const indexKey = ref(1)
const indexKeyOpt = ref(0)
const optList = ref([])

const changeBtn = (e) => {
  indexKey.value = e.id
  optList.value = e.opt
  indexKeyOpt.value = 0
}
const optBtn = (e) => {
  indexKeyOpt.value = e.id
}
const tabstatus = ref([
  {
    name: '全部',
    id: 1,
    total: '192'

  },
  {
    name: '四川省',
    id: 2,
    total: '192',
    opt:[
     {
      name:'成都市',
      id: 1,
      total:'29'
     },
     {
      name:'宜宾市',
      id: 2,
      total:'29'
     }
    ]
  },
  {
    name: '云南省',
    id: 3,
    total: '192',
    opt:[
     {
      name:'昆明市',
      total:'29',
      id:1
     },
     {
      name:'大理',
      total:'29',
      id:2
     }
    ]
  },
])
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "status",
    changeOn:true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
    key:'value',
    keyVal:'label',
    opt:[
      {
        label:'是',
        value:1
      },
      {
        label:'否',
        value:0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签分类",
    value: null,
    prop: "vinNo",
    width: 150,
    key:'value',
    keyVal:'label',
    opt:[]
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
 
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },

  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
])
const columns = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots:{
      customRender: 'add'
    }
  },
  {
    title: '车牌号/车架-车型',
    dataIndex: 'vinNo',
    align: 'center',
    slots:{
      customRender: 'vinNo'
    }
  },
  {
    title: '订单状态',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '当前城市',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '位置更新时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '更新人',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item{
  padding: 10px;
  padding-bottom: 0;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem{
  color: #0066CC;
}
.optBox{
  padding: 10px;
}
.optItem{
  padding: 2px 10px;
  border: 1px solid #eee;
  font-size: 13px;
  border-radius: 1px;
  margin-right: 10px;
}
.changeOpt{
  border: 1px solid #0066CC;
  color: #0066CC;
}
</style>