import { postBodyRequest } from '@/utils/axios'

export function putWarehousePage (params) {
  return postBodyRequest('/trans/order/vehicle/stock/warehousing/page', params)
}

export function put (params) {
  return postBodyRequest('/trans/order/vehicle/stock/warehousing', params)
}
export function statistics (params) {
  return postBodyRequest('/trans/order/vehicle/stock/warehousing/statistics', params)
}
export function getRecentlyData (orderVehicleId, params) {
  return postBodyRequest(`/trans/order/vehicle/${orderVehicleId}/trans/last`, params)
}
export function transferVeh (params) {
  return postBodyRequest('/trans/order/vehicle/stock/transfer', params)
}
export function getAddressData (params) {
  return postBodyRequest('/trans/order/vehicle/stock/app/warehousing/stats', params)
}
export function getnoShopPage (params) {
  return postBodyRequest('/trans/order/vehicle/stock/warehousing/noShop/page', params)
}