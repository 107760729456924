<template>
  <!-- 异常上报 -->
  <a-modal :visible="shareShow" title="共享" @ok="shareSubmit" @cancel="cancel">
    <div v-if="shareShow">
      <div style="font-size:18px;color:#0066CC;margin:10px 0;">你已选择{{ batchList.length }}台车辆</div>
      <div>
        <div>选择类型<span style="color:#f00">*</span></div>
        <a-select v-model:value="shareChoose" placeholder="选择" style="width: 120px">
          <a-select-option :value="1">共享</a-select-option>
          <a-select-option :value="2">取消共享</a-select-option>
        </a-select>
      </div>
      <div v-show="shareChoose === 1">
        <div>被分享人<span style="color:#f00">*</span></div>
        <a-select style="width:75%" :filter-option="false" show-search placeholder="请输入员工姓名搜索" @search="assignment">
          <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name" @click="memberChange(item)">{{ item.name }}-{{ item.no
          }}-{{ item.mobile }}</a-select-option>
          <template v-if="fild" #notFoundContent>
            <a-spin size="small" />
          </template>
        </a-select>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
import { employeeList } from '@/api/crmManagement/setRules'

export default {
  props: { shareShow: Boolean, recordList: Array },
  setup (props, context) {
    const state = reactive({
      shareShow: props.shareShow,
      shareChoose: null,
      timer: null,
      fild: false,
      shareData: {},
      batchList: [],
      employeeOptions: [],
    })
    watch(() => props.shareShow, (newVal, oldVal) => {
      state.shareShow = newVal
      if (props.recordList) state.batchList = props.recordList
    })

    const memberChange = item => {
      state.shareData = item
      state.employeeOptions = []
    }
    const assignment = (e, v) => {
      if (e !== '') {
        if (state.timer !== null) {
          clearTimeout(state.timer)
        }
        state.fild = true
        state.timer = setTimeout(() => {
          employeeList({
            current: 1,
            name: e,
            size: 10
          }).then(res => {
            if (res.code === 10000) {
              state.employeeOptions = res.data
              state.fild = false
            }
          }).catch(err => { console.log(err) })
        }, 1000)
      } else state.employeeOptions = [], clearTimeout(state.timer), state.fild = false
    }
    const cancel = () => {
      state.shareShow = false
      clearData()
      context.emit('statusShow', state.shareShow)
    }
    const shareSubmit = () => {
      if (state.shareChoose === 2) {
        state.shareData = {}
        state.shareData.cancelType = 1
      }
      context.emit('statusShow', state.shareData)
      setTimeout(() => {
        state.shareShow = false
        clearData()
      }, 1000)
    }
    const clearData = () =>{
      state.shareChoose = null    // 清空赋值
      state.timer = null    // 清空赋值
      state.fild = false    // 清空赋值
      state.shareData = {}    // 清空赋值
      state.batchList = []    // 清空赋值
      state.employeeOptions = []    // 清空赋值
    }

    return {
      ...toRefs(state),
      cancel,
      memberChange,
      assignment,
      shareSubmit
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

:deep(.ant-steps-icon-dot) {
  background-color: #0C8226 !important;
}

:deep(.ant-steps-item-icon) {
  margin-left: 9px !important;
}

:deep(.ant-steps-item-tail) {
  width: 112rpx !important;
  margin-left: 47% !important;
}

:deep(.ant-steps-item:first-child) {
  .ant-steps-item-tail {
    display: none;
  }
}

:deep(.ant-steps-item-tail::after) {
  width: calc(100% - 11px) !important;
  height: 1px !important;
  margin-left: -192% !important;
  background-color: #0C8226 !important;
}

:deep(.ant-steps-item:last-child) {
  flex: 1 !important;
}

:deep(.ant-steps-item-error) {
  .ant-steps-icon-dot {
    background-color: #C1C7D0 !important;
  }

  .ant-steps-item-tail::after {
    background-color: #C1C7D0 !important;
  }

  .ant-steps-item-title {
    color: #C1C7D0 !important;
  }

  .ant-steps-item-description {
    color: #C1C7D0 !important;
  }
}

:deep(.ant-steps-item-active) {
  .ant-steps-item-icon {
    width: 8px !important;
    height: 8px !important;
  }

  .ant-steps-item-tail::after {
    background-color: #0066CC !important;
  }

  .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45) !important;
  }
}

:deep(.ant-steps-item-wait) {
  .ant-steps-icon-dot {
    background-color: #0066CC !important;
  }

  .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.85) !important;
  }
}

:deep(.ant-steps-item) {
  width: 50px;
}

:deep(.ant-steps-item:last-child) {
  display: none;
}

:deep(.ant-steps-item-content) {
  width: 100% !important;
  margin: 0 !important;
}

:deep(.ant-steps-item-title) {
  font-size: 12px !important;
}

:deep(.ant-steps-item-description) {
  font-size: 12px !important;
}

.ant-table-body {
  overflow: auto;
  max-height: 400px;
  border: 1px solid #f0f0f0;
}

.ant-table-fixed {
  width: 2600px;
}

.ant-table-thead>tr>th {
  height: 63px;
  text-align: center;
  border-right: 1px solid #f0f0f0;
}

.divider {
  height: 100%;
  position: absolute;
  left: -16px;
  top: 1px;

  :deep(.ant-divider) {
    width: 15px !important;
    height: 100%;
    box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 15%);
    border: none;
    margin: 0 !important;
  }
}

.ant-table-thead>tr>th:last-child {
  text-align: center;
  position: sticky;
  right: 0px;
}

.ant-table-row-cell-break-word {
  text-align: center;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
</style>