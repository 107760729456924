<template>
  <div>
    <a-select style="width:75%" mode="multiple" :not-found-content="fild ? undefined : null" :filter-option="false"
      show-search placeholder="请输入员工姓名或关键字,按下回车搜索" @inputKeyDown="search" @search="assignment" @change="memberChange">
      <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name">{{ item.name }}-{{ item.no }}-{{
        item.mobile }}</a-select-option>
      <template v-if="fild" #notFoundContent>
        <a-spin size="small" />
      </template>
    </a-select>
    <a @click="messageShow = !messageShow">
      <SwapOutlined />&emsp;{{ messageShow ? '聊天' : '计划' }}
    </a>
    <!-- <a-select v-model:value="empRealId" style="width: 120px;margin-left:15px">
      <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.id">{{item.value}}</a-select-option>
    </a-select> -->
    <div style="position:relative">
      <a-textarea v-model:value="value" placeholder="请输入发送内容" :rows="4" style="border:none" @pressEnter="send" />
      <div :style="{opacity:opacityError}" class="point-out"> <a-alert type="error" message="请输入要发送的内容" banner /></div>
      <div :style="{opacity:opacitySuccess}" class="point-out"> <a-alert type="success" message="已发送" banner /></div>
    </div>
    <div style="display:flex;justify-content:end">
      <a-button style="color:#0066CC;border:none;box-shadow:none" @click="send" :loading="sendLoad"
        type="link">发送</a-button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
import { websocket } from '@/api/global'
import { message } from 'ant-design-vue'
import { employeeList } from '@/api/crmManagement/setRules'
import { SwapOutlined } from '@ant-design/icons-vue'
export default {
  props: {
    empId: String,
    name: String,
    vehicleId: String,
    orderId: String,
    employeeList: Array
  },
  components: {
    SwapOutlined
  },
  emits: ['ok'],
  setup (props, { emit }) {
    const state = reactive({
      orderVehicleId: '',
      orderId: '',
      name: '',
      type: '',
      empRealId: '',
      value: '',
      fild: false,
      opacityError:0,
      opacitySuccess: 0,
      messageShow: true,
      sendLoad: false,
      pointOutShow: false,
      employeeOptions: []
    })
    // 消息发送四件套
    const search = e => {
      if (state.empRealId === '' || state.empRealId === undefined || state.empRealId === null) state.employeeOptions = []
      if (e.keyCode === 13) {
        state.fild = true
        state.timer = setTimeout(() => {
          employeeList({
            current: 1,
            name: state.name,
            size: 10
          }).then(res => {
            if (res.code === 10000) {
              state.employeeOptions = res.data
              state.fild = false
            }
          }).catch(err => { console.log(err) })
        }, 500)
      }
    }
    const assignment = e => {
      if (e !== '') state.name = e
      // console.log(e)
    }
    const memberChange = (e, val) => {
      if (e.length > 1) {
        e.splice(0, 1)
        val.splice(0, 1)
        e[0] = val[0].value
      } else state.empRealId = ''
      if (val.length > 0) state.empRealId = val[0].key
      state.employeeOptions = []
    }
    const send = () => {
      // 1454994300918116353
      if (state.value != '') {
        state.sendLoad = true
        document.activeElement.blur()
        const data = { clientTag: 'USER_PC', msgType: 'CHAT', uniqueId: state.empRealId, content: state.value, orderVehicleId: state.orderVehicleId, orderId: props.orderId, type: state.messageShow ? 2 : 4 }
        websocket(JSON.stringify(data))
          .then(res => {
            state.value = ''
            if (res.data === 'true') {
              message.success('发送成功')
              state.opacitySuccess = 1
              emit('ok')
            } else if (res.code === 10000) {
              state.opacitySuccess = 1
              emit('ok')
            }
          }).catch(err => { console.log(err) })
          .finally(() => {
            setTimeout(()=>{
              state.opacitySuccess = 0
            },1000)
            state.sendLoad = false
          })
      }else{
        state.opacityError = 1
        state.pointOutShow = true
        setTimeout(()=>{
          state.opacityError = 0
          state.pointOutShow = false
        },1000)
      }
    }
    watch(() => props.type, (newVal, oldVal) => {
      state.type = newVal
    }, { deep: true })
    // watch(() => props.employeeList, (newVal, oldVal) => {
    //   state.employeeOptions = newVal
    // }, { deep: true })

    watch(() => props.empId, (newVal, oldVal) => {
      state.empId = newVal
    }, { deep: true })
    watch(() => props.orderId, (newVal, oldVal) => {
      state.orderId = newVal
    }, { deep: true })

    watch(() => props.vehicleId, (newVal, oldVal) => {
      state.orderVehicleId = props.vehicleId
    }, { deep: true })
    return {
      ...toRefs(state),
      search,
      assignment,
      memberChange,
      send
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
