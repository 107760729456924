<template>
  <div>
    <a-table size="small" :columns="columnsSend" :scroll="{ x: 1500 }" bordered :row-key="record => record.id" :data-source="dispatchDetail.tasks" :pagination="false">
      <template #vinNo="{ record }">
        <div> {{ record.vinNo }} </div>
        {{ record.brand }} {{record.model}}
      </template>
      <template #image="{ record }">
        <span v-if="record.pic?.value === 0">{{ record.pic?.label}}</span>
        <a v-else @click="lookVehicleImg(record)">{{record.picNum}}张</a>
      </template>
      <template #deliveryCard="{ record }">
        <span v-if="record.deliveryCard?.value === 0">{{ record.deliveryCard?.label}}</span>
        <a v-else @click="lookDeliveryCard(record)">查看</a>
      </template>
      <template #address="{ record }">
        <span v-if="record.address?.value === 0">{{ record.address?.label }}</span>
        <a v-else @click="lookVehicleGps(record)">查看</a>
      </template>
      <template #add="{ record }">
        <a-button size="small" @click="taskOperation(record)" type="primary">操作</a-button>
      </template>
    </a-table>
    <!-- 在途位置 -->
    <VehicleGps ref="vehicleGps" />
    <!-- 交车条 -->
    <PictureViewer v-model:visible="visibleLookDeliver" :lookImgID="lookImgID"></PictureViewer>
    <!-- 详情模块 -->
    <DetailsModal v-model:visible="detailsShow" :lookID="lookID" @statusAdd="revokeSend" v-if="detailsShow"></DetailsModal>
    <!-- 派车图片查看 -->
    <VehicleImg ref="vehicleImgRef" />
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from 'vue'
import DetailsModal from '../taskAssignment/detailsModal'
import VehicleImg from '@/views/components/vehicleImg'
import VehicleGps from '@/views/order/positionInfo/carGps.vue'
import PictureViewer from '@/components/PictureViewer'
import { vehicleTaskInfo, taskDeliveryPic } from '@/api/transport/transport'

const props = defineProps({
  dispatchDetail: {
    type: Object,
    default: {}
  },
  type: {
    type: String,
    default: ''
  }

})
const vehicleImgRef = ref(null)
const detailsShow = ref(false)
const lookID = ref('')
const lookImgID = ref([])
const visibleLookDeliver = ref(false)
const vehicleGps = ref(null)

// 查看交车条
const lookDeliveryCard = (record) => {
  taskDeliveryPic(record.orderVehicleId, {
    taskId: record.taskId
  }).then(res => {
    if (res.code !== 10000) return
    lookImgID.value = res.data.map(item => item.fileId)
    visibleLookDeliver.value = true
  })

}
const lookVehicleGps = (record) => {
  setTimeout(() => {
    vehicleGps.value.onvisible(true)
    vehicleGps.value.onorderVehicleId(record.orderVehicleId)
  }, 50)
}
// 撤销外派
const revokeSend = () => {

}
const taskOperation = (item) => {
  lookID.value = item.taskId
  detailsShow.value = true
}

// 查看视频图片
const lookVehicleImg = (record) => {
  setTimeout(() => {
    vehicleImgRef.value.vehicleImg(record.orderVehicleId, record.vinNo)
  }, 50)
}
onMounted(() => {
  if (props.type === 'carTable') {
    columnsSend.value.forEach((item,index) => {
      if(item.title === '承运商' || item.title === '操作'){
        columnsSend.value.splice(index,1)
      }
    })
  }
})
const columnsSend = ref([
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    align: 'center',
    slots: { customRender: 'vinNo' },
  },
  {
    title: '运费',
    dataIndex: 'totalAmt',
    align: 'center',
  },
  {
    title: '任务状态',
    dataIndex: 'taskStatus.label',
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'carrierName',
    align: 'center',
  },
  {
    title: '任务起点',
    dataIndex: 'lineStart',
    align: 'center',
  },
  {
    title: '任务终点',
    dataIndex: 'lineEnd',
    align: 'center',
  },
  {
    title: '结算状态',
    dataIndex: 'settlementStatus.label',
    align: 'center',
  },
  {
    title: '车辆状态',
    dataIndex: 'transStatus.label',
    align: 'center',
  },
  {
    title: '开始时间',
    dataIndex: 'taskStartTime',
    align: 'center',
  },
  {
    title: '结束时间',
    dataIndex: 'taskEndTime',
    align: 'center',
  },
  {
    title: '完成时效',
    dataIndex: 'finishPrescription',
    align: 'center',
  },
  {
    title: '照片',
    dataIndex: 'pic.label',
    slots: {
      customRender: 'image'
    },
    align: 'center',
  },
  {
    title: '位置',
    dataIndex: 'address',
    slots: { customRender: 'address' },
    align: 'center',
  },
  {
    title: '交车条',
    dataIndex: 'deliveryCard',
    slots: { customRender: 'deliveryCard' },
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    slots: { customRender: 'add' },
    fixed: 'right',
    align: 'center',
  },
])
</script>

<style lang="less" scoped>
</style>