import { ref } from 'vue'
export const iptData = ref([
  {
    type: 'input',
    placeholder: '起运地',
    value: '',
    prop: 'startAddress',
    width: '10%'
  },
  {
    type: 'input',
    placeholder: '目的地',
    value: '',
    prop: 'endAddress',
    width: '10%'
  },
  {
    type: 'selectTime',
    placeholder: '运输开始时间起',
    value: null,
    prop: 'startTime',
    width: '10%',
  },
  {
    type: 'selectTime',
    placeholder: '运输开始时间止',
    value: null,
    prop: 'endTime',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '大车车牌号',
    value: null,
    prop: 'carNo',
    width: '10%',
  },
  {
    type: 'input',
    placeholder: '司机姓名',
    value: null,
    prop: 'driverName',
    width: '10%',
  },

])
export const columns = ref([
  {
    title: '序号',
    dataIndex: 'sort',
    slots:{ customRender: 'sort' },
    align: 'center',
    width: '60px',
  },
  {
    title: '司机姓名',
    dataIndex: 'driverName',
    align: 'center',
  },
  {
    title: '小车车牌车型',
    dataIndex: 'vinBrandModel',
    align: 'center'
  },
  {
    title: '大车车牌号',
    dataIndex: 'carNo',
    align: 'center'
  },
  {
    title: '起始地',
    dataIndex: 'startAddress',
    align: 'center'
  },
  {
    title: '目的地',
    dataIndex: 'endAddress',
    align: 'center'
  },
  {
    title: '派车人',
    dataIndex: 'departure',
    align: 'center'
  },
  {
    title: '运输开始时间',
    dataIndex: 'departureTime',
    align: 'center'
  },
  {
    title: '运输完成时间',
    dataIndex: 'unloadingTime',
    align: 'center'
  },
  {
    title: '运费',
    dataIndex: 'freight',
    align: 'center'
  },
  {
    title: '客户名称',
    dataIndex: 'customerName',
    align: 'center'
  },
  {
    title: '业务员',
    dataIndex: 'sourceMan',
    align: 'center'
  },

  {
    title: '订单号',
    dataIndex: 'orderId',
    align: 'center'
  },
  {
    title: '订单备注',
    dataIndex: 'remark',
    align: 'center'
  },
  {
    title: '小车备注',
    dataIndex: 'ovRemark',
    align: 'center'
  },
])
