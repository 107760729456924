<template>
  <div>
    <a-modal :visible="visible" title="发车计划" footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
         <div v-for="item in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.id ?'changeItem':''">
            {{ item.name }}({{ item.total }})
         </div>
      </div>
      <SearchList :isWatch="true" :iptData="iptData"></SearchList>
      <div class="m-t1 tableBox">
        <a-table :dataSource="dataSource" :columns="columns" :pagination="false" bordered></a-table>
      </div>
    </a-modal>
  </div>
</template>
  
  <script setup>
import { onMounted, ref } from 'vue'
import SearchList from '@/components/searchList'
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
})
const cancel = () => {
  emit('update:visible', false)
}
const indexKey = ref(1)

const changeBtn = (e) => {
  indexKey.value = e.id
  if(e.id === 3){
    if(iptData.value.length < 10){
      iptData.value =  [...iptData.value,...changeIpt.value]
    }
  }else{
    if(iptData.value.length === 10){
      iptData.value = iptData.value.slice(0,iptData.value.length-2)
    } 
  }
}
onMounted(() => {
})
const changeIpt = ref([
  {
    type: "selectTime",
    placeholder: "预计发车时间",
    value: null,
    prop: "vinNo12",
    width: 150,
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[]
  },
])
const tabstatus = ref([
  {
    name: '全部',
    id: 1,
    total: '192'
  },
  {
    name: '待添加计划',
    id: 2,
    total: '192'
  },
  {
    name: '已添加计划',
    id: 3,
    total: '192'
  },
])
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "status",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "超时状态",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[]
  },
  {
    type: "address",
    placeholder: "当前城市",
    value: null,
    prop: "vinNo",
    width: 150,
  },

  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
])
const columns = ref([
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '超时状态',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '预计发车时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '当前城市',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '起运地',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '目的地',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item{
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem{
  border-bottom: 4px solid #0066CC;
  color: #0066CC;
}
</style>