<template>
  <div>
    <a-modal :visible="visible" title="待验车" footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
        <div v-for="item in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.id ?'changeItem':''">
          {{ item.name }}({{ item.total }})
        </div>
      </div>
      <SearchList :iptData="iptData"></SearchList>
      <div class="m-t1 tableBox">
        <a-table :dataSource="dataSource" :columns="columns" bordered :pagination="false">
          <template #add="{ record }">
            <a>操作</a>
          </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>
  
  <script setup>
import { ref } from 'vue'
import SearchList from '@/components/searchList'
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
})
const cancel = () => {
  emit('update:visible', false)
}
const indexKey = ref(1)

const dataSource = ref([
  {}
])

const changeBtn = (e) => {
  indexKey.value = e.id
}
const tabstatus = ref([
  {
    name: '全部',
    id: 1,
    total: '192'
  },
  {
    name: '验正常',
    id: 2,
    total: '192'
  },
  {
    name: '验预警',
    id: 3,
    total: '192'
  },
  {
    name: '验超时',
    id: 4,
    total: '192'
  },
  {
    name: '验严重',
    id: 5,
    total: '192'
  },
])
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn: true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "status",
    width: 150,
    changeOn: true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "安排人",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
])
const columns = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    }
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '确认运输时间',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '预约时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '安排人',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '安排时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '照片数量',
    dataIndex: 'taskStatus1',
    align: 'center',
  },

  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'taskStatus1',
    align: 'center',
  },

])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item {
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem {
  border-bottom: 4px solid #0066cc;
  color: #0066cc;
}
</style>