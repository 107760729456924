import { postBodyRequest, getRequest } from '@/utils/axios'

export function add (params) {
  return postBodyRequest('/market/crm/follow/add', params)
}
export function edit (params) {
  return postBodyRequest('/market/crm/follow/edit', params)
}

export function contactCustomerIdPage (customerId, params) {
  return postBodyRequest(`/market/crm/follow/${customerId}/page`, params)
}
// 获取所有标签数组
export function getLabelAll (params) {
  return getRequest('/market/crm/label/customer-all-label', params)
}
// 获取客户所有标签信息
export function getCustomerLabelAll (customerId, params) {
  return getRequest(`/market/crm/tag/${customerId}/all`, params)
}
// 新建客户标签
export function addCustomerLabel (params) {
  return postBodyRequest('/market/crm/tag/new', params)
}