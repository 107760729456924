<template>
  <div>
    <a-modal :visible="visible" title="小车查询"  width="90%" :footer="null" :destroyOnClose="true" @cancel="cancel"  :bodyStyle="{background:'#EEEEEE',padding:'10px'}">
      <div class="flex al-center tabBox">
        <div v-for=" (item,index) in statisticsList" :key="item.id" class="itemBox m-r4 cur-p" :class="activeKey == index ? 'active' : ''" @click="changeTab(item,index)">
          {{ item.label || item.orderStatus?.label }}&nbsp;&nbsp;{{ item.num }}
        </div>
      </div>

      <div>
        <SearchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn"></SearchList>
      </div>

      <!-- 表格主体 -->
      <div class="table-data">
        <a-table ref="tableRef" size="small" :columns="columns" :row-key="record => record.orderVehicleId" :data-source="smallVehList" bordered :scroll="{ x: 3200,y:600}" :pagination="pagination" @change="changePage" :loading="loading">
          <template #operation="{ record }">
            <div style="position: relative;">
              <a class="ant-dropdown-link" @click="model(record)">
                操作
              </a>
              <a-dropdown>
                <template #overlay>
                  <a-menu @mouseenter="detail = record">
                    <a-menu-item @click="dataDetail(record)">
                      <a>详情</a>
                    </a-menu-item>
                    <a-menu-item @click="batchManual(record)">
                      <a>派单</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a>发运模式</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a @click="transVehicle(record)">发运</a>
                    </a-menu-item>
                    <!-- <a-menu-item>
                      <a>异常上报</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a>添加费用</a>
                    </a-menu-item> -->
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
          <template #completeness="{ record }">
            <div class="content">
              <a-steps :current="2" size="small" progress-dot>
                <!-- wait蓝色-进行中  error灰色-未安排  默认绿色-已安排 -->
                <a-step v-for="(item, index) in record.completeness" :key="index" :description="item.status" :status="item.value === 1 ? 'error' : item.value === 4 ? 'process' : 'wait'" :title="item.label" />
                <a-step description="error" status="" title="" />
              </a-steps>
            </div>
          </template>
          <template #remark="{ record }">
            <div style="display:flex" @mousemove="remarkId = record.id">
              <div class="remark-content">
                <!-- <span >{{ record.remark }}</span> -->
                <a-tooltip color="#fff">
                  <template #title>
                    <span style="color:#000">{{ record.remark }}</span>
                  </template>
                  <span>{{ record.remark }}</span>
                </a-tooltip>
                <a v-show="!record.remark" @click="openDisRemarks(record)">新增调度备注</a>
              </div>
              <div style="display:flex;align-items:center;width:30px" class="m-l1">
                <a v-show="record.remark" @click="openDisRemarks(record)">记录</a>
              </div>
            </div>
          </template>
          <template #orderTime="{ record }">
            <div>{{record.orderTime}}</div>
            <div style="color:green">{{record.pickAppointTime}}</div>
          </template>
          <template #orderStatus="{record}">
            <div>{{ record.orderStatus.label }}</div>
            <div style="color:green">{{ record.transportType.label }}-{{ record.transStatus.label }}</div>
            <div>{{ record.taskStatus?.label }}</div>
          </template>
          <template #vinNo="{ record }">
            <div style="position: relative;" v-if="record.taskId">
              <a-popover>
                <template #content>
                  {{ record.taskCarrierName }}
                </template>
                <div v-show="record.vinNo === ''">暂无车牌</div>
                <div v-show="record.vinNo !== ''" class="flex al-center ju-center">
                  <div class="isExpatriate" v-if="record.taskId">
                    派
                  </div>{{ record.vinNo }}
                </div>
                <div v-show="record.model !== '' || record.brand !== ''">{{ record.brand + record.model }}</div>
                <div v-show="record.brand === '' && record.model === ''">暂无车型</div>
              </a-popover>
            </div>
            <div style="position: relative;" v-if="!record.taskId">
              <div v-show="record.vinNo === ''">暂无车牌</div>
              <div v-show="record.vinNo !== ''" class="flex al-center ju-center">
                <div class="isExpatriate" v-if="record.taskId">
                  派
                </div>{{ record.vinNo }}
              </div>
              <div v-show="record.model !== '' || record.brand !== ''">{{ record.brand + record.model }}</div>
              <div v-show="record.brand === '' && record.model === ''">暂无车型</div>
            </div>
          </template>
          <template #currentShipper="{ record }">
            <div style="position: relative;align-items: center;text-align: center;">
              <div v-show="record.despatchTime !== '' && record.despatchTime !== null" style="font-size:12px;color:#ad1540;">{{ record.despatchTime }}</div>
              <div v-show="record.dispatchType?.value !== 2">{{ record.currentShipper }}</div>
              <div v-show="record.dispatchType?.value === 2">{{ record.innerCarrier }}</div>
              <div style="font-size:12px;width:100%;color:#0C8226;" v-show="record.shareType !== '' && record.shareType !== null">{{ record.shareType }}</div>
            </div>
          </template>
          <template #address="{ record }">
            <div style="text-align: left;">
              <div>起：{{ record.startAddress }}</div>
              <div>终：{{ record.endAddress }}</div>
            </div>
          </template>
          <template #nowCityId="{ record }">
            <div style="text-align: left;">
              <div>当前：{{ record.nowCity }}</div>
              <div>下个：{{ record.nextCity }}</div>
              <div v-if="record.theoreticalArrivalTime !== null">{{ record.theoreticalArrivalTime }}</div>
            </div>
          </template>
          <template #taskData="{ record }">
            <div class="plant-content">
              <div v-if="record.taskData?.INSPECTION?.length <= 3" v-for="(item, index) in record.taskData?.INSPECTION" :key="index">
                <div v-if="item.transportType.value === 0">
                  <span style="margin:0 5px">{{ item.carrierName }}</span>
                  <span style="margin-right:5px">{{ item.driverName }}</span>
                  <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                  }}</span>
                  <span style="margin-right:5px">{{ '￥' + item.freight || '' }}</span>
                </div>
              </div>
              <a-popover v-if="record.taskData?.INSPECTION?.length > 3">
                <template #content>
                  <div v-for="(item, index) in record.taskData?.INSPECTION" :key="index">
                    <div v-if="item.transportType.value === 0">
                      <span style="margin:0 5px">{{ item.carrierName }}</span>
                      <span style="margin-right:5px">{{ item.driverName }}</span>
                      <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                      }}</span>
                      <span style="margin-right:5px">{{ '￥' + item.freight || '' }}</span>
                    </div>
                  </div>
                </template>
                <div v-if="record.taskData?.INSPECTION?.length > 3" v-for="(item, index) in record.taskData?.INSPECTION" :key="index">
                  <div v-if="item.transportType.value === 0">
                    <span style="margin:0 5px">{{ item.carrierName }}</span>
                    <span style="margin-right:5px">{{ item.driverName }}</span>
                    <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                    }}</span>
                    <span style="margin-right:5px">{{ '￥' + item.freight || '' }}</span>
                  </div>
                </div>
              </a-popover>
            </div>
          </template>
          <template #extractionTask="{ record }">
            <div class="plant-content">
              <div v-if="record.taskData">
                <div v-if="record.taskData?.PICK?.length <= 3" v-for="(item, index) in record.taskData?.PICK" :key="index">
                  <div v-if="item.transportType.value === 1">
                    <span style="margin:0 5px">{{ item.carrierName }}</span>
                    <span style="margin-right:5px">{{ item.driverName }}</span>
                    <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                    }}</span>
                    <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                  </div>
                </div>
                <a-popover v-if="record.taskData?.PICK?.length > 3">
                  <template #content>
                    <div v-for="(item, index) in record.taskData?.PICK" :key="index">
                      <div v-if="item.transportType.value === 1">
                        <span style="margin:0 5px">{{ item.carrierName }}</span>
                        <span style="margin-right:5px">{{ item.driverName }}</span>
                        <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                        }}</span>
                        <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                      </div>
                    </div>
                  </template>
                  <div v-if="record.taskData?.PICK?.length > 3" v-for="(item, index) in record.taskData?.PICK" :key="index">
                    <div v-for="(item, index) in record.taskData?.PICK" :key="index">
                      <div v-if="item.transportType.value === 1">
                        <span style="margin:0 5px">{{ item.carrierName }}</span>
                        <span style="margin-right:5px">{{ item.driverName }}</span>
                        <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                        }}</span>
                        <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                      </div>
                    </div>
                  </div>
                </a-popover>
              </div>
            </div>
          </template>
          <template #trunkTask="{ record }">
            <div class="plant-content">
              <div v-if="record.taskData">
                <div v-if="record.taskData?.TRANSPORT?.length <= 3" v-for="(item, index) in record.taskData?.TRANSPORT" :key="index">
                  <div v-if="item.transportType.value === 2">
                    <span style="margin:0 5px">{{ item.carrierName }}</span>
                    <span style="margin-right:5px">{{ item.driverName }}</span>
                    <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                    }}</span>
                    <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                  </div>
                </div>
                <a-popover v-if="record.taskData?.TRANSPORT?.length > 3">
                  <template #content>
                    <div v-for="(item, index) in record.taskData?.TRANSPORT" :key="index">
                      <div v-if="item.transportType.value === 2">
                        <span style="margin:0 5px">{{ item.carrierName }}</span>
                        <span style="margin-right:5px">{{ item.driverName }}</span>
                        <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                        }}</span>
                        <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                      </div>
                    </div>
                  </template>
                  <div v-if="record.taskData?.TRANSPORT?.length > 3" v-for="(item, index) in record.taskData?.TRANSPORT" :key="index">
                    <div v-if="item.transportType.value === 2">
                      <span style="margin:0 5px">{{ item.carrierName }}</span>
                      <span style="margin-right:5px">{{ item.driverName }}</span>
                      <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                      }}</span>
                      <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                    </div>
                  </div>
                </a-popover>
              </div>
            </div>
          </template>
          <template #deliveryTask="{ record }">
            <div class="plant-content">
              <div v-if="record.taskData">
                <div v-if="record.taskData?.DELIVERY?.length <= 3" v-for="(item, index) in record.taskData?.DELIVERY" :key="index">
                  <div v-if="item.transportType.value === 3">
                    <span style="margin:0 5px">{{ item.carrierName }}</span>
                    <span style="margin-right:5px">{{ item.driverName }}</span>
                    <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                    }}</span>
                    <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                  </div>
                </div>
                <a-popover v-if="record.taskData?.DELIVERY?.length > 3">
                  <template #content>
                    <div v-for="(item, index) in record.taskData?.DELIVERY" :key="index">
                      <div v-if="item.transportType.value === 3">
                        <span style="margin:0 5px">{{ item.carrierName }}</span>
                        <span style="margin-right:5px">{{ item.driverName }}</span>
                        <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                        }}</span>
                        <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                      </div>
                    </div>
                  </template>
                  <div v-if="record.taskData?.DELIVERY?.length > 3" v-for="(item, index) in record.taskData?.DELIVERY" :key="index">
                    <div v-for="(item, index) in record.taskData?.DELIVERY" :key="index">
                      <div v-if="item.transportType.value === 3">
                        <span style="margin:0 5px">{{ item.carrierName }}</span>
                        <span style="margin-right:5px">{{ item.driverName }}</span>
                        <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                        }}</span>
                        <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                      </div>
                    </div>
                  </div>
                </a-popover>
              </div>
            </div>
          </template>
          <template #taskNames="{ record }">
            <div v-if="record.taskNames">
              <div v-for="item in record.taskNames.split(',')" :key="item">{{ item }}</div>
            </div>
          </template>
          <template #report="{ record }">
            <div>
              <div><a @click="openDetailed(record)">查看明细</a></div>
            </div>
          </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { getStatisticsData, getVehicleTaskList } from '@/api/transport/transport'
import SearchList from '@/components/searchList'
import { addressTranString } from '@/utils/util'
import { cloneDeep } from 'lodash-es'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:visible'])
const statisticsList = ref([])
const activeKey = ref(0)
const orderStatus = ref(null)
const smallVehList = ref([])
const loading = ref(false)
const searchMsg = ref({})
const pagination = ref({
  showSizeChanger: true,
  showQuickJumper: true,
  current: 1,
  pageSize: 10,
  total: 0,
  showTotal: (totals) => `共 ${totals} 条`
})

const searchAdd = (e) => {
  let msg = cloneDeep(e)
  msg.startAddress = addressTranString(msg.startAddress)
  searchMsg.value = msg
  loadData()
}
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  loadData()
} 
const changePage = (val) => {
  pagination.value = val
  loadData()
}
const cancel = () => {
  emit('update:visible', false)
}

const changeTab = (item, index) => {
  pagination.value.current = 1
  orderStatus.value = item.orderStatus.value
  activeKey.value = index
  loadData()
}
const getStatistics = () => {
  getStatisticsData({}).then(res => {
    if (res.code === 10000) {
      if (res.data) {
        statisticsList.value = res.data
        orderStatus.value = res.data.length > 0 ? res.data[0].orderStatus?.value : ''
        loadData()
      }
    }
  })
}
const loadData = () => {
  loading.value = true
  getVehicleTaskList({
    ...searchMsg.value,
    dispatchId: '',
    transportType: orderStatus.value,
    current: pagination.value.current,
    size: pagination.value.pageSize,
  }).then(res => {
    if (res.code === 10000) {
      if (res.data) {
        smallVehList.value = res.data.records
        pagination.value.total = res.data.total
        smallVehList.value.forEach((item, index) => {
              item.NO = index + 1
            })
      } else message.error('暂无数据')
    }
  }).finally(() => {
    loading.value = false
  })
}
onMounted(() => {
  getStatistics()
})
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "startAddress",
    changeOn:true,
    fieldNamesValue:'label',
    width: 180,
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "endAddress",
    changeOn:true,
    width: 180,
  },
  {
    type: "address",
    placeholder: "当前城市",
    value: null,
    prop: "transit",
    changeOn:true,
    width: 180,
  },
  {
    type: "address",
    placeholder: "即将到达城市",
    value: null,
    prop: "nextCity",
    changeOn:true,
    width: 180,
  },
  {
    type: "select",
    placeholder: "车辆类型",
    value: null,
    prop: "truckType",
    width: 180,
    opt: [
      {
        id: 1,
        name: '大板车'
      },
      {
        id: 2,
        name: '小板车'
      },
      {
        id: 3,
        name: '货车'
      },
      {
        id: 4,
        name: '地跑'
      }
    ]
  },
  {
    type: "input",
    placeholder: "承运商名称",
    value: "",
    prop: "carrierName",
    width: 180,
  },
  {
    type: "input",
    placeholder: "大车车牌号",
    value: "",
    prop: "carNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: "",
    prop: "vehicleNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "派车人",
    value: "",
    prop: "dispatcher",
    width: 180,
  }
])
const columns = ref([
  {
    title: '序号',
    dataIndex: 'NO',
    align: 'center',
    fixed: 'left',
    width: '3%'
  },
  {
    title: '操作',
    align: 'center',
    fixed: 'left',
    width: '3%',
    slots: {
      customRender: 'operation'
    }
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    width: '6%',
    fixed: 'left',
    align: 'center',
    slots: {
      customRender: 'vinNo'
    }
  },
  {
    title: '发运进度',
    dataIndex: 'completeness',
    width: '12%',
    align: 'center',
    slots: {
      customRender: 'completeness'
    }
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus.label',
    width: '5%',
    align: 'center',
    slots: {
      customRender: 'orderStatus'
    }
  },
  {
    title: '订单号',
    dataIndex: 'orderId',
    width: '7%',
    align: 'center'
  },
  {
    title: '调度备注',
    dataIndex: 'remark',
    width: '10%',
    align: 'center',
    slots: {
      customRender: 'remark'
    }
  },
  {
    title: '预计装车时间',
    dataIndex: 'arrangeLoadTime',
    width: 140,
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'salesman',
    width: '4%',
    align: 'center'
  },
  {
    title: '下单时间',
    dataIndex: 'orderTime',
    width: 140,
    align: 'center',
    slots: {
      customRender: 'orderTime'
    }
  },

  {
    title: '调度员',
    dataIndex: 'currentShipper',
    width: 150,
    align: 'center',
    slots: {
      customRender: 'currentShipper'
    }
  },
  {
    title: '城市',
    dataIndex: 'nowCity',
    width: '8%',
    align: 'center',
    slots: {
      customRender: 'nowCityId'
    }
  },
  {
    title: '起-终',
    dataIndex: 'address',
    width: '10%',
    align: 'center',
    slots: {
      customRender: 'address'
    }
  },
  {
    title: '下个调度员',
    dataIndex: 'nextShipper',
    width: '6%',
    align: 'center'
  },
  {
    title: '验车任务',
    dataIndex: 'taskData',
    align: 'center',
    width: 300,
    slots: {
      customRender: 'taskData'
    }
  },
  {
    title: '提车任务',
    dataIndex: 'extractionTask',
    align: 'center',
    width: 300,
    slots: {
      customRender: 'extractionTask'
    }
  },
  {
    title: '干线任务',
    dataIndex: 'trunkTask',
    align: 'center',
    width: 300,
    slots: {
      customRender: 'trunkTask'
    }
  },
  {
    title: '送车任务',
    dataIndex: 'deliveryTask',
    width: 300,
    align: 'center',
    slots: {
      customRender: 'deliveryTask'
    }
  },
  {
    title: '外派任务',
    dataIndex: 'taskNames',
    width: 300,
    align: 'center',
    slots: {
      customRender: 'taskNames'
    }
  },
])
</script>

<style lang="less" scoped>
@import './index.less';
.tabBox {
  padding: 10px 0px 0px 0px;
  border-bottom: 1px solid #eee;
  background: #fff;
  color: #344563;
  font-size: 16px;
}

.itemBox {
  padding: 10px 5px;
  border-bottom: 3px solid transparent;
}

.active {
  color: #0066cc;
  border-bottom: 3px solid #0066cc;
}

.table-data {
  margin-top: 10px;
  padding: 15px 10px;
  background: #fff;
}
:deep(.ant-form-item) {
  margin-bottom: 10px !important;
}

:deep(.ant-upload-picture-card-wrapper) {
  width: auto !important;
  flex-direction: column;
}

:deep(.ant-upload-select-picture-card) {
  margin-bottom: 0 !important;
}

:deep(.ant-table-row) {
  position: relative;
}

:deep(.ant-table-row > td) {
  height: 63px !important;
}

:deep(.ant-table-row > th:last-child) {
  background-color: #f00 !important;
  position: absolute !important;
  right: 0;
  top: 0;
}

:deep(.ant-table-row:nth-child(even)) {
  background-color: #fafafa;
}

.ant-upload-text {
  background-color: #f0f2f5 !important;
  border: none;
  border-radius: 0 !important;
}

:deep(.ant-steps-icon-dot) {
  background-color: #0c8226 !important;
}

:deep(.ant-steps-item-icon) {
  margin-left: 9px !important;
}

:deep(.ant-steps-item-tail) {
  width: 112rpx !important;
  margin-left: 47% !important;
}

// 隐藏最后的线条
:deep(.ant-steps-item):nth-last-child(2) {
  .ant-steps-item-tail::after {
    display: none !important;
  }
}

:deep(.ant-steps-item-tail::after) {
  width: calc(100% - 11px) !important;
  height: 1px !important;
  // margin-left: -192% !important;
  background-color: #0c8226;
}

.batch-order-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  .ant-btn {
    background-color: #f0f2f5;
  }

  :deep(.ant-btn-primary) {
    color: #fff;
    border-color: #344563;
    background-color: #344563;
  }

  .ant-btn-primary:hover {
    color: #000;
    background-color: #fff;
  }
}

.animated-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #333;
  position: relative;
  overflow: hidden;
  animation: slide-in 2s forwards;
}

.data-paginate {
  position: absolute;
  right: 10px;
  top: -10px;
  z-index: 99;
}

.remark-content {
  width: 80%;
  max-height: 80px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.plant-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.isExpatriate {
  width: 16px;
  height: 16px;
  background-color: rgba(57, 106, 252, 1);
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
</style>