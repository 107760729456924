export const asyncRouterMap = [
  // system
  {
    path: '/system',
    name: 'system',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.system',
      icon: 'system'
    },
    children: [{
      path: '/system/position',
      name: 'position',
      component: () => import('@/views/system/position'),
      meta: {
        title: 'menu.system.position',
        keepAlive: false
      }
    },
    {
      path: '/system/employee',
      name: 'employee',
      component: () => import('@/views/system/employee'),
      meta: {
        title: 'menu.system.employee',
        keepAlive: true
      }
    },
    {
      path: '/system/officeArea',
      name: 'officeArea',
      component: () => import('@/views/system/officeArea'),
      meta: {
        title: 'menu.system.officeArea',
        keepAlive: false
      }
    },
    {
      path: '/system/role',
      name: 'role',
      component: () => import('@/views/system/role'),
      meta: {
        title: 'menu.system.role',
        keepAlive: false
      }
    },
    {
      path: '/system/organzition',
      name: 'organzition',
      component: () => import('@/views/system/organzition'),
      meta: {
        title: 'menu.system.organzition',
        keepAlive: false
      }
    },
    {
      path: '/system/vehiclemodel',
      name: 'vehiclemodel',
      component: () => import('@/views/system/vehiclemodel'),
      meta: {
        title: 'menu.system.vehiclemodel',
        keepAlive: false
      }
    }
    ]
  },
  // flow
  {
    path: '/flow',
    name: 'flow',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.flow',
      icon: 'flow'
    },
    children: [{
      path: '/flow/myFlow',
      name: 'myFlow',
      component: () => import('@/views/system/flow/myFlow'),
      meta: {
        title: 'menu.flow.myFlow',
        keepAlive: false
      }
    },
    {
      path: '/flow/myPending',
      name: 'myPending',
      component: () => import('@/views/system/flow/myPending'),
      meta: {
        title: 'menu.flow.myPending',
        keepAlive: true
      }
    },
    {
      path: '/flow/myProcessed',
      name: 'myProcessed',
      component: () => import('@/views/system/flow/myProcessed'),
      meta: {
        title: 'menu.flow.myProcessed',
        keepAlive: false
      }
    },
    {
      path: '/flow/copyList',
      name: 'copyList',
      component: () => import('@/views/system/flow/copyList'),
      meta: {
        title: 'menu.flow.copyList',
        keepAlive: false
      }
    }
    ]
  },
  // crm
  // {
  //   path: '/crm',
  //   name: 'crm',
  //   component: () => import('@/layouts/BasicLayout.vue'),
  //   meta: {
  //     title: 'menu.crm',
  //     icon: 'crm'
  //   },
  //   children: [
  //   {
  //   path: '/crm/customer',
  //   name: 'customer',
  //   component: () => import('@/views/crm/customer'),
  //   meta: {
  //     title: 'menu.crm.customer',
  //     keepAlive: false
  //   }
  // },
  // {
  //   path: '/crm/carrier',
  //   name: 'carrier',
  //   component: () => import('@/views/crm/carrier'),
  //   meta: {
  //     title: 'menu.crm.carrier',
  //     keepAlive: false
  //   }
  // }
  //   ]
  // },
  // order
  {
    path: '/order',
    name: 'order',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.order',
      icon: 'order'
    },
    children: [{
      path: '/order/expenseList',
      name: 'expenseList',
      component: () => import('@/views/order/expenseList'),
      meta: {
        title: 'menu.order.expenseList',
        keepAlive: true
      }
    }, {
      path: '/order/internal',
      name: 'internal',
      component: () => import('@/views/order/internal'),
      meta: {
        title: 'menu.order.internal',
        keepAlive: true
      }
    },
    {
      path: '/order/evaluationList',
      name: 'evaluationList',
      component: () => import('@/views/order/evaluationList/index'),
      meta: {
        title: 'menu.order.evaluationList',
        keepAlive: true
      }
    },
    {
      path: '/order/insurance',
      name: 'insurance',
      component: () => import('@/views/order/insurance'),
      meta: {
        title: 'menu.order.insurance',
        keepAlive: true
      }
    },
    {
      path: '/order/insuranceSZ',
      name: 'insuranceSZ',
      component: () => import('@/views/order/insuranceSZ'),
      meta: {
        title: 'menu.order.insurance', 
        keepAlive: true
      }
    },
    {
      path: '/order/insuranceOutOne',
      name: 'insuranceOutOne',
      component: () => import('@/views/order/insuranceOutOne'),
      meta: {
        title: 'menu.order.insuranceOutOne',
        keepAlive: true
      }
    },
    {
      path: '/order/insuranceOutTwo',
      name: 'insuranceOutTwo',
      component: () => import('@/views/order/insuranceOutTwo'),
      meta: {
        title: 'menu.order.insuranceOutTwo',
        keepAlive: true
      }
    },
    {
      path: '/order/Coffer',
      name: 'Coffer',
      component: () => import('@/views/order/Coffer'),
      meta: {
        title: 'menu.order.Coffer',
        keepAlive: false
      }
    },
    {
      path: '/order/Boffer',
      name: 'Boffer',
      component: () => import('@/views/order/Boffer'),
      meta: {
        title: 'menu.order.Boffer',
        keepAlive: false
      }
    }, {
      path: '/order/queryVehicle',
      name: 'queryVehicle',
      component: () => import('@/views/order/queryVehicle/index.vue'),
      meta: { title: 'menu.order.queryVehicle', keepAlive: true }
    }, {
      path: '/order/documentary',
      name: 'documentary',
      component: () => import('@/views/order/documentary/index.vue'),
      meta: { title: 'menu.order.documentary', keepAlive: true }
    }, {
      path: '/order/followOrderAbnormalList',
      name: 'followOrderAbnormalList',
      component: () => import('@/views/order/followOrderAbnormalList/index.vue'),
      meta: { title: 'menu.order.followOrderAbnormalList', keepAlive: true }
    }, {
      path: '/order/virtualNumber',
      name: 'virtualNumber',
      component: () => import('@/views/order/virtualNumber/index.vue'),
      meta: { title: 'menu.order.virtualNumber', keepAlive: true }
    }]
  },
  // transport
  {
    path: '/transport',
    name: 'transport',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.transport',
      icon: 'transport'
    },
    children: [{
      path: '/transport/car',
      name: 'car',
      component: () => import('@/views/transport/car'),
      meta: {
        title: 'menu.transport.car',
        keepAlive: false
      }
    },
    //   {
    //   path: '/transport/inspectionList', // 验车列表
    //   name: 'inspectionList',
    //   component: () => import('@/views/transport/inspectionList'),
    //   meta: {
    //     title: 'menu.transport.inspectionList',
    //     keepAlive: false
    //   }
    // },

    // {
    //   path: '/transport/bigcar', // 运车列表
    //   name: 'bigcar',
    //   component: () => import('@/views/transport/bigcar'),
    //   meta: {
    //     title: 'menu.transport.bigcar',
    //     keepAlive: true
    //   }
    // },
    // {
    //   path: '/transport/complement',
    //   name: 'complement',
    //   component: () => import('@/views/transport/complement'),
    //   meta: {
    //     title: 'menu.transport.complement',
    //     keepAlive: false
    //   }
    // },
    {
      path: '/transport/gps',
      name: 'gps',
      component: () => import('@/views/transport/gps'),
      meta: {
        title: 'menu.transport.gps',
        keepAlive: false
      }
    },
    // {
    //   path: '/transport/lineAdd',
    //   name: 'lineAdd',
    //   component: () => import('@/views/transport/lineAdd'),
    //   meta: {
    //     title: 'menu.transport.lineAdd',
    //     keepAlive: false
    //   }
    // },
    // {
    //   path: '/transport/line',
    //   name: 'line',
    //   component: () => import('@/views/transport/line'),
    //   meta: {
    //     title: 'menu.transport.line',
    //     keepAlive: false
    //   }
    // },
    {
      path: '/transport/vehicle',
      name: 'vehicle',
      component: () => import('@/views/transport/vehicle'),
      meta: {
        title: 'menu.transport.vehicle',
        keepAlive: false
      }
    },
    // 大车列表
    {
      path: '/transport/transportList',
      name: 'transportList',
      component: () => import('@/views/transport/transportList'),
      meta: {
        title: 'menu.transport.transportList',
        keepAlive: false
      }
    },
    // {
    //   path: '/transport/offer',
    //   name: 'offer',
    //   component: () => import('@/views/transport/offer'),
    //   meta: {
    //     title: 'menu.transport.offer',
    //     keepAlive: false
    //   }
    // },
    {
      path: '/transport/offerXls',
      name: 'offerXls',
      component: () => import('@/views/transport/offerXls'),
      meta: {
        title: 'menu.transport.offerXls',
        keepAlive: false
      }
    },
    {
      path: '/transport/abnormalReport',
      name: 'abnormalReport',
      component: () => import('@/views/transport/abnormalReport'),
      meta: {
        title: 'menu.transport.abnormalReport',
        keepAlive: false
      }
    },
    {
      path: '/transport/qualityLoss',
      name: 'qualityLoss',
      component: () => import('@/views/transport/qualityLoss'),
      meta: {
        title: 'menu.transport.qualityLoss',
        keepAlive: false
      }
    }
    ]
  },
  // dataCenter
  {
    path: '/dataCenter',
    name: 'dataCenter',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.dataCenter',
      icon: 'dataCenter'
    },
    children: [
      {
        path: '/dataCenter/punctual',
        name: 'punctual',
        component: () => import('@/views/dataCenter/punctual/index.vue'),
        meta: { title: 'menu.dataCenter.punctual', keepAlive: false }
      },
      {
        path: '/dataCenter/monitoring',
        name: 'monitoring',
        component: () => import('@/views/dataCenter/monitoring/index.vue'),
        meta: { title: 'menu.dataCenter.monitoring', keepAlive: false }
      },
      {
        path: '/dataCenter/prescription',
        name: 'prescription',
        component: () => import('@/views/dataCenter/prescription'),
        meta: {
          title: 'menu.dataCenter.prescription',
          keepAlive: false
        }
      },
      {
        path: '/dataCenter/regionalStatistics',
        name: 'regionalStatistics',
        component: () => import('@/views/dataCenter/regionalStatistics'),
        meta: {
          title: 'menu.dataCenter.regionalStatistics',
          keepAlive: false
        }
      },
      {
        path: '/finance/invoiceStatistics',
        name: 'invoiceStatistics',
        component: () => import('@/views/finance/invoiceStatistics/index.vue'),
        meta: {
          title: 'menu.finance.invoiceStatistics',
          keepAlive: false
        }
      },
      {
        path: '/dataCenter/vehicleQuota',
        name: 'vehicleQuota',
        component: () => import('@/views/dataCenter/vehicleQuota'),
        meta: {
          title: 'menu.dataCenter.vehicleQuota',
          keepAlive: false
        }
      },
      {
        path: '/dataCenter/vehicleStock',
        name: 'stock',
        component: () => import('@/views/dataCenter/vehicleStock'),
        meta: {
          title: 'menu.dataCenter.stock',
          keepAlive: false
        }
      },
      {
        path: '/dataCenter/estimatedProfit',
        name: 'estimatedProfit',
        component: () => import('@/views/dataCenter/estimatedProfit'),
        meta: {
          title: 'menu.dataCenter.estimatedProfit',
          keepAlive: false
        }
      },
      {
        path: '/dataCenter/vehicleStockKanBan',
        name: 'stockList',
        component: () => import('@/views/dataCenter/vehicleStockKanBan'),
        meta: {
          title: 'menu.dataCenter.stockKanBan',
          keepAlive: false
        }
      },
      {
        path: '/dataCenter/vehicleTransKanBan',
        name: 'transKanbBan',
        component: () => import('@/views/dataCenter/vehicleTransKanBan'),
        meta: {
          title: 'menu.dataCenter.transKanbBan',
          keepAlive: false
        }
      },
      {
        path: '/dataCenter/carrierStatistics',
        name: 'carrierStatistics',
        component: () => import('@/views/dataCenter/carrierStatistics'),
        meta: {
          title: 'menu.dataCenter.carrierStatistics',
          keepAlive: false
        }
      },
      {
        path: '/dataCenter/massLossStatistics',
        name: 'massLossStatistics',
        component: () => import('@/views/dataCenter/massLossStatistics'),
        meta: {
          title: 'menu.dataCenter.massLossStatistics',
          keepAlive: false
        }
      },
      {
        path: '/dataCenter/lineStatistics',
        name: 'lineStatistics',
        component: () => import('@/views/dataCenter/lineStatistics'),
        meta: {
          title: 'menu.dataCenter.lineStatistics',
          keepAlive: false
        }
      },
      {
        path: '/dataCenter/privateSeaKanBan',
        name: 'privateSeaKanBan',
        component: () => import('@/views/dataCenter/privateSeaKanBan/index.vue'),
        meta: { title: 'menu.dataCenter.privateSeaKanBan', keepAlive: false }
      },
      {
        path: '/dataCenter/cooperativeKanBan',
        name: 'cooperativeKanBan',
        component: () => import('@/views/dataCenter/cooperativeKanBan/index.vue'),
        meta: { title: 'menu.dataCenter.cooperativeKanBan', keepAlive: false }
      },
      {
        path: '/dataCenter/customerStatistics',
        name: 'customerStatistics',
        component: () => import('@/views/dataCenter/customerStatistics/index.vue'),
        meta: { title: 'menu.dataCenter.customerStatistics', keepAlive: false }
      },
      // 2024年1月9日11:23:35 LZM移除菜单
      // {
      //   path: '/dataCenter/carrier',
      //   name: 'carrierKanBan',
      //   component: () => import('@/views/dataCenter/carrier'),
      //   meta: {
      //     title: 'menu.dataCenter.carrier',
      //     keepAlive: false
      //   }
      // },
      // 2024年1月4日14:20:48 LZM移除菜单
      // {
      //   path: '/dataCenter/regionalCarrier',
      //   name: 'regionalCarrier',
      //   component: () => import('@/views/dataCenter/regionalCarrier'),
      //   meta: {
      //     title: 'menu.dataCenter.regionalCarrier',
      //     keepAlive: true
      //   }
      // },
      // {
      //   path: '/dataCenter/carrierList',
      //   name: 'carrierList',
      //   component: () => import('@/views/dataCenter/carrierList'),
      //   meta: {
      //     title: 'menu.dataCenter.carrierList',
      //     keepAlive: false
      //   }
      // },
      // {
      //   path: '/dataCenter/customer',
      //   name: 'customerKanBan',
      //   component: () => import('@/views/dataCenter/customer'),
      //   meta: {
      //     title: 'menu.dataCenter.customer',
      //     keepAlive: false
      //   }
      // },
      {
        path: '/dataCenter/taskStatistics',
        name: 'taskStatistics',
        component: () => import('@/views/dataCenter/taskStatistics/index.vue'),
        meta: { title: 'menu.dataCenter.taskStatistics', keepAlive: false }
      },
      {
        path: '/dataCenter/abnormalStatistics',
        name: 'abnormalStatistics',
        component: () => import('@/views/dataCenter/abnormalStatistics/index.vue'),
        meta: { title: 'menu.dataCenter.abnormalStatistics', keepAlive: false }
      }
    ]
  },
  // finance
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.finance',
      icon: 'finance'
    },
    children: [
      {
        path: '/finance/bodyConfiguration',
        name: 'bodyConfiguration',
        component: () => import('@/views/finance/bodyConfiguration'),
        meta: {
          title: 'menu.finance.bodyConfiguration',
          keepAlive: false
        }
      },
      {
        path: '/finance/InitiateBilling',
        name: 'InitiateBilling',
        component: () => import('@/views/finance/InitiateBilling'),
        meta: {
          title: 'menu.finance.InitiateBilling',
          keepAlive: false
        }
      },
      {
      path: '/finance/compositeReport',
      name: 'compositeReport',
      component: () => import('@/views/finance/compositeReport'),
      meta: {
        title: 'menu.finance.compositeReport',
        keepAlive: false
      }
    },
    {
      path: '/finance/orderReport',
      name: 'orderReport',
      component: () => import('@/views/finance/orderReport'),
      meta: {
        title: 'menu.finance.orderReport',
        keepAlive: false
      }
    },
    {
      path: '/finance/transReport',
      name: 'transReport',
      component: () => import('@/views/finance/transReport'),
      meta: {
        title: 'menu.finance.transReport',
        keepAlive: false
      }
    },
    {
      path: '/finance/IncomeExpenditureSituation',
      name: 'incomeExpenditureSituation',
      component: () => import('@/views/finance/IncomeExpenditureSituation'),
      meta: {
        title: 'menu.finance.incomeExpenditureSituation',
        keepAlive: false
      }
    },
    {
      path: '/finance/income',
      name: 'income',
      component: () => import('@/views/finance/income'),
      meta: {
        title: 'menu.finance.income',
        keepAlive: false
      }
    },
    // {
    //   path: '/finance/settlement/carrier',
    //   name: 'settlementCarrier',
    //   component: () => import('@/views/finance/settlement/Carrier'),
    //   meta: {
    //     title: 'menu.finance.settlement.carrier',
    //     keepAlive: false
    //   }
    // },
    // {
    //   path: '/finance/settlement/customer',
    //   name: 'settlementCustomer',
    //   component: () => import('@/views/finance/settlement/Customer'),
    //   meta: {
    //     title: 'menu.finance.settlement.customer',
    //     keepAlive: false
    //   }
    // },
    // {
    //   path: '/finance/settlement/vipCustomer',
    //   name: 'settlementVipCustomer',
    //   component: () => import('@/views/finance/settlement/VipCustomer'),
    //   meta: {
    //     title: 'menu.finance.settlement.vipCustomer',
    //     keepAlive: false
    //   }
    // },
    // 2024年1月4日14:21:43 LZM移除菜单
    // {
    //   path: '/finance/settlement/tip',
    //   name: 'settlementTip',
    //   component: () => import('@/views/finance/settlement/Tip'),
    //   meta: {
    //     title: 'menu.finance.settlement.tip',
    //     keepAlive: false
    //   }
    // },
    {
      path: '/finance/settlement/other',
      name: 'settlementOther',
      component: () => import('@/views/finance/settlement/Other'),
      meta: {
        title: 'menu.finance.settlement.other',
        keepAlive: false
      }
    },
    {
      path: '/finance/bill/carrier',
      name: 'carrierBill',
      component: () => import('@/views/finance/bill/Carrier'),
      meta: {
        title: 'menu.finance.bill.carrier',
        keepAlive: false
      }
    },
    {
      path: '/finance/archive/businessProfit',
      name: 'profit',
      component: () => import('@/views/finance/archive/businessProfit'),
      meta: {
        title: 'menu.finance.archive.businessProfit',
        keepAlive: false
      }
    },
    {
      path: '/finance/archive/transProfit',
      name: 'arichive',
      component: () => import('@/views/finance/archive/transProfit'),
      meta: {
        title: 'menu.finance.archive.transProfit',
        keepAlive: false
      }
    },
    {
      path: '/finance/archive/accountAge',
      name: 'accountAge',
      component: () => import('@/views/finance/archive/accountAge'),
      meta: {
        title: 'menu.finance.archive.accountAge',
        keepAlive: false
      }
    },
    {
      path: '/finance/payList',
      name: 'payList',
      component: () => import('@/views/finance/payList'),
      meta: {
        title: 'menu.finance.archive.payList',
        keepAlive: false
      }
    }
    ]
  },
  // inquiry
  {
    path: '/inquiry',
    name: 'inquiry',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.inquiry',
      icon: 'pingtaiguanli'
    },
    children: [{
      path: '/inquiry/inquiryList',
      name: 'inquiryList',
      component: () => import('@/views/inquiry/inquiryList'),
      meta: {
        title: 'menu.inquiry.inquiryList',
        keepAlive: false
      }
    }, {
      path: '/inquiry/clueList',
      name: 'clueList',
      component: () => import('@/views/inquiry/clueList'),
      meta: {
        title: 'menu.inquiry.clueList',
        keepAlive: false
      }
    },
    {
      path: '/inquiry/orderList',
      name: 'orderList',
      component: () => import('@/views/inquiry/orderList'),
      meta: {
        title: 'menu.inquiry.orderList',
        keepAlive: false
      }
    }
    ]
  },
  // ops
  {
    path: '/opsCenter',
    name: 'opsCenter',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.opsCenter',
      icon: 'opsCenter'
    },
    children: [{
      path: '/opsCenter/opsApplication',
      name: 'opsApplication',
      component: () => import('@/views/opsCenter/opsApplication'),
      meta: {
        title: 'menu.opsCenter.opsApplication',
        keepAlive: false
      }
    },
    {
      path: '/opsCenter/dailyPoster',
      name: 'dailyPoster',
      component: () => import('@/views/opsCenter/dailyPoster'),
      meta: {
        title: 'menu.opsCenter.dailyPoster',
        keepAlive: false
      }
    },
    {
      path: '/opsCenter/opsRevise',
      name: 'opsRevise',
      component: () => import('@/views/opsCenter/opsRevise'),
      meta: {
        title: 'menu.opsCenter.opsRevise',
        keepAlive: false
      }
    }
    ]
  },
  //知识库repository
  {
    path: '/repository',
    name: 'repository',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: {
      title: 'menu.repository',
      icon: 'opsCenter'
    },
    children: [{  // 类目配置
      path: '/repository/categoryConfiguration',
      name: 'categoryConfiguration',
      component: () => import('@/views/repository/categoryConfiguration/categoryConfiguration.vue'),
      meta: {
        title: 'menu.repository.categoryConfiguration',
        keepAlive: false
      }
    },
    {   //文章管理
      path: '/repository/articleManagement',
      name: 'articleManagement',
      component: () => import('@/views/repository/articleManagement/articleManagement.vue'),
      meta: {
        title: 'menu.repository.articleManagement',
        keepAlive: false
      }
    },
    {    //文章列表
      path: '/repository/articleList',
      name: 'articleList',
      component: () => import('@/views/repository/articleList/articleList.vue'),
      meta: {
        title: 'menu.repository.repository',
        keepAlive: false
      }
    },
    {    //反馈列表
      path: '/repository/feedbackList',
      name: 'feedbackList',
      component: () => import('@/views//repository/feedbackList/feedbackList.vue'),
      meta: {
        title: 'menu.repository.feedbackList',
        keepAlive: false
      }
    },
    ]
  },

  // market
  {
    path: '/marketing',
    name: 'marketing',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: { title: 'menu.marketing', icon: 'yingxiaozhongxin', keepAlive: false },
    children: [{
      path: '/marketing/modList',
      name: 'modList',
      component: () => import('@/views/marketing/modList/index.vue'),
      meta: { title: '模板列表', keepAlive: false }
    },
    //  {
    //   path: '/marketing/useMod',
    //   name: 'useMod',
    //   component: () => import('@/views/marketing/useMod/index.vue'),
    //   meta: { title: '投放中模板', keepAlive: false }
    // },
    {
      path: '/marketing/channel',
      name: 'channel',
      component: () => import('@/views/marketing/channel/index.vue'),
      meta: { title: '渠道管理', keepAlive: false }
    },
    {
      path: '/marketing/statistics',
      name: 'statistics',
      component: () => import('@/views/marketing/statistics/index.vue'),
      meta: { title: '营销统计', keepAlive: false }
    }, {
      path: '/crmManagement/drainageClue',
      name: 'drainageClue',
      component: () => import('@/views/crmManagement/drainageClue/index.vue'),
      meta: { title: '引流线索', keepAlive: false }
    }]
  },
  // crmManagement
  {
    path: '/crmManagement',
    name: 'crmManagement',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: { title: 'menu.crmManagement', icon: 'crmkehuguanli', keepAlive: false },
    children: [{
      path: '/crmManagement/setRules',
      name: 'setRules',
      component: () => import('@/views/crmManagement/setRules/index.vue'),
      meta: { title: '规则设置', keepAlive: false }
    }, {
      path: '/crmManagement/comSea',
      name: 'comSea',
      component: () => import('@/views/crmManagement/comSea/index.vue'),
      meta: { title: '公海客户', keepAlive: false }
    }, {
      path: '/crmManagement/privateSea',
      name: 'privateSea',
      component: () => import('@/views/crmManagement/privateSea/index.vue'),
      meta: { title: '私海客户', keepAlive: false }
    }, {
      path: '/crmManagement/approveContract',
      name: 'approveContract',
      component: () => import('@/views/crmManagement/approveContract/index.vue'),
      meta: { title: '审批合同', keepAlive: false }
    }, {
      path: '/crmManagement/cooperativeCustomers',
      name: 'cooperativeCustomers',
      component: () => import('@/views/crmManagement/cooperativeCustomers/index.vue'),
      meta: { title: '合作客户', keepAlive: true }
    }, {
      path: '/crmManagement/invalidCustomer',
      name: 'invalidCustomer',
      component: () => import('@/views/crmManagement/invalidCustomer/index.vue'),
      meta: { title: '无效客户', keepAlive: true }
    }, {
      path: '/crmManagement/temporaryCustomer',
      name: 'temporaryCustomer',
      component: () => import('@/views/crmManagement/temporaryCustomer/index.vue'),
      meta: { title: '临时客户', keepAlive: true }
    }, {
      path: '/crmManagement/crmOrderList',
      name: 'crmOrderList',
      component: () => import('@/views/crmManagement/crmOrderList'),
      meta: { title: 'menu.crmManagement.crmOrderList', keepAlive: false }
    }, {
      path: '/crmManagement/crmInquiryList',
      name: 'crmInquiryList',
      component: () => import('@/views/crmManagement/crmInquiryList'),
      meta: { title: 'menu.crmManagement.crmInquiryList', keepAlive: false }
    }, {
      path: '/crmManagement/weChatUser',
      name: 'weChatUser',
      component: () => import('@/views/crmManagement/weChatUser'),
      meta: { title: 'menu.crmManagement.weChatUser', keepAlive: false }
    }, {
      path: '/crmManagement/relevance',
      name: 'relevance',
      component: () => import('@/views/crmManagement/relevance'),
      meta: { title: 'menu.crmManagement.relevance', keepAlive: false }
    }]
  },
  // carrier
  {
    path: '/storeCarrier',
    name: 'storeCarrier',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: { title: 'menu.storeCarrier', icon: 'shangjia1' },
    children: [{
      path: '/storeCarrier/storeCarrierList',
      name: 'storeCarrierList',
      component: () => import('@/views/carrier/carrierList'),
      meta: { title: 'menu.storeCarrier.storeCarrierList', keepAlive: false }
    }, {
      path: '/storeCarrier/storeCarrierApprove',
      name: 'storeCarrierApprove',
      component: () => import('@/views/carrier/carrjerLisTexamine/index.vue'),
      meta: { title: 'menu.storeCarrier.storeCarrierApprove', keepAlive: false }
    }, {
      path: '/storeCarrier/lineList',
      name: 'storeCarrierLineList',
      component: () => import('@/views/carrier/lineList/index.vue'),
      meta: { title: 'menu.storeCarrier.lineList', keepAlive: true }
    }, {
      path: '/storeCarrier/strongholdLineList',
      name: 'storeCarrierStrongholdLineList',
      component: () => import('@/views/carrier/strongholdLineList/index.vue'),
      meta: { title: 'menu.storeCarrier.strongholdLineList', keepAlive: true }
    }]
  },
  // store
  // {
  //   path: '/store',
  //   name: 'store',
  //   component: () => import('@/layouts/BasicLayout.vue'),
  //   meta: { title: 'menu.store', icon: 'zhiyuandidian6' },
  //   children: [{
  //     path: '/store/storeUse',
  //     name: 'storeUse',
  //     component: () => import('@/views/store/storeUse/index.vue'),
  //     meta: { title: 'menu.store.storeUse', keepAlive: true }
  //   }]
  // },
  // transportCapacity
  {
    path: '/transportCapacity',
    name: 'transportCapacity',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: { title: 'menu.transportCapacity', icon: 'basesettingdepartment' },
    children: [
      //   {
      //   path: '/transportCapacity/transportUse',
      //   name: 'transportUse',
      //   component: () => import('@/views/transportCapacity/transportUse/index.vue'),
      //   meta: { title: 'menu.transportCapacity.transportUse', keepAlive: true }
      // },
      {
        path: '/transportCapacity/dispatchDeploy',
        name: 'dispatchDeploy',
        component: () => import('@/views/transportCapacity/dispatchDeploy/index.vue'),
        meta: { title: 'menu.transportCapacity.dispatchDeploy', keepAlive: false }
      }
    ]
  },
  // insurance
  // {
  //   path: '/insurance',
  //   name: 'insuranceList',
  //   component: () => import('@/layouts/BasicLayout.vue'),
  //   meta: { title: 'menu.insurance', icon: 'baoxianchanpin', keepAlive: false },
  //   children: [
  //     {
  //       path: '/insurance/user',
  //       name: 'insuranceListUser',
  //       component: () => import('@/views/Insurance/user/index.vue'),
  //       meta: { title: 'menu.insurance.user', keepAlive: false }
  //     },
  //     {
  //       path: '/insurance/order',
  //       name: 'insuranceListOrder',
  //       component: () => import('@/views/Insurance/order/index.vue'),
  //       meta: { title: 'menu.insurance.order', keepAlive: false }
  //     }
  //   ]
  // },
  // achievementAudit
  {
    path: '/achievement',
    name: 'achievement',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: { title: 'menu.achievement', icon: 'jixiaokaoping', keepAlive: false },
    children: [
      {
        path: '/achievement/achievementAudit',
        name: 'achievementAudit',
        component: () => import('@/views/achievement/achievementAudit/index.vue'),
        meta: { title: 'menu.achievement.achievementAudit', keepAlive: false }
      },
      {
        path: '/achievement/mineCustom',
        name: 'mineCustom',
        component: () => import('@/views/achievement/mineCustom/index.vue'),
        meta: { title: 'menu.achievement.mineCustom', keepAlive: false }
      },
      {
        path: '/achievement/standardList',
        name: 'standardList',
        component: () => import('@/views/achievement/standardList/index.vue'),
        meta: { title: 'menu.achievement.standardList', keepAlive: false }
      }
    ]
  },
  // LineList
  {
    path: '/lineList',
    name: 'lineList',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: { title: 'menu.lineList', icon: 'xianlu', keepAlive: false },
    children: [
      {
        path: '/lineList/optimalLine',
        name: 'optimalLine',
        component: () => import('@/views/lineList/optimalLine/index.vue'),
        meta: { title: 'menu.lineList.optimalLine', keepAlive: true }
      }, {
        path: '/lineList/optimalLineBEnd',
        name: 'optimalLineBEnd',
        component: () => import('@/views/lineList/optimalLineBEnd/index.vue'),
        meta: { title: 'menu.lineList.optimalLineBEnd', keepAlive: true }
      }, {
        path: '/lineList/optimalLineCEnd',
        name: 'optimalLineCEnd',
        component: () => import('@/views/lineList/optimalLineCEnd/index.vue'),
        meta: { title: 'menu.lineList.optimalLineCEnd', keepAlive: true }
      }, {
        path: '/lineList/optimalLineExternal',
        name: 'optimalLineExternal',
        component: () => import('@/views/lineList/optimalLineExternal/index.vue'),
        meta: { title: 'menu.lineList.optimalLineExternal', keepAlive: false }
      }, {
        path: '/lineList/optimalLineSame',
        name: 'optimalLineSame',
        component: () => import('@/views/lineList/optimalLineSame/index.vue'),
        meta: { title: 'menu.lineList.optimalLineSame', keepAlive: true }
      }, {
        path: '/lineList/grade',
        name: 'optimalLineGrade',
        component: () => import('@/views/lineList/grade/index.vue'),
        meta: { title: 'menu.lineList.grade', keepAlive: false }
      }, {
        path: '/lineList/gradeBEnd',
        name: 'optimalLineGradeBEnd',
        component: () => import('@/views/lineList/gradeBEnd/index.vue'),
        meta: { title: 'menu.lineList.gradeBEnd', keepAlive: false }
      }, {
        path: '/lineList/gradeCEnd',
        name: 'optimalLineGradeCEnd',
        component: () => import('@/views/lineList/gradeCEnd/index.vue'),
        meta: { title: 'menu.lineList.gradeCEnd', keepAlive: false }
      }
    ]
  },

  // BEnd
  {
    path: '/BEnd',
    name: 'BEnd',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: { title: '车小象管理', icon: 'a-ziyuan11', keepAlive: false },
    children: [
      {
        path: '/BEnd/applet',
        name: 'BEndApplet',
        component: () => import('@/views/BEnd/applet'),
        meta: { title: '小程序用户', keepAlive: false }
      },
      {
        path: '/BEnd/comSea',
        name: 'BEndComSea',
        component: () => import('@/views/BEnd/comSea'),
        meta: { title: '小程序公海', keepAlive: false }
      },
      {
        path: '/BEnd/order',
        name: 'BEndOrder',
        component: () => import('@/views/BEnd/order'),
        meta: { title: '订单列表', keepAlive: false }
      },
      {
        path: '/BEnd/inquiry',
        name: 'BEndInquiry',
        component: () => import('@/views/BEnd/inquiry'),
        meta: { title: '询价单列表', keepAlive: false }
      },
      {
        path: '/BEnd/coupon',
        name: 'BEndCoupon',
        component: () => import('@/views/BEnd/coupon'),
        meta: { title: '优惠券', keepAlive: false }
      },
      {
        path: '/BEnd/couponHistory',
        name: 'BEndCouponHistory',
        component: () => import('@/views/BEnd/couponHistory'),
        meta: { title: '优惠券历史', keepAlive: false }
      },
      {
        path: '/BEnd/extension',
        name: 'BEndExtension',
        component: () => import('@/views/BEnd/extension'),
        meta: { title: '推广人', keepAlive: false }
      }
    ]
  },
  // yst
  {
    path: '/yst',
    name: 'yst',
    component: () => import('@/layouts/BasicLayout.vue'),
    meta: { title: 'menu.yst', icon: 'jichupaas', keepAlive: false },
    children: [
      {
        path: '/storeCarrier/paymentList',
        name: 'paymentList',
        component: () => import('@/views/yst/paymentList/index.vue'),
        meta: { title: 'menu.yst.paymentList', keepAlive: false }
      },
      {
        path: '/storeCarrier/memberPageYst',
        name: 'memberPageYst',
        component: () => import('@/views/yst/memberPageYst/index.vue'),
        meta: { title: 'menu.yst.memberPageYst', keepAlive: true }
      },
      {
        path: '/payListYst',
        name: 'payListYst',
        component: () => import('@/views/yst/payListYst'),
        meta: {
          title: 'menu.yst.payListYst',
          keepAlive: true
        }
      },
      {
        path: '/collectMoneyListYst',
        name: 'collectMoneyListYst',
        component: () => import('@/views/yst/collectMoneyListYst'),
        meta: {
          title: 'menu.yst.collectMoneyListYst',
          keepAlive: true
        }
      },
      {
        path: '/settlementAccount',
        name: 'settlementAccount',
        component: () => import('@/views/yst/settlementAccount'),
        meta: {
          title: 'menu.yst.settlementAccount',
          keepAlive: true
        }
      },
      {
        path: '/payPlatform',
        name: 'payPlatform',
        component: () => import('@/views/yst/payPlatform'),
        meta: {
          title: 'menu.yst.payPlatform',
          keepAlive: true
        }
      }
    ]
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [{
  // path: '/transport/regionalStatistics',
  // name: 'regionalStatistics',
  // component: () => import('@/views/transport/regionalStatistics'),
  // meta: {
  //   title: 'menu.transport.regionalStatistics',
  //   keepAlive: false
  // }
}]
