<template>
  <!-- 验车照片 -->
  <a-modal v-model:visible="inspectionImg" title="上传车辆照片" :width="1200" footer="" :destroyOnClose="true"
    @cancel="closeWindow">
    <a-tabs v-if="activeKey" v-model:activeKey="activeKey" size="large" :tabBarGutter="40" style="flex:1"
      @change="tabsChange">
      <a-tab-pane :key="1" :tab="'常规照片 ' + normalTotal + '张'">
        <a-row :gutter="0">
          <VInfos :info="orderVehicleId" @injuryPage="normalPage" :special="1" :inspectType=2 order=1
            @inspectionLoadData="loadInspectList" />
        </a-row>
        <a-row v-if="totalNormalInfo > 0" :gutter="25">
          <a-pagination v-if="totalNormalInfo > 0" v-model:current="normalInfoCurrent" :total="totalNormalInfo"
            :pageSize="5" show-less-items @change="NormalInfoChange" />
        </a-row>
        <a-row :gutter="25">
          <a-col :span="4" v-for="item in carNormalInfos" :key="item.id">
            <ValidateInfo :info="item" @inspectionLoadData="loadInspectList" @change="imageChange" order=2 />
          </a-col>
        </a-row>
        <a-row :gutter="[25, 25]">
          <a-col :span="4" v-for=" item in carValidateInfos" :key="item.id">
            <ValidateInfo v-if="item.inspectType?.value !== 128" :info="item" @inspectionLoadData="loadInspectList"
              @change="imageChange" order=1 />
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane :key="2" :tab="'车伤照片 ' + totalValidateInfo + '张'">
        <a-row :gutter="25">
          <a-col :span="4" v-for="item in carInjuryInfos" :key="item.id">
            <ValidateInfo :info="item" @inspectionLoadData="loadInspectList" @change="imageChange" order=2 />
          </a-col>
        </a-row>
        <a-row v-if="totalValidateInfo > 0" :gutter="25">
          <a-pagination v-if="totalValidateInfo > 0" v-model:current="validateInfoCurrent" :total="totalValidateInfo"
            :pageSize="5" show-less-items @change="ValidateInfoChange" />
        </a-row>
        <VInfos :info="orderVehicleId" @injuryPage="injuryPage" :special="1" :inspectType=1 order=1
          @inspectionLoadData="loadInspectList" />
      </a-tab-pane>
      <a-tab-pane :key="3" :tab="ware.total === 0 ? '入盘库照片' : '入盘库照片 ' + ware.total + '张'">
        <a-row :gutter="25">
          <a-col :span="4" v-for="item in ware.record" :key="item.id">
            <ValidateInfo :info="item" @inspectionLoadData="loadInspectList" order=3 />
          </a-col>
        </a-row>
        <a-row v-if="ware.total > 0" :gutter="25">
          <a-pagination v-if="ware.total > 0" v-model:current="ware.current" :total="ware.total" :pageSize="5"
            show-less-items @change="ValidateInfoChange" />
        </a-row>
      </a-tab-pane>
      <a-tab-pane :key="4" tab="上板视频">
        <div class="video-content">
          <div v-if="videoData.fileUrl" style="display:flex;margin-bottom:100px">
            <div class="video-list" style="margin-right:5px">
              <div style="height:100%;display:flex;flex-direction: column;">
                <video style="width:200px;height:100%" controls :src="videoData.fileUrl"></video>
                <div>
                  <a-textarea v-model:value="videoData.fileRemark" placeholder="填写备注" :maxlength="200" showCount />
                  <div style="margin-top:-25px">
                    <a-button type="link" @click="save">
                      保存备注
                    </a-button>
                    <a-button type="link" @click="onCancel">
                      取消
                    </a-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="videoList.length > 0 && inspectionImg">
            <div class="video-list" v-for="(item, index) in videoList" :key="index">
              <video style="width:200px;height:100%" controls :src="item.fileUrl"></video>
            </div>
          </div>
          <div class="video-div">
            <a-upload list-type="picture-card" v-model:fileList="videoFileList" accept="video/*" :show-upload-list="false"
              @change="videoFileListChange" :before-upload="videoBeforeUpload" style="width:auto">
              <div>
                <plus-outlined></plus-outlined>
                <div class="ant-upload-text">选择视频</div>
              </div>
            </a-upload>
            <div style="width:150px">
              <a-button @click="uploadVideo">上传视频</a-button>
              <a-button @click="deleteVideo" v-if="videoList.length > 0" :disabled="videoFileList.length <= 0">删除视频</a-button>
            </div>
          </div>
          <div class="uoload-ing-div" v-show="uploading">
            <a-spin size="large">
              <a-alert message="视频上传中" description="您可以关闭此弹窗，视频将在后台继续完成上传"></a-alert>
            </a-spin>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { carPicsGroup, uploadVehicle as imageUpload, inspectUpload, deleteVehiocleImg } from '@/api/global'
import { PlusOutlined } from '@ant-design/icons-vue'
import { formatBytes, confirmWindow } from '@/utils/util'
import ValidateInfo from '@/views/components/vehicleUpload/ValidateInfo'
import VInfos from '@/views/components/vehicleUpload/ValidateInfos'
import { message } from 'ant-design-vue'
function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default {
  components: {
    VInfos,
    ValidateInfo,
    PlusOutlined
  },
  props: {
    orderVehicleId: String,
    loadInspectList: Function
  },
  setup (props, context) {
    const state = reactive({
      activeKey: 1,
      normalTotal: 0,
      injuryShow: false,
      wareShow: false,
      inspectionImg: false,
      uploading: false,
      loadInspectList: undefined,
      carNormalLen: 0,
      orderVehicleId: '',
      totalValidateInfo: '',
      validateInfoCurrent: 1,
      normalInfoCurrent: 1,
      totalNormalInfo: '',
      alidateInfoCurrent: 1,
      carValidateInfos: [],
      carInjuryInfos: [],
      carNormalInfos: [],
      videoFileList: [], // 视频变量
      videoList: [], // 本地缓存的视频
      videoData: { fileUrl: null, fileRemark: '' }, // 已上传的视频
      ware: {
        record: [],
        current: 1,
        total: 0
      }
    })
    // 图片上传和加载
    const upload = (orderVehicleId) => {
      carPicsGroup(orderVehicleId, {
        injury: 0
      }).then(res => {
        if (res.code === 10000) {
          state.carValidateInfos = res.data.records
          state.videoData = state.carValidateInfos.find(item => item.inspectType.value === 128)
          const carNormalList = state.carValidateInfos.filter(item => {
            return item.fileId !== null
          })
          state.carNormalLen = carNormalList.length
          state.normalTotal = state.carNormalLen + state.totalNormalInfo
        }
      })
    }
    // 车伤
    const injuryPage = (orderVehicleId) => {
      carPicsGroup(orderVehicleId, {
        injury: 1,
        current: state.validateInfoCurrent,
        size: 5
      }).then(res => {
        if (res.code === 10000) {
          state.carInjuryInfos = res.data.records
          state.validateInfoCurrent = res.data.current
          state.totalValidateInfo = res.data.total
        }
      })
    }
    // 常规
    const normalPage = (orderVehicleId) => {
      carPicsGroup(orderVehicleId, {
        injury: 2,
        current: state.normalInfoCurrent,
        size: 6
      }).then(res => {
        if (res.code === 10000) {
          state.carNormalInfos = res.data.records
          state.normalInfoCurrent = res.data.current
          state.totalNormalInfo = res.data.total
          state.normalTotal = state.carNormalLen + state.totalNormalInfo
        }
      })
    }
    // 入盘库照片
    const warePage = (orderVehicleId) => {
      carPicsGroup(orderVehicleId, {
        injury: 3,
        current: state.ware.current,
        size: 5
      }).then(res => {
        if (res.code === 10000) {
          state.ware.record = res.data.records
          state.ware.current = res.data.current
          state.ware.total = res.data.total
        }
      })
    }
    // 车伤照片分页
    const ValidateInfoChange = page => {
      state.validateInfoCurrent = page
      injuryPage(state.orderVehicleId)
    }
    // 常规照片分页
    const NormalInfoChange = page => {
      state.validateInfoCurrent = page
      normalPage(state.orderVehicleId)
    }
    // 车伤照片分页
    const wareChange = page => {
      state.ware.current = page
      warePage(state.orderVehicleId)
    }
    const loadData = (orderVehicleId) => {
      state.activeKey = 1
      state.injuryShow = false
      state.wareShow = false
      if (orderVehicleId) state.orderVehicleId = orderVehicleId
      upload(orderVehicleId)
      normalPage(orderVehicleId)
      injuryPage(orderVehicleId)
      warePage(orderVehicleId)
    }
    const loadInspectList = (e, v) => {
      if (e) {
        // if (state.loadInspectList !== undefined) state.loadInspectList()
        if (v) normalPage(state.orderVehicleId)
        else {
          switch (state.activeKey) {
            case 1:
              upload(state.orderVehicleId)
              break
            case 2:
              injuryPage(state.orderVehicleId)
              break
            case 3:
              warePage(state.orderVehicleId)
              break
          }
        }
      }
    }
    const tabsChange = e => {
    }
    // 上板视频
    const videoBeforeUpload = file => {
      state.videoFileList = []
      state.videoList = []
      state.videoFileList.push(file)
      return false
    }
    const videoFileListChange = info => {
      state.videoList = []
      getBase64(info.file, base64Url => {
        state.videoList.push({ fileUrl: base64Url })
      })
    }
    const uploadVideo = () => {
      if (state.videoFileList.length > 0) { // 判断是否上传视频
        if (Number(formatBytes(state.videoFileList[0].size, 0)) < 20000) { // 判断视频是否超出最大限制
          state.uploading = true
          let submit = {
            fileId: '',
            fileRealUrl: '',
            fileUrl: '',
            inspectType: 128,
            orderVehicleId: state.orderVehicleId
          }
          const formData = new FormData()
          formData.append('file', state.videoFileList[0].originFileObj)
          imageUpload(formData).then(res => {
            if (res.code === 10000) {
              submit.fileId = res.data.id
              submit.fileRealUrl = res.data.url
              submit.fileUrl = res.data.previewUrl
              inspectUpload(state.orderVehicleId, submit).then(res => {
                if (res.code === 10000) {
                  message.success('上板视频已上传完成')
                  state.uploading = false
                  state.videoFileList = []
                  state.videoList = []
                  upload(state.orderVehicleId)
                }
              })
            }
          }).catch(err => { console.log(err); })
        } else message.error('上传失败，视频超出最大限制(20MB)')
      } else message.error('您还未选择上传视频')
    }
    const deleteVideo = () => {
      confirmWindow('删除视频操作不可逆', requireAPI)
      function requireAPI () {
        deleteVehiocleImg(state.videoData.id).then(res => {
          if (res.code === 10000) {
            message.success(data.form.inspectType.label + '删除成功')
            state.videoData = { fileUrl: null, fileRemark: '' }
            state.videoFileList = []
            state.videoList = []
            upload(state.orderVehicleId)
          }
        })
      }
    }
    const save = () => {
      inspectUpload(state.orderVehicleId, state.videoData).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          emit('inspectionLoadData', true)
        }
      })
    }
    const closeWindow = () => {
      state.videoFileList = []
      state.videoList = []
      state.carValidateInfos = []
      state.carInjuryInfos = []
      state.carNormalInfos = []
    }

    onMounted(() => {
      if (props.orderVehicleId) state.orderVehicleId = props.orderVehicleId
      if (props.carValidateInfos) state.carValidateInfos = props.carValidateInfos
      if (props.carInjuryInfos) state.carInjuryInfos = props.carInjuryInfos
      if (props.loadInspectList) state.loadInspectList = props.loadInspectList
    })
    return {
      ...toRefs(state),
      upload,
      save,
      uploadVideo,
      deleteVideo,
      tabsChange,
      loadData,
      injuryPage,
      normalPage,
      closeWindow,
      ValidateInfoChange,
      warePage,
      wareChange,
      loadInspectList,
      NormalInfoChange,
      videoBeforeUpload,
      videoFileListChange
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-pagination) {
  padding-left: 15px;
}

.left {
  width: 20%;
  height: 200px;
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 15px;
}

.video-div {
  display: flex;
  align-items: flex-end;

  :deep(.ant-upload-picture-card-wrapper) {
    width: auto;
  }

  :deep(.ant-upload-select-picture-card) {
    margin-bottom: 0;
  }
}

:deep(.ant-spin-nested-loading) {
  height: 100%;

  .ant-spin-container {
    height: 100%;

    .ant-alert-info {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.video-content {
  position: relative;

  .video-list {
    width: 200px;
    height: 200px;
  }

  .uoload-ing-div {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .5);
  }
}
</style>
