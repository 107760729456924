<template>
  <div>
    <a-modal :visible="visible" title="交车条" footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
         <div v-for="item in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.id ?'changeItem':''">
            {{ item.name }}({{ item.total }})
         </div>
      </div>
      <SearchList :iptData="iptData"></SearchList>
      <div class="m-t1 tableBox">
        <a-table :dataSource="dataSource" :columns="columns" bordered :pagination="false">
          <template #add="{ record }">
            <a @click="visibleCar = true">交车</a>
          </template>
        </a-table>
      </div>
      <DeliveryCar v-model:visible="visibleCar" v-if="visibleCar"></DeliveryCar>  
    </a-modal>
  </div>
</template>
  
  <script setup>
import { ref } from 'vue'
import SearchList from '@/components/searchList'
import DeliveryCar from '@/components/DeliveryCar'
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
})
const cancel = () => {
  emit('update:visible', false)
}
const indexKey = ref(1)
const visibleCar = ref(false)
const dataSource = ref([
  {}
])

const changeBtn = (e) => {
  indexKey.value = e.id
}
const tabstatus = ref([
  {
    name: '全部',
    id: 1,
    total: '192'
  },
  {
    name: '今日待上传',
    id: 2,
    total: '192'
  },
  {
    name: '昨日未上传',
    id: 3,
    total: '192'
  },
])
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "status",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "结算状态",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "卸车人",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
])
const columns = ref([
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '卸车时间',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '卸车人',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '卸车地',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '结算方式',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '结算状态',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots:{
      customRender: 'add'
    }
  },
])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item{
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem{
  border-bottom: 4px solid #0066CC;
  color: #0066CC;
}
</style>