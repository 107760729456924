<template>
  <div>
    <a-space style="margin-bottom:10px;">
      <a-select style="width: 140px" v-model:value="searchType" placeholder="选择大车类型"
        @change="BIGpagination.current = 1, loadData()" allowClear>
        <a-select-option :value="1">大板车</a-select-option>
        <a-select-option :value="2">小板车</a-select-option>
        <a-select-option :value="3">货车</a-select-option>
        <a-select-option :value="4">地跑</a-select-option>
      </a-select>
      <a-input v-model:value="searchVinno" placeholder="车牌号" />
      <a-button type="primary" @click="search">搜索</a-button>
      <a-button @click="onAddVehicle">新增</a-button>
    </a-space>
    <a-table :data-source="dataSource" v-model:columns="mirrorImgColumns" :loading="loading" bordered
      :pagination="BIGpagination" @change="handleChange" :rowKey="(record, index) => { return index }" size="small">
      <template #carNo="{ record }">
        <div>
          <a @click="lookBigCarData(record)">{{ record.carNo }}</a>
          <span>({{ record.gpsType?.label }})</span>
        </div>
      </template>
      <template #latestLocation="{ record }">
        <div v-show="record.startAddress && record.endAddress">
          <div>{{ record.startAddress + '——' + record.endAddress }}</div>
          <div>
            <span v-show="record.dispatcher">{{ record.dispatcher + ' ' }}</span>
            <span v-show="record.createTime">装车时间：{{ record.createTime }}</span>
          </div>
        </div>
      </template>
      <template #carrierName="{ record }">
        <div>
          <span>{{ record.carrierName }}&emsp;</span>
          <a @click="addDriverBtn(record)">+新增</a>
        </div>
      </template>
      <!-- 1空闲  2安排中  3运输中 -->
      <template #operation="{ record }">
        <div>
          <a-button size="small" v-show="record.truckStatus?.value === 1 && record.place > 0" @click="choose(record, 1)">
            {{ record.carChoose ? '已选择' : '选择该车' }}
          </a-button>
          <a-popconfirm title="该车辆已被安排，是否继续装车?" ok-text="确认" cancel-text="取消" @confirm="choose(record, 1)">
            <a-button size="small" v-show="record.truckStatus?.value === 2 && record.place > 0">
              {{ record.carChoose ? '已选择' : '选择该车' }}
            </a-button>
          </a-popconfirm>
          <a-popconfirm title="该车辆正在运输中，是否确认中途补位?" ok-text="确认" cancel-text="取消" @confirm="choose(record, 3)">
            <a-button size="small" v-show="record.truckStatus?.value === 3 && record.place > 0">中途补位</a-button>
          </a-popconfirm>
          <a-button size="small" v-show="record.truckStatus?.value === 1 && record.place > 0" @click="choose(record, 2)">
            {{ record.driveChoose ? '已选择' : '选择司机' }}
          </a-button>
          <a-button size="small" v-show="record.place <= 0" :disabled="record.place <= 0">已装满</a-button>
          <a-button v-show="searchType === 4" size="small" @click="selfDriving(record)">
            {{ record.driveShow ? '已选择' : '选该地跑司机' }}

          </a-button>
        </div>
      </template>
    </a-table>
  </div>
  <!-- 选择司机 -->
  <a-modal width="50%" v-model:visible="deiverShow" title="选择司机" @ok="deiverShow = false" :destroyOnClose="true">
    <Driver ref="driverRef" :carrierId="carrierId" @onondriverName="chooseDriver" />
  </a-modal>
  <!-- 新增司机 -->
  <a-modal v-model:visible="addDriverStatus" title="新增司机" centered @ok="driverSave">
    <a-form :model="carrierDriver" :label-col="{ style: { width: '100px' } }" :rules="rules">
      <a-col>
        <a-form-item label="承运商" style="display:flex">
          <a-input style="width:80%" v-model:value="carrierDriver.searchCarrier" placeholder="请输入承运商" />
        </a-form-item>
        <a-form-item label="司机姓名">
          <a-input v-model:value="carrierDriver.name" placeholder="请填写司机姓名" />
        </a-form-item>
        <a-form-item label="身份证号">
          <a-input v-model:value="carrierDriver.idNumber" placeholder="请填写身份证号码" />
        </a-form-item>
        <a-form-item label="联系电话" name="mobile">
          <a-input v-model:value="carrierDriver.mobile" placeholder="请填写司机电话" />
        </a-form-item>
        <a-form-item label="司机类型">
          <a-select v-model:value="driverTypeData" style="width: 250px" :options="driverType" placeholder="请选择司机类型"
            @change="driverTypeChange" />
        </a-form-item>
      </a-col>
    </a-form>
  </a-modal>
  <!-- 大车详情 -->
  <a-modal v-model:visible="bigCarShow" title="大车信息" @ok="bigCarShow = false">
    <a-form layout="horizontal" ref="formRef">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="发车时间">
            {{ transportform ? transportform.departureTime : '' }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="派车人">
            {{ transportform ? transportform.dispatcher : '' }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="线路名称">
            {{ transportform ? transportform.lineName : '' }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="已占车位">
            {{ transportform ? transportform.usedParkPlace : '' }}
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  <!-- 承运商车辆添加 -->
  <Addvehicle @loadData="loadData" :carrierId="carrierId" ref="_addVehicle" />
</template>

<script>
import { onMounted, reactive, toRefs, ref, watch } from 'vue'
import { carrierTruckFreePage, truckByCarrierTruckId } from '@/api/transport/truck'
import { Freepage } from '@/api/crm/carrer/driver'
import { message } from 'ant-design-vue'
import { mobileValidate, idNumberValidate } from '@/utils/validate'
import { addDriver as add } from '@/api/carrier/driverList'
import Driver from '@/views/components/driver'
import GPS from '@/views/transportCapacity/transportUse/comon/gps.vue'
import Addvehicle from '@/views/carrier/toolDetail/vehicleList/add.vue'
export default {
  props: { carrierId: String },
  components: {
    Driver,
    GPS,
    Addvehicle
  },
  setup (props, context) {
    const _addVehicle = ref()
    const driverRef = ref(null)
    const state = reactive({
      searchType: 1,
      searchVinno: '',
      carrierId: '',
      driverTypeData: null,
      addDriverStatus: false,
      bigCarShow: false,
      loading: false,
      deiverShow: false,
      detail: {},
      carrierDriver: { id: '' },
      transportform: {},
      dataSource: [],
      mirrorImgColumns: [],
      rules: {
        idNumber: [{ required: true, message: '请填写身份证号码', trigger: 'blur' }, { pattern: idNumberValidate, message: '请填写正确的身份证号码', trigger: 'blur' }],
        mobile: [{ required: true, message: '请填写电话号码', trigger: 'blur' }, { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }]
      },
      driverType: [
        {
          value: 1,
          label: '大板车'
        },
        {
          value: 2,
          label: '小板车'
        },
        {
          value: 3,
          label: '地跑'
        }
      ],
      columns: [
        {
          title: '运输类型',
          dataIndex: 'truckType.label'
        },
        {
          title: '大车车牌',
          dataIndex: 'carNo',
          slots: {
            customRender: 'carNo'
          }
        },
        {
          title: '运输信息',
          dataIndex: 'latestLocation',
          slots: {
            customRender: 'latestLocation'
          }
        },
        {
          title: '剩余空位',
          dataIndex: 'vacancy'
        },
        {
          title: '车辆状态',
          dataIndex: 'truckStatus.label'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      BIGcolumnss: [
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true
        },
        {
          title: '电话',
          dataIndex: 'mobile',
          key: 'mobile',
          width: 100
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          key: 'carrierName',
          slots: {
            customRender: 'carrierName'
          }
        },
        {
          title: '选择',
          dataIndex: 'operation',
          width: 200,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      BIGpagination: {
        pageSize: 3,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['3', '6', '10'],
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const loadData = () => {
      state.loading = true
      if (state.searchType === 4) {
        state.mirrorImgColumns = state.BIGcolumnss
        Freepage({
          carrierId: state.carrierId,
          current: state.BIGpagination.current,
          size: state.BIGpagination.pageSize,
          name: state.searchVinno,
          truckType: state.searchType
        }).then(res => {
          if (res.code === 10000) {
            state.dataSource = res.data.records
            clearStatus()
            state.BIGpagination.total = res.data.total
            state.loading = false
          }
        })
      } else {
        state.mirrorImgColumns = state.columns
        carrierTruckFreePage({
          carrierId: state.carrierId,
          current: state.BIGpagination.current,
          size: state.BIGpagination.pageSize,
          name: state.searchVinno,
          truckType: state.searchType
        }).then(res => {
          if (res.code === 10000) {
            state.dataSource = res.data.records
            clearStatus()
            state.BIGpagination.total = res.data.total
            state.loading = false
          }
        })
      }
    }
    const handleChange = page => {
      state.BIGpagination = page
      loadData()
    }
    const search = () => {
      state.BIGpagination.current = 1
      loadData()
    }
    // 确认该车和司机
    const choose = (item, num) => {
      item.carrierTruckId = item.id
      switch (num) {
        case 1: // 选择该车
          state.detail = {}
          if (state.detail.choose) state.detail.choose = false
          if (state.detail.chooseDriver) state.detail.chooseDriver = false
          state.detail = item

          state.dataSource.forEach(item => {
            item.carChoose = false
          })
          setTimeout(() => {
            let index = state.dataSource.findIndex(item => item.id === state.detail.carrierTruckId)
            state.dataSource[index].carChoose = true
            if (state.detail.truckStatus.value === 2) {
              context.emit('carDriverData', state.detail)
            }
          }, 10)
          break
        case 2: // 选择司机
          state.deiverShow = true
          setTimeout(() => {
            driverRef.value.loadData()
          }, 100)
          break
        case 3: // 中途补位
          state.detail = {}
          state.detail = item
          context.emit('carDriverData', state.detail)
          break
      }
    }
    const clearStatus = () => {
      state.dataSource.forEach(item => {
        item.carChoose = false
        item.driveChoose = false
        item.driveShow = false
      })
    }
    const chooseDriver = recode => {
      if (recode) {
        if (state.detail.truckStatus && state.detail.truckStatus.value === 1 && state.detail.carNo) {
          const objData = { ...state.detail, ...recode }
          state.detail = objData
          let index = state.dataSource.findIndex(item => item.id === state.detail.carrierTruckId)
          state.dataSource[index].driveChoose = true
          context.emit('carDriverData', state.detail)
        } else message.error('请先点击选择大车'), console.log(state.detail);
        state.deiverShow = false
      }
    }
    // 选择地跑司机
    const selfDriving = record => {
      if (record) {
        state.dataSource.forEach(item => {
          item.driveShow = false
        })
        record.driveShow = true
        state.detail = record
        state.detail.driver = true
        context.emit('carDriverData', state.detail)
      }
    }
    // 查看大车信息
    const lookBigCarData = record => {
      state.bigCarShow = true
      state.transportform = {}
      truckByCarrierTruckId(record.id).then(res => {
        if (res.code === 10000) {
          state.transportform = res.data
        }
      })
    }

    watch(() => props.carrierId, (newVal, oldVal) => {
      if (newVal !== undefined) state.carrierId = newVal
    }, { deep: true })
    onMounted(() => {
      if (props.carrierId) {
        state.carrierId = props.carrierId
      }
    })
    // 新增承运商车辆
    const onAddVehicle = () => {
      _addVehicle.value.addShow = true
    }
    // 新增司机
    const addDriverBtn = (record) => {
      console.log(record)
      state.addDriverStatus = true
      state.carrierDriver.searchCarrier = record.carrierName
      state.carrierId = record.carrierId
    }
    // 新增司机车辆类型选择
    const driverTypeChange = (e, v) => {
      state.carrierDriver.driverType = v
    }
    // 保存新增司机
    const driverSave = () => {
      state.carrierDriver.carrierId = state.carrierId
      add(state.carrierDriver)
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.addDriverStatus = false
            loadData()
          }
        }).catch(err => {
          console.log(err)
        })
    }
    return {
      ...toRefs(state),
      driverRef,
      lookBigCarData,
      chooseDriver,
      handleChange,
      selfDriving,
      loadData,
      choose,
      search,
      driverTypeChange,
      addDriverBtn,
      driverSave,
      clearStatus,
      onAddVehicle,
      _addVehicle
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-btn-sm) {
  margin-right: 5px;
}
</style>
