<template>
  <a-modal v-model:visible="visible" title="修改密码" @ok="handleOk" :maskClosable="false" destroyOnClose>
    <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
      <a-form-item label="旧密码" name="oldPassword">
        <a-input-password v-model:value="form.oldPassword" placeholder="请输入现在使用的密码" />
      </a-form-item>

      <a-popover trigger="focus">
        <template #content>
          <div class="words">强度 ：{{}}</div>
        </template>
        <a-form-item required has-feedback label="新密码" name="newPassword">
          <a-input-password v-model:value="form.newPassword" maxlength="20" placeholder="请输入新密码，长度为6-20个字符" />
        </a-form-item>
      </a-popover>

      <a-form-item required has-feedback label="确认新密码" name="rePassword">
        <a-input-password v-model:value="form.rePassword" placeholder="请再次输入新密码" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { computed, reactive, ref, toRefs } from 'vue'
import { useStore } from 'vuex'
import md5 from 'md5'
import { useRouter } from 'vue-router'
import { changePass } from '@/api/login'
import { message } from 'ant-design-vue'

export default {
  name: 'change-pass',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()

    const formRef = ref()

    const validatePass = async (rule, value) => {
      if (!value) {
        return Promise.reject(new Error('请输入新密码'))
      } else if (value === state.form.oldPassword) {
        return Promise.reject(new Error('新密码不能与旧密码相同'))
      } else {
        if (state.form.rePassword) {
          formRef.value.validate('rePassword')
        }
        return Promise.resolve()
      }
    }
    const validatePass2 = async (rule, value) => {
      if (!value) {
        return Promise.reject(new Error('请再次输入新密码'))
      } else if (value !== state.form.newPassword) {
        return Promise.reject(new Error('两次输入密码不一致'))
      } else {
        return Promise.resolve()
      }
    }

    const state = reactive({
      visible: computed({
        get: () => props.value,
        set: val => emit('update:value', val)
      }),
      form: {},
      rules: {
        oldPassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPassword: [
          { min: 6, message: '长度不能小于6位', trigger: 'blur' },
          { validator: validatePass, trigger: 'change' }
        ],
        rePassword: [{ validator: validatePass2, trigger: 'change' }]
      }
    })

    const handleOk = () => {
      formRef.value.validate()
        .then(() => {
          changePass({
            oldPassword: md5(state.form.oldPassword),
            newPassword: md5(state.form.newPassword)
          }).then(res => {
            if (res.code === 10000) {
              message.success('密码修改成功，请重新登录')
              store.dispatch('Logout').then(() => {
                router.push({ name: 'login' })
              })
            }
          })
        })
    }

    return {
      formRef,
      ...toRefs(state),
      handleOk
    }
  }
}
</script>
