<template>
  <div style="height:100%">
    <div class="add-report-btn">
      <!-- <a-button type="primary" @click="addShow = true">+新增上报</a-button> -->
    </div>
    <div class="abnormal-content">
      <div class="content-title"></div>
      <div style="margin-bottom:10px" class="content-data" v-for="item in abnormalList" :key="item.explainTime">
        <a-row :gutter="[24, 8]">
          <a-col :span="3">
            <span class="title-color">
              <span v-show="!item.amount">异常类型</span>
              <span v-show="item.amount > 0">费用类型</span>
            </span>
          </a-col>
          <a-col :span="5">
            <span class="data-color">{{ item.exceptionType?.label }}</span>
          </a-col>
          <a-col :span="3">
            <span class="title-color">所属任务段</span>
          </a-col>
          <a-col :span="5">
            <span class="data-color">{{ item.transportType?.label }}</span>
          </a-col>
          <a-col :span="3">
            <span class="title-color">审核状态</span>
          </a-col>
          <a-col :span="5">
            <span class="data-color">{{ item.auditStatus?.label || '' }}</span>
            <a-tooltip>
              <template #title>{{ item.auditRemark || '没有审核备注' }}</template>
              <MessageOutlined :style="{ marginLeft: '5px' }" />
            </a-tooltip>
          </a-col>
          <!-- 分隔线 -->
          <a-col :span="3">
            <span class="title-color">异常原因</span>
          </a-col>
          <a-col :span="5">
            <span class="data-color">{{ item.exceptionSubType?.label }}</span>
          </a-col>
          <a-col :span="3" v-show="item.amount > 0">
            <span class="title-color">费用金额</span>
          </a-col>
          <a-col :span="5" v-show="item.amount > 0">
            <span class="data-color">{{ item.amount }}</span>
          </a-col>
          <!-- 分隔线 -->
          <a-col :span="3">
            <span class="title-color">原因</span>
          </a-col>
          <a-col :span="5">
            <div class="data-color" style="width:150px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
              <a-tooltip placement="left">
                <template #title>{{ item.exceptionExplain }}</template>
                {{ item.exceptionExplain }}
              </a-tooltip>
            </div>
          </a-col>
          <a-col :span="3">
            <span class="title-color">异常凭证</span>
          </a-col>
          <a-col :span="5">
            <span class="data-color"><a-image v-show="item.fileRealUrl" :width="20" :height="20"
                :src="item.fileRealUrl" /></span>
          </a-col>
          <a-col :span="3">
            <span class="title-color">上报人</span>
          </a-col>
          <a-col :span="5">
            <span class="data-color">{{ item.explainer }}</span>
          </a-col>
          <a-col :span="3">
            <span class="title-color">上报时间</span>
          </a-col>
          <a-col :span="5">
            <span class="data-color">{{ item.explainTime }}</span>
          </a-col>
          <!-- 分隔线 -->
          <a-col :span="3">
            <span class="title-color">审核人</span>
          </a-col>
          <a-col :span="5">
            <span class="data-color">{{ item.follower }}</span>
          </a-col>
          <a-col :span="3">
            <span class="title-color">审核时间</span>
          </a-col>
          <a-col :span="5">
            <span class="data-color">{{ item.followTime || '' }}</span>
          </a-col>
          <a-col :span="3">
            <span class="title-color">所属承运商</span>
          </a-col>
          <a-col :span="5">
            <span class="data-color">{{ item.carrierName || '' }}</span>
          </a-col>
        </a-row>
      </div>
      <div style="text-align: center;">
        <a v-show="!loading" @click="getloadingData">{{ titleSpan ? '没有更多数据啦...' : '加载更多...' }}</a>
        <a-spin v-show="loading" tip="加载中...">
        </a-spin>
      </div>
    </div>
  </div>
  <AddReport :windowShow="addShow" :vehicleDetail="vehicleDetail" @reportData="addReportData" />
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
import { addReport, abnormalPage } from '@/api/transport/transport'
import AddReport from './addReport.vue'
import { message } from 'ant-design-vue'
import { MessageOutlined } from '@ant-design/icons-vue'

export default {
  props: {
    windowShow: Boolean,
    vinNo: String,
    vehicleDetail: Object
  },
  components: {
    AddReport,
    MessageOutlined
  },
  setup (props, context) {
    const state = reactive({
      windowShow: false,
      addShow: false,
      loading: false,
      titleSpan: false,
      vinNo: '',
      paginate: {
        current: 1,
        size: 5
      },
      abnormalList: [],
      vehicleDetail: {}
    })
    watch(() => props.windowShow, (newVal, oldVal) => {
      state.windowShow = newVal
      if (state.windowShow === true) {
        if (props.vinNo) state.vinNo = props.vinNo
        if (props.vehicleDetail.trans) props.vehicleDetail.trans.vinNo
        state.vehicleDetail = props.vehicleDetail
        state.abnormalList = []
        getAbnormalPage()
      }
    })
    const getloadingData = () => {
      state.paginate.current++
      getAbnormalPage()
    }
    const clearData = () => {
      state.abnormalList = []
    }
    // 异常数据分页
    const getAbnormalPage = () => {
      if (state.vinNo !== '' && state.vinNo !== null && state.vinNo !== undefined) {
        state.loading = true
        abnormalPage({
          current: state.paginate.current,
          size: state.paginate.size,
          carNo: state.vinNo
        }).then(res => {
          if (res.code === 10000) {
            if (state.abnormalList.length === res.data.total) {
              state.titleSpan = true
              state.paginate.current--
            } else {
              res.data.records.forEach(item => {
                state.abnormalList.push(item)
              })
            }
            state.loading = false
          }
        })
      } else message.error('当前小车暂无车牌/车架号')
    }
    const cancelClose = () => {
      state.windowShow = false
      state.abnormalList = []
      state.paginate.current = 1
      context.emit('statusShow', state.windowShow)
    }
    const addReportData = e => {
      if (e !== undefined && e.reportType) {
        delete e.belong
        let submit = {
          ...e,
          totvId: state.vehicleDetail.totvId,
          orderVehicleId: state.vehicleDetail.orderVehicleId,
          taskId: state.vehicleDetail.taskId,
        }
        addReport(submit).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
          }
        })
        console.log(e);
      }
    }
    const openWindow = () => {
      state.windowShow = true
      if (props.vinNo) state.vinNo = props.vinNo
      if (props.vehicleDetail.trans) props.vehicleDetail.trans.vinNo
      state.vehicleDetail = props.vehicleDetail
      getAbnormalPage()
    }
    return {
      ...toRefs(state),
      cancelClose,
      openWindow,
      clearData,
      addReportData,
      getloadingData
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

.add-report-btn {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  padding-right: 10px;
}

.content-data {
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 3px;
  background: rgba(242, 242, 242, .3);
}

.abnormal-content {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 10px;
}
</style>
