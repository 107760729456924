<template>
  <div style="display:flex">
    <div class="left">
      <div class="text-center" style="margin:15px 0;font-weight:bold">
        <img class="img" src="@/assets/images/carrier/basic.png">&emsp;基本信息
      </div>
      <div class="order-data">
        <a-row :gutter="[24,12]" v-if="detailData&&detailData.truckStatus!==undefined">
          <a-col :span="12">
            <span class="order-data-title">运输状态</span>
            <span class="font-color2">{{detailData.truckStatus.label}}</span>
          </a-col>
          <a-col :span="12">
            <span class="order-data-title">承运商</span>
            <span class="font-color2">{{detailData.carrierName}}</span>
          </a-col>
          <a-col :span="12">
            <span class="order-data-title">车型</span>
            <span class="font-color2">{{detailData.brand + detailData.model}}</span>
          </a-col>
          <a-col :span="12">
            <span class="order-data-title">线路名称</span>
            <span class="font-color2">{{detailData.lineName}}</span>
          </a-col>
        </a-row>
        <a-empty v-else/>
      </div>
      <div class="text-center" style="margin:15px 0;font-weight:bold">
        <img class="img" src="@/assets/images/carrier/u237.png">&emsp;车辆入库 &emsp;<a @click="putBtn" style="font-weight:normal">入库</a>
      </div>
    </div>
    <div class="right">
      <Record v-if="vehiclesId!==''" :vehicleList="vehicleList" :vehiclesId="vehiclesId" :employeeRealList="employeeList" :orderId="orderId" />
    </div>
  </div>
  <a-modal v-model:visible="putShow" title="入库">
    <div>入库类型</div>
    <a-select v-model:value="putStatus" style="width: 120px">
      <a-select-option :value="1">正常</a-select-option>
      <a-select-option :value="2">异常</a-select-option>
      <a-select-option :value="3">拒绝</a-select-option>
    </a-select>
    <div><span style="color:#f00">*</span>照片</div>
    <div class="row-div-upload">
      <a-upload multiple v-model::file-list="fileList" :remove="handleRemove" list-type="picture-card" :before-upload="beforeUpload">
        <div>
          <plus-outlined></plus-outlined>
          <div class="ant-upload-text">上传图片</div>
        </div>
      </a-upload>
      <div v-if="fileList.length > 0 ">
        <a-button :loading="fileLoading" @click="handleChange()">请点击上传保存</a-button>
      </div>
    </div>
    <div v-show="putStatus === 2 || putStatus === 3">备注</div>
    <a-textarea v-show="putStatus === 2 || putStatus === 3" v-model:value="remark" showCount :maxlength="200" placeholder="填写备注" :rows="4" />
    <template #footer>
      <div>
        <a-button @click="putShow=false">取消</a-button>
        <a-button type="primary" @click="confirm">确定</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { put, getRecentlyData } from '@/api/store/putWarehouse'
import {uploadVehicle as upload} from '@/api/global'
import { message } from 'ant-design-vue'
import Record from '@/views/transportCapacity/transportUse/comon/record.vue'

export default {
  components: {
    Record,
    PlusOutlined
  },
  props: {
    orderId: String,
    orderVehicleId: String,
    orderVehicleNo: String,
    element: Object,
    close: Function
  },
  setup (props,context) {
    const state = reactive({
      putStatus: '',
      imageUrl: '',
      remark: '',
      putShow: false,
      fileLoading: false,
      loading: false,
      fileList: [],
      element: {},
      detailData: {},
      vehicleList: [{ id: props.orderVehicleId, vinNo: props.orderVehicleNo }],
      vehiclesId: '',
      employeeList: [],
      addForm: {
        fileIds: []
      }
    })
    const loadData = () => {
      getRecentlyData(state.vehiclesId)
        .then(res => {
          if (res.code === 10000) {
            state.detailData = res.data.last
          }
        })
    }
    onMounted(() => {
      console.log(props);
      if (props.orderVehicleId) state.vehiclesId = props.orderVehicleId
      if (props.element) state.element = props.element
      loadData()
      // console.log(props.reload(1))
    })
    const putBtn = () => {
      if (state.element.stockType.value !== 7) {
        state.putShow = true
        // context.emit('status',true)
      } else message.info('当前车辆在途中，请更换于手机APP进行操作')
    }
    const confirm = () => {
      if (state.addForm.fileIds.length > 0) {
        put({
          fileIds: state.addForm.fileIds,
          orderVehicleId: props.orderVehicleId,
          remark: state.remark,
          warehouseType: state.putStatus
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.putShow = false
            context.emit('status',true)
            if(props.close) props.close()
          }
        })
      } else {
        message.error('请上传至少一张图片')
      }
    }
    // 图片移除
    const handleRemove = file => {
      const index = state.fileList.indexOf(file)
      const newfileList = state.fileList.slice()
      newfileList.splice(index, 1)
      state.fileList = newfileList
      state.addForm.fileIds.splice(index, 1)
    }
    // 选中图片
    const beforeUpload = file => {
      state.fileList.push(file)
      return false
    }
    // 图片上传
    const handleChange = () => {
      const len = state.fileList.length
      state.fileLoading = true
      updateBatch(0, len, state.fileList)
    }
    // 图片上传
    const updateBatch = (index, len, imageList) => {
      if (index <= len - 1) {
        const formData = new FormData()
        formData.append('file', imageList[index])
        upload(formData).then(res => {
          if (res.code === 10000) {
            state.addForm.fileIds.push(res.data.id)
            // data.fileUrl = res.data.previewUrl
            if (index === len - 1) {
              setTimeout(() => {
                state.fileList = []
                state.fileLoading = false
                message.success('图片上传成功')
              }, 1000)
            } else {
              updateBatch(++index, len, imageList)
            }
          }
        })
      } else {
        return false
      }
    }

    return {
      ...toRefs(state),
      handleChange,
      confirm,
      putBtn,
      handleRemove,
      beforeUpload
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
