<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <div class="ant-suy-table">
    <div class="ant-suy-table-search" v-if="$slots.search">
      <slot name="search"></slot>
    </div>
    <a-card :bodyStyle="{'padding':'0px'}" ref="card">
      <div class="ant-suy-table-toolbar">
        <div class="ant-suy-table-toolbar-container">
          <div class="ant-suy-table-toolbar-left">
            <div class="ant-suy-table-toolbar-title" v-if="title">{{title}}</div>
          </div>
          <div class="ant-suy-table-toolbar-left-show" v-if="$slots.toolbarLeft">
            <slot name="toolbarLeft"></slot>
          </div>
          <div class="ant-suy-table-toolbar-right">
            <a-space v-if="$slots.toolbarRight">
              <slot name="toolbarRight"></slot>
            </a-space>
            <div class="ant-suy-table-toolbar-divider" v-if="$slots.toolbarRight">
              <a-divider type="vertical" />
            </div>

            <div class="ant-suy-table-toolbar-setting-item" v-if="reload">
              <RedoOutlined @click="reload" />
            </div>

            <div class="ant-suy-table-toolbar-setting-item" v-if="columns">
              <a-popover overlayClassName="ant-suy-table-column-setting-overlay" placement="bottomRight" trigger="click" arrow-point-at-center>
                <template #content>
                  <div class="ant-suy-table-column-setting-list">
                    <a-checkbox-group v-model:value="checkColumns" @change="changeCheckColumns">
                      <div class="ant-suy-table-column-setting-list-item" v-for="column in columns" :key="column.dataIndex">
                        <NumberOutlined class="ant-suy-table-column-drag-icon" />
                        <a-checkbox :value="column.dataIndex">{{column.title}}</a-checkbox>
                      </div>
                    </a-checkbox-group>
                  </div>
                </template>
                <template #title>
                  <div class="ant-suy-table-column-setting-title">
                    <span>{{$t('columnTitle')}}</span>
                    <a @click="columnReset">{{$t('columnReset')}}</a>
                  </div>
                </template>
                <ControlOutlined :rotate="90" />
              </a-popover>

            </div>
           <div class="ant-suy-table-toolbar-setting-item">
             <a-tooltip @click="handleFullscreen">
               <template #title>{{ isFullscreen ? $t('fullscreen.ok'):$t('fullscreen')}}</template>
               <FullscreenExitOutlined v-if="isFullscreen" />
               <FullscreenOutlined v-else />
             </a-tooltip>
           </div>
          </div>
        </div>
      </div>
      <slot></slot>
    </a-card>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { RedoOutlined, ControlOutlined, FullscreenOutlined, FullscreenExitOutlined, NumberOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: 'SuyTable',
  props: {
    title: {
      type: String,
      default: ''
    },
    reload: {
      type: Function || Boolean
    },
    columns: {
      type: Array
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:columns'],
  setup (props, { emit }) {
    const state = reactive({
      isFullscreen: false,
      columns: props.columns,
      checkColumns: props.columns.map(column => { return column.dataIndex })
    })
    // 全屏
    const card = ref()
    const handleFullscreen = () => {
      if (state.isFullscreen) {
        if (document.fullscreenElement) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (card.value.$el.requestFullscreen) {
          card.value.$el.requestFullscreen()
        } else if (card.value.$el.mozRequestFullScreen) {
          card.value.$el.mozRequestFullScreen()
        } else if (card.value.$el.webkitRequestFullScreen) {
          card.value.$el.webkitRequestFullScreen()
        } else if (card.value.$el.msRequestFullscreen) {
          card.value.$el.msRequestFullscreen()
        }
      }
      state.isFullscreen = !state.isFullscreen
    }
    onMounted(() => {
      state.isFullscreen = !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen)
    })
    const changeCheckColumns = checkItem => {
      emit('update:columns', state.columns.filter(column => checkItem.includes(column.dataIndex)))
    }
    const columnReset = () => {
      state.checkColumns = state.columns.map(column => { return column.dataIndex })
      emit('update:columns', state.columns)
    }

    return {
      ...toRefs(state),
      card,
      handleFullscreen,
      changeCheckColumns,
      columnReset
    }
  },
  components: {
    RedoOutlined,
    ControlOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    NumberOutlined
  }
})
</script>
