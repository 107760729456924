<template>
  <div >
    <a-drawer width="50%" :bodyStyle="{'padding': '0'}" placement="right" :closable="false" :visible="visible"  @close="close">
      <div class="boxStyle">
        <div class="navBox" id="myElement">
          <a-tabs v-model:activeKey="activeKey" @change="changeTabs" size="large">
            <a-tab-pane v-for="item in tabs" :key="item.msgCategory.value">
              <template #tab>
                <span>
                  {{ item.msgCategory.label }}
                  <div class="badge" :style="item.num<10?'right:4px':''"> <a-badge :count="item.num" /></div>
                </span>
              </template>
              <a-tabs tab-position="top" type="card" @change="changeTabsOpt($event,item)" v-model:activeKey="item.idx" :hideAdd="true">
                <a-tab-pane v-for="(opt,idx) in item.msgTypes" :key="opt.msgType.value">
                  <template #tab>
                    <span>
                      {{ opt.msgType.label }}
                    </span>
                  </template>
                </a-tab-pane>
              </a-tabs>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div class="contentBox">
          <div v-if="unreadQuantity>0" class="newsInfo flex ju-between m-b1" style="padding-right:20px">
            <span><span style="color:red;margin-right:3px">{{unreadQuantity}}</span>未读消息</span>
            <span @click="clearAll" class="flex al-center cur-p">
              <img style="width:24px;height:24px" src="@/assets/images/marketing/clar.png" alt="">
              清除未读
            </span>
          </div>
          <div class=" flex al-center">
            <a-input class="searchBox" style="height:36px" v-model:value="contentVal" placeholder="关键词搜索" />
            <a-button  style="height:34px" class="m-l1 m-r3 " type="primary" @click="searchAdd">搜索</a-button>
          </div>
          <div class="flex al-center wrap">
            <div v-for="item in historyList" @click="hisAdd(item)" style="margin: 5px ;" class="m-l1" :key="item.id">
                    <a>{{ item }}</a>
            </div>
          </div>
          <div class="listData m-t1" :class="unreadQuantity==0?'heiCal1':'heiCal2'" >
            <div v-if="loading" class="flex al-center ju-center m-t4">
              <a-spin />
            </div>
            <div v-if="!loading">
              <div v-for="item in listData" class="listBox" :key="item.id">
                <div class="flex ju-between"><span>{{ item.title }} </span> <span v-if="item.isRead.value == 0" style="color:red;font-size:12px">未读</span> </div>
                <div class="m-t1" style="font-size:14px;color:#6B788B">{{ item.content }}</div>
                <div class="m-t1 flex ju-between" style="font-size:14px;color:#ADB4C4">{{ item.createTime }}<div @click="msgOk(item)" v-if="item.isRead.value == 0" class="btnBox">我已知悉</div>
                </div>
              </div>
            </div>

          </div>
          <div class="m-t1 flex ju-center" style="padding-right:20px">
            <a-pagination size="small" v-model:current="pagination.current" @change="changePage" :page-size="pagination.size" :total="pagination.total" :show-total="pagination.showTotal" show-less-items />
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive, createVNode, defineComponent } from 'vue'
import { msgCategory, msgPage ,readID} from '@/api/news/news'
import { Modal, message } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { IfNull } from '@/utils/util'
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})
const contentVal =  ref('')
const emit = defineEmits(['update:visible','change'])
const pagination = ref({
  total: '',
  showTotal: (total) => `共${total}条`,
  current: 1,
  size: 10
})
const historyList = ref([])
//清除全部
const clearAll = () => {
  Modal.confirm({
    title: () => '确认操作',
    icon: () => createVNode(ExclamationCircleOutlined),
    content: () => createVNode(
      'div',
      {
        style: 'color:red;',
      },
      '确定要清除全部未读吗？',
    ),
    onOk () {
       reqReadID('',msgPageData.msgType)
    },
    onCancel () { },
  });
}
const searchAdd = () => {
  if(!IfNull(contentVal.value))return
   if(localStorage.getItem('newsHistory')){
    let list = JSON.parse(localStorage.getItem('newsHistory'))
    if(!list.includes(contentVal.value)){
      if (list.length == 5) {
        list.pop()
      }
      list.unshift(contentVal.value)
      historyList.value = list
      localStorage.setItem('newsHistory', JSON.stringify(list))
    }
      
   }else{
      let list = []
      list.push(contentVal.value)
      historyList.value = list
      localStorage.setItem('newsHistory', JSON.stringify(list))
   }
  pagination.value.current =  1
  reqMsgPage()
}
const hisAdd = (item) => {
  pagination.value.current = 1
  contentVal.value = item
  reqMsgPage()
}
const changePage = (e) => {
  pagination.value.current = e
  reqMsgPage()
}
const loading = ref(false)
const listData = ref([])
const tabs = ref([])
const msgPageData = reactive({
  msgCategory: '',
  msgType: ''
})
const activeKey = ref('')
const unreadQuantity = ref(0)
//我已知悉
const msgOk = (item) => {
  reqReadID(item,'')
}
const dispatchData =  (data,e) => {
  let arr = []
  data.forEach( item => {
   if(item.msgCategory.value == e){
    arr = item
   }
  })
  return arr
}
const changeTabs = (e) => {
  contentVal.value = ''
  pagination.value.current = 1
  msgPageData.msgCategory = e
  msgPageData.msgType =  dispatchData(tabs.value,e).idx
  dispatchData(tabs.value,e).msgTypes.forEach( opt => {
    if(opt.msgType.value == dispatchData(tabs.value,e).idx ){
      unreadQuantity.value = opt.num
    }
  })
  reqMsgPage()
}
const changeTabsOpt = (item, opt) => {
   contentVal.value = ''
  pagination.value.current = 1
  msgPageData.msgCategory = opt.msgCategory.value
  msgPageData.msgType = item
  opt.msgTypes.forEach( val => {
    if(val.msgType.value == opt.idx){
      unreadQuantity.value = val.num
    }
  } )
  reqMsgPage()

}
const close = () => {
  emit('update:visible', false)
}
const reqReadID = (item,type) => {
  let id = item.id?item.id:''
  readID(id,type).then( res => {
    if(res.code !== 10000) return
    if(type){
      listData.value.forEach( val => {
        val.isRead.value = 1
      })
    }else{
      item.isRead.value = 1
    }
    emit('change')
    message.success('操作成功')
    reqMsgCategory('read')
  } )
}

const reqMsgCategory = (type) => {
  msgCategory({}).then(res => {
    if (res.code !== 10000) return
    res.data.forEach(item => {
      item.idx =item.msgCategory.value
      item.msgTypes.unshift(
        {
          msgType: { value: item.msgCategory.value, label: '全部' },
          num: item.num
        }
      )
    })
    if(type){ 
      dispatchData(tabs.value,msgPageData.msgCategory).num =  dispatchData(res.data,msgPageData.msgCategory).num
      dispatchData(tabs.value,msgPageData.msgCategory).msgTypes.forEach( msg1 => {
        dispatchData(res.data,msgPageData.msgCategory).msgTypes.forEach( msg2 => {
          if(msg1.msgType.value == msg2.msgType.value) {
            msg1.num = msg2.num
          }
          if(msg2.msgType.value ==  dispatchData(tabs.value,msgPageData.msgCategory).idx){
            unreadQuantity.value = msg2.num
          }
        })
      })
      return
    }
    tabs.value = res.data
    activeKey.value = res.data[0].msgCategory.value
    unreadQuantity.value = res.data[0].msgTypes[0].num
    msgPageData.msgCategory = res.data[0].msgCategory.value
    msgPageData.msgType = res.data[0].msgTypes[0].msgType.value
    reqMsgPage()

  })
}
const reqMsgPage = () => {
  loading.value = true
  let page = {
    current: pagination.value.current,
    size: pagination.value.size,
    content:contentVal.value
  }
  Object.assign(page, msgPageData)
  msgPage(page).then(res => {
    if (res.code !== 10000) return
    listData.value = res.data.records
    pagination.value.total = res.data.total
  }).finally(() => {
    loading.value = false
  })
}
defineExpose({
  reqMsgCategory
})
onMounted(() => {
  reqMsgCategory()
  if (localStorage.getItem('newsHistory')) {
    historyList.value = JSON.parse(localStorage.getItem('newsHistory'))
  }
})

</script>

<style lang="less" scoped>
.boxStyle {
  width: 100%;
  min-height: 100vh;
  background: #f8f8fa;
}
.navBox {
  padding: 5px 20px;
  border-bottom: 1px solid #cce3f7;
  background: linear-gradient(
    0deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(230, 240, 253, 1) 100%
  );
}

.optBack {
  margin: 0 15px;
  padding: 5px 15px;
}

.changeBack {
  background: #daebfa;
  color: #0066cc;
  border-radius: 5px;
  
}

.contentBox {
  padding: 20px 0 20px 20px;
}
.listData {
  height: calc(100vh - 290px);
  overflow: auto;
  padding-right: 20px;
}
.listData::-webkit-scrollbar {
  width: 5px;
}
/* 滚动条的轨道 */
.listData::-webkit-scrollbar-track {
  /* 设置滚动条轨道的背景色 */
  background-color: #fff;
  cursor: pointer;
}
/* 滚动条的滑块 */
.listData::-webkit-scrollbar-thumb {
  /* 设置滚动条滑块的背景色 */
  background-color: #daebfa;
  width: 5px;
  border-radius: 8px;
}

.badge {
  position: absolute;
  top: 4px;
  right: -6px;
}
.btnBox {
  padding: 0px 4px;
  border: 1px solid #6b778c;
  border-radius: 4px;
  cursor: pointer;
  color: #6b778c;
}
.searchBox{
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.09);
}
.listBox {
  padding: 20px 15px;
  background: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  margin-top: 10px;
  font-size: 15px;
  color: #172b4c;
}
:deep(.ant-drawer-body) {
  padding: 0 !important;
}
:deep(.ant-tabs-bar) {
  margin: 0 0 10px 0;
}
:deep(.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab) {
  background: none;
  line-height: 30px;
  height: 30px;
  margin-top: 5px;
}
:deep(.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active) {
  background: #daebfa;
  border: 1px solid #daebfa;
  color: #0066cc;
  line-height: 30px;
  height: 30px;
}

.heiCal1{
 height:  632px;
}
.heiCal2{
  height:  600px;
}
</style>