import { getRequest, postBodyRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/market/crm/customer/page', params)
}

export function detail (id) {
  return getRequest(`/market/crm/customer/${id}`)
}

export function fuzzy (params) {
  return postBodyRequest('/market/crm/customer/fuzzy', params)
}

export function edit (params) {
  return postBodyRequest('/market/crm/customer/edit', params)
}

export function add (params) {
  return postBodyRequest('/market/crm/customer/add', params)
}

export function pageAll (params) {
  return postBodyRequest('/market/crm/customer/page/all', params)
}
