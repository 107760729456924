<template>
<div>
   <a-modal :visible="visible" title="在途跟踪" footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
             <SearchList :iptData="iptData" ></SearchList> 
         <div class="m-t1 tableBox">
          <div class="m-b1">共4条数据</div>
          <a-table :dataSource="dataSource" :columns="columns" bordered  :pagination="false">
             <template #add="{ record }">
               <a  @click="add(record)">新增</a>
             </template>
             <template #vinNo="{ record }">
               <a  @click="add(record)">{{ record.vinNo }}</a>
             </template>
          </a-table>
         </div>
         <!-- 新增位置信息 -->
         <a-modal v-model:visible="addShow" title="新增位置信息"  width="25%" @ok="submit">
            <FormList :fromList="fromList" ref="forListRef" @getVal="getVal"></FormList>
         </a-modal>
   </a-modal>
</div>
</template>

<script setup>
import { ref } from 'vue'
import SearchList from '@/components/searchList'
import enumAll from '@/store/modules/enumAll'
import FormList from '@/components/formList'
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
})
const forListRef = ref(null)
const addShow = ref(false)
const dataSource = ref([{

}])
const getVal = (val) => {
  console.log(val);
}
const submit = () => {
  forListRef.value.getValRef()
}
const add = (record) => {
 addShow.value = true
}
const cancel = () => {
  emit('update:visible', false)
}
const fromList = ref([
  {
  label:'所在地区',
  prop: "vinNo",
  type:'address',
  name:'vinNo',
  value: null,
  placeholder: '请选择',
  changeOn:true,
  rules:[{
    required: true,
    message: '请选择所在地区',
    trigger: 'blur',
    type: 'array'
  }]
},
{
  label:'详细地址',
  prop: "vinNo1",
  type:'input',
  width:'100%',
  name:'vinNo1',
  value: null,
  placeholder: '请输入',
},
])
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "status",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "车辆类型",
    value: null,
    prop: "vinNo",
    width: 150,
    key:'value',
    keyVal:'label',
    opt:enumAll.state.nweTruckType
  },
  {
    type: "input",
    placeholder: "承运商名称",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "发车人",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "大车车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
])
const columns = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: 70,
    slots:{
      customRender: 'add'
    }
  },
  {
    title: '大车车牌',
    dataIndex: 'vinNo',
    align: 'center',
    slots:{
      customRender: 'vinNo'
    }
  },
  {
    title: '车辆类型',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '已装小车',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '超时信息',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '最新位置',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '发车人',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '发车时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: 'GPS类型',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
</script>

<style lang="less" scoped>
.tableBox{
  padding: 20px 10px;
  background: #fff;
}
</style>