import { getRequest, postRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/utils/axios'

export function login (params) {
  return postNoAuthRequest('/auth/login', params)
}

export function getInfo (params) {
  return getRequest(`/cis/userInfo/${Math.floor(Math.random() * 100)}/1`, params)
}

export function logout (params) {
  return postRequest('/auth/logout', params)
}

export function getSmsCaptcha (params) {
  return getNoAuthRequest('/Login/verify', params)
}

export function changePass (params) {
  return postBodyRequest('/auth/change/password', params)
}
