<template>
  <a-modal v-model:visible="windowShow" title="支付码" footer="" @cancel="closeWindow">
    <div>
      <div>
        <div v-show="detail.startAddress">起始地：{{ detail.startAddress }}</div>
        <div v-show="detail.endAddress">目的地：{{ detail.endAddress }}</div>
        <div v-show="detail.vehicleList">车架号：<a-tag color="green" v-for="(item, index) in detail.vehicleList"
            :key="index">{{ item.vinNo }}</a-tag>
        </div>
      </div>
      <div class="QRC-div">
        <a-radio-group v-model:value="payType" size="large" @change="payTypeChange">
          <a-radio-button :value="12">
            <div style="display:flex;align-items:center">
              <img class="QRC-icon" src="@/assets/images/polymerize-QRC.png">
              聚合支付
            </div>
          </a-radio-button>
          <a-radio-button :value="1">
            <div style="display:flex;align-items:center">
              <img class="QRC-icon" src="@/assets/images/wechat-QRC.png">
              微信支付
            </div>
          </a-radio-button>
          <a-radio-button :value="2">
            <div style="display:flex;align-items:center">
              <img class="QRC-icon" src="@/assets/images/alipay-QRC.png">
              支付宝支付
            </div>
          </a-radio-button>
        </a-radio-group>
        <QrcodeVue id="QRCpayImg" v-show="!QRCloading && payUrl !== ''" :value="payUrl" :size="200" level="H" />
        <div v-show="QRCcontext !== ''" style="text-align: center;">{{ QRCcontext }}</div>
        <a-spin v-show="QRCloading" tip="Loading..." />
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import QrcodeVue from 'qrcode.vue'
import { QRCurl, QRCpayurl, QRCpayurlYST, getYunsReconcQRCode, sandqrcodeTask } from '@/api/transport/inspection'
import { getsandqrcode } from '@/api/trans/finance/bill'

export default {
  components: {
    QrcodeVue,
  },
  setup () {
    const state = reactive({
      windowShow: false,
      QRCloading: false,
      payType: null,
      detail: {},
      QRCcontext: '',
      payUrl: ''
    })
    const QRCpayImg = () => {
      state.QRCloading = true
      state.payUrl = ''
      if (state.QRCpay === '') state.QRCloading = true
      if (state.detail.frId) {
        getsandqrcode({
          // orderId: state.detail.orderId,
          frId: state.detail.frId,
          payMethod: state.payType
        }).then(res => {
          if (res.code === 10000) {
            state.payUrl = res.data.qrCode
            state.QRCcontext = ''
          }
          if (res.code === 10001) {
            state.QRCcontext = res.msg
          }
        }).finally(() => { state.QRCloading = false })
      } else {
        if (state.payType === 12) {
          QRCpayurl({
            orderId: state.detail.orderId,
            frId: state.detail.frId,
            ovId: state.detail.ovId || null,
            payType: 1,
            payMethod: state.payType
          }).then(res => {
            if (res.code === 10000) {
              state.payUrl = res.data.qrCode
              state.QRCcontext = ''
            }
            if (res.code === 10001) {
              state.QRCcontext = res.msg
            }
          }).finally(() => { state.QRCloading = false })
        }
        else {
          QRCpayurlYST({
            orderId: state.detail.orderId,
            frId: state.detail.frId,
            ovId: state.detail.ovId || null,
            payType: 1,
            payMethod: state.payType
          }).then(res => {
            if (res.code === 10000) {
              state.payUrl = res.data.qrCode
              state.QRCcontext = ''
            }
            if (res.code === 10001) {
              state.QRCcontext = res.msg
            }
          }).finally(() => { state.QRCloading = false })
        }
      }
    }
    const payTypeChange = (e) => {
      QRCpayImg()
    }
    const openWindow = (obj) => {
      console.log(obj);
      state.detail = obj
      state.windowShow = true
    }
    const closeWindow = () => {
      state.windowShow = false
      state.payType = null
      state.payUrl = ''
      state.QRCcontext = ''
    }
    return {
      ...toRefs(state),
      openWindow,
      closeWindow,
      payTypeChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>