import { postBodyRequest,getRequest } from '@/utils/axios'

export function page (carrierId, params) {
  return postBodyRequest(`/carrier/carrier/address/${carrierId}/page`, params)
}

export function add (carrierId, params) {
  return postBodyRequest(`/carrier/carrier/address/${carrierId}/add`, params)
}

export function deleteAddress (addressId) {
  return postBodyRequest(`/carrier/carrier/address/${addressId}/delete`, {})
}

export function lookPhotos (params) {
  return getRequest('/carrier/carrier/address/photos', params)
}

export function detailEdit (carrierId,params) {
  return postBodyRequest(`/carrier/carrier/address/${carrierId}/edit`, params)
}

export function getDetailData (params) {
  return getRequest('/carrier/carrier/address/detail', params)
}