import {
  getRequest,
  postBodyRequest,
  fileUpload
} from '@/utils/axios'
// 所有运输车辆(跟踪)-分页
export function pageAll (params) {
  return postBodyRequest('/trans/transport/truck/page/all', params)
}
// 正在运输车辆(跟踪)-分页
export function page (params) {
  return postBodyRequest('/trans/transport/truck/page', params)
}
// 补位-分页
export function coverPage (params) {
  return postBodyRequest('/trans/transport/truck/coverPage', params)
}

// 运输任务新增
export function add (params) {
  return postBodyRequest('/trans/transport/truck/task', params)
}
// 更改运输线路
export function changeLine (transTruckId, lineId, params) {
  return postBodyRequest(`/trans/transport/truck/${transTruckId}/changeLine/${lineId}`, params)
}
// 查运输任务的当前的车辆信息
export function vehicleId (transTruckId, params) {
  return postBodyRequest(`/trans/transport/vehicle/${transTruckId}`, params)
}
// 查运输任务的历史的车辆信息
export function vehicleIdHis (transTruckId, params) {
  return postBodyRequest(`/trans/transport/vehicle/${transTruckId}/his`, params)
}

// 查运输任务的所有有效的车辆信息
export function vehicleTruckAll (transTruckId, params) {
  return postBodyRequest(`/trans/transport/vehicle/${transTruckId}/all`, params)
}
// 运输任务详情
export function detail (params) {
  return getRequest(`/trans/transport/truck/${params}`)
}

// 查运输任务的归档车辆
export function vehicleTruckOnFile (transTruckId) {
  return getRequest(`/trans/transport/vehicle/${transTruckId}/onfile`)
}
// 批量交车-可安排小车列表
export function deliveryList (params) {
  return postBodyRequest('/trans/order/vehicle/not-delivery/page', params)
}
// 图片上传
export const fileUploadUrl = params => {
  return fileUpload(`/file/upload/${Math.floor(Math.random() * 1000)}`, params)
}