<template>
  
    <div  class="m-t1 customTags flex ju-between al-center">
      <span> {{ item.title }}</span>&nbsp;
      <CountTo color="#0066CC" fontSize="18px" :endVal="item.num" />
    </div>
   <CustomModal v-model:visible="visible"></CustomModal>
</template>

<script setup>
import { ref } from 'vue'
import CustomModal from './components/CustomModal.vue'
import CountTo from '@/components/countTo'
const props = defineProps({
  item: {
    type: Object,
    default: {}
  }
})

const visible = ref(false)
const openModal = () => {
  visible.value = true
}
</script>

<style lang="less" scoped>
.customTags {
  padding: 8px 10px;
  background: #EDF0F9;
  border-bottom: 1px solid #fff;
}
</style>