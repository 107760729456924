<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view></router-view>
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
// import { useStore } from 'vuex'
// import { useI18n } from 'vue-i18n'
// import { useStore } from 'vuex'
// import { computed } from 'vue'
// import { useRoute } from 'vue-router'

export default {
  data () {
    return {
    }
  },
  computed: {
    locale () {
      // 只是为了切换语言时，更新标题
      const { title } = this.$route.meta
      title && (setDocumentTitle(`${this.$t(title)} - ${domTitle}`))

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  }
  // setup () {
  //   const { t, getLocaleMessage } = useI18n()
  //   const store = useStore()
  //   const route = useRoute()
  //   const locale = computed(() => {
  //     const { title } = route.meta
  //     title && (setDocumentTitle(`${t(title)} - ${domTitle}`))
  //     return getLocaleMessage(store.getters.lang).antLocale
  //   })
  //   return {
  //     locale
  //   }
  // }
}
</script>
<style>
@import './styles/common.less'
</style>
