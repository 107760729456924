<template>
  <a-modal :visible="windowShow" title="选择提车到达地址" :destroyOnClose="true" @ok="confirmChoose" @cancel="closeWindow">
    <div>
      <div>
        <div>选择到达类型<span style="color:#f00">*</span></div>
        <a-select v-model:value="chooseType" style="width: 120px" @focus="focus" @change="handleChange">
          <a-select-option :value="1">办事处</a-select-option>
          <a-select-option :value="2">承运商</a-select-option>
        </a-select>
        <a-input v-model:value="storeName" :disabled="true" v-show="chooseType === 1" />
        <a-select style="width: 100%" v-model:value="storeDetail" v-show="chooseType === 1">
          <a-select-option v-for="(v, index) in addressList" :key="index" :value="v.name" @click="memberChange(v)">
            {{ v.name }}
          </a-select-option>
        </a-select>
      </div>
      <div v-show="chooseType === 2">
        <div>选择承运商<span style="color:#f00">*</span></div>
        <a-select style="width:100%" :filterOption="false" labelInValue show-search placeholder="请输入承运商名称搜索"
          @search="assignment">
          <a-select-option v-for="item in carrierList" :key="item.id" :value="item.carrierName"
            @click="memberChange(item)">{{ item.carrierName
            }}</a-select-option>
          <template v-if="fild" #notFoundContent>
            <a-spin size="small" />
          </template>
        </a-select>
        <div style="margin:10px 0">
          <a-select style="width:100%" v-if="addressShow">
            <a-select-option v-for="item in carrierAddressList" :key="item.id" @click="setAddress(item)">{{
              item.address + item.addressDetails }}</a-select-option>
          </a-select>
        </div>
        <a v-show="carrierData.id" @click="addAddressShow = !addAddressShow">+新增地址</a>
        <div v-show="addAddressShow">
          <Add @acceptCode='acceptCode' ref="addRef" />
        </div>
        <div>
          <div>对接人</div>
          <div><a-input v-model:value="chooseAddress.dockingPerson" /></div>
          <div>对接人联系方式</div>
          <div><a-input v-model:value="chooseAddress.dockingMobile" /></div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted, watch } from 'vue'
import { page as getCarrier } from '@/api/crm/carrer/carrier'
import { carrierAddress } from '@/api/global'
import { page as getCarrierAddress, deleteAddress, add } from '@/api/carrier/addressList'
import { message } from 'ant-design-vue'
import Add from '@/views/components/address'

export default {
  props: {
    windowShow: Boolean,
    operateDetail: Object
  },
  components: {
    Add
  },
  setup (props, context) {
    const state = reactive({
      fild: false,
      fetching: false,
      windowShow: false,
      addressShow: true,
      addAddressShow: false,
      timeOutId: null,
      chooseType: null,
      storeName: '', // 门店名称
      carrierName: '',
      address: '',
      storeDetail: '',
      operateDetail: {},
      carrierData: {},
      chooseAddress: {
        dockingPerson: '',
        dockingMobile: ''
      },
      carrierList: [],
      addressList: [], // 办事处门店列表 
      carrierAddressList: []
    })
    watch(props.windowShow, (newVal, oldVal) => {
      console.log(props.windowShow);
      state.windowShow = props.windowShow
      state.operateDetail = props.operateDetail
    })
    onMounted(() => {

    })
    const getCarrierList = () => {
      state.carrierList = []
      state.fild = true
      getCarrier({
        current: 1,
        size: 20,
        carrierName: state.carrierName
      }).then(res => {
        if (res.code === 10000) {
          state.carrierList = res.data.records
          state.fild = false
        }
      })
    }
    const handleChange = e => {
      if (e === 1) { // 办事处
        carrierAddress({
          isStore: 1,
          addressProvince: state.operateDetail.startProvince,
          addressCity: state.operateDetail.startCity
        }).then(res => {
          let arr = []
          res.data.forEach(item => {
            item.address.forEach((v, i) => {
              arr.push({ name: item.name + '-' + v, carrierId: item.carrierId,carrierName:item.name })
            })
          })
          setTimeout(() => {
            state.addressList = arr
          }, 100)
        })
      } else { // 承运商

      }
    }
    const getCarrierAddressList = () => {
      getCarrierAddress(state.carrierData.id, {
        current: 1,
        size: 999999
      }).then(res => {
        if (res.code === 10000) {
          state.carrierAddressList = res.data.records
          state.addressShow = true
        }
      })
    }
    // 搜索框值
    const assignment = (value) => {
      state.fetching = true
      if (value === '' || value === null) clearTimeout(state.timeOutId), state.fetching = false
      if (value.length > 1) {
        state.carrierName = value
        if (state.timeOutId !== null) {
          clearTimeout(state.timeOutId)
        }
        state.timeOutId = setTimeout(() => {
          getCarrierList()
        }, 1000)
      } else {
        state.carrierList = []
      }
    }
    const memberChange = (item) => {
      if (state.chooseType === 2) {
        state.carrierData = item
        state.addressShow = false
        state.carrierAddressList = []
        getCarrierAddressList()
        state.chooseAddress.dockingPerson = item.charge
        state.chooseAddress.dockingMobile = item.chargeMobile
      }else{
        console.log(item);
        state.carrierData = {}
        state.carrierData.addressDetails = item.name
        state.carrierData.carrierId = item.carrierId
        state.carrierData.carrierName = item.carrierName
      }
    }
    // 地址选择
    const setAddress = item => {
      let object = JSON.stringify(state.chooseAddress)
      state.chooseAddress = item
      state.chooseAddress = {
        ...item,
        carrierId: state.carrierData.id,
        carrierName: state.carrierData.carrierName,
        dockingPerson: JSON.parse(object).dockingPerson,
        dockingMobile: JSON.parse(object).dockingMobile,
      }
    }
    // 新增承运商地址
    const acceptCode = e => {
      if (e) {
        add(state.carrierData.id, e).then(res => {
          if (res.code === 10000) {
            state.addAddressShow = false
            getCarrierAddressList()
            message.info(res.msg)
          }
        })
      }
    }
    // 确认选择地址
    const confirmChoose = () => {
      if (state.chooseAddress !== '') {
        context.emit('address', state.chooseAddress)
        state.chooseAddress.dockingMobile = ''
        state.chooseAddress.dockingPerson = ''
      } else message.error('地址未选择')
    }
    const closeWindow = () => {
      state.chooseAddress.dockingPerson = ''
      state.chooseAddress.dockingMobile = ''
      context.emit('address', false)
    }

    return {
      ...toRefs(state),
      assignment,
      memberChange,
      setAddress,
      acceptCode,
      confirmChoose,
      closeWindow,
      handleChange
    }
  }
}
</script>

<style lang="less" scoped></style>