import storage from 'store'
import { login, getInfo, logout } from '@/api/login'
import Cookies from 'js-cookie'
import { welcome } from '@/utils/util'
import { SERVER_PROXY_WSS } from '@/api/api'
import { notification } from 'ant-design-vue'
let timer = null
const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    url: '',
    roles: [],
    info: {},
    tabs: []
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_UAT: (state, roles) => {
      state.uat = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_TABS_INIT (state, home) {
      state.tabs = [home]
    },
    SET_WEBSOCKET (state, websocket) {
      state.ws = websocket
    },
    BASE_URL (state, url) {
      state.url = url
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      userInfo.type = 2
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (response.code === 10000) {
            const result = response.data
            storage.set('accessToken', result.accessToken, result.expiresIn)
            if (Cookies.get('accessToken') !== null || Cookies.get('accessToken') !== undefined || Cookies.get('accessToken') !== '') {
              Cookies.remove('accessToken')
            }
            // 28799
            Cookies.set('accessToken', result.accessToken, { expires: new Date(new Date().getTime() + result.expiresIn * 1000), domain: '.' + result.systemDomain })
            commit('SET_TOKEN', result.accessToken)
            commit('SET_TABS_INIT', { name: 'home', meta: { title: 'menu.home' } })
            localStorage.tabs = JSON.stringify([{ name: 'home', meta: { title: 'menu.home' } }])
          }
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          if (response.code === 10000) {
            const result = response.data
            // WebSocket
            const ws = new WebSocket(SERVER_PROXY_WSS)
            ws.onopen = function (evt) {
              console.log(Date(), 'webSocket连接中...')
              if (storage.get('accessToken') != null) {
                console.log('webSocket已发送')
                ws.send(`{"clientTag":"USER_PC","msgType":1,"uniqueId":"${storage.get('accessToken')}","content":"hello"}`)
              }
            }
            ws.onclose = function (evt) {
              console.log(Date(), 'webSocket连接已断开,正在重新连接')
              notification.open({
                message: '提示',
                description:
                  '后台系统连接已断开,正在尝试重新连接...'
                // placement
              })
              ws.onopen()
            }
            // timer = setInterval(() => {
            // ws.onopen()
            // }, 300000)

            if (result.resourceList.length > 0) {
              commit('SET_WEBSOCKET', ws)
              // const expires = new Date(new Date().getTime() + 60 * 1000 * 28799 / 60)
              // console.log(new Date(new Date().getTime() + 28799 * 1000), expires)
              commit('SET_ROLES', result.resourceList)
              commit('SET_UAT', result.resourceSystemList)
              commit('SET_INFO', result)
            } else {
              reject(new Error('没有权限，请联系曹国娇!'))
            }
            commit('SET_NAME', { name: result.name, welcome: welcome() })
            commit('SET_AVATAR', result.avatar)
            resolve(response)
          } else {
            reject(response.msg)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          storage.remove('accessToken')
          clearInterval(timer)
          // sessionStorage.clear()
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
        })
      })
    }
  }
}

export default user