<template>
  <div style="margin-top:10px">
    <p>
      <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true"
        @change="setStartAddress" placeholder="请输入终点" />
      ——
      <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true"
        @change="setEndAddress" placeholder="请输入终点" />
      <a @click="confirmLine">确认地址</a>
      <!-- <a @click="searchLine(), linePage.current = 1" style="margin-left:15px">搜索</a>
      <a-divider type="vertical" />
      <a @click="getLine(), linePage.current = 1">系统推荐</a> -->
    </p>
    <div v-if="false" style="max-height:200px;overflow-y: scroll;overflow-x: hidden;" v-show="lineShow && !lineLoading">
      <div class="line-div" v-for="(item, index) in lineList" :key="index" @click="confirmLine(item)">
        <span>{{ item.name }}</span>
        <a class="confirm">确认选择</a>
      </div>
    </div>
    <a-skeleton v-show="lineLoading" active :paragraph="{ rows: 5 }" />
    <div v-show="lineList.length > 0 && lineShow" style="display:flex;justify-content:flex-end">
      <a-pagination size="small" :current="linePage.current" :total="linePage.total" :pageSize="linePage.pageSize"
        @change="lineListChange" />
    </div>
    <!-- <span v-show="lineList.length <= 0 && !lineLoading"><a @click="addLine">点击添加</a></span> -->
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { lineChoose, linePage } from '@/api/transport/transportUse'

export default {
  setup (props, context) {
    const state = reactive({
      lineShow: false,
      lineLoading: false,
      startName: '',
      endName: '',
      lineList: [],
      endAddressData: {},
      startAddressData: {},
      lineData: {
        startCityId: '',
        endCityId: ''
      },
      linePage: {
        current: 1
      },
    })
    const setEndAddress = (value, n) => {
      state.endAddressData.province = Number(value[0])
      state.endAddressData.city = Number(value[1])
      state.endAddressData.area = Number(value[2])
      if (n.length === 1) state.endName = n[0].label
      if (n.length === 2) state.endName = n[0].label + n[1].label
      if (n.length === 3) state.endName = n[0].label + n[1].label + n[2].label
    }
    const setStartAddress = (value, n) => {
      state.startAddressData.province = Number(value[0])
      state.startAddressData.city = Number(value[1])
      state.startAddressData.area = Number(value[2])
      if (n.length === 1) state.startName = n[0].label
      if (n.length === 2) state.startName = n[0].label + n[1].label
      if (n.length === 3) state.startName = n[0].label + n[1].label + n[2].label
    }
    // 获取推荐线路
    const getLine = () => {
      state.lineLoading = true
      linePage({ // 获取路线
        current: state.linePage.current,
        size: state.linePage.pageSize,
        startCityId: state.lineData.startCityId,
        endCityId: state.lineData.endCityId
      }).then(res => {
        if (res.code === 10000) {
          state.linePage.total = res.data.total
          state.lineList = res.data.records
          state.lineLoading = false
          state.lineShow = true
        }
      })
    }
    // 获取默认路线
    const getWhether = () => {
      if (state.detailData.orderVehicleId) {
        state.lineLoading = true
        lineChoose({
          transportType: state.transportType,
          orderVehicleIds: [state.detailData.orderVehicleId]
        }).then(res => {
          if (res.code === 10000) {
            console.log('线路信息', res.data);
            state.lineData = res.data
            state.lineLoading = false
            state.lineShow = true
          }
        }).catch(err => { console.log(err) })
      }
    }
    // 自定义搜索线路
    const searchLine = () => {
      state.lineShow = true
      state.lineLoading = true
      linePage({ // 获取路线 m
        current: state.linePage.current,
        size: state.linePage.pageSize,
        startProvinceId: state.startAddressData.province,
        startAreaId: state.startAddressData.area,
        startCityId: state.startAddressData.city,
        endProvinceId: state.endAddressData.province,
        endCityId: state.endAddressData.city,
        endAreaId: state.endAddressData.area
      }).then(res => {
        if (res.code === 10000) {
          state.linePage.total = res.data.total
          state.lineList = res.data.records
          state.lineLoading = false
        }
      })
    }
    // 确认路线
    const confirmLine = item => {
      state.lineData = {
        startAddress: state.startAddressData,
        endAddress: state.endAddressData,
        name: state.startName + '——' + state.endName
      }
      context.emit('lineData', state.lineData)
    }

    return {
      ...toRefs(state),
      getLine,
      getWhether,
      searchLine,
      confirmLine,
      setStartAddress,
      setEndAddress
    }
  }
}
</script>

<style lang="less" scoped>
.content-title {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  color: #344563;
  font-weight: 700;
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
}

.transport {
  border: 1px solid #eee;
  padding-top: 10px;

  .transport-choose {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
}

.line-div {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 12px;
  background-color: #fafafb;

  .confirm {
    display: none;
  }
}

.line-div:hover .confirm {
  display: block;
}
</style>