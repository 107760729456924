<template>
  <div>
    <a-modal :visible="visible" title="报价查询" width="70%" footer="" @cancel="cancel"  :bodyStyle="{padding:0,background:'#F4F5F7'}">
     <div class="flex ju-between">
      <div class="leftBox">
         <Inquiry></Inquiry>
      </div>
      <div class="leftBox">
           <div class="title">
              <div style="font-weight: 700;" class="fz-16">历史发运数据</div>
              <span style="color:#6B778C">默认显示最近10条，数据时间较长的请咨询您的组长</span>
           </div>
           <div class="content">

           </div>
      </div>
     </div>

    </a-modal>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { SwapOutlined, SearchOutlined } from '@ant-design/icons-vue';
import Inquiry from './inquiry.vue'

const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})






const cancel = () => {
  emit('update:visible', false)
}
</script>

<style lang="less" scoped>
.leftBox {
  width: 49.5%;
  height: 100%;
  background: #fff;
}
.iconBox {
  width: 120px;
  height: 32px;
  background: #0066cc;
  border-radius: 2px;
}
.tabBox {
  width: 80px;
  height: 35px;
  border: 1px solid #c1c7d0;
  border-right: transparent;
}
.bor-right {
  border-right: 1px solid #c1c7d0;
}

.changeStyle {
  background: #0066cc;
  border-color: #0066cc;
  color: #fff;
}

.title{
  background: #fff;
  padding:  10px 15px;
  border-bottom: 1px solid #eee;

}

.content{
  padding: 10px;
  min-height: 200px;
}
</style>