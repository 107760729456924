<template>
  <div>
    <a-modal :visible="visible" title="交车" width="30%" @ok="submit" @cancel="cancel" :confirmLoading="btnLoading">
      <a-form ref="formRef" :model="fromOpt" :rules="rulesOpt">
        <a-form-item name="deliveryType" label="收款对象">
          <div class="flex ">
            <div v-for="item in recipientOfPay" @click="celItem(item)" :key="item.value" class="itemBox m-r2 cur-p" :class="{ 'active': item.value === fromOpt.deliveryType }">
              {{ item.label }}
            </div>
          </div>
        </a-form-item>
        <a-form-item v-if="fromOpt.deliveryType === 2" name="officeOrgId" label="选择办事处">
          <a-select style="width:33%" @select="selectOrg" v-model:value="fromOpt.officeOrgId" placeholder="请选择">
            <a-select-option v-for=" item in $store.state.app.orgTypeList" :label="item.name" :key="item.id">{{ item.name
                }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="deliveryOfficeOrg" label="是否到达办事处">
          <a-radio-group name="radioGroup" v-model:value="fromOpt.deliveryOfficeOrg">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item name="fileId" label="交车条">
          <ly-upload ref="uploadRef" :maxMun="1" @fileStatus="fileStatus"></ly-upload>
        </a-form-item>
        <a-form-item  label="交车说明">
          <a-textarea v-model:value="fromOpt.remark" placeholder="请填写" :rows="2" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import lyFormList from '@/components/formList'
import { recipientOfPay } from "@/utils/enum.js";
import lyUpload from '@/components/ly-upload' 
import { message } from 'ant-design-vue';
const emit = defineEmits(['update:visible','getVal'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
})
const formRef = ref(null)
const uploadRef = ref(null)
const btnLoading = ref(false)
const fromOpt = ref({
  deliveryType: null,
  officeOrgId: null,
  officeOrgName: '',
  deliveryOfficeOrg: null,
  fileId: 1,
  remark:''
})
const rulesOpt = ref({
  deliveryType: [{ required: true, message: '请选择收款对象' ,type:'number',trigger: 'change',}],
  officeOrgId: [{ required: true, message: '请选择办事处' }],
  deliveryOfficeOrg: [{ required: true, message: '请选择是否到达办事处' }],
  fileId: [{ required: true, message: '请上传', type: 'number', trigger: 'change' }]
})
const fileStatus = (e) => {
  btnLoading.value = e
}
const cancel = () => {
  emit('update:visible', false)
}
const celItem = (item) => {
  fromOpt.value.deliveryType = item.value
  formRef.value.clearValidate('deliveryType')
}

const submit = () => {
  formRef.value.validate().then( () => {
    if(uploadRef.value.state.fileIds.length === 0){
      message.error('请上传交车条')
      return
    }
    emit('getVal',fromOpt.value,uploadRef.value.state.fileIds)
  })
}
defineExpose({
  btnLoading
})
</script>

<style lang="less" scoped>
.itemBox {
  padding: 6px 18px;
  border: 1px solid #c1c7d0;
  color: #6b778c;
  border-radius: 6px;
}
.active {
  background: #1890ff;
  border-color: #1890ff;
  color: #fff;
}
:deep(.ant-form-item-label) {
  width: 100%;
  text-align: left;
}
:deep(.ant-form-item) {
  margin-bottom: 5px;
}
:deep(.ant-radio-wrapper){
  margin-right: 38px;
}
</style>