<template>
  <a-tooltip v-if="showFullscreenBtn" @click="handleChange">
    <template #title>{{ value ? $t('fullscreen.ok'):$t('fullscreen')}}</template>
    <CompressOutlined v-if="value" />
    <ExpandOutlined v-else />
  </a-tooltip>
</template>
<script>
import { defineComponent, reactive, toRefs, computed, onMounted } from 'vue'
import {
  ExpandOutlined,
  CompressOutlined
} from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    ExpandOutlined,
    CompressOutlined
  },
  props: { value: { type: Boolean, defualt: false } },
  setup (props, { emit }) {
    const state = reactive({

    })
    const showFullscreenBtn = computed(() => {
      return window.navigator.userAgent.indexOf('MSIE') < 0
    })
    const handleFullscreen = () => {
      const main = document.body

      if (props.value) {
        if (document.fullscreenElement) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (main.requestFullscreen) {
          main.requestFullscreen()
        } else if (main.mozRequestFullScreen) {
          main.mozRequestFullScreen()
        } else if (main.webkitRequestFullScreen) {
          main.webkitRequestFullScreen()
        } else if (main.msRequestFullscreen) {
          main.msRequestFullscreen()
        }
      }
    }

    const handleChange = () => {
      handleFullscreen()
    }

    onMounted(() => {
      let isFullscreen = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen
      isFullscreen = !!isFullscreen
      document.addEventListener('fullscreenchange', () => {
        emit('update:value', !props.value)
      })
      document.addEventListener('mozfullscreenchange', () => {
        emit('update:value', !props.value)
      })
      document.addEventListener('webkitfullscreenchange', () => {
        emit('update:value', !props.value)
      })
      document.addEventListener('msfullscreenchange', () => {
        emit('update:value', !props.value)
      })
      emit('update:value', isFullscreen)
    })

    return {
      ...toRefs(state),
      showFullscreenBtn,
      handleChange
    }
  }
})
</script>
