import { postBodyRequest, getRequest,deleteBodyRequest } from '@/utils/axios'
// 分类
export function  msgCategory(params) {
  return postBodyRequest('/cis/msg/msg-category',params)
}
//消息分页
 export function msgPage(params) {
  return postBodyRequest('/cis/msg/page', params)
}
// 已读
export function  readID(id,type) {
  return getRequest(`/cis/msg/read?id=${id}&&type=${type}`)
}
// 未读数量
export function  msgUnread(params) {
  return postBodyRequest(`/cis/msg/unread-count`,params)
}