import {
  postBodyRequest,
  getRequest
} from '@/utils/axios'

export function page (params) {
  return postBodyRequest(`/carrier/carrier/driver/page`, params)
}

export function edit (params, carrierId) {
  return postBodyRequest(`/carrier/carrier/${carrierId}/driver/edit`, params)
}

export function add (params, carrierId) {
  return postBodyRequest(`/carrier/carrier/${carrierId}/driver/add`, params)
}
export function Freepage (params) {
  return postBodyRequest('/carrier/carrier/driver/page/free', params)
}

export function detail (id, driverId, params) {
  return getRequest(`/carrier/carrier/${id}/driver/${driverId}`, params)
}
