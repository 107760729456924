<template>
<div>
  <!-- 派单模块 -->
  <SendModal  v-model:visible="sendShow" v-if="sendShow"></SendModal>
</div>
</template>

<script setup>
import { ref } from 'vue'
import SendModal from '@/views/components/transport/taskAssignment/sendModal.vue'
const sendShow = ref(false)

defineExpose({
  sendShow
})

</script>

<style lang="less" scoped>

</style>