<template>
  <div id="map"></div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import endImg from '@/assets/images/takeCar.png'
import startImg from '@/assets/images/endCar.png'
export default {
  props: {
    num: Number
  },
  setup (props, ctx) {
    // console.log(props)
    const state = reactive({
      num: props.num
    })
    const createMap = (d) => {
      let map = ref()
      /* eslint-disable */
      // 创建Map实例
    }
    onMounted(createMap)

    return {
      ...toRefs(state),
    }
  }
}
</script>

<style lang="less" scoped>
#map {
  width: 100%;
  height: 100%;
}
</style>
