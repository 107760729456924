<template>
  <a-modal v-model:visible="modalShow" width="90%" footer="" @cancel="cancel" :bodyStyle="{ padding: '0 0 15px 0' }">
    <div class="title-content">
      <a-tabs @change="tabsChange" v-model:activeKey="activeKey" size="large">
        <a-tab-pane :key="1" tab="入库">
          <PutStorage ref="putStorageRef" @detail="getDetail" />
        </a-tab-pane>
        <a-tab-pane :key="2" tab="盘库">
          <CheckStorage ref="checkStorageRef" @detail="getDetail" />
        </a-tab-pane>
        <a-tab-pane :key="3" tab="出库">
          <OutStorage ref="outStorageRef" @detail="getDetail" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-modal>
  <!-- 车辆信息查看 -->
  <a-modal v-model:visible="detailShow" width="60%" footer="" @cancel="detailShow = false">
    <template #title>
      <div>
        <span>{{ element.vinNo + ' ' + element.brand + element.model }}</span>
        <a style="margin-left:10px;font-size:14px;margin-right:10px" @click="journalShow = true">信息日志</a>
        <a style="margin-left:10px;font-size:14px" @click="getUpdateImg">车辆图片</a>
      </div>
    </template>
    <div class="left-content">
      <TransportModel ref="transportModelRef" :windowShow="detailShow" :data="element"
        @statusShow="transportModelShowStatus" />
    </div>
  </a-modal>
  <!-- 信息日志 -->
  <a-modal v-model:visible="journalShow" width="40%" title="车辆日志" footer=""
    :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">
    <div>
      <Record v-if="element.orderVehicleId" :vehicleList="[]" :vehiclesId="element.orderVehicleId"
        :orderId="element.orderId" />
    </div>
  </a-modal>
  <!-- 车辆图片 -->
  <InspectionImg ref="imgListRef" :orderVehicleId="element.orderVehicleId" :loadInspectList="[]" />
</template>

<script>
import { reactive, toRefs, ref, watch } from 'vue'
import PutStorage from './putStorage.vue'
import CheckStorage from './checkStorage.vue'
import OutStorage from './outStorage.vue'
import TransportModel from '@/views/components/transport/smallVehicleOperate/transportModel.vue'
import InspectionImg from '@/views/components/vehicleUpload/inspectionImg'
import Record from '@/views/transportCapacity/transportUse/comon/record.vue'

export default {
  components: {
    PutStorage,
    CheckStorage,
    OutStorage,
    TransportModel,
    InspectionImg,
    Record
  },
  props: {
    showDOM: Boolean,
    shopOperateIndex: Number
  },
  setup (props, context) {
    const putStorageRef = ref(null)
    const checkStorageRef = ref(null)
    const outStorageRef = ref(null)
    const imgListRef = ref(null)
    const transportModelRef = ref(null)
    const state = reactive({
      modalShow: false,
      journalShow: false,
      detailShow: false,
      element: {},
      activeKey: 1,
      shopOperateIndex: props.shopOperateIndex
    })
    watch(() => props.showDOM, (newVal, oldVal) => {
      state.modalShow = props.showDOM
      if (state.modalShow === true) {
        state.activeKey = 1
        setTimeout(() => {
          putStorageRef.value.loadData()
        }, 50)
      }
    })
    const tabsChange = e => {
      switch (e) {
        case 1:
          setTimeout(() => {
            // putStorageRef.value.loadData()
            // putStorageRef.value.getStatistics()
          }, 50)
          break
        case 2:
          setTimeout(() => {
            checkStorageRef.value.loadData()
          }, 50)
          break
        case 3:
          setTimeout(() => {
            outStorageRef.value.loadData()
          }, 50)
          break
      }
    }
    const cancel = () => {
      state.modalShow = false
      context.emit('statusShow', false)
    }
    const getDetail = e => {
      console.log(e);
      if (e.orderVehicleId) {
        state.element = e
        state.detailShow = true
        setTimeout(()=>{
          transportModelRef.value.getTaskListData()
        },50)
      }
    }
    // 车辆图片
    const getUpdateImg = () => {
      setTimeout(() => {
        imgListRef.value.inspectionImg = true
        imgListRef.value.loadData(state.element.orderVehicleId)
      }, 100)
    }
    return {
      ...toRefs(state),
      putStorageRef,
      checkStorageRef,
      imgListRef,
      outStorageRef,
      transportModelRef,
      tabsChange,
      cancel,
      getUpdateImg,
      getDetail
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';

:deep(.ant-modal-body) {
  background-color: #f00;
  padding: 10px !important;
  padding-left: 0 !important;
}

.ant-modal-body {
  padding: 10px !important;
  padding-left: 0 !important;
}

.title-content {
  :deep(.ant-tabs-ink-bar) {
    display: none !important;
  }

  :deep(.ant-tabs-tab) {
    margin: 0 !important;
    color: #6B778C;
    font-weight: bold;
    font-size: 18px;
    padding: 10px 40px !important;
    background-color: #F2F2F2;
  }

  :deep(.ant-tabs-tab-active) {
    color: #fff !important;
    background-color: #0066CC;
  }
}
</style>