import { getRequest, postBodyRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/carrier/carrier/page', params)
}

export function detail (id, params) {
  return getRequest(`/carrier/carrier/${id}`, params)
}

export function fuzzy (params) {
  return postBodyRequest('/carrier/carrier/fuzzy', params)
}

export function fuzzyAll (params) {
  return postBodyRequest('/carrier/carrier/fuzzy/all', params)
}

export function add (params) {
  return postBodyRequest('/carrier/carrier/add', params)
}

export function edit (params) {
  return postBodyRequest('/carrier/carrier/edit', params)
}
