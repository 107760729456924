<template>
  <div class="countTo">
    <countTo :startVal="startVal"  :style="{ color:color,fontSize:fontSize,fontWeight:700}"  :endVal="endVal" :duration="duration"></countTo>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { CountTo } from 'vue3-count-to'
const props = defineProps({
  color:{
    type:String,
    default:'red'
  },
  fontSize:{
   type:String,
    default:'16px'
  },
  endVal:{
    type:Number,
    default:10
  },
  duration:{
    type:Number,
    default:2000
  }
  })
const startVal = ref(0)
const flag = ref(0)
watch(() => props.endVal,(val,oldVal)=> {
    startVal.value = oldVal
    if(val > oldVal){
      flag.value = 2
    }else{
      flag.value = 1
    }
})
</script>

<style lang="less" scoped>
.countTo{
  display: inline-block;
}
</style>