<template>
  <div>
    <a-modal :visible="visible" title="待推送" footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <SearchList :iptData="iptData"></SearchList>
      <div class="m-t1 tableBox">
        <div class="m-b1">共4条数据</div>
        <a-table :dataSource="dataSource" :columns="columns" bordered :pagination="false">
          <template #add="{ record }">
            <a @click="add(record)">操作</a>
          </template>
          <template #vinNo="{ record }">
            <a @click="add(record)">{{ record.vinNo }}</a>
          </template>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>   
   
  <script setup>
import { ref } from 'vue'
import SearchList from '@/components/searchList'
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
})
const dataSource = ref([{

}])


const add = (record) => {
  addShow.value = true 
}
const cancel = () => {
  emit('update:visible', false)
}

const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "status",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
])
const columns = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'add'
    }
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
    slots: {
      customRender: 'vinNo'
    }
  },
  {
    title: '订单号',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'taskStatus',
    align: 'center',
  },

  {
    title: '起-终',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '确认运输时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '超时状态',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '验车完成时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '照片数量',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}
</style>