import { getRequest, postBodyRequest } from '@/utils/axios'

// 部门配置-----分页
export function dispathPage (params) {
  return postBodyRequest('/trans/order/org/getOrgDispatchPage', params)
}
// 部门配置-----新增/编辑
export function dispathEdit (params) {
  return postBodyRequest('/trans/order/org/editOrgDispatch', params)
}
// 线路配置----分页
export function routeDeplotPage (params) {
  return postBodyRequest('/trans/order/org/getCarrierLineConfigPage', params)
}
// 线路配置----新增/编辑
export function routeDeplotEdit (params) {
  return postBodyRequest('/trans/order/org/editCarrierLineConfig', params)
}
// 批量车配置----分页
export function batchVehiclePage (params) {
  return postBodyRequest('/trans/order/org/getCarrierNumConfigPage', params)
}
// 批量车----新增/编辑
export function batchVehicleEdit (params) {
  return postBodyRequest('/trans/order/org/editCarrierNumberConfig', params)
}
// 排班配置----分页
export function schedulePage (params) {
  return postBodyRequest('/trans/order/config/getEmpScheduleConfigPage', params)
}
// 排班配置----新增/编辑
export function scheduleEdit (params) {
  return postBodyRequest('/trans/order/config/editEmpScheduleConfig', params)
}
// 新增员工分组
export function addGruop (params) {
  return postBodyRequest('/trans/order/config/addGroup', params)
}
// 编辑权限
export function editGruop (params) {
  return postBodyRequest('/trans/order/config/edit-group', params)
}
// 员工分组
export function getGroupPage (params) {
  return postBodyRequest('/trans/order/config/getGroupPage', params)
}
// 添加组员
export function addGroupMember (params) {
  return postBodyRequest('/trans/order/config/addEmpGroup', params)
}
// 组内成员列表
export function getEmpGroupList (params) {
  return getRequest('/trans/order/config/getEmpGroupList', params)
}
// 删除组员
export function deleteGroupEmp (params) {
  return getRequest('/trans/order/config/deleteEmpGroup', params)
}
// 解散小组
export function dismissGroupOperate (params) {
  return getRequest('/trans/order/config/dissolveGroup', params)
}
// 获取小组成员列表
export function getGroupList (params) {
  return getRequest('/trans/order/config/getGroupEmployeeList', params)
}
// 获取当前登录账户的身份：组长/资源
export function getIdentity (params) {
  return getRequest('/trans/order/config/getEmpGroup', params)
}
// 分享线路列表
export function getShareLinePage (params) {
  return postBodyRequest('/trans/order/config/getShareLinePage', params)
}
// 添加分享线路
export function addShareLine (params) {
  return postBodyRequest('/trans/order/config/addShareLine', params)
}
// 删除分享线路
export function deleteShareLine (params) {
  return getRequest('/trans/order/config/deleteShareLine', params)
}
