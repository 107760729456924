import { ref } from 'vue'

export const carryiptData1 = ref([
  {
    type: "address",
    placeholder: "取车城市",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "select",
    placeholder: "取车类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
])
export const  carrycolumns1 = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    }
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '取车地址',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '取车类型',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '起运地交车人',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '确认运输时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '预约时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus1',
    align: 'center',
  },

  {
    title: '业务员',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '验车人',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '验车完成时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
export const carryiptData2 = ref([
  {
    type: "address",
    placeholder: "取车城市",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "select",
    placeholder: "取车类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "安排人",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
])
export const  carrycolumns2 = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    }
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '取车地址',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '取车类型',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '起运地交车人',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '确认运输时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '预约时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus1',
    align: 'center',
  },

  {
    title: '业务员',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '安排人',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '安排时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
export const carryiptData3 = ref([
  {
    type: "address",
    placeholder: "取车城市",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "select",
    placeholder: "取车类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "发车人",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
])
export const  carrycolumns3 = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    }
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '取车地址',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '取车类型',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '起运地交车人',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '确认运输时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '预约时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus1',
    align: 'center',
  },

  {
    title: '业务员',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '发车人',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '发车时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
export const carryiptData4 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "address",
    placeholder: "当前城市",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
])
export const  carrycolumns4 = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    }
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '上段完成时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '预计发车时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '当前城市',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
export const carryiptData5 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "address",
    placeholder: "当前城市",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "安排人",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
])
export const  carrycolumns5 = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    }
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '预计发车时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '安排时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '安排人',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '大车车牌',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
export const carryiptData6 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "发车人",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
]) 
export const  carrycolumns6 = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    }
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '起-终',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '发车时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '发车人',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '当前位置',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '大车车牌',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
export const carryiptData7 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "select",
    placeholder: "送车类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
]) 
export const  carrycolumns7 = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    }
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '送车地址',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '送车类型',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '目的地取车人',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '上段卸车时间',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '结算信息',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '收款码',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
export const carryiptData8 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "select",
    placeholder: "送车类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
]) 
export const  carrycolumns8 = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    }
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '送车地址',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '送车类型',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '目的地交车人',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '安排信息',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '结算信息',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '收款码',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
export const carryiptData9 = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "vinNo",
    width: 150,
    changeOn:true
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[
      {
        name: '是',
        id: 1
      },
      {
        name: '否',
        id: 0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "select",
    placeholder: "送车类型",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 180,
  },
  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "承运商",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "发车人",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "小车车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
]) 
export const  carrycolumns9 = ref([
  {
    title: '操作',
    dataIndex: 'add',
    align: 'center',
    slots: {
      customRender: 'add'
    }
  },
  {
    title: '车牌/车型',
    dataIndex: 'vinNo',
    align: 'center',
  },
  {
    title: '送车地址',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '送车类型',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '目的地交车人',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '发车信息',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '承运商',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '结算信息',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '收款码',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])