<template>
  <div>
    <div class="carrier-name">
      {{ customerName }}
    </div>
    <div class="list-data">
      <div class="content-data" v-for="item in receiverArray" :key="item.id">
        <div style="width:100px">{{ item.name }}</div>
        <div>{{ item.mobile }}</div>
        <div><a-button type="primary" size="small" @click="choose(item)">选择</a-button></div>
      </div>
    </div>
    <div><a @click="addShow = true">新增对接人</a></div>
    <div v-show="addShow">
      <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical">
        <a-form-item label="对接人姓名" name="name">
          <a-input v-model:value="addForm.name" />
        </a-form-item>
        <a-form-item label="对接人电话" name="mobile">
          <a-input v-model:value="addForm.mobile" />
        </a-form-item>
        <a-form-item label="身份证号">
          <a-input v-model:value="addForm.certificateNumber" />
        </a-form-item>
        <!-- <a-form-item label="备注">
          <a-textarea  v-model:value="addForm.remark" :rows="4"/>
        </a-form-item> -->
        <a-form-item label="" style="margin-top:15px">
          <a-button class="confirm-add-btn" @click="confirmAdd">确定</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch, ref, onMounted } from 'vue'
import { contactCustomerIdPage, add } from '@/api/crmManagement/contact'
import { message } from 'ant-design-vue'

export default {
  props: {
    customerName: String,
    customerId: String
  },
  setup (props, context) {
    const addFormRef = ref(null)
    const state = reactive({
      customerName: props.customerName,
      customerId: props.customerId,
      addShow: false,
      receiverArray: [],
      rules: {
        name: { required: true, message: '请填写联系人名称', trigger: 'blur' },
        mobile: [{ required: true, message: '请填写电话号码', trigger: 'blur' }]
      },
      addForm: {
        name: '',
        mobile: '',
        certificateNumber: ''
        // remark: ''
      },
      mirrorAddForm: {}
    })
    onMounted(() => {
      if (props.customerId) getContachList()
      state.mirrorAddForm = JSON.stringify(state.addForm)
    })
    watch(
      () => props.customerId,
      (newValue, oldValue) => {
        state.customerId = props.customerId
        state.customerName = props.customerName
        if (newValue && newValue !== oldValue) {
          getContachList()
        }
      }
    )
    const confirmAdd = () => {
      addFormRef.value.validate().then(res => {
        add({ customerContact: { ...state.addForm, customerId: props.customerId } }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            getContachList()
            clearData()
          }
        })
      })
    }
    const clearData = () => {
      state.addForm = JSON.parse(state.mirrorAddForm)
      state.addShow = false
    }
    const getContachList = () => {
      contactCustomerIdPage(state.customerId, {
        size: 9999,
        current: 1
      }).then(res => {
        if (res.code === 10000) {
          state.receiverArray = res.data.records
        }
      })
    }
    const choose = item => {
      context.emit('contact', item)
    }
    return {
      ...toRefs(state),
      addFormRef,
      confirmAdd,
      getContachList,
      choose,
      clearData
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-form-item) {
  margin-bottom: 0 !important;
}

::webkit-scrollbar {
  width: 3px !important;
  height: 3px !important;
}

.carrier-name {
  padding: 15px;
  border: 1px solid #eee;
  margin-bottom: 20px;
}

.list-data {
  max-height: 40vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  .content-data {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #f2f2f2;
    background: #F6F6F6;
  }
}
</style>