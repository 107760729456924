<template>
  <a-card hoverable>
    <template #cover>
      <div class="upload-image" v-if="form.fileId">
        <div class="upload-image-info">
          <a-image v-if="form.fileRealUrl !== null && form.fileRealUrl !== ''" ref="imageRef" style="height:140px"
            :preview="preview" :src="form.fileUrl" />
          <a-image v-else ref="imageRef" style="height:140px" :preview="preview" :src="form.fileUrl" />
        </div>
        <div class="upload-image-actions">
          <a-button type="link" @click="handlePreview">
            <EyeOutlined />
            预览
          </a-button>
          <a-button type="link" :disabled="percent != 0" @click="deleteImg">删除图片</a-button>
          <!-- <a-upload v-model:file-list="imageList" accept="image/*" @change="handleChange" :before-upload="beforeUpload"
            :show-upload-list="false">
          </a-upload> -->
        </div>
      </div>
      <a-upload-dragger v-else :disabled="percent > 0" class="avatar-uploader" v-model:fileList="imageList"
        accept="image/*" @change="handleChange" :before-upload="beforeUpload" :show-upload-list="false">
        <div>
          <plus-outlined></plus-outlined>
          <div class="ant-upload-text">上传公司要求图片</div>
        </div>
      </a-upload-dragger>
    </template>
    <div style="display:flex;justify-content: space-between;font-size:12px">
      <span>{{ form.creator }}</span>
      <span>{{ form.createTime }}</span>
    </div>
    <div v-if="form.inspectType">
      <a-card-meta :title="form.inspectType.label" style="font-size: 12px;">
        <template #description>
          <!-- <div style="margin-left:20px;">
          <a-typography-paragraph v-model:content="form.description" :editable="editable" />
        </div> -->
          <div class="editable-cell-input-wrapper">
            <a-textarea v-model:value="form.fileRemark" placeholder="填写备注" :maxlength="200" showCount />
            <a-button type="link" @click="save">
              保存备注
            </a-button>
            <a-button type="link" @click="onCancel">
              取消
            </a-button>
          </div>
          <!-- <div v-else>
            {{ form.fileRemark}}
            <a-button type="link" @click="edit">
              <EditOutlined />
            </a-button>
          </div> -->
        </template>
      </a-card-meta>
    </div>
  </a-card>
</template>
<script>
import { EyeOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { reactive, ref, toRefs } from 'vue'
import { inspectUpload, uploadVehicle as imageUpload, deleteVehiocleImg } from '@/api/global'
import { confirmWindow } from '@/utils/util'
import { message } from 'ant-design-vue'

function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'ImageUpload',
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          id: '',
          fileId: '',
          fileUrl: '',
          fileRealUrl: '',
          orderVehicleId: '',
          inspectType: {
            label: '',
            value: 0
          },
          fileRemark: ''
        }
      }
    }
  },
  components: {
    EyeOutlined,
    PlusOutlined
  },
  emits: [
    'change', 'inspectionLoadData'
  ],
  setup (props, { emit }) {
    const imageRef = ref()
    const data = reactive({
      progress: false,
      percent: 0,
      imageList: [],
      preview: false,
      fileUrl: '',
      fileRealUrl: props.info.fileUrl,
      form: props.info,
      editable: true
    })
    const handlePreview = () => {
      data.preview = {
        visible: true,
        onVisibleChange: (visible, prevVisible) => {
          !visible && (data.preview = visible)
        }
      }
      data.form.fileUrl = data.form.fileRealUrl
    }

    const beforeUpload = file => {
      data.imageList = [file]
      return false
    }

    const handleChange = info => {
      data.percent = 10
      const timer = setInterval(() => { // 进度条数值
        data.percent += 5 / Math.round(info.fileList[0].size / 1024 / 1024)
        if (Number(formData.start) === 100) data.percent = 100
      }, 100)
      const formData = new FormData()
      formData.append('file', info.fileList[0].originFileObj)
      imageUpload(formData).then(res => {
        if (res.code === 10000) {
          data.form.fileId = res.data.id
          data.form.fileUrl = res.data.previewUrl
          data.form.fileRealUrl = res.data.url
          getBase64(info.fileList[0].originFileObj, base64Url => {
            data.fileUrl = base64Url
          })
          save()
          setTimeout(() => {
            clearInterval(timer)
            data.percent = 0
          }, 1000)
        }
      }).catch(err => console.log(err))
      // uploadVehicle(formData).then(res => {
      //   // console.log(formData.start)
      //   if (res.code === 10000) {
      //     data.form.fileId = res.data.id
      //     data.form.fileUrl = null
      //     data.fileUrl = res.data.url
      //     console.log(res.data)
      //     getBase64(info.fileList[0].originFileObj, base64Url => {
      //       data.fileUrl = base64Url
      //     })
      //     save()
      //     setTimeout(() => {
      //       clearInterval(timer)
      //       data.percent = 0
      //     }, 1000)
      //   }
      // })
      data.imageList = []
    }
    const edit = () => {
      // data.description = data.form.description
      data.editable = true
    }
    const save = () => {
      inspectUpload(props.info.orderVehicleId, { ...data.form, id: props.info.id }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          emit('inspectionLoadData', true)
          data.editable = false
        }
      })
    }
    const deleteImg = () => {
      confirmWindow('是否删除此图片?',requireAPI)
      function requireAPI (resolve) {
        deleteVehiocleImg(data.form.id).then(res => {
          if (res.code === 10000) {
            message.success(data.form.inspectType.label + '删除成功')
            data.form.createTime = null
            data.form.creator = null
            data.form.fileId = null
            data.form.fileRealUrl = null
            data.form.fileUrl = null
            let flag = null
            if (data.form.inspectType.value === 2) flag = true
            else flag = false
            emit('inspectionLoadData', true, flag)
          }
        }).finally(()=>{resolve()})
      }
    }

    const onCancel = () => {
      data.editable = true
    }

    return {
      imageRef,
      ...toRefs(data),
      handlePreview,
      beforeUpload,
      handleChange,
      deleteImg,
      edit,
      save,
      onCancel
    }
  }
}
</script>
<style lang="less" scoped>
:deep .ant-upload {
  &-drag {
    .ant-upload {
      padding: 0;
    }
  }
}

:deep(.ant-btn-link) {
  border: none;
  box-shadow: none;
}

.ant-modal {
  :global(.ant-col .ant-card .ant-card-body) {
    padding: 10px;
  }

  :global(.ant-col .ant-card .ant-card-body .ant-card-meta-title) {
    font-size: 12px;
  }

  :global(.ant-col .ant-card .ant-card-body .ant-card-meta-detail .ant-input) {
    font-size: 12px;
  }

  :global(.ant-col .ant-card .ant-card-body .ant-card-meta-detail .ant-btn) {
    font-size: 12px;
    padding: 2px 2px;
  }

  :global(.ant-col .ant-card .ant-card-cover .ant-upload) {
    height: 140px;
  }
}

:deep .ant-card-body {
  padding: 0;
}

:deep .ant-col>div {
  height: 300px;
  font-size: 13px;
}

:deep .avatar-uploader {
  .ant-upload {
    height: 140px;
  }
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload-image {
  position: relative;
  height: 140px;

  &-info {
    &::before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.3s;
      content: ' ';
    }
  }

  &:hover {

    .upload-image-actions,
    .upload-image-info::before {
      opacity: 1;
    }
  }

  &-actions {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s;
  }
}
</style>
