import { postBodyRequest, getRequest } from '@/utils/axios'

export function getPage (params) {
  return postBodyRequest('/carrier/carrier/page', params)
}

export function addCarrier (params) {
  return postBodyRequest('/carrier/carrier/add', params)
}

export function detailData (carrierId, params) {
  return getRequest(`/carrier/carrier/${carrierId}`, params)
}

export function dominantLine (carrierId, params) {
  return getRequest(`/carrier/carrier/dominantLine/${carrierId}`, params)
}
// 评级
export function SelectionGrade (params) {
  return postBodyRequest('/carrier/carrier/updateLevelTag', params)
}
// 拉黑
export function black (carrierId, params) {
  return getRequest(`/carrier/carrier/block/${carrierId}`, params)
}
// 审批
export function approval (params) {
  return postBodyRequest(`/carrier/carrier/approval`, params)
}
// 拉黑-新
export function filterOutAPI (params) {
  return postBodyRequest('/carrier/carrier/carrier-block', params)
}
// 拉黑原因
export function filterOutReason (params) {
  return getRequest('/carrier/carrier/rate/block-reason-type', params)
}
// 消费统计
export function consumptionData (params) {
  return getRequest('/anal/carrier/consumption', params)
}
// 重新启用
export function carrierUse (params) {
  return postBodyRequest('/carrier/carrier/rate/carrier-re-enable', params)
}
// 取消拉黑
export function carrierActivate (params) {
  return postBodyRequest('/carrier/carrier/carrier-unblock', params)
}
// 流转日志
export function circulationPage (params) {
  return postBodyRequest('/carrier/carrier/rate/period-circulation-page', params)
}
// 统计列表
export function carrierStatistics (params) {
  return postBodyRequest('/anal/carrier/statistics/page', params)
}
// 质损统计
export function massLossPage (params) {
  return postBodyRequest('/anal/carrier/loss/page', params)
}
// 质损明细
export function massLossDetail (params) {
  return postBodyRequest('/trans/finance/quality/loss/detail/page', params)
}
// 线路统计
export function lineStatisticsData (params) {
  return postBodyRequest('/anal/carrier/trans-line/page', params)
}
export function cityLineData (params) {
  return postBodyRequest('/anal/carrier/city-line/page', params)
}
//单个承运商统计
export function singleStatistics (params) {
  return postBodyRequest('/anal/carrier/statistics', params)
}
// 固定承运商
export function carrierSettled (params) {
  return getRequest('/carrier/carrier/settled/list', params)
}
// 承运商线路询价
export function linePriceInquiry (params) {
  return postBodyRequest('/carrier/carrier/line/price/inquiry', params)
}