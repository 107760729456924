// import { result } from "lodash"
import { list as orgList } from '@/api/system/organzition'
import { Modal } from 'ant-design-vue'
import { createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export const timeFix = () => {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export const welcome = () => {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * @param {*} num 数字大写
 * @returns
 */
export const numUppercase = num => {
  const changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  const unit = ['', '十', '百', '千', '万']
  num = parseInt(num)
  const getWan = (temp) => {
    const strArr = temp.toString().split('').reverse()
    let newNum = ''
    for (let i = 0; i < strArr.length; i++) {
      newNum = (i === 0 && strArr[i] === 0 ? '' : (i > 0 && strArr[i] === 0 && strArr[i - 1] === 0 ? '' : changeNum[strArr[i]] + (strArr[i] === 0 ? unit[0] : unit[i]))) + newNum
    }
    return newNum
  }
  const overWan = Math.floor(num / 10000)
  let noWan = num % 10000
  if (noWan.toString().length < 4) {
    noWan = '0' + noWan
  }
  return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
}

/**
 * @param {*} num 金额大写
 * @returns
 */
export const amountUppercase = num => {
  num = num === null ? 0 : num
  num = num === undefined ? 0 : num
  const fraction = ['角', '分']
  const digit = [
    '零', '壹', '贰', '叁', '肆',
    '伍', '陆', '柒', '捌', '玖'
  ]
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ]
  const head = num < 0 ? '负' : ''
  num = Math.abs(num)
  let s = ''
  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(num * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '')
  }
  s = s || '整'
  num = Math.floor(num)
  for (let i = 0; i < unit[0].length && num > 0; i++) {
    let p = ''
    for (let j = 0; j < unit[1].length && num > 0; j++) {
      p = digit[num % 10] + unit[1][j] + p
      num = Math.floor(num / 10)
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
  }
  return head + s.replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整')
}

/**
 * @param {*} value 一键复制文本
 * @returns
 */
export const copyText = value => {
  // 直接构建input
  const input = document.createElement('textarea')
  // 设置内容
  input.value = value
  // 添加临时实例
  document.body.appendChild(input)
  // 选择实例内容
  input.select()
  // 执行复制
  document.execCommand('Copy')
  // 删除临时实例
  input.remove()
}

/**
* 自定义函数名：补 00
* @param num： 被操作数
* @param n： 固定的总位数
*/
export const PrefixZero = (num, n) => {
  return (Array(n).join(0) + num).slice(-n)
}

/**
 * 数字，隔断
 * @param {*} value
 * @returns
 */
export const formatNumber = value => {
  value += ''
  const list = value.split('.')
  const prefix = list[0].charAt(0) === '-' ? '-' : ''
  let num = prefix ? list[0].slice(1) : list[0]
  let result = ''

  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`
    num = num.slice(0, num.length - 3)
  }

  if (num) {
    result = num + result
  }

  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`
}

/**
 * @param {Number} times 回调函数需要执行的次数
 * @param {Function} callback 回调函数
 */
export const doCustomTimes = (times, callback) => {
  let i = -1
  while (++i < times) {
    callback(i)
  }
}
/**
 * @description 根据name/params/query判断两个路由对象是否相等
 * @param {*} route1 路由对象
 * @param {*} route2 路由对象
 */
export const routeEqual = (route1, route2) => {
  // const query1 = route1.query || {}
  // const query2 = route2.query || {}
  return (route1.name === route2.name) // && objEqual(query1, query2)
}

/**
 * @param {*} obj1 对象
 * @param {*} obj2 对象
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */
export const objEqual = (obj1, obj2) => {
  const keysArr1 = Object.keys(obj1)
  const keysArr2 = Object.keys(obj2)
  if (keysArr1.length !== keysArr2.length) return false
  else if (keysArr1.length === 0 && keysArr2.length === 0) return true
  /* eslint-disable-next-line */
  else return !keysArr1.some(key => obj1[key] != obj2[key])
}

/**
 * 判断打开的列表里是否已存在这个新添加的路由对象
 * @param {*} tagNavList
 * @param {*} routeItem
 * @returns
 */
export const routeHasExist = (tabList, routeItem) => {
  const len = tabList.length
  let res = false
  doCustomTimes(len, (index) => {
    if (routeEqual(tabList[index], routeItem)) res = true
  })
  return res
}
/**
 * @param {Array} list 标签列表
 * @param {String} name 当前关闭的标签的name
 */
export const getNextRoute = (list, route) => {
  let res = {}
  if (list.length === 2) {
    res = getHomeRoute(list)
  } else {
    const index = list.findIndex(item => routeEqual(item, route))
    if (index === list.length - 1) res = list[list.length - 2]
    else res = list[index + 1]
  }
  return res
}

/**
 * @param {Array} routers 路由列表数组
 * @description 用于找到路由列表中name为home的对象
 */
export const getHomeRoute = (routers, homeName = '/transport/regionalStatistics') => {
  let i = -1
  const len = routers.length
  let homeRoute = {}
  while (++i < len) {
    const item = routers[i]
    if (item.children && item.children.length) {
      const res = getHomeRoute(item.children, homeName)
      if (res.name) return res
    } else {
      if (item.name === homeName) homeRoute = item
    }
  }
  return homeRoute
}

/**
 * @param {Array} routeMetched 当前路由metched
 * @returns {Array}
 */
export const getBreadCrumbList = (route, homeRoute) => {
  // const homeItem = { ...homeRoute, icon: homeRoute.meta.icon }
  const homeItem = { ...homeRoute, icon: homeRoute.meta.icon }
  const routeMetched = route.matched
  if (routeMetched.some(item => item.name === homeRoute.name)) return [homeItem]
  let res = routeMetched.filter(item => {
    return item.meta === undefined || !item.meta.hideInBread
  }).map(item => {
    const meta = { ...item.meta }
    if (meta.title && typeof meta.title === 'function') {
      meta.__titleIsFunction__ = true
      meta.title = meta.title(route)
    }
    const obj = {
      icon: (item.meta && item.meta.icon) || '',
      name: item.name,
      meta: meta
    }
    return obj
  })
  res = res.filter(item => {
    return !item.meta.hideInMenu
  })
  return [{ ...homeItem, to: homeRoute.path }, ...res]
}

// 日期获取 YYY-MMM-DD HHH-MMM-SSS
export const getTime = (num) => {
  const myDate = new Date()
  const week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  const weeks = myDate.getDay()
  const H = PrefixZero(myDate.getHours(), 2)
  const M = PrefixZero(myDate.getMinutes(), 2)
  const S = PrefixZero(myDate.getSeconds(), 2)
  const YMD = myDate.toLocaleDateString().split('/')
  for (let i = 1; i < 2; i++) {
    YMD[i] = PrefixZero(YMD[i], 2)
  }
  let data = null
  if (num === 1) data = YMD.join('-') + ' ' + H + ':' + M + ':' + S
  else data = YMD.join('-') + ' ' + H + ':' + M + '  ' + week[weeks]
  return data
}
// 日期获取 YYY-MMM-DD
export const getTimeYMD = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  // const hour = date.getHours()
  // const second = date.getSeconds()
  // const minute = date.getMinutes()
  // const week = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  // const weeks = date.getDay()
  return year + '-' + PrefixZero(month, 2) + '-' + PrefixZero(day, 2)
}
// 空值转换 int
export const conversionN = data => {
  if (data === null || data === undefined) return 0
  else return data
}
// 空值转换 str
export const conversionS = data => {
  if (data === null || data === undefined) return ''
  else return data
}

// 获取机构
export const getOrgList = type => {
  return orgList({ orgType: type }).then(res => {
    if (res.code === 10000) return res.data
  })
}
// 防抖节流   _that传state,fn为方法体,delay为延迟时间
export const debounce = (_that, fn, delay) => {
  if (_that.timer !== null) {
    clearTimeout(_that.timer)
  }
  _that.timer = setTimeout(() => {
    fn()
  }, delay)
}
// 换算文件大小---适用于上传前判断是否超出最大限制
export const formatBytes = (a, b) => { // a文件的大小，b换算时保留的小数位数
  if (0 == a) return "0 B"
  let c = 1024, d = b || 2, e = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], f = Math.floor(Math.log(a) / Math.log(c))
  return parseFloat((a / Math.pow(c, f)).toFixed(d))
  // return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f] // 单位MB
}

// 全屏化
export const fullScreen = (boolean, ref) => { // boolean全屏图标状态，ref需要全屏的组件
  if (!boolean) {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  } else {
    if (ref.value.requestFullscreen) {
      ref.value.requestFullscreen()
    } else if (ref.value.mozRequestFullScreen) {
      ref.value.mozRequestFullScreen()
    } else if (ref.value.webkitRequestFullScreen) {
      ref.value.webkitRequestFullScreen()
    } else if (ref.value.msRequestFullscreen) {
      ref.value.msRequestFullscreen()
    }
  }
}

// 确认弹窗 content为提示语  require为接口调用
export const confirmWindow = (content, require) => {
  Modal.confirm({
    title: () => '确认操作？',
    icon: () => createVNode(ExclamationCircleOutlined),
    content: () => createVNode(
      'div',
      {
        style: 'color:red;',
      },
      content || '',
    ),
    onOk () {
      return new Promise((resolve, reject) => {
        require(resolve)
      }).catch(() => console.log('Oops errors!'));
    },
    onCancel () { },
  });
}

//判断输入框是否有空格
export function IfNull (strings) {
  if (!strings) return false
  if (typeof strings === 'number') {
    return true
  }
  var str = strings;
  str = str.replace(/(^\s*)|(\s*$)/g, "");
  str = str.replace(/(^　*)|(　*$)/g, "");
  if (str == '') {
    return false;
  }
  return str;
}
//判断数组里面是否包含传的对象
function includesObject (arr, obj) {
  for (let i = 0; i < arr.length; i++) {
    if (JSON.stringify(arr[i]) === JSON.stringify(obj)) {
      return true;
    }
  }
  return false;
}

// 日期转换 YYY-MMM-DD
export const transitionTimeYMD = (time) => {
  if (!time) return ''
  const date = new Date(time)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return year + '-' + PrefixZero(month, 2) + '-' + PrefixZero(day, 2)
}
// 日期转换 YYY-MMM
export const transitionTimeYM = (time) => {
  if (!time) return ''
  const date = new Date(time)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  return year + '-' + PrefixZero(month, 2)
}
//比较两个时间的大小
export const compareTime = (time1, time2) => {
  if (!time1 || !time2) return false
  const date1 = new Date(time1)
  const date2 = new Date(time2)
  if (date1 > date2) {
    return true
  } else {
    return false
  }
}
export const compareTimeAad = (time1, time2) => {
  if (!time1 || !time2) return false
  const date1 = new Date(time1)
  const date2 = new Date(time2)
  if (date1 >= date2) {
    return true
  } else {
    return false
  }
}
// 运算方法
export function compute (type,...args) {
  const getPower = (numbers) => {
    const lens = numbers.map(num =>  num.toString().split('.')[1]?.length || 0);
    const len = Math.max(...lens)
    return Math.pow(10, len)
  }
  const power = getPower(args)
  const newNunbers = args.map(num => Math.round(num * power))
  let result = 0
  switch (type) {
    case '+':
      result = newNunbers.reduce((perNumber, nextNubmer) =>  perNumber + nextNubmer) / power
      break;
    case '-':
      result = newNunbers.reduce((perNumber, nextNubmer) =>  perNumber - nextNubmer ) / power
      break;
    case '*':
      result = newNunbers.reduce((perNumber, nextNubmer) =>  perNumber * nextNubmer ) / (power ** newNunbers.length)
      break;
    case '/':
      result = newNunbers.reduce((perNumber, nextNubmer) =>  perNumber / nextNubmer )
      break;
  }
  return{
    result,
    next(nestType,...nextArgs){
      return compute(nestType,result,...nextArgs)
    }
  }
}

//地址转换
export const addressTran = (arr) => {
  if(!arr) return null
  if(arr.length === 0 ) return null
  let msg = {
   province:arr[0],
   city:arr[1],
   area:arr[2],
  }
  return msg
}
export const addressTranString = (arr) => {
  if(!arr) return null
  if(arr.length === 0 ) return null
  let msg = ''
  arr.forEach( item => {
    msg += item
  })
  return msg
}
