<template>
  <div ref="modal" class="classmodal">
    <a-modal :getContainer="()=>$refs.modal"  :visible="visible" footer="" @cancel="cancel" width="85%" :bodyStyle="{ padding:'10px',background:'#EDF0F9' }">
      <template #title>
        <div class="titleBox flex al-center">
          <a-select v-model:value="value" style="width: 120px" :options="options3"></a-select>
          <div @click="openConfig" class="m-l2 cur-p" style="color:#6b778c">区域管理&gt;</div>
          <img @click="openColor" class="m-l2 cur-p" style="width:18px;height:18px" src="@/assets/images/newTransport/colorChange.png" alt="">
          <div class="taskInfo flex al-center ju-end">
            <div style="margin-right: 80px;">
              <span class="m-r1">今日总任务量：
                <CountTo :endVal="endVal" />
              </span>
              <span class="m-r1">已完成：70</span>
              <span>完成进度：68%</span>
            </div>
            <div>
              <span>本月准时率：</span>
              <span class="m-r1">委托 {{timeRate.commission}}%</span>
              <span class="m-r1">验车 {{timeRate.inspection}}%</span>
              <span class="m-r1">提车 {{timeRate.pick}}%</span>
              <span class="m-r1">干线 {{timeRate.trans}}%</span>
              <span class="m-r1">送车 {{timeRate.delivery}}%</span>
            </div>
          </div>
        </div>
      </template>
      <div v-if="loading" class="flex al-center ju-center" style="height: 300px">
        <a-spin />
      </div>
      <div v-if="data.length>0" class="contenBox" >
        <div v-for="(item,index) in data" :key="item.id" class="conten" :class="(index+1)%5 === 0 ? 'changeMar':''" :style="{top:item.top+'px'}">
          <div class="topBox" :style="{background:item.back}">
            <div class="titleText flex ju-between">
              <span class="flex al-center">
                <img style="width: 18px;height:18px" :src="require(`@/assets/images/newTransport/icon${item.styleType}.png`)" alt="">
                <span class="m-l1">{{ item.title }}</span>
              </span>
              <a-tooltip :title="item.define">
                <img class="tipsIcon" src="@/assets/images/newTransport/u60.png" alt="">
              </a-tooltip>
            </div>
            <div v-if="item.subItemList" :class="{'custom':typeList.includes(item.styleType)}">
              <div v-for=" opt in item.subItemList" :key="opt.id" :style="{width:item.customWid}">
                <Meun :msg="opt" :styleType="item.styleType"></Meun>
              </div>
            </div>
            <div v-else>
               <Meun :msg="item" :styleType="item.styleType"></Meun>
            </div>
          </div>
        </div>
      </div>
       <div>
       </div>
      <ConfigMoadl @success="success" v-if="configVisible" :selProvince="dispatchProvinceList" :selEmployee="reliefList"  v-model:visible="configVisible"> </ConfigMoadl>
      <ColorMoadl @submit="submit" v-model:visible="colorVisible" :data="data"></ColorMoadl>
    </a-modal>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from 'vue'
import CountTo from '@/components/countTo' 
import Meun from './meun'
import { pageAll } from '@/api/newTransport/newTransport' 
import ConfigMoadl from './components/configAdd/configMoadl'
import ColorMoadl from './components/colorMoadl'


const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})
const colorData = ref([])
const colorVisible = ref(false)
const loadingNum = ref(0)
const loading = ref(false)
const columnCount = ref(4)
const data = ref([
])
const timeRate = ref({})
const dispatchProvinceList = ref([])
const reliefList = ref([])
const configVisible = ref(false)
const value = ref('1')
const options3 = ref([
  { value: '1', label: '全部' },
])
const typeList = ref([2,7])
const endVal = ref(100)

const openColor = () => {
  colorVisible.value = true
}
const openConfig = () => {
  configVisible.value = true
  
}
const submit = (e) => {
 colorData.value = e
}
const cancel = () => {
  emit('update:visible', false)
}
const success = () => {
  reqPageAll()
}
const reqPageAll = () => {
  if(!loadingNum.value){
    loading.value = true
  }
  pageAll({}).then(res => {
    if (res.code === 10000) {
      timeRate.value = res.data.timeRate
      dispatchProvinceList.value = res.data.dispatchProvinceList
      reliefList.value = res.data.reliefList
      data.value = res.data.consoleList
      data.value.forEach( item => {
        item.back = '#fff'
        if(item.styleType === 2){
          item.customWid = '49%'
        }
        if(item.styleType === 7){
          item.customWid = '33%'
        }
        colorData.value.forEach( opt => {
          if(opt.title === item.title){
            item.back = opt.back
          }
        })
      })
        nextTick(()=>{
        let list = document.querySelectorAll('.topBox')
        let h = list[0].clientHeight
        for (let i = 0; i < list.length; i++) {
        data.value[i].clientHeight = list[i].clientHeight
        }
        let maxList2 = data.value.slice(0,5).map(item => item.clientHeight)
        let max2 =  Math.max.apply(null, maxList2)
        let maxList3 = data.value.slice(5,5*2).map(item => item.clientHeight)
        let max3 =  Math.max.apply(null, maxList3)
        data.value.forEach( (item,index) => {
            if((index+1)/5>1 && (index+1)/5<=2){
             item.top = data.value[index-5].clientHeight - max2
            }
            if((index+1)/5>2 && (index+1)/5<=3){
              item.top = (data.value[index-5].clientHeight + data.value[index-5].top )  - max3
            }
        })
        console.log( h);
      })
   
     
    }
  }).finally(() => {
    loading.value = false
    loadingNum.value = 1
  })
}


onMounted(() => {
  reqPageAll()
  // setInterval(() => {
  //   reqPageAll()
  // }, 20000)
})
</script>

<style lang="less" scoped>
.waterfall {
  position: relative;
}
.waterfall-item {
  position: absolute;
  padding: 10px;
  background: red;
}
.classmodal {
  :deep(.ant-modal-header) {
    padding: 10px !important;
  }
}

.titleBox {
  font-weight: normal;
  font-size: 13px;
}

.taskInfo {
  width: 80%;
  color: #344563;
}

.contenBox {
  display: flex;
 flex-wrap: wrap;
 
}

.conten {
  width: 19%;
  position: relative;
  margin-right: 15px;
  .topBox {
    width: 100%;
    min-height: 100px;
    background: #fff;
    margin-bottom: 10px;
    padding: 15px 10px;
    border-radius: 2px;
  }
}

.titleText {
  color: #333;
  font-size: 15px;
  font-weight: bold;
}
.itemBox {
  margin-top: 10px;
  padding: 10px;
  background: #f7f8fc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  .tips {
    font-size: 12px;
    color: #999;
  }
}

.itemTitle {
  padding: 5px 10px;
  background: #f7f8fc;
  border-bottom: 1px solid #fff;
}

.stateStyle {
  padding: 4px 8px;
  background: #edf0f9;
  border-radius: 4px;
  width: 48%;
  margin-bottom: 10px;
  border-left: 5px solid transparent;
}

.arrBox {
  padding: 2px 8px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 12px;
}

.customTags {
  padding: 5px 10px;
  background: #f7f8fc;
  border-bottom: 1px solid #fff;
  width: 49%;
}

.line {
  width: 100%;
  height: 1px;
  background: #eee;
  margin: 8px 0;
}

.outerBox {
  padding: 25px 10px;
  background: #f7f8fc;
  border-radius: 4px;
  margin-top: 10px;
  color: #333;
  font-size: 14px;
}

.tipsIcon {
  width: 16px;
  height: 16px;
}

.custom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.changeMar{
  margin-right: 0;
}

</style>