<template>
  <div class="small-body" style="position: relative;">
    <div class="data-paginate" v-show="statisticsList.length > 9">
      <LeftCircleFilled style="font-size:20px;color:#B0B8BF;cursor: pointer;" @click="leftMove" />
      <RightCircleFilled style="font-size:20px;color:#B0B8BF;cursor: pointer;margin-left:5px" @click="rightMove" />
    </div>
    <!-- 头部信息统计模块 -->
    <div class="small-car">
      <div class="data-statistics">
        <div v-show="groupListData.length > 0" style="margin: 15px 0px 30px 15px;display:flex;flex-direction:column;justify-content:center;align-items:center">
          组内查询
          <a-select v-model:value="value1" style="width: 120px" :placeholder="$store.getters.userName">
            <a-select-option v-for="item in groupListData" :key="item.empId" :value="empId" @click="groupChange(item)">{{
              item.empName }}</a-select-option>
          </a-select>
        </div>
        <div v-for="(item, index) in statisticsList" :key="index" class="data-statistics-content">
          <div class="statistics-content" :style="{ flex: index === statisticsList.length - 1 ? '1' : '' }" @click="statisticsSearch(item, index)">
            <div class="statistics-title">{{ item.label || item.orderStatus?.label }}</div>
            <div class="data-statistics-data">{{ item.num }}</div>
            <div v-show="item.status === 1" class="data-status">待审核 00</div>
          </div>
          <a-divider type="vertical" style="height: 60px; background-color: #eee" />
        </div>
      </div>
    </div>
    <a-divider style="height: 10px;margin-top:0; background-color: #EEEEEE;border:none" />
    <GlobalTable :loading="loading">
      <!-- 搜索栏 -->
      <template #headerSearch>
        <div style="display:flex;justify-content: space-between;">
          <a-form layout="inline" :model="searchForm">
            <a-form-item label="">
              <a-cascader style="" :show-search="{ filter }" v-model:value="startCitys" :options="$store.state.app.PClist" :changeOnSelect="true" @change="startAddressChange" placeholder="起运地" />
            </a-form-item>
            <a-form-item label="">
              <a-cascader style="" :show-search="{ filter }" v-model:value="endtCitys" :options="$store.state.app.PClist" :changeOnSelect="true" @change="endAddressChange" placeholder="目的地" />
            </a-form-item>
            <a-form-item label="">
              <a-cascader style="width:120px" :options="$store.state.app.PClist" v-model:value="searchForm.transit" :show-search="{ filter }" :changeOnSelect="true" @change="setTransitAddress" placeholder="当前城市" />
            </a-form-item>
            <a-form-item label="">
              <a-cascader style="width:120px" :options="$store.state.app.PClist" v-model:value="searchForm.nextCity" :show-search="{ filter }" :changeOnSelect="true" @change="setNextCityAddress" placeholder="即将到达城市" />
            </a-form-item>
            <a-form-item label="">
              <a-select allowClear v-model:value="searchForm.status" :options="$store.state.enumAll.smallVehStatus" placeholder="车辆状态" style="width:100px" />
            </a-form-item>
            <a-form-item label="">
              <a-select allowClear v-model:value="searchForm.orderStatus" placeholder="订单状态" :options="$store.state.enumAll.orderStatus" style="width:100px" />
            </a-form-item>
            <a-form-item label="">
              <a-input style="width:200px" allowClear v-model:value="searchForm.orderId" placeholder="订单号" />
            </a-form-item>
            <a-form-item label="">
              <a-input v-model:value="searchForm.vinNo" style="width:120px" placeholder="车牌号" />
            </a-form-item>
            <a-form-item label="">
              <a-input style="width:100px" allowClear v-model:value="searchForm.salesman" placeholder="业务员" />
            </a-form-item>
            <a-form-item label="">
              <a-date-picker v-model:value="searchForm.startOrderTime" placeholder="开始时间" valueFormat="YYYY-MM-DD" style="width:120px" />
            </a-form-item>
            <a-form-item label="">
              <a-date-picker v-model:value="searchForm.endOrderTime" placeholder="截止时间" valueFormat="YYYY-MM-DD" style="width:120px" />
            </a-form-item>
            <a-form-item label="">
              <a-select allowClear v-model:value="searchForm.isSelfArea" placeholder="本区域" :options="$store.state.enumAll.BooleanFlagEnum" style="width:100px" />
            </a-form-item>
            <a-form-item label="">
              <a-select allowClear v-model:value="searchForm.monitorTypeLevel" placeholder="监控任务" :options="$store.state.app.monitorTypeLevel" style="width:150px" />
            </a-form-item>
            <a-form-item label="">
              <a-input v-model:value="searchForm.remark" style="width:120px" placeholder="调度备注" />
            </a-form-item>
            <a-form-item label="">
              <a-date-picker v-model:value="searchForm.arrangeLoadTimeStr" placeholder="预计装车时间" valueFormat="YYYY-MM-DD" style="width:130px" />
            </a-form-item>
            <a-form-item label="">
              <a-select allowClear v-model:value="searchForm.vehicleNum" placeholder="台量类型" :options="vehicleENUM" style="width:100px" />
            </a-form-item>
            <a-form-item label="">
              <a-select allowClear v-model:value="searchForm.isArrangeLoadTime" placeholder="是否有预装车时间" :options="$store.state.enumAll.BooleanFlagEnum" style="width:160px" />
            </a-form-item>
            <a-form-item label="">
              <a-input v-model:value="searchForm.taskCarrierName" style="width:140px" placeholder="外派承运商名称" />
            </a-form-item>
            <a-form-item label="">
              <a-select v-model:value="searchForm.taskStatus" style="width:140px" allowClear placeholder="任务状态" :options="taskStatusOpt" />
            </a-form-item>
            <a-form-item label="">
              <a-select allowClear v-model:value="searchForm.isTask" placeholder="是否外派" style="width:100px">
                <a-select-option :value="1">是</a-select-option>
                <a-select-option :value="0">否</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="">
              <a-select allowClear v-model:value="searchForm.pic" placeholder="是否照片" style="width:100px">
                <a-select-option :value="1">是</a-select-option>
                <a-select-option :value="0">否</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="">
              <a-select allowClear v-model:value="searchForm.address" placeholder="是否地址" style="width:100px">
                <a-select-option :value="1">是</a-select-option>
                <a-select-option :value="0">否</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="">
              <a-select allowClear v-model:value="searchForm.deliveryCard" placeholder="是否交车" style="width:100px">
                <a-select-option :value="1">是</a-select-option>
                <a-select-option :value="0">否</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="">
              <div class="operate-btn">
                <a-button class="header-search-btn" @click="searchData" :loading="loading">搜索</a-button>
                <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </template>
      <!-- 操作按钮 -->
      <template #operateBtn>
        <!-- <a-button @click="shareShow = true" :disabled="batchList.length <= 0">共享</a-button> -->
        <a-button @click="batchInspection">批量验车完成</a-button>
        <a-button @click="batchReportClick">批量上报</a-button>
        <a-button @click="batchBtn">批量验车</a-button>
        <!-- <a-button :disabled="batchList.length <= 0" @click="shareBtn">共享</a-button> -->
        <!-- <a-button :disabled="batchList.length <= 0" @click="batchVehicle">批量交车</a-button> -->
        <a-button @click="batchVehicle('new')">批量交车</a-button>
        <a-button @click="empTypeOpen">批量放空</a-button>
        <!-- <a-popconfirm v-if="chooseData.tovId" title="确定更改小车运车完成?" @confirm="transportComplete(element)">
          <a-button>中途完成</a-button>
        </a-popconfirm>
        <a-button v-if="!chooseData.tovId" :disabled="!chooseData.tovId">中途完成</a-button> -->
        <span>共{{ pagination.total }}条</span>
      </template>
      <!-- 表格主体 -->
      <div class="table-data">
        <!-- <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, onSelect: onSelect }" -->
        <a-table ref="tableRef" size="small" :columns="columns" :row-key="record => record.orderVehicleId" :data-source="smallVehList" bordered :scroll="{ x: 3400, y: 400 }" :pagination="false" :loading="loading">
          <template #operation="{ record }">
            <div style="position: relative;">
              <a class="ant-dropdown-link" @click="model(record)">
                操作
              </a>
              <a-dropdown>
                <template #overlay>
                  <a-menu @mouseenter="detail = record">
                    <a-menu-item @click="dataDetail(record)">
                      <a>详情</a>
                    </a-menu-item>
                    <a-menu-item @click="batchManual(record)">
                      <a>派单</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a>发运模式</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a @click="transVehicle(record)">发运</a>
                    </a-menu-item>
                    <!-- <a-menu-item>
                      <a>异常上报</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a>添加费用</a>
                    </a-menu-item> -->
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </template>
          <template #completeness="{ record }">
            <div class="content">
              <a-steps :current="2" size="small" progress-dot>
                <!-- wait蓝色-进行中  error灰色-未安排  默认绿色-已安排 -->
                <a-step v-for="(item, index) in record.completeness" :key="index" :description="item.status" :status="item.value === 1 ? 'error' : item.value === 4 ? 'process' : 'wait'" :title="item.label" />
                <a-step description="error" status="" title="" />
              </a-steps>
            </div>
          </template>
          <template #taskInfo="{ record }">
            <template v-if="record.taskId>0">
              <div class="flex" v-if="record.pic?.value === 1">
                <div class="picInfoBox">照片：</div>
                <a  @click="lookVehicleImgs(record)">查看</a>
              </div>
              <div style="text-align: left;" v-if="record.address?.value === 1">
                地址：<a  @click="lookVehicleGps(record)">查看</a>
                ({{ record.gpsCity}}{{ record.gpsTime}})
              </div>
              <div class="flex"  v-if="record.deliveryCard?.value === 1">
                <div class="picInfoBox">交车条：</div>
                <a @click="lookDeliveryCard(record)">查看</a>
              </div>
              <div  v-if="record.taskStartTime" style="text-align: left;">
               发运时间：{{ record.taskStartTime}}
              
              </div>

            </template>
          </template>
          <template #remark="{ record }">
            <div style="display:flex" @mousemove="remarkId = record.id">
              <div class="remark-content">
                <!-- <span >{{ record.remark }}</span> -->
                <a-tooltip color="#fff">
                  <template #title>
                    <span style="color:#000">{{ record.remark }}</span>
                  </template>
                  <span>{{ record.remark }}</span>
                </a-tooltip>
                <a v-show="!record.remark" @click="openDisRemarks(record)">新增调度备注</a>
              </div>
              <div style="display:flex;align-items:center;min-width:30px" class="m-l1">
                <a v-show="record.remark" @click="openDisRemarks(record)">记录</a>
              </div>
            </div>
          </template>
          <template #orderTime="{ record }">
            <div>{{record.orderTime}}</div>
            <div style="color:green">{{record.pickAppointTime}}</div>
          </template>
          <template #orderStatus="{record}">
            <div>{{ record.orderStatus.label }}</div>
            <div style="color:green">{{ record.transportType.label }}-{{ record.transStatus.label }}</div>
            <div>{{ record.taskStatus?.label }}</div>
          </template>
          <template #vinNo="{ record }">
            <div style="position: relative;" v-if="record.taskId">
              <a-popover>
                <template #content>
                  {{ record.taskCarrierName }}
                </template>
                <div v-show="record.vinNo === ''">暂无车牌</div>
                <div v-show="record.vinNo !== ''" class="flex al-center ju-center">
                  <div class="isExpatriate" v-if="record.taskId">
                    派
                  </div>{{ record.vinNo }}
                </div>
                <div v-show="record.model !== '' || record.brand !== ''">{{ record.brand + record.model }}</div>
                <div v-show="record.brand === '' && record.model === ''">暂无车型</div>
              </a-popover>
            </div>
            <div style="position: relative;" v-if="!record.taskId">
              <div v-show="record.vinNo === ''">暂无车牌</div>
              <div v-show="record.vinNo !== ''" class="flex al-center ju-center">
                <div class="isExpatriate" v-if="record.taskId">
                  派
                </div>{{ record.vinNo }}
              </div>
              <div v-show="record.model !== '' || record.brand !== ''">{{ record.brand + record.model }}</div>
              <div v-show="record.brand === '' && record.model === ''">暂无车型</div>
            </div>
          </template>
          <template #currentShipper="{ record }">
            <div style="position: relative;align-items: center;text-align: center;">
              <div v-show="record.despatchTime !== '' && record.despatchTime !== null" style="font-size:12px;color:#ad1540;">{{ record.despatchTime }}</div>
              <div v-show="record.dispatchType?.value !== 2">{{ record.currentShipper }}</div>
              <div v-show="record.dispatchType?.value === 2">{{ record.innerCarrier }}</div>
              <div style="font-size:12px;width:100%;color:#0C8226;" v-show="record.shareType !== '' && record.shareType !== null">{{ record.shareType }}</div>
            </div>
          </template>
          <template #address="{ record }">
            <div style="text-align: left;">
              <div>起：{{ record.startAddress }}</div>
              <div>终：{{ record.endAddress }}</div>
            </div>
          </template>
          <template #nowCityId="{ record }">
            <div style="text-align: left;">
              <div>当前：{{ record.nowCity }}</div>
              <div>下个：{{ record.nextCity }}</div>
              <div v-if="record.theoreticalArrivalTime !== null">{{ record.theoreticalArrivalTime }}</div>
            </div>
          </template>
          <template #taskData="{ record }">
            <div class="plant-content">
              <div v-if="record.taskData?.INSPECTION?.length <= 3" v-for="(item, index) in record.taskData?.INSPECTION" :key="index">
                <div v-if="item.transportType.value === 0">
                  <span style="margin:0 5px">{{ item.carrierName }}</span>
                  <span style="margin-right:5px">{{ item.driverName }}</span>
                  <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                  }}</span>
                  <span style="margin-right:5px">{{ '￥' + item.freight || '' }}</span>
                </div>
              </div>
              <a-popover v-show="record.taskData?.INSPECTION?.length > 3">
                <template #content>
                  <div v-for="(item, index) in record.taskData?.INSPECTION" :key="index">
                    <div v-if="item.transportType.value === 0">
                      <span style="margin:0 5px">{{ item.carrierName }}</span>
                      <span style="margin-right:5px">{{ item.driverName }}</span>
                      <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                      }}</span>
                      <span style="margin-right:5px">{{ '￥' + item.freight || '' }}</span>
                    </div>
                  </div>
                </template>
                <div v-if="record.taskData?.INSPECTION?.length > 3" v-for="(item, index) in record.taskData?.INSPECTION" :key="index">
                  <div v-if="item.transportType.value === 0">
                    <span style="margin:0 5px">{{ item.carrierName }}</span>
                    <span style="margin-right:5px">{{ item.driverName }}</span>
                    <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                    }}</span>
                    <span style="margin-right:5px">{{ '￥' + item.freight || '' }}</span>
                  </div>
                </div>
              </a-popover>
            </div>
          </template>
          <template #extractionTask="{ record }">
            <div class="plant-content">
              <div v-if="record.taskData">
                <div v-show="record.taskData?.PICK?.length <= 3" v-for="(item, index) in record.taskData?.PICK" :key="index">
                  <div v-if="item.transportType.value === 1">
                    <span style="margin:0 5px">{{ item.carrierName }}</span>
                    <span style="margin-right:5px">{{ item.driverName }}</span>
                    <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                    }}</span>
                    <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                  </div>
                </div>
                <a-popover v-show="record.taskData?.PICK?.length > 3">
                  <template #content>
                    <div v-for="(item, index) in record.taskData?.PICK" :key="index">
                      <div v-if="item.transportType.value === 1">
                        <span style="margin:0 5px">{{ item.carrierName }}</span>
                        <span style="margin-right:5px">{{ item.driverName }}</span>
                        <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                        }}</span>
                        <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                      </div>
                    </div>
                  </template>
                  <div v-if="record.taskData?.PICK?.length > 3" v-for="(item, index) in record.taskData?.PICK" :key="index">
                    <div v-for="(item, index) in record.taskData?.PICK" :key="index">
                      <div v-if="item.transportType.value === 1">
                        <span style="margin:0 5px">{{ item.carrierName }}</span>
                        <span style="margin-right:5px">{{ item.driverName }}</span>
                        <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                        }}</span>
                        <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                      </div>
                    </div>
                  </div>
                </a-popover>
              </div>
            </div>
          </template>
          <template #trunkTask="{ record }">
            <div class="plant-content">
              <div v-if="record.taskData">
                <div v-show="record.taskData?.TRANSPORT?.length <= 3" v-for="(item, index) in record.taskData?.TRANSPORT" :key="index">
                  <div v-if="item.transportType.value === 2">
                    <span style="margin:0 5px">{{ item.carrierName }}</span>
                    <span style="margin-right:5px">{{ item.driverName }}</span>
                    <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                    }}</span>
                    <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                  </div>
                </div>
                <a-popover v-show="record.taskData?.TRANSPORT?.length > 3">
                  <template #content>
                    <div v-for="(item, index) in record.taskData?.TRANSPORT" :key="index">
                      <div v-if="item.transportType.value === 2">
                        <span style="margin:0 5px">{{ item.carrierName }}</span>
                        <span style="margin-right:5px">{{ item.driverName }}</span>
                        <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                        }}</span>
                        <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                      </div>
                    </div>
                  </template>
                  <div v-if="record.taskData?.TRANSPORT?.length > 3" v-for="(item, index) in record.taskData?.TRANSPORT" :key="index">
                    <div v-if="item.transportType.value === 2">
                      <span style="margin:0 5px">{{ item.carrierName }}</span>
                      <span style="margin-right:5px">{{ item.driverName }}</span>
                      <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                      }}</span>
                      <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                    </div>
                  </div>
                </a-popover>
              </div>
            </div>
          </template>
          <template #deliveryTask="{ record }">
            <div class="plant-content">
              <div v-if="record.taskData">
                <div v-show="record.taskData?.DELIVERY?.length <= 3" v-for="(item, index) in record.taskData?.DELIVERY" :key="index">
                  <div v-if="item.transportType.value === 3">
                    <span style="margin:0 5px">{{ item.carrierName }}</span>
                    <span style="margin-right:5px">{{ item.driverName }}</span>
                    <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                    }}</span>
                    <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                  </div>
                </div>
                <a-popover v-show="record.taskData?.DELIVERY?.length > 3">
                  <template #content>
                    <div v-for="(item, index) in record.taskData?.DELIVERY" :key="index">
                      <div v-if="item.transportType.value === 3">
                        <span style="margin:0 5px">{{ item.carrierName }}</span>
                        <span style="margin-right:5px">{{ item.driverName }}</span>
                        <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                        }}</span>
                        <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                      </div>
                    </div>
                  </template>
                  <div v-if="record.taskData?.DELIVERY?.length > 3" v-for="(item, index) in record.taskData?.DELIVERY" :key="index">
                    <div v-for="(item, index) in record.taskData?.DELIVERY" :key="index">
                      <div v-if="item.transportType.value === 3">
                        <span style="margin:0 5px">{{ item.carrierName }}</span>
                        <span style="margin-right:5px">{{ item.driverName }}</span>
                        <span style="margin-right:5px" v-show="item.transportWay">{{ item.transportWay?.label || ''
                        }}</span>
                        <span style="margin-right:5px">{{ '￥' + item.freight || '0' }}</span>
                      </div>
                    </div>
                  </div>
                </a-popover>
              </div>
            </div>
          </template>
          <template #taskNames="{ record }">
            <div v-if="record.taskNames">
              <div v-for="item in record.taskNames.split(',')" :key="item">{{ item }}</div>
            </div>
          </template>
          <template #report="{ record }">
            <div>
              <div><a @click="openDetailed(record)">查看明细</a></div>
            </div>
          </template>
        </a-table>
        <div style="text-align:right;margin-top:10px"><a-button @click="nextPage" :disabled="smallVehList.length >= pagination.total">下一页</a-button></div>
      </div>
      <!-- 各类操作弹窗↓ -->
      <a-modal v-model:visible="batchOrderShow" title="接单" width="30%" footer="">
        <div>
          <p>是否批量接单以下车辆？</p>
          <div>
            <a-list size="small" bordered :data-source="batchList">
              <template #renderItem="{ item }">
                <a-list-item>{{ item.vinNo }}&emsp;{{ item.startAddressDetail + '——' + item.endAddressDetail
                }}</a-list-item>
              </template>
            </a-list>
          </div>
          <div class="batch-order-btn">
            <a-space>
              <a-button @click="batchOrderShow = false">暂不接单</a-button>
              <a-button type="primary" @click="acceptOrder">接单</a-button>
              <a-button type="primary" @click="batchManual">接单并安排</a-button>
              <a-button type="primary" @click="batchDispatch">接单并派单</a-button>
            </a-space>
          </div>
        </div>
      </a-modal>
      <!-- 共享 -->
      <!-- <ShareData :shareShow="shareShow" :recordList="batchList" @statusShow="confirmShare" /> -->
      <!-- 人工调度----派单 -->
      <ManualScheduling :manualSchedulingShow="manualSchedulingShow" :detail="detail" :recordList="batchList" @statusShow="batchManual" />
      <!-- 详情 -->
      <PlantDetail :windowShow="dataDetailShow" :vehicleDetailData="detail" @statusShow="dataDetail" />
      <!-- 异常审核 -->
      <!-- <AbnormalAudit :windowShow="abnormalAuditShow" @statusShow="abnormalAuditEvent" /> -->
    </GlobalTable>
    <a-modal v-model:visible="transShow" :width="1200" footer="" :destroyOnClose="true" :maskClosable="false">
      <template #title>
        <div style="display:flex;justify-content: space-between;">
          <div style="display:flex">
            <div class="avater">
              <FileZipOutlined />
            </div>
            <div style="color: #344563">&emsp;<span>{{ detail.vinNo }}&emsp;</span></div>
            <a class="fs12 mlg-15" @click="copyText(detail.vinNo)">复制</a>
          </div>
        </div>
      </template>
      <OperationDetail :vehiclesArr="[detail]" />
    </a-modal>
    <!-- 移交车辆 -->
    <a-modal v-model:visible="transferShow" title="移交车辆" @ok="confirmTransfer">
      <a-form ref="transferFormRef" :model="transferForm" :rules="transferFormRules" :label-col="{ span: 100 }" :wrapper-col="{ span: '100%' }" layout="vertical">
        <a-form-item label="选择部门" name="handoverType">
          <a-select v-model:value="transferForm.handoverType" @change="dispatchTypeChange">
            <a-select-option :value="2">内部承运商</a-select-option>
            <a-select-option :value="1">总部调度</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-show="transferForm.handoverType === 1" label="选择调度人" name="handoverId">
          <a-select :filter-option="false" show-search placeholder="请输入员工姓名或关键字,按下回车搜索" @search="employerValue">
            <template v-if="flagLoading" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name" @click="employeeChoose(item)">{{ item.name }}-{{ item.no }}-{{ item.mobile }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-show="transferForm.handoverType === 2" label="选择承运商" name="handoverId">
          <a-select placeholder="搜索并选择承运商" @search="fetchCarrier" labelInValue :filterOption="false" show-search>
            <template v-if="fetching" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option v-for="item in carriers" :key="item.id" @click="carrierChoose(item)">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <!-- 新增/编辑调度备注 -->
    <SchedulingLogs @setRemaekOk="setRemaekOk" :remarkId="remarkId" :isOverTime="isOverTime" v-if="remarkShow" v-model:visible="remarkShow" :orderVehicleId="logsVehicleId"></SchedulingLogs>
    <!-- <a-textarea v-model:value="remark" placeholder="调度备注" :rows="4" :maxlength="200" /> -->
    <!-- 小车操作详情 -->
    <WindowModal :windowShow="windowShow" :data="detail" @statusShow="model" :clearData="clearData">
      <template #titleContent>
        <div style="display:flex;justify-content: space-between;padding-right:40px">
          <div>
            <span>{{ detail.vinNo + ' ' + detail.brand + detail.model }}</span>
            <a style="margin-left:10px;font-size:14px" @click="showRecord">车辆日志</a>
            <a style="margin-left:10px;font-size:14px" @click="getUpdateImg">上传图片</a>
            <a style="margin-left:10px;font-size:14px" @click="lookVehicleImg">查看图片</a>
            <a style="margin-left:10px;font-size:14px" @click="orderVehicleGps">在途位置</a>
            <a style="margin-left:10px;font-size:14px" @click="openDetailed(detail)">异常列表</a>
            <a style="margin-left:10px;font-size:14px" v-show="dispatchDetail.isPlaceIn && dispatchDetail.isPlaceIn.value === 1" @click="pickVisible = true">取车委托</a>
            <a style="margin-left:10px;font-size:14px" @click="transVisible = true">运车委托</a>
            <a style="margin-left:10px;font-size:14px" v-show="dispatchDetail.isTakeOut && dispatchDetail.isTakeOut.value === 1" @click="takeVisible = true">送车委托</a>
            <a style="margin-left:10px;font-size:14px" @click="lookFollowList">跟单列表</a>
          </div>
          <div>
            <span>业务员：{{ detail.salesman }}</span>
            <span style="margin-left:10px">下单时间：{{ detail.orderTime }}</span>
          </div>
        </div>
      </template>
      <template #leftContent>
        <div class="window-content">
          <TransportModel ref="transportModelRef" :windowShow="windowShow" :data="detail" @statusShow="transportModelShowStatus" :tovId="tovId" />
          <!-- 信息日志 -->
          <a-modal v-model:visible="journalShow" width="40%" title="车辆日志" footer="" :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">
            <div v-if="detail.orderVehicleId">
              <Record v-if="detail.orderVehicleId" ref="recordRef" :vehicleList="[]" :vehiclesId="detail.orderVehicleId" :orderId="detail.orderId" />
            </div>
          </a-modal>
          <!-- 跟单列表 -->
          <a-modal v-model:visible="followListShow" title="跟单列表" width="60%" footer="" :getContainer="false">
            <div>
              <ResultFollow ref="resultFollowRef" />
            </div>
          </a-modal>
          <!-- 上传图片 -->
          <InspectionImg ref="imgListRef" :orderVehicleId="detail.orderVehicleId" :loadInspectList="[]" />
          <!-- 车辆图片 -->
          <VehicleImg ref="vehicleImgRef" />
          <!-- 批量交车 -->
          <!-- <HandInVehicle ref="handInVehicleRef" :windowShow="handInShow" :vehicleArr="batchList" @statusShow="handInStatus" /> -->
          <!-- 在途位置 -->
          <VehicleGps ref="vehicleGps" />
          <a-modal v-model:visible="pickVisible" title="取车委托" width="600px" footer="">
            <div>
              <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
              <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
              <p class="font-color2">交车人姓名：{{ dispatchDetail.consignor }}</p>
              <p class="font-color2">交车人电话：{{ dispatchDetail.consignorMobile }}</p>
              <p class="font-color2">交车人身份证：{{ dispatchDetail.consignorCertificateNumber }}</p>
              <p class="font-color2" v-if="dispatchDetail.orderRemark">订单备注：{{ dispatchDetail.orderRemark }}</p>
              <p class="font-color2" v-if="dispatchDetail.remark">小车备注：{{ dispatchDetail.remark }}</p>
              <p class="font-color2">取车信息：{{ dispatchDetail.pickType ? dispatchDetail.pickType.label : '' }}&nbsp;{{
                dispatchDetail.placeInAddress }}</p>
              <p class="font-color2">温馨提示：物流只负责外观内饰的检查，车辆性能及其它内部问题我们没有技术能力检验，望理解！贵重物品不随车，小件物品请打包放好，有ETC请提前拔掉，谢谢配合！</p>
            </div>
          </a-modal>
          <a-modal v-model:visible="transVisible" title="运车委托" width="600px" footer="">
            <div>
              <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
              <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
              <p class="font-color2">起运地：{{dispatchDetail.startAddress}}</p>
              <p class="font-color2">目的地：{{dispatchDetail.endAddress}} {{ dispatchDetail.picker }} {{ dispatchDetail.pickerMobile }}</p>
              <p class="font-color2"><span v-show="dispatchDetail.paymentArrival > 0">
                  订单到付: <a-tag color="red">{{ dispatchDetail.paymentArrival }}</a-tag>
                  <span>{{ dispatchDetail.isNoticeReleaseCar?.value === 1 ? '等通知放车' : '' }}</span></span></p>
              <!--              <p class="font-color2" v-if="dispatchDetail.orderRemark">订单备注：{{ dispatchDetail.orderRemark }}</p>-->
              <!--              <p class="font-color2" v-if="dispatchDetail.remark">小车备注：{{ dispatchDetail.remark }}</p>-->
              <p class="font-color2">温馨提示：承运商只做交车相关的沟通，若客户询问其它，让联系业务咨询。有私自添加客户微信等撬客户行为，一经发现，终止合作！</p>

              <!--              <p class="font-color2">{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>-->
              <!--              <p class="font-color2">收车人姓名：{{ dispatchDetail.picker }}</p>-->
              <!--              <p class="font-color2" v-if="dispatchDetail.pushTime">收车人电话：{{ dispatchDetail.pickerMobile }}</p>-->
              <!--              <p class="font-color2">收车人身份证：{{ dispatchDetail.pickerCertificateNumber }}</p>-->
              <!--              <p class="font-color2"><span v-show="dispatchDetail.paymentArrival > 0">-->
              <!--                  订单到付：<a-tag color="red">{{ dispatchDetail.paymentArrival }}</a-tag>-->
              <!--                  <span>{{ dispatchDetail.isNoticeReleaseCar?.value === 0 ? '' : '等通知放车' }}</span></span></p>-->
              <!--              <p class="font-color2">{{ dispatchDetail.endAddress }}</p>-->
            </div>
          </a-modal>
          <a-modal v-model:visible="takeVisible" title="送车委托" width="600px" footer="">
            <div>
              <p class="font-color2">订单号：{{dispatchDetail.orderId}}</p>
              <p class="font-color2">车牌/车架号：{{ dispatchDetail.brand + dispatchDetail.model }} {{ dispatchDetail.vinNo }}</p>
              <p class="font-color2">收车人姓名：{{ dispatchDetail.picker }}</p>
              <p class="font-color2" v-if="dispatchDetail.pushTime">收车人电话：{{ dispatchDetail.pickerMobile }}</p>
              <p class="font-color2">收车人身份证：{{ dispatchDetail.pickerCertificateNumber }}</p>
              <p class="font-color2"><span v-show="dispatchDetail.paymentArrival > 0">
                  订单到付：<a-tag color="red">{{ dispatchDetail.paymentArrival }}</a-tag></span>
                <span>{{ dispatchDetail.isNoticeReleaseCar?.value === 1 ? '等通知放车' : '' }}</span>
              </p>
              <p class="font-color2" v-if="dispatchDetail.orderRemark ">订单备注：{{ dispatchDetail.orderRemark }}</p>
              <p class="font-color2" v-if="dispatchDetail.remark">小车备注：{{ dispatchDetail.remark }}</p>
              <p class="font-color2">送车信息：{{ dispatchDetail.takeType ? dispatchDetail.takeType.label : '' }}
                <span v-if="dispatchDetail.takeOutAddress">&nbsp;{{ dispatchDetail.takeOutAddress }}</span>
              </p>
              <p class="font-color2">温馨提示：承运商只做交车相关的沟通，若客户询问其它，让联系业务咨询。有私自添加客户微信等撬客户行为，一经发现，终止合作！</p>
            </div>
          </a-modal>
        </div>
      </template>
      <template #rightContent>
        <a-tabs v-model:activeKey="activeKey" @change="tabsChange">
          <template #tabBarExtraContent>
            <a-button v-if="!dispatchDetail.taskId" type="primary" size="small" @click="sendOrder" class="m-r2">外派</a-button>
          </template>
          <a-tab-pane :key="1" tab="发运模式">
            <OperateModel ref="operateModelRef" :transList="dispatchDetail.details" :detail="detail" :dispatchDetail="dispatchDetail" @loadData="modelLoadData" />
          </a-tab-pane>
          <!-- <a-tab-pane :key="2" tab="任务模式">
          <DispatchOrder ref="dispatchOrderRef" :show="windowShow" :detail="detail" :tasks="dispatchDetail.tasks"
            @status="planTable" />
        </a-tab-pane> -->
        </a-tabs>
      </template>
    </WindowModal>
    <!-- 派单 -->
    <a-modal v-model:visible="sendVisible" title="外派" footer="" width="40%" destroyOnClose>
      <submitInfo ref="infoRef" @status="status" :title="false"></submitInfo>
    </a-modal>
    <!-- 批量交车 -->
    <WindowModal :windowShow="batchGiveShow" @statusShow="closeWindow">
      <template #titleContent>
        <div>
          批量交车
        </div>
      </template>
      <template #leftContent>
        <BatchGiveTable ref="batchGiveTableRef" @operateData="operateData" />
      </template>
      <template #rightContent>
        <HandInVehicle ref="handInVehicleRef" v-show="handInShow" :vehicleArr="batchGiveList" @statusShow="handInStatus" />
      </template>
    </WindowModal>
    <!-- 批量验车 -->
    <WindowModal :windowShow="batchOperateShow" :data="detail" @statusShow="closeWindow">
      <template #titleContent>
        <div>
          批量操作
        </div>
      </template>
      <template #leftContent>
        <BatchTable ref="batchTableRef" v-if="batchOperateShow" :show="batchOperateShow" @operateData="operateData" />
      </template>
      <template #rightContent>
        <BatchOperate v-if="batchOperateShow" ref="batchOperateRef" :batchData="batchData" @status="loadTable" />
      </template>
    </WindowModal>
    <!-- 批量验车完成 -->
    <a-modal title="批量验车完成" v-model:visible="batchAccomplishShow" width="60%" footer="" :bodyStyle="{ paddingRight: 0 }">
      <BatchTable ref="batchTableRef" v-if="batchAccomplishShow" :show="batchOperateShow" :batchType="1" @operateData="operateData" />
    </a-modal>

    <!-- 批量上报 -->
    <a-modal title="批量上报" v-model:visible="batchReportShow" width="60%" footer="" :bodyStyle="{ paddingRight: 0 }">
      <BatchReport ref="batchReportRef" />
    </a-modal>
    <!-- 批量放空 -->
    <a-modal title="批量放空" v-model:visible="emptyShow" width="80%" footer="">
      <div class="flex">
        <div style="width:70%">
          <EmptyTable v-if="emptyShow" :batchType="1" @operateData="emptyData" />
        </div>
        <div style="width:1%;height:100%;background:red"></div>
        <div v-show="emptyIsEnd" style="width:28%;padding:20px">
          <span class="m-t4" style="font-size:16px;color:#333">放空费</span>
          <div class="m-t3"><span style="color:red">*</span>单台放空费:</div>
          <div style="width:300px" class="m-t1"> <a-input v-model:value="emptyNum" suffix="元" /></div>
          <div class="m-t2" style="color:#D9001B">注：请填写单台车辆的放空费</div>
          <div class="flex m-t4 pos-rel" style="left:250px">
            <a-button type="primary" :loading="emptyLoading" @click="submitEmpty">确定</a-button>
          </div>
        </div>
      </div>
    </a-modal>

    <!-- <WindowModal :windowShow="batchAccomplishShow" :data="detail" @statusShow="closeWindow">
      <template #titleContent>
        <div>
          批量操作
        </div>
      </template>
      <template #leftContent>
        <BatchTable ref="batchTableRef" :show="batchOperateShow" @operateData="operateData" />
      </template>
      <template #rightContent>
        <BatchOperate v-if="batchOperateShow" ref="batchOperateRef" :batchData="batchData" @status="loadTable" />
      </template>
    </WindowModal> -->
    <!-- 批量交车 -->
    <WindowModal :windowShow="batchGiveShow" @statusShow="closeWindow">
      <template #titleContent>
        <div>
          批量交车
        </div>
      </template>
      <template #leftContent>
        <BatchGiveTable ref="batchGiveTableRef" @operateData="operateData" />
      </template>
      <template #rightContent>
        <HandInVehicle ref="handInVehicleRef" v-show="handInShow" :vehicleArr="batchGiveList" @statusShow="handInStatus" />
      </template>
    </WindowModal>
    <VehicleImg ref="vehicleImgRefs" />
    <!-- 在途位置 -->
    <VehicleGps ref="vehicleGpss" />
    <!-- 交车条 -->
    <PictureViewer v-model:visible="visibleLookDeliver" :lookImgID="lookImgID"></PictureViewer>
  </div>
  <a-modal v-model:visible="abnormalReportShow" width="50%" footer="" @cancel="abnormalReportRef.clearData()">
    <template #title>
      <div>
        {{ `${detail.vinNo || '暂无'}-异常列表` }}
      </div>
    </template>
    <div>
      <AbnormalReport ref="abnormalReportRef" :vinNo="detail.vinNo" :vehicleDetail="detail" @statusShow="abnormal" />
    </div>
    <!-- 派车图片查看 -->

  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted, createVNode, nextTick } from 'vue'
import { copyText } from '@/utils/util'
import { useStore } from 'vuex'
import { getVehicleTaskList, getStatisticsData, shareOrderVehicle, changeOrderVehicleStatus, handoverVehicle, batchDelivery, setRemark, shareVehicle, ventBatch, taskDeliveryPic } from '@/api/transport/transport'
import { getIdentity } from '@/api/transport/dispatch'
import { employeeList } from '@/api/crmManagement/setRules'
import { uploadVehicle as upload } from '@/api/global'
import { fuzzy } from '@/api/crm/carrer/carrier'
import { LeftCircleFilled, RightCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { FileZipOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'
import GlobalTable from '@/components/GlobalTable'
import ManualScheduling from './manualScheduling.vue'
import ShareData from './shareData'
import PlantDetail from './plantDetail.vue'
import OperationDetail from '../../../transportCapacity/transportUse/detail/operationDetail.vue'
import AbnormalReport from '../comon/abnormalReport'
import AbnormalAudit from './abnormalAudit.vue'
import WindowModal from '@/views/components/windowModal/index.vue'
import OperateModel from './operateModel'
import DispatchOrder from './dispatchOrder.vue'
import TransportModel from './transportModel'
import BatchTable from './batchTable'
import EmptyTable from './emptyTable'
import BatchReport from './batchReport'
import Record from '@/views/transportCapacity/transportUse/comon/record.vue'
import BatchOperate from './batchOperate.vue'
import InspectionImg from '@/views/components/vehicleUpload/inspectionImg'
import HandInVehicle from '../comon/handInVehicle'
import VehicleGps from '@/views/order/positionInfo/carGps.vue'
import BatchGiveTable from './batchGiveTable'
import ResultFollow from '@/views/components/followOrder'
import VehicleImg from '@/views/components/vehicleImg'
import SchedulingLogs from './schedulingLogs.vue'
import submitInfo from '../taskAssignment/components/submitInfo.vue'
import PictureViewer from '@/components/PictureViewer'

export default {
  components: {
    GlobalTable,
    VehicleImg,
    BatchReport,
    ManualScheduling,
    ShareData,
    LeftCircleFilled,
    RightCircleFilled,
    AbnormalReport,
    OperationDetail,
    FileZipOutlined,
    PlantDetail,
    AbnormalAudit,
    WindowModal,
    OperateModel,
    DispatchOrder,
    TransportModel,
    BatchTable,
    BatchOperate,
    Record,
    InspectionImg,
    HandInVehicle,
    VehicleGps,
    BatchGiveTable,
    ResultFollow,
    SchedulingLogs,
    EmptyTable,
    submitInfo,
    PictureViewer
  },
  props: {
    userData: Object
  },
  setup (props) {
    const infoRef = ref(null)
    const batchReportRef = ref(null)
    const vehicleImgRef = ref(null)
    const abnormalReportRef = ref(null)
    const imgListRef = ref(null)
    const batchOperateRef = ref(null)
    const transportModelRef = ref(null)
    const batchTableRef = ref(null)
    const operateModelRef = ref(null)
    const dispatchOrderRef = ref(null)
    const tableRef = ref(null)
    const transferFormRef = ref(null)
    const handInVehicleRef = ref(null)
    const vehicleGps = ref(null)
    const vehicleGpss = ref(null)
    const batchGiveTableRef = ref(null)
    const recordRef = ref(null)
    const resultFollowRef = ref(null)
    const sendVisible = ref(false)
    const store = useStore()
    const visibleLookDeliver = ref(false)
    const lookImgID = ref([])
    const state = reactive({
      vehicleImgRefs: null,
      emptyNum: '',
      emptyShow: false,
      isOverTime: {},
      logsVehicleId: '',
      loading: false,
      shareShow: false,
      windowShow: false,
      batchReportShow: false,
      fetching: false,
      handInShow: false,
      journalShow: false,
      flagLoading: false,
      transferShow: false,
      followListShow: false,
      batchOrderShow: false,
      dataDetailShow: false,
      batchGiveShow: false,
      remarkShow: false,
      empShow: false,
      transVisible: false,
      takeVisible: false,
      pickVisible: false,
      remarkLoading: false,
      batchAccomplishShow: false,
      manualSchedulingShow: false,
      abnormalReportShow: false,
      abnormalAuditShow: false,
      transportModelShow: false,
      batchOperateShow: false,
      timerId: null,
      value1: null, // 占位符
      transShow: false,
      timeOutId: null,
      timer: null,
      vEle: undefined,
      startIndex: 0,
      globalIndex: null,
      activeKey: 1,
      tovId: '',
      remarkId: '',
      dispatchId: '',
      remark: '',
      taskStatusOpt: [
        {
          label: '新任务',
          value: 0
        },
        {
          label: '已取消',
          value: -2
        },
        {
          label: '已拒绝',
          value: -1
        },
        {
          label: '已接单',
          value: 1
        },
        {
          label: '处理中',
          value: 2
        },
        {
          label: '已完成',
          value: 9
        },
      ],
      vehicleENUM: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '散车',
          value: 1
        },
        {
          label: '批量车',
          value: 2
        },
      ],
      searchForm: {
        vinNo: null,
        orderId: null,
        transit: null,
        nextCity: null,
        status: null,
        monitorTypeLevel: null,
        startAddress: null,
        transitAddress: undefined,
        endAddress: null,
        startOrderTime: null,
        endOrderTime: null,
        isSelfArea: null,
        arrangeLoadTimeStr: null,
        vehicleNum: null,
        isArrangeLoadTime: null,
        taskCarrierName: null,
        orderStatus: null,
        isTask: null,
        pic: null,
        address: null,
        deliveryCard: null
      },
      transferForm: {
        handoverName: '', // 调度员工姓名
        handoverId: '',
        handoverType: null // 调度类型
      },
      mirrorTransferForm: {}, // 镜像字段
      detail: {},
      userData: {
        empName: null,
        empType: { value: 0 }
      },
      chooseData: {}, // 选中的数据-暂用于中途完成
      statisticsVal: null,
      dispatchDetail: {}, // 任务详情
      mirrorSearchForm: {}, // 镜像字段
      transportStatusList: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '已验车',
          value: 5
        },
        {
          label: '运输中',
          value: 2
        },
        {
          label: '已到达',
          value: ''
        },
        {
          label: '已完成',
          value: 9
        }
      ],
      batchGiveList: [], // 批量交车数据回传接收
      groupListData: [], // 小组成员list
      employeeOptions: [], // 员工list
      carriers: [], // 内部承运商list
      startCitys: [], // 重置赋值置空字段
      endtCitys: [], // 重置赋值置空字段
      batchData: [], // 批量操作组件传递数组
      smallVehList: [],
      statisticsList: [],
      sliceTable: [],
      selectedRowKeys: [],
      batchList: [],
      dataSource: [],
      transferFormRules: {
        handoverId: {
          required: true,
          message: '请选择承运商',
          trigger: 'blur',
          type: 'string'
        },
        handoverType: {
          required: true,
          message: '请选择部门',
          trigger: 'blur',
          type: 'number'
        }
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: null,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'NO',
          align: 'center',
          fixed: 'left',
          width: '3%'
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'left',
          width: '3%',
          slots: {
            customRender: 'operation'
          }
        },
        {
          title: '车牌/车型',
          dataIndex: 'vinNo',
          width: '6%',
          fixed: 'left',
          align: 'center',
          slots: {
            customRender: 'vinNo'
          }
        },
        {
          title: '发运进度',
          dataIndex: 'completeness',
          width: '12%',
          align: 'center',
          slots: {
            customRender: 'completeness'
          }
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatus.label',
          width: '5%',
          align: 'center',
          slots: {
            customRender: 'orderStatus'
          }
        },
        {
          title: '订单号',
          dataIndex: 'orderId',
          width: '7%',
          align: 'center'
        },
        {
          title: '任务信息',
          dataIndex: 'taskInfo',
          slots: {
            customRender: 'taskInfo'
          },
          width: '12%',
          align: 'center'
        },
        {
          title: '调度备注',
          dataIndex: 'remark',
          width: '10%',
          align: 'center',
          slots: {
            customRender: 'remark'
          }
        },
        {
          title: '预计装车时间',
          dataIndex: 'arrangeLoadTime',
          width: 140,
          align: 'center',
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
          width: '4%',
          align: 'center'
        },
        {
          title: '下单时间',
          dataIndex: 'orderTime',
          width: 140,
          align: 'center',
          slots: {
            customRender: 'orderTime'
          }
        },

        {
          title: '调度员',
          dataIndex: 'currentShipper',
          width: 150,
          align: 'center',
          slots: {
            customRender: 'currentShipper'
          }
        },
        {
          title: '城市',
          dataIndex: 'nowCity',
          width: '8%',
          align: 'center',
          slots: {
            customRender: 'nowCityId'
          }
        },
        {
          title: '起-终',
          dataIndex: 'address',
          width: '10%',
          align: 'center',
          slots: {
            customRender: 'address'
          }
        },
        {
          title: '下个调度员',
          dataIndex: 'nextShipper',
          width: '6%',
          align: 'center'
        },
        {
          title: '验车任务',
          dataIndex: 'taskData',
          align: 'center',
          width: 300,
          slots: {
            customRender: 'taskData'
          }
        },
        {
          title: '提车任务',
          dataIndex: 'extractionTask',
          align: 'center',
          width: 300,
          slots: {
            customRender: 'extractionTask'
          }
        },
        {
          title: '干线任务',
          dataIndex: 'trunkTask',
          align: 'center',
          width: 300,
          slots: {
            customRender: 'trunkTask'
          }
        },
        {
          title: '送车任务',
          dataIndex: 'deliveryTask',
          width: 300,
          align: 'center',
          slots: {
            customRender: 'deliveryTask'
          }
        },
        {
          title: '外派任务',
          dataIndex: 'taskNames',
          width: 300,
          align: 'center',
          slots: {
            customRender: 'taskNames'
          }
        },
        // {
        //   title: '异常/费用上报',
        //   align: 'center',
        //   width: 110,
        //   fixed: 'right',
        //   slots: {
        //     customRender: 'report'
        //   }
        // }
      ]
    })
    onMounted(() => {
      state.dispatchId = store.getters.info.id
      state.userName = store.getters.userName
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      state.mirrorTransferForm = JSON.stringify(state.transferForm)
      let DOM = document.querySelectorAll('.table-data .ant-table-body')
      loadData()
      // 滚动请求
      // DOM[0].addEventListener('scroll', e => {
      //   clearTimeout(state.timerId)
      //   state.timerId = setTimeout(() => {
      //     if (e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) < 10) {
      //       if (state.smallVehList.length < state.pagination.total) state.pagination.current++, loadData()
      //     }
      //     state.timerId = null
      //   }, 500)
      // })
    })
    // 查看交车条
    const lookDeliveryCard = (record) => {
      console.log(11, record);
      taskDeliveryPic(record.orderVehicleId, {
        taskId: record.taskId
      }).then(res => {
        if (res.code !== 10000) return
        lookImgID.value = res.data.map(item => item.fileId)
        visibleLookDeliver.value = true
      })
    }
    // 查看位置
    const lookVehicleGps = (record) => {
      setTimeout(() => {
        vehicleGpss.value.onvisible(true)
        vehicleGpss.value.onorderVehicleId(record.orderVehicleId)
      }, 50)
    }
    const status = () => {
      sendVisible.value = false
      transportModelRef.value.getTaskListData()
    }
    const sendOrder = () => {
      sendVisible.value = true
      nextTick(() => {
        infoRef.value.reqVehicleTaskType([state.dispatchDetail.orderVehicleId])

      })
    }
    const openDisRemarks = (record) => {
      state.isOverTime.isOverTime = record.isOverTime.value
      state.isOverTime.transportType = record.transportType.value
      state.isOverTime.taskType = record.taskType
      state.remarkShow = true
      state.logsVehicleId = record.orderVehicleId
    }
    const leftMove = () => {
      let DOM = document.querySelector('.small-car .data-statistics')
      let left = Number(DOM.style.left.replace('vw', ''))
      if (left < 0) {
        DOM.style.left = left + 8 + 'vw'
      }
    }
    const rightMove = () => {
      let DOM = document.querySelector('.small-car .data-statistics')
      let left = Number(DOM.style.left.replace('vw', ''))
      DOM.style.left = left - 8 + 'vw'
    }
    const getEmpData = () => {
      state.userData = props.userData.userData
      state.groupListData = props.userData.groupListData
    }
    const getStatistics = () => {
      getStatisticsData({ dispatchId: state.dispatchId === '' ? state.userData.empId : state.dispatchId }).then(res => {
        if (res.code === 10000) {
          if (res.data) {
            state.statisticsList = res.data
          }
        }
      })
    }
    // 小组成员切换
    const groupChange = item => {
      state.dispatchId = item.empId
      state.smallVehList = []
      state.statisticsVal = null
      state.globalIndex = null
      loadData()
      let DOMS = document.querySelectorAll('.small-car .statistics-content')
      for (let i = 0; i < DOMS.length; i++) {
        DOMS[i].style.color = '#344563'
        DOMS[i].style.background = ''
      }
    }
    const loadData = (type) => {
      state.loading = true
      getVehicleTaskList({
        ...state.searchForm,
        dispatchId: state.dispatchId === '' ? state.userData.empId : state.dispatchId,
        transportType: state.statisticsVal,
        current: state.pagination.current,
        size: 10,
      }).then(res => {
        if (res.code === 10000) {
          if (res.data) {
            state.pagination.total = res.data.total
            if (res.data.records) {
              res.data.records.forEach((item, index) => {
                state.smallVehList.push({
                  ...item
                })
              })
            }
            state.smallVehList.forEach((item, index) => {
              item.NO = index + 1
            })
            setTimeout(() => {
              if (state.smallVehList.length >= state.pagination.total) {
                state.pagination.current--
              }
            }, 1000)
          } else message.error('暂无数据')
        }
      }).finally(() => {
        state.loading = false
      })
      if (!type) getStatistics()
    }
    const onSelectChange = (e, v) => {
      state.selectedRowKeys = e
      state.batchList = v
    }
    const onSelect = e => {
      setTimeout(() => {
        if (state.selectedRowKeys.length <= 0) state.chooseData = {}
        else state.chooseData = e
      }, 50)
    }
    const resetData = () => {
      state.loading = true
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      // state.smallVehList = JSON.parse(state.dataSource)
      state.startCitys = []
      state.endtCitys = []
      state.selectedRowKeys = []
      state.batchList = []
      state.pagination.current = 1
      state.smallVehList = []
      loadData()
      // setTimeout(() => {
      //   fictitiousScroll()
      //   state.loading = false
      // }, JSON.parse(state.dataSource).length * 2)
    }
    const searchData = () => {
      state.pagination.current = 1
      state.smallVehList = []
      loadData()
      // state.loading = true
      // state.smallVehList = JSON.parse(state.dataSource)
      // 查询筛选 ↓
      // for (const key in state.searchForm) {
      //   if (state.searchForm[key] == '') continue
      //   if (state.searchForm[key].value === null) continue // 选择框未选择则跳出本次循环并开始下一循环
      //   state.smallVehList = JSON.parse(JSON.stringify(state.smallVehList)).filter(item => {
      //     return JSON.stringify(state.searchForm[key]) == JSON.stringify(item[key])
      //   })
      // }
      // // 查询筛选 ↑
      // setTimeout(() => {
      //   fictitiousScroll()
      //   state.loading = false
      // }, JSON.parse(state.dataSource).length * 2 < 500 ? 1000 : JSON.parse(state.dataSource).length * 2)
    }
    const statisticsSearch = (item, index) => {
      let DOMS = document.querySelectorAll('.small-car .statistics-content')
      state.smallVehList = []
      console.log(DOMS);
      if (state.globalIndex !== index) {
        state.globalIndex = index
        for (let i = 0; i < DOMS.length; i++) {
          DOMS[i].style.color = '#344563'
          DOMS[i].style.background = ''
          if (i === index) {
            DOMS[index].style.color = '#fff'
            DOMS[index].style.background = 'rgba(0,102,204,.8)'
            DOMS[index].style.borderRadius = '8px'
          }
        }
        state.statisticsVal = item.orderStatus.value
        state.pagination.current = 1
        loadData(1)
      } else {
        DOMS[state.globalIndex].style.color = '#344563'
        DOMS[state.globalIndex].style.background = ''
        state.statisticsVal = null
        state.globalIndex = null
        state.pagination.current = 1
        loadData(1)
      }
    }
    const timeChange = (e, v) => {
      console.log(e, v);
    }
    const startAddressChange = (e, v) => {
      if (v.length <= 0) state.searchForm.startAddress = ''
      switch (v.length) {
        case 1:
          state.searchForm.startAddress = v[0].label
          break
        case 2:
          state.searchForm.startAddress = v[0].label + v[1].label
          break
        case 3:
          state.searchForm.startAddress = v[0].label + v[1].label + v[2].label
          break
      }
    }
    const endAddressChange = (e, v) => {
      if (v.length <= 0) state.searchForm.endAddress = ''
      switch (v.length) {
        case 1:
          state.searchForm.endAddress = v[0].label
          break
        case 2:
          state.searchForm.endAddress = v[0].label + v[1].label
          break
        case 3:
          state.searchForm.endAddress = v[0].label + v[1].label + v[2].label
          break
      }
    }
    const setTransitAddress = (value, selectedOptions) => {
      state.searchForm.nowCityId = value[1]
    }
    const setNextCityAddress = (value, selectedOptions) => {
      state.searchForm.nextCityId = value[1]
    }
    const batchOrder = () => {
      state.batchOrderShow = true
    }
    const acceptOrder = () => {
      state.batchList = []
      state.selectedRowKeys = []
      state.dataSource = []
      state.batchOrderShow = false
      // loadData()
    }
    // --------------------------------------------------异常上报显示
    const abnormalReport = (e) => {
      if (e !== undefined) state.reportShow = e
      else state.reportShow = true
    }
    // ---------------------------------------------------
    const batchManual = (e, n) => {
      if (e.orderId) state.detail = e
      if (e !== undefined) state.manualSchedulingShow = e
      else state.dispatchShow = true
    }
    // ---------------------------------------------------
    const dataDetail = (e) => {
      if (e?.orderId) state.detail = e, state.dataDetailShow = true
      else state.dataDetailShow = e
      // if (e === true) state.dataDetailShow = e
      // else {
      //   state.dataDetailShow = true
      // }
    }
    const model = e => {
      if (e?.orderId) {
        state.detail = e, state.windowShow = true
        setTimeout(() => {
          state.transportModelShow = true
          operateModelRef.value.getData()
          transportModelRef.value.getTaskListData()
        }, 1)
      } else state.windowShow = false
    }
    // ----------------------------------------------------批量共享/取消共享
    const confirmShare = (e) => {
      if (e.key || e.cancelType) {
        shareOrderVehicle({
          orderVehicleIds: state.selectedRowKeys,
          sharer: e.value || null,
          sharerId: e.key || null
        }).then(res => {
          if (res.code === 10000) {
            message.success(e.value ? '共享成功' : '已取消共享')
            resetData()
            state.shareShow = false
          }
        })
      } else state.shareShow = false
    }
    // 发运弹窗
    const transVehicle = record => {
      state.detail = record
      state.transShow = true
    }
    const openDetailed = record => {
      state.detail = record
      state.abnormalReportShow = true
      setTimeout(() => {
        console.log(abnormalReportRef);
        if (abnormalReportRef.value) abnormalReportRef.value.openWindow()
      }, 50)
    }
    // 查看明细--关闭弹窗
    const abnormalData = e => {
      state.abnormalReportShow = false
    }
    // 异常审核--关闭弹窗
    const abnormalAuditEvent = e => {
      state.abnormalAuditShow = false
    }
    // 选择部门移交车辆
    const dispatchTypeChange = e => {
      if (state.transferForm.handoverType === 1) {
        state.transferFormRules.handoverId.message = '请选择员工'
      } else {
        state.transferFormRules.handoverId.message = '请选择承运商'
      }
    }
    // 承运商关键字搜索
    const fetchCarrier = value => {
      if (value === '' || value === null) clearTimeout(state.timeOutId), state.fetching = false
      if (value.length > 1) {
        state.fetching = true
        if (state.timeOutId !== null) {
          clearTimeout(state.timeOutId)
        }
        state.timeOutId = setTimeout(() => {
          fuzzy({ name: value, carrierType: 1 }).then(res => {
            if (res.code === 10000) {
              state.carriers = res.data
            } else state.carriers = []
            state.fetching = false
          })
        }, 1000)
      } else {
        state.carriers = []
      }
    }
    // 承运商选择
    const carrierChoose = item => {
      state.transferForm.handoverId = item.id
      state.transferForm.handoverName = item.name
      state.carriers = []
    }
    // 员工搜索
    const employerValue = e => {
      if (e === '' || e === null) clearTimeout(state.timer), state.fetching = false
      state.flagLoading = true
      if (state.timer !== null) {
        clearTimeout(state.timer)
      }
      state.timer = setTimeout(() => {
        employeeList({
          current: 1,
          name: e,
          size: 10
        }).then(res => {
          if (res.code === 10000) {
            state.employeeOptions = res.data
            state.flagLoading = false
          }
        }).catch(err => { console.log(err) })
      }, 1000)
    }
    // 选择员工
    const employeeChoose = (item) => {
      state.transferForm.handoverName = item.name
      state.transferForm.handoverId = item.id
      state.employeeOptions = []
    }
    // 确认移交
    const confirmTransfer = () => {
      transferFormRef.value.validate().then(res => {
        handoverVehicle({ ...state.transferForm, orderVehicleIds: state.selectedRowKeys })
          .then(res => {
            if (res.code === 10000) {
              message.success('移交成功')
              resetData()
              state.transferShow = false
              state.transferForm = JSON.parse(state.mirrorTransferForm)
            }
          })
      })
    }
    // 任务模式派单完成自动重新请求任务模式表格数据
    const planTable = (e) => {
      if (e) transportModelRef.value.getTaskListData()
    }

    const tabsChange = e => {
      setTimeout(() => {
        if (e === 2) dispatchOrderRef.value.getTaskList()
        if (e === 1) operateModelRef.value.getData()
      }, 10)
    }
    // 批量操作传递数据
    const operateData = e => {
      if (e.orderId) {
        state.batchData = e
        setTimeout(() => {
          batchOperateRef.value.getData()
        }, 100)
      } else {
        state.batchGiveList = e
        state.handInShow = true
      }
    }
    // 批量放空
    const empTypeOpen = () => {
      state.emptyShow = true
      state.emptyNum = null
      emptyIsEnd.value = false
    }

    const emptyInfo = ref({})
    const emptyIsEnd = ref(false)
    const emptyData = (e) => {
      emptyIsEnd.value = true
      emptyInfo.value = e
    }
    //批量放空接口
    const emptyLoading = ref(false)
    const submitEmpty = () => {
      if (!state.emptyNum) {
        message.error('请输入单台放空费')
        return
      }
      emptyLoading.value = true
      ventBatch({
        amt: state.emptyNum,
        orderId: emptyInfo.value.orderId,
        orderVehicleIds: emptyInfo.value.orderVehicleIds
      }).then(res => {
        if (res.code === 10000) {
          message.success('操作成功')
          state.emptyShow = false
        }
      }).finally(() => { emptyLoading.value = false })
    }
    const closeWindow = e => {
      state.batchOperateShow = e
      state.batchGiveShow = e
      setTimeout(() => {
        if (batchTableRef.value) batchTableRef.value.clearData()
        if (batchGiveTableRef.value) batchGiveTableRef.value.clearData()
      }, 100)
    }
    // 更新可安排小车列表数据
    const loadTable = e => {
      if (e === true) {
        batchTableRef.value.loadDataList()
        batchTableRef.value.arrangedList = []
        state.batchOperateShow = false
      }
    }
    const transportModelShowStatus = e => {
      if (e.orderId) state.dispatchDetail = e
      if (e === true) operateModelRef.value.operateData()
      if (typeof e == 'number') {
        state.activeKey = e
        setTimeout(() => {
          tabsChange(e)
        }, 1)
      }
    }

    // 更新数据
    const modelLoadData = e => {
      if (e) {
        if (e === true) {
          setTimeout(() => {
            transportModelRef.value.getTaskListData()
          }, 200)
        } else {
          state.tovId = e
        }
      }
    }
    const lookVehicleImg = () => {
      setTimeout(() => {
        vehicleImgRef.value.vehicleImg(state.detail.orderVehicleId, state.detail.vinNo)
      }, 50)
    }
    // 查看视频图片
    const lookVehicleImgs = (record) => {
      console.log(state.vehicleImgRefs);
      setTimeout(() => {
        state.vehicleImgRefs.vehicleImg(record.orderVehicleId, record.vinNo)
      }, 50)
    }
    // 车辆图片
    const getUpdateImg = () => {
      setTimeout(() => {
        imgListRef.value.inspectionImg = true
        imgListRef.value.loadData(state.detail.orderVehicleId)
      }, 100)
    }
    // 异常列表回传
    const abnormal = e => {
      state.abnormalReportShow = false
    }
    // 在途位置gps
    const orderVehicleGps = () => {
      setTimeout(() => {
        vehicleGps.value.onvisible(true)
        vehicleGps.value.onorderVehicleId(state.detail.orderVehicleId)
      }, 50)
    }
    // 运车完成
    const transportComplete = () => {
      let status = state.chooseData.completeness.some(item => item.label == '干' && item.value === 5)
      if (state.selectedRowKeys.length === 1 && state.chooseData.tovId) {
        if (status) {
          changeOrderVehicleStatus({ transOrderVehicleIds: [state.chooseData.tovId] })
            .then(res => {
              if (res.code === 10000) {
                message.success(`${state.chooseData.vinNo}已中途完成`)
                state.pagination.current = 1
                state.smallVehList = []
                loadData()
              }
            })
        } else message.error('该车不满足中途完成的条件(干线中途卸车)')
      } else {
        state.selectedRowKeys = []
        state.batchList = []
        state.chooseData = {}
        message.error('中途完成暂不支持选择多辆车，请重新选择')
      }
    }
    const handInStatus = e => {
      handInVehicleRef.value.confirmLoading = true
      if (!e.formData) state.handInShow = false
      else {
        handInVehicleRef.value.confirmLoading = true
        let orderVehicleIds = state.batchGiveList.map(item => item.orderVehicleId)
        upload(e.formData).then(res => {
          if (res.code === 10000) {
            let fileId = res.data.id
            let submit = {
              deliveryOfficeOrg: e.deliveryOfficeOrg,
              deliveryType: e.deliveryType,
              officeOrgId: e.officeOrgId,
              remark: e.remark,
              fileId: fileId,
              orderVehicleIds: orderVehicleIds
            }
            batchDelivery(submit).then(res => {
              if (res.code === 10000) {
                message.success('交车成功')
                loadData()
                handInVehicleRef.value.cancelClose()
                state.batchGiveShow = false
              }
            }).finally(() => {
              if (handInVehicleRef.value) {
                handInVehicleRef.value.confirmLoading = false
              }

            })
          }
        })
      }
    }
    // 批量验车完成
    const batchInspection = () => {
      state.batchAccomplishShow = true
    }
    const batchBtn = () => {
      state.batchOperateShow = true
      setTimeout(() => {
        batchTableRef.value.loadDataList()
      }, 100)
    }
    const batchVehicle = (type) => {
      if (!type) { // 老交车方式
        let status = state.batchList.every(item => item.vehicleTransStatus.value === 9)
        if (status) state.handInShow = true
        else message.error('存在任务未完成的车辆')
      } else { // 新交车方式
        state.batchGiveShow = true
        state.handInShow = false
        setTimeout(() => {
          batchGiveTableRef.value.clearData()
          batchGiveTableRef.value.loadData()
        }, 100)
        // batchGiveTableRef.value.clearData()
        // batchGiveTableRef.value.loadData()
      }
    }
    const batchReportClick = () => {
      state.batchReportShow = true
      // setTimeout(() => {
      //   batchReportRef.value.loadData()
      // })
    }

    const showRecord = () => {
      state.journalShow = true
      setTimeout(() => {
        recordRef.value.loadData()
      }, 10)
    }
    const nextPage = () => {
      state.pagination.current++
      loadData()
    }
    const clearData = () => {
      setTimeout(() => {
        transportModelRef.value.clearData()
      }, 10)
    }
    const shareBtn = () => {
      Modal.confirm({
        title: () => '确认将选择的车辆共享吗?',
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () =>
          createVNode(
            'div',
            {
              style: 'color:red;'
            },
            '',
          ),
        onOk () {
          shareVehicle({
            isShare: 1,
            orderVehicleIds: state.selectedRowKeys
          }).then(res => {
            if (res.code === 10000) {
              message.success('共享成功')
              resetData()
            }
          })
        },
        onCancel () {
          console.log('Cancel');
        },
        class: '',
      });
    }
    const lookFollowList = () => {
      state.followListShow = true
      setTimeout(() => {
        resultFollowRef.value.loadData(state.detail.orderVehicleId)
      }, 10)
    }
    const getContainer = () => {
      return document.querySelector('.window-content')
    }
    const setRemaekOk = (str) => {
      state.smallVehList.forEach(item => {
        if (item.orderVehicleId == state.logsVehicleId) {
          item.remark = str;
          console.log(item.orderVehicleId);
        }
      })


    }
    return {
      ...toRefs(state),
      tableRef,
      recordRef,
      imgListRef,
      vehicleImgRef,
      batchTableRef,
      batchReportRef,
      transferFormRef,
      operateModelRef,
      abnormalReportRef,
      dispatchOrderRef,
      transportModelRef,
      batchOperateRef,
      handInVehicleRef,
      batchGiveTableRef,
      resultFollowRef,
      operateData,
      getContainer,
      openDetailed,
      shareBtn,
      lookFollowList,
      clearData,
      nextPage,
      vehicleGps,
      loadTable,
      showRecord,
      transportComplete,
      orderVehicleGps,
      abnormal,
      model,
      getEmpData,
      setTransitAddress,
      setNextCityAddress,
      batchVehicle,
      lookVehicleImg,
      lookVehicleImgs,
      getUpdateImg,
      groupChange,
      statisticsSearch,
      dispatchTypeChange,
      confirmTransfer,
      carrierChoose,
      employerValue,
      employeeChoose,
      fetchCarrier,
      confirmShare,
      batchManual,
      copyText,
      leftMove,
      rightMove,
      loadData,
      resetData,
      searchData,
      timeChange,
      abnormalAuditEvent,
      startAddressChange,
      endAddressChange,
      abnormalData,
      onSelect,
      dataDetail,
      acceptOrder,
      batchOrder,
      abnormalReport,
      onSelectChange,
      transVehicle,
      planTable,
      closeWindow,
      tabsChange,
      batchBtn,
      batchReportClick,
      batchInspection,
      modelLoadData,
      handInStatus,
      transportModelShowStatus,
      openDisRemarks,
      setRemaekOk,
      emptyData,
      submitEmpty,
      emptyInfo,
      emptyIsEnd,
      empTypeOpen,
      sendVisible,
      sendOrder,
      infoRef,
      status,
      lookDeliveryCard,
      visibleLookDeliver,
      lookImgID,
      lookVehicleGps,
      vehicleGps,
      vehicleGpss
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

:deep(.ant-form-item) {
  margin-bottom: 10px !important;
}

:deep(.ant-upload-picture-card-wrapper) {
  width: auto !important;
  flex-direction: column;
}

:deep(.ant-upload-select-picture-card) {
  margin-bottom: 0 !important;
}

:deep(.ant-table-row) {
  position: relative;
}

:deep(.ant-table-row > td) {
  height: 63px !important;
}

:deep(.ant-table-row > th:last-child) {
  background-color: #f00 !important;
  position: absolute !important;
  right: 0;
  top: 0;
}

:deep(.ant-table-row:nth-child(even)) {
  background-color: #fafafa;
}

.ant-upload-text {
  background-color: #f0f2f5 !important;
  border: none;
  border-radius: 0 !important;
}

:deep(.ant-steps-icon-dot) {
  background-color: #0c8226 !important;
}

:deep(.ant-steps-item-icon) {
  margin-left: 9px !important;
}

:deep(.ant-steps-item-tail) {
  width: 112rpx !important;
  margin-left: 47% !important;
}

// 隐藏最后的线条
:deep(.ant-steps-item):nth-last-child(2) {
  .ant-steps-item-tail::after {
    display: none !important;
  }
}

:deep(.ant-steps-item-tail::after) {
  width: calc(100% - 11px) !important;
  height: 1px !important;
  // margin-left: -192% !important;
  background-color: #0c8226;
}

.batch-order-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  .ant-btn {
    background-color: #f0f2f5;
  }

  :deep(.ant-btn-primary) {
    color: #fff;
    border-color: #344563;
    background-color: #344563;
  }

  .ant-btn-primary:hover {
    color: #000;
    background-color: #fff;
  }
}

.animated-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #333;
  position: relative;
  overflow: hidden;
  animation: slide-in 2s forwards;
}

.data-paginate {
  position: absolute;
  right: 10px;
  top: -10px;
  z-index: 99;
}

.remark-content {
  width: 80%;
  max-height: 80px;
  overflow: hidden;
  text-align: left;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.plant-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.isExpatriate {
  width: 16px;
  height: 16px;
  background-color: rgba(57, 106, 252, 1);
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.picInfoBox {
  min-width: 30px;
}
</style>
