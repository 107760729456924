import { putRequest, getRequest, postBodyRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/trans/transport/line/page', params)
}

export function detail (id) {
  return getRequest(`/trans/transport/line/${id}`)
}

export function add (params) {
  return postBodyRequest('/trans/transport/line/add', params)
}

export function edit (params) {
  return postBodyRequest('/trans/transport/line/edit', params)
}

export function enable (id, isnable) {
  return putRequest(`/trans/transport/line/${id}/${isnable}`)
}
