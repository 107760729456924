<template>
  <div>
    <div style="display:flex;width:100%;max-height:75vh;overflow:scroll;overflow-x: hidden;">
      <div class="left-content">
        <div class="content-data" style="margin-bottom:40px" v-for="(item, index) in tasks" :key="index">
          <div>
            <!-- <a style="font-weight:normal;" @click="updateImg">
                  <span>车辆照片</span>
                  <span style="margin-left:10px">{{ item.picNum || '0' }}张</span>
                </a> -->
            <div>
              <div style="display: flex;align-items:center;margin-bottom:15px;">
                <div class="title-name-style">{{ item.taskType?.label }}</div>
                <a-divider type="vertical" />
                <span style="font-size:14px">
                  <span>{{ item.taskName }}</span>
                </span>
                <a-divider type="vertical" />
                <span class="operation">
                  <img class="img" src="@/assets/images/carrier/QRC.png" @click="handleCope(item)">
                </span>
                <!-- <a-divider type="vertical" /> -->
                <!-- <span><img class="img" src="@/assets/images/carrier/employee.png" /></span> -->
              </div>

            </div>
            <div style="display: flex;justify-content: space-between;font-size: 12px;">
              <div>
                <div class="title-color">承运商</div>
                <div class="data-color">{{ item.carrierName }}</div>
              </div>
              <div>
                <div class="title-color">派单价</div>
                <div class="data-color">￥{{ item.totalAmt }}</div>
              </div>
              <div>
                <div class="title-color">当前状态</div>
                <div class="data-color">{{ item.taskStatus?.label }}</div>
              </div>
              <div style="position: relative;">
                <div class="operate-btn">
                  <a-popconfirm ok-text="确认" cancel-text="取消" @confirm="confirmCancel(item)">
                    <template #title>
                      <div>
                        <div>确认取消该任务？</div>
                        <div><a-input v-model:value="cancelPlantRemark" placeholder="取消原因" /></div>
                      </div>
                    </template>
                    <div v-show="!item.btnShow"><a-button
                        style="margin-bottom:5px;background:#0066CC;color:#fff">撤销</a-button></div>
                  </a-popconfirm>
                  <div><a-button style="background:#0066CC;color:#fff" @click="monitorDetail(item)">监控</a-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 选择发运任务 -->
        <div v-show="taskList.length > 0">
          <div class="content-title">选择发运任务</div>
          <div class="transport">
            <a-radio-group v-model:value="taskIds">
              <a-radio :value="item.taskIds" v-for="(item, index) in taskList" :key="index"
                @click.stop="planChange(item)">
                <span class="choose-title">{{ item.taskName + '：' }}</span>
                <span class="choose-content" v-if="item.startAddress == '' && item.endAddress == ''">暂无数据</span>
                <span class="choose-content" v-else>{{ item.startAddress + '——' + item.endAddress }}</span>
              </a-radio>
            </a-radio-group>
            <div class="operate-btn">
              <a-button class="confirm-btn" type="primary" @click="choosePlant">确定</a-button>
            </div>
          </div>
        </div>
        <h2 style="display:flex;justify-content:center;align-items:center" v-show="taskList.length <= 0">暂无发运任务</h2>
        <!-- 选择发运线路 -->
        <div v-show="taskArr.length > 0">
          <div class="content-title">发运线路</div>
          <div class="transport" style="padding:10px;display:flex;justify-content: space-between;">
            <span v-show="lineData.name">{{ lineData.name }}</span>
            <span>
              <span v-show="lineData.startAddress !== ''">
                {{ lineData.startAddress || recordsList[0]?.startAddress }} ————
              </span>
              <span v-show="lineData.endAddress !== ''">
                {{ lineData.endAddress || recordsList[0]?.endAddress }}
                <a-cascader v-if="changeLineShow && lineChooseShow" :options="$store.state.app.cityDict"
                  :show-search="{ filter }" :changeOnSelect="true" @change="setEndAddress" placeholder="请输入终点" />
              </span>
            </span>
            <span v-show="lineChooseShow"><a @click="confirmLine">{{ changeLineShow ? '确认线路' : '更换线路' }}</a></span>
          </div>
        </div>

        <!-- 选择承运商 -->
        <div v-show="taskArr.length > 0">
          <div class="content-title">承运商</div>
          <div class="transport" style="padding:10px;display:flex;justify-content: space-between;">
            <span>{{ carrier.carrierName }}</span>
            <a @click="carrierShow = !carrierShow">{{ carrier.carrierName ? '更换承运商' : '选择承运商' }}</a>
          </div>
          <div v-show="carrierShow">
            <Carrier ref="carrierRef" :carrierId="carrierId" @confirmCarrier="confirmCarrier" />
          </div>
        </div>
        <!-- 填写对接人 -->
        <div v-show="taskArr.length > 0 && carrier.carrierName">
          <div class="content-title">对接人</div>
          <div class="transport" style="padding:10px;display:flex;justify-content: space-between;">
            <a-form-item label="对接人" style="margin-bottom:0">
              <a-input v-model:value="dockingPerson" />
            </a-form-item>
            <a-form-item label="对接人电话" style="margin-bottom:0">
              <a-input v-model:value="dockingMobile" />
            </a-form-item>
          </div>
        </div>
        <!-- 设置运费 -->
        <div v-show="taskArr.length > 0 && carrier.carrierName">
          <div class="content-title" v-if="taskArr.length > 0">
            <span>运费</span>
            <span style="color:#0066CC">单台总费用：￥{{ taskArr.reduce((t, v) => t + v.amt, 0) }}</span>
          </div>
          <div class="transport"
            style="padding:10px 10px 0 10px;display:flex;justify-content: space-between;flex-wrap: wrap;">
            <div class="set-place" v-for="(item, index) in taskArr" :key="index">
              <span v-show="item.type.value == 0">验车：</span>
              <span v-show="item.type.value == 1">提车：</span>
              <span v-show="item.type.value == 2">干线：</span>
              <span v-show="item.type.value == 3">送车：</span>
              <div class="input-div">
                <div class="input-data">
                  <span>运费：</span>
                  <a-input-number v-show="item != 2" v-model:value="item.amt" :min="0" style="width:100px" />
                  <a-input v-show="item == 2" v-model:value="value" style="width:100px;">
                    <template #addonAfter>
                      <setting-outlined @click="remarkShow = !remarkShow" />
                    </template>
                  </a-input>
                </div>
                <div class="input-data">
                  <span>油卡：</span>
                  <a-input-number v-model:value="item.oilAmt" :min="0" style="width:100px" />
                </div>
              </div>
            </div>
            <!-- 备注 -->
            <div v-show="remarkShow" class="set-place" style="margin-top:15px;width:100%">
              <span>&emsp;&emsp;&emsp;</span>
              <div class="input-div" style="flex:1">
                <div class="input-data" style="flex:1">
                  <span>备注</span>
                  <a-input v-model:value="value" style="flex:1" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 备注 -->
        <div v-show="taskArr.length > 0 && carrier.carrierName">
          <div class="content-title">
            <span>备注</span>
          </div>
          <a-textarea v-model:value="remark" showCount :maxlength="200" />
        </div>
        <!-- 确认派单 -->
        <div v-show="taskArr.length > 0 && carrier.carrierName" style="margin:15px 0"><a-button class="confirm-btn"
            type="primary" @click="confirmDispatch">确定</a-button>
        </div>
        <div class="loading-div" v-show="loadingShow">
          <a-spin size="large" />
        </div>
      </div>

    </div>
  </div>
  <!-- 小程序码 -->
  <a-modal v-model:visible="QRCshow" title="小程序码" footer="">
    <div class="QRC-img">
      <div>
        <div>车架号：<a-tag color="green">{{ detailData.vinNo }}</a-tag></div>
        <div>线路：<span style="color:#f00">{{ QRCdata.lineStart + '—' + QRCdata.lineEnd }}</span></div>
        <img v-show="!QRCloading" class="img" :src="QRCurl" style="width:100%;height:100%">
        <div style="text-align: center"><a-spin v-show="QRCloading" size="large" /></div>
      </div>
    </div>
  </a-modal>
  <!-- 中转地 -->
  <a-modal v-model:visible="transitChooseShow" title="是否选择中转地" @ok="transitConfirm"
    @cancel="transitChooseShow = false, whether = null" :destroyOnClose="true">
    <a-radio-group v-model:value="whether">
      <a-radio :value="1">是</a-radio>
      <a-radio :value="2">否</a-radio>
    </a-radio-group>
    <a-divider />
    <a-cascader v-if="whether === 1" :options="$store.state.app.PClist" :show-search="{ filter }" :changeOnSelect="true"
      @change="setAddress" placeholder="中转地选择" />
    <a-input v-model:value="arriveStore.details" placeholder="输入详细地址" />
  </a-modal>
  <ReachAddress :windowShow="reachChooseShow" @address="changeEndAddress" />
  <!-- 发运详情 -->
  <TransportDetail :windowShow="monitorDetailShow" :taskDetail="taskDetail" @statusShow="monitorDetail" />
</template>

<script>
import { reactive, toRefs, ref, watch, onMounted } from 'vue'
import { batchManualOperate, dispatchArrange, getDispatchList, cancelPlant } from '@/api/transport/transport'
import { SettingOutlined } from '@ant-design/icons-vue'
// import { transPlan, transDepart, transfer, setAssembled, transArrive, advanceLoading, getAdvance, getTransData, changeCarrier, changeDriver } from '@/api/transport/truck'
import { lineChoose, linePage } from '@/api/transport/transportUse'
import { message } from 'ant-design-vue'
import { QRCurl } from '@/api/transport/inspection'
import Carrier from '../comon/carrierMod.vue'
import ReachAddress from '../comon/reachAddress.vue'
import PlantDetail from './plantDetail.vue'
import Inspector from '@/views/components/inspector'
import TransportDetail from './transportDetail.vue'

export default {
  props: {
    detail: Object,
    tasks: Array,
    show: Boolean
  },
  components: {
    Carrier,
    SettingOutlined,
    PlantDetail,
    Inspector,
    TransportDetail,
    ReachAddress
  },
  setup (props, context) {
    const carrierRef = ref(null)
    const state = reactive({
      lineShow: false,
      QRCshow: false,
      btnLoading: false,
      loadingShow: true,
      QRCloading: false,
      bigCarShow: false,
      recordShow: false,
      carrierShow: false,
      remarkShow: false,
      lineLoading: false,
      lineChooseShow: false,
      changeLineShow: false,
      reachChooseShow: false,
      transitChooseShow: false,
      detailData: props.detail,
      transAddress: '',
      arriveStore: {
        city: '',
        area: '',
        province: '',
        details: ''
      }, // 中转地
      startAddressData: {},
      endAddressData: {},
      monitorDetailShow: false,
      carrier: {},
      QRCdata: {},
      planData: {},
      taskDetail: {},
      storeAddress: {
        dockingMobile: '',
        dockingPerson: ''
      },
      linePage: {
        current: 1
      },
      transportType: null,
      whether: null,
      lineData: {},
      lineList: [],
      endId: [],
      startId: [],
      tasks: [],
      orderVehicleIds: [],
      recordsList: [],
      taskList: [],
      planId: '',
      taskIds: '',
      remark: '',
      QRCurl: '',
      cancelPlantRemark: '',
      taskArr: [],
      carrierId: '',
      dockingMobile: '',
      dockingPerson: ''
    })
    const cancelClose = () => {
      state.taskArr = []
      state.lineData = {}
      state.carrier = {}
      state.carrierId = ''
      state.lineShow = false
    }
    watch(() => props.show, (newVal, oldVal) => {
      state.detailData = props.detail
      state.tasks = props.tasks
      if (props.show === false) cancelClose()
      else getTaskList()
    })
    onMounted(() => {
    })
    const monitorDetail = e => {
      if (e.orderVehicleId) state.taskDetail = e, context.emit('taskDetail', state.taskDetail)
      if (e !== undefined && !e.orderVehicleId) state.monitorDetailShow = e
      else state.monitorDetailShow = true
    }
    // 取消任务
    const confirmCancel = item => {
      if (state.cancelPlantRemark != '') {
        cancelPlant({
          remark: state.cancelPlantRemark,
          taskId: item.taskId
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            context.emit('status', true)
          }
        })
      } else message.info('请填写原因')
    }
    // 生成小程序码
    const handleCope = (item) => {
      state.QRCshow = !state.QRCshow
      state.QRCdata = item
      if (state.QRCshow === true) {
        state.QRCloading = true
        const wXprogramQRcode = { scene: state.detailData.orderVehicleId, type: 1 }
        QRCurl(wXprogramQRcode)
          .then(res => {
            if (res.code === 10000) {
              state.QRCurl = 'data:image/png;base64,' + res.data
              state.QRCloading = false
            }
          }).catch(err => console.log(err))
      }
    }
    const getTaskList = () => {
      // getDispatchList(state.detailData.orderVehicleId).then(res => {
      //   if (res.code === 10000) {
      //     res.data.completeness = state.detailData.completeness
      //     res.data.orderVehicleId = state.detailData.orderVehicleId
      //     // console.log('派单任务列表', state.dispatchDetail.tasks);
      //   }
      // })
      state.loadingShow = true
      batchManualOperate(state.detailData.orderVehicleId).then(res => {
        if (res.code === 10000 && res.data) {
          state.taskList = res.data
          if (state.taskList.length <= 0) {
            message.info('该车辆无未安排任务')
          }
        } else state.taskList = []
      }).finally(() => { state.loadingShow = false })
    }
    // 选择发运任务
    const planChange = (item) => {
      state.taskArr = []
      state.lineData = {}
      state.carrier = {}
      state.carrierId = ''
      state.lineShow = false
      state.planData = item
    }
    // 确认发运任务
    const choosePlant = () => {
      state.taskArr = []
      state.taskIds.split(',').forEach(item => {
        state.taskArr.push({
          amt: 0,
          oilAmt: 0,
          type: { value: Number(item) }
        })
      })
      if (state.taskIds.split(',').includes('2') || state.taskIds.split(',').includes('3')) state.reachChooseShow = true
      setTimeout(() => {
        // state.transitChooseShow = state.taskIds.split(',').includes('2') // 干
        if (state.taskIds.split(',').includes('2') === true) {
          let s = state.taskIds.split(',').includes('3') // 送
          if (s === false) state.transitChooseShow = true
        }
        state.lineChooseShow = state.taskIds.split(',').includes('2')
      }, 100)
      state.lineData.startAddress = state.planData.startAddress
      state.lineData.endAddress = state.planData.endAddress
      // getWhether()
    }
    // 起始地更改---停用
    const setStartAddress = (value, selectedOptions) => {
      state.startAddressData.province = Number(value[0])
      state.startAddressData.city = Number(value[1])
      state.startAddressData.area = Number(value[2])

    }
    // 提车任务修改目的地地址
    const changeEndAddress = e => {
      if (e.addressDetails) {
        state.lineData.endAddress = e.address + e.addressDetails
        state.storeAddress.dockingPerson = e.dockingPerson
        state.storeAddress.dockingMobile = e.dockingMobile
        state.reachChooseShow = false
      } else state.reachChooseShow = false
    }
    // 获取推荐线路---停用
    const getLine = () => {
      state.lineLoading = true
      linePage({ // 获取路线
        current: state.linePage.current,
        size: state.linePage.pageSize,
        startCityId: state.lineData.startCityId,
        endCityId: state.lineData.endCityId
      }).then(res => {
        if (res.code === 10000) {
          state.linePage.total = res.data.total
          state.lineList = res.data.records
          state.lineLoading = false
        }
      })
    }
    // 获取默认路线---停用
    const getWhether = () => {
      if (state.detailData.orderVehicleId) {
        state.lineLoading = true
        lineChoose({
          transportType: state.transportType,
          orderVehicleIds: [state.detailData.orderVehicleId]
        }).then(res => {
          if (res.code === 10000) {
            state.lineData = res.data
            state.lineLoading = false
            state.lineShow = true
          }
        }).catch(err => { console.log(err) })
      }
    }
    // 自定义搜索线路---停用
    const searchLine = () => {
      state.lineShow = true
      state.lineLoading = true
      linePage({ // 获取路线 m
        current: state.linePage.current,
        size: state.linePage.pageSize,
        startProvinceId: state.startAddressData.province,
        startAreaId: state.startAddressData.area,
        startCityId: state.startAddressData.city,
        endProvinceId: state.endAddressData.province,
        endCityId: state.endAddressData.city,
        endAreaId: state.endAddressData.area
      }).then(res => {
        if (res.code === 10000) {
          state.linePage.total = res.data.total
          state.lineList = res.data.records
          state.lineLoading = false
        }
      })
    }
    // 线路更换目的地
    const setEndAddress = (value, selectedOptions) => {
      state.endAddressData.province = Number(value[0])
      state.endAddressData.city = Number(value[1])
      state.endAddressData.area = Number(value[2])
      state.endAddressData.provinceLabel = selectedOptions[0]?.label || ''
      state.endAddressData.cityLabel = selectedOptions[1]?.label || ''
      state.endAddressData.areaLabel = selectedOptions[2]?.label || ''
    }
    // 确认路线
    const confirmLine = item => {
      state.changeLineShow = !state.changeLineShow
      if (state.changeLineShow === false) {
        if (state.endAddressData.city) {
          state.lineData.endAddress = state.endAddressData.provinceLabel + state.endAddressData.cityLabel + state.endAddressData.areaLabel
        } else state.lineData.endAddress = state.recordsList[0]?.endAddress
      }
    }

    // 选择承运商
    const confirmCarrier = e => {
      if (e) {
        state.carrierId = e.id
        state.carrier = e
        state.carrierShow = false
      }
    }

    // 确认派单
    const confirmDispatch = async () => {
      let submit = {
        orderId: state.detailData.orderId,
        orderVehicleId: state.detailData.orderVehicleId,
        carrierId: state.carrierId,
        remark: state.remark,
        carrierName: state.carrier.carrierName,
        taskFees: state.taskArr,
        taskIds: state.taskIds,
        lineStart: state.lineData.startAddress,
        lineEnd: state.lineData.endAddress,
        taskType: state.carrier.isSettled?.value === 1 ? 6 : 1, // 人工为1；外派为6
        remark: state.remark,
        dockingPerson: state.dockingPerson,
        dockingMobile: state.dockingMobile,
        unloadingAddress: state.arriveStore
        // taskIds:''
      }
      await dispatchArrange(submit).then(res => {
        if (res.code === 10000) {
          submit = {}
          state.lineShow = false
          cancelClose()
          getTaskList()
          message.success('派单成功')
          context.emit('status', true)
        }
      })
    }

    // 选择中转地
    const setAddress = (e, v) => {
      state.arriveStore.province = e[0]
      state.arriveStore.city = e[1]
      state.arriveStore.area = e[2]
      switch (e.length) {
        case 1:
          state.transAddress = v[0].label
          break
        case 2:
          state.transAddress = v[0].label + v[1].label
          break
        case 3:
          state.transAddresss = v[0].label + v[1].label + v[2].label
          break
      }
    }
    // 确认---中转地
    const transitConfirm = () => {
      if (state.transAddress != '') {
        state.lineData.endAddress = state.transAddress
        setTimeout(() => {
          state.transAddress = ''
        }, 1000)
      }
      state.transitChooseShow = false
    }
    return {
      ...toRefs(state),
      carrierRef,
      planChange,
      getTaskList,
      choosePlant,
      getLine,
      monitorDetail,
      handleCope,
      confirmCancel,
      changeEndAddress,
      confirmCarrier,
      getWhether,
      confirmLine,
      searchLine,
      cancelClose,
      confirmDispatch,
      setStartAddress,
      setEndAddress,
      transitConfirm,
      setAddress,
    }
  }
}
</script>

<style lang="less" scoped>
a {
  color: #0066CC;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #eee;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(185, 183, 183);
  border-radius: 10px;
}

:deep(.ant-input-number) {
  border: none !important;
}

:deep(.ant-input-group-addon) {
  border: none;
  background-color: #fff;
}

:deep(.ant-input-group-wrapper) {
  .ant-input {
    border: none !important;
  }
}

.ant-input-number:outline {
  border: none !important;
}


:deep(.ant-radio-wrapper:hover .ant-radio-inner, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner) {
  border-color: #344563 !important;
}

:deep(.ant-radio-wrapper-checked) {
  .ant-radio-inner {
    border-color: #344563;
    background-color: #344563;
  }

  .ant-radio-inner::after {
    background-color: #fff !important;
  }
}

:deep(.ant-radio-group) {
  display: flex;
  flex-direction: column !important;
}

:deep(.ant-radio-wrapper) {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  white-space: normal!important;
  margin-left: 0 !important;
}

.loading-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, .8)
}

.left-content {
  width: 100%;
  padding: 0 15px;
  max-height: 90%;
  overflow-y: scroll;

  .content-data {
    padding: 5px;
    padding-bottom: 0;
    font-size: 12px;
    border: 1px solid #eee;
    border-radius: 3px;
    flex-wrap: nowrap;
    background: rgba(242, 242, 242, 0.3);
  }
}

.line-div {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 12px;
  background-color: #fafafb;

  .confirm {
    display: none;
  }
}

.line-div:hover .confirm {
  display: block;
}

.set-place {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .input-div {
    display: flex;
    align-items: center;

    .input-data {
      margin-right: 10px;
      padding-left: 5px;
      display: flex;
      align-items: center;
      border: 1px solid #ccc;

      :deep(.ant-input) {
        border: none !important;
      }
    }
  }
}

.operate-btn {
  margin: 10px;
  display: flex;
  justify-content: flex-end;
}

.ant-btn {
  background-color: #F0F2F5;
}

:deep(.ant-btn-primary) {
  color: #fff;
  border-radius: 0;
  border-color: #344563;
  background-color: #344563;
}

.ant-btn-primary:hover {
  color: #000;
  background-color: #fff;
}

.content-title {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  color: #344563;
  font-weight: 700;
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
}

.transport {
  border: 1px solid #eee;
  padding-top: 10px;

  .transport-choose {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .transport-choose:last-child {
    padding-top: 0 !important;
  }

  .choose-title {
    font-size: 12px;
    color: #344563;
    font-weight: 700;
  }

  .choose-content {
    font-size: 12px;
    color: #6B778C;
  }
}
</style>