<template>
  <div style="position: relative;">
    <div class="data-paginate" v-show="statisticsList.length > 10">
      <LeftCircleFilled style="font-size:20px;color:#B0B8BF;cursor: pointer;" @click="leftMove" />
      <RightCircleFilled style="font-size:20px;color:#B0B8BF;cursor: pointer;margin-left:5px" @click="rightMove" />
    </div>
    <!-- 头部信息统计模块 -->
    <div class="shop-content-put">
      <div class="data-statistics">
        <div v-for="(item, index) in statisticsList" :key="index" class="data-statistics-content">
          <div class="statistics-content" :style="{ flex: index === statisticsList.length - 1 ? '1' : '' }"
            @click="statisticsSearch(item, index)">
            <div class="statistics-title">{{ item.cityName }}</div>
            <div class="data-statistics-data">{{ item.count }}</div>
            <div v-show="item.status === 1" class="data-status">待审核 00</div>
          </div>
          <a-divider type="vertical" style="height: 60px; background-color: #eee" />
        </div>
      </div>
    </div>
    <a-divider style="height: 10px;margin-top:0; background-color: #EEEEEE;border:none" />
    <GlobalTable :loading="loading">
      <template #headerSearch>
        <div style="display:flex;justify-content: space-between;">
          <a-form layout="inline" :model="searchForm">
            <a-form-item label="">
              <a-cascader style="width:200px" :show-search="{ filter }" :options="$store.state.app.PClist"
                v-model:value="searchForm.start" :changeOnSelect="true" @change="setStartAddress" placeholder="起始地" />
            </a-form-item>
            <a-form-item label="">
              <a-cascader style="width:200px" :show-search="{ filter }" :options="$store.state.app.PClist"
                v-model:value="searchForm.end" :changeOnSelect="true" @change="setEndAddress" placeholder="目的地" />
            </a-form-item>
            <a-form-item label="">
              <a-input v-model:value="searchForm.vinNo" style="width:120px" placeholder="车牌号" />
            </a-form-item>
            <a-form-item label="">
              <a-cascader v-model:value="searchForm.brand" placeholder="车辆型号" :options="$store.state.app.carBrand"
                :show-search="{ filter }" />
            </a-form-item>
            <a-form-item label="">
              <a-input v-model:value="searchForm.orderId" style="width:120px" placeholder="订单号" />
            </a-form-item>
            <a-form-item label="">
              <a-select v-model:value="searchForm.stockType" placeholder="库存状态" style="width: 150px">
                <a-select-option :value="0">初始</a-select-option>
                <a-select-option :value="7">在途</a-select-option>
                <a-select-option :value="8">待入库</a-select-option>
                <a-select-option :value="9">拒绝入库</a-select-option>
                <a-select-option :value="2">已出库</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="">
              <div class="operate-btn">
                <a-button class="header-search-btn" style="margin-right:5px" @click="searchData"
                  :loading="loading">搜索</a-button>
                <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </template>
      <div class="table-data">
        <a-button style="margin-bottom:15px" type="primary" @click="openShop">转移到门店</a-button>
        <span style="margin-left:15px">共{{ pagination.total }}条数据</span>
        <a-table size="small" :columns="columns" :row-key="record => record.vinNo" :data-source="putList" bordered
          :pagination="pagination" :loading="loading" @change="pageChange">
          <template #operation="{ record }">
            <div>
              <a style="margin-right:10px" @click="detailClick(record)">{{ record.title }}</a>
              <a-popconfirm title="确认移出?" ok-text="确认" cancel-text="取消" @confirm="transfer(record)">
                <a v-show="record.stockType.value === 8">移出</a>
              </a-popconfirm>
            </div>
          </template>
          <template #vehicle="{ record }">
            <div>
              {{ record.vinNo + '-' + record.brand + record.model }}
            </div>
          </template>
          <template #address="{ record }">
            <div style="text-align:left">
              <div>起：{{ record.startAddress }}</div>
              <div>终：{{ record.endAddress }}</div>
            </div>
          </template>
          <template #detail="{ record }">
            <div>
              <a @click="lookDetail(record)">查看>></a>
            </div>
          </template>
        </a-table>
      </div>
    </GlobalTable>
  </div>
  <!-- 入库 -->
  <a-modal v-model:visible="putShow" :width="1200" footer="" :destroyOnClose="true">
    <template #title>
      <div class="font-color" style="display:flex;justify-content: space-between">
        <div style="display:flex">
          <div class="avater">
            <img class="img" src="@/assets/images/carrier/orderId.png">
          </div>
          <div style="color: #344563">&emsp;{{ element.vinNo }}
            <a-divider type="vertical" />
            {{ element.stockCityName }}办事处
          </div>
        </div>
        <div class="text-center" style="margin-right:5%;font-size:14px">
          <img class="img" src="@/assets/images/carrier/order-people.png">
          <span>&nbsp;{{ element.operation }}</span>&emsp;&emsp;
          <span>操作时间：{{ element.operationTime }}</span>
        </div>
      </div>
    </template>
    <PutDetail :orderId="element.orderId" :orderVehicleId="element.orderVehicleId" :element="element"
      @status="updateData" />
  </a-modal>
  <!-- 入库到门店 -->
  <a-modal v-model:visible="putStoreShow" width="60%" title="入库到门店" footer="">
    <div>
      <a-form layout="inline">
        <a-form-item label="">
          <a-input style="width:120px" v-model:value="noShopSearch.vinNo" placeholder="车牌号" />
        </a-form-item>
        <a-form-item label="">
          <a-input style="width:120px" v-model:value="noShopSearch.unloadingOperator" placeholder="卸车人" />
        </a-form-item>
        <a-form-item label="">
          <a-cascader style="width:150px" v-model:value="startList" :show-search="{ filter }"
            :options="$store.state.app.PClist" :changeOnSelect="true" @change="setnoShopStartAddress" placeholder="起运地" />
        </a-form-item>
        <a-form-item label="">
          <a-cascader style="width:150px" v-model:value="endList" :show-search="{ filter }"
            :options="$store.state.app.PClist" :changeOnSelect="true" @change="setnoShopEndAddress" placeholder="目的地" />
        </a-form-item>
        <a-form-item label="">
          <a-cascader style="width:150px" v-model:value="cityList" :show-search="{ filter }"
            :options="$store.state.app.PClist" :changeOnSelect="true" @change="setCityId" placeholder="所在城市" />
        </a-form-item>
        <a-form-item label="">
          <div class="operate-btn">
            <a-button class="header-search-btn" style="margin-right:5px" @click="searchnoShop"
              :loading="loading">搜索</a-button>
            <a-button class="header-reset-btn" @click="resetnoShop" :loading="loading">重置</a-button>
          </div>
        </a-form-item>
      </a-form>
      <div style="color:#f00;font-size:12px;margin:20px 0">此处均为调度没有选择到达门店的车辆，需手动转移到门店</div>
      <a-table size="small" :columns="noShopColumns" :row-key="record => record.vinNo" :data-source="noShopList" bordered
        :pagination="paginationShop" :loading="noShopLoading" @change="noShopTableChange">
        <template #brand="{ record }">
          <div>
            {{ record.brand + '' + record.model }}
          </div>
        </template>
        <template #address="{ record }">
          <div>
            {{ record.startAddress + '—' + record.endAddress }}
          </div>
        </template>
        <template #operation="{ record }">
          <div>
            <a-popconfirm title="确认转移到门店?" ok-text="确认" cancel-text="取消" @confirm="confirmTransfer(record)">
              <a>转移到门店</a>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons-vue'
import { changeShop } from '@/api/store/checkWarehouse'
import { putWarehousePage, getAddressData, transferVeh, getnoShopPage } from '@/api/store/putWarehouse'
import { message } from 'ant-design-vue'
import GlobalTable from '@/components/GlobalTable'
import PutDetail from '@/views/store/storeUse/detail/putDetail.vue'

export default {
  components: {
    LeftCircleFilled,
    RightCircleFilled,
    GlobalTable,
    PutDetail
  },
  setup (props, context) {
    const imgListRef = ref(null)
    const state = reactive({
      loading: false,
      noShopLoading: false,
      putShow: false,
      index: null,
      putStoreShow: false,
      globalIndex: null,
      searchOrgId: null,
      startList: [],
      endList: [],
      cityList: [],
      searchForm: {
        vinNo: '',
        orderId: '',
        stockType: null,
        startAddress: {},
        endAddress: {}
      },
      mirrorSearchForm: {},
      noShopSearch: {
        vinNo: '',
        unloadingOperator: '',
        orgCityId: '',
        startAddress: {},
        endAddress: {}
      },
      mirrorNoShopSearch: {},
      element: {},
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['5', '10', '15', '20'],
        pageSize: 10
      },
      paginationShop: {
        current: 1,
        total: 0,
        pageSizeOptions: ['5', '10', '15', '20'],
        pageSize: 10
      },
      statisticsList: [],
      noShopList: [],
      putList: [],
      columns: [
        {
          title: '操作',
          align: 'center',
          width: '3%',
          slots: {
            customRender: 'operation'
          }
        },
        {
          title: '订单号',
          dataIndex: 'orderId',
          width: '9.5%',
          align: 'center'
        },
        {
          title: '车牌号/车架-车型',
          width: '9.5%',
          align: 'center',
          slots: {
            customRender: 'vehicle'
          }
        },
        {
          title: '起-终',
          width: '9.5%',
          align: 'center',
          slots: {
            customRender: 'address'
          }
        },
        {
          title: '卸车人',
          dataIndex: 'unloadingOperator',
          width: '5%',
          align: 'center'
        },
        {
          title: '卸车时间',
          dataIndex: 'unloadingTime',
          width: '5%',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'stockType.label',
          width: '5%',
          align: 'center'
        },
        {
          title: '车辆详情',
          dataIndex: 'detail',
          width: '5%',
          align: 'center',
          slots: {
            customRender: 'detail'
          }
        },
      ],
      noShopColumns: [
        {
          title: '车牌号',
          dataIndex: 'vinNo',
          align: 'center'
        },
        {
          title: '车型',
          dataIndex: 'brand',
          align: 'center',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '卸车人',
          dataIndex: 'unloadingOperator',
          align: 'center'
        },
        {
          title: '卸车时间',
          dataIndex: 'unloadingTime',
          align: 'center'
        },
        {
          title: '运送线路',
          dataIndex: 'address',
          align: 'center',
          slots: {
            customRender: 'address'
          }
        },
        {
          title: '当前城市',
          dataIndex: 'stockCityName',
          align: 'center'
        },
        {
          title: '操作',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        },
      ]
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      state.mirrorNoShopSearch = JSON.stringify(state.noShopSearch)
    })
    const openShop = () => {
      state.noShopLoading = true
      state.putStoreShow = true
      state.loading = true
      getnoShopPage({
        ...state.noShopSearch,
        current: state.paginationShop.current,
        size: state.paginationShop.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.noShopList = res.data.records
          state.paginationShop.total = res.data.total
        }
      }).finally(() => { state.noShopLoading = false,state.loading = false })
    }
    const searchnoShop = () => {
      state.paginationShop.current = 1
      openShop()
    }
    const resetnoShop = () => {
      state.noShopSearch = JSON.parse(state.mirrorNoShopSearch)
      state.startList = []
      state.endList = []
      state.cityList = []
      openShop()
    }
    const noShopTableChange = (page) =>{
      state.paginationShop = page
      openShop()
    }
    const confirmTransfer = record => {
      changeShop({
        isShop: 1,
        orderVehicleId: record.orderVehicleId,
        transOrderTruckVehicleId: record.totvId
      }).then(res => {
        if (res.code === 10000) {
          message.success('转移成功')
          openShop()
        }
      })
    }
    const loadData = (type) => {
      state.loading = true
      putWarehousePage({
        ...state.searchForm,
        isShop: 1,
        orgId: state.searchOrgId,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          if (res.data) {
            state.pagination.total = res.data.total
            state.putList = res.data.records
            state.putList.forEach(item => {
              item.title = '入库'
            })
          } else message.error('暂无数据')
        }
      }).finally(() => { state.loading = false })
      if (!type) getStatistics()
    }
    const getStatistics = () => {
      getAddressData().then(res => {
        if (res.code === 10000) {
          state.statisticsList = res.data
        }
      })
    }
    // 移交
    const transfer = element => {
      transferVeh({
        orderVehicleIds: [element.orderVehicleId]
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
        }
      })
    }
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startAddress = {}
      state.searchForm.startAddress.province = value[0]
      state.searchForm.startAddress.city = value[1]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endAddress = {}
      state.searchForm.endAddress.province = value[0]
      state.searchForm.endAddress.city = value[1]
    }
    const setnoShopStartAddress = (value, selectedOptions) => {
      state.noShopSearch.StartAddress = {}
      state.noShopSearch.StartAddress.province = value[0]
      state.noShopSearch.StartAddress.city = value[1]
      state.noShopSearch.StartAddress.area = value[2]
    }
    const setnoShopEndAddress = (value, selectedOptions) => {
      state.noShopSearch.endAddress = {}
      state.noShopSearch.endAddress.province = value[0]
      state.noShopSearch.StartAddress.city = value[1]
      state.noShopSearch.endAddress.area = value[2]
    }
    const setCityId = (value, selectedOptions) => {
      state.noShopSearch.orgCityId = value[1]
    }
    const statisticsSearch = (item, index) => {
      let DOMS = document.querySelectorAll('.shop-content-put .statistics-content')
      if (state.globalIndex !== index) {
        state.globalIndex = index
        for (let i = 0; i < DOMS.length; i++) {
          DOMS[i].style.color = '#344563'
          DOMS[i].style.background = ''
          if (i === index) {
            DOMS[index].style.color = '#fff'
            DOMS[index].style.background = 'rgba(0,102,204,.8)'
            DOMS[index].style.borderRadius = '8px'
          }
        }
        state.searchOrgId = item.cityId
        state.pagination.current = 1
        loadData(1)
      } else {
        DOMS[state.globalIndex].style.color = '#344563'
        DOMS[state.globalIndex].style.background = ''
        state.searchOrgId = null
        state.globalIndex = null
        state.pagination.current = 1
        loadData(1)
      }
    }
    const pageChange = page => {
      state.pagination = page
      loadData()
    }
    const resetData = () => {
      state.loading = true
      state.putList = []
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.pagination.current = 1
      loadData()
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const detailClick = record => {
      state.element = record
      state.putShow = true
      state.index = state.putList.findIndex(item => item.orderId === state.element.orderId)
    }
    const lookDetail = (record) => {
      state.element = record
      context.emit('detail', record)
    }
    const leftMove = () => {
      let DOM = document.querySelector('.shop-content-put .data-statistics')
      let left = Number(DOM.style.left.replace('vw', ''))
      if (left < 0) {
        DOM.style.left = left + 8 + 'vw'
      }
    }
    const rightMove = () => {
      let DOM = document.querySelector('.shop-content-put .data-statistics')
      let left = Number(DOM.style.left.replace('vw', ''))
      DOM.style.left = left - 8 + 'vw'
    }
    const updateData = e => {
      if (e === true) {
        loadData()
        state.putShow = false
      }
    }
    return {
      ...toRefs(state),
      imgListRef,
      leftMove,
      rightMove,
      loadData,
      openShop,
      setCityId,
      updateData,
      searchData,
      resetData,
      transfer,
      resetnoShop,
      searchnoShop,
      setnoShopStartAddress,
      setnoShopEndAddress,
      pageChange,
      noShopTableChange,
      detailClick,
      lookDetail,
      confirmTransfer,
      setStartAddress,
      setEndAddress,
      getStatistics,
      statisticsSearch
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';

.data-paginate {
  position: absolute;
  right: 10px;
  top: -10px;
  z-index: 99;
}
</style>