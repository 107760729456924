
<template>
  <a-modal :visible="windowShow" title="异常上报" @ok="handleOk" width="35%" @cancel="cancelClose"
    :bodyStyle="{ padding: '15px', paddingTop: 0 }" :confirm-loading="confirmLoading">
    <div>
      <!-- 上报车辆 -->
      <div class="report-vehicle" v-show="vehicleArr?.length > 0">
        上报车辆
        <div class="vehicle-array" v-for="item in vehicleArr" :key="item.vinNo">
          <span style="margin-right:15px">{{ item.vinNo }}</span>
          <span>{{ item.startAddress }}</span>
          <span>————</span>
          <span>{{ item.endAddress }}</span>
        </div>
      </div>
      <!-- 选择类型 -->
      <div style="margin: 10px 0">
        <a-form layout="vertical">
          <a-form-item label="上报类型">
            <a-select :disabled="!vehicleDetail" v-model:value="reportType" placeholder="选择" @change="reportChange">
              <a-select-option :value="2">异常上报</a-select-option>
              <a-select-option :value="1">费用上报</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="承运商" v-show="reportType">
            <div style="display:flex;justify-content: space-between;align-items: center;">
              <a-select v-model:value="carrierData.carrierName" style="flex:1" placeholder="搜索并选择承运商"
                @search="fetchCarrier" :filterOption="false" show-search>
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <!-- <a-select-option v-if="!carriers.length && expenseForm.carrierId" :value="expenseForm.carrierId">{{
                  expenseForm.carrierName
                }}</a-select-option> -->
                <a-select-option v-for="item in carriers" :key="item.id" @click="chooseCarrier(item)">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a-button @click="clearChoose">清除选择</a-button>
              <!-- <div style="width:45%" v-show="expenseForm?.carrierName">({{ expenseForm?.carrierName }})</div> -->
            </div>
          </a-form-item>
        </a-form>
      </div>

      <!-- 异常上报 -->
      <div v-show="reportType === 2">
        <a-form layout="vertical" ref="abnormalFormRef" :model="abnormalForm" :rules="abnormaRules">
          <a-form-item label="异常类型" name="exceptionType">
            <a-select v-model:value="abnormalForm.exceptionType" class="ransport_search_input" allowClear>
              <a-select-option v-for="(type, index) in exceptionTypes" :key="index"> {{ type[0] }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="异常原因" name="exceptionSubType">
            <a-select v-model:value="abnormalForm.exceptionSubType" class="ransport_search_input" allowClear>
              <a-select-option v-for="(type, index)  in exceptionExplains" :key="type[1]"> {{ type[0] }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="备注">
            <a-textarea v-model:value="abnormalForm.exceptionExplain" placeholder="备注信息" :rows="4" />
          </a-form-item>
          <div style="margin-bottom:15px">
            <div>异常照片</div>
            <div style="display: flex;align-items:flex-end">
              <!-- 单张上传 -->
              <a-upload v-model:file-list="abnormalProofList" list-type="picture-card" :show-upload-list="false"
                :before-upload="beforeUpload" @change="handleChange">
                <img v-if="abnormalUrl !== ''" style="width:102px;height:102px" :src="abnormalUrl" />
                <div v-else>
                  <loading-outlined v-if="abnormalLoading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div>上传</div>
                </div>
              </a-upload>
              <a-button @click="resetFile(2)">重置照片</a-button>
            </div>
          </div>
        </a-form>
      </div>
      <!-- 费用上报 -->
      <div v-show="reportType === 1">
        <a-form layout="vertical" ref="expenseFormRef" :model="expenseForm" :rules="expenseRules">
          <a-form-item label="费用类型" name="exceptionType">
            <a-select v-model:value="expenseForm.exceptionType" :options="expenseOptions" placeholder="请选择费用类型">
            </a-select>
          </a-form-item>
          <a-form-item label="费用" name="amount">
            <a-input-number v-model:value="expenseForm.amount" :min="0" type="number" />
          </a-form-item>
          <a-form-item label="备注" name="exceptionExplain">
            <a-textarea v-model:value="expenseForm.exceptionExplain" placeholder="备注信息" :rows="4" />
          </a-form-item>
        </a-form>
        <div style="margin-bottom:15px">
          <div>费用凭证</div>
          <div style="display: flex;align-items:flex-end">
            <a-upload v-model:file-list="expenseProofList" list-type="picture-card" :show-upload-list="false"
              :before-upload="beforeUpload" @change="handleChange">
              <img v-if="expenseUrl !== ''" style="width:102px;height:102px" :src="expenseUrl" />
              <div v-else>
                <loading-outlined v-if="expenseLoading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div>上传</div>
              </div>
            </a-upload>
            <a-button @click="resetFile(1)">重置照片</a-button>
            <!-- <a-button class="ant-upload-text" :disabled="expenseUrl == ''" @click="uploadImg">上传照片</a-button> -->
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, watch, onMounted } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { fileUpload as upload } from '@/api/marketing/modList'


import { fuzzy } from '@/api/crm/carrer/carrier'
import transportType from '@/config/TransportType.json'
import { message } from 'ant-design-vue'

export default {
  props: {
    windowShow: Boolean,
    // vehicleArr: Array,
    vehicleDetail: Object
  },
  data () {
    return {
      exceptionExplains: transportType.exceptionExplain,
      exceptionTypes: transportType.exceptionType
    }
  },
  setup (props, context) {
    const abnormalFormRef = ref(null)
    const expenseFormRef = ref(null)
    const state = reactive({
      reportType: null, // 1为费用 2为异常
      timeOutId: null,
      windowShow: false,
      expenseLoading: false,
      confirmLoading: false,
      fetching: false,
      expenseUrl: '',
      abnormalUrl: '',
      abnormalLoading: false,
      carriers: [],
      vehicleArr: [],
      abnormalProofList: [],
      expenseProofList: [],
      expenseForm: {
        belong: [],
        amount: 0,
        carrierName: null,
        carrierId: null,
        exceptionType: null,
        exceptionExplain: '',
        fileId: '',
      },
      carrierData: {
        carrierId: null,
        carrierName: null
      },
      vehicleDetail: {},
      mirrorExpense: {},
      mirrorAbnormal: {},
      mirrorexpenseForm: {},
      abnormalForm: {
        exceptionType: null,
        belong: [],
        carrierName: null,
        carrierId: null,
        exceptionSubType: null,
        exceptionExplain: '',
        fileId: '',
      },
      expenseOptions: [
        {
          label: '放空费',
          value: 8
        },
        {
          label: '油费',
          value: 9
        },
        {
          label: '等时费',
          value: 10
        },
        {
          label: '维修费',
          value: 11
        },
        {
          label: '叉车费',
          value: 12
        },
        {
          label: '停车费',
          value: 13
        },
        {
          label: '保管费',
          value: 14
        },
        {
          label: '异常处理费',
          value: 15
        },
        {
          label: '报关费',
          value: 16
        },
        {
          label: '转关费',
          value: 17
        },
      ],
      abnormaRules: {
        belong: { required: true, message: '请选择所属任务段', trigger: 'blur', type: 'number' },
        exceptionType: [{ required: true, message: '请选择异常类型', trigger: 'blur', type: 'number' }],
        exceptionSubType: [{ required: true, message: '请选择异常原因', trigger: 'blur', type: 'number' }],
      },
      expenseRules: {
        exceptionType: { required: true, message: '请选择费用类型', trigger: 'blur', type: 'number' },
        amount: { required: true, message: '请输入费用', trigger: 'blur', type: 'number' },
        exceptionExplain: { required: true, message: '请填写原因', trigger: 'blur' },
      },
      // ---------------------------------

      abnormalOptions: [
        {
          label: '取车异常',
          value: 1
        },
        {
          label: '配载异常',
          value: 2
        },
        {
          label: '中转异常',
          value: 3
        },
        {
          label: '在途异常',
          value: 4
        },
        {
          label: '到达异常',
          value: 5
        },
        {
          label: '送车异常',
          value: 6
        },
        {
          label: '交车异常',
          value: 7
        },

      ],
    })
    onMounted(() => {
      state.mirrorAbnormal = JSON.stringify(state.abnormalForm)
      state.mirrorExpense = JSON.stringify(state.mirrorExpense)
      state.mirrorexpenseForm = JSON.stringify(state.expenseForm)
    })
    watch(() => props.windowShow, (newVal, oldVal) => {
      state.windowShow = newVal
      if (newVal === true) {
      } else state.confirmLoading = false
      // if (!props.vehicleDetail.truckStatus) {
      //   if (props.vehicleDetail.sysRecommend) {
      //     state.expenseForm.carrierId = props.vehicleDetail.sysRecommend.carrierId
      //     state.expenseForm.carrierName = props.vehicleDetail.sysRecommend.carrierName
      //   }
      // } else {
      //   state.expenseForm.carrierId = props.vehicleDetail.carrierId
      //   state.expenseForm.carrierName = props.vehicleDetail.carrierName
      // }

      // if (state.vehicleDetail.sysRecommend) {
      //   state.expenseForm.carrierId = state.vehicleDetail.sysRecommend.carrierId
      //   state.expenseForm.carrierName = state.vehicleDetail.sysRecommend.carrierName
      // }
    })
    const getData = () => {
      if (props.vehicleDetail) {
        state.vehicleArr = props.vehicleArr
        state.vehicleDetail = props.vehicleDetail
        state.carrierData.carrierId = props.vehicleDetail.carrierId
        state.carrierData.carrierName = props.vehicleDetail.carrierName
        state.expenseForm.carrierId = props.vehicleDetail.carrierId
        state.expenseForm.carrierName = props.vehicleDetail.carrierName
      } else {
        state.reportType = 2
      }
    }
    const fetchCarrier = value => {
      if (value.length > 1) {
        state.carriers = []
        state.fetching = true
        if (state.timeOutId !== null) {
          clearTimeout(state.timeOutId)
        }
        state.timeOutId = setTimeout(() => {
          fuzzy({ name: value }).then(res => {
            if (res.code === 10000) {
              state.carriers = res.data
            } else state.carriers = []
            state.fetching = false
          })
        }, 1000)
      } else {
        state.carriers = []
      }
    }
    const chooseCarrier = item => {
      state.carrierData.carrierId = item.id
      state.carrierData.carrierName = item.name
      if (state.reportType === 2) {
        state.abnormalForm.carrierName = item.name
        state.abnormalForm.carrierId = item.id
      } else {
        state.expenseForm.carrierName = item.name
        state.expenseForm.carrierId = item.id
      }
    }
    const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }
    const abnormalSubmit = () => {
      state.abnormalReportShow = false
    }
    const abnormalChange = (e, v) => {
      console.log(e, v);
    }

    const cancel = () => {
      state.expenseForm = JSON.parse(state.mirrorExpense)
      state.expenseReportShow = false
      state.expenseUrl = ''
      state.abnormalUrl = ''
      context.emit('statusShow', state.expenseReportShow)
    }
    const expenseChange = (e, v) => {
      console.log(e, v);
    }
    const reportChange = (e) => {
      getData()
    }
    // 上传数组赋值
    const beforeUpload = file => {
      if (state.reportType === 2) {
        state.abnormalProofList.push(file)
        return false // 取消ant组件上传自动调用本地API(无效且不存在的) ---必加
      } else {
        state.expenseProofList.push(file)
        return false
      }
    }
    // 静态图片赋值
    const handleChange = info => {
      // Get this url from response in real world.
      getBase64(info.file, base64Url => {
        state.reportType === 2 ? state.abnormalUrl = base64Url : state.expenseUrl = base64Url
        // uploadImg()
      })
    }
    // 接口上传
    const confirmUploadImg = (status) => {
      switch (state.reportType) {
        case 1: // 费用上报
          state.expenseLoading = true
          const fileData = new FormData()
          fileData.append('file', state.expenseProofList[0])
          upload(fileData).then(res => {
            if (res.code === 10000) {
              state.expenseForm.fileId = res.data.id
              requestApi()
              state.expenseLoading = false
            }
          })
          break
        case 2: // 异常上报
          state.abnormalLoading = true
          const fileImg = new FormData()
          fileImg.append('file', state.abnormalProofList[0])
          upload(fileImg).then(res => {
            if (res.code === 10000) {
              state.abnormalForm.fileId = res.data.id
              requestApi()
              state.abnormalLoading = false
            }
          })
          break
      }
    }
    const handleOk = async () => {
      if (state.expenseUrl != '' || state.abnormalUrl != '') confirmUploadImg()
      else requestApi()
    }
    const requestApi = () => {
      switch (state.reportType) {
        case 1: // 费用
          expenseFormRef.value.validate().then(() => {
            state.confirmLoading = true
            context.emit('reportData', { ...state.expenseForm, reportType: state.reportType })
            setTimeout(() => {
              state.expenseForm = JSON.parse(state.mirrorExpense)
              state.expenseProofList = []
              state.reportType = null
              state.expenseUrl = ''
            }, 500)
          }).catch(err => { console.log(err) })
          break
        case 2: // 异常
          abnormalFormRef.value.validate().then(() => {
            state.confirmLoading = true
            context.emit('reportData', { ...state.abnormalForm, reportType: state.reportType })
            setTimeout(() => {
              state.abnormalForm = JSON.parse(state.mirrorAbnormal)
              state.abnormalProofList = []
              state.reportType = null
              state.abnormalUrl = ''
            }, 500)
          }).catch(err => { console.log(err) })
          break
      }
    }
    // 重置图片
    const resetFile = (num) => {
      // num 1为费用  2为异常
      if (num === 1) {
        state.expenseUrl = ''
        state.expenseProofList = []
        state.expenseForm.fileId = ''
      } else {
        state.abnormalUrl = ''
        state.abnormalProofList = []
        state.abnormalForm.fileId = ''
      }
    }
    const cancelClose = () => {
      state.expenseForm = JSON.parse(state.mirrorexpenseForm)
      state.abnormalForm = JSON.parse(state.mirrorAbnormal)
      state.expenseReportShow = false
      state.reportType = null
      state.expenseUrl = ''
      state.abnormalUrl = ''
      state.carriers = []
      context.emit('reportData', false)
    }
    const clearChoose = () => {
      state.carrierData.carrierId = null
      state.carrierData.carrierName = null
      if (state.reportType === 2) {
        state.abnormalForm.carrierName = null
        state.abnormalForm.carrierId = null
      } else {
        state.expenseForm.carrierName = null
        state.expenseForm.carrierId = null
      }
    }

    return {
      ...toRefs(state),
      abnormalFormRef,
      expenseFormRef,
      handleOk,
      getData,
      reportChange,
      cancelClose,
      abnormalSubmit,
      abnormalChange,
      resetFile,
      cancel,
      clearChoose,
      chooseCarrier,
      fetchCarrier,
      expenseChange,
      beforeUpload,
      handleChange,
      confirmUploadImg
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

:deep(.ant-upload-picture-card-wrapper) {
  width: auto !important;
}

:deep(.ant-upload-select) {
  margin-bottom: 0 !important;
}

:deep(.ant-input-number) {
  width: 100%;
}

:deep(.ant-upload-list-item-error) {
  border-color: #ccc !important;
}

.report-vehicle {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;

  .vehicle-array {
    margin-bottom: 5px;
    padding: 5px 5px;
    color: #6B778C;
    font-size: 12px;
    border: 1px solid #ccc;
    background-color: #F0F0F0;
  }
}
</style>
