<template>
  <div>
    <div class="flex al-center ju-between itemBox">
      <div style="width: 90%">
        <div class="flex al-center">
          {{ item.title }}
          <a-tooltip class="m-l1" :title="item.define">
            <img class="tipsIcon" src="@/assets/images/newTransport/u60.png" alt="">
          </a-tooltip>
        </div>
        <div class="tips">
          <span> {{ item.subTitle }}</span>
        </div>
        <div style="color: #344563;font-size: 13px;">
          <span v-for="(opt,index) in item.subItemList" class="m-r1" :key="opt.id">
            {{ opt.title }}:&nbsp;
            <span>
              <span :style="{color:colorType[opt.colorType]}"> {{ opt.num }}</span>
            </span>
            {{ opt.unit }}
          </span>
        </div>
      </div>
      <div  >
        <span class="cur-p" @click="open">
          <CountTo color="#0066CC" fontSize="18px" :endVal="item.num" />
        </span>
      </div>
      <div v-if="visible">
        <component :is="componentType" v-model:visible="visible"></component>
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref } from 'vue'
import OnTheway from './components/OnTheway.vue';
import CheckCarPhoto from './components/CheckCarPhoto.vue';
import DepartureCalculation from './components/DepartureCalculation.vue';
import DeliveryNote from './components/DeliveryNote.vue';
import CountTo from '@/components/countTo'
import { colorType } from '@/utils/enum.js'
const props = defineProps({
  item: {
    type: Object,
    default: {}
  }
})
const componentType = ref(null)
const visible = ref(false)
const open = () => {
  switch (props.item.pageType) {
    case 1:
      componentType.value = OnTheway
      break;
    case 2:
      componentType.value = CheckCarPhoto
      break;
    case 3:
      componentType.value = DepartureCalculation
      break;
    case 4:
      componentType.value = DeliveryNote
      break;
  }
  visible.value = true
}
const color1 = ['#DC363E', '#E9941B']
const color2 = ['#0066CC', '#D20509']
</script>

<style lang="less" scoped>
.itemBox {
  margin-top: 10px;
  padding: 10px;
  background: #edf0f9;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  .tips {
    font-size: 12px;
    color: #999;
  }
}

.tipsIcon {
  width: 14px;
  height: 14px;
}
</style>