<template>
  <div style="position: relative;">
    <div class="data-paginate" v-show="statisticsList.length > 10">
      <LeftCircleFilled style="font-size:20px;color:#B0B8BF;cursor: pointer;" @click="leftMove" />
      <RightCircleFilled style="font-size:20px;color:#B0B8BF;cursor: pointer;margin-left:5px" @click="rightMove" />
    </div>
    <!-- 头部信息统计模块 -->
    <div class="shop-content-check">
      <div class="data-statistics">
        <div v-for="(item, index) in statisticsList" :key="index" class="data-statistics-content">
          <div class="statistics-content" :style="{ flex: index === statisticsList.length - 1 ? '1' : '' }"
            @click="statisticsSearch(item, index)">
            <div class="statistics-title">{{ item.cityName }}</div>
            <div class="data-statistics-data">{{ item.count }}</div>
            <div class="data-status">
<!--              <span style="margin-right:10px;color:#0C8226">已盘{{ item.count }}</span>-->
              <span>未盘{{ item.notInventoryCount }}</span>
            </div>
          </div>
          <a-divider type="vertical" style="height: 60px; background-color: #eee" />
        </div>
      </div>
    </div>
    <a-divider style="height: 10px;margin-top:0; background-color: #EEEEEE;border:none" />
    <GlobalTable :loading="loading">
      <template #headerSearch>
        <div style="display:flex;justify-content: space-between;">
          <a-form layout="inline" :model="searchForm">
            <a-form-item label="">
              <a-cascader style="width:200px" :show-search="{ filter }" :options="$store.state.app.PClist"
                v-model:value="searchForm.start" :changeOnSelect="true" @change="setStartAddress" placeholder="起始地" />
            </a-form-item>
            <a-form-item label="">
              <a-cascader style="width:200px" :show-search="{ filter }" :options="$store.state.app.PClist"
                v-model:value="searchForm.end" :changeOnSelect="true" @change="setEndAddress" placeholder="目的地" />
            </a-form-item>
            <a-form-item label="">
              <a-input v-model:value="searchForm.vinNo" style="width:120px" placeholder="车牌号" />
            </a-form-item>
            <a-form-item label="">
              <a-cascader v-model:value="searchForm.brand" placeholder="车辆型号" :options="$store.state.app.carBrand"
                :show-search="{ filter }" />
            </a-form-item>
            <a-form-item label="">
              <a-input v-model:value="searchForm.orderId" style="width:120px" placeholder="订单号" />
            </a-form-item>
            <a-form-item label="">
              <a-select v-model:value="searchForm.warehouseType" placeholder="库存状态" style="width: 150px">
                <a-select-option :value="-1">新入库</a-select-option>
                <a-select-option :value="0">未盘库</a-select-option>
                <a-select-option :value="1">正常盘库</a-select-option>
                <a-select-option :value="2">异常盘库</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="">
              <div class="operate-btn">
                <a-button class="header-search-btn" style="margin-right:5px" @click="searchData" :loading="loading">搜索</a-button>
                <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
      </template>
      <div class="table-data">
        <span>共{{ pagination.total }}条数据</span>
        <a-table size="small" :columns="columns" :row-key="record => record.vinNo" :data-source="checkList" bordered
          :pagination="pagination" :loading="loading" @change="pageChange">
          <template #operation="{ record }">
            <div>
              <a @click="detailClick(record)" v-show="record.inventoryStatus?.value === 0">{{ record.title }}</a>
              <a style="color:#6B778C" @click="detailClick(record)" v-show="record.inventoryStatus?.value !== 0">查看</a>
            </div>
          </template>
          <template #vehicle="{ record }">
            <div>
              {{ record.vinNo + '-' + record.brand + record.model }}
            </div>
          </template>
          <template #address="{ record }">
            <div style="text-align:left">
              <div style="maring-right:10px">起：{{ record.startAddress }}</div>
              <div>终：{{ record.endAddress }}</div>
            </div>
          </template>
          <template #checkImg="{record}">
            <div>
              <a-image v-if="record.fileRealUrl" :src="record.fileRealUrl" style="width:25px;height:25px" />
              <span v-show="!record.fileRealUrl">暂无盘库照片</span>
            </div>
          </template>
          <template #detail="{ record }">
            <div>
              <a @click="lookDetail(record)">查看>></a>
            </div>
          </template>
        </a-table>
      </div>
    </GlobalTable>
  </div>
  <!-- 盘库 -->
  <a-modal v-model:visible="checkShow" :width="1200" footer="" :destroyOnClose="true">
    <template #title>
      <div class="font-color" style="display:flex;justify-content: space-between">
        <div style="display:flex">
          <div class="avater">
            <img class="img" src="@/assets/images/carrier/orderId.png">
          </div>
          <div style="color: #344563">&emsp;{{ element.vinNo }}</div>
        </div>
        <div class="text-center" style="margin-right:5%;font-size:14px">
          <img class="img" src="@/assets/images/carrier/order-people.png">
          <span>&nbsp;{{ element.operation }}</span>&emsp;&emsp;
          <span>操作时间：{{ element.operationTime }}</span>
        </div>
      </div>
    </template>
    <CheckDetail :orderVehicleId="element.orderVehicleId" :orderVehicleNo="element.vinNo" @status="updateData" />
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons-vue'
import { newCheckWarehousePage, getAddressData } from '@/api/store/checkWarehouse'
import { message } from 'ant-design-vue'
import GlobalTable from '@/components/GlobalTable'
import CheckDetail from '@/views/store/storeUse/detail/checkDetail.vue'

export default {
  components: {
    LeftCircleFilled,
    RightCircleFilled,
    GlobalTable,
    CheckDetail
  },
  setup (props, context) {
    const state = reactive({
      loading: false,
      checkShow: false,
      index: null,
      globalIndex: null,
      searchOrgId: null,
      searchForm: {
        vinNo: '',
        orderId: '',
        stockType: null,
        startAddress: {},
        endAddress: {}
      },
      mirrorSearchForm: {},
      element: {},
      pagination: {
        current: 1,
        total: 0,
        pageSizeOptions: ['5', '10', '15', '20'],
        pageSize: 10
      },
      statisticsList: [],
      checkList: [],
      columns: [
        {
          title: '操作',
          align: 'center',
          width: '3%',
          slots: {
            customRender: 'operation'
          }
        },
        {
          title: '订单号',
          dataIndex: 'orderId',
          width: '9.5%',
          align: 'center'
        },
        {
          title: '车牌号/车架-车型',
          width: '9.5%',
          align: 'center',
          slots: {
            customRender: 'vehicle'
          }
        },
        {
          title: '起-终',
          width: '9.5%',
          align: 'center',
          slots: {
            customRender: 'address'
          }
        },
        {
          title: '盘库状态',
          dataIndex: 'inventoryStatus.label',
          width: '9.5%',
          align: 'center'
        },
        {
          title: '盘库照片',
          dataIndex: 'checkImg',
          width: '9.5%',
          align: 'center',
          slots: {
            customRender: 'checkImg'
          }
        },
        {
          title: '车辆详情',
          dataIndex: 'detail',
          width: '9.5%',
          align: 'center',
          slots: {
            customRender: 'detail'
          }
        }
      ]
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const loadData = (type) => {
      state.loading = true
      newCheckWarehousePage({
        ...state.searchForm,
        isShop: 1,
        orgCityId: state.searchOrgId,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          if (res.data) {
            state.pagination.total = res.data.length
            state.checkList = res.data
            state.checkList.forEach(item => {
              item.title = '盘库'
            })
          } else message.error('暂无数据')
        }
      }).finally(() => { state.loading = false })
      if(!type) getStatistics()
    }
    const setStartAddress = (value, selectedOptions) => {
      state.searchForm.startAddress = {}
      state.searchForm.startAddress.province = value[0]
      state.searchForm.startAddress.city = value[1]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.endAddress = {}
      state.searchForm.endAddress.province = value[0]
      state.searchForm.endAddress.city = value[1]
    }
    const getStatistics = () => {
      getAddressData().then(res => {
        if (res.code === 10000) {
          state.statisticsList = res.data
        }
      })
    }
    const statisticsSearch = (item, index) => {
      let DOMS = document.querySelectorAll('.shop-content-check .statistics-content')
      if (state.globalIndex !== index) {
        state.globalIndex = index
        for (let i = 0; i < DOMS.length; i++) {
          DOMS[i].style.color = '#344563'
          DOMS[i].style.background = ''
          if (i === index) {
            DOMS[index].style.color = '#fff'
            DOMS[index].style.background = 'rgba(0,102,204,.8)'
            DOMS[index].style.borderRadius = '8px'
          }
        }
        state.searchOrgId = item.cityId
        state.pagination.current = 1
        loadData(1)
      } else {
        DOMS[state.globalIndex].style.color = '#344563'
        DOMS[state.globalIndex].style.background = ''
        state.searchOrgId = null
        state.globalIndex = null
        state.pagination.current = 1
        loadData(1)
      }
    }
    const pageChange = page => {
      state.pagination = page
      // loadData()
    }
    const resetData = () => {
      state.loading = true
      state.checkList = []
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.pagination.current = 1
      loadData()
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const detailClick = record => {
      state.element = record
      state.checkShow = true
      state.index = state.checkList.findIndex(item => item.orderId === state.element.orderId)
    }
    const lookDetail = (record) => {
      state.element = record
      context.emit('detail', record)
    }
    const leftMove = () => {
      let DOM = document.querySelector('.shop-content-check .data-statistics')
      let left = Number(DOM.style.left.replace('vw', ''))
      if (left < 0) {
        DOM.style.left = left + 8 + 'vw'
      }
    }
    const rightMove = () => {
      let DOM = document.querySelector('.shop-content-check .data-statistics')
      let left = Number(DOM.style.left.replace('vw', ''))
      DOM.style.left = left - 8 + 'vw'
    }
    const updateData = e => {
      if (e === true) {
        loadData()
        state.checkList[state.index].title = '已盘库'
      }
    }
    return {
      ...toRefs(state),
      leftMove,
      rightMove,
      loadData,
      searchData,
      resetData,
      lookDetail,
      pageChange,
      updateData,
      detailClick,
      getStatistics,
      setStartAddress,
      setEndAddress,
      statisticsSearch
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';

.data-paginate {
  position: absolute;
  right: 10px;
  top: -10px;
  z-index: 99;
}
</style>
