<template>
  <a-modal :visible="windowShow" :title="detailData?.vinNo === '' ? '暂无车牌' : `任务详情——${detailData?.vinNo}`"
    footer="" width="70%" @cancel="cancelClose"
    :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">
    <div style="display: flex;">
      <div class="left-content">
        <!-- 基本信息 -->
        <div>
          <div class="content-title" style="position:relative">
            <div style="width:100%;display: flex;justify-content: space-between;">
              <div>基本信息</div>
              <a @click="priceDataShow = !priceDataShow">查看报价</a>
            </div>
            <div v-show="priceDataShow" class="offer-data">
              <div style="margin-bottom:20px;padding:20px;background:#F3F8FE">
                <div class="altogether">
                  <div>总成本价:<span style="color:#F68557">￥{{ orderInspectionCustomer.offer?.totalCost }}</span>
                  </div>
                  <div>总里程:{{ orderInspectionCustomer.offer?.totalMileage }}公里</div>
                  <div>总时效:{{ orderInspectionCustomer.offer?.totalTime }}天</div>
                  <div>建议报价:￥{{ orderInspectionCustomer.offer?.totalPrice }}</div>
                </div>
                <div style="display: flex;justify-content: space-between;color:#B4B7BC">
                  <div>车辆运费：￥{{ orderInspectionCustomer.offer?.carFreight }}</div>
                  <div>
                    接车费用：￥{{ orderInspectionCustomer.offer?.carCome }}</div>
                  <div>
                    送车费用：￥{{ orderInspectionCustomer.offer?.carGo }}</div>
                  <div>
                    保险费用：￥{{ orderInspectionCustomer.offer?.totalInsurance }}</div>
                </div>
              </div>
              <a-timeline style="padding:10px 20px">
                <a-timeline-item v-for="(item, index) in orderInspectionCustomer?.offer?.pdetaileds" :key="index">
                  <template #dot>
                    <div class="title-num">{{ index + 1 }}</div>
                  </template>
                  <div>{{ item.startAddress }} ——> {{ item.endAddress }}<span v-if="item.remark !== ''">
                      ({{ item.remark }})</span></div>
                  <div class="reference">
                    <div>成本价：{{ item.cost }}元</div>
                    <div>参考公里：{{ item.mileage }}公里</div>
                    <div>参考时效：{{ item.time }}天</div>
                    <div>参考报价：{{ item.offer }}元</div>
                  </div>
                </a-timeline-item>
              </a-timeline>
            </div>
          </div>
          <div class="content-data">
            <a-row :gutter="[24, 8]">
              <a-col :span="2.5">
                <span class="title-color">客户来源</span>
              </a-col>
              <a-col :span="7">
                <span class="data-color">{{ dispatchDetail.isInside?.label || '' }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">是否取车</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ dispatchDetail.isPlaceIn?.label || '' }}</span>
              </a-col>
              <a-col :span="2.5">
                <span class="title-color">合同总金额</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ dispatchDetail.contractAmt }}</span>
                <span v-show="dispatchDetail.paymentArrival > 0"> 订单到付：<span style="color:#f00">{{
                  dispatchDetail.paymentArrival || '' }}</span></span>
                <span v-show="dispatchDetail.refundFee > 0"> 返款：<span style="color:#f00">{{ dispatchDetail.refundFee || ''
                }}</span></span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="2.5">
                <span class="title-color">客户名称</span>
              </a-col>
              <a-col :span="7">
                <span class="data-color">{{ dispatchDetail.customerName }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">取车地址</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ dispatchDetail.placeInAddress || '' }}</span>
              </a-col>
              <a-col :span="2.5">
                <span class="title-color">优惠券&emsp;&emsp;</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ dispatchDetail.coupon || '' }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="2.5">
                <span class="title-color">订单号&emsp;</span>
              </a-col>
              <a-col :span="7">
                <span class="data-color">{{ dispatchDetail.orderId || '' }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">取车类型</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ dispatchDetail.pickType?.label || '' }}</span>
              </a-col>
              <a-col :span="2.5">
                <span class="title-color">结算方式&emsp;</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ dispatchDetail.orderSettlement?.label || '' }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="2.5">
                <span class="title-color">车型&emsp;&emsp;</span>
              </a-col>
              <a-col :span="7">
                <span class="data-color">{{ dispatchDetail.brand + dispatchDetail.model }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">是否送车</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ dispatchDetail.isTakeOut?.label || '' }}</span>
              </a-col>
              <a-col :span="2.5">
                <span class="title-color">成本报价&emsp;</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ dispatchDetail.amt || '' }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="2.5">
                <span class="title-color">送车类型</span>
              </a-col>
              <a-col :span="7">
                <span class="data-color">{{ dispatchDetail.takeType?.label || '' }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">结算状态</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ dispatchDetail.settlementStatus?.label || '' }}</span>
              </a-col>
              <a-col :span="2.5">
                <span class="title-color">始发地&emsp;&emsp;</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ dispatchDetail.startAddress || '' }}</span>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="2.5">
                <span class="title-color">送车地址</span>
              </a-col>
              <a-col :span="7">
                <span class="data-color">{{ dispatchDetail.startAddress || '' }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">订单状态</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ dispatchDetail.orderStatus?.label }}</span>
              </a-col>
              <a-col :span="2.5">
                <span class="title-color">目的地&emsp;&emsp;</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ dispatchDetail.endAddress || '' }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">产品名</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color">{{ dispatchDetail.productName || '' }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">小车备注</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ dispatchDetail.remark || '' }}</span>
              </a-col>
              <a-col :span="2.5">
                <span class="title-color">订单备注&emsp;</span>
              </a-col>
              <a-col :span="4">
                <div class="data-color" style="word-break: break-all;">{{ dispatchDetail.orderRemark || '' }}</div>
              </a-col>
              <!-- 分隔线 -->
              <a-col :span="3">
                <span class="title-color">等通知放车</span>
              </a-col>
              <a-col :span="6">
                <span class="data-color">{{ dispatchDetail.isNoticeReleaseCar?.label }}</span>
              </a-col>
              <a-col :span="3">
                <span class="title-color">交车条</span>
              </a-col>
              <a-col :span="5">
                <span class="data-color">{{ dispatchDetail.isDeliveryCard?.label }}</span>
              </a-col>
              <a-col :span="2.5">
                <span class="title-color">验车打款&emsp;</span>
              </a-col>
              <a-col :span="4">
                <span class="data-color">{{ dispatchDetail.isInspectionPay?.label }}</span>
              </a-col>
            </a-row>
          </div>
        </div>
        <!-- 订单进度 -->
        <div>
          <div class="content-title">订单进度</div>
          <div class="content-data content">
            <a-steps :current="2" size="small" progress-dot>
              <!-- wait蓝色-进行中  error灰色-未安排 -->
              <a-step v-for="(item, index) in detailData.completeness" :key="index" :description="item.status"
                :status="item.value === 1 ? 'error' : item.value === 3 ? 'wait' : ''" :title="item.label" />
              <a-step description="" status="error" title="" />
            </a-steps>
          </div>
        </div>
        <!-- 全程时效 -->
        <div>
          <div class="content-title">全程时效</div>
          <div class="content-data">
            <a-row :gutter="[24, 8]">
              <a-col :span="2.5">
                <span>下单时间</span>
              </a-col>
              <a-col :span="7">
                <span>{{ dispatchDetail.pushStartTime }}</span>
              </a-col>
              <a-col :span="3">
                <span>总里程数</span>
              </a-col>
              <a-col :span="6">
                <span>{{ dispatchDetail.totalTransportKm || '' }}KM</span>
              </a-col>
              <a-col :span="3">
                <span>最晚完成时间</span>
              </a-col>
              <a-col :span="2">
                <span>{{ dispatchDetail.theoreticalArrivalTime }}</span>
              </a-col>
            </a-row>
          </div>
        </div>
        <!-- 派单任务 -->
        <div>
          <div class="content-title">
            <span style="margin-right: 15px">派单任务</span>
          </div>
          <a-tabs>
            <a-tab-pane key="1" tab="派单任务">
              <div class="content-data" style="margin-bottom:10px" v-for="(item, index) in dispatchDetail.tasks"
                :key="index">
                <div>
                  <a style="font-weight:normal;" @click="updateImg">
                    <span>车辆照片</span>
                    <span style="margin-left:10px">{{ item.picNum || '0' }}张</span>
                  </a>
                  <div>
                    <div style="display: flex;align-items:center;margin-bottom:15px;">
                      <div class="title-name-style">{{ item.taskType?.label }}</div>
                      <a-divider type="vertical" />
                      <span style="font-size:14px">
                        <span>{{ item.taskName }}</span>
                      </span>
                      <a-divider type="vertical" />
                      <span class="operation">
                        <img class="img" src="@/assets/images/carrier/QRC.png" @click="handleCope(item)">
                        <div class="QRC-img" v-show="item.QRCshow">
                          <div>
                            <div>车架号：<a-tag color="green">待字段</a-tag></div>
                            <div>线路：<span style="color:#f00">{{ item.lineStart + '——' + item.lineEnd }}</span></div>
                            <img v-show="!QRCloading" class="img" :src="QRCurl" style="width:100%;height:100%">
                            <div style="text-align: center"><a-spin v-show="QRCloading" size="large" /></div>
                          </div>
                        </div>
                      </span>
                      <!-- <a-divider type="vertical" /> -->
                      <!-- <span><img class="img" src="@/assets/images/carrier/employee.png" /></span> -->
                    </div>

                  </div>
                  <div style="display: flex;justify-content: space-between;font-size: 12px;">
                    <div>
                      <div class="title-color">承运商</div>
                      <div class="data-color">{{ item.carrierName }}</div>
                    </div>
                    <div>
                      <div class="title-color">派单价</div>
                      <div class="data-color">￥{{ item.totalAmt }}</div>
                    </div>
                    <div>
                      <div class="title-color">当前状态</div>
                      <div class="data-color">{{ item.taskStatus?.label }}</div>
                    </div>
                    <div style="position: relative;">
                      <div class="operate-btn">
                        <a-popconfirm ok-text="确认" cancel-text="取消" @confirm="confirmCancel(item)">
                          <template #title>
                            <div>
                              <div>确认取消该任务？</div>
                              <div><a-input v-model:value="cancelPlantRemark" placeholder="取消原因" /></div>
                            </div>
                          </template>
                          <div v-show="!item.btnShow"><a-button
                              style="margin-bottom:5px;background:#0066CC;color:#fff">撤销</a-button></div>
                        </a-popconfirm>
                        <div><a-button style="background:#0066CC;color:#fff" @click="monitorDetail(item)">监控</a-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="运输信息" force-render>
              <a-table :columns="transColumns" :data-source="dispatchDetail?.details" :pagination="false" bordered
                :row-key="record => record.totvId" :scroll="{ x: 1500 }" size="small">
                <template #carrierName="{ record }">
                  <div><a @click="carrierEdit(record)">{{ record.carrierName }}</a></div>
                </template>
                <template #transportFee="{ record }">
                  <div>
                    <a @click="editMoney(record)">{{ record.transportFee }}</a>
                  </div>
                </template>
                <template #settlementStatus="{ record }">
                  对账：<a @click="settlementData(record.ftsId, record.billStatus)">{{ record.billStatus ?
                    record.billStatus.label : '' }}</a>
                  <a-divider type="vertical" />
                  <span>{{ record.settlementStatus ? record.settlementStatus.label : '' }}</span>
                </template>
                <template #repeal="{ record }">
                  <div v-show="record.index + 1 === dispatchDetail?.details.length">
                    <a-popconfirm ok-text="是" cancel-text="否" @confirm="repealClick(record)">
                      <template #title>
                        <div>
                          <p>确认撤销？</p>
                          <a-textarea v-model:value="repealRemark" placeholder="备注信息" :rows="4" />
                        </div>
                      </template>
                      <a>撤销到达</a>
                    </a-popconfirm>
                  </div>
                </template>
              </a-table>
            </a-tab-pane>
          </a-tabs>

        </div>
        <!-- 运输位置 -->
        <div>
          <div class="content-title" style="display: flex;justify-content: space-between;">
            <div>
              <span style="margin-right:10px">运输位置</span>
              <span style="color:#B5BBC5;font-weight: normal;">共{{ locationData.length || 0 }}条</span>
            </div>
          </div>
          <div class="content-data">
            <a-steps v-show="locationData.length >= 0" progress-dot :current="1" direction="vertical">
              <a-step v-for="(item, index) in locationData" :key="index">
                <template #title>
                  <div style="display: flex;justify-content: space-between;">
                    <span>{{ item.location }}</span>
                    <a-typography-paragraph :copyable="{ text: item.location }">
                    </a-typography-paragraph>
                  </div>
                </template>
                <template #description>
                  <div>
                    <div>备注：{{ item.remark }}</div>
                    <div>{{ item.operator }}<span style="margin-left:10px">{{ item.createTime }}</span></div>
                  </div>
                </template>
              </a-step>
            </a-steps>
            <div v-show="locationData.length <= 0" style="text-align: center;color:#6B778C">暂无在途信息</div>
          </div>
        </div>
      </div>
      <div class="right-content">
        <Record v-if="detailData.orderVehicleId" :vehicleList="[]" :vehiclesId="detailData.orderVehicleId"
          :orderId="detailData.orderId" />
      </div>
    </div>
  </a-modal>
  <!-- 发运详情 -->
  <TransportDetail :windowShow="monitorDetailShow" :taskDetail="taskDetail" @statusShow="monitorDetail" />
  <!-- 验车图片 -->
  <InspectionImg ref="imgListRef" :orderVehicleId="detailData.orderVehicleId" :loadInspectList="[]" />
  <!--修改承运商-->
  <a-modal v-model:visible="carrierShow" title="修改承运商" width="50%" :destroyOnClose="true">
    <div>
      <CarrierList @carrier="carrierChange" />
    </div>
  </a-modal>
  <!-- 修改金额 -->
  <a-modal v-model:visible="editShow" title="修改内容" :destroyOnClose="true" @ok="confirmEdit">
    金额：
    <a-input v-model:value="money" type="number" />
    <a-divider />
    备注：
    <a-textarea :maxlength="200" v-model:value="reason" placeholder="请说明您要修改的原因" />
  </a-modal>
  <!-- 结算信息 -->
  <a-modal v-model:visible="settlementShow" :width="400" footer="" :destroyOnClose="true">
    <a-row :gutter="[24, 12]">
      <a-col :span="12">
        <span class="order-data-title">账单号</span>
        <span class="font-color2" style="display: inline-block;width: 50%">
          <a-typography-paragraph :copyable="{ text: settlementDetail.id }">
            {{ settlementDetail.id }}
          </a-typography-paragraph>
        </span>
      </a-col>
      <a-col :span="12">
        <span class="order-data-title">客户名称</span>
        <span class="font-color2">{{ settlementDetail.customerName }}</span>
      </a-col>
      <a-col :span="12">
        <span class="order-data-title">对账人</span>
        <span class="font-color2">{{ settlementDetail.sponsor }}</span>
      </a-col>
      <a-col :span="12">
        <span class="order-data-title">对账时间</span>
        <span class="font-color2">{{ settlementDetail.createTime }}</span>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue'
import { getDispatchList, cancelPlant } from '@/api/transport/transport'
import { vehicleGpsDetail, vehicleSettlement } from '@/api/transport/truck'
import { driverPage, QRCurl, inspection } from '@/api/transport/inspection'
import { transEdit } from '@/api/trans/ops/edit'
import { message } from 'ant-design-vue'
import Record from '@/views/transportCapacity/transportUse/comon/record.vue'
import TransportDetail from './transportDetail.vue'
import InspectionImg from '@/views/components/vehicleUpload/inspectionImg'
import CarrierList from '@/views/transportCapacity/transportUse/comon/carrierList'

export default {
  props: {
    windowShow: Boolean,
    vehicleDetailData: Object
  },
  components: {
    Record,
    TransportDetail,
    CarrierList,
    InspectionImg
  },
  setup (props, context) {
    const imgListRef = ref(null)
    const state = reactive({
      windowShow: false,
      priceDataShow: false,
      QRCshow: false,
      QRCloading: false,
      monitorDetailShow: false,
      editShow: false,  // 运输信息变量定义
      carrierShow: false, // 运输信息变量定义
      settlementShow: false,  // 运输信息变量定义
      money: '',  // 运输信息变量定义
      reason: '', // 运输信息变量定义
      settlementDetail: {}, // 运输信息变量定义
      record: {}, // 运输信息变量定义
      QRCurl: '',
      cancelPlantRemark: '',
      detailData: {},
      orderInspectionCustomer: {},
      locationData: [],
      dispatchDetail: {},
      taskDetail: {},
      transColumns: [
        {
          title: '大车车牌',
          dataIndex: 'carNo',
          width: '10%'
        },
        {
          title: '运输类型',
          width: '9%',
          dataIndex: 'transportType.label'
        },
        {
          title: '承运商',
          width: '10%',
          slots: {
            customRender: 'carrierName'
          }
        },
        {
          title: '运费',
          dataIndex: 'transportFee',
          width: '8%',
          slots: {
            customRender: 'transportFee'
          }
        },
        {
          title: '运输状态',
          dataIndex: 'truckStatus.label',
          width: '9%'
        },
        {
          title: '运输路线',
          dataIndex: 'lineName',
          width: '9%'
        },
        {
          title: '对账结算',
          dataIndex: 'settlementStatus',
          width: '14%',
          slots: {
            customRender: 'settlementStatus'
          }
        },
        {
          title: '装车时间',
          dataIndex: 'loadingTime',
          width: '10%'
        },
        {
          title: '装车人',
          dataIndex: 'loadingOperator',
          width: '10%'
        },
        {
          title: '发车时间',
          dataIndex: 'departureTime',
          width: '10%'
        },
        {
          title: '发车人',
          dataIndex: 'departure',
          width: '10%'
        },
        {
          title: '卸车时间',
          dataIndex: 'unloadingTime',
          width: '10%'
        },
        {
          title: '卸车人',
          dataIndex: 'unloadingOperator',
          width: '10%'
        },
        {
          title: '卸车地',
          dataIndex: 'endAddress',
          width: '10%'
        },
        {
          title: '操作',
          width: '100px',
          slots: {
            customRender: 'repeal'
          }
        }
      ],
    })
    watch(() => props.windowShow, (newVal, oldVal) => {
      state.windowShow = props.windowShow
      state.detailData = props.vehicleDetailData
      if (state.windowShow === true) {
        getLocation()
        getTaskListData()
        getPriceData()
      }
    })
    // 生成小程序码
    const handleCope = item => {
      if (item.taskId !== '' && item.taskId !== undefined && item.taskId !== null) {
        item.QRCshow = !item.QRCshow
        if (item.QRCshow === true) {
          state.QRCloading = true
          const wXprogramQRcode = { scene: item.id, type: 1 }
          QRCurl(wXprogramQRcode)
            .then(res => {
              if (res.code === 10000) {
                state.QRCurl = 'data:image/png;base64,' + res.data
                state.QRCloading = false
              }
            }).catch(err => console.log(err))
        }
      }
    }
    // 获取任务列表数据
    const getTaskListData = () => {
      getDispatchList(state.detailData.orderVehicleId).then(res => {
        if (res.code === 10000) {
          if (res.data.tasks && res.data.tasks.length > 0) {
            res.data.tasks.forEach((v, i) => {
              v.QRCshow = false
              v.btnShow = false
              if (v.taskStatus.value === 9) v.btnShow = true
              else v.btnShow = false
            })
          }
          setTimeout(() => {
            state.dispatchDetail = res.data
          }, 50)
        }
      })
    }
    // 获取报价信息
    const getPriceData = () => {
      inspection(props.vehicleDetailData.orderId)
        .then(res => {
          if (res.code === 10000) {
            if (res.data.orderInspectionCustomer.offer !== null) res.data.orderInspectionCustomer.offer = JSON.parse(res.data.orderInspectionCustomer.offer)
            state.orderInspectionCustomer = res.data.orderInspectionCustomer
          }
        }).catch(err => { console.log(err) })
    }
    // 运输位置
    const getLocation = () => {
      vehicleGpsDetail(state.detailData.orderVehicleId).then(res => {
        if (res.code === 10000) {
          state.locationData = res.data
        }
      })
    }
    const cancelClose = () => {
      state.windowShow = false
      context.emit('statusShow', state.windowShow)
    }
    const monitorDetail = e => {
      if (e.orderVehicleId) state.taskDetail = e
      if (e !== undefined && !e.orderVehicleId) state.monitorDetailShow = e, getTaskListData()
      else state.monitorDetailShow = true
    }
    // 运输信息-修改承运商
    const carrierEdit = record => {
      state.record = record
      state.carrierShow = true
    }
    // 运输信息-修改承运商接口
    // 修改承运商
    const carrierChange = (e) => {
      if (e) {
        transEdit({
          params: {
            transportTruckId: state.record.transTruckId,
            totvId: state.record.totvId,
            carrierId: e.id,
            carrierName: e.carrierName
          },
          type: {
            value: 1
          },
          reason: e.reason,
          subType: 102
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.carrierShow = false
            getTaskListData()
          }
        })
      }
    }
    // 运输信息-修改金额
    const editMoney = record => {
      state.record = record
      state.editShow = true
    }
    // 运输信息-确认修改金额
    const confirmEdit = () => {
      if (state.money !== '') {
        transEdit({
          params: {
            transportTruckId: state.record.transTruckId,
            totvId: state.record.totvId,
            changeFee: state.money
          },
          type: {
            value: 1
          },
          reason: state.reason,
          subType: 101
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.editShow = false
            getTaskListData()
          }
        })
      }
    }
    // 运输信息-对账
    const settlementData = (id, status) => {
      if (status && status.value === 1) {
        state.settlementShow = true
        state.settlementDetail = {}
        vehicleSettlement(id)
          .then(res => {
            if (res.code === 10000) {
              state.settlementDetail = res.data
            }
          })
      } else {
        message.warning('还未对账')
      }
    }
    // 运输信息-撤销到达
    const repealClick = record => {
      transEdit({
        params: {
          transportTruckId: record.transTruckId,
          totvId: record.totvId
        },
        type: {
          value: 1
        },
        reason: state.repealRemark,
        subType: 104
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          getTaskListData()
        }
      })
    }
    // 验车图片
    const updateImg = () => {
      imgListRef.value.inspectionImg = true
      imgListRef.value.loadData(state.detailData.orderVehicleId)
    }
    // 取消任务
    const confirmCancel = item => {
      if (state.cancelPlantRemark != '') {
        cancelPlant({
          remark: state.cancelPlantRemark,
          taskId: item.taskId
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            getTaskListData()
          }
        })
      } else message.info('请填写原因')
    }

    return {
      ...toRefs(state),
      imgListRef,
      cancelClose,
      monitorDetail,
      handleCope,
      carrierEdit,
      carrierChange,
      editMoney,
      confirmEdit,
      confirmEdit,
      settlementData,
      repealClick,
      updateImg,
      confirmCancel,
      getPriceData
    }
  }
}
</script>

<style lang="less" scoped>
@import '../index.less';

:deep(.ant-typography) {
  font-size: 12px !important;
}

:deep(.ant-steps-item-content) {
  width: auto !important;
  color: #6B778C;

  .ant-steps-item-title {
    width: 100%;
    color: #6B778C !important;
  }
}

.content {
  :deep(.ant-steps-item-tail::after) {
    margin-left: 6% !important;
  }

  :deep(.ant-steps-item-icon) {
    margin-left: 49% !important
  }
}

.title-name-style {
  padding: 5px 3px;
  color: #fff;
  border-radius: 50%;
  background-color: #3470FF;
}

.operation {
  position: relative;

  .QRC-img {
    width: 200px;
    position: absolute;
    padding: 5px;
    background-color: #fff;
    z-index: 9;
  }
}

.operate-btn {
  position: absolute;
  bottom: 0%;
  right: 100%;
  text-align: center;
}

.offer-data {
  width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  border: 1px solid #eee;
  background-color: #fff;
  z-index: 99;

  .altogether {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #1890ff;
    font-weight: bold;
  }

  .title-num {
    color: #ffffff;
    width: 22px;
    line-height: 22px;
    background-color: #0584fa;
    border-radius: 90px;
  }

  .reference {
    display: flex;
    justify-content: space-between;
    color: rgb(175, 172, 172);
  }
}
</style>