<template>
<div>
  <a-modal v-model:visible="visible" footer="">
    <a-button type="primary" @click="add1">按钮1</a-button>
    <a-button @click="add2">按钮2</a-button>
  </a-modal>
    
  <!-- <ContrastModal v-if="visibless" /> -->
</div>
</template>

<script setup>
import { ref } from 'vue'
import ContrastModal from '@/components/contrastModal' 
const visible = ref(false)
const visibless = ref(false)

const add1 = () => {
  visibless.value = true
}
const add2 = () => {

}
defineExpose({
  visible
})
</script>

<style lang="less" scoped>

</style>