<template>
  <div>
    <!-- 头部搜索模块 -->
    <div class="header-search" v-if="$slots.headerSearch">
      <slot name="headerSearch"></slot>
      <div class="header-search-operateBtn" v-if="buttonShow">
        <!-- <a-button class="header-search-btn" @click="searchData" :loading="loading">搜索</a-button>
        <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button> -->
      </div>
    </div>
    <a-divider />
    <!-- 表格统筹按钮 -->
    <div class="operate-btn" v-if="$slots.operateBtn">
      <a-space>
        <slot name="operateBtn"></slot>
      </a-space>
    </div>
    <!-- 其他自定义内容 -->
    <div style="margin:15px">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent,watch } from 'vue'
export default defineComponent({
  name: 'GbobalTable',
  props: {
    searchData: Function,
    resetData: Function,
    loading: Boolean
  },
  setup (props) {
    const state = reactive({
      loading: false,
      buttonShow: Boolean,
    })
    watch(() => props.loading, (newVal, oldVal) => {
      state.loading = newVal
      if(props.searchData) state.buttonShow = true
      else state.buttonShow = false
    })
    const searchData = () => {
      props.searchData()
    }
    const resetData = () => {
      props.resetData()
    }

    return {
      ...toRefs(state),
      searchData,
      resetData
    }
  }
})
</script>

<style lang="less" scoped>
@import './index.less';
</style>