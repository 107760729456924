<template>
  <div>
    <a-modal :visible="visible" title="验车照检查" footer="" @cancel="cancel" width="80%" :bodyStyle="{ padding:'10px',background:'#EEEEEE'}">
      <div class="statusBox flex">
         <div v-for="item in tabstatus" :key="item.id" class="item cur-p" @click="changeBtn(item)" :class="indexKey === item.id ?'changeItem':''">
            {{ item.name }}({{ item.total }})
         </div>
      </div>
      <SearchList :iptData="iptData"></SearchList>
      <div class="m-t1 tableBox">
        <a-table :dataSource="dataSource" :columns="columns" bordered :pagination="false"></a-table>
      </div>
    </a-modal>
  </div>
</template>
  
  <script setup>
import { ref } from 'vue'
import SearchList from '@/components/searchList'
import enumAll from '@/store/modules/enumAll'
const emit = defineEmits(['update:visible'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
})
const cancel = () => {
  emit('update:visible', false)
}
const indexKey = ref(1)

const changeBtn = (e) => {
  indexKey.value = e.id
}
const tabstatus = ref([
  {
    name: '全部',
    id: 1,
    total: '192'
  },
  {
    name: '未上传',
    id: 2,
    total: '192'
  },
  {
    name: '照片≤10',
    id: 3,
    total: '192'
  },
])
const iptData = ref([
  {
    type: "address",
    placeholder: "起运地",
    value: null,
    prop: "vinNo",
    changeOn:true
  },
  {
    type: "address",
    placeholder: "目的地",
    value: null,
    prop: "status",
    changeOn:true
  },
  {
    type: "select",
    placeholder: "车辆状态",
    value: null,
    prop: "vinNo",
    width: 150,
    key:'value',
    keyVal:'label',
    opt:enumAll.state.smallVehStatus
  },
  {
    type: "select",
    placeholder: "订单状态",
    value: null,
    prop: "vinNo",
    width: 150,
    key:'value',
    keyVal:'label',
    opt:[
      {
        value: '',
        label: '全部'
      },
      {
        value: 0,
        label: '保存'
      },
   
      {
        value: 1,
        label: '已推送'
      },
      {
        value: 2,
        label: '运输中'
      },
      {
        value: 9,
        label: '已完成'
      },
      {
        value: 4,
        label: '待验车'
      },
      {
        value: 5,
        label: '已验车'
      },
      {
        value: 6,
        label: '已拒绝'
      },
      {
        value: 7,
        label: '已取消'
      },
      {
        value: 8,
        label: '待提车'
      }
    ]
  },
  {
    type: "select",
    placeholder: "是否外派",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[
      {
        name:'是',
        id:1
      },
      {
        name:'否',
        id:0
      }
    ]
  },
  {
    type: "select",
    placeholder: "标签类型",
    value: null,
    prop: "vinNo",
    width: 150,
    opt:[
    ]
  },

  {
    type: "input",
    placeholder: "业务员",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "订单号",
    value: null,
    prop: "vinNo",
    width: 190,
  },
  {
    type: "input",
    placeholder: "调度备注",
    value: null,
    prop: "vinNo",
    width: 150,
  },
  {
    type: "input",
    placeholder: "车牌号",
    value: null,
    prop: "vinNo",
    width: 150,
  },
])
const columns = ref([
  {
    title: '车牌车型',
    dataIndex: 'vinNo',
    align: 'center',
    slots:{
      customRender: 'vinNo'
    }
  },
  {
    title: '照片数量',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: '发运进度',
    dataIndex: 'taskStatus',
    align: 'center',
  },
  {
    title: '订单状态',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '订单号',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '业务员',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '起运地',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '目的地',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '调度备注',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
  {
    title: '标签',
    dataIndex: 'taskStatus1',
    align: 'center',
  },
])
  </script>
  
  <style lang="less" scoped>
.tableBox {
  padding: 20px 10px;
  background: #fff;
}

.statusBox {
  border-bottom: 1px solid #eee;
  background: #fff;
}

.item{
  padding: 10px;
  border-bottom: 4px solid transparent;
  margin-right: 40px;
  font-size: 16px;
}
.changeItem{
  border-bottom: 4px solid #0066CC;
  color: #0066CC;
}
</style>