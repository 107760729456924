import { getRequest, postBodyRequest, fileRealUpload, postBodyRequestSpecial,putRequest } from '@/utils/axios'

export function getTemplateList (params) {
  return postBodyRequest('/market/template/getTemplateList', params)
}

export function getPageList (params) {
  return getRequest('/market/template/getTemplatePageList', params)
}

export function getMenu (params) {
  return getRequest('/market/template/getTemplateMenu', params)
}

export function getattribute (params) {
  return getRequest('/market/template/getTemplateAttribute', params)
}

export function editSwitch (params) {
  return getRequest('/market/template/enableSwitch', params)
}

export function deletePage (params) {
  return getRequest('/market/template/deleteTemplatePage', params)
}
// 图片上传
export const fileUpload = params => {
  return fileRealUpload(`/file/upload/${Math.floor(Math.random() * 1000)}`, params)
}



export function saveEdit (params) {
  return postBodyRequest('/market/template/saveTemplateAttribute', params)
}

export function detailList (params) {
  return getRequest('/market/template/getTemplatePageDetailList', params)
}

export function saveDetail (params) {
  return postBodyRequest('/market/template/saveTemplatePageDetail', params)
}

export function addPage (params) {
  return postBodyRequest('/market/template/addTemplatePage', params)
}

export function generatePage (params) {
  return postBodyRequest('/market/template/generatePage', params)
}

export function getTemplateImg (params) {
  return postBodyRequest('/market/template/getTemplatePhotoList', params)
}

export function update (params) {
  return getRequest('/market/template/oneClickUpdate', params)
}

//客户发票抬头新增
export function invoiceAdd (customerId,params) {
  return postBodyRequest(`/market/crm/customer/${customerId}/invoice/add`, params)
}
//编辑发票抬头新增
export function invoiceEdit (customerId,params) {
  return postBodyRequest(`/market/crm/customer/${customerId}/invoice/edit`, params)
}
//客户发票抬头集合
export function invoiceList (customerId,params) {
  return postBodyRequest(`/market/crm/customer/${customerId}/invoice/list`,params)
}
// 客户发票抬头详情
export function invoiceDetail (id) {
  return getRequest(`/market/crm/customer/invoice/detail?id=${id}`)
}
// 客户发票抬头设置主要使用
export function invoiceIsMain (customerId,invoiceId) {
  return putRequest(`/market/crm/customer/${customerId}/invoice/${invoiceId}/main`)
}
// 客户发票抬头-是否启用
export function invoiceEnableType (invoiceId,enableType) {
  return putRequest(`/market/crm/customer/invoice-enable/${invoiceId}/${enableType}`)
}