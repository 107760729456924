<template>
  <div id="map"></div>
</template>

<script>
import { reactive, toRefs, ref, onMounted, watch } from 'vue'
import { message } from 'ant-design-vue'
export default {
  props: {
    num: Number,
    city: String,
    adCode: String,
    details: String
  },
  setup (props, ctx) {
    const state = reactive({
      num: props.num,
      city: props.city,
      adCode: props.adCode || '',
      details: '',
      addressComponents: '',
      marker: null,
    })
    let map = ref()
    const createMap = (d) => {
      map = new LKMap.Map('map', {
        center: [116.4124519634895, 39.9043382673205],      // 中心点-北京市
        zoom: 4,   // 缩放级别
        style: 'lkmap://styles/standard',    // 地图样式
      })
      /* eslint-disable */
      // 创建Map实例
      // if (BMap !== undefined) {
      //   map = new BMap.Map('map')
      //   if (state.city === undefined || state.city === '') {
      //     state.city = '北京'
      //   }
      //   let accuracy = 11
      //   if (state.city.indexOf('区') != -1) {
      //     accuracy = 13
      //   }
      //   if (props.details != undefined && props.details != '') {
      //     accuracy = 16
      //     addressSearch()
      //   } else {
      //     // 初始化地图,设置中心点坐标和地图级别
      //     map.centerAndZoom(state.city, accuracy)
      //   }
      //   //添加地图类型控件
      //   map.addControl(new BMap.MapTypeControl({
      //     mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP]
      //   }))
      //   // 设置地图显示的城市 此项是必须设置的
      //   //开启鼠标滚轮缩放
      //   map.enableScrollWheelZoom(true)
      //   // map.addControl(new BMap.NavigationControl()) // 添加平移缩放控件
      //   map.addControl(new BMap.ScaleControl()) // 添加比例尺控件
      //   // map.addControl(new BMap.OverviewMapControl()) // 添加缩略地图控件
      // }
    }
    watch(() => props.details,
      (newValue, oldValue) => {
        console.log(props);
        state.city = newValue
        if (newValue !== oldValue) {
          // createMap()
        }
      }
    )
    onMounted(createMap)
    const addressSearch = (adCode) => {
      if (adCode) state.adCode = adCode
      if (state.adCode) {
        var myGeo = new LKMap.TextSearch({
          adcode: Number(props.adCode || state.adCode)
        })
        let address = ''
        if (props.details !== null && props.details !== '') {
          address = props.details
        } else {
          address = props.city
        }
        console.log(props.details, address);
        myGeo.search(
          address,
          (status, result) => {
            console.log(status, result)
            if (!result) {
              message.error('地图搜索不到这个地址，请换个地址搜索')
              return
            }
            let data = result.length > 1 ? result[0].properties : result
            console.log(state.adCode);
            console.log('data', data, 'address', address);
            let lon = data.route_point.lon //经度
            let lat = data.route_point.lat //纬度
            if (state.marker !== null) state.marker.remove()
            state.marker = new LKMap.Marker({
              position: data.route_point,
              anchor: 'bottom',
              draggable: true,    // 是否拖拽
            })
            map.panTo(data.center)
            state.addressComponents = {
              district: data.county,
              city: data.city ? data.city : data.province,
              province: data.province
            }
            setTimeout(() => {
              map.setZoom(12)
            }, 500)
            state.marker.setMap(map)
            state.marker.on('dragend', (e, v) => {
              var geocoderService = new LKMap.Geocoder()
              var center = new LKMap.LngLat(e.lngLat.lng, e.lngLat.lat)
              geocoderService.getAddress(center, (status, result) => {
                console.log(status, result);
                let v = result.length > 0 ? result[0].properties : result
                state.addressComponents = {
                  street: v.street,
                  district: v.county,
                  city: v.city ? v.city : v.province,
                  province: v.province
                }
                onEmit(lon, lat, v.name)
                props.details = ''
              })
            })
            onEmit(lon, lat, address)
            props.details = ''
            console.log(props);

          })

        // var myGeo = new BMap.Geocoder()
        // // 将地址解析结果显示在地图上，并调整地图视野
        // let address= state.city ;
        // if (props.details!==null && props.details!==''){
        //   address=props.details;
        // }else {
        //   address=state.city;
        // }
        // myGeo.getPoint(address, function (point) {
        //   if (point) {
        //     map.centerAndZoom(point, 16)
        //     myGeo.getLocation(new BMap.Point(point.lng, point.lat), function (result) {
        //       if (result) {
        //         state.addressComponents = result.addressComponents
        //         onEmit(point.lng, point.lat, props.details)
        //       }
        //     });
        //     let allOverlay = map.getOverlays()
        //     for (let i = 0; i < allOverlay.length; i++) {
        //       if (allOverlay[i].id === 1) {
        //         map.removeOverlay(allOverlay[i])
        //       }
        //     }
        //     var marker = new BMap.Marker(point)
        //     marker.enableDragging()
        //     marker.addEventListener("dragend", function (e) {
        //       var x = e.point.lng //经度
        //       var y = e.point.lat //纬度
        //       myGeo.getLocation(new BMap.Point(x, y), function (res) {
        //         state.addressComponents = res.addressComponents
        //         if (res.surroundingPois.length > 0) onEmit(x, y, res.surroundingPois[0].title)
        //         else onEmit(x, y, res.addressComponents.district + res.addressComponents.street)
        //       })
        //     })
        //     marker.id = 1
        //     map.addOverlay(marker)
        //   } else {
        //     message.error('您选择的地址没有解析到结果！')
        //   }
        // }, state.city)
      } else message.error('adCode错误')
    }
    const onEmit = (x, y, address) => {
      let posiData = {
        lng: x,
        lat: y,
        address: address,
        addressComponents: state.addressComponents
      }
      ctx.emit('onData', posiData)
    }
    return {
      ...toRefs(state),
      addressSearch,
      onEmit
    }
  }
}
</script>

<style lang="less" scoped>
#map {
  width: 100%;
  height: 100%;
}

.anchorBL {
  display: none;
}
</style>
