import { putRequest, postBodyRequest } from '@/utils/axios'
export function orderPickEdit (params) {
  return putRequest('/trans/ops/order/edit', params)
}

export function transEdit (params) {
  return putRequest('/trans/ops/trans/edit', params)
}

export function opsPage (params) {
  return postBodyRequest('/trans/ops/order/page', params)
}

export function query (id) {
  return postBodyRequest(`/trans/ops/order/query/${id}`, {})
}

export function editAudit (params) {
  return putRequest('/trans/ops/order/audit', params)
}
