import {
  fileUpload,
  getRequest,
  postBodyRequest
} from '@/utils/axios'
// 分页
export function page (params) {
  return postBodyRequest('/trans/transport/page', params)
}
// 车辆大版分页
export function truckPage (params) {
  return postBodyRequest('/carrier/carrier/truck/page', params)
}
// 装车
export function carPlan (params) {
  return postBodyRequest('/trans/transport/plan', params)
}
// 到达
export function carArrive (params) {
  return postBodyRequest('/trans/transport/arrive', params)
}
// 发车
export function carDepart (params) {
  return postBodyRequest('/trans/transport/depart', params)
}
// 小车发车(新2023年8月31日)
export function vehDepart (params) {
  return postBodyRequest('/trans/order/vehicle/depart', params)
}
// 中转
export function carTransfer (params) {
  return postBodyRequest('/trans/transport/transfer', params)
}
// 小车到达(新2023年8月31日)
export function arriveVehicle (params) {
  return postBodyRequest('/trans/order/vehicle/arrive', params)
}
// 卸板
export function carUnloadTruck (params) {
  return postBodyRequest('/trans/transport/unload/truck', params)
}
// 卸车
export function carUnloadVehicle (params) {
  return postBodyRequest('/trans/transport/unload/vehicle', params)
}

// 上传图片
export function carUpload (params) {
  return fileUpload(`/file/upload/${Math.floor(Math.random() * 1000)}`, params)
}
// 上传身份证图片
export function idNumberUpload (params) {
  return fileUpload('/file/upload/idNumber/ocr', params)
}
// 根据车辆id查询图片
export function carPics (id, params) {
  return postBodyRequest(`/trans/order/vehicle/inspect/${id}/page`, params)
}
// 根据车辆id与图片id相互绑定
export function carinspect (orderVehicleId, fileId, params) {
  return postBodyRequest(`/trans/order/vehicle/inspect/${orderVehicleId}/file/${fileId}`, params)
}
// 已选大版
export function truckdetail (id, params) {
  return getRequest(`/trans/transport/${id}/truck`, params)
}
// 车主小车
export function vehicledetail (id, params) {
  return getRequest(`/trans/transport/${id}/vehicle`, params)
}

// 查运输任务的车辆信息
export function vehicle (params) {
  return getRequest(`/trans/transport/vehicle/${params}`)
}
// 查询未安排的订单的客户车辆的数量
export function vehicleUnscheduled (params) {
  return getRequest('/trans/transport/vehicle/unscheduled', params)
}

export function changeOrderVehicleStatus (params) {
  return postBodyRequest('/trans/transport/vehicle/arrive', params)
}
// 获取地理位置
export function transTransportTruckGpsCarNoInfo (params, carNo, type) {
  return postBodyRequest(`/trans/transport/truck/gps/${carNo}/info/${type}`, params)
}
// 报价直达编辑
export function offerThroughEdit (params) {
  return postBodyRequest(`/bs/offer/through/edit`, params)
}
// 报价归属编辑
export function offerBelongEdit (params) {
  return postBodyRequest(`/bs/offer/belong/edit`, params)
}
// 更改运输方式
export function changeTransType (transTruckId, truckId, driverId, params) {
  return postBodyRequest(`/trans/transport/truck/${transTruckId}/changeTruck/${truckId}/${driverId}`, params)
}
// ----------------------------------------

// 车辆任务通缉数据信息
export function getStatisticsData ( params) {
  return postBodyRequest('/trans/order/vehicle-task/statistics', params)
}
export function getVehicleTaskList ( params) {
  return postBodyRequest('/trans/order/vehicle-task/list', params)
}
// 批量人工调度
export function batchManualOperate ( orderVehicleId,params) {
  return getRequest(`/trans/order/vehicle-task/${orderVehicleId}/type`, params)
}
// 安排车辆任务
export function dispatchArrange(params) {
  return postBodyRequest('/trans/order/vehicle-task/arrange', params)
}
// 车辆任务分页
export function getTaskList(params) {
  return postBodyRequest('/trans/order/vehicle-task/list', params)
}
// 派单任务列表
export function getDispatchList(orderVehicleId,params) {
  return getRequest(`/trans/order/vehicle-task/${orderVehicleId}`, params)
}
// 派单任务监控
export function getMonitorDetail(orderVehicleId,taskId,params) {
  return getRequest(`/trans/order/vehicle-task/${orderVehicleId}/${taskId}`, params)
}
// 小车安排发运
export function arrangeTrnas(params) {
  return postBodyRequest('/trans/order/vehicle-task/plan-vehicle', params)
}
// 小车发车
export function beginDepart(params) {
  return postBodyRequest('/trans/order/vehicle-task/depart', params)
}
// 小车发运到达(卸车)
export function vehicleArrive(params) {
  return postBodyRequest('/trans/order/vehicle-task/arrive', params)
}
// 异常上报
export function addReport (params) {
  return postBodyRequest('/trans/order/vehicle/exception/batch', params)
}
// 异常数据分页
export function abnormalPage (params) {
  return postBodyRequest('/trans/order/vehicle/exception/page', params)
}
// 订单车辆共享
export function shareOrderVehicle (params) {
  return postBodyRequest('/trans/order/shareOrderVehicle', params)
}
// 取消任务
export function cancelPlant (params) {
  return postBodyRequest('/trans/order/vehicle-task/cancel', params)
}
// 异常审核列表
export function abnomalList (params) {
  return postBodyRequest('/trans/order/vehicle/exception/audit/page', params)
}
// 审核操作
export function checkOperate (params) {
  return postBodyRequest('/trans/order/vehicle/exception/audit', params)
}
// 取消费用上报
export function cancelFeeException (params) {
  return postBodyRequest('/trans/order/vehicle/exception/cancel-fee-exception', params)
}
// 移交车辆
export function handoverVehicle (params) {
  return postBodyRequest('/trans/order/handoverOrderVehicle', params)
}
// 获取每段路线信息
export function getReclineData (params) {
  return getRequest('/trans/transport/truck/getOriginalRouteLine', params)
}
// 获取验提干送任务操作信息
export function getExecute (orderVehicleId,params) {
  return getRequest(`/trans/order/vehicle-task/execute/${orderVehicleId}`, params)
}
// 获取实际方案信息
export function getActualRouteLine (params) {
  return getRequest('/trans/transport/truck/getActualRouteLine', params)
}
// 发运模式-小车装车
export function loadVehicle (params) {
  return postBodyRequest('/trans/order/vehicle/plan-vehicle', params)
}
// 获取订单是否已推送
export function getIsPushOrder (orderId,params) {
  return postBodyRequest(`/trans/order/${orderId}/is-push`, params)
}
// 安排中--选择大车
export function plantPit (params) {
  return postBodyRequest('/trans/transport/truck/plan-pit', params)
}
// 中途补位
export function coverPit (params) {
  return postBodyRequest('/trans/transport/truck/cover-pit', params)
}
// 批量-任务详情(验提干送)
export function batchPlantDetail (params) {
  return postBodyRequest('/trans/order/vehicle-task/execute-batch', params)
}
// 批量-验车完成
export function batchInspect (params) {
  return postBodyRequest('/trans/order/inspection/done-batch', params)
}
// 批量-交车
export function batchDelivery (params) {
  return postBodyRequest('/trans/transport/vehicle/batch/delivery', params)
}
// 调度备注
export function setRemark (params) {
  return postBodyRequest('/trans/order/vehicle-task/set/remark', params)
}
// 调度日志分页
export function arrangePage (params) {
  return postBodyRequest('/trans/order/vehicle/arrange/page', params)
}

export function shareVehicle (params) {
  return postBodyRequest('/trans/order/shareOrderVehicle', params)
}
// 批量放空

export function ventBatch (params) {
  return postBodyRequest('/trans/order/inspection/vent-batch', params)
}

// 获取车辆的任务类型
export function vehicleTaskType (params) {
  return postBodyRequest('/trans/order/vehicle-task/task-type', params)
}
// 安排车辆任务
export function vehicleTaskArrange (params) {
  return postBodyRequest('/trans/order/vehicle-task/arrange', params)
}
// 任务的统计
export function vehicleTaskStatistics(params) {
  return postBodyRequest('/trans/order/vehicle-task/task-statistics', params)
}
// 任务分页
export function vehicleTaskPage(params) {
  return postBodyRequest('/trans/order/vehicle-task/task-page', params)
}
// 调度任务详情
export function vehicleTaskInfo(taskId) {
  return getRequest(`/trans/order/vehicle-task/task-detail/${taskId}`, )
}
// 取消车辆任务
export function vehicleTaskCancel(params) {
  return postBodyRequest(`/trans/order/vehicle-task/cancel`,params )
}
// 新增任务中选择小车列表
export function vehicleAddVehiclePage(params) {
  return postBodyRequest(`/trans/order/vehicle-task/add-vehicle-page`,params )
}
// 获取代收金额的详情
export function peerCollectionFee(params) {
  return postBodyRequest(`/trans/order/vehicle-task/peer-collection-fee`,params )
}
// 获取车辆任务中交车图片
export function taskDeliveryPic(orderVehicleId,params) {
  return postBodyRequest(`/trans/order/vehicle/inspect/${orderVehicleId}/task-delivery-pic`,params )
}